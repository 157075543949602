import { ErrorModel } from '../error/errorModel';
import { ReceiptStatusType } from './receiptStatusType';
import { ReverseReceiptType } from './reverseReceiptType';

/**
 * Represents a reverse purchase order receipt. Values in comments show example data.
 * Directly models the server side ReversePurchaseOrderReceipt object. If that one changes, then this one must change.
 */
export interface IReversePurchaseOrderReceipt {
    reverseReceiptBy: ReverseReceiptType;
    materialDocumentNumber?: string;
    purchaseOrder?: string;
    lineItem?: string;
    reverseQuantity?: number;
    requestor: string;
    reversalStatus: ReceiptStatusType;
    errors?: ErrorModel[];
}

export class ReversePurchaseOrderReceipt implements IReversePurchaseOrderReceipt {
    public reverseReceiptBy: ReverseReceiptType;
    public materialDocumentNumber?: string;
    public purchaseOrder?: string;
    public lineItem?: string;
    public reverseQuantity?: number;
    public requestor: string;
    public reversalStatus: ReceiptStatusType;
    public errors?: ErrorModel[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IReversePurchaseOrderReceipt) {
        this.reverseReceiptBy = jsonData.reverseReceiptBy;
        this.materialDocumentNumber = jsonData.materialDocumentNumber;
        this.purchaseOrder = jsonData.purchaseOrder;
        this.lineItem = jsonData.lineItem;
        this.reverseQuantity = jsonData.reverseQuantity;
        this.requestor = jsonData.requestor;
        this.reversalStatus = jsonData.reversalStatus;

        if (jsonData.errors && this.errors) {
            for (let i: number = 0; i < jsonData.errors.length; i++) {
                this.errors.push(new ErrorModel(jsonData.errors[i]));
            }
        }
    }
}
