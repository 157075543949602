import { TreeStateSelection } from './treeStateSelection';

export interface ITreeStateNode {
    type: string;
    desc: string;
    code: string;
    treeStateSelection: TreeStateSelection;
    children: ITreeStateNode[] | undefined;
}

/**
 * The TreeStateNode represents the selection state of the tree and its children.
 */
export class TreeStateNode implements TreeStateNode {
    public type: string;
    public desc: string;
    public code: string;
    public treeStateSelection: TreeStateSelection;
    public children: TreeStateNode[] | undefined;

    constructor(type: string, desc: string, code: string, treeStateSelection: TreeStateSelection, children: TreeStateNode[] | undefined) {
        this.type = type;
        this.desc = desc;
        this.code = code;
        this.treeStateSelection = treeStateSelection;
        this.children = children;
    }
}
