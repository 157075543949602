import { ExecFunctionCode } from './execFunctionCode';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IExecFunctionSummary extends IHierarchyNodeCommon {
    functionCode: ExecFunctionCode[];
}

export class ExecFunctionSummary implements IExecFunctionSummary {
    public typeName: string = HierarchyConstants.className.executive.execFunctionSummary;
    public code: string;
    public description: string;
    public functionCode: ExecFunctionCode[] = [];
    public children?: IHierarchyNodeCommon[] = this.functionCode; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IExecFunctionSummary) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.functionCode) {
            for (let i: number = 0; i < jsonData.functionCode.length; i++) {
                this.functionCode.push(new ExecFunctionCode(jsonData.functionCode[i]));
            }
        }
    }
}
