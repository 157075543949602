import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    ComboBox,
    DefaultButton,
    IComboBox,
    IComboBoxOption,
    Label,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { pageStyles } from './DriToolsPage.styles';
import { CallApiState } from '../../store/actions/generic.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';
import { validationConstants } from '../../common/validationConstants';
import { IApiReverseGoodsReceipt, callApiReverseGoodsReceipt, resetApiCallStateForReverseGoodsReceipt } from '../../store/actions/pageActions/driToolsPage.action';
import { ReversePurchaseOrderReceipt } from '../../models/grReversal/reversePurchaseOrderReceipt';
import { ReverseReceiptType } from '../../models/grReversal/reverseReceiptType';
import { padNumberWithLeadingChar } from '../../common/common.func.transform';
import { getUserAlias } from '../../services/auth/msalHelper';
import { ReceiptStatusType } from '../../models/grReversal/receiptStatusType';
import { tooltips } from '../../common/tooltips';

interface IComponentProps {
}

const reversalTypeOptions: IComboBoxOption[] = [
    {
        key: ReverseReceiptType.PurchaseOrderLine,
        text: commonString.poLineNumber
    } as IComboBoxOption,
    {
        key: ReverseReceiptType.MaterialDocumentNumber,
        text: commonString.materialDocNumber
    } as IComboBoxOption
];

export const GrReversalTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    const reversalTypeInputId: string = useId();
    const poNumberInputId: string = useId();
    const lineNumberInputId: string = useId();
    const reversalQuantityInputId: string = useId();
    const materialDocNumberInputId: string = useId();

    const [selectedReversalType, setSelectedReversalType] = useState<ReverseReceiptType>(ReverseReceiptType.PurchaseOrderLine);
    const [poNumber, setPoNumber] = useState<string>('');
    const [lineNumber, setLineNumber] = useState<string>('');
    const [reversalQuantity, setReversalQuantity] = useState<string>('');
    const [materialDocNumber, setMaterialDocNumber] = useState<string>('');

    const [poNumberValidationError, setPoNumberValidationError] = useState<string>('');
    const [lineNumberValidationError, setLineNumberValidationError] = useState<string>('');
    const [reversalQuantityValidationError, setReversalQuantityValidationError] = useState<string>('');
    const [materialDocNumberValidationError, setMaterialDocNumberValidationError] = useState<string>('');

    // Redux store selectors to get state from the store when it changes.
    const apiReverseGoodsReceipt: IApiReverseGoodsReceipt =
        useAppSelector<IApiReverseGoodsReceipt>((state) => state.driToolsPageReducer.apiReverseGoodsReceipt);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiReverseGoodsReceipt.errMsg) {
            handleError(apiReverseGoodsReceipt.errMsg);
        }
    }, [apiReverseGoodsReceipt.errMsg, handleError]);

    /**
     * Effect that returns a cleanup function for when this component is unmounted.
     */
    useEffect(() => {
        return () => {
            // Reset the api call state for reverse goods receipt.
            // So that when users navigate away and come back later, the state is refreshed.
            dispatch(resetApiCallStateForReverseGoodsReceipt());
        }
    }, [dispatch]);

    /**
     * Reversal type change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeReversalType = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            setSelectedReversalType(option.key as ReverseReceiptType);
        }
    };

    /**
     * Submit button clicked event handler.
     */
    const submitButtonClicked = () => {
        let po: string | undefined;
        let line: string | undefined;
        let quantity: number | undefined;
        let mdn: string | undefined;

        if (selectedReversalType === ReverseReceiptType.PurchaseOrderLine) {
            po = padNumberWithLeadingChar(poNumber, 10, '0');
            line = padNumberWithLeadingChar(lineNumber, 5, '0');
            quantity = Number(reversalQuantity);
        } else if (selectedReversalType === ReverseReceiptType.MaterialDocumentNumber) {
            mdn = materialDocNumber;
        }

        const reversalRequest: ReversePurchaseOrderReceipt = new ReversePurchaseOrderReceipt({
            purchaseOrder: po,
            lineItem: line,
            reverseQuantity: quantity,
            materialDocumentNumber: mdn,
            requestor: getUserAlias(),
            reverseReceiptBy: selectedReversalType,
            reversalStatus: ReceiptStatusType.InProgress
        });

        const reversalRequests: ReversePurchaseOrderReceipt[] = [reversalRequest];

        dispatch(callApiReverseGoodsReceipt(reversalRequests));
    };

    /**
     * Memoized helper to check if the reversal api is running.
     */
    const isReversalRunning = useMemo<boolean>(() => {
        if (apiReverseGoodsReceipt.callApiState === CallApiState.Running) {
            return true;
        }

        return false;
    }, [apiReverseGoodsReceipt.callApiState]);

    /**
     * Memoized helper to check if the input is invalid.
     */
    const isInputInvalid = useMemo<boolean>(() => {
        if (selectedReversalType === ReverseReceiptType.PurchaseOrderLine) {
            if (poNumber.trim().length === 0 || lineNumber.trim().length === 0 || Number(reversalQuantity) <= 0) {
                return true;
            }
            if (poNumberValidationError.length > 0 || lineNumberValidationError.length > 0 || reversalQuantityValidationError.length > 0) {
                return true;
            }
        } else if (selectedReversalType === ReverseReceiptType.MaterialDocumentNumber) {
            if (materialDocNumber.trim().length === 0) {
                return true;
            }
            if (materialDocNumberValidationError.length > 0) {
                return true;
            }
        }

        return false;
    }, [lineNumber, lineNumberValidationError.length, materialDocNumber, materialDocNumberValidationError.length, poNumber, poNumberValidationError.length, reversalQuantity, reversalQuantityValidationError.length, selectedReversalType]);

    /**
     * Build table header.
     * @param receipt Reverse purchase order receipt.
     * @returns JSX for table header.
     */
    const buildTableHeader = (receipt: ReversePurchaseOrderReceipt): JSX.Element => {
        return (
            <thead>
                {receipt.reverseReceiptBy === ReverseReceiptType.PurchaseOrderLine && (
                    <tr>
                        <th>PO Number</th>
                        <th>Line Item</th>
                        <th>Reverse Quantity</th>
                        <th>Reversal Status</th>
                        <th>Errors</th>
                    </tr>
                )}
                {receipt.reverseReceiptBy === ReverseReceiptType.MaterialDocumentNumber && (
                    <tr>
                        <th>Material Document Number</th>
                        <th>Reversal Status</th>
                        <th>Errors</th>
                    </tr>
                )}
            </thead>
        );
    };

    /**
     * Build table rows.
     * @param receipts Reverse purchase order receipts.
     * @returns JSX for table rows.
     */
    const buildTableRows = (receipts: ReversePurchaseOrderReceipt[]): JSX.Element => {
        return (
            <tbody>
                {receipts[0].reverseReceiptBy === ReverseReceiptType.PurchaseOrderLine && (
                    <>
                        {receipts.map((x: ReversePurchaseOrderReceipt, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{x.purchaseOrder}</td>
                                    <td>{x.lineItem}</td>
                                    <td>{x.reverseQuantity}</td>
                                    <td>{ReceiptStatusType[x.reversalStatus]}</td>
                                    <td>{x.errors?.map(x => x.message).join('; ')}</td>
                                </tr>
                            );
                        })}
                    </>
                )}
                {receipts[0].reverseReceiptBy === ReverseReceiptType.MaterialDocumentNumber && (
                    <>
                        {receipts.map((x: ReversePurchaseOrderReceipt, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{x.materialDocumentNumber}</td>
                                    <td>{ReceiptStatusType[x.reversalStatus]}</td>
                                    <td>{x.errors?.map(x => x.message).join('; ')}</td>
                                </tr>
                            );
                        })}
                    </>
                )}
            </tbody>
        );
    };

    /**
     * Display results for reversal success and failures.
     * @returns JSX for results.
     */
    const displayResults = (): JSX.Element => {
        if (apiReverseGoodsReceipt.reversePurchaseOrderReceipts) {
            const succeededReceipt: ReversePurchaseOrderReceipt[] = apiReverseGoodsReceipt.reversePurchaseOrderReceipts.filter(response => response.reversalStatus === ReceiptStatusType.Reversed);
            const failedReceipt: ReversePurchaseOrderReceipt[] = apiReverseGoodsReceipt.reversePurchaseOrderReceipts.filter(response => response.reversalStatus === ReceiptStatusType.ErroredOut);
            
            return (
                <>
                    {failedReceipt.length > 0 && (
                        <div>
                            <h3 className={pageStyles.grReversalResultHeading}>Reversal Failures</h3>
                            <table className={pageStyles.grReversalResultTable}>
                                {buildTableHeader(failedReceipt[0])}
                                {buildTableRows(failedReceipt)}
                            </table>
                        </div>
                    )}
                    {succeededReceipt.length > 0 && (
                        <div>
                            <h3 className={pageStyles.grReversalResultHeading}>Reversal Successes</h3>
                            <table className={pageStyles.grReversalResultTable}>
                                {buildTableHeader(succeededReceipt[0])}
                                {buildTableRows(succeededReceipt)}
                            </table>
                        </div>
                    )}
                </>
            );
        }

        return <></>;
    };

    return (
        <>    
            <Separator />

            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="medium">Use this tool to perform a goods receipt reversal in both SAP and in the receipting database.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <Label htmlFor={reversalTypeInputId}>{commonString.reversalType}</Label>
                            <TooltipHost content={tooltips.grReversalComboBox} delay={TooltipDelay.long}>
                                <ComboBox
                                    id={reversalTypeInputId}
                                    ariaLabel={`${commonString.reversalType} ${tooltips.grReversalComboBox}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                    className={pageStyles.comboBox}
                                    options={reversalTypeOptions}
                                    selectedKey={selectedReversalType}
                                    onChange={onChangeReversalType}
                                    useComboBoxAsMenuWidth={true}
                                />
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                {selectedReversalType === ReverseReceiptType.PurchaseOrderLine && (
                    <Stack.Item>
                        <Stack horizontal tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Label htmlFor={poNumberInputId}>{commonString.poNumber}</Label>
                                <TooltipHost content={validationConstants.poNumber.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={poNumberInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.poNumber} ${validationConstants.poNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.textField}
                                        value={poNumber}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.poNumber.maxLength! ||
                                                !RegExp(validationConstants.poNumber.pattern!).test(newValue))) {
                                                setPoNumberValidationError(validationConstants.poNumber.errorMsg!);
                                            } else {
                                                setPoNumberValidationError('');
                                            }
                                            setPoNumber(newValue);
                                        }}
                                        errorMessage={poNumberValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                            <Stack.Item>
                                <Label htmlFor={lineNumberInputId}>{commonString.lineNumber}</Label>
                                <TooltipHost content={validationConstants.lineNumber.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={lineNumberInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.lineNumber} ${validationConstants.lineNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.textField}
                                        value={lineNumber}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.lineNumber.maxLength! ||
                                                !RegExp(validationConstants.lineNumber.pattern!).test(newValue))) {
                                                setLineNumberValidationError(validationConstants.lineNumber.errorMsg!);
                                            } else {
                                                setLineNumberValidationError('');
                                            }
                                            setLineNumber(newValue);
                                        }}
                                        errorMessage={lineNumberValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                            <Stack.Item>
                                <Label htmlFor={reversalQuantityInputId}>{commonString.reversalQuantity}</Label>
                                <TooltipHost content={validationConstants.reversalQuantity.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={reversalQuantityInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.reversalQuantity} ${validationConstants.reversalQuantity.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.textField}
                                        value={reversalQuantity}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.reversalQuantity.maxLength! ||
                                                !RegExp(validationConstants.reversalQuantity.pattern!).test(newValue))) {
                                                setReversalQuantityValidationError(validationConstants.reversalQuantity.errorMsg!);
                                            } else {
                                                setReversalQuantityValidationError('');
                                            }
                                            setReversalQuantity(newValue);
                                        }}
                                        errorMessage={reversalQuantityValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}
                {selectedReversalType === ReverseReceiptType.MaterialDocumentNumber && (
                    <Stack.Item>
                        <Stack horizontal tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Label htmlFor={materialDocNumberInputId}>{commonString.materialDocNumber}</Label>
                                <TooltipHost content={validationConstants.materialDocNumber.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={materialDocNumberInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.materialDocNumber} ${validationConstants.materialDocNumber.tooltip}`}
                                        className={pageStyles.textField}
                                        value={materialDocNumber}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            if (newValue.length > 0 && (newValue.length > validationConstants.materialDocNumber.maxLength! ||
                                                !RegExp(validationConstants.materialDocNumber.pattern!).test(newValue))) {
                                                setMaterialDocNumberValidationError(validationConstants.materialDocNumber.errorMsg!);
                                            } else {
                                                setMaterialDocNumberValidationError('');
                                            }
                                            setMaterialDocNumber(newValue);
                                        }}
                                        errorMessage={materialDocNumberValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <DefaultButton
                        onClick={submitButtonClicked}
                        disabled={isReversalRunning || isInputInvalid}
                    >
                        {isReversalRunning ? (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerGrid} />
                        ) : (
                            <Text>Submit</Text>
                        )}
                    </DefaultButton>
                </Stack.Item>
                {apiReverseGoodsReceipt.reversePurchaseOrderReceipts && apiReverseGoodsReceipt.reversePurchaseOrderReceipts?.length > 0 && (
                    <Stack.Item>
                        {displayResults()}
                    </Stack.Item>
                )}
            </Stack>
        </>
    );
};
