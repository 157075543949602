import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    tile: {
        border: '1px solid lightgray',
        '-webkit-box-shadow': '6px 6px 6px 0px rgb(160 160 160 / 90%)',
        '-moz-box-shadow': '6px 6px 6px 0px rgba(160, 160, 160, 0.90)',
        boxShadow: '1px 1px 1px 0px rgb(160 160 160 / 90%)',
        margin: '2px 2px 2px 0px',
        width: 'fit-content',
        table: {
            borderCollapse: 'collapse'
        } as IStyle
    } as IStyle,
    colorBar: {
        padding: '2px'
    } as IStyle,
    tileContent: {
        padding: '6px'
    } as IStyle
});
