import {
    IStyle,
    mergeStyleSets,
    IStackTokens,
    IComboBoxStyles,
    ITextFieldStyles,
    IStyleFunctionOrObject,
    IChoiceGroupOptionStyleProps,
    IChoiceGroupOptionStyles
} from '@fluentui/react';

/**
 * Common styles used across all pages.
 */
export const commonStyles = mergeStyleSets({
    rootDiv: {
        paddingRight: '30px',
        paddingLeft: '10px'
    } as IStyle,
    dividerLine: {
        width: '100%',
        height: '1px',
        backgroundColor: 'black',
        marginBottom: '20px'
    } as IStyle,
    rowGap: {
        height: '30px'
    } as IStyle,
    spinnerInline: {
        display: 'inline-block',
        margin: '0 12px 0 12px'
    } as IStyle,
    spinnerGrid: {
        display: 'grid',
        margin: '0 12px 0 12px'
    } as IStyle,
    errorText: {
        color: '#e50000'
    } as IStyle,
    headingTextContainer: {
        marginLeft: '12px',
        width: '100%'
    } as IStyle,
    headingText: {
        fontWeight: 'bold'
    } as IStyle,
    boldText: {
        fontWeight: 'bold'
    } as IStyle,
    wordWrapColumnHeader: {
        overflowWrap: 'break-word',
        whiteSpace: 'break-spaces',
        lineHeight: 'normal'
    } as IStyle,
    // Use on a div containing a FontIcon when used in a TooltipHost.
    tooltipFontIconContainer: {
        padding: '2px 2px 2px 2px',
        display: 'flex'
    } as IStyle,
    absoluteCenter: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute'
    } as IStyle,
    sectionHeading: {
        fontWeight: '500'
    } as IStyle,
    // sectionContent can optionally be used under a sectionHeading only if extra margin spacing is needed around the content.
    sectionContent: {
        margin: '4px 0 4px 0'
    } as IStyle,
    expandCollapseIcon: {
        margin: '4px 0 0 0',
        cursor: 'pointer'
    } as IStyle,
    pointer: {
        cursor: 'pointer'
    } as IStyle,
    wrapText: {
        whiteSpace: 'pre-wrap'
    } as IStyle,
    comboBoxLoadSpinnerOuterContainer: {
        position: 'relative',
        width: '170px'
    } as IStyle,
    comboBoxLoadSpinnerInnerContainer: {
        position: 'absolute',
        top: '2px',
        left: '136px' // Positions the spinner over the dropdown arrow, based on above comboBoxLoadSpinnerOuterContainer width.
    } as IStyle,
    comboBoxLoadSpinner: {
        height: '28px',
        width: '32px',
        background: 'white'
    } as IStyle,
    linkIcon: {
        margin: '0 0 0 4px'
    } as IStyle,
    detailsTable: {
        width: '100%',
        margin: '8px 0 0 0',
        borderCollapse: 'collapse',
        'tr': {
            borderTop: '.1px solid lightgray'
        } as IStyle,
        'tr:last-child': {
            borderBottom: '.1px solid lightgray'
        } as IStyle,
        'td:first-child': {
            padding: '0 10px 0 0',
            maxWidth: '130px'
        } as IStyle,
        'td:last-child': {
            whiteSpace: 'pre-wrap',
            overflowWrap: 'anywhere'
        } as IStyle
    } as IStyle,
    detailsSubHeading: {
        fontWeight: 500,
        paddingTop: '20px!important', // Use !important here to override above detailsTable 'td:first-child'.
        textAlign: 'center',
        fontSize: '16px'
    } as IStyle,
    textCell: {
        lineHeight: '32px'
    } as IStyle,
    textCellRightAlign: {
        lineHeight: '32px',
        textAlign: 'right'
    } as IStyle,
    closedIndicator: {
        lineHeight: '32px',
        color: '#e50000'
    } as IStyle,
    statusColumnContainer: {
        position: 'relative'
    } as IStyle,
    requiredFieldStar: {
        color: 'red',
        position: 'relative',
        bottom: '6px',
        margin: '0 4px 0 4px'
    } as IStyle,
    panel: {
        marginTop: '48px',
        position: 'absolute'
    } as IStyle,
    panelOverlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    } as IStyle
});

export const stackTokensLargeGap: IStackTokens = {
    childrenGap: 25
};

export const stackTokensNormalGap: IStackTokens = {
    childrenGap: 15
};

export const stackTokensSmallGap: IStackTokens = {
    childrenGap: 8
};

export const stackTokensTinyGap: IStackTokens = {
    childrenGap: 4
};

/**
 * Styles used for all comboboxes.
 */
export const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
        // Nothing at this time.
    } as IStyle
};

/**
 * Styles used for all textfields.
 */
export const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        marginTop: 2,
        marginLeft: 1,
        padding: 5
    } as IStyle
};

/**
 * Makes a choice group radio buttons to appear horizontally. Normally they are stacked.
 */
export const horizontalChoiceGroupStyle: IStyleFunctionOrObject<IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles> = {
    root: {
        '.ms-ChoiceField': {
            display: 'inline-flex',
            margin: '0 36px 0 0'
        } as IStyle
    } as IStyle
};
