export interface IPriorYearAccrualElevatedSummary {
    totalLineItems: number | undefined;
    totalOpenLineItems: number | undefined;
    totalRetainedLineItems: number | undefined;
    totalClosedLineItems: number | undefined;
    totalOpenAmount: number | undefined;
    totalOpenAccrualAmount: number | undefined;
    totalInvoicedAmount: number | undefined;
}

export class PriorYearAccrualElevatedSummary implements IPriorYearAccrualElevatedSummary {
    public totalLineItems: number | undefined;
    public totalOpenLineItems: number | undefined;
    public totalRetainedLineItems: number | undefined;
    public totalClosedLineItems: number | undefined;
    public totalOpenAmount: number | undefined;
    public totalOpenAccrualAmount: number | undefined;
    public totalInvoicedAmount: number | undefined;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPriorYearAccrualElevatedSummary) {
        this.totalLineItems = jsonData.totalLineItems;
        this.totalOpenLineItems = jsonData.totalOpenLineItems;
        this.totalRetainedLineItems = jsonData.totalRetainedLineItems;
        this.totalClosedLineItems = jsonData.totalClosedLineItems;
        this.totalOpenAmount = jsonData.totalOpenAmount;
        this.totalOpenAccrualAmount = jsonData.totalOpenAccrualAmount;
        this.totalInvoicedAmount = jsonData.totalInvoicedAmount;
    }
}
