import { createShortGuid } from '../../../common/common.func.guid';
import { IKeyValuePair } from '../../../models/utility/keyValuePair';
import { ReportFile } from '../../../models/report/reportFile';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { reportSecurityGroupName } from '../../reducers/pageReducers/reportsPage.reducer';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';

/**
 * Payload used with all callApiSgCheckReportAccess action.
 */
export interface IApiSgCheckReportAccess extends ICallApiBase {
    results?: IKeyValuePair<boolean>;
}

/**
 * Call API (multiple calls to sgCheck api) to check SG membership for reports.
 * @returns Redux dispatch function.
 */
export const callApiSgCheckReportAccess = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<IKeyValuePair<boolean>>(
        actionTypes.API_SGCHECK_REPORTS,
        async (): Promise<IKeyValuePair<boolean>> => {
            const promises: Promise<boolean>[] = [];

            const sgNames: string[] = [
                reportSecurityGroupName.daily1010ReportsSecurityGroup,
                reportSecurityGroupName.dailyNon1010ReportsSecurityGroup,
                reportSecurityGroupName.dailyOpenAccrual1010ReportsSecurityGroup,
                reportSecurityGroupName.dailyOpenAccrualNon1010ReportsSecurityGroup,
                ...reportSecurityGroupName.dailyPriorYearAccrualReportsSecurityGroups,
                reportSecurityGroupName.corUsageSecurityGroup,
                reportSecurityGroupName.gsrUsageSecurityGroup,
                reportSecurityGroupName.gsr1010UsageSecurityGroup,
                reportSecurityGroupName.gsrPoReportSecurityGroup,
                reportSecurityGroupName.goodsReceiptingSummarySecurityGroup,
                reportSecurityGroupName.pyaNotificationsReportSecurityGroup
            ];

            // Some SGs are the same for different reports. Get just the unique ones so ne don't make needless API calls
            // to check the same SG when it was already checked.
            const uniqueSgNames: string[] = [];
            sgNames.forEach(x => {
                if (uniqueSgNames.indexOf(x) === -1) {
                    uniqueSgNames.push(x);
                }
            })

            uniqueSgNames.forEach(name => {
                promises.push(receiptingApiClient.sgCheck(name));
            });

            const results: boolean[] = await Promise.all(promises);

            // Put results into a key value pair, where the key is the SG name.
            const result: IKeyValuePair<boolean> = {};
            sgNames.forEach((name) => {
                const uniqueSgIndex: number = uniqueSgNames.indexOf(name);
                result[name] = results[uniqueSgIndex];
            });

            return result;
        },
        (payload: IApiSgCheckReportAccess, data: IKeyValuePair<boolean> | null) => {
            payload.results = data || undefined;
        }
    );
};

/**
 * Payload used with callApiDaily1010Reports action.
 */
export interface IApiDaily1010Reports extends ICallApiBase {
    reportFiles?: ReportFile[] | null;
}

/**
 * Call API to get daily 1010 reports.
 * @param startDate Start date.
 * @param endDate End date.
 * @returns Redux dispatch function.
 */
export const callApiDaily1010Reports = (startDate: Date, endDate: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ReportFile[] | null>(
        actionTypes.API_DAILY_1010_REPORTS,
        async () => {
            return await receiptingApiClient.daily1010Reports(startDate, endDate);
        },
        (payload: IApiDaily1010Reports, data: ReportFile[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: ReportFile) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.reportFiles = data;
        }
    );
};

/**
 * Payload used with callApiDailyNon1010Reports action.
 */
export interface IApiDailyNon1010Reports extends ICallApiBase {
    reportFiles?: ReportFile[] | null;
}

/**
 * Call API to get daily non-1010 reports.
 * @param startDate Start date.
 * @param endDate End date.
 * @returns Redux dispatch function.
 */
export const callApiDailyNon1010Reports = (startDate: Date, endDate: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ReportFile[] | null>(
        actionTypes.API_DAILY_NON_1010_REPORTS,
        async () => {
            return await receiptingApiClient.dailyNon1010Reports(startDate, endDate);
        },
        (payload: IApiDailyNon1010Reports, data: ReportFile[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: ReportFile) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.reportFiles = data;
        }
    );
};

/**
 * Payload used with callApiDailyOpenAccrual1010Reports action.
 */
export interface IApiDailyOpenAccrual1010Reports extends ICallApiBase {
    reportFiles?: ReportFile[] | null;
}

/**
 * Call API to get daily open accrual 1010 reports.
 * @param startDate Start date.
 * @param endDate End date.
 * @returns Redux dispatch function.
 */
export const callApiDailyOpenAccrual1010Reports = (startDate: Date, endDate: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ReportFile[] | null>(
        actionTypes.API_DAILY_OPEN_ACCRUAL_1010_REPORTS,
        async () => {
            return await receiptingApiClient.dailyOpenAccrual1010Reports(startDate, endDate);
        },
        (payload: IApiDailyOpenAccrual1010Reports, data: ReportFile[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: ReportFile) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.reportFiles = data;
        }
    );
};

/**
 * Payload used with callApiDailyOpenAccrualNon1010Reports action.
 */
export interface IApiDailyOpenAccrualNon1010Reports extends ICallApiBase {
    reportFiles?: ReportFile[] | null;
}

/**
 * Call API to get daily open accrual non-1010 reports.
 * @param startDate Start date.
 * @param endDate End date.
 * @returns Redux dispatch function.
 */
export const callApiDailyOpenAccrualNon1010Reports = (startDate: Date, endDate: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ReportFile[] | null>(
        actionTypes.API_DAILY_OPEN_ACCRUAL_NON_1010_REPORTS,
        async () => {
            return await receiptingApiClient.dailyOpenAccrualNon1010Reports(startDate, endDate);
        },
        (payload: IApiDailyOpenAccrualNon1010Reports, data: ReportFile[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: ReportFile) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.reportFiles = data;
        }
    );
};

/**
 * Payload used with callApiDailyPriorYearAccrualReports action.
 */
export interface IApiDailyPriorYearAccrualReports extends ICallApiBase {
    reportFiles?: ReportFile[] | null;
}

/**
 * Call API to get daily open accrual non-1010 reports.
 * @param startDate Start date.
 * @param endDate End date.
 * @returns Redux dispatch function.
 */
export const callApiDailyPriorYearAccrualReports = (startDate: Date, endDate: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ReportFile[] | null>(
        actionTypes.API_DAILY_PRIOR_YEAR_ACCRUAL_REPORTS,
        async () => {
            return await receiptingApiClient.dailyPriorYearAccrualReports(startDate, endDate);
        },
        (payload: IApiDailyPriorYearAccrualReports, data: ReportFile[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: ReportFile) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.reportFiles = data;
        }
    );
};
