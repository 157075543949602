import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { DefaultButton, IPanel, Panel, PanelType, Stack } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import { getAccessTokenForReceipting } from '../../services/auth/msalHelper';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import * as signalR from '@microsoft/signalr';
import { appConfig } from '../appConfig';
import { callApiDismissAllNotifications, callApiExistingNotifications, IApiDismissAllNotifications, IApiDismissNotification, IApiExistingNotifications, upsertNotificationItem } from '../../store/actions/app.action';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { NotificationPanelItem } from './NotificationPanelItem';
import { ClientNotificationData, IClientNotificationData } from '../../models/clientNotification/clientNotificationData';
import { CallApiState } from '../../store/actions/generic.action';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';
import { commonStyles } from '../../common/common.styles';

interface INotificationPanelProps {
    componentRef?: RefObject<IPanel>;
    isOpen: boolean;
    onDismiss: () => void;
}

export const NotificationPanel: React.FunctionComponent<INotificationPanelProps> = (props: INotificationPanelProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const itemCount = useRef<number>(0);

    // Redux store selectors to get state from the store when it changes.
    const apiExistingNotifications: IApiExistingNotifications | undefined =
        useAppSelector<IApiExistingNotifications>((state) => state.appReducer.apiExistingNotifications);
    const apiDismissNotification: IApiDismissNotification | undefined =
        useAppSelector<IApiDismissNotification>((state) => state.appReducer.apiDismissNotification);    
    const apiDismissAllNotifications: IApiDismissAllNotifications | undefined =
        useAppSelector<IApiDismissAllNotifications>((state) => state.appReducer.apiDismissAllNotifications);
    const clientNotificationItems: ClientNotificationItem[] =
        useAppSelector<ClientNotificationItem[]>((state) => state.appReducer.clientNotificationItems);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const hubConnection = useRef<signalR.HubConnection | null>(null);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiExistingNotifications.errMsg) {
            handleError(apiExistingNotifications.errMsg);
        }
        if (apiDismissNotification.errMsg) {
            handleError(apiDismissNotification.errMsg);
        }
        if (apiDismissAllNotifications.errMsg) {
            handleError(apiDismissAllNotifications.errMsg);
        }
    }, [apiDismissAllNotifications.errMsg, apiDismissNotification.errMsg, apiExistingNotifications.errMsg, handleError]);

    /**
     * Effect for when the client notification items change.
     * This effect is to work around an issue with the sticky panel footer.
     */
    useEffect(() => {
        if (clientNotificationItems.length !== itemCount.current) {
            itemCount.current = clientNotificationItems.length;

            // The sticky footer in the panel will not render the sticky footer if new items appear in the list
            // which would normally cause the sticky footer styling to work. Manually fire a resize event which
            // fixes this problem.
            window.dispatchEvent(new Event('resize'));
        }
    }, [clientNotificationItems.length]);

    /**
     * Connect to SignalR hub.
     */
    const connectHub = async () => {
        try {
            // Cache buster. This cb querystring param is ignored on the server side. It is intended to make
            // the URL unique always so that the local browser cache is not used for the connection.
            const cb: string = Math.random().toString();

            // Construct hub url.
            const url = `${appConfig.current.signalR.clientNotificationHub}?cb=${cb}`;

            hubConnection.current = new signalR.HubConnectionBuilder()
                .withUrl(url, {
                    accessTokenFactory: async () => {
                        // Acquire access token for receipting api. This token will be added to the Authorization header of the negotiate api call.
                        const token: string = await getAccessTokenForReceipting();
                        return token;
                    }
                })
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: () => {
                        return 1000 * 20; // Retry connection every 20 seconds.
                    }
                })
                .build();
                    
            // Listen for these calls from the server side hub.
            hubConnection.current.on('SendClientNotification', (data: IClientNotificationData) => {
                const clientNotificationData = new ClientNotificationData(data);
                if (clientNotificationData && clientNotificationData.items) {
                    clientNotificationData.items.forEach(item => {
                        dispatch(upsertNotificationItem(item));
                    });
                }
            });

            await hubConnection.current.start();
        } catch (err: any) {
            console.error(err);
        }
    };

    /**
     * Log the SignalR hub connection state.
     * @returns SignalR hub connection state.
     */
    const logConnectionState = (): void => {
        const state: signalR.HubConnectionState | undefined = hubConnection.current?.state;
        console.log(`SignalR ${state} ${hubConnection.current?.baseUrl}`)
    };

    /**
     * This effect is run once during component load.
     */
    useMountEffect(() => {
        (async () => {
            // Get existing notifications during app load. All these existing notifications will be marked as pre-existing.
            dispatch(callApiExistingNotifications(true));

            // Start an intrval loop to get existing notifications every 30 seconds.
            // This is a fallback to the SignalR push mechanism. Sometimes the SignalR connection fails
            // or disconnects and cannot reconnect. This will ensure the client gets notifications.
            setInterval(() => {
                dispatch(callApiExistingNotifications(false));
            }, 1000 * 30);

            // Connect to SignalR hub. Notifications will be pushed from the server to the client.
            if (!appConfig.current.service.useLocalMockData) {
                await connectHub();
                logConnectionState();
            }

            // When using mock data. This simulates new notification items coming in.
            /* Keeping code but commented out in case it is needed for testing.
            if (appConfig.current.service.useLocalMockData) {
                let n: number = 1;
                const intervalId = setInterval(() => {
                    dispatch(upsertNotificationItem({
                        id: `test${n}`,
                        heading: `Test notification ${n}`,
                        text: 'This is a test notification.',
                        notificationItemState: ClientNotificationItemState.Succeeded,
                        createDateUtc: new Date(),
                        updatedDateUtc: new Date(),
                        clientNotificationItemProcessedActionType: 2,
                        isPreExisting: false
                    }));
                    n += 1;
                    if (n > 5) {
                        clearInterval(intervalId);
                    }
                }, 2000);
            }
            */
        })();
    });

    return (
        <Panel
            componentRef={props.componentRef}
            headerText="Notifications"
            isOpen={props.isOpen}
            onDismiss={() => {
                props.onDismiss();
            }}
            closeButtonAriaLabel="Close"
            overlayProps={{
                className: commonStyles.panelOverlay
            }}
            className={commonStyles.panel}
            type={PanelType.custom}
            customWidth="460px"
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return (
                    <Stack>
                        <Stack.Item align='end'>
                            <DefaultButton
                                split
                                splitButtonAriaLabel="See options"
                                aria-roledescription="split button"
                                menuProps={{
                                    useTargetWidth: true,
                                    items: [
                                        {
                                            key: 'Succeeded',
                                            text: 'Dismiss succeeded',
                                            onClick: () => {
                                                dispatch(callApiDismissAllNotifications(ClientNotificationItemState.Succeeded));
                                            }
                                        }
                                    ]
                                }}
                                text='Dismiss all'
                                disabled={apiDismissAllNotifications.callApiState === CallApiState.Running ||
                                    apiDismissNotification.callApiState === CallApiState.Running}
                                onClick={() => {
                                    telemetryService.trackEvent({ name: trackedEvent.dismissAllNotificationsButtonClicked });
                                    dispatch(callApiDismissAllNotifications());
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                )
            }}
        >
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />
            <Stack>
                {
                    // Sort the notification items by the date the item was created - descending so the newest
                    // appears on top. For example when the item was created it might have a state of processing. Later
                    // it might change to succeeded. Using the create date will keep the order of the items in the list
                    // consistent, rather than jumping around in position. If for some reason an create date is not
                    // present on the item, then fall back to use the updated date.
                    clientNotificationItems.sort((a, b) => {
                        return (a.createDateUtc || a.updatedDateUtc) > (b.createDateUtc || b.updatedDateUtc) ? -1 : 1;
                    }).map((item, index) => {
                        return (
                            <Stack.Item key={index}>
                                <NotificationPanelItem item={item}/>
                            </Stack.Item>
                        );
                    })
                }
            </Stack>
        </Panel>
    );
};
