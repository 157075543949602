import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { UserDelegate } from '../../models/user/userDelegate';
import { GraphUserInput } from '../../components/GraphUserInput/GraphUserInput';
import { editDelegateTo, editDelegateToValid } from '../../store/actions/pageActions/userProfilePage.action';
import { GraphUser } from '../../models/user/graphUser';
import { AppDispatch } from '../../store/reduxStore';
import { getUserAlias } from '../../services/auth/msalHelper';

interface IDelegateToAliasInputProps {
    item: UserDelegate;

    /**
     * Optional input id. If not supplied then one will be generated.
     */
    inputId?: string;
}

export const DelegateToAliasInput: React.FunctionComponent<IDelegateToAliasInputProps> = (props: IDelegateToAliasInputProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <GraphUserInput
            required={true}
            initialSelectedAlias={props.item.alias}
            onChange={(graphUser: GraphUser | undefined) => {
                if (graphUser && graphUser.alias && graphUser.id) {
                    // If there is a graph user passed in the onChange.
                    // Only dispatch the change for the delegate if the alias or id are different from before.
                    if (props.item.alias !== graphUser.alias || props.item.aadOid !== graphUser.id) {
                        dispatch(editDelegateTo(props.item.clientRowKey!, graphUser.alias, graphUser.id));
                    }
                }
            }}
            onInputValidation={(msg: string, isValid: boolean) => {
                // Only dispatch the change if the valid state changed from what it was before.
                if (props.item.isValid !== isValid) {
                    dispatch(editDelegateToValid(props.item.clientRowKey!, isValid));
                }
            }}
            illegalAlias={getUserAlias()}
            illegalAliasErrorMessage="Cannot delegate to self"
            inputId={props.inputId}
        />
    );
};
