import { Role } from '../../common/appEnums';
import { RoleScope } from './roleScope';

export interface IUserRole {
    name: Role;
    scope?: RoleScope;
}

export class UserRole implements IUserRole {
    public name: Role;
    public scope?: RoleScope;

    // Below fields are not part of the server returned object, but are added for client side support.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IUserRole) {
        this.name = jsonData.name;

        if (jsonData.scope) {
            this.scope = new RoleScope(jsonData.scope);
        }
    }
}
