import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';

// The search results page also makes use of search.action.tsx and search.reducer.tsx for common search
// functionality used across multiple pages.

export interface ISearchResultsPageReducerState {
    editPoNumber: string | undefined;
}

const initialSearchResultsPageReducerState: ISearchResultsPageReducerState = {
    editPoNumber: undefined
}

export const searchResultsPageReducer: Reducer<ISearchResultsPageReducerState, AnyAction> = (
    state: ISearchResultsPageReducerState = initialSearchResultsPageReducerState, action: AnyAction
): ISearchResultsPageReducerState => {
    switch (action.type) {
        case actionTypes.EDIT_PO: {
            return {
                ...state,
                editPoNumber: action.poNumber
            } as ISearchResultsPageReducerState
        }
        default:
    }

    return state;
};
