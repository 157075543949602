import React from 'react';
import { IColumn, IDetailsColumnProps } from '@fluentui/react';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { EditActionButton } from '../../components/EditActionButton/EditActionButton';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { tooltips } from '../../common/tooltips';

export const headerColumns: IColumn[] = [
    {
        key: 'purchaseOrderNumber',
        fieldName: 'purchaseOrderNumber',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='PO'
                    tooltip={tooltips.clickToViewPo}
                />
            );
        },
        isResizable: false,
        minWidth: 76,
        onRender: (item: PurchaseOrderHeader, index?: number) => {
            return (
                <EditActionButton poHeader={item} index={index} enableTeachingBubble={true} />
            );
        }
    },
    {
        key: 'projectName',
        fieldName: 'projectName',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Title'
                    tooltip={tooltips.poTitle}
                />
            );
        },
        isResizable: true,
        minWidth: 200
    },
    {
        key: 'supplierNameNumberCombo',
        fieldName: 'supplierNameNumberCombo',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Supplier'
                    tooltip={tooltips.supplierName}
                />
            );
        },
        isResizable: true,
        minWidth: 200
    },
    {
        key: 'purchaseOrderRequestor',
        fieldName: 'purchaseOrderRequestor',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Owner'
                    tooltip={tooltips.poOwner}
                />
            );
        },
        isResizable: true,
        minWidth: 110
    },
    {
        key: 'invoiceApprover',
        fieldName: 'invoiceApprover',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Invoice'
                    headerLine2='Approver'
                    tooltip={tooltips.invoiceApprover}
                />
            );
        },
        isResizable: true,
        minWidth: 110
    },
    {
        key: 'companyCode',
        fieldName: 'companyCode',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Company'
                    headerLine2='Code'
                    tooltip={tooltips.companyCode}
                />
            );
        },
        isResizable: false,
        minWidth: 110
    }
];
