import React, { useEffect } from 'react';
import { componentStyles } from './AppHeader.styles';
import { currentPstDate } from '../../common/common.func.datetime';
import { TooltipHost } from '@fluentui/react';

export interface IPstTimeDisplayProps {
}

export const PstTimeDisplay: React.FunctionComponent<IPstTimeDisplayProps> = (props: IPstTimeDisplayProps): JSX.Element => {
    const [pstTime, setPstTime] = React.useState<Date>(currentPstDate());

    /**
     * Effect to update the PST time every second.
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setPstTime(currentPstDate());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <TooltipHost content="Receipting and accrual processes in GSR follow Pacific Standard Time (PST). Key functionalities, including fiscal period and year boundaries, are determined by the current PST time.">
            <div className={componentStyles.pstTimeDisplay}>
                <div className={componentStyles.pstTimeDisplayLabel}>PST Time</div>
                <div className={componentStyles.pstTimeDisplayDateTime}>{pstTime.toLocaleString()}</div>
            </div>
        </TooltipHost>
    );
};
