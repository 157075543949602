import { ClientNotificationItemProcessedActionType } from './clientNotificationItemProcessedActionType';
import { ClientNotificationItemState } from './clientNotificationItemState';

export interface IClientNotificationItem {
    id: string;
    heading: string;
    text: string;
    notificationItemState: ClientNotificationItemState;
    createDateUtc: Date;
    updatedDateUtc: Date;
    clientNotificationItemProcessedActionType: ClientNotificationItemProcessedActionType
}

export class ClientNotificationItem implements IClientNotificationItem {
    public id: string;
    public heading: string;
    public text: string;
    public notificationItemState: ClientNotificationItemState;
    public createDateUtc: Date;
    public updatedDateUtc: Date;
    public clientNotificationItemProcessedActionType: ClientNotificationItemProcessedActionType;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public isPreExisting: boolean = false;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IClientNotificationItem) {
        this.id = jsonData.id;
        this.heading = jsonData.heading;
        this.text = jsonData.text;
        this.notificationItemState = jsonData.notificationItemState;
        this.createDateUtc = new Date(jsonData.createDateUtc);
        this.updatedDateUtc = new Date(jsonData.updatedDateUtc);
        this.clientNotificationItemProcessedActionType = jsonData.clientNotificationItemProcessedActionType;
    }
}
