import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    Label,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { pageStyles } from './AccrualDashboardPage.styles';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { validationConstants } from '../../common/validationConstants';
import { commonString } from '../../common/commonString';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { callApiAccrualOutOfSync, IApiAccrualOutOfSync } from '../../store/actions/pageActions/accrualDashboardPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { PurchaseOrderLineReceiptsAndAccruals } from '../../models/accrualDashboard/purchaseOrderLineReceiptsAndAccruals';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { accrualsOutOfSyncColumns } from './accrualOutOfSyncColumns';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';

interface IAccrualOutOfSyncTabProps {
}

export const AccrualOutOfSyncTab: React.FunctionComponent<IAccrualOutOfSyncTabProps> = (props: IAccrualOutOfSyncTabProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    
    const companyCodeInputId: string = useId();
    const poNumberInputId: string = useId();

    const [filterPoNumber, setFilterPoNumber] = useState<string>('');
    const [filterCompanyCode, setFilterCompanyCode] = useState<string>('');

    const [poNumberValidationError, setPoNumberValidationError] = useState<string>('');
    const [companyCodeValidationError, setCompanyCodeValidationError] = useState<string>('');

    const [filteredItems, setFilteredItems] = useState<PurchaseOrderLineReceiptsAndAccruals[]>([]);

    // Redux store selectors to get state from the store when it changes.
    const apiAccrualsOutOfSync: IApiAccrualOutOfSync =
        useAppSelector<IApiAccrualOutOfSync>(state => state.accrualDashboardPageReducer.apiAccrualOutOfSync);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Only if not yet loaded, then call the api to load. Otherwise already cached data in Redux will be used.
        if (apiAccrualsOutOfSync.callApiState === CallApiState.Initial) {
            dispatch(callApiAccrualOutOfSync());
        }
    });

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiAccrualsOutOfSync.errMsg) {
            handleError(apiAccrualsOutOfSync.errMsg);
        }
    }, [apiAccrualsOutOfSync.errMsg, handleError]);

    /**
     * Effect for when the API to get accruals out of sync returns data.
     */
    useEffect(() => {
        setFilteredItems([...(apiAccrualsOutOfSync.poLineReceiptAccrualDetail?.poLineReceiptsAndAccruals || [])]);
    }, [apiAccrualsOutOfSync.poLineReceiptAccrualDetail?.poLineReceiptsAndAccruals])

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (apiAccrualsOutOfSync.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiAccrualsOutOfSync.callApiState]);

    /**
     * Filter button clicked event handler.
     */
    const filterButtonClicked = () => {
        // Initially set the new filtered items to be the original data from the api.
        let newFilteredItems: PurchaseOrderLineReceiptsAndAccruals[] = [...(apiAccrualsOutOfSync.poLineReceiptAccrualDetail?.poLineReceiptsAndAccruals || [])];

        // Filter it out based on inputs.
        if (!filterCompanyCode && filterPoNumber) {
            newFilteredItems = newFilteredItems.filter(item =>
                item.purchaseOrderNumber === Number(filterPoNumber));
        } else if (filterCompanyCode && !filterPoNumber) {
            newFilteredItems = newFilteredItems.filter(item =>
                item.companyCode === filterCompanyCode);
        } else if (filterPoNumber && filterCompanyCode) {
            newFilteredItems = newFilteredItems.filter(item =>
                item.purchaseOrderNumber === Number(filterPoNumber) &&
                item.companyCode === filterCompanyCode);
        }

        setFilteredItems(newFilteredItems);
    };

    /**
     * Refresh button clicked event handler.
     */
    const refreshButtonClicked = () => {
        dispatch(callApiAccrualOutOfSync());
    }

    return (
        <Stack tokens={stackTokensNormalGap}>
            <Stack.Item>
                <Separator />

                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <Text variant="mediumPlus" className={commonStyles.sectionHeading} role="heading" aria-level={1}>Accruals out of sync with receipts</Text>
            </Stack.Item>
            <Stack.Item>
                <>
                    <Text block>This includes purchase order lines which have one of the following conditions:</Text>
                    <ol>
                        <li>
                            Failed activity creation step.
                        </li>
                        <li>
                            Has activities which are either failed or in queue for processing.
                        </li>
                    </ol>
                    <Text block>Last refresh: {
                        apiAccrualsOutOfSync.callApiState === CallApiState.Running ? (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        ) : (
                            <>
                                {
                                    apiAccrualsOutOfSync.poLineReceiptAccrualDetail?.lastRefreshTime ?
                                        formatDateUsingLocale(apiAccrualsOutOfSync.poLineReceiptAccrualDetail?.lastRefreshTime) : ''
                                }
                            </>
                        )
                    }
                    </Text>
                </>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                <Stack.Item>
                        <Label htmlFor={poNumberInputId}>{commonString.poNumber}</Label>
                        <TooltipHost content={validationConstants.poNumber.tooltip} delay={TooltipDelay.long}>
                            <TextField
                                id={poNumberInputId}
                                autoComplete='off'
                                ariaLabel={`${commonString.poNumber} ${validationConstants.poNumber.tooltip}`}
                                className={pageStyles.filterTextField}
                                value={filterPoNumber}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    newValue = newValue || '';
                                    newValue = newValue.trim();
                                    if (newValue.length > 0 && (newValue.length > validationConstants.poNumber.maxLength! ||
                                        !RegExp(validationConstants.poNumber.pattern!).test(newValue))) {
                                        setPoNumberValidationError(validationConstants.poNumber.errorMsg!);
                                    } else {
                                        setPoNumberValidationError('');
                                    }
                                    setFilterPoNumber(newValue);
                                }}
                                errorMessage={poNumberValidationError}
                                disabled={isSearchRunning}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <Label htmlFor={poNumberInputId}>{commonString.companyCode}</Label>
                        <TooltipHost content={validationConstants.companyCode.tooltip} delay={TooltipDelay.long}>
                            <TextField
                                id={companyCodeInputId}
                                autoComplete='off'
                                ariaLabel={`${commonString.companyCode} ${validationConstants.companyCode.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                className={pageStyles.filterTextField}
                                value={filterCompanyCode}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    newValue = newValue || '';
                                    newValue = newValue.trim();
                                    if (newValue.length > 0 && (newValue.length > validationConstants.companyCode.maxLength! ||
                                        !RegExp(validationConstants.companyCode.pattern!).test(newValue))) {
                                        setCompanyCodeValidationError(validationConstants.companyCode.errorMsg!);
                                    } else {
                                        setCompanyCodeValidationError('');
                                    }
                                    setFilterCompanyCode(newValue);
                                }}
                                errorMessage={companyCodeValidationError}
                                disabled={isSearchRunning}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            className={pageStyles.filterButton}
                            text="Filter"
                            onClick={filterButtonClicked}
                            disabled={isSearchRunning}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            className={pageStyles.refreshButton}
                            text="Refresh"
                            onClick={refreshButtonClicked}
                            disabled={isSearchRunning}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <CustomDetailsList
                    id="accrualsOutOfSyncDetailsList"
                    ariaLabelForGrid="Accruals out of sync"
                    displayTotalItems={false}
                    showPaginator={false}
                    showPageSize={false}
                    items={filteredItems}
                    isLoading={apiAccrualsOutOfSync.callApiState === CallApiState.Running}
                    compact={false}
                    columns={accrualsOutOfSyncColumns}
                    selectionMode={SelectionMode.none}
                    getKey={(item: PurchaseOrderLineReceiptsAndAccruals) => item.clientRowKey!}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    isHeaderVisible={true}
                    constrainMode={ConstrainMode.horizontalConstrained}
                    onRenderRow={onCustomRenderRow}
                    useScrollablePane={true}
                    onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                        if (detailsHeaderProps) {
                            return (
                                <Sticky stickyPosition={StickyPositionType.Header}>
                                    {defaultRender!({ ...detailsHeaderProps })}
                                </Sticky>
                            );
                        } else {
                            return null;
                        }
                    }}
                />
            </Stack.Item>
        </Stack>
    );
};
