import React, { useState } from 'react';
import { DefaultButton, Separator, Stack, Text } from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { appConfig } from '../../shell/appConfig';
import PrettyJSON from 'react-prettify-json';
import { getMsalInstance, getAccessTokenForGraph, getAccessTokenForReceipting, getAccessTokenForShipment } from '../../services/auth/msalHelper';
import { pageStyles } from './DiagnosticsPage.styles';
import { AccountInfo } from '@azure/msal-browser';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shipmentApiClient } from '../../services/api/shipmentApiClient';

export const DiagnosticsPage: React.FunctionComponent<ICommonPageProps> = (props: ICommonPageProps): JSX.Element => {
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [graphAccessToken, setGraphAccessToken] = useState<string>();
    const [receiptingAccessToken, setReceiptingAccessToken] = useState<string>();
    const [shipmentAccessToken, setShipmentAccessToken] = useState<string>();

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        (async () => {
            setAccountInfo(getMsalInstance().getActiveAccount());

            let graphAccessToken = '';
            try {
                graphAccessToken = await getAccessTokenForGraph();
            } catch (error) {
                graphAccessToken = 'Error getting token';
            }
            setGraphAccessToken(graphAccessToken);

            let receiptingAccessToken = '';
            try {
                receiptingAccessToken = await getAccessTokenForReceipting();
            } catch (error) {
                receiptingAccessToken = 'Error getting token';
            }
            setReceiptingAccessToken(receiptingAccessToken);

            let shipmentAccessToken = '';
            try {
                // For the Shipment API, also make a call to the Ping API. It is only needed to call Ping on the Shipment API to cause the
                // access token to be validated by MISE in the API code. The Receipting API access token is always used because during app
                // startup, the Receipting API is called (many API's, starting with the user profile API). The Shipment API is only called
                // when the user clicks the shipment truck icon for a PO line item. The issue is that when a developer visits this Diagnostics
                // page to get an access token, possibly only for the Receipting API, the Shipment API access token is never used and therefore
                // not validated by MISE. This causes an issue in S360 for unused and unvalidated access tokens.
                await shipmentApiClient.ping();
                // The next call to get an access token for Shipment API will use the cached token acquired from the above call to ping.
                shipmentAccessToken = await getAccessTokenForShipment();
            } catch (error) {
                shipmentAccessToken = 'Error getting token';
            }
            setShipmentAccessToken(shipmentAccessToken);
        })();
    });

    /**
     * Returns a JSX.Element that uses PrettyJSON to display any JSON object.
     * @param jsonObj Any JSON object.
     */
    const jsonBlock: (jsonObj: any) => JSX.Element = (jsonObj) => {
        return (
            <div className={pageStyles.jsonBlock}>
                <PrettyJSON jsonObject={jsonObj} colors={{ punctuation: 'gray', key: 'teal', value: '#727272', string: '#727272' }}/>
            </div>
        );
    };

    /**
     * Returns a JSX.Element that contains a link to jwt.ms for the token.
     * @param jwt JWT token.
     */
    const jwtLink: (jwt: string | undefined) => JSX.Element = (jwt) => {
        if (!jwt) {
            return <></>;
        }
        return (
            <div className={pageStyles.jwtMsLinkContainer}>
                <a rel="noreferrer noopener" href={'https://jwt.ms/#access_token=' + jwt} target="_blank">Examine token on jwt.ms</a>
            </div>
        );
    };

    /**
     * Copy to clipboard button.
     */
    const copyToClipboardButton: (textToCopy: string | undefined) => JSX.Element = (textToCopy) => {
        if (!textToCopy) {
            return <></>;
        }
        return (
            <CopyToClipboard text={textToCopy}>
                <DefaultButton className={pageStyles.copyToClipboardButton}>Copy to clipboard</DefaultButton>
            </CopyToClipboard>
        );
    };

    return (
        <PageWrapper {...props}>
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={1}>Diagnostics</Text>
                </Stack.Item>
                <Stack.Item>
                    <table className={pageStyles.diagnosticDisplay} role="presentation">
                        <tbody>
                            <tr>
                                <td>NodeJS process environment variables during build</td>
                                <td tabIndex={0}>
                                    {jsonBlock(process.env)}
                                </td>
                            </tr>
                            <tr>
                                <td>App config</td>
                                <td tabIndex={0}>
                                    {jsonBlock(appConfig.current)}
                                </td>
                            </tr>
                            <tr>
                                <td>Account info</td>
                                <td tabIndex={0}>
                                    {jsonBlock(accountInfo)}
                                </td>
                            </tr>
                            <tr>
                                <td>Access token for Graph</td>
                                <td tabIndex={0}>
                                    <div className={pageStyles.jwtDisplay}>
                                        {graphAccessToken}
                                    </div>
                                    <Stack horizontal tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            {jwtLink(graphAccessToken)}
                                        </Stack.Item>
                                        <Stack.Item>
                                            {copyToClipboardButton(graphAccessToken)}
                                        </Stack.Item>
                                    </Stack>
                                </td>
                            </tr>
                            <tr>
                                <td>Access token for Receipting API</td>
                                <td tabIndex={0}>
                                    <div className={pageStyles.jwtDisplay}>
                                        {receiptingAccessToken}
                                    </div>
                                    <Stack horizontal tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            {jwtLink(receiptingAccessToken)}
                                        </Stack.Item>
                                        <Stack.Item>
                                            {copyToClipboardButton(receiptingAccessToken)}
                                        </Stack.Item>
                                    </Stack>
                                </td>
                            </tr>
                            <tr>
                                <td>Access token for Shipment API</td>
                                <td tabIndex={0}>
                                    <div className={pageStyles.jwtDisplay}>
                                        {shipmentAccessToken}
                                    </div>
                                    <Stack horizontal tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            {jwtLink(shipmentAccessToken)}
                                        </Stack.Item>
                                        <Stack.Item>
                                            {copyToClipboardButton(shipmentAccessToken)}
                                        </Stack.Item>
                                    </Stack>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Stack.Item>
            </Stack>
        </PageWrapper>
    );
};
