import {
    ApplicationInsights,
    IMetricTelemetry,
    IExceptionTelemetry,
    IEventTelemetry,
    ITraceTelemetry
} from '@microsoft/applicationinsights-web';
import { reduxStore } from '../../store/reduxStore';
import { UserProfile } from '../../models/user/userProfile';

class TelemetryService {
    private appInsights: ApplicationInsights;
    private logPrefix: string = 'UI: ';

    // A flag to determine whether or not to send telemetry to dev console.
    // If true, the no logging will be done to Application Insights.
    private logToConsole: boolean = process.env.NODE_ENV === 'development';

    constructor() {
        this.appInsights = {} as ApplicationInsights;
    }

    /**
     * Initializes the TelemetryService.
     * @param appInsightsInstrumentationKey App insights instrumentation key.
     */
    public initialize(appInsightsInstrumentationKey: string) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: appInsightsInstrumentationKey
            }
        });

        this.appInsights.loadAppInsights();
    }

    /**
     * Log metric.
     * Typically used to send regular reports of performance indicators.
     * @param metric A string that identifies the metric.
     * @param customProperties Additional properties to send with the metric.
     */
    public trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: unknown }) { 
        // Add the prefix to the name.
        if (!metric.name.startsWith(this.logPrefix)) {
            metric.name = `${this.logPrefix}${metric.name}`
        }

        customProperties = this.addUserInfoToProperties(customProperties);

        if (this.logToConsole) {
            this.consoleLog(`Metric: ${JSON.stringify(metric)}, Custom Properties: ${JSON.stringify(customProperties)}`);
        } else {
            this.appInsights.trackMetric(metric, customProperties);
        }
    }

    /**
     * Log an exception you have caught.
     * @param exceptionTelemetry The exception to log.
     * @param customProperties Optional additional properties on the event.
     */
    public trackException(exceptionTelemetry: IExceptionTelemetry, customProperties?: { [key: string]: unknown }) {
        // If there is an exception with a message, then add the prefix to the exception message.
        if (exceptionTelemetry.exception?.message && !exceptionTelemetry.exception?.message.startsWith(this.logPrefix)) {
            exceptionTelemetry.exception.message = `${this.logPrefix}${exceptionTelemetry.exception.message}`
        }

        customProperties = this.addUserInfoToProperties(customProperties);

        if (this.logToConsole) {
            this.consoleLog(`Exception: ${JSON.stringify(exceptionTelemetry)}, Custom Properties: ${JSON.stringify(customProperties)}`);
        } else {
            this.appInsights.trackException(exceptionTelemetry, customProperties);
        }
    }

    /**
     * Log a user action or other occurrence.
     * @param event The telemetry event to send.
     * @param customProperties Optional additional properties on the event.
     */
    public trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: unknown }) {    
        // Add the prefix to the name.
        if (!event.name.startsWith(this.logPrefix)) {
            event.name = `${this.logPrefix}${event.name}`
        }

        customProperties = this.addUserInfoToProperties(customProperties);

        if (this.logToConsole) {
            this.consoleLog(`Event: ${JSON.stringify(event)}, Custom Properties: ${JSON.stringify(customProperties)}`);
        } else {
            this.appInsights.trackEvent(event, customProperties);
        }
    }

    /**
     * Log a diagnostic message.
     * @param trace A diagnostic trace to log.
     * @param customProperties Optional additional properties.
     */
    public trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: unknown }) {
        // Add the prefix to the name.
        if (!trace.message.startsWith(this.logPrefix)) {
            trace.message = `${this.logPrefix}${trace.message}`
        }

        customProperties = this.addUserInfoToProperties(customProperties);

        if (this.logToConsole) {
            this.consoleLog(`Trace: ${JSON.stringify(trace)}, Custom Properties: ${JSON.stringify(customProperties)}`);
        } else {
            this.appInsights.trackTrace(trace, customProperties);
        }
    }

    /**
     * Send telemetry data immediately.
     */
    public sendTelemetryNow() {
        this.appInsights.flush();
    }

    /**
     * Add user info to properties.
     * @param customProperties Optional additional properties.
     * @returns Properties with user info added.
     */
    private addUserInfoToProperties(customProperties?: { [key: string]: unknown }): { [key: string]: unknown } {
        if (!customProperties) {
            customProperties = {};
        }

        const userProfile: UserProfile | undefined = reduxStore.getState().appReducer.apiLoadUserProfile.userProfile;
        if (userProfile) {
            customProperties.userAlias = userProfile.alias;
            customProperties.id = userProfile.id;
            customProperties.isAgent = userProfile.isAgent ? 'true' : 'false';
            customProperties.isFinanceController = userProfile.isFinanceController ? 'true' : 'false';
            customProperties.isAccrualsAdmin = userProfile.isAccrualsAdmin ? 'true' : 'false';
        }

        return customProperties;
    }

    /**
     * Log message to console with color text.
     * @param message Message to log.
     * @param color Color of the message.
     */
    private consoleLog(message: string, color: string = 'blue') {
        console.log(`%c${message}`, `color: ${color}`);
    }
}

export const telemetryService = new TelemetryService();
