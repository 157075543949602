import React from 'react';
import {
    ConstrainMode,
    DetailsListLayoutMode,
    SelectionMode,
    Stack
} from '@fluentui/react';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { JournalEntry } from '../../models/accrualDashboard/journalEntry';
import { accrualFailureJournalEntryColumns } from './accrualFailureJournalEntryColumns';
import { pageStyles } from './AccrualDashboardPage.styles';

interface IAccrualFailureDetailRowProps {
    journalEntries?: JournalEntry[];
}

export const AccrualFailureDetailRow: React.FunctionComponent<IAccrualFailureDetailRowProps> = (props: IAccrualFailureDetailRowProps): JSX.Element => {
    return (
        <Stack>
            {
                // Only if there are journal entires, add the details list for those.
            }
            {props.journalEntries && props.journalEntries.length > 0 && (
                <Stack.Item>
                    <div className={pageStyles.journalEntryDetailsIndent} />
                    <div className={pageStyles.journalEntryDetails}>
                        <CustomDetailsList
                            ariaLabelForGrid="Accruals failure journal entries"
                            items={props.journalEntries}
                            compact={false}
                            columns={accrualFailureJournalEntryColumns}
                            selectionMode={SelectionMode.none}
                            getKey={(item: JournalEntry) => item.clientRowKey!}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            isHeaderVisible={true}
                            constrainMode={ConstrainMode.horizontalConstrained}
                            onRenderRow={onCustomRenderRow}
                        />
                    </div>
                </Stack.Item>
            )}

            {
                // Add a border between accrual failure items. Gives a visual separation between each PO and
                // the possible journal entries under each. 
            }
            <Stack.Item>
                <div className={pageStyles.accrualFailureBottomBorderBar}></div>
            </Stack.Item>
        </Stack>
    );
};
