import React, { useState } from 'react';
import { appConstants } from '../../common/appConstants';
import { loadExternalScript } from '../../common/common.func.general';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { appConfig } from '../appConfig';
import { fdaStyles } from './Fda.styles';
import { useAppSelector } from '../../store/hooks';
import { CopilotPanelOpenState } from '../../store/reducers/app.reducer';

export interface IFdaProps {
}

export const Fda: React.FunctionComponent<IFdaProps> = (props: IFdaProps): JSX.Element => {
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

    // Redux store selectors to get state from the store when it changes.
    const copilotPanelOpenState: CopilotPanelOpenState =
        useAppSelector<CopilotPanelOpenState>((state) => state.appReducer.copilotPanelOpenState);

    /**
     * Open finance bot. aka: FDA / Finance Digital Assistant.
     */
    const openFinanceBot = (): void => {
        telemetryService.trackEvent({ name: trackedEvent.financeBotOpened });

        const openBot: () => void = () => {
            try {
                (window as any).OpenFDA(`hostappid=${appConfig.current.financeBot.hostAppId}`, {
                    top: (window.screen.availHeight / 2) - 300, left: (window.screen.availWidth / 2) - 300
                });
            } catch {
                // If popup blocker is enabled, then an exception will be thrown when trying to open the FDA popup.
                // Most browsers will prompt user to allow popups once or always.
                // Catching exception here will prevent the default error handler displaying an error.
            }
        };

        if (scriptLoaded) {
            openBot();
        } else {
            loadExternalScript(appConfig.current.financeBot.fileUrl, () => {
                openBot();
                setScriptLoaded(true);
            });
        }
    };

    return (
        <div
            className={`${fdaStyles.fdaContainer} ${
                copilotPanelOpenState === CopilotPanelOpenState.OpenDefault ? fdaStyles.fdaContainer_CopilotOpenDefault :
                copilotPanelOpenState === CopilotPanelOpenState.OpenExpanded ? fdaStyles.fdaContainer_CopilotOpenExpanded :
            ''}`}
            onClick={openFinanceBot}
            tabIndex={0}
            role="button"
            aria-haspopup="true"
            aria-label="Click 'I need help!' to use the Finance Digital Assistant">

            <img
                className={fdaStyles.chatbotImg}
                src={`${appConstants.publicUrl}/images/chatbot.png`}
                alt="Chat bot"
            />

            I need help!
        </div>
    );
};
