import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    FontIcon,
    IColumn,
    IDetailsHeaderProps,
    Label,
    Link,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { pageStyles } from './AccrualDashboardPage.styles';
import { CallApiState } from '../../store/actions/generic.action';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { callApiAccrualFailures, callApiPriorYearAccrualFailures, IApiAccrualFailures, IApiPriorYearAccrualFailures } from '../../store/actions/pageActions/accrualDashboardPage.action';
import { POLineAccrualActivity } from '../../models/accrualDashboard/poLineAccrualActivity';
import { validationConstants } from '../../common/validationConstants';
import { commonString } from '../../common/commonString';
import { CustomDetailsList, IExcelCol, IExcelData } from '../../components/CustomDetailsList/CustomDetailsList';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { accrualFailureColumns } from './accrualFailureColumns';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';
import { appConfig } from '../../shell/appConfig';
import { POLinePriorYearAccrualActivity } from '../../models/accrualDashboard/poLinePriorYearAccrualActivity';
import { priorYearAccrualFailureColumns } from './priorYearAccrualFailureColumns';
import ReactExport from 'react-data-export';
import { accrualFailureJournalEntryColumns } from './accrualFailureJournalEntryColumns';
import { JournalEntry } from '../../models/accrualDashboard/journalEntry';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export enum AccrualFailuresTabMode {
    AccrualFailures = 'AccrualFailures',
    PriorYearAccrualFailures = 'PriorYearAccrualFailures'
}

interface IAccrualFailuresTabProps {
    mode: AccrualFailuresTabMode;
}

export const AccrualFailuresTab: React.FunctionComponent<IAccrualFailuresTabProps> = (props: IAccrualFailuresTabProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    
    const companyCodeInputId: string = useId();
    const poNumberInputId: string = useId();

    const [filterPoNumber, setFilterPoNumber] = useState<string>('');
    const [filterCompanyCode, setFilterCompanyCode] = useState<string>('');

    const [poNumberValidationError, setPoNumberValidationError] = useState<string>('');
    const [companyCodeValidationError, setCompanyCodeValidationError] = useState<string>('');

    const [autoFilter, setAutoFilter] = useState<boolean>(false);
    const [filteredItems, setFilteredItems] = useState<(POLineAccrualActivity | POLinePriorYearAccrualActivity)[]>([]);

    // Redux store selectors to get state from the store when it changes.
    const apiAccrualFailures: IApiAccrualFailures =
        useAppSelector<IApiAccrualFailures>(state => state.accrualDashboardPageReducer.apiAccrualFailures);
    const apiPriorYearAccrualFailures: IApiPriorYearAccrualFailures =
        useAppSelector<IApiPriorYearAccrualFailures>(state => state.accrualDashboardPageReducer.apiPriorYearAccrualFailures);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Only if not yet loaded, then call the api to load. Otherwise already cached data in Redux will be used.
        if (props.mode === AccrualFailuresTabMode.AccrualFailures) {
            if (apiAccrualFailures.callApiState === CallApiState.Initial) {
                dispatch(callApiAccrualFailures());
            }
        } else if (props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures) {
            if (apiPriorYearAccrualFailures.callApiState === CallApiState.Initial) {
                dispatch(callApiPriorYearAccrualFailures());
            }
        }
    });

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiAccrualFailures.errMsg) {
            handleError(apiAccrualFailures.errMsg);
        }
        if (apiPriorYearAccrualFailures.errMsg) {
            handleError(apiPriorYearAccrualFailures.errMsg);
        }
    }, [apiAccrualFailures.errMsg, apiPriorYearAccrualFailures.errMsg, handleError]);

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (props.mode === AccrualFailuresTabMode.AccrualFailures) {
            if (apiAccrualFailures.callApiState === CallApiState.Running) {
                return true;
            }
        } else if (props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures) {
            if (apiPriorYearAccrualFailures.callApiState === CallApiState.Running) {
                return true;
            }
        }

        return false;
    }, [apiAccrualFailures.callApiState, apiPriorYearAccrualFailures.callApiState, props.mode]);

    /**
     * Effect to handle auto filter.
     */
    useEffect(() => {
        if (autoFilter) {
            setAutoFilter(false);

            let newFilteredItems: (POLineAccrualActivity | POLinePriorYearAccrualActivity)[] = [];
            // Initially set the new filtered items to be the original data from the api.
            if (props.mode === AccrualFailuresTabMode.AccrualFailures) {
                newFilteredItems = [...apiAccrualFailures.poLineAccrualProcessingDetail?.poLineAccrualActivities || []];
            } else if (props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures) {
                newFilteredItems = [...apiPriorYearAccrualFailures.poLinePriorYearAccrualProcessingDetail?.poLinePriorYearAccrualActivities || []];
            }

            // Filter it out based on inputs.
            if (!filterCompanyCode && filterPoNumber) {
                newFilteredItems = newFilteredItems.filter(item =>
                    item.purchaseOrderNumber === Number(filterPoNumber));
            } else if (filterCompanyCode && !filterPoNumber) {
                newFilteredItems = newFilteredItems.filter(item =>
                    item.poCompanyCode === filterCompanyCode);
            } else if (filterCompanyCode && filterPoNumber) {
                newFilteredItems = newFilteredItems.filter(item =>
                    item.purchaseOrderNumber === Number(filterPoNumber) &&
                    item.poCompanyCode === filterCompanyCode);
            }

            setFilteredItems(newFilteredItems);
        }
    }, [apiAccrualFailures.poLineAccrualProcessingDetail?.poLineAccrualActivities, apiPriorYearAccrualFailures.poLinePriorYearAccrualProcessingDetail?.poLinePriorYearAccrualActivities, autoFilter, filterCompanyCode, filterPoNumber, props.mode]);

    /**
     * Filter button clicked event handler.
     */
    const filterButtonClicked = () => {
        setAutoFilter(true);
    };

    /**
     * Effect for when the API to get accrual failures returns data.
     */
    useEffect(() => {
        // When the call api state moves to completed, or is already at completed during page load, then
        // set a state flag to trigger an automatic filter. Doing this rather than calling filterButtonClicked directly
        // to avoid all the state dependencies.
        if (props.mode === AccrualFailuresTabMode.AccrualFailures) {
            if (apiAccrualFailures.callApiState === CallApiState.Completed) {
                setAutoFilter(true);
            }
        } else if (props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures) {
            if (apiPriorYearAccrualFailures.callApiState === CallApiState.Completed) {
                setAutoFilter(true);
            }
        }

    }, [apiAccrualFailures.callApiState, apiPriorYearAccrualFailures.callApiState, props.mode]);

    /**
     * Refresh button clicked event handler.
     */
    const refreshButtonClicked = () => {
        if (props.mode === AccrualFailuresTabMode.AccrualFailures) {
            dispatch(callApiAccrualFailures());
        } else if (props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures) {
            dispatch(callApiPriorYearAccrualFailures());
        }
    };

    /**
     * Memoized field for excel data to export.
     */
    const excelData: IExcelData = useMemo<IExcelData>(() => {
        const columns: IColumn[] = props.mode === AccrualFailuresTabMode.AccrualFailures ? accrualFailureColumns : priorYearAccrualFailureColumns;
        // Combine columns with the journal entry columns to flatten out the nested data into one table.
        const combinedColumns: IColumn[] = [...columns, ...accrualFailureJournalEntryColumns];

        // Combine the data.
        const combinedData: any[] = [];
        filteredItems.forEach((item: POLineAccrualActivity | POLinePriorYearAccrualActivity) => {
            // If the item has no journal entries, then just add the item as is.
            if (!(item.journalEntries && item.journalEntries.length > 0)) {
                combinedData.push(item);
            }
            // If the item has journal entries, then add the item and each journal entry as a separate row.
            item.journalEntries?.forEach((journalEntry: JournalEntry) => {
                combinedData.push({
                    ...item,
                    ...journalEntry
                });
            });
        });

        return {
            items: combinedData,
            // If fieldName is not set, then ignore that column.
            cols: combinedColumns?.filter(x => x.fieldName !== undefined && x.fieldName !== null && x.fieldName !== '').map((col: IColumn) => {
                return {
                    colName: col.name,
                    dataField: col.fieldName
                } as IExcelCol
            })
        } as IExcelData;
    }, [filteredItems, props.mode]);

    /**
     * Renders the Excel file export element.
     * @returns JSX for the excel file export.
     */
    const renderExcelFileExport = (): JSX.Element => {
        const exportButtonText: string = 'Export';

        return (
            // See: https://www.npmjs.com/package/react-data-export
            <ExcelFile filename="export" element={
                <DefaultButton
                    ariaLabel={exportButtonText}
                    className={pageStyles.excelExportImportButton}
                    disabled={excelData.items === undefined || excelData.items.length === 0}>
                    <FontIcon iconName="ExcelDocument" className={pageStyles.excelIcon} />
                    <Text className={pageStyles.exportButtonText}>{exportButtonText}</Text>
                </DefaultButton>
            }>
                <ExcelSheet data={excelData.items} name={props.mode === AccrualFailuresTabMode.AccrualFailures ? 'Accrual Failures' : 'Prior Year Accrual Failures'}>
                    {
                        excelData.cols.map((col: IExcelCol, index: number) => {
                            return <ExcelColumn key={index} label={col.colName} value={col.dataField} />
                        })
                    }
                </ExcelSheet>
            </ExcelFile>
        );
    };

    return (
        <Stack tokens={stackTokensNormalGap}>
            <Stack.Item>
                <Separator />

                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <Text variant="mediumPlus" className={commonStyles.sectionHeading} role="heading" aria-level={1}>
                    {props.mode === AccrualFailuresTabMode.AccrualFailures && (
                        <>Failed or queued accrual activities</>
                    )}
                    {props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures && (
                        <>Failed or queued prior year accrual activities</>
                    )}
                </Text>
            </Stack.Item>
            <Stack.Item>
                <>
                    <Text block>This includes activities which are either failed or in queue for processing.</Text>
                    <br/>
                    <div>
                        <Text>Open&nbsp;
                        <Link href={appConfig.current.settings.jemUrl} target="_blank">
                            Journal Entry Management (JEM)
                            <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                        </Link>
                        &nbsp;and navigate to General Ledger / JE Dashboard / Automated JE to see Journal Entries posted from GSR to JEM.
                        </Text>
                    </div>
                    <br/>
                    <Text block>Last refresh: {
                        (apiAccrualFailures.callApiState === CallApiState.Running ||
                         apiPriorYearAccrualFailures.callApiState === CallApiState.Running) ? (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        ) : (
                            <>
                                {props.mode === AccrualFailuresTabMode.AccrualFailures && (
                                <>
                                    {
                                        apiAccrualFailures.poLineAccrualProcessingDetail?.lastRefreshTime ?
                                            formatDateUsingLocale(apiAccrualFailures.poLineAccrualProcessingDetail?.lastRefreshTime) : ''
                                    }
                                </>
                                )}
                                {props.mode === AccrualFailuresTabMode.PriorYearAccrualFailures && (
                                <>
                                    {
                                        apiPriorYearAccrualFailures.poLinePriorYearAccrualProcessingDetail?.lastRefreshTime ?
                                            formatDateUsingLocale(apiPriorYearAccrualFailures.poLinePriorYearAccrualProcessingDetail?.lastRefreshTime) : ''
                                    }
                                </>
                                )}
                            </>
                        )
                    }
                    </Text>
                </>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                    <Stack.Item>
                        <Label htmlFor={poNumberInputId}>{commonString.poNumber}</Label>
                        <TooltipHost content={validationConstants.poNumber.tooltip} delay={TooltipDelay.long}>
                            <TextField
                                id={poNumberInputId}
                                autoComplete='off'
                                ariaLabel={`${commonString.poNumber} ${validationConstants.poNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                className={pageStyles.filterTextField}
                                value={filterPoNumber}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    newValue = newValue || '';
                                    newValue = newValue.trim();
                                    if (newValue.length > 0 && (newValue.length > validationConstants.poNumber.maxLength! ||
                                        !RegExp(validationConstants.poNumber.pattern!).test(newValue))) {
                                        setPoNumberValidationError(validationConstants.poNumber.errorMsg!);
                                    } else {
                                        setPoNumberValidationError('');
                                    }
                                    setFilterPoNumber(newValue);
                                }}
                                errorMessage={poNumberValidationError}
                                disabled={isSearchRunning}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <Label htmlFor={poNumberInputId}>{commonString.companyCode}</Label>
                        <TooltipHost content={validationConstants.companyCode.tooltip} delay={TooltipDelay.long}>
                            <TextField
                                id={companyCodeInputId}
                                autoComplete='off'
                                ariaLabel={`${commonString.companyCode} ${validationConstants.companyCode.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                className={pageStyles.filterTextField}
                                value={filterCompanyCode}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    newValue = newValue || '';
                                    newValue = newValue.trim();
                                    if (newValue.length > 0 && (newValue.length > validationConstants.companyCode.maxLength! ||
                                        !RegExp(validationConstants.companyCode.pattern!).test(newValue))) {
                                        setCompanyCodeValidationError(validationConstants.companyCode.errorMsg!);
                                    } else {
                                        setCompanyCodeValidationError('');
                                    }
                                    setFilterCompanyCode(newValue);
                                }}
                                errorMessage={companyCodeValidationError}
                                disabled={isSearchRunning}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            className={pageStyles.filterButton}
                            text="Filter"
                            onClick={filterButtonClicked}
                            disabled={isSearchRunning}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            className={pageStyles.refreshButton}
                            text="Refresh"
                            onClick={refreshButtonClicked}
                            disabled={isSearchRunning}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <CustomDetailsList
                    id="failuresDetailsList"
                    ariaLabelForGrid={props.mode === AccrualFailuresTabMode.AccrualFailures ? 'Accrual Failures' : 'Prior Year Accrual Failures'}
                    displayTotalItems={true}
                    items={filteredItems}
                    isLoading={props.mode === AccrualFailuresTabMode.AccrualFailures ? apiAccrualFailures.callApiState === CallApiState.Running : apiPriorYearAccrualFailures.callApiState === CallApiState.Running}
                    compact={false}
                    columns={props.mode === AccrualFailuresTabMode.AccrualFailures ? accrualFailureColumns : priorYearAccrualFailureColumns}
                    selectionMode={SelectionMode.none}
                    getKey={(item: POLineAccrualActivity | POLinePriorYearAccrualActivity) => item.clientRowKey!}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    isHeaderVisible={true}
                    constrainMode={ConstrainMode.horizontalConstrained}
                    onRenderRow={onCustomRenderRow}
                    useScrollablePane={true}
                    onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                        if (detailsHeaderProps) {
                            return (
                                <Sticky stickyPosition={StickyPositionType.Header}>
                                    {defaultRender!({ ...detailsHeaderProps })}
                                </Sticky>
                            );
                        } else {
                            return null;
                        }
                    }}
                />
            </Stack.Item>
            <Stack.Item>
                {renderExcelFileExport()}
            </Stack.Item>
        </Stack>
    );
};
