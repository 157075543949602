/*
General purpose functions to convert or transform objects.
*/

/**
* Converts input properties of { string : object } pair to flat array.
* @param inputProps Input properties of { string : object } pair.
*/
export const flatProperties = (inputProps: { [key: string]: unknown } | undefined): string[] | undefined => {
    if (!inputProps) {
        return undefined;
    }

    const retVal: string[] = [];
    Object.keys(inputProps).forEach((key: string, index: number) => {
        retVal[index] = key + ':' + String(inputProps[key]);
    });

    return retVal;
};

/**
 * Rename key in an object.
 * Generic types are inferred, do not pass them in.
 * See: https://stackoverflow.com/questions/4647817/javascript-object-rename-key
 * @param oldKey Old key.
 * @param newKey New key.
 * @param obj Object to have a key renamed. 
 */
export const renameKey = <OldKey extends keyof T, NewKey extends string, T extends Record<string, unknown>>(
    oldKey: OldKey,
    newKey: NewKey extends keyof T ? never : NewKey,
    obj: T
): Record<NewKey, T[OldKey]> & Omit<T, OldKey> => {
    const { [oldKey]: value, ...common } = obj
    return {
        ...common,
        ...({ [newKey]: value } as Record<NewKey, T[OldKey]>)
    }
};

/**
 * Check for and return a boolean value, given a possible string or boolean.
 * @param value Value to convert to boolean.
 * @returns Boolean value.
 */
export const convertToBoolean = (value: boolean | string): boolean => {
    if (value === 'true' || value === true) {
        return true;
    }

    return false;
};

/**
 * Converts all values in array to strings.
 * @param values Values of any type.
 * @returns String array.
 */
export const convertToStringArray = (values: any[]) => {
    const result: string[] = [];
    values.forEach(val => result.push(String(val)));
    return result;
};

/**
 * Shorten number to thousands, millions, billions, etc.
 * Taken from: https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
 * http://en.wikipedia.org/wiki/Metric_prefix
 * Examples:
 * shortenLargeNumber(12543, 1) - '12.5k'
 * shortenLargeNumber(-12567) - '-13k'
 * shortenLargeNumber(51000000) - '51M'
 * shortenLargeNumber(651) - '651'
 * shortenLargeNumber(0.12345) - '0.12345'
 * @param num Number to shorten.
 * @param digits The number of digits to appear after the decimal point.
 * @returns Shortened number as a string.
 */
export const shortenLargeNumber = (num: number, digits: number = 0): string => {
    const units = ['k', 'M', 'B' /* Using billions format, rather than giga 'G' */, 'T', 'P', 'E', 'Z', 'Y'];
    let decimal;

    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);
        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return String(num);
};

/**
 * Similar to javascripts toFixed (which rounds the number) except this version does not round.
 * @param num Number to make fixed length with no rounding.
 * @param fractionDigits Number of fractional digits.
 * @returns Number as string.
 */
export const toFixedNoRounding = (num, fractionDigits): string => {
    const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + fractionDigits + '})?', 'g');
    const a = num.toString().match(reg)[0];
    const dot = a.indexOf('.');
    if (dot === -1) { // Integer, insert decimal dot and pad up zeros.
        return a + '.' + '0'.repeat(fractionDigits);
    }
    const b = fractionDigits - (a.length - dot) + 1;
    return b > 0 ? (a + '0'.repeat(b)) : a;
};

/**
 * Return only the alpha numeric part of a string. Also allows whitespace and dash and underscore.
 * @param input Input string.
 * @returns String with non alphanumeric, whitespace, dash, underscore stripped.
 */
export const stripNonAlphaNumericWhitespaceDashUnderscore = (input: string): string => {
    if (!input || typeof input !== 'string') {
        return '';
    }
    return input.replace(/[^0-9a-zA-Z \-_]/gi, '');
};

/**
 * Return all words from a string except the last. If only one word exists then return empty string.
 * @param str Input string.
 * @returns Input string not including last word of string.
 */
export const allExceptLastWordInString = (str: string): string => {
    const i: number = str.lastIndexOf(' ');
    if (i < 0) {
        return '';
    }
    return str.substring(0, i);
};

/**
 * Return only the final word in a string. If only one word exists then return that word.
 * @param str Input string.
 * @returns Final word in string.
 */
export const finalWordInString = (str: string): string => {
    const i: number = str.lastIndexOf(' ');
    if (i < 0) {
        return str;
    }
    return str.substring(i);
};

/**
 * Return trimmed string, or null if the string is empty or null or undefined to begin with.
 * @param str Input string.
 * @returns Trimmed string or null.
 */
export const trimOrNull = (str: string): string | null => {
    if (typeof str === 'string') {
        str = str.trim();
        if (str.length === 0) {
            return null;
        }
        return str;
    }
    // If str was not a string then just return it unmodified.
    return str;
};

/**
 * Return a number as a locale specific string. Note that different locales display the thousands separator and decimal differently.
 * United States uses comma for thousands, and period for decimal, such as: 12,345,678.12
 * Spain uses period for thousands, and comma for decimal, such as:         12.345.678,12
 * https://en.wikipedia.org/wiki/Decimal_separator
 * @param num Number.
 * @param fractionDigits Fraction digits. Used for both min and max.
 * @returns Number converted to locale specific string.
 */
export const numberAsLocaleString = (num?: number, fractionDigits: number = 2): string => {
    if (num === undefined || num === null) {
        return '';
    }
    return num.toLocaleString(undefined, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
};

/**
 * Pad a number with a leading character (default '0').
 * @param n Number to pad.
 * @param width Pad width.
 * @param z Charachter to pad with, default to '0'.
 * @returns Padded number string.
 */
export const padNumberWithLeadingChar = (n: number | string, width: number, z: string = '0'): string => {
    let result: string = '';
    result = n + '';
    return result.length >= width ? result : new Array(width - result.length + 1).join(z) + result;
};

/**
 * Trim leading zeros from a string.
 * @param str String to trim.
 * @returns Trimmed string.
 */
export const trimLeadingZeros = (str: string): string => {
    return parseInt(str, 10).toString();
};

/**
 * Trims leading chars from the string.
 * @param str String to trim.
 * @param trimChars Array of chars to trim.
 */
export const trimLeadingChars = (str: string, trimChars: string[]): string => {
    let startIndex: number = 0;
    for (; startIndex < str.length; startIndex++) {
        if (trimChars.indexOf(str[startIndex]) === -1) {
            break;
        }
    }
    const result: string = str.substring(startIndex);
    return result;
};

/**
 * Trims trailing chars from the string.
 * @param str String to trim.
 * @param trimChars Array of chars to trim.
 */
export const trimTrailingChars = (str: string, trimChars: string[]): string => {
    let endIndex: number = str.length - 1;
    for (; endIndex > 0; endIndex--) {
        if (trimChars.indexOf(str[endIndex]) === -1) {
            break;
        }
    }
    const result: string = str.substring(0, endIndex + 1);
    return result;
};

/**
 * Trims leading and trailing chars from the string.
 * @param str String to trim.
 * @param trimChars Array of chars to trim.
 */
export const trimLeadingAndTrailingChars = (str: string, trimChars: string[]): string => {
    let result: string = trimLeadingChars(str, trimChars);
    result = trimTrailingChars(result, trimChars);
    return result;
};

/**
 * Remove an item from an array.
 * @param arr Array of any type.
 * @param item Item to check for using equality match.
 * @returns Array with item removed.
 */
export const removeFromArray = (arr: any[], item: any) => {
    const index: number = arr.indexOf(item);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

/**
 * Add an item to an array if not already present in the array.
 * @param arr Array of any type.
 * @param item Item to check if it is in the array by equality match.
 * @returns Array with item added if not already present.
 */
export const addToArrayIfNotPresent = (arr: any[], item: any) => {
    const index: number = arr.indexOf(item);
    if (index < 0) {
        arr.push(item);
    }
    return arr;
};

/**
 * Remove duplicate items from an array.
 * @param arr Array of any type.
 * @returns Array with duplicates removed.
 */
export const removeDuplicatesFromArray = (arr: any[]) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}
