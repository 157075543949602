import { OrgSummary } from './orgSummary';

export interface IChannelFunction {
    orgSummary: OrgSummary[];
}

/**
 * User defined type guard to determine if object is a ChannelFunction.
 * @param obj Object to check if is a channel function.
 * @returns Type guard indicating if channel function.
 */
export function isChannelFunction(obj: any): obj is ChannelFunction {
    return 'orgSummary' in obj;
}

export class ChannelFunction implements IChannelFunction {
    public orgSummary: OrgSummary[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IChannelFunction) {
        if (jsonData.orgSummary) {
            for (let i: number = 0; i < jsonData.orgSummary.length; i++) {
                this.orgSummary.push(new OrgSummary(jsonData.orgSummary[i]));
            }
        }
    }
}
