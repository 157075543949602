import React, { useState } from 'react';
import {
    Stack,
    DefaultButton,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { AccessRequest } from '../../models/user/accessRequest';
import { pageStyles } from './AccessRequestPage.styles';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

export interface IApproveRejectButtonsProps {
    accessRequest: AccessRequest;
    requestCompleted?: () => void;
    handleError?: (errMsg: string) => void;
}

export const ApproveRejectButtons: React.FunctionComponent<IApproveRejectButtonsProps> = (props: IApproveRejectButtonsProps): JSX.Element => {
    const [isApproveRunning, setIsApproveRunning] = useState<boolean>(false);
    const [isApproveCompleted, setIsApproveCompleted] = useState<boolean>(false);
    const [isRejectRunning, setIsRejectRunning] = useState<boolean>(false);
    const [isRejectCompleted, setIsRejectCompleted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    /**
     * Approve access request button clicked event handler.
     */
    const approveAccessRequestButtonClicked = async () => {
        telemetryService.trackEvent({ name: trackedEvent.approveAccessRequestButtonClicked });

        setIsApproveRunning(true);

        try {
            await receiptingApiClient.approveAccessRequest(props.accessRequest);
            setIsApproveCompleted(true);
            if (props.requestCompleted) {
                props.requestCompleted();
            }
        } catch (err: any) {
            setError('Failed');
            if (props.handleError) {
                props.handleError(err.message);
            }
        }

        setIsApproveRunning(false);
    }

    /**
     * Reject access request button clicked event handler.
     */
    const rejectAccessRequestButtonClicked = async () => {
        telemetryService.trackEvent({ name: trackedEvent.rejectAccessRequestButtonClicked });

        setIsRejectRunning(true);

        try {
            await receiptingApiClient.rejectAccessRequest(props.accessRequest);
            setIsRejectCompleted(true);
            if (props.requestCompleted) {
                props.requestCompleted();
            }
        } catch (err: any) {
            setError('Failed');
            if (props.handleError) {
                props.handleError(err.message);
            }
        }

        setIsRejectRunning(false);
    }

    return (
        <Stack horizontal tokens={stackTokensNormalGap}>
            {!isRejectCompleted && (
                <Stack.Item>
                    <DefaultButton
                        className={pageStyles.approveRejectButton}
                        onClick={approveAccessRequestButtonClicked}
                        disabled={isRejectRunning || isRejectCompleted || isApproveRunning || isApproveCompleted || error !== ''}
                    >
                        {!error && !isApproveCompleted && isApproveRunning && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        )}
                        {!error && !isApproveCompleted && !isApproveRunning && (
                            <span>Approve</span>
                        )}
                        {!error && isApproveCompleted && (
                            <span>Approved</span>
                        )}
                        {error && (
                            <span>{error}</span>
                        )}
                    </DefaultButton>
                </Stack.Item>
            )}
            {!isApproveCompleted && (
                <Stack.Item>
                    <DefaultButton
                        className={pageStyles.approveRejectButton}
                        onClick={rejectAccessRequestButtonClicked}
                        disabled={isRejectRunning || isRejectCompleted || isApproveRunning || isApproveCompleted || error !== ''}
                    >
                        {!error && !isRejectCompleted && isRejectRunning && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        )}
                        {!error && !isRejectCompleted && !isRejectRunning && (
                            <span>Reject</span>
                        )}
                        {!error && isRejectCompleted && (
                            <span>Rejected</span>
                        )}
                        {error && (
                            <span>{error}</span>
                        )}
                    </DefaultButton>
                </Stack.Item>
            )}
        </Stack>
    );                               
};
