export interface IPurchaseOrderCheckResult {
    result: boolean;
    message: string;
    recommendedAction?: string;

    // The server object also has a PurchaseOrderCheckType enum, but it is not being modeled in the client
    // as there is no current use for it.
}

export class PurchaseOrderCheckResult implements IPurchaseOrderCheckResult {
    public result: boolean;
    public message: string;
    public recommendedAction?: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderCheckResult) {
        this.result = jsonData.result;
        this.message = jsonData.message;
        this.recommendedAction = jsonData.recommendedAction;
    }
}
