/**
 * Hierarchy constants.
 */
export class HierarchyConstants {
    // Class names for all models used for the hierarchy. Defined here so it isn't needed to copy/paste the strings all over.
    // Note that using child.constructor.name to get the type works in dev but in production build it gets minified so it cannot be relied on.
    public static className: any = {
        channel: {
            channelFunction: 'ChannelFunction',
            functionCode: 'FunctionCode',
            functionDetailCode: 'FunctionDetailCode',
            functionSummary: 'FunctionSummary',
            orgCode: 'OrgCode',
            orgDetail: 'OrgDetail',
            orgSummary: 'OrgSummary'
        },
        executive: {
            execFunctionCode: 'ExecFunctionCode',
            execFunctionDetailCode: 'ExecFunctionDetailCode',
            execFunctionSummary: 'ExecFunctionSummary',
            execOrg: 'ExecOrg',
            execOrgDetail: 'ExecOrgDetail',
            execOrgSummary: 'ExecOrgSummary',
            executiveFunction: 'ExecutiveFunction'
        },
        geography: {
            area: 'Area',
            areaSummary: 'AreaSummary',
            financeGeography: 'FinanceGeography',
            region: 'Region',
            salesDistrict: 'SalesDistrict',
            salesLocation: 'SalesLocation',
            salesSubLocation: 'SalesSubLocation',
            subRegion: 'SubRegion'
        }
    };
}
