import { FunctionCode } from './functionCode';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IFunctionSummary extends IHierarchyNodeCommon {
    functionCode: FunctionCode[];
}

export class FunctionSummary implements IFunctionSummary {
    public typeName: string = HierarchyConstants.className.channel.functionSummary;
    public code: string;
    public description: string;
    public functionCode: FunctionCode[] = [];
    public children?: IHierarchyNodeCommon[] = this.functionCode; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IFunctionSummary) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.functionCode) {
            for (let i: number = 0; i < jsonData.functionCode.length; i++) {
                this.functionCode.push(new FunctionCode(jsonData.functionCode[i]));
            }
        }
    }
}
