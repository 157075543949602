import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    errorIcon: {
        paddingRight: 4,
        fontSize: 16,
        display: 'inline-block'
    } as IStyle
});
