import React, { useEffect, useReducer, useState } from 'react';
import {
    Text,
    Stack,
    FontIcon
} from '@fluentui/react';
import { componentStyles } from './GraphUserInput.styles';
import { GraphUser } from '../../models/user/graphUser';
import { graphApiClient } from '../../services/api/graphApiClient';
import { stackTokensSmallGap } from '../../common/common.styles';

/**
 * Graph user option props.
 */
export interface IGraphUserOptionProps {
    /**
     * Graph user.
     */
    graphUser: GraphUser;
}

/**
 * Graph user option to put in ComboBox.
 * @param props Graph user option props.
 */
export const GraphUserOption: React.FunctionComponent<IGraphUserOptionProps> = (props: IGraphUserOptionProps): JSX.Element => {
    const [userPhoto, setUserPhoto] = useState<string>();
    const [, forceRender] = useReducer((s) => s + 1, 0);

    const populateUserPhotos = async (graphUser: GraphUser) => {
        if (graphUser && graphUser.userPrincipalName) {
            const userPhoto: string = await graphApiClient.getUserPhoto(graphUser.userPrincipalName);
            setUserPhoto(userPhoto);
            forceRender();
        }
    }

    useEffect(() => {
        populateUserPhotos(props.graphUser);
    }, [props.graphUser])

    return (
        <>
            {props.graphUser && (
                <Stack className={componentStyles.comboBoxOption}>
                    <Stack horizontal tokens={stackTokensSmallGap}>
                        {userPhoto && (
                            <img className={componentStyles.userPhoto} src={userPhoto} alt='User' />
                        )}
                        {!userPhoto && (
                            <FontIcon className={componentStyles.userPhotoNotFoundIcon} iconName="Contact" />
                        )}
                        <Stack key={props.graphUser.id}>
                            <Text variant="medium" className={componentStyles.displayName}>{props.graphUser.displayName}</Text>
                            <Text variant="smallPlus">{props.graphUser.alias}</Text>
                        </Stack>
                    </Stack>
                </Stack>
            )}
            {!props.graphUser && (
                <Text variant="medium" className={componentStyles.displayName}>Nothing found</Text>
            )}
        </>
    );
}
