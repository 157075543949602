import { PriorYearAccrualLineItem } from './priorYearAccrualLineItem';

export interface IPriorYearAccrualSearchResults {
    items: PriorYearAccrualLineItem[];
    totalItems: number;
    lastRefreshTime?: Date;
}

export class PriorYearAccrualSearchResults implements IPriorYearAccrualSearchResults {
    public items: PriorYearAccrualLineItem[] = [];
    public totalItems: number;
    public lastRefreshTime?: Date;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPriorYearAccrualSearchResults) {
        if (jsonData.items) {
            for (let i: number = 0; i < jsonData.items.length; i++) {
                this.items.push(new PriorYearAccrualLineItem(jsonData.items[i]));
            }
        }

        this.totalItems = jsonData.totalItems || 0;
        this.lastRefreshTime = jsonData.lastRefreshTime ? new Date(jsonData.lastRefreshTime) : undefined;
    }
}
