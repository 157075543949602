import React, { useEffect, useMemo, useState } from 'react';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useAppDispatch } from '../../store/hooks';
import { commonString } from '../../common/commonString';
import { UserDelegate } from '../../models/user/userDelegate';
import { editDelegateToAccessRights } from '../../store/actions/pageActions/userProfilePage.action';
import { AppDispatch } from '../../store/reduxStore';
import { AccessRight } from '../../common/appEnums';

export interface IAccessRightSelectionProps {
    userDelegate: UserDelegate;

    /**
     * Optional input id. If not supplied then one will be generated.
     */
    inputId?: string;

    /**
     * Optional next input id. This is used to help with accessibility. Using the arrow keys to navigate out
     * of the ComboBox should move focus to the next or previous element if this control is used in a grid like
     * a DetailsList. When the ComboBox is not using allowFreeInput or allowFreeForm then by using the arrow
     * keys will not cause focus to move to the next or previous column's input. This will help make that work.
     * See code for onKeyDownCapture in the ComboBox.
     */
    nextInputId?: string;

    /**
     * Optional prev input id. This is used to help with accessibility. Using the arrow keys to navigate out
     * of the ComboBox should move focus to the next or previous element if this control is used in a grid like
     * a DetailsList. When the ComboBox is not using allowFreeInput or allowFreeForm then by using the arrow
     * keys will not cause focus to move to the next or previous column's input. This will help make that work.
     * See code for onKeyDownCapture in the ComboBox.
     */
    prevInputId?: string;
}

/**
 * Access right selector.
 * @param props Access right selection props.
 * @returns JSX for the component.
 */
export const AccessRightSelection: React.FunctionComponent<IAccessRightSelectionProps> = (props: IAccessRightSelectionProps): JSX.Element => {
    const [selectedKeys, setSelectedKeys] = useState<AccessRight[]>(props.userDelegate.accessRights || []);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);
    const inputId: string = useId(); // This will be used only if props.inputElemId was not supplied.

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Build access rights combobox options. Selections based on input access rights.
     * @returns Array of combobox options.
     */
    const accessRightComboboxOptions = useMemo((): IComboBoxOption[] => {
        return [
            {
                key: AccessRight.PoRequestor,
                data: AccessRight.PoRequestor,
                text: commonString.poOwner,
                selected: props.userDelegate.accessRights.indexOf(AccessRight.PoRequestor) > -1
            } as IComboBoxOption,
            {
                key: AccessRight.InvoiceApprover,
                data: AccessRight.InvoiceApprover,
                text: commonString.invoiceApprover,
                selected: props.userDelegate.accessRights.indexOf(AccessRight.InvoiceApprover) > -1
            } as IComboBoxOption
        ]
    }, [props.userDelegate.accessRights]);

    /**
     * Effect to set options for access rights when the access right combobox options changes.
     */
    useEffect(() => {
        setOptions(accessRightComboboxOptions);
    }, [accessRightComboboxOptions])

    return (
        <>
            <ComboBox
                id={props.inputId || inputId}
                ariaLabel={commonString.accessRights}
                onKeyDownCapture={(event: React.KeyboardEvent<IComboBox>) => {
                    if (props.nextInputId && event.code === 'ArrowRight') {
                        document.getElementById(props.nextInputId)?.focus();
                    } else if (props.prevInputId && event.code === 'ArrowLeft') {
                        document.getElementById(props.prevInputId)?.focus();
                    }
                }}
                multiSelect={true}
                autoComplete='off' // Turn off autoComplete when multiSelect is true.
                options={options}
                selectedKey={selectedKeys}
                useComboBoxAsMenuWidth={true}
                onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                    if (option) {
                        // Get access right for the current option.
                        const accessRight: AccessRight = option.data as AccessRight;
                        // Prepare a new accessRights array.
                        const accessRights: AccessRight[] = [...selectedKeys];

                        const index: number = accessRights.indexOf(accessRight);
                        if (option.selected && index < 0) {
                            accessRights.push(accessRight);
                        } else if (!option.selected && index > -1) {
                            accessRights.splice(index, 1);
                        }

                        const newOptions = [...options];
                        newOptions.forEach(o => {
                            o.selected = accessRights.indexOf(o.key as AccessRight) > -1;
                        });
                        setOptions(newOptions);

                        setSelectedKeys(accessRights);
                    }
                }}
                onMenuDismissed={() => {
                    dispatch(editDelegateToAccessRights(props.userDelegate.clientRowKey!, selectedKeys!));
                }}
                errorMessage={selectedKeys.length === 0 ? commonString.selectAtLeastOne : ''}
            />
        </>
    );
};
