export const trackedEvent = {
    getApiCall: 'getApiCall',
    putApiCall: 'putApiCall',
    postApiCall: 'postApiCall',
    deleteApiCall: 'deleteApiCall',
    financeBotOpened: 'financeBotOpened',
    auditButtonClicked: 'auditButtonClicked',
    shipmentInfoButtonClicked: 'shipmentInfoButtonClicked',
    detailsButtonClicked: 'detailsButtonClicked',
    submitAccessRequestButtonClicked: 'submitAccessRequestButtonClicked',
    approveAccessRequestButtonClicked: 'approveAccessRequestButtonClicked',
    rejectAccessRequestButtonClicked: 'rejectAccessRequestButtonClicked',
    closeLineButtonClicked: 'closeLineButtonClicked',
    editPoSaveButtonClicked: 'editPoSaveButtonClicked',
    homeOpenButtonClicked: 'homeOpenButtonClicked',
    homeDueThisMonthButtonClicked: 'homeDueThisMonthButtonClicked',
    homeOverdueButtonClicked: 'homeOverdueButtonClicked',
    homeFcOpenButtonClicked: 'homeFcOpenButtonClicked',
    homeFcDueThisMonthButtonClicked: 'homeFcDueThisMonthButtonClicked',
    homeFcUnavailableOwnerButtonClicked: 'homeFcUnavailableOwnerButtonClicked',
    homeFcUnavailableApproverButtonClicked: 'homeFcUnavailableApproverButtonClicked',
    homeFcReceiptedByFinanceControllerButtonClicked: 'homeFcReceiptedByFinanceControllerButtonClicked',
    homeSearchButtonClicked: 'homeSearchButtonClicked',
    homeClearButtonClicked: 'homeClearButtonClicked',
    customDetailsListImportButtonClicked: 'customDetailsListImportButtonClicked',
    customDetailsListExportButtonClicked: 'customDetailsListExportButtonClicked',
    exportButtonClicked: 'exportButtonClicked',
    importButtonClicked: 'importButtonClicked',
    pyaConfirmCloseButtonClicked: 'pyaConfirmCloseButtonClicked',
    pyaConfirmRetainButtonClicked: 'pyaConfirmRetainButtonClicked',
    pyaUpdateCorpDetailsButtonClicked: 'pyaUpdateCorpDetailsButtonClicked',
    pyaSearchButtonClicked: 'pyaSearchButtonClicked',
    pyaPageLoadOccurred: 'pyaPageLoadOccurred',
    pyaSendReminderEmailsButtonClicked: 'pyaSendReminderEmailsButtonClicked',
    findDailyReportsButtonClicked: 'findDailyReportsButtonClicked',
    downloadDailyReportButtonClicked: 'downloadDailyReportButtonClicked',
    editPoButtonClicked: 'editPoButtonClicked',
    deleteDelegateButtonClicked: 'deleteDelegateButtonClicked',
    addNewDelegateButtonClicked: 'addNewDelegateButtonClicked',
    saveUserProfileButtonClicked: 'saveUserProfileButtonClicked',
    dismissAllNotificationsButtonClicked: 'dismissAllNotificationsButtonClicked',
    dismissNotificationButtonClicked: 'dismissNotificationButtonClicked',
    resetTeachingBubbleButtonClicked: 'resetTeachingBubbleButtonClicked',
    openVideoGuidesButtonClicked: 'openVideoGuidesButtonClicked',
    openNotificationPanelButtonClicked: 'openNotificationPanelButtonClicked',
    openOcvPanelButtonClicked: 'openOcvPanelButtonClicked',
    serviceReceipt100PctButtonClicked: 'serviceReceipt100PctButtonClicked',
    serviceReceiptAddAmountButtonClicked: 'serviceReceiptAddAmountButtonClicked',
    pyaCorpDetailsImportButtonClicked: 'pyaCorpDetailsImportButtonClicked',
    pyaCorpDetailsExportButtonClicked: 'pyaCorpDetailsExportButtonClicked',
    copilotButtonClicked: 'copilotButtonClicked',
    shipmentAdminFilterButtonClicked: 'shipmentAdminFilterButtonClicked',
    shipmentAdminUploadButtonClicked: 'shipmentAdminUploadButtonClicked'
};
