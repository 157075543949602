import { createShortGuid } from '../../../common/common.func.guid';
import { AuditEvent } from '../../../models/auditHistory/auditEvent';
import { AuditHistoryEvent } from '../../../models/auditHistory/auditHistoryEvent';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';

/**
 * Payload used with callApiLoadAuditHistory action.
 */
export interface IApiLoadAuditHistory extends ICallApiBase {
    auditHistoryEvents?: AuditHistoryEvent[] | null;
}

/**
 * Call API to load audit history.
 * @returns Redux dispatch function.
 */
export const callApiLoadAuditHistory = (purchaseOrderNumber: string, purchaseOrderLineItemNumber: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<AuditHistoryEvent[] | null>(
        actionTypes.API_LOAD_AUDIT_HISTORY,
        async () => {
            return await receiptingApiClient.loadAuditHistory(purchaseOrderNumber, purchaseOrderLineItemNumber);
        },
        (payload: IApiLoadAuditHistory, data: AuditHistoryEvent[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: AuditHistoryEvent) => {
                    item.clientRowKey = createShortGuid();
                    // Set the parsed event to a displayable audit event name.
                    if (item.event in AuditEvent) {
                        item.parsedEvent = AuditEvent[item.event];
                    } else {
                        // If the event is not in AuditEvent enum, then just use the event name itself.
                        item.parsedEvent = item.event;
                    }
                });
            }

            payload.auditHistoryEvents = data;
        }
    );
};
