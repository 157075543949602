import React, { useMemo } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    FontIcon,
    Link,
    PrimaryButton
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { DisplayCurrencyKey, queryStringParams } from './priorYearAccrualPageConstants';
import { useAppSelector } from '../../store/hooks';
import { UserProfile } from '../../models/user/userProfile';
import { appConstants } from '../../common/appConstants';
import { useLocation } from 'react-router-dom';
import { PriorYearAccrualStatus } from '../../models/priorYearAccrual/priorYearAccrualStatus';
import { ApprovalStatus } from '../../models/priorYearAccrual/priorYearAccrualSearch';

interface IComponentProps {
    showDialog: boolean;
    displayCurrencyKey: DisplayCurrencyKey | undefined;
    filterCompanyCodes: number[] | undefined;
    filterDelegatedBy: string[] | undefined;
    filterFiscalYearKeys: string[] | undefined;
    filterIncludeOwnPo: boolean | undefined;
    filterStatusKeys: PriorYearAccrualStatus[] | undefined;
    filterPoOwner: string | undefined;
    filterPoPlusOptionalLineNumber: string | undefined;
    filterSupplierNumber: string | undefined;
    filterRegionKeys: string[] | undefined;
    filterChannelFunctionDetailCodes: string[] | undefined;
    filterExecutiveFunctionDetailCodes: string[] | undefined;
    filterCostCategorySubclassKeys: string[] | undefined;
    filterCorpApproval: ApprovalStatus | undefined;
    filterStcApproval: ApprovalStatus | undefined;
    filterPoePodAvailability: ApprovalStatus | undefined;
    onCloseClicked: () => void;
}

export const GetPageLinkDialog: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    const location = useLocation();

    /**
     * Memoized page link with filter.
     * Gets page link to this page with filter inputs on query string.
     */
    const pageLinkWithFilter = useMemo<string>(() => {
        const viewFor: string[] = [];
        props.filterDelegatedBy?.forEach(x => {
            viewFor.push(x);
        });
        if (props.filterIncludeOwnPo) {
            viewFor.push(userProfile!.alias);
        }

        const path: string = `${window.location.protocol}//${window.location.host}${appConstants.publicUrl}${location.pathname}?` +
            (props.filterPoPlusOptionalLineNumber === undefined ? '' : `${queryStringParams.po}=${props.filterPoPlusOptionalLineNumber}&`) +
            (props.filterCompanyCodes === undefined ? '' : `${queryStringParams.cc}=${props.filterCompanyCodes.join(',')}&`) +
            (props.filterPoOwner === undefined ? '' : `${queryStringParams.owner}=${props.filterPoOwner}&`) +
            (props.filterFiscalYearKeys === undefined ? '' : `${queryStringParams.fy}=${props.filterFiscalYearKeys.join(',')}&`) +
            (props.filterStatusKeys === undefined ? '' : `${queryStringParams.status}=${props.filterStatusKeys.join(',')}&`) +
            ((viewFor.length === 0) ? '' : `${queryStringParams.vf}=${viewFor.join(',')}&`) +
            (props.filterSupplierNumber === undefined ? '' : `${queryStringParams.sup}=${props.filterSupplierNumber}&`) +
            (props.filterRegionKeys === undefined ? '' : `${queryStringParams.reg}=${props.filterRegionKeys.join(',')}&`) +
            (props.filterChannelFunctionDetailCodes === undefined ? '' : `${queryStringParams.cfdc}=${props.filterChannelFunctionDetailCodes.join(',')}&`) +
            (props.filterExecutiveFunctionDetailCodes === undefined ? '' : `${queryStringParams.efdc}=${props.filterExecutiveFunctionDetailCodes.join(',')}&`) +
            (props.filterCostCategorySubclassKeys === undefined ? '' : `${queryStringParams.ccsc}=${props.filterCostCategorySubclassKeys.join(',')}&`) +
            (props.filterCorpApproval === undefined ? '' : `${queryStringParams.corp}=${props.filterCorpApproval}&`) +
            (props.filterStcApproval === undefined ? '' : `${queryStringParams.stc}=${props.filterStcApproval}&`) +
            (props.filterPoePodAvailability === undefined ? '' : `${queryStringParams.pp}=${props.filterPoePodAvailability}&`) +
            (props.displayCurrencyKey === undefined ? '' : `${queryStringParams.cur}=${props.displayCurrencyKey}`);
        return path;
    }, [location.pathname, props.displayCurrencyKey, props.filterChannelFunctionDetailCodes, props.filterCompanyCodes, props.filterCorpApproval, props.filterCostCategorySubclassKeys, props.filterDelegatedBy, props.filterExecutiveFunctionDetailCodes, props.filterFiscalYearKeys, props.filterIncludeOwnPo, props.filterPoOwner, props.filterPoPlusOptionalLineNumber, props.filterPoePodAvailability, props.filterRegionKeys, props.filterStatusKeys, props.filterStcApproval, props.filterSupplierNumber, userProfile]);

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.onCloseClicked}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Get page link',
                subText: 'Get a link to this page with all search filters pre-selected to what they are now. You can share this link with others or bookmark it.'
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth="400px"
        >
            <div className={pageStyles.pageLink}>
                <Link target='_blank' href={pageLinkWithFilter}>
                    Try link
                    <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                </Link>
            </div>
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        navigator.clipboard.writeText(pageLinkWithFilter);
                    }}
                    text='Copy to clipboard'
                />
                <DefaultButton
                    onClick={props.onCloseClicked}
                    text="Close" 
                />
            </DialogFooter>
        </Dialog>
    );
};
