import React, { useState } from 'react';
import { DefaultButton, FontIcon, Spinner, SpinnerSize } from '@fluentui/react';
import { ReportFile } from '../../models/report/reportFile';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { pageStyles } from './ReportsPage.styles';
import { commonStyles } from '../../common/common.styles';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IComponentProps {
    reportFile: ReportFile;
}

export const DownloadButton: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    /**
     * Download button clicked event handler.
     */
    const downloadButtonClicked = async () => {
        telemetryService.trackEvent({ name: trackedEvent.downloadDailyReportButtonClicked });

        setIsDownloading(true);

        try {
            await receiptingApiClient.downloadReportFile(props.reportFile.reportLink);
        } catch (err: any) {
            setError('Failed');
        }

        setIsDownloading(false);
    }

    return (
        <DefaultButton
            className={pageStyles.downloadButton}
            onClick={downloadButtonClicked}
            disabled={isDownloading || error !== ''}
            ariaLabel='Download report'
        >
            {!error && isDownloading && (
                <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
            )}
            {!error && !isDownloading && (
                <>
                    <FontIcon iconName="Download" className={pageStyles.fontIcon} />
                    <span>Download</span>
                </>
            )}
            {error && (
                <>
                    <FontIcon iconName="Error" className={pageStyles.fontIcon} />
                    <span>Failed</span>
                </>
            )}
        </DefaultButton>
    );
}
