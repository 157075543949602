import { AccessRight } from '../../common/appEnums';

export interface IUserDelegate {
    aadOid: string;
    alias: string;
    accessRights: AccessRight[];
    companyCodes: number[];
    startDate: Date;
    endDate: Date;
}

export class UserDelegate implements IUserDelegate {
    public aadOid: string;
    public alias: string;
    public accessRights: AccessRight[] = [];
    public companyCodes: number[] = [];
    public startDate: Date;
    public endDate: Date;

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';
    public isValid?: boolean;

    public get isExpired(): boolean {
        if (!this.endDate) {
            return false;
        }
        return new Date() > new Date(this.endDate);
    }

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IUserDelegate) {
        this.aadOid = jsonData.aadOid;
        this.alias = jsonData.alias;

        if (jsonData.accessRights) {
            for (let i: number = 0; i < jsonData.accessRights.length; i++) {
                this.accessRights.push(jsonData.accessRights[i]);
            }
        }

        if (jsonData.companyCodes) {
            for (let i: number = 0; i < jsonData.companyCodes.length; i++) {
                this.companyCodes.push(jsonData.companyCodes[i]);
            }
        }

        this.startDate = new Date(jsonData.startDate);
        this.endDate = new Date(jsonData.endDate);
    }
}
