import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import {
    Breadcrumb,
    ConstrainMode,
    DetailsListLayoutMode,
    IBreadcrumbItem,
    IDetailsHeaderProps,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate, useParams } from 'react-router';
import { ISearchReducer } from '../../store/reducers/search.reducer';
import { callApiClosePoLines, clearEditPageData, IApiClosePoLines, IEditPageData, storeEditPageData } from '../../store/actions/pageActions/editPage.action';
import { appConstants } from '../../common/appConstants';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { CallApiState } from '../../store/actions/generic.action';
import { callApiSearchForPo, IApiSearchForPo } from '../../store/actions/search.action';
import { PoType, Role, SearchOperation, SearchOperationParameter, Source } from '../../common/appEnums';
import { UserProfile } from '../../models/user/userProfile';
import { PurchaseOrderDetails } from '../../models/purchaseOrder/purchaseOrderDetails';
import { PurchaseOrderSearchResults } from '../../models/purchaseOrder/purchaseOrderSearchResults';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { closeLineColumns } from './closeLineColumns';
import { AppDispatch } from '../../store/reduxStore';
import { LineItemDetailsPanel } from '../../components/LineItemDetailsPanel/LineItemDetailsPanel';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { PurchaseOrderLineClose } from '../../models/purchaseOrder/purchaseOrderLineClose';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { reloadCountdown } from '../../common/common.func.general';
import { PoVisibilityCheckResults } from '../../components/PoVisibilityCheckResults/PoVisibilityCheckResults';
import { teachingBubbleClearArray } from '../../store/actions/app.action';
import { commonString } from '../../common/commonString';
import { LineItemAuditPanel } from '../../components/LineItemAuditPanel/LineItemAuditPanel';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { LineItemShipmentInfoPanel } from '../../components/LineItemShipmentInfoPanel/LineItemShipmentInfoPanel';

interface IPageProps extends ICommonPageProps {
}

/**
 * Close line page.
 * @param props Page props.
 * @returns JSX for the page.
 */
export const CloseLinePage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [breadCrumbItems, setBreadCrumbItems] = useState<IBreadcrumbItem[]>();
    const [completedMsg, setCompletedMsg] = useState<string>('');
    const poNumber = useRef<string>(''); 

    // Redux store selectors to get state from the store when it changes.
    const apiSearchForPo: IApiSearchForPo =
        useAppSelector<IApiSearchForPo>((state) => state.searchReducer.apiSearchForPo);
    const searchReducer: ISearchReducer =
        useAppSelector<ISearchReducer>((state) => state.searchReducer);
    // This close line page will make use of the edit page reducer and actions.
    const editPageData: IEditPageData =
        useAppSelector<IEditPageData>((state) => state.editPageReducer.editPageData);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);
    const apiClosePoLines: IApiClosePoLines =
        useAppSelector<IApiClosePoLines>((state) => state.editPageReducer.apiClosePoLines);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const params = useParams();
    const navigate = useNavigate();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Make breadcrumbs.
     */
    const makeBreadCrumbs = useCallback(() => {
        // Get PO number for edit link from params. Do not rely on poNumber state as it isn't set on first
        // render pass (it is set in useMountEffect).
        const editLinkPoNumber: string = params.poNumber || '';

        // If there is no purchaseOrderSearch in searchReducer then the user got to this page not
        // through the search results page. This happens if the user:
        // - Searched for a PO directly from the home page or header search.
        // - Directly links to this page.
        // - F5 refreshes this page.
        // Don't show the breadcrumb for the search results page in this case.
        let showSearchResultsPage: boolean = true;
        if (!searchReducer.purchaseOrderHeaders || searchReducer.purchaseOrderHeaders.length === 0) {
            showSearchResultsPage = false;
        }

        const items: IBreadcrumbItem[] = [];
        items.push({
            text: 'Home',
            key: 'Home',
            onClick: () => {
                navigate(`${appConstants.publicUrl}/Home`);
            }
        });
        if (showSearchResultsPage) {
            items.push({
                text: 'Search Results',
                key: 'SearchResults',
                onClick: () => {
                    navigate(`${appConstants.publicUrl}/SearchResults`);
                }
            });
        }

        items.push({
            text: 'Edit PO',
            key: 'EditPo',
            onClick: () => {
                navigate(`${appConstants.publicUrl}/Edit/${editLinkPoNumber}`);
            }
        });

        items.push({
            text: 'Close Line Items',
            key: 'CloseLine',
            isCurrentItem: true
        });

        setBreadCrumbItems(items);
    }, [navigate, params.poNumber, searchReducer.purchaseOrderHeaders]);

    /**
     * Load purchase order. Called by initial load as well as when refreshing after save.
     */
    const loadPurchaseOrder = useCallback(() => {
        // Clear any previous edit page data.
        dispatch(clearEditPageData());

        const searchAsRole: Role | undefined = userProfile?.getSearchAsRole();

        dispatch(callApiSearchForPo(
            poNumber.current,
            Source.SAP,
            SearchOperation.Search,
            SearchOperationParameter.None,
            true,
            PoType.All,
            searchAsRole,
            !searchReducer.inputFilter.driSearchEnabled,
            false // Do not store the last search and results (that should only be done when called from the home page).
        ));
    }, [dispatch, poNumber, searchReducer.inputFilter.driSearchEnabled, userProfile]);

    /**
     * Memoized field to check if the visiblity check result should be displayed.
     */
    const displayVisibilityCheckResult = useMemo<boolean>(() => {
        return searchReducer.purchaseOrderCheckResults !== undefined && searchReducer.purchaseOrderCheckResults.length > 0;
    }, [searchReducer.purchaseOrderCheckResults]);

    /**
     * Effect for when params.poNumber changes.
     */
    useEffect(() => {
        let po: string = params.poNumber || '';
        po = po.trim();
        if (po) {
            if (poNumber.current !== po) {
                poNumber.current = po;
                loadPurchaseOrder();
            }
        } else {
            navigate(appConstants.publicUrl);
        }

        makeBreadCrumbs();
    }, [loadPurchaseOrder, makeBreadCrumbs, navigate, params.poNumber]);

    /**
     * Effect for when apiSearchForPo returns data.
     */
    useEffect(() => {
        if (apiSearchForPo.callApiState === CallApiState.DataAvailable) {
            const searchResults: PurchaseOrderSearchResults | null | undefined = apiSearchForPo.purchaseOrderSearchResults;
            if (searchResults && searchResults?.results && searchResults?.results.length > 0) {
                const poDetails: PurchaseOrderDetails = searchResults.results[0];
                if (poDetails) {
                    dispatch(
                        storeEditPageData(
                            poDetails.header,
                            poDetails.items?.filter(x => x.isAsset) || [],
                            poDetails.items?.filter(x => !x.isAsset) || []
                        )
                    );
                }
            }
        }
    }, [apiSearchForPo.callApiState, apiSearchForPo.purchaseOrderSearchResults, dispatch]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiSearchForPo.errMsg) {
            handleError(apiSearchForPo.errMsg);
        }
        if (apiClosePoLines.errMsg) {
            handleError(apiClosePoLines.errMsg);
        }
    }, [apiClosePoLines.errMsg, apiSearchForPo.errMsg, handleError]);

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (apiSearchForPo.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSearchForPo.callApiState]);

    /**
     * Memoized helper to check if the api to close lines is running.
     * @returns True or false.
     */
    const isSaveRunning = useMemo<boolean>(() => {
        if (apiClosePoLines.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiClosePoLines.callApiState]);

    /**
     * Memoized field for all line items (goods and services combined).
     */
    const allLineItems = useMemo<PurchaseOrderLineItem[]>(() => {
        const allItems: PurchaseOrderLineItem[] = [
            ...(editPageData.goodsPurchaseOrderLineItems || []),
            ...(editPageData.servicesPurchaseOrderLineItems || [])
        ];
        return allItems.sort((a, b) => {
            if (Number(a.purchaseOrderLineNumber) < Number(b.purchaseOrderLineNumber)) {
                return -1;
            }
            return 1;
        });
    }, [editPageData.goodsPurchaseOrderLineItems, editPageData.servicesPurchaseOrderLineItems]);

    /**
     * Memoized dirty line item count.
     * @returns Count of dirty lines.
     */
    const dirtyItemCount = useMemo<number>((): number => {
        let count: number = 0;

        const combinedLineItems: PurchaseOrderLineItem[] = [
            ...editPageData.goodsPurchaseOrderLineItems || [],
            ...editPageData.servicesPurchaseOrderLineItems || []
        ];

        combinedLineItems?.forEach(item => {
            if (item.isDirty) {
                count++;
            }
        });

        return count;
    }, [editPageData.goodsPurchaseOrderLineItems, editPageData.servicesPurchaseOrderLineItems]);

    /**
     * Memoized check if any line has an error. 
     */
    const anyLineHasError = useMemo<boolean>((): boolean => {
        if (editPageData.lineItemsWithError && editPageData.lineItemsWithError.length > 0) {
            return true;
        }
        return false;
    }, [editPageData.lineItemsWithError]);

    /**
     * Effect for when apiClosePoLines returns data.
     * Close line is async and completions will stream in via SignalR notifications.
     */
    useEffect(() => {
        if (apiClosePoLines.callApiState === CallApiState.DataAvailable) {
            // Refresh data after a countdown interval.
            reloadCountdown(
                (msg) => setCompletedMsg(msg), 
                () => {
                    setCompletedMsg('');
                    loadPurchaseOrder();
                }
            );
        }
    }, [apiClosePoLines.callApiState, loadPurchaseOrder, poNumber]);

    /**
     * Close line button clicked event handler.
     */
    const closeLineButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.closeLineButtonClicked });

        // Get what lines have been selected to be closed.
        const selectedLines: PurchaseOrderLineItem[] = allLineItems.filter(x => x.canClose && x.isClosed);
        // Prepare the array of PurchaseOrderLineClose to be passed to the close lines api.
        const poLines: PurchaseOrderLineClose[] = selectedLines.map(x => {
            return new PurchaseOrderLineClose(
                x.purchaseOrderNumber,
                x.purchaseOrderLineNumber,
                x.closedComments
            );
        });

        dispatch(callApiClosePoLines(poLines));
    };

    return (
        <>
            <PageWrapper {...props}>
                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <Stack tokens={stackTokensNormalGap}>
                    {breadCrumbItems && (
                        <Breadcrumb
                            items={breadCrumbItems}
                            ariaLabel="Site navigation"
                        />
                    )}

                    <SectionWrapper>
                        <Section>
                            <Stack tokens={stackTokensNormalGap}>
                                { isSearchRunning && (
                                    <Stack.Item>
                                        <Text variant='mediumPlus'>Loading...</Text>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                    </Stack.Item>
                                )}
                                { !isSearchRunning && (
                                    <>
                                        {displayVisibilityCheckResult && (
                                            <PoVisibilityCheckResults
                                                purchaseOrderNumber={poNumber.current || commonString.missingPoNumber}
                                                results={searchReducer.purchaseOrderCheckResults!}
                                            />
                                        )}

                                        {!displayVisibilityCheckResult && (
                                            <>
                                                {!editPageData.purchaseOrderHeader && (
                                                    <Stack.Item>
                                                        No purchase order data found.
                                                    </Stack.Item>
                                                )}
                                                {editPageData.purchaseOrderHeader && (
                                                    <>
                                                        <Stack.Item>
                                                            <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={1}>
                                                                Close line items for PO {poNumber.current}
                                                            </Text>
                                                            <ul>
                                                                <li>
                                                                    <Text variant='mediumPlus'>
                                                                        It is not required to close line items, it is optional. Line items will be automatically closed in SAP after the associated invoice has been paid.
                                                                    </Text>
                                                                </li>
                                                                <li>
                                                                    <Text variant='mediumPlus'>
                                                                        Please be aware that if you close the line item then it cannot be invoiced and paid.
                                                                    </Text>
                                                                </li>
                                                                <li>
                                                                    <Text variant='mediumPlus'>
                                                                        Do not close the line if it has active or parked invoices. No additional accruals can be recorded towards the line after closing.
                                                                    </Text>
                                                                </li>
                                                                <li>
                                                                    <Text variant='mediumPlus'>
                                                                        If you close a line item, it will become uneditable. No goods or services receipt will be allowed for that line.
                                                                    </Text>
                                                                </li>
                                                            </ul>
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <CustomDetailsList
                                                                id="closeLineDetailsList"
                                                                ariaLabelForGrid="Line Items"
                                                                displayTotalItems={false}
                                                                showPaginator={false}
                                                                showPageSize={false}
                                                                items={allLineItems}
                                                                isLoading={isSearchRunning}
                                                                compact={false}
                                                                columns={closeLineColumns}
                                                                selectionMode={SelectionMode.none}
                                                                getKey={(item: PurchaseOrderLineItem) => item.clientRowKey!}
                                                                setKey="none"
                                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                                isHeaderVisible={true}
                                                                constrainMode={ConstrainMode.horizontalConstrained}
                                                                onRenderRow={onCustomRenderRow}
                                                                useScrollablePane={true}
                                                                onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                                                    if (detailsHeaderProps) {
                                                                        return (
                                                                            <Sticky stickyPosition={StickyPositionType.Header}>
                                                                                {defaultRender!({ ...detailsHeaderProps })}
                                                                            </Sticky>
                                                                        );
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <PrimaryButton
                                                                onClick={closeLineButtonClicked}
                                                                disabled={dirtyItemCount === 0 || anyLineHasError || isSearchRunning || isSaveRunning || completedMsg.length > 0}
                                                            >
                                                                {isSaveRunning && (
                                                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                )}
                                                                {!isSaveRunning && (
                                                                    <span>Close selected items</span>
                                                                )}
                                                            </PrimaryButton>
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            {!isSaveRunning && dirtyItemCount > 0 && !completedMsg && (
                                                                // If save is not running, and there are dirty items, and not displaying the save completed
                                                                // message, then display the modified line count.
                                                                <Text>{dirtyItemCount} line items modified</Text>
                                                            )}
                                                            {!isSaveRunning && completedMsg && (
                                                                // If save is not running, and completed msg exists, then display the message.
                                                                <Text>{completedMsg}</Text>
                                                            )}
                                                        </Stack.Item>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        </Section>
                    </SectionWrapper>
                </Stack>

            </PageWrapper>

            <LineItemDetailsPanel />
            <LineItemAuditPanel />
            <LineItemShipmentInfoPanel />
        </>
    );
};
