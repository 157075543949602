import { ExecOrgDetail } from './execOrgDetail';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IExecOrg extends IHierarchyNodeCommon {
    orgDetail: ExecOrgDetail[];
}

export class ExecOrg implements IExecOrg {
    public typeName: string = HierarchyConstants.className.executive.execOrg;
    public code: string;
    public description: string;
    public orgDetail: ExecOrgDetail[] = [];
    public children?: IHierarchyNodeCommon[] = this.orgDetail; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IExecOrg) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.orgDetail) {
            for (let i: number = 0; i < jsonData.orgDetail.length; i++) {
                this.orgDetail.push(new ExecOrgDetail(jsonData.orgDetail[i]));
            }
        }
    }
}
