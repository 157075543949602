import { Region } from './region';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IArea extends IHierarchyNodeCommon {
    region: Region[];
}

export class Area implements IArea {
    public typeName: string = HierarchyConstants.className.geography.area;
    public code: string;
    public description: string;
    public region: Region[] = [];
    public children?: IHierarchyNodeCommon[] = this.region; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IArea) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.region) {
            for (let i: number = 0; i < jsonData.region.length; i++) {
                this.region.push(new Region(jsonData.region[i]));
            }
        }
    }
}
