import React from 'react';
import { IColumn, TooltipDelay, TooltipHost } from '@fluentui/react';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { EditActionButton } from '../../components/EditActionButton/EditActionButton';

export const miniSearchResultsColumns: IColumn[] = [
    {
        key: 'purchaseOrderNumber',
        fieldName: 'purchaseOrderNumber',
        name: '',
        isResizable: false,
        minWidth: 76,
        onRender: (item: PurchaseOrderHeader, index?: number) => {
            return (
                <TooltipHost content={item.projectName} delay={TooltipDelay.long}>
                    <EditActionButton poHeader={item} index={index} enableTeachingBubble={false} highlightActiveFromRoute={true} />
                </TooltipHost>
            );
        }
    }
];
