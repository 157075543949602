import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    headerWrapper: {
        paddingLeft: '24px',
        paddingRight: '24px',
        alignSelf: 'flex-start',
        flexGrow: 1
    } as IStyle,
    headerText: {
        fontSize: '20px',
        fontWeight: 600,
        color: 'rgb(50, 49, 48)',
        lineHeight: '27px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        hyphens: 'auto'
    } as IStyle,
    infoList: {
        padding: '6px',
        margin: 0,
        li: {
            whiteSpace: 'pre-wrap'
        } as IStyle
    } as IStyle,
    driCheckbox: {
        margin: '8px 0px 0 0px'
    } as IStyle,
    eventTable: {
        borderCollapse: 'collapse',
        width: '100%'
    } as IStyle,
    eventNameCell: {
        h2: {
            fontWeight: '500',
            fontSize: '14px',
            margin: '0'
        } as IStyle
    } as IStyle,
    eventTimeCell: {
        fontSize: '12px'
    } as IStyle,
    eventInfoCell: {
        fontSize: '12px',
        'ul': {
            padding: '0 0 0 30px'
        } as IStyle
    } as IStyle,
    scrollToBottom: {
        width: 'fit-content',
        fontSize: '14px',
        color: '#0070CC',
        ':hover': {
            color: '#0000EE',
            cursor: 'pointer'
        } as IStyle
    } as IStyle
});
