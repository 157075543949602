import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
    Calendar,
    ConstrainMode,
    DateRangeType,
    DefaultButton,
    DetailsListLayoutMode,
    FontIcon,
    SelectionMode,
    Separator,
    Stack
} from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { pageStyles } from './ReportsPage.styles';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { CallApiState } from '../../store/actions/generic.action';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { ReportFile } from '../../models/report/reportFile';
import { columns } from './reportColumns';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    callApiDaily1010Reports,
    callApiDailyNon1010Reports,
    callApiDailyOpenAccrual1010Reports,
    callApiDailyOpenAccrualNon1010Reports,
    callApiDailyPriorYearAccrualReports,
    IApiDaily1010Reports,
    IApiDailyNon1010Reports,
    IApiDailyOpenAccrual1010Reports,
    IApiDailyOpenAccrualNon1010Reports,
    IApiDailyPriorYearAccrualReports
} from '../../store/actions/pageActions/reportsPage.action';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { AppDispatch } from '../../store/reduxStore';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const MonthYearFmt: string = 'MM/YYYY';

interface IComponentProps {
    mode: '1010' | 'non1010' | '1010oa' | 'non1010oa' | 'pya';
}

export const DailyReportsTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    const [selectedStartDate, setSelectedStartDate] = useState<dayjs.Dayjs>(dayjs().startOf('month'));
    const [selectedEndDate, setSelectedEndDate] = useState<dayjs.Dayjs>(dayjs().endOf('month'));

    const [displayCalendarDialog, { toggle: toggleDisplayCalendarDialog }] = useBoolean(false);

    // Redux store selectors to get state from the store when it changes.
    const apiDaily1010Reports: IApiDaily1010Reports =
        useAppSelector<IApiDaily1010Reports>((state) => state.reportsPageReducer.apiDaily1010Reports);
    const apiDailyNon1010Reports: IApiDailyNon1010Reports =
        useAppSelector<IApiDailyNon1010Reports>((state) => state.reportsPageReducer.apiDailyNon1010Reports);
    const apiDailyOpenAccrual1010Reports: IApiDailyOpenAccrual1010Reports =
        useAppSelector<IApiDailyOpenAccrual1010Reports>((state) => state.reportsPageReducer.apiDailyOpenAccrual1010Reports);
    const apiDailyOpenAccrualNon1010Reports: IApiDailyOpenAccrualNon1010Reports =
        useAppSelector<IApiDailyOpenAccrualNon1010Reports>((state) => state.reportsPageReducer.apiDailyOpenAccrualNon1010Reports);
    const apiDailyPriorYearAccrualReports: IApiDailyPriorYearAccrualReports =
        useAppSelector<IApiDailyPriorYearAccrualReports>((state) => state.reportsPageReducer.apiDailyPriorYearAccrualReports);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();
    
    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiDaily1010Reports.errMsg) {
            handleError(apiDaily1010Reports.errMsg);
        }
        if (apiDailyNon1010Reports.errMsg) {
            handleError(apiDailyNon1010Reports.errMsg);
        }
        if (apiDailyOpenAccrual1010Reports.errMsg) {
            handleError(apiDailyOpenAccrual1010Reports.errMsg);
        }
        if (apiDailyOpenAccrualNon1010Reports.errMsg) {
            handleError(apiDailyOpenAccrualNon1010Reports.errMsg);
        }
        if (apiDailyPriorYearAccrualReports.errMsg) {
            handleError(apiDailyPriorYearAccrualReports.errMsg);
        }
    }, [apiDaily1010Reports.errMsg, apiDailyNon1010Reports.errMsg, apiDailyOpenAccrual1010Reports.errMsg, apiDailyOpenAccrualNon1010Reports.errMsg, apiDailyPriorYearAccrualReports.errMsg, handleError]);

    /**
     * On select date change event handler.
     * @param date Selected date.
     */
    const onSelectDate = (date: Date): void => {
        const dayjsDate: dayjs.Dayjs = dayjs(date);
        setSelectedStartDate(dayjsDate.clone().startOf('month'));
        setSelectedEndDate(dayjs(dayjsDate.clone().endOf('month')));
    };

    /**
     * Find button clicked event handler.
     */
    const findButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.findDailyReportsButtonClicked });

        switch (props.mode) {
            case '1010': {
                dispatch(callApiDaily1010Reports(selectedStartDate.toDate(), selectedEndDate.toDate()));
                break;
            }
            case 'non1010': {
                dispatch(callApiDailyNon1010Reports(selectedStartDate.toDate(), selectedEndDate.toDate()));
                break;
            }
            case '1010oa': {
                dispatch(callApiDailyOpenAccrual1010Reports(selectedStartDate.toDate(), selectedEndDate.toDate()));
                break;
            }
            case 'non1010oa': {
                dispatch(callApiDailyOpenAccrualNon1010Reports(selectedStartDate.toDate(), selectedEndDate.toDate()));
                break;
            }
            case 'pya': {
                dispatch(callApiDailyPriorYearAccrualReports(selectedStartDate.toDate(), selectedEndDate.toDate()));
                break;
            }
            default:
        }
    };

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        switch (props.mode) {
            case '1010': {
                if (apiDaily1010Reports.callApiState === CallApiState.Running) {
                    return true;
                }
                break;
            }
            case 'non1010': {
                if (apiDailyNon1010Reports.callApiState === CallApiState.Running) {
                    return true;
                }
                break;
            }
            case '1010oa': {
                if (apiDailyOpenAccrual1010Reports.callApiState === CallApiState.Running) {
                    return true;
                }
                break;
            }
            case 'non1010oa': {
                if (apiDailyOpenAccrualNon1010Reports.callApiState === CallApiState.Running) {
                    return true;
                }
                break;
            }
            case 'pya': {
                if (apiDailyPriorYearAccrualReports.callApiState === CallApiState.Running) {
                    return true;
                }
                break;
            }
            default:
        }

        return false;
    }, [apiDaily1010Reports.callApiState, apiDailyNon1010Reports.callApiState, apiDailyOpenAccrual1010Reports.callApiState, apiDailyOpenAccrualNon1010Reports.callApiState, apiDailyPriorYearAccrualReports.callApiState, props.mode]);

    /**
     * This effect is run once during component load.
     */
    useMountEffect(() => {
        let simulateClick: boolean = false;
        switch (props.mode) {
            case '1010': {
                if (apiDaily1010Reports.callApiState === CallApiState.Initial) {
                    simulateClick = true;
                }
                break;
            }
            case 'non1010': {
                if (apiDailyNon1010Reports.callApiState === CallApiState.Initial) {
                    simulateClick = true;
                }
                break;
            }
            case '1010oa': {
                if (apiDailyOpenAccrual1010Reports.callApiState === CallApiState.Initial) {
                    simulateClick = true;
                }
                break;
            }
            case 'non1010oa': {
                if (apiDailyOpenAccrualNon1010Reports.callApiState === CallApiState.Initial) {
                    simulateClick = true;
                }
                break;
            }
            case 'pya': {
                if (apiDailyPriorYearAccrualReports.callApiState === CallApiState.Initial) {
                    simulateClick = true;
                }
                break;
            }
            default:
        }

        if (simulateClick) {
            // Simulate find button click on load. The current month is automatically selected.
            findButtonClicked();
        }
    });

    return (
        <>
            <Separator />

            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Stack horizontal tokens={stackTokensNormalGap} verticalAlign="end">
                        <Stack.Item>
                            <DefaultButton
                                onClick={toggleDisplayCalendarDialog}
                                ariaLabel={`Select Month (currently ${selectedStartDate.format(MonthYearFmt)})`}
                            >
                                <FontIcon iconName="Calendar" className={pageStyles.fontIcon} />
                                Select Month - {selectedStartDate.format(MonthYearFmt)}
                            </DefaultButton>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton
                                onClick={findButtonClicked}
                                text="Find Reports"
                                disabled={isSearchRunning}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <CustomDetailsList
                        id="reportList"
                        ariaLabelForGrid="Reports"
                        items={props.mode === '1010' ? apiDaily1010Reports.reportFiles || [] :
                            props.mode === 'non1010' ? apiDailyNon1010Reports.reportFiles || [] :
                            props.mode === '1010oa' ? apiDailyOpenAccrual1010Reports.reportFiles || [] :
                            props.mode === 'non1010oa' ? apiDailyOpenAccrualNon1010Reports.reportFiles || [] :
                            props.mode === 'pya' ? apiDailyPriorYearAccrualReports.reportFiles || [] :
                            []
                        }
                        isLoading={isSearchRunning}
                        compact={false}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={(item: ReportFile) => item.clientRowKey!}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        isHeaderVisible={true}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        onRenderRow={onCustomRenderRow}
                    />
                </Stack.Item>
            </Stack>

            <GenericDialog
                displayDialog={displayCalendarDialog}
                width={280}
                title='Select month'
                content={
                    <Calendar
                        dateRangeType={DateRangeType.Month}
                        highlightSelectedMonth
                        isDayPickerVisible={false}
                        showGoToToday={false}
                        onSelectDate={onSelectDate}
                        value={selectedStartDate.toDate()}
                    />
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayCalendarDialog();
                }}
            />
        </>
    );
};
