export interface IReportFile {
    fileName: string;
    lastModifiedDateUtc: Date;
    reportDateUtc: Date;
    reportLink: string;
}

export class ReportFile implements IReportFile {
    public fileName: string;
    public lastModifiedDateUtc: Date;
    public reportDateUtc: Date;
    public reportLink: string;

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IReportFile) {
        this.fileName = jsonData.fileName;
        this.lastModifiedDateUtc = jsonData.lastModifiedDateUtc;
        this.reportDateUtc = jsonData.reportDateUtc;
        this.reportLink = jsonData.reportLink;
    }
}
