import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    businessJustificationTextField: {
        width: '500px'
    } as IStyle,
    requestTable: {
        borderCollapse: 'collapse',
        // The requestTableHead/requestTableBody class used here is to target only this thead and tbody,
        // not those found deeper in the children.
        // The hierarchy selector also has a table in it, so we don't want this css to target that too.
        'thead.requestTableHead>tr>th': {
            fontWeight: 500,
            minWidth: '100px',
            textAlign: 'left'
        } as IStyle,
        'tbody.requestTableBody>tr>td': {
            padding: '10px 30px 10px 0',
            borderBottom: '1px solid lightgray'
        } as IStyle
    } as IStyle,
    companyCodeSelectionContainer: {
        width: '230px'
    } as IStyle,
    approveRejectButton: {
        width: '80px'
    } as IStyle,
    selectHierarchyNotice: {
        color: 'red'
    } as IStyle
});
