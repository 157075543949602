import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    actionButtonShipmentInfo: {
        height: '26px',
        width: 'fit-content',
        margin: '2px 0 0 0 !important',
        color: '#0070cc',
        paddingLeft: '0px',
        span: {
            marginLeft: '0px'
        } as IStyle
    } as IStyle
});
