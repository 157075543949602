export interface ICompany {
    companyCode: number;
    companyName: string;
    countryCode: number;
}

export class Company implements ICompany {
    public companyCode: number;
    public companyName: string;
    public countryCode: number;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ICompany) {
        this.companyCode = jsonData.companyCode;
        this.companyName = jsonData.companyName;
        this.countryCode = jsonData.countryCode;
    }
}
