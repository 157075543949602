import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    tileValue: {
        fontSize: '18px',
        textAlign: 'center'
    } as IStyle,
    tileText: {
        fontSize: '12px'
    } as IStyle
});
