import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    container: {
        position: 'relative',
        display: 'inline-block'
    } as IStyle,
    gridRowCheckbox: {
        top: '4px',
        '.ms-Checkbox-label': {
            /* There is no label used on this checkbox, so set the width to the checkbox width. */
            /* Note that globalStyles.css has an override for .ms-Checkbox-checkbox */
            width: '24px'
        }
    } as IStyle
});
