import React from 'react';
import { createShortGuid } from '../../../common/common.func.guid';
import { JournalEntry } from '../../../models/accrualDashboard/journalEntry';
import { POLineAccrualActivity } from '../../../models/accrualDashboard/poLineAccrualActivity';
import { POLinePriorYearAccrualActivity } from '../../../models/accrualDashboard/poLinePriorYearAccrualActivity';
import { POLineAccrualProcessingDetail } from '../../../models/accrualDashboard/poLineAccrualProcessingDetail';
import { POLinePriorYearAccrualProcessingDetail } from '../../../models/accrualDashboard/poLinePriorYearAccrualProcessingDetail';
import { POLineReceiptAccrualDetail } from '../../../models/accrualDashboard/poLineReceiptAccrualDetail';
import { PurchaseOrderLineReceiptsAndAccruals } from '../../../models/accrualDashboard/purchaseOrderLineReceiptsAndAccruals';
import { AccrualFailureDetailRow } from '../../../pages/AccrualDashboardPage/AccrualFailureDetailRow';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';

/**
 * Payload used with callApiAccrualsUnsynced action.
 */
export interface IApiAccrualOutOfSync extends ICallApiBase {
    poLineReceiptAccrualDetail?: POLineReceiptAccrualDetail | null;
}

/**
 * Call API to get accruals out of sync.
 * @returns Redux dispatch function.
 */
export const callApiAccrualOutOfSync = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<POLineReceiptAccrualDetail | null>(
        actionTypes.API_ACCRUAL_OUT_OF_SYNC,
        async () => {
            return await receiptingApiClient.accrualsOutOfSync();
        },
        (payload: IApiAccrualOutOfSync, data: POLineReceiptAccrualDetail | null) => {
            if (data && data.poLineReceiptsAndAccruals) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.poLineReceiptsAndAccruals.forEach((item: PurchaseOrderLineReceiptsAndAccruals) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.poLineReceiptAccrualDetail = data;
        }
    );
};

/**
 * Payload used with callApiAccrualFailures action.
 */
export interface IApiAccrualFailures extends ICallApiBase {
    poLineAccrualProcessingDetail?: POLineAccrualProcessingDetail | null;
}

/**
 * Call API to get accrual failures.
 * @returns Redux dispatch function.
 */
export const callApiAccrualFailures = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<POLineAccrualProcessingDetail | null>(
        actionTypes.API_ACCRUAL_FAILURES,
        async () => {
            return await receiptingApiClient.accrualFailures();
        },
        (payload: IApiAccrualFailures, data: POLineAccrualProcessingDetail | null) => {
            if (data && data.poLineAccrualActivities) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.poLineAccrualActivities.forEach((poLineAccrualActivity: POLineAccrualActivity) => {
                    poLineAccrualActivity.clientRowKey = createShortGuid();

                    poLineAccrualActivity.journalEntries.forEach((je: JournalEntry) => {
                        je.clientRowKey = createShortGuid();
                    });

                    // Add the details row JSX here.
                    poLineAccrualActivity.detailsRowJsx = (
                        <AccrualFailureDetailRow journalEntries={poLineAccrualActivity.journalEntries} />
                    )
                });
            }

            payload.poLineAccrualProcessingDetail = data;
        }
    );    
};

/**
 * Payload used with callApiPriorYearAccrualFailures action.
 */
export interface IApiPriorYearAccrualFailures extends ICallApiBase {
    poLinePriorYearAccrualProcessingDetail?: POLinePriorYearAccrualProcessingDetail | null;
}

/**
 * Call API to get prior year accrual failures.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualFailures = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<POLinePriorYearAccrualProcessingDetail | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_FAILURES,
        async () => {
            return await receiptingApiClient.priorYearAccrualFailures();
        },
        (payload: IApiPriorYearAccrualFailures, data: POLinePriorYearAccrualProcessingDetail | null) => {
            if (data && data.poLinePriorYearAccrualActivities) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.poLinePriorYearAccrualActivities.forEach((poLinePriorYearAccrualActivity: POLinePriorYearAccrualActivity) => {
                    poLinePriorYearAccrualActivity.clientRowKey = createShortGuid();

                    poLinePriorYearAccrualActivity.journalEntries.forEach((je: JournalEntry) => {
                        je.clientRowKey = createShortGuid();
                    });

                    // Add the details row JSX here.
                    poLinePriorYearAccrualActivity.detailsRowJsx = (
                        <AccrualFailureDetailRow journalEntries={poLinePriorYearAccrualActivity.journalEntries} />
                    )
                });
            }

            payload.poLinePriorYearAccrualProcessingDetail = data;
        }
    );    
};
