import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    container: {
        position: 'relative',
        display: 'inline-block'
    } as IStyle,
    lineError: {
        position: 'relative',
        top: '8px'
    } as IStyle,
    lineErrorIcon: {
        color: '#e50000',
        ':focus, :hover': {
            // When focused or hovered, keep the color red. Otherwise since this is used in a Link,
            // the color would change to the Link focused color.
            color: '#e50000'
        } as IStyle,
        fontSize: '16px'
    } as IStyle,
    lineUnsupported: {
        position: 'relative',
        top: '8px',
        fontSize: '16px'
    } as IStyle,
    lineProcessingSpinner: {
        position: 'relative',
        top: '8px'
    } as IStyle
});
