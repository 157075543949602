import { useEffect, useState } from 'react';

/**
 * Hook to check if an element is onscreen.
 * From: https://usehooks.com/
 * @param ref Ref of element to check if onscreen.
 * @param rootMargin Margin around the target element that needs to be onscreen. 
 * @returns True if onscreen, otherwise false.
 */
export const useOnScreen = (ref, rootMargin = '0px') => {
    // State and setter for storing whether element is visible.
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires.
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        const r = ref.current;
        return () => {
            observer.unobserve(r);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
}
