/**
 * Purchase order line close.
 */
export class PurchaseOrderLineClose {
    public purchaseOrder: string;
    public lineItem: string;
    public statusComment?: string;

    constructor(purchaseOrder: string, lineItem: string, statusComment?: string) {
        this.purchaseOrder = purchaseOrder;
        this.lineItem = lineItem;
        this.statusComment = statusComment;
    }
}
