import React, { useMemo, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { editLineItemSerialNumber, editLineItemSerialNumberHasError } from '../../store/actions/pageActions/editPage.action';
import { commonString } from '../../common/commonString';
import { validationConstants } from '../../common/validationConstants';
import { TextField, TooltipHost, Text, Link, FontIcon, TooltipDelay } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { commonStyles } from '../../common/common.styles';
import { pageStyles } from './EditPage.styles';
import { tooltips } from '../../common/tooltips';

interface ISerialNumberInputProps {
    item: PurchaseOrderLineItem;
}

export const SerialNumberInput: React.FunctionComponent<ISerialNumberInputProps> = (props: ISerialNumberInputProps): JSX.Element => {
    const [errorMsg, setErrorMsg] = useState<string>('');

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Memoized helper to indicate if the prepopulated icon should be shown.
     */
    const showPrepopulatedIcon = useMemo<boolean>(() => {
        return !!props.item.isSerialNumberPrePopulatedBySupplier && !!props.item.requireAssetDataCollection;
    }, [props.item.isSerialNumberPrePopulatedBySupplier, props.item.requireAssetDataCollection]);

    return (
        <>
            { props.item.requireAssetDataCollection && (
                <>
                    <TooltipHost content={validationConstants.serialNumber.tooltip} delay={TooltipDelay.long}>
                        <TextField
                            id={`serialNumber-${props.item.purchaseOrderLineNumber}`}
                            autoComplete='off'
                            ariaLabel={`${commonString.serialNumber} ${validationConstants.serialNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                            value={props.item.serialNumber}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                newValue = newValue || '';
                                newValue = newValue.trim();
            
                                if (newValue.length > 0 &&
                                    (
                                        newValue?.length > validationConstants.serialNumber.maxLength! ||
                                        !RegExp(validationConstants.serialNumber.pattern!).test(newValue)
                                    )
                                ) {
                                    setErrorMsg(validationConstants.serialNumber.errorMsg!);
                                    dispatch(editLineItemSerialNumberHasError(props.item.purchaseOrderLineNumber, true));
                                } else {
                                    setErrorMsg('');
                                    dispatch(editLineItemSerialNumberHasError(props.item.purchaseOrderLineNumber, false));
                                }
            
                                dispatch(editLineItemSerialNumber(props.item.purchaseOrderLineNumber, newValue));
                            }}
                            onBlur={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                dispatch(editLineItemSerialNumber(props.item.purchaseOrderLineNumber, props.item.serialNumber || ''));
                            }}
                            errorMessage={
                                (((props.item.canReceive && props.item.received) || props.item.isUpdatingAssetDetails) && props.item.serialNumber?.length === 0) ? 'Enter serial number or n/a' :
                                errorMsg ? errorMsg : ''
                            }
                            disabled={props.item.checkToDisableEditing()}
                            styles={{ root: { width: `calc(100% - ${showPrepopulatedIcon ? '14px' /* Leave a little room for the icon. */ : '0'})` } }}
                        />
                    </TooltipHost>

                    {showPrepopulatedIcon && (
                        <div className={pageStyles.prePopulatedIconContainer}>
                            <TooltipHost content={tooltips.serialNumberPrePopulatedBySupplier} delay={TooltipDelay.long}>
                                {/* Using a Link so the icon becomes focusable with keyboard navigation. */}
                                <Link aria-label={tooltips.serialNumberPrePopulatedBySupplier}>
                                    <FontIcon iconName='Info' className={commonStyles.pointer} />
                                </Link>
                            </TooltipHost>
                        </div>
                    )}
                </>
            )}
            { !props.item.requireAssetDataCollection && (
                <Text className={commonStyles.textCell}>
                    {commonString.notRequired}
                </Text>
            )}
        </>
    );
};
