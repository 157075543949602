import React from 'react';
import { ActionButton, Stack, TooltipDelay, TooltipHost } from '@fluentui/react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { showDetailsForLineItem } from '../../store/actions/pageActions/editPage.action';
import { AppDispatch } from '../../store/reduxStore';
import { componentStyles } from './DetailsActionButton.styles';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { showDetailsForPriorYearAccrualLineItem } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { tooltips } from '../../common/tooltips';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IDetailsActionButtonProps {
    usedOnPage: 'priorYearAccrual' | 'editOrCloseLineItem';
    item: PurchaseOrderLineItem | PriorYearAccrualLineItem;
}

export const DetailsActionButton: React.FunctionComponent<IDetailsActionButtonProps> = (props: IDetailsActionButtonProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Stack>
            <TooltipHost content={tooltips.viewLineDetails} delay={TooltipDelay.long}>
                <ActionButton
                    id={
                        `detailsButton-${
                            props.item instanceof PurchaseOrderLineItem ? `${props.item.purchaseOrderNumber}-${props.item.purchaseOrderLineNumber}` :
                            props.item instanceof PriorYearAccrualLineItem ? `${props.item.purchaseOrder}-${props.item.lineItem}` :
                            ''
                        }`
                    }
                    ariaLabel={
                        `${props.item instanceof PurchaseOrderLineItem ? `${props.item.purchaseOrderNumber}-${props.item.purchaseOrderLineNumber}` :
                            props.item instanceof PriorYearAccrualLineItem ? `${props.item.purchaseOrder}-${props.item.lineItem}` :
                            ''
                        } ${tooltips.viewLineDetails}` // Make the screen reader speak the line number plus tooltip content.
                    }
                    className={componentStyles.actionButtonDetails}
                    onClick={() => {
                        if (props.usedOnPage === 'editOrCloseLineItem' && props.item instanceof PurchaseOrderLineItem) {
                            telemetryService.trackEvent({ name: trackedEvent.detailsButtonClicked }, {
                                po: props.item.purchaseOrderNumber, line: props.item.purchaseOrderLineNumber
                            });
                            dispatch(showDetailsForLineItem(props.item));
                        } else if (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem) {
                            telemetryService.trackEvent({ name: trackedEvent.detailsButtonClicked }, {
                                po: props.item.purchaseOrder, line: props.item.lineItem
                            });
                            dispatch(showDetailsForPriorYearAccrualLineItem(props.item));
                        }
                    }}
                >
                    { props.item instanceof PurchaseOrderLineItem && (
                        <>
                            {props.item.purchaseOrderLineNumber}
                        </>
                    )}
                    { props.item instanceof PriorYearAccrualLineItem && (
                        <>
                            {/* On prior year accruals page, both PO and line number are shown. */}
                            {props.item.purchaseOrder}-{props.item.lineItem}
                        </>
                    )}
                </ActionButton>
            </TooltipHost>
        </Stack>
    );
};
