export interface ISupplier {
    supplierNumber: string;
    supplierName: string;
}

export class Supplier implements ISupplier {
    public supplierNumber: string;
    public supplierName: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ISupplier) {
        this.supplierNumber = jsonData.supplierNumber;
        this.supplierName = jsonData.supplierName;
    }
}
