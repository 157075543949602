import React, { useEffect } from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { Pivot, PivotItem, Stack } from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { UserProfile } from '../../models/user/userProfile';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { appConstants } from '../../common/appConstants';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { AccrualOutOfSyncTab } from './AccrualOutOfSyncTab';
import { AccrualFailuresTab, AccrualFailuresTabMode } from './AccrualFailuresTab';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { teachingBubbleClearArray } from '../../store/actions/app.action';
import { AppDispatch } from '../../store/reduxStore';

interface IPageProps extends ICommonPageProps {
}

export const AccrualDashboardPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const navigate = useNavigate();

    // Redux store selectors to get state from the store when it changes.
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    // Check if user is authorized to see this page.
    if (!(userProfile?.isAccrualsAdmin || userProfile?.isDri)) {
        navigate(`${appConstants.publicUrl}/AccessDenied`);
        return <></>;
    }

    return (
        <PageWrapper {...props}>
            <SectionWrapper>
                <Section>
                    <Stack tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <Pivot aria-label="Accrual Dashboard">
                                <PivotItem headerText="Accrual Failures">
                                    <AccrualFailuresTab mode={AccrualFailuresTabMode.AccrualFailures} />
                                </PivotItem>
                                <PivotItem headerText="Accruals Out Of Sync">
                                    <AccrualOutOfSyncTab />
                                </PivotItem>
                                <PivotItem headerText="Prior Year Accrual Failures">
                                    <AccrualFailuresTab mode={AccrualFailuresTabMode.PriorYearAccrualFailures} />
                                </PivotItem>
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Section>
            </SectionWrapper>
        </PageWrapper>
    );
};
