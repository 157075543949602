import React, { useEffect, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    ComboBox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IComboBox,
    IComboBoxOption,
    Label,
    Stack
} from '@fluentui/react';
import { componentStyles } from './VideoGuide.styles';
// Uses ReactPlayer, see:
// https://www.npmjs.com/package/react-player
// https://cookpete.com/react-player/
import ReactPlayer from 'react-player/file'// Import support for mp4 but not other formats.
import { commonString } from '../../common/commonString';
import { stackTokensNormalGap } from '../../common/common.styles';
import { appConfig } from '../appConfig';

/**
 * Initial video to show. Otherwise the video is based on the selected page.
 * Only adding to this enum when needed to show a particular video.
 */
export enum InitialVideo {
    Intro,
    QuarterlyTraining,
    Pya,
    PyaElevated,
    PyaElevatedAccountingDetails
}

interface IVideoGuideProps {
    isOpen: boolean;
    showInitialVideo?: InitialVideo;
    onDismissVideoGuide: () => void;
}

let optionKey: number = 0;

const introOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Intro (1:20)',
    data: 'Intro.mp4' // Put the file names into the option data.
};

const homeAndSearchOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Home page and search (1:44)',
    data: 'Home%20and%20search.mp4'
};

const editOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Edit PO line items (2:48)',
    data: 'Edit.mp4'
};

const closeLineOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Close line items (0:42)',
    data: 'Close%20line.mp4'
};

const userProfileAndDelegatesOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'User profile and delegates (0:52)',
    data: 'User%20profile%20and%20delegates.mp4'
};

const accessRequestOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Access request for Agent or Finance Controller (1:59)',
    data: 'Access%20request.mp4'
};

const priorYearAccrualOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Prior year accrual (1:31)',
    data: 'Prior%20year%20accrual.mp4'
};

const priorYearAccrualElevatedOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Prior year accrual for Agent or Finance Controller (1:40)',
    data: 'Prior%20year%20accrual%20elevated.mp4'
};

const priorYearAccrualElevatedAccountingDetailsOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Prior year accrual accounting details (1:32)',
    data: 'Prior%20year%20accrual%20elevated%20accounting%20details.mp4'
};

const quarterlyTrainingOption: IComboBoxOption = {
    key: optionKey += 1,
    text: 'Quarterly training',
    data: 'Quarterly%20training.mp4'
};

export const videoSelectionOptions: IComboBoxOption[] = [
    introOption,
    homeAndSearchOption,
    editOption,
    closeLineOption,
    userProfileAndDelegatesOption,
    accessRequestOption,
    priorYearAccrualOption,
    priorYearAccrualElevatedOption,
    priorYearAccrualElevatedAccountingDetailsOption,
    quarterlyTrainingOption
];

export const VideoGuide: React.FunctionComponent<IVideoGuideProps> = (props: IVideoGuideProps): JSX.Element => {
    const videoSelectionInputId: string = useId();
    const [selectedVideoKey, setSelectedVideoKey] = useState<number>(0);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>();

    /**
     * Effect for when the dialog opens.
     */
    useEffect(() => {
        if (props.isOpen) {
            if (props.showInitialVideo !== undefined) {
                switch (props.showInitialVideo) {
                    case InitialVideo.QuarterlyTraining:
                        changeSelection(quarterlyTrainingOption);
                        break;
                    case InitialVideo.Pya:
                        changeSelection(priorYearAccrualOption);
                        break;
                    case InitialVideo.PyaElevated:
                        changeSelection(priorYearAccrualElevatedOption);
                        break;
                    case InitialVideo.PyaElevatedAccountingDetails:
                        changeSelection(priorYearAccrualElevatedAccountingDetailsOption);
                        break;
                    case InitialVideo.Intro:
                    default:
                        changeSelection(introOption);
                        break;
                }
            } else {
                // Auto-select the video based on the current page url.
                // Using pathname with indexof so this will match on subpaths also.
                if (window.location.pathname.indexOf('/Home') > -1) {
                    changeSelection(homeAndSearchOption);
                } else if (window.location.pathname.indexOf('/SearchResults') > -1) {
                    changeSelection(homeAndSearchOption);
                } else if (window.location.pathname.indexOf('/Edit') > -1) {
                    changeSelection(editOption);
                } else if (window.location.pathname.indexOf('/CloseLine') > -1) {
                    changeSelection(closeLineOption);
                } else if (window.location.pathname.indexOf('/UserProfile') > -1) {
                    changeSelection(userProfileAndDelegatesOption);
                } else if (window.location.pathname.indexOf('/AccessRequest') > -1) {
                    changeSelection(accessRequestOption);
                } else if (window.location.pathname.indexOf('/PriorYearAccrual/Elevated') > -1) {
                    // Put this before the one below for proper path match!
                    changeSelection(priorYearAccrualElevatedOption);
                    // Not having an option for priorYearAccrualElevatedAccountingDetailsOption in this list.
                    // That video will be selectable on the PYA page.
                } else if (window.location.pathname.indexOf('/PriorYearAccrual') > -1) {
                    changeSelection(priorYearAccrualOption);
                } else {
                    changeSelection(homeAndSearchOption);
                }
            }   
        }
    }, [props.isOpen, props.showInitialVideo]);

    /**
     * Change selection.
     * @param option ComboBox option.
     */
    const changeSelection = (option: IComboBoxOption) => {
        setSelectedVideoKey(option.key as number);
        setSelectedVideoUrl(`${appConfig.current.settings.videoGuideRootUrl}${option.data as string}`);
    };

    /**
     * Video selection change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeVideoSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            changeSelection(option);
        }
    };

    const renderTitleSection = () => {
        return (
            <Stack horizontal tokens={stackTokensNormalGap} horizontalAlign='space-between'>
                <Stack.Item>
                    <h1 className={componentStyles.heading}>{commonString.videoTutorialGuides}</h1>
                </Stack.Item>
                <Stack.Item align='end'>
                    <Label htmlFor={videoSelectionInputId} className={componentStyles.videoSelectionLabel}>{commonString.videoGuideSelection}</Label>
                    <ComboBox
                        id={videoSelectionInputId}
                        ariaLabel={commonString.videoGuideSelection}
                        className={componentStyles.videoSelectionComboBox}
                        options={videoSelectionOptions}
                        selectedKey={selectedVideoKey}
                        onChange={onChangeVideoSelection}
                        useComboBoxAsMenuWidth={true}
                    />
                </Stack.Item>
            </Stack>
        );
    };

    return (
        <Dialog
            hidden={!props.isOpen}
            onDismiss={props.onDismissVideoGuide}
            dialogContentProps={{
                type: DialogType.normal,
                title: renderTitleSection(),
                subText: undefined
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth="60vw"
        >
            <div className={componentStyles.videoContainerResponsive}>
                <ReactPlayer
                    url={selectedVideoUrl}
                    width='100%'
                    height='100%'
                    controls={true}
                    className={componentStyles.reactPlayer}
                />
            </div>
            <DialogFooter>
                <DefaultButton onClick={props.onDismissVideoGuide} text="Close" />
            </DialogFooter>
        </Dialog>
    );
};
