// Common enums used throughout the app.

/**
 * Roles that the user can be in. These cannot be delegated to anyone else.
 */
export enum Role {
    Agent = 'Agent',
    FinanceController = 'Finance Controller',
    AccrualsAdmin = 'Accruals Admin',
    Dri = 'dri',

    // Normal end user with no specialized role.
    // Note this is not an actual role and is not saved to the RBAC store for the user record.
    // If a user has no role then they implicitly have this role.
    User = 'User'
}

/**
 * Access rights that can be delegated to others.
 * All users may access their own POs where they are the PO requestor, or any PO where they are marked
 * as the invoice or PO approver. These rights can be delegated to other users so those other users can
 * access the delegators POs.
 */
export enum AccessRight {
    InvoiceApprover = 'InvoiceApprover',
    PoRequestor = 'PoRequestor' // aka, PO Owner
}

/**
 * The type of acccess request data table mode.
 */
 export enum AccessRequestTableMode {
    RequestForYourself = 1,
    RequestForOthers = 2,
    RequestHistory = 3,
    CurrentAccess = 4
}

/**
 * The type of search operation to pass into the API query string parameters.
 */
export enum SearchOperation {
    Nop = 'NOP',
    Search = 'Search',
    Tile = 'Tile'
}

/**
 * Search operation parameter used with the single PO API search route "api/purchaseorders/{purchaseOrderNumber}" -
 * the GET based API that returns a single purchase order. This was used in the older GSR UI but is no longer used
 * in this new UI. The search API ("api/purchaseorders/advancedsearch") is used in most cases. While the singe PO
 * API is used on the edit page to load a single PO. None of these search operation parameters are used any longer.
 * Should be refactored.
 */
export enum SearchOperationParameter {
    None = 'None',
    Open = 'Open',
    DueNow = 'DueNow',
    OverDue = 'OverDue',
    UnavailableOwners = 'UnavailableOwners',
    UnavailableApprovers = 'UnavailableApprovers',
    ReceivedByFC = 'ReceivedByFC'
}

/**
 * The type of source to pass into the API query string parameters.
 */
export enum Source {
    SAP = 'SAP'
}

/**
 * Type of line items to include, asset, service, or all.
 */
export enum PoType {
    Asset = 0,
    Service = 1,
    All = 2
}
