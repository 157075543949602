import React, { useEffect, useMemo, useState } from 'react';
import { ICommonPageProps } from '../../common/common.types';
import {
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { commonString } from '../../common/commonString';
import { validationConstants } from '../../common/validationConstants';

interface IExportDialogProps extends ICommonPageProps {
    display: boolean;
    lineTotal: number;
    currentReceivedAmount: number;
    maxAddAllowed: number;
    maxSubtractAllowed: number;
    onOkClicked: (addAmount: number) => void;
    onCancelClicked: () => void;
}

/**
 * Received amount adjust dialog.
 * @param props Export dialog props.
 * @returns JSX for the component.
 */
export const ReceivedAmountAdjustDialog: React.FunctionComponent<IExportDialogProps> = (props: IExportDialogProps): JSX.Element => {
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [adjustReceivedAmountInput, setAdjustReceivedAmountInput] = React.useState<string>('');
    const [adjustReceivedAmount, setAdjustReceivedAmount] = React.useState<number>(0);

    /**
     * Effect that will reset the fields when the dialog is displayed.
     */
    useEffect(() => {
        if (props.display) {
            setAdjustReceivedAmountInput('');
            setAdjustReceivedAmount(0);
            setErrorMsg('');
        }
    }, [props.display]);

    const checkDisabled = useMemo<boolean>(() => {
        if (errorMsg.length > 0 || adjustReceivedAmount === 0) {
            return true;
        }

        if (adjustReceivedAmount > 0 && adjustReceivedAmount > props.maxAddAllowed) {
            return true;
        }

        // If the user entered a negative number, then the absolute value of that number must be less than the
        // subtract allowed amount.
        if (adjustReceivedAmount < 0 && Math.abs(adjustReceivedAmount) > props.maxSubtractAllowed) {
            return true;
        }

        return false;
    }, [adjustReceivedAmount, errorMsg.length, props.maxAddAllowed, props.maxSubtractAllowed]);

    return (
        <GenericDialog
            displayDialog={props.display}
            title='Adjust received amount'
            content={
                <Stack tokens={stackTokensNormalGap}>
                    <Stack.Item>
                        <Text variant="medium">
                            Enter the amount you would like to add or subtract.<br/><br/>
                            The line total is {numberAsLocaleString(props.lineTotal)}.
                            The current received amount is {numberAsLocaleString(props.currentReceivedAmount)}.
                            The maximum amount you can add is {numberAsLocaleString(props.maxAddAllowed)}.
                            The maximum amount you can subtract is {numberAsLocaleString(props.maxSubtractAllowed)}.
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <TooltipHost content={validationConstants.receivedAmountAdjust.tooltip} delay={TooltipDelay.long}>
                            <TextField
                                id='receivedAmountAdjust'
                                autoComplete='off'
                                ariaLabel={commonString.adjustReceivedAmount}
                                value={adjustReceivedAmountInput}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    newValue = newValue || '';
                                    newValue = newValue.trim();

                                    if (newValue.length > 0 &&
                                        (
                                            newValue?.length > validationConstants.receivedAmountAdjust.maxLength! ||
                                            !RegExp(validationConstants.receivedAmountAdjust.pattern!).test(newValue)
                                        )
                                    ) {
                                        setErrorMsg(validationConstants.receivedAmountAdjust.errorMsg!);
                                    } else {
                                        setErrorMsg('');
                                    }

                                    setAdjustReceivedAmountInput(newValue);
                                    const newValueAsNumber = newValue && newValue.length > 0 ? Number(newValue) : 0;
                                    // If the user started by entering a decimal point, then the number will be NaN.
                                    if (!Number.isNaN(newValueAsNumber)) {
                                        setAdjustReceivedAmount(newValueAsNumber);
                                    }
                                }}
                                onKeyDown={(key: React.KeyboardEvent<HTMLElement>) => {
                                    if (key.code === 'Enter' && !checkDisabled) {
                                        props.onOkClicked(adjustReceivedAmount);
                                    }
                                }}
                                errorMessage={errorMsg}
                            />
                        </TooltipHost>
                    </Stack.Item>
                </Stack>
            }
            mode={GenericDialogMode.OkCancel}
            disableOkButton={checkDisabled}
            onOkClicked={() => props.onOkClicked(adjustReceivedAmount)}
            onCancelClicked={props.onCancelClicked}
            width={400}
        />
    );
};
