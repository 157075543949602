import { FunctionDetailCode } from './functionDetailCode';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IFunctionCode extends IHierarchyNodeCommon {
    functionDetailCode: FunctionDetailCode[];
}

export class FunctionCode implements IFunctionCode {
    public typeName: string = HierarchyConstants.className.channel.functionCode;
    public code: string;
    public description: string;
    public functionDetailCode: FunctionDetailCode[] = [];
    public children?: IHierarchyNodeCommon[] = this.functionDetailCode; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IFunctionCode) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.functionDetailCode) {
            for (let i: number = 0; i < jsonData.functionDetailCode.length; i++) {
                this.functionDetailCode.push(new FunctionDetailCode(jsonData.functionDetailCode[i]));
            }
        }
    }
}
