import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiLoadAuditHistory } from '../../actions/miscActions/auditHistory.action';

export interface IAuditHistoryReducerState {
    apiLoadAuditHistory: IApiLoadAuditHistory;
}

const initialAuditHistoryReducerState: IAuditHistoryReducerState = {
    apiLoadAuditHistory: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        auditHistoryEvents: undefined
    }
}

export const auditHistoryReducer: Reducer<IAuditHistoryReducerState, AnyAction> = (
    state: IAuditHistoryReducerState = initialAuditHistoryReducerState, action: AnyAction
): IAuditHistoryReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_AUDIT_HISTORY: {
            const payload: IApiLoadAuditHistory = action.payload as IApiLoadAuditHistory;
            return {
                ...state,
                apiLoadAuditHistory: { ...payload }
            } as IAuditHistoryReducerState
        }
        default:
    }

    return state;
};
