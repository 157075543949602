export interface IPoePodAvailability {
    available?: boolean;
    updatedDateUtc?: Date;
    updatedBy?: string;
}

export class PoePodAvailability implements IPoePodAvailability {
    public available?: boolean;
    public updatedDateUtc?: Date;
    public updatedBy?: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPoePodAvailability) {
        this.available = jsonData.available;
        this.updatedDateUtc = jsonData.updatedDateUtc ? new Date(jsonData.updatedDateUtc) : undefined;
        this.updatedBy = jsonData.updatedBy;
    }
}
