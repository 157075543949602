import { AnyAction } from '@reduxjs/toolkit';
import { AccessRequest } from '../../../models/user/accessRequest';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';

/**
 * Payload used with callApiDailyOpenAccrualNon1010Reports action.
 */
export interface IApiSubmitAccessRequest extends ICallApiBase {
}

/**
 * Call API to submit access request.
 * @param accessRequest Access request.
 * @returns Redux dispatch function.
 */
export const callApiSubmitAccessRequest = (accessRequest: AccessRequest): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_SUBMIT_ACCESS_REQUEST,
        async () => {
            return await receiptingApiClient.submitAccessRequest(accessRequest);
        }
    );
};

/**
 * Payload used with callApiLoadFinanceControllerAccessRequestHistory action.
 */
export interface IApiLoadFinanceControllerAccessRequestHistory extends ICallApiBase {
    accessRequests?: AccessRequest[] | null;
}

/**
 * Call API to load finance controller access request history.
 * @returns Redux dispatch function.
 */
export const callApiLoadFinanceControllerAccessRequestHistory = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<AccessRequest[] | null>(
        actionTypes.API_LOAD_FINANCE_CONTROLLER_ACCESS_REQUEST_HISTORY,
        async () => {
            return await receiptingApiClient.loadFinanceControllerAccessRequestHistory();
        },
        (payload: IApiLoadFinanceControllerAccessRequestHistory, data: AccessRequest[] | null) => {
            payload.accessRequests = data;
        }
    );
};

/**
 * Payload used with callApiLoadLoadAccessRequestsForAgent action.
 */
export interface IApiLoadAccessRequestsForAgent extends ICallApiBase {
    accessRequests?: AccessRequest[] | null;
}

/**
 * Call API to load access requests for agent.
 * @returns Redux dispatch function.
 */
export const callApiLoadAccessRequestsForAgent = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<AccessRequest[] | null>(
        actionTypes.API_LOAD_ACCESS_REQUESTS_FOR_AGENT,
        async () => {
            return await receiptingApiClient.loadAccessRequestsForAgent();
        },
        (payload: IApiLoadAccessRequestsForAgent, data: AccessRequest[] | null) => {
            payload.accessRequests = data;
        }
    );
};

/**
 * Payload used with callApiLoadAccessRequestsForFinanceController action.
 */
export interface IApiLoadAccessRequestsForFinanceController extends ICallApiBase {
    accessRequests?: AccessRequest[] | null;
}

/**
 * Call API to load access requests for finance controller.
 * @returns Redux dispatch function.
 */
export const callApiLoadAccessRequestsForFinanceController = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<AccessRequest[] | null>(
        actionTypes.API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER,
        async () => {
            return await receiptingApiClient.loadAccessRequestsForFinanceController();
        },
        (payload: IApiLoadAccessRequestsForFinanceController, data: AccessRequest[] | null) => {
            payload.accessRequests = data;
        }
    );
};

/**
 * Payload used with callApiIsAgentAccessRequestApprover action.
 */
export interface IApiIsAgentAccessRequestApprover extends ICallApiBase {
    isAgentAccessRequestApprover?: boolean | null;
}

/**
 * Call API to check if user is an agent access request approver.
 * @returns Redux dispatch function.
 */
export const callApiIsAgentAccessRequestApprover = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<boolean | null>(
        actionTypes.API_IS_AGENT_ACCESS_REQUEST_APPROVER,
        async () => {
            return await receiptingApiClient.sgCheck('Receipting Access Request Approvers');
        },
        (payload: IApiIsAgentAccessRequestApprover, data: boolean | null) => {
            payload.isAgentAccessRequestApprover = data;
        }
    );
};

/**
 * Action creator: Reset call state for api to load access requests for finance controller.
 * @returns Redux action.
 */
export const resetApiLoadAccessRequestsForFinanceController = (): AnyAction => {
    return {
        type: actionTypes.RESET_API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER
    } as AnyAction;
};

/**
 * Action creator: Reset call state for api to load access requests for agent.
 * @returns Redux action.
 */
export const resetApiLoadAccessRequestsForAgent = (): AnyAction => {
    return {
        type: actionTypes.RESET_API_LOAD_ACCESS_REQUESTS_FOR_AGENT
    } as AnyAction;
};
