import React from 'react';
import { componentStyles } from './CustomPieChart.styles';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { numberAsLocaleString } from '../../common/common.func.transform';

/**
 * Custom pie chart props.
 */
export interface ICustomTooltipProps extends TooltipProps<number, string> {
}

/**
 * Custom tooltip used with charts.
 * @param props Tooltip props.
 */
export const CustomTooltip: React.FunctionComponent<ICustomTooltipProps> = (props: ICustomTooltipProps): JSX.Element => {
    if (props.active && props.payload && props.payload.length) {
        const payloadItem: Payload<number, string> = props.payload[0];
        return (
            <div className={componentStyles.pieChartTooltip}>
                {`${numberAsLocaleString(payloadItem.value, 0)} ${payloadItem.name} (${numberAsLocaleString(payloadItem.payload?.payload?.percent, 2)}%)`}
            </div>
        );
    }
    return <></>;
};
