import { CorpApproval, ICorpApproval } from './corpApproval';
import { IInvoiceAvailability, InvoiceAvailability } from './invoiceAvailability';
import { IPoePodAvailability, PoePodAvailability } from './poePodAvailability';
import { IStcApproval, StcApproval } from './stcApproval';

export interface ICorpDetails {
    corpApproval?: ICorpApproval;
    stcApproval?: IStcApproval;
    poePodAvailability?: IPoePodAvailability;
    invoiceAvailability?: IInvoiceAvailability;
}

export class CorpDetails implements ICorpDetails {
    public corpApproval?: ICorpApproval;
    public stcApproval?: IStcApproval;
    public poePodAvailability?: IPoePodAvailability;
    public invoiceAvailability?: IInvoiceAvailability;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ICorpDetails) {
        this.corpApproval = jsonData.corpApproval ? new CorpApproval(jsonData.corpApproval) : undefined;
        this.stcApproval = jsonData.stcApproval ? new StcApproval(jsonData.stcApproval) : undefined;
        this.poePodAvailability = jsonData.poePodAvailability ? new PoePodAvailability(jsonData.poePodAvailability) : undefined;
        this.invoiceAvailability = jsonData.invoiceAvailability ? new InvoiceAvailability(jsonData.invoiceAvailability) : undefined;
    }
}
