/*
General purpose functions for GUIDs.
*/

/**
 * Create a new guid.
 * @returns New guid string.
 */
export const createGuid = (): string => {
    const guidPattern: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return guidPattern.replace(/[xy]/g, c => {
        const r = Number('0.' + window.crypto.getRandomValues(new Uint32Array(1))[0]) * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

/**
 * Create a new Application Insights style unique id (shorter than a standard guid - 5 characters).
 * Based off newId in Application Insights source code Util.ts: https://github.com/Microsoft/ApplicationInsights-JS/blob/master/JavaScript/JavaScriptSDK/Util.ts
 * This function is not readily available from the window.appInsights object.
 * This will create a small random 5 character string similar to: wVLno
 * @returns Short guid.
 */
export const createShortGuid = (): string => {
    const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let result = '';
    let random = Math.random() * 1073741824; // 5 symbols in base64, almost maxint.

    while (random > 0) {
        const char = base64Chars.charAt(random % 64);
        result += char;
        random = Math.floor(random / 64);
    }

    return result;
}

/**
 * Checks if the Guid is valid.
 * @param guid Guid
 */
export const isValidGuid = (guid: string): boolean => {
    const regex: RegExp = new RegExp(/^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/);
    return regex.test(guid);
}
