import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    accrualJsonReferenceContainer: {
        height: '60vh',
        width: '25%',
        overflow: 'auto',
        display: 'inline-block'
    } as IStyle,
    accrualJsonReferenceHeading: {
        textDecoration: 'underline'
    }as IStyle,
    accrualJsonMainContainer: {
        height: '60vh',
        width: '75%',
        overflow: 'auto',
        display: 'inline-block'
    } as IStyle,
    accrualAuditHistoryJsonContainer: {
        height: '60vh',
        width: '100%',
        overflow: 'auto'
    }
});
