import React from 'react';
import { componentStyles } from './DataTile.styles';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { ITileProps, Tile } from '../Tile/Tile';

/**
 * Data tile props.
 */
export interface IDataTileProps extends ITileProps {
    dollarSymbol: boolean;
    tileValue: number;
    tileText: string;
}

/**
 * Data tile.
 * @param props Data tile props.
 */
export const DataTile: React.FunctionComponent<IDataTileProps> = (props: IDataTileProps): JSX.Element => {
    return (
        <Tile barColor={props.barColor}>
            <div className={componentStyles.tileValue}>
                {`${props.dollarSymbol ? '$' : ''}${numberAsLocaleString(props.tileValue, 0)}`}
            </div>
            <div className={componentStyles.tileText}>
                {props.tileText}
            </div>
        </Tile>          
    );
};
