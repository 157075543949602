import React, { useMemo } from 'react';
import { CoherenceTreeItem, CoherenceTreeView } from '@coherence-design-system/controls';
import { useAppSelector } from '../../store/hooks';
import { ICustomCoherenceTreeItem } from './ICustomCoherenceTreeItem';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    FontIcon,
    IModalProps,
    Pivot,
    PivotItem,
    PrimaryButton,
    Stack,
    Text
} from '@fluentui/react';
import { componentStyles, treeView } from './HierarchySelection.styles';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { IHierarchyTreeItemState } from './IHierarchyTreeItemState';
import { getHierarchySelectedCount } from './hierarchyUtil';
import { stackTokensNormalGap } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { IHierarchyState } from '../../store/reducers/app.reducer';

export interface IHierarchySelectionDialogProps {
    instanceId: string,
    readonly: boolean;
    displayDialog: boolean;

    financeGeographyCustomCoherenceTreeItems: ICustomCoherenceTreeItem[];
    channelFunctionCustomCoherenceTreeItems: ICustomCoherenceTreeItem[];
    executiveFunctionCustomCoherenceTreeItems: ICustomCoherenceTreeItem[];

    showGeography: boolean; // Optionally hide the geography selection if not needed.
    showWarningIfBothChannelAndExecSelected: boolean; // Optionally show a warning if nodes from both channel and exec are selected. Not all use cases need this.
    
    onClear?: () => void;
    onCancel?: () => void;
    onOk?: () => void;
}

/**
 * Hierarchy selection dialog.
 * @param props Hierarchy selection dialog props.
 * @returns JSX for the component.
 */
export const HierarchySelectionDialog: React.FunctionComponent<IHierarchySelectionDialogProps> = (props: IHierarchySelectionDialogProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const hierarchyStateInstances: IKeyValuePair<IHierarchyState> = 
        useAppSelector<IKeyValuePair<IHierarchyState>>(state => state.appReducer!.hierarchyStateInstances);

    /**
     * Memoized count of selected finance geography nodes.
     */
    const financeGeographySelectedCount = useMemo<number>(() => {
        const financeGeographyHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateInstances[props.instanceId]?.financeGeographyHierarchyTreeItemStates;
        if (financeGeographyHierarchyTreeItemStates) {
            return getHierarchySelectedCount(financeGeographyHierarchyTreeItemStates);
        }
        return 0;
    }, [hierarchyStateInstances, props.instanceId]);

    /**
     * Memoized count of selected channel function nodes.
     */
    const channelFunctionSelectedCount = useMemo<number>(() => {
        const channelFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateInstances[props.instanceId]?.channelFunctionHierarchyTreeItemStates;
        if (channelFunctionHierarchyTreeItemStates) {
            return getHierarchySelectedCount(channelFunctionHierarchyTreeItemStates);
        }
        return 0;
    }, [hierarchyStateInstances, props.instanceId]);

    /**
     * Memoized count of selected executive function nodes.
     */
    const executiveFunctionSelectedCount = useMemo<number>(() => {
        const executiveFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateInstances[props.instanceId]?.executiveFunctionHierarchyTreeItemStates;
        if (executiveFunctionHierarchyTreeItemStates) {
            return getHierarchySelectedCount(executiveFunctionHierarchyTreeItemStates);
        }
        return 0;
    }, [hierarchyStateInstances, props.instanceId]);
    
    /**
     * Memoized indicator to show the warning text or not.
     */
    const showWarning = useMemo<boolean>(() => {
        if (!props.showWarningIfBothChannelAndExecSelected) {
            return false;
        }
        if (channelFunctionSelectedCount > 0 && executiveFunctionSelectedCount > 0) {
            return true;
        }
        return false;
    }, [channelFunctionSelectedCount, executiveFunctionSelectedCount, props.showWarningIfBothChannelAndExecSelected]);

    return (
        <Dialog
            hidden={!props.displayDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: commonString.hierarchy,
                titleProps: {
                    className: componentStyles.dialogTitle
                },
                showCloseButton: false
            }}
            modalProps={{
                containerClassName: componentStyles.dialogContainer,
                isBlocking: true
            } as IModalProps}
            minWidth="500px"
        >
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Pivot aria-label="Hierarchy">
                        {props.showGeography && (
                            <PivotItem onRenderItemLink={() => {
                                return (
                                    <div className={componentStyles.pivotItemHeading}>
                                        <Text block variant="medium">Geography</Text>
                                        <Text block variant="small">{financeGeographySelectedCount} items selected</Text>
                                    </div>
                                );
                            }}>
                                <CoherenceTreeView
                                    styles={treeView}
                                    data={props.financeGeographyCustomCoherenceTreeItems}
                                    onItemToggled={(item: CoherenceTreeItem, isExpanded: boolean) => {
                                        item.isExpanded = isExpanded;
                                    }}
                                />
                            </PivotItem>
                        )}
                        {/* Executive tab ordered before Channel as per business. */}
                        <PivotItem onRenderItemLink={() => {
                            return (
                                <div className={componentStyles.pivotItemHeading}>
                                    <Text block variant="medium">Executive</Text>
                                    <Text block variant="small">{executiveFunctionSelectedCount} items selected</Text>
                                </div>
                            );
                        }}>
                            <CoherenceTreeView
                                styles={treeView}
                                data={props.executiveFunctionCustomCoherenceTreeItems}
                                onItemToggled={(item: CoherenceTreeItem, isExpanded: boolean) => {
                                    item.isExpanded = isExpanded;
                                }}
                            />
                        </PivotItem>
                        <PivotItem onRenderItemLink={() => {
                            return (
                                <div className={componentStyles.pivotItemHeading}>
                                    <Text block variant="medium">Channel</Text>
                                    <Text block variant="small">{channelFunctionSelectedCount} items selected</Text>
                                </div>
                            );
                        }}>
                            <CoherenceTreeView
                                styles={treeView}
                                data={props.channelFunctionCustomCoherenceTreeItems}
                                onItemToggled={(item: CoherenceTreeItem, isExpanded: boolean) => {
                                    item.isExpanded = isExpanded;
                                }}
                            />
                        </PivotItem>
                    </Pivot>
                </Stack.Item>
                {showWarning && (
                    <Stack.Item>
                        <Text variant="medium">
                            <FontIcon iconName="Warning" className={componentStyles.warningIcon} />
                            You have selected nodes from both the Executive and Channel hierarchies. This will cause search results to include POs mapped to either one. It is recommended to choose nodes from one of these two hierarchies, but not both.
                        </Text>
                    </Stack.Item>
                )}
            </Stack>
            <DialogFooter>
                {!props.readonly && (
                    <DefaultButton
                        className={componentStyles.clearButton}
                        onClick={
                            () => {
                                if (props.onClear) {
                                    props.onClear();
                                }
                            }
                        }
                        text="Clear"
                    />
                )}
                <PrimaryButton
                    onClick={
                        () => {
                            if (props.onOk) {
                                props.onOk();
                            }
                        }
                    }
                    text="Ok"
                />
                {!props.readonly && (
                    <DefaultButton
                        onClick={
                            () => {
                                if (props.onCancel) {
                                    props.onCancel();
                                }
                            }
                        }
                        text="Cancel"
                    />
                )}
            </DialogFooter>
        </Dialog>
    );
};
