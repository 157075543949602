import { AnyAction, Reducer } from 'redux';
import { TileSet } from '../../../models/dashboard/tileSet';
import { ViewAsRoleOptionKey } from '../../../pages/HomePage/homePageConstants';
import * as actionTypes from '../../actions/actionTypes';

// The home page also makes use of search.action.tsx and search.reducer.tsx for common search
// functionality used across multiple pages.

export interface IHomePageReducerState {
    currentTileSet: TileSet;
    selectedViewAsRoleOptionKey: ViewAsRoleOptionKey;
}

const initialHomePageReducerState: IHomePageReducerState = {
    currentTileSet: TileSet.Home,
    selectedViewAsRoleOptionKey: ViewAsRoleOptionKey.ForYou
}

export const homePageReducer: Reducer<IHomePageReducerState, AnyAction> = (
    state: IHomePageReducerState = initialHomePageReducerState, action: AnyAction
): IHomePageReducerState => {
    switch (action.type) {
        case actionTypes.CHANGE_TILESET: {
            return {
                ...state,
                currentTileSet: action.tileSet,
                selectedViewAsRoleOptionKey: action.viewAsRoleOptionKey
            } as IHomePageReducerState
        }
        default:
    }

    return state;
};
