import React, { useEffect, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    FontIcon,
    IModalProps,
    Label,
    List,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap, stackTokensSmallGap } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { Supplier } from '../../models/domain/supplier';
import { componentStyles } from './SupplierSelection.styles';
import { validationConstants } from '../../common/validationConstants';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';

export interface ISupplierSelectionDialogProps {
    readonly: boolean;
    displayDialog: boolean;
    selectedSuppliers: Supplier[];
    onDismiss?: (selectedSuppliers: Supplier[]) => void;
}

/**
 * Supplier selection dialog.
 * @param props Supplier selection dialog props.
 * @returns JSX for the component.
 */
export const SupplierSelectionDialog: React.FunctionComponent<ISupplierSelectionDialogProps> = (props: ISupplierSelectionDialogProps): JSX.Element => {
    const supplierNameOrNumberInputId: string = useId();
    const [filterSupplier, setFilterSupplier] = useState<string>('');
    const [supplierValidationError, setSupplierValidationError] = useState<string>('');
    const [supplierApiError, setSupplierApiError] = useState<string>('');
    const [selectedSuppliers, setSelectedSuppliers] = useState<Supplier[]>([]);
    const [searchResultSuppliers, setSearchResultSuppliers] = useState<Supplier[]>([]);
    const [apiCallSupplierLookupRunning, setApiCallSupplierLookupRunning] = useState<boolean>(false);

    /**
     * Effect for when display dialog prop changes.
     */
    useEffect(() => {
        // If switched to display, then...
        if (props.displayDialog) {
            // Reset the search results, so that prior search results are not displayed.
            setSearchResultSuppliers([]);
            // Reset the filter input.
            setFilterSupplier('');
        }
    }, [props.displayDialog])

    /**
     * Effect for when selected suppliers changes via props.
     */
    useEffect(() => {
        // Put selected suppliers into component state.
        setSelectedSuppliers([...props.selectedSuppliers]);
    }, [props.selectedSuppliers]);

    /**
     * Dismiss button event handler.
     */
    const dismiss = () => {
        if (props.onDismiss) {
            props.onDismiss(selectedSuppliers);
        }
    };

    /**
     * Search button clicked event handler.
     */
    const searchButtonClicked = async () => {
        setApiCallSupplierLookupRunning(true);
        setSearchResultSuppliers([]);

        try {
            // Note we are not using Redux here (no dispatch to store). Just using regular api call with await.
            const suppliers: Supplier[] | null = await receiptingApiClient.searchSuppliers(filterSupplier);
            setSearchResultSuppliers(suppliers || []);
        } catch (err: any) {
            setSupplierApiError('Search failed');
        }

        setApiCallSupplierLookupRunning(false);
    };

    /**
     * Add supplier button clicked event handler.
     * @param supplier: Supplier.
     */
    const addSupplierButtonClicked = (supplier: Supplier) => {
        setSelectedSuppliers(prev => {
            const newSuppliers: Supplier[] = [...prev];
            const index: number = newSuppliers.findIndex(x => x.supplierNumber === supplier.supplierNumber);
            if (index === -1) {
                newSuppliers.push(supplier);
            }
            return newSuppliers;
        });
    };

    /**
     * Remove supplier button clicked event handler.
     * @param supplier: Supplier.
     */
    const removeSupplierButtonClicked = (supplier: Supplier) => {
        setSelectedSuppliers(prev => {
            const newSuppliers: Supplier[] = [...prev];
            const index: number = newSuppliers.findIndex(x => x.supplierNumber === supplier.supplierNumber);
            if (index > -1) {
                newSuppliers.splice(index, 1);
            }
            return newSuppliers;
        });
    };

    /**
     * Render supplier details for list item.
     * @param supplier Suppler.
     * @returns JSX.
     */
    const renderSupplierDetails = (supplier: Supplier): JSX.Element => {
        return (
            <div className={componentStyles.listItemDetailsContainer}>
                <Text variant="medium" block>{supplier.supplierName}</Text>
                <Text variant="smallPlus" block>{supplier.supplierNumber}</Text>
            </div>
        );
    };

    /**
     * On render cell for search result list.
     * @param item Item to render (the Supplier).
     * @param index Item index.
     * @param isScrolling Is scrolling.
     * @returns JSX element.
     */
    const onRenderCellSearchResultList = (item?: Supplier, index?: number, isScrolling?: boolean): React.ReactNode => {
        if (item) {
            const supplier: Supplier = item;
            return (
                <div className={componentStyles.listItem}>
                    {renderSupplierDetails(supplier)}
                    {!props.readonly && (
                        <div className={componentStyles.listItemButtonContainer}>
                            <DefaultButton
                                className={componentStyles.addRemoveSupplierButton}
                                ariaLabel='Add'
                                onClick={() => addSupplierButtonClicked(supplier)}
                                text="+"
                            />
                        </div>
                    )}
                </div>
            );
        }
        return <></>;
    };

    /**
     * On render cell for seected supplier list.
     * @param item Item to render (the Supplier).
     * @param index Item index.
     * @param isScrolling Is scrolling.
     * @returns JSX element.
     */
    const onRenderCellSelectedSupplierList = (item?: Supplier, index?: number, isScrolling?: boolean): React.ReactNode => {
        if (item) {
            const supplier: Supplier = item;
            return (
                <div className={componentStyles.listItem}>
                    {renderSupplierDetails(supplier)}
                    {!props.readonly && (
                        <div className={componentStyles.listItemButtonContainer}>
                            <DefaultButton
                                className={componentStyles.addRemoveSupplierButton}
                                ariaLabel='Remove'
                                onClick={() => removeSupplierButtonClicked(supplier)}
                                text="-"
                            />
                        </div>
                    )}
                </div>
            );
        }
        return <></>;
    };

    return (
        <Dialog
            hidden={!props.displayDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: commonString.suppliers,
                titleProps: {
                    className: componentStyles.dialogTitle
                },
                showCloseButton: false
            }}
            modalProps={{
                containerClassName: componentStyles.dialogContainer,
                isBlocking: true
            } as IModalProps}
            minWidth={props.readonly ? '300px' : '600px'}
        >
            <Stack horizontal tokens={stackTokensSmallGap}>

                {/* Left side dialog: Supplier search and result list. Only display if not readonly. */}
                {!props.readonly && (
                    <>
                        <Stack.Item>
                            <Stack tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <Stack horizontal tokens={stackTokensSmallGap}>
                                        <Stack.Item>
                                            <Label htmlFor={supplierNameOrNumberInputId}>{commonString.supplierNameNumber}</Label>
                                            <TooltipHost content={validationConstants.supplierNameOrNumber.tooltip} delay={TooltipDelay.long}>
                                                <TextField
                                                    id={supplierNameOrNumberInputId}
                                                    autoComplete='off'
                                                    ariaLabel={`${commonString.supplierNameNumber} ${validationConstants.supplierNameOrNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                                    className={componentStyles.supplierTextField}
                                                    value={filterSupplier}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        newValue = newValue || '';
                                                        if (newValue.length > 0 && (newValue.length > validationConstants.supplierNameOrNumber.maxLength! ||
                                                            !RegExp(validationConstants.supplierNameOrNumber.pattern!).test(newValue))) {
                                                            setSupplierValidationError(validationConstants.supplierNameOrNumber.errorMsg!);
                                                        } else {
                                                            setSupplierValidationError('');
                                                        }
                                                        setFilterSupplier(newValue);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            searchButtonClicked();
                                                        }
                                                    }}
                                                    errorMessage={supplierValidationError}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DefaultButton
                                                className={componentStyles.searchSupplierButton}
                                                ariaLabel='Search'
                                                onClick={searchButtonClicked}
                                                disabled={apiCallSupplierLookupRunning || !!supplierValidationError}
                                            >
                                                {apiCallSupplierLookupRunning ? (
                                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerGrid} />
                                                ) : (
                                                    <FontIcon iconName="Search" />
                                                )}
                                            </DefaultButton>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                {supplierApiError && (
                                    <Stack.Item>
                                        <Text variant="medium">{supplierApiError}</Text>
                                    </Stack.Item>
                                )}
                                <Stack.Item>
                                    <List
                                        className={componentStyles.list}
                                        items={searchResultSuppliers}
                                        onRenderCell={onRenderCellSearchResultList}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        {/* Middle dialog: Divider. */}
                        <Stack.Item>
                            <div className={componentStyles.dialogDivider}></div>
                        </Stack.Item>
                    </>
                )}

                {/* Right side dialog: Selected suppliers list. */}
                <Stack.Item>
                    <Stack tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <Label>Selected Suppliers</Label>
                        </Stack.Item>
                        <Stack.Item>
                            <List
                                className={componentStyles.list}
                                items={selectedSuppliers}
                                onRenderCell={onRenderCellSelectedSupplierList}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            <DialogFooter>
                <PrimaryButton onClick={() => dismiss()} text="Ok" />
            </DialogFooter>
        </Dialog>
    );
};
