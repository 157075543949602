export interface IServiceAlert {
    message: string;
    display: boolean;
}

export class ServiceAlert implements IServiceAlert {
    public message: string;
    public display: boolean;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IServiceAlert) {
        this.message = jsonData.message;
        this.display = jsonData.display;
    }
}
