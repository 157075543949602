import React, { useMemo } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton
} from '@fluentui/react';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { PurchaseOrderLineItemPair } from '../../models/purchaseOrder/purchaseOrderLineItemPair';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IComponentProps {
    showDialog: boolean;
    priorYearAccrualLineItems: PriorYearAccrualLineItem[];
    onYesClicked: (poLines: PurchaseOrderLineItemPair[]) => void;
    onNoClicked: () => void;
}

export const ConfirmRetainDialog: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    /**
     * Memoized field for selection count.
     */
    const selectedCount: number = useMemo(() => {
        const selectedLines: PriorYearAccrualLineItem[] = props.priorYearAccrualLineItems;
        return selectedLines.length;
    }, [props.priorYearAccrualLineItems]);

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={() => props.onNoClicked()}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Confirm Retain',
                subText: `I have validated and confirm to retain the selected PO line${selectedCount > 1 ? 's' : ''}. Once invoiced, I will close the PO line${selectedCount > 1 ? 's' : ''}. Retention will last 3 months from today's date. You will not be notified via email about these items until after the retention period has expired.`
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth="400px"
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.pyaConfirmRetainButtonClicked });

                        const selectedLines: PriorYearAccrualLineItem[] = props.priorYearAccrualLineItems.filter(x => x.isSelected) || [];
                        const poLines: PurchaseOrderLineItemPair[] = selectedLines.map(x => {
                            return new PurchaseOrderLineItemPair(Number(x.purchaseOrder), Number(x.lineItem));
                        });
                        
                        props.onYesClicked(poLines);
                    }}
                    text="Yes"
                />
                <DefaultButton
                    onClick={() => props.onNoClicked()}
                    text="No" 
                />
            </DialogFooter>
        </Dialog>
    );
};
