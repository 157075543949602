import { PurchaseOrderLineReceiptsAndAccruals } from './purchaseOrderLineReceiptsAndAccruals';

export interface IPOLineReceiptAccrualDetail {
    poLineReceiptsAndAccruals: PurchaseOrderLineReceiptsAndAccruals[];
    lastRefreshTime?: Date;
}

export class POLineReceiptAccrualDetail implements IPOLineReceiptAccrualDetail {
    public poLineReceiptsAndAccruals: PurchaseOrderLineReceiptsAndAccruals[] = [];
    public lastRefreshTime?: Date;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPOLineReceiptAccrualDetail) {
        if (jsonData.poLineReceiptsAndAccruals) {
            for (let i: number = 0; i < jsonData.poLineReceiptsAndAccruals.length; i++) {
                this.poLineReceiptsAndAccruals.push(new PurchaseOrderLineReceiptsAndAccruals(jsonData.poLineReceiptsAndAccruals[i]));
            }
        }
        this.lastRefreshTime = jsonData.lastRefreshTime ? new Date(jsonData.lastRefreshTime) : undefined;
    }
}
