import { cloneDeep } from 'lodash';
import { IFieldValidation } from './validationConstants';

/*
General purpose functions for HTML manipulation or utility purposes.
*/

/**
 * Check if variable is null or undefined. Not checking for empty string or 0 (both are falsey).
 * @param x Value to check if null or undefined.
 * @returns True if null or undefined.
 */
export const isNullOrUndefined = (x?: any): boolean => {
    if (x === null) {
        return true;
    } else if (x === undefined) {
        return true;
    }
    return false;
};

/**
 * Check if variable is null or undefined or empty string. Does not check for 0 (which is falsey).
 * @param x Value to check if null or undefined.
 * @returns True if null or undefined or empty string.
 */
export const isNullOrUndefinedOrEmptyString = (x?: any): boolean => {
    if (isNullOrUndefined(x)) {
        return true;
    } else if (typeof x === 'string' && x.trim() === '') {
        return true;
    }
    return false;
};

/**
 * Check if an array is null or undefined or empty. Not checking for empty string or 0 (both are falsey).
 * @param x Array to check if null or undefined or empty.
 * @returns True if array is null or undefined or empty.
 */
export const isNullOrUndefinedOrEmpty = (x?: any[]): boolean => {
    if (x === null) {
        return true;
    } else if (x === undefined) {
        return true;
    }

    if (x?.length === 0) {
        return true;
    }

    return false;
};

/**
 * Check the row id (guid) for special characters (like a forward slash) which need to be escaped to use in an html id.
 * @param rowId Row id.
 * @returns Formatted row id.
 */
export const formatRowId = (rowId: string): string => {
    if (rowId.includes('/')) {
        let newRowId: string = '';
        const splitSlashes: string[] = rowId.split('/');
        for (let i: number = 0; i < splitSlashes.length; i++) {
            newRowId += splitSlashes[i];
            if (i < splitSlashes.length - 1) {
                newRowId += '\\/';
            }
        }
        return newRowId;
    }
    return rowId;
};

/**
 * Downloads a file to the local computer using data already in the blob, and using the specified file name.
 * This is done by creating an object url, creating an anchor tag, and then virtually clicking the anchor tag.
 * @param blob Blob.
 * @param fileName File name.
 */
export const downloadBlobFileUsingObjectUrl = (blob: Blob, fileName: string): void => {
    // Useful links with info on how to download files with Angular.
    // https://stackoverflow.com/questions/51682514/how-download-a-file-from-httpclient
    // https://stackoverflow.com/questions/28310366/angularjs-restangular-how-to-name-file-blob-for-download
    // https://www.illucit.com/en/angular/angular-5-httpclient-file-download-with-authentication/
    // https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications

    const objectUrl = window.URL.createObjectURL(blob);

    // This approach, using window.open, requires that the browser popup blocker is disabled.
    // Also, the file name is generated and cannot be overridden.
    // Not using this approach. Keeping here for reference purposes...
    /*
    const windowRef = window.open(objectUrl);
    if (!windowRef || windowRef.closed || typeof windowRef.closed === 'undefined') {
        // How to disable popup blocker in Chrome: https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=en
        alert('Please disable your Pop-up blocker and try again.');
    }
    */

    // This approach, using a temporary anchor tag, has the following advantages:
    // - Works when popup blocker enabled, as it does not use window.open.
    // - Allows us to specify a file name.
    const downloadLink = document.createElement('a');
    downloadLink.href = objectUrl;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    setTimeout(() => {
        // Clean up after 5 seconds wait. Edge has a problem with virtual clicking of an anchor tag and then having it removed immediately.
        window.URL.revokeObjectURL(objectUrl);
        // If the elem parent is still document.body then remove it.
        // If navigation or other DOM modification happened in last 5 seconds this may not be the case.
        if (downloadLink.parentElement === document.body) {
            document.body.removeChild(downloadLink);
        }
    }, 5000);
};

/**
 * Check if an element has a horizontal scroll bar displayed.
 * @param elem Html element.
 */
export const hasHorizontalScrollBar = (elem: HTMLElement): boolean => {
    return elem.scrollWidth > elem.clientWidth;
};

/**
 * Reload the site.
 */
export const reloadSite = (): void => {
    // Reload the site. Using window.top here in case this code is running in the MSAL hidden iframe used with silent token acquisition.
    window.top?.location.reload();
};

/**
 * Clears local storage cache and reloads site.
 */
export const clearCacheAndReloadSite = (): void => {
    // Clear the cached local storage. MSAL is configured to use local storage, but clearing both just in case.
    localStorage.clear();
    sessionStorage.clear();

    reloadSite();
};

/**
 * Dynamically load any external scripts into the HTML DOM.
 * @param src External script source.
 * @param callback Callback function to call after script loaded.
 */
export const loadExternalScript = (src: string, callback?: () => void): void => {
    const node: HTMLScriptElement = document.createElement('script');
    node.src = src;
    node.type = 'text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);

    if (callback) {
        node.onload = () => {
            callback();
        };
    }
};

/**
 * Given a full href, return the path portion.
 * @param href Full href.
 * @returns Path portion of href.
 */
export const getHrefPath = (href: string): string => {
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = href;
    return anchor.pathname;
};

/**
 * Get a query string value for specified key.
 * See: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 * @param key Query string key.
 * @returns Value from query string matching the key.
 */
export const getQueryStringValue = (key: string): string => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams: URLSearchParams, prop: string) => searchParams.get(prop)
    });
    // Get the value of key in eg "https://example.com/?some_key=some_value"
    return params[key];
};

/**
 * Checks if an element is scrollable (scroll bar present).
 * @param elem Html element.
 * @returns True if element is scrollable (either horizontal or vertical), otherwise false.
 */
export const isScrollable = (elem: HTMLElement | null): boolean => {
    if (!elem) {
        return false;
    }

    // The scrollTop property sets or returns the vertical scrollbar position for the selected elements.
    const y1: number = elem.scrollTop;
    elem.scrollTop += 1;
    const y2: number = elem.scrollTop;
    elem.scrollTop -= 1;
    const y3: number = elem.scrollTop;
    elem.scrollTop = y1;

    // The scrollLeft property sets or returns the horizontal scrollbar position for the selected elements.
    const x1: number = elem.scrollLeft;
    elem.scrollLeft += 1;
    const x2: number = elem.scrollLeft;
    elem.scrollLeft -= 1;
    const x3: number = elem.scrollLeft;
    elem.scrollLeft = x1;

    return (x1 !== x2 || x2 !== x3) || (y1 !== y2 || y2 !== y3);
};

/**
 * Set focus on element (such as a link) by id.
 * @param id Element id.
 */
export const setFocusOnElemById = (id: string): void => {
    const elem: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
    if (elem != null) {
        elem.tabIndex = 0;
        elem.focus();
    }
};

/**
 * Set focus on element (such as a link) by class name.
 * @param className Class name.
 */
export const setFocusOnElemByClassName = (className: string): void => {
    const elem: HTMLInputElement = document.getElementsByClassName(className)[0] as HTMLInputElement;
    if (elem != null) {
        elem.tabIndex = 0;
        elem.focus();
    }
};

/**
 * Focus next element.
 * Based on ideas from: https://stackoverflow.com/questions/7208161/focus-next-element-in-tab-index
 */
export const focusNextElement = (activeElem: HTMLElement | null = null, direction: 'forward' | 'reverse' = 'forward') => {
    // Check if an element is defined or use activeElement.
    activeElem = activeElem instanceof HTMLElement ? activeElem : document.activeElement as HTMLElement;

    const queryString = [
        'a:not([disabled]):not([tabindex="-1"])',
        'button:not([disabled]):not([tabindex="-1"])',
        'input:not([disabled]):not([tabindex="-1"])',
        'select:not([disabled]):not([tabindex="-1"])',
        '[tabindex]:not([disabled]):not([tabindex="-1"])'
    ].join(',');

    const queryResult: HTMLElement[] = Array.prototype.filter.call(document.querySelectorAll(queryString), elem => {
        // Check for visibility while always include the current activeElement.
        return elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem === activeElem;
    });

    const indexedList: HTMLElement[] = queryResult.slice().filter(elem => {
        // Filter out all indexes not greater than 0.
        return elem.tabIndex === 0 || elem.tabIndex === -1 ? false : true;
    }).sort((a, b) => {
        // Sort the array by index from smallest to largest.
        return a.tabIndex !== 0 && b.tabIndex !== 0
            ? (a.tabIndex < b.tabIndex ? -1 : b.tabIndex < a.tabIndex ? 1 : 0)
            : a.tabIndex !== 0 ? -1 : b.tabIndex !== 0 ? 1 : 0;
    });

    const focusable: HTMLElement[] = [...indexedList, ...queryResult.filter(elem => {
        // Filter out all indexes above 0.
        return elem.tabIndex === 0 || elem.tabIndex === -1 ? true : false;
    })];

    const elem: HTMLElement = direction === 'reverse' ? (focusable[focusable.indexOf(activeElem) - 1] || focusable[focusable.length - 1]) 
        : (focusable[focusable.indexOf(activeElem) + 1] || focusable[0]);

    elem.focus();
};

/**
 * Check if object has a named property.
 * Not checking if the property falsey (null, undefined, empty string, or 0) - just checking if the property exists on the object.
 * @param obj Object to check if property exists.
 * @param propName Property name.
 * @returns True if the property exists on the object.
 */
export const hasProperty = (obj: Record<string, unknown>, propName: string): boolean => {
    return Object.prototype.hasOwnProperty.call(obj, propName);
};

/**
 * Helper function to wait for a condition to be true and then calls a callback.
 * If the stepIntervals count is reached then it will also call the callback. By default, the stepMilliseconds
 * is 100 (or 1/10th of a second), the stepIntervals is 20 which means it will wait up to 2 seconds.
 * @param waitForConditionToBeTrue Wait for condition to be true.
 * @param callback Callback function to call after condition is true.
 * @param stepMilliseconds Step milliseconds.
 * @param stepIntervals Step intrevals.
 * @param stepInterval Step interval.
 */
export const waitFn = (waitForConditionToBeTrue: () => boolean, callback: () => void, stepMilliseconds: number = 100, stepIntervals: number = 20, stepInterval: number = 0): void => {
    if (waitForConditionToBeTrue() || stepInterval > stepIntervals) {
        callback();
    } else {
        stepInterval++;
        setTimeout(() => {
            waitFn(waitForConditionToBeTrue, callback, stepMilliseconds, stepIntervals, stepInterval);
        }, stepMilliseconds);
    }
};

/**
 * Checks if the string is alphanumeric with - or _ allowed.
 * @param str String to test.
 */
export const isValidAlphaNumericWithDashOrUnderscore = (str: string): boolean => {
    const regex: RegExp = new RegExp(/^[a-zA-Z0-9-_]+$/);
    return regex.test(str);
};

/**
 * Checks to see if value is an integer and not a string.
 * @param val Value to check if is integer.
 * @returns True if is an integer.
 */
export const isInteger = (val: string | number): boolean => {
    if (typeof val === 'string') {
        return false;
    }
    return Number.isInteger(val);
};

/**
 * Tests if a string is a number.
 * https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
 * @param val Value string to test if it is a number.
 * @returns True if the string is a number.
 */
export const isNumeric = (val: string): boolean => {
    return /^-?\d+$/.test(val);
};

/**
 * Deep copy any object. Uses lodash.
 * @param obj Object to copy.
 * @returns Copied object.
 */
export const deepCopyObject = (obj: any): any => {
    // See: https://flaviocopes.com/how-to-clone-javascript-object/
    //      https://www.npmjs.com/package/lodash
    //      https://lodash.com/docs/4.17.15#cloneDeep
    return cloneDeep(obj);
};

/**
 * Compares two objects to see if all values are the same.
 * From: https://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects
 * @param x Object x to check for equality.
 * @param y Object y to check for equality.
 * @returns True if objects are equal.
 */
export const objectValuesEqual = <T>(x: T, y: T): boolean => {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => objectValuesEqual(x[key], y[key]))
    ) : (x === y);
};

/**
 * Return an objects keys as an array.
 * @param obj Object to get keys for.
 * @returns Array of key strings.
 */
export const objectKeys = (obj: Record<string, unknown>): string[] => {
    return Object.keys(obj);
};

/**
 * Validate an input string. Returns a message to be shown.
 * @param value Value to validate.
 * @param fieldValidation Field validation to check.
 * @returns 
 */
export const validateInput = (value: string, fieldValidation: IFieldValidation): string => {
    if (fieldValidation.minLength) {
        if (value.length < fieldValidation.minLength) {
            return `Min length: ${fieldValidation.minLength}`;
        }
    }

    if (fieldValidation.maxLength) {
        if (value.length > fieldValidation.maxLength) {
            return `Max length: ${fieldValidation.maxLength}`;
        }
    }
    
    // Only do pattern check if value is non empty.
    if (value && fieldValidation.pattern && !RegExp(fieldValidation.pattern).test(value)) {
        return fieldValidation.errorMsg || '';
    }

    return '';
};

/**
 * Extract the PO and line item from a notification id.
 * @param notificationId Notification id in the format of PONUMBER_LINENUMBER_ACTION, such as 0080095676_00010_COR.
 * @returns Object containing PO and line item.
 */
export const extractPoLineFromNotificationId = (notificationId: string): {poNumber?: string, lineItem?: string} => {
    // Format of notification item id is expected to be: PONUMBER_LINENUMBER_ACTION
    // Such as: 0080095676_00010_COR or 0080095676_00010_CLOSELINE
    // Here we will just split on _ and take the PO number and the line number.
    const idParts: string[] = notificationId.split('_');
    let poNumber: string | undefined = undefined;
    let lineItem: string | undefined = undefined;
    if (idParts.length >= 2) {
        poNumber = idParts[0];
        lineItem = idParts[1];
        return { poNumber, lineItem };
    }

    return { poNumber, lineItem };
};

/**
 * Check if strings (or numbers as strings) are equal regardless of padding at start (default '0').
 * For example, 00010 equals 10, 0543789122 equals 543789122.
 * Useful to compare if PO or line numbers are equal regardless of 0 padding in front.
 * @param a String a.
 * @param b String b.
 * @param padChr Padding character. Default to '0'.
 * @returns True if equal, otherwise false.
 */
export const isEqualIgnorePadding = (a: string, b: string, padChr: string = '0'): boolean => {
    let startA: number = 0;
    let startB: number = 0;

    while (startA < a.length && a[startA] === padChr) {
        ++startA;
    }
    while (startB < b.length && b[startB] === padChr) {
        ++startB;
    }
    return (a.substring(startA) === b.substring(startB));
};

/**
 * Use to display a reload countdown message. A message callback will be called for 3 seconds,
 * and then a completed callback will be called at the end.
 * @param msgCallback Message callback.
 * @param completedCallback Completed callback.
 */
export const reloadCountdown = (msgCallback: (msg: string) => void, completedCallback: () => void) => {
    const msg1: string = 'Reloading in ';
    const msg2: string = ' seconds...';
    msgCallback(`${msg1}3${msg2}`);
    setTimeout(() => {
        msgCallback(`${msg1}2${msg2}`);
        setTimeout(() => {
            msgCallback(`${msg1}1${msg2}`);
            setTimeout(() => {
                msgCallback('');
                completedCallback();
            }, 1000);
        }, 1000);
    }, 1000);
};

/**
 * Calculate percentage of partial value against total value.
 * @param partialValue Partial value out of the total value.
 * @param totalValue Total value of all numbers.
 * @returns 
 */
export const calcPercentage = (partialValue: number, totalValue: number): number => {
    if (partialValue === 0 && totalValue === 0) {
        return 0; // Prevent divide by zero below.
    }
    return (100 * partialValue) / totalValue;
};
