import React from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { FontIcon, Stack, Text } from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { pageStyles } from './AccessDeniedPage.styles';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface IPageProps extends ICommonPageProps {
}

export const AccessDeniedPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    return (
        <PageWrapper {...props}>
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <FontIcon className={pageStyles.blockedIcon} iconName="Blocked" />
                    <Text variant='large'>
                        You do not have access to this page.
                    </Text>
                </Stack.Item>
            </Stack>
        </PageWrapper>
    );
};
