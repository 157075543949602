import React from 'react';
import {
    Label,
    TooltipHost,
    Text,
    Stack,
    DefaultButton,
    TooltipDelay
} from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { commonString } from '../../common/commonString';
import { Supplier } from '../../models/domain/supplier';
import { stackTokensSmallGap } from '../../common/common.styles';
import { SupplierSelectionDialog } from './SupplierSelectionDialog';

export interface ISupplierSelectionProps {
    showLabel: boolean;
    readonly: boolean;
    disabled: boolean;
    tooltip: string;

    selectedSuppliers: Supplier[];

    onchanged?: (
        selectedSuppliers: Supplier[]
    ) => void;
}

/**
 * Supplier selection component.
 * @param props Supplier selection props.
 * @returns JSX for the component.
 */
export const SupplierSelection: React.FunctionComponent<ISupplierSelectionProps> = (props: ISupplierSelectionProps): JSX.Element => {
    const supplierButtonId: string = useId();
    const [displayDialog, { toggle: toggleDisplayDialog }] = useBoolean(false);

    return (
        <>
            <Stack horizontal tokens={stackTokensSmallGap}>
                <Stack.Item align='center'>
                    {props.showLabel && (
                        <Label htmlFor={supplierButtonId}>{commonString.suppliers}</Label>
                    )}
                    <TooltipHost content={props.tooltip} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                        <DefaultButton
                            id={supplierButtonId}
                            onClick={() => toggleDisplayDialog()}
                            text={props.readonly ? 'View' : 'Select'}
                            ariaLabel={`${props.readonly ? 'View' : 'Select'} supplier. ${props.tooltip}`} // Use both the text and the tooltip content for the aria label used by the screen reader.
                            disabled={props.disabled}
                        />
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item align='end'>
                    <Text variant="small" block>{props.selectedSuppliers.length} suppliers</Text>
                    <Text variant="small" block>selected</Text>
                </Stack.Item>
            </Stack>

            <SupplierSelectionDialog
                readonly={props.readonly}
                displayDialog={displayDialog}
                selectedSuppliers={props.selectedSuppliers}
                onDismiss={(selectedSuppliers: Supplier[]) => {
                    toggleDisplayDialog();

                    if (props.onchanged) {
                        props.onchanged(selectedSuppliers);
                    }
                }}
            />
        </>
    );
};
