import React from 'react';
import { componentStyles } from './Section.styles';

export interface ISectionProps {
    children?: React.ReactNode;
}

/**
 * A section to be displayed on a page with a 3D shaded background, and placed in a SectionWrapper.
 * @param props Section props.
 * @returns JSX for the component.
 */
export const Section: React.FunctionComponent<ISectionProps> = (props: ISectionProps): JSX.Element => {
    return (
        <div className={componentStyles.section}>
            {props.children}
        </div>
    );
};
