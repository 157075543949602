import React, { useEffect, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    ChoiceGroup,
    DatePicker,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IChoiceGroupOption,
    Label,
    PrimaryButton,
    TextField,
    Text,
    TooltipHost,
    TooltipDelay
} from '@fluentui/react';
import { horizontalChoiceGroupStyle } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { ApprovalOptionKey, AvailabilityOptionKey, approvalOptions, availabilityOptions } from './priorYearAccrualPageConstants';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { validationConstants } from '../../common/validationConstants';
import { isNullOrUndefined, validateInput } from '../../common/common.func.general';
import { addToArrayIfNotPresent, removeFromArray } from '../../common/common.func.transform';
import { PurchaseOrderLineItemPair } from '../../models/purchaseOrder/purchaseOrderLineItemPair';
import { CorpDetails } from '../../models/priorYearAccrual/corpDetails';
import { CorpApproval } from '../../models/priorYearAccrual/corpApproval';
import { PoePodAvailability } from '../../models/priorYearAccrual/poePodAvailability';
import { StcApproval } from '../../models/priorYearAccrual/stcApproval';
import { InvoiceAvailability } from '../../models/priorYearAccrual/invoiceAvailability';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

export enum CorpDetailsUpdateMode {
    All = 'All',
    CorpApproval = 'CorpApproval',
    StcApproval = 'StcApproval',
    PoePodAvailability = 'PoePodAvailability',
    InvoiceAvailability = 'InvoiceAvailability'
}

interface IComponentProps {
    showDialog: boolean;
    priorYearAccrualLineItems: PriorYearAccrualLineItem[];
    onUpdateClicked: (
        poLines: PurchaseOrderLineItemPair[],
        corpDetails: CorpDetails
    ) => void;
    onCancelClicked: () => void;
    updateMode: CorpDetailsUpdateMode
}

export const CorpDetailsDialog: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const corpApprovalInputId: string = useId();
    const corpCommentInputId: string = useId();
    const stcApprovalInputId: string = useId();
    const stcCommentInputId: string = useId();
    const poePodAvailabilityInputId: string = useId();
    const invoiceExpectedDateInputId: string = useId();
    const [selectedCorpApprovalOptionKey, setSelectedCorpApprovalOptionKey] = useState<ApprovalOptionKey | undefined | null>(undefined);
    const [selectedStcApprovalOptionKey, setSelectedStcApprovalOptionKey] = useState<ApprovalOptionKey | undefined | null>(undefined);
    const [selectedPoePodAvailabilityOptionKey, setSelectedPoePodAvailabilityOptionKey] = useState<AvailabilityOptionKey | undefined | null>(undefined);
    const [corpComment, setCorpComment] = useState<string>('');
    const [stcComment, setStcComment] = useState<string>('');
    const [invoiceExpectedDate, setInvoiceExpectedDate] = useState<Date | null | undefined>();
    const [displayPrefillWarning, setDisplayPrefillWarning] = useState<boolean>(false);

    // Array of inputs that are invalid.
    const [inputInvalid, setInputInvalid] = useState<string[]>([]);

    /**
     * Effect for when the dialog is shown.
     */
    useEffect(() => {
        if (props.showDialog && props.priorYearAccrualLineItems.length > 0) {
            // Prefill the dialog with existing data from the props.priorYearAccrualLineItems. Only prefill if ALL the corp detail data
            // across all the line items is identical. If anything is different then do not prefill. In that case, and if multiple lines
            // are selected, then display a warning indicator in the UI that no prefill was done as there are differences across the
            // line item data for the corp details.

            let allLinesAreTheSame: boolean = true; // Assume true by default.

            // Take the first line and compare it with all the rest. Check for any differences.
            const firstLine: PriorYearAccrualLineItem = props.priorYearAccrualLineItems[0];

            if (props.updateMode === CorpDetailsUpdateMode.All) {
                for (let i: number = 1; i < props.priorYearAccrualLineItems.length; i++) {
                    if (props.priorYearAccrualLineItems[i].corpDetails?.corpApproval?.approved !== firstLine.corpDetails?.corpApproval?.approved ||
                        props.priorYearAccrualLineItems[i].corpDetails?.corpApproval?.comment !== firstLine.corpDetails?.corpApproval?.comment ||
                        props.priorYearAccrualLineItems[i].corpDetails?.stcApproval?.approved !== firstLine.corpDetails?.stcApproval?.approved ||
                        props.priorYearAccrualLineItems[i].corpDetails?.stcApproval?.comment !== firstLine.corpDetails?.stcApproval?.comment ||
                        props.priorYearAccrualLineItems[i].corpDetails?.poePodAvailability?.available !== firstLine.corpDetails?.poePodAvailability?.available ||
                        props.priorYearAccrualLineItems[i].corpDetails?.invoiceAvailability?.expectedDate?.getDay() !== firstLine.corpDetails?.invoiceAvailability?.expectedDate?.getDay() ||
                        props.priorYearAccrualLineItems[i].corpDetails?.invoiceAvailability?.expectedDate?.getMonth() !== firstLine.corpDetails?.invoiceAvailability?.expectedDate?.getMonth() ||
                        props.priorYearAccrualLineItems[i].corpDetails?.invoiceAvailability?.expectedDate?.getFullYear() !== firstLine.corpDetails?.invoiceAvailability?.expectedDate?.getFullYear()) {
                        allLinesAreTheSame = false;
                        break;
                    }
                }
            } else if (props.updateMode === CorpDetailsUpdateMode.CorpApproval) {
                for (let i: number = 1; i < props.priorYearAccrualLineItems.length; i++) {
                    if (props.priorYearAccrualLineItems[i].corpDetails?.corpApproval?.approved !== firstLine.corpDetails?.corpApproval?.approved ||
                        props.priorYearAccrualLineItems[i].corpDetails?.corpApproval?.comment !== firstLine.corpDetails?.corpApproval?.comment) {
                        allLinesAreTheSame = false;
                        break;
                    }
                }
            } else if (props.updateMode === CorpDetailsUpdateMode.StcApproval) {
                for (let i: number = 1; i < props.priorYearAccrualLineItems.length; i++) {
                    if (props.priorYearAccrualLineItems[i].corpDetails?.stcApproval?.approved !== firstLine.corpDetails?.stcApproval?.approved ||
                        props.priorYearAccrualLineItems[i].corpDetails?.stcApproval?.comment !== firstLine.corpDetails?.stcApproval?.comment) {
                        allLinesAreTheSame = false;
                        break;
                    }
                }
            } else if (props.updateMode === CorpDetailsUpdateMode.PoePodAvailability) {
                for (let i: number = 1; i < props.priorYearAccrualLineItems.length; i++) {
                    if (props.priorYearAccrualLineItems[i].corpDetails?.poePodAvailability?.available !== firstLine.corpDetails?.poePodAvailability?.available) {
                        allLinesAreTheSame = false;
                        break;
                    }
                }
            } else if (props.updateMode === CorpDetailsUpdateMode.InvoiceAvailability) {
                for (let i: number = 1; i < props.priorYearAccrualLineItems.length; i++) {
                    if (props.priorYearAccrualLineItems[i].corpDetails?.invoiceAvailability?.expectedDate?.getDay() !== firstLine.corpDetails?.invoiceAvailability?.expectedDate?.getDay() ||
                        props.priorYearAccrualLineItems[i].corpDetails?.invoiceAvailability?.expectedDate?.getMonth() !== firstLine.corpDetails?.invoiceAvailability?.expectedDate?.getMonth() ||
                        props.priorYearAccrualLineItems[i].corpDetails?.invoiceAvailability?.expectedDate?.getFullYear() !== firstLine.corpDetails?.invoiceAvailability?.expectedDate?.getFullYear()) {
                        allLinesAreTheSame = false;
                        break;
                    }
                }
            }

            if (allLinesAreTheSame) {
                setSelectedCorpApprovalOptionKey(
                    isNullOrUndefined(firstLine.corpDetails?.corpApproval?.approved) ? undefined :
                    firstLine.corpDetails?.corpApproval?.approved ? ApprovalOptionKey.Approved :
                    ApprovalOptionKey.NotApproved
                );
                setCorpComment(firstLine.corpDetails?.corpApproval?.comment || '');
                setSelectedStcApprovalOptionKey(
                    isNullOrUndefined(firstLine.corpDetails?.stcApproval?.approved) ? undefined :
                    firstLine.corpDetails?.stcApproval?.approved ? ApprovalOptionKey.Approved
                    : ApprovalOptionKey.NotApproved
                );
                setStcComment(firstLine.corpDetails?.stcApproval?.comment || '');
                setSelectedPoePodAvailabilityOptionKey(
                    isNullOrUndefined(firstLine.corpDetails?.poePodAvailability?.available) ? undefined :
                    firstLine.corpDetails?.poePodAvailability?.available ? AvailabilityOptionKey.Yes :
                    AvailabilityOptionKey.No
                );
                setInvoiceExpectedDate(firstLine.corpDetails?.invoiceAvailability?.expectedDate ? new Date(firstLine.corpDetails?.invoiceAvailability?.expectedDate) : undefined);
            } else {
                setSelectedCorpApprovalOptionKey(undefined);
                setCorpComment('');
                setSelectedStcApprovalOptionKey(undefined);
                setStcComment('');
                setSelectedPoePodAvailabilityOptionKey(undefined);
                setInvoiceExpectedDate(undefined);
            }

            setDisplayPrefillWarning(!allLinesAreTheSame);
        }
    }, [props.priorYearAccrualLineItems, props.showDialog, props.updateMode]);

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={() => props.onCancelClicked()}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Corporate Accounting Details',
                subText: 'Update corporate accounting details for all selected line items.'
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth="650px"
        >
            {displayPrefillWarning && (
                <div className={pageStyles.corpDetailsDialogPrefillWarningContainer}>
                    <Text variant="medium" className={pageStyles.corpDetailsDialogPrefillWarning}>This dialog was not prefilled with existing data as there were differences across the selected {props.priorYearAccrualLineItems.length} lines. Any data entered here will overwrite details for all the selected lines.</Text>
                </div>
            )}
            <table className={pageStyles.corpDetailsDialogTable} role="presentation">
                <tbody>
                    {(props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.CorpApproval) && (
                        <tr>
                            <td>
                                <Label htmlFor={corpApprovalInputId}>{commonString.corpApproval}</Label>
                                <ChoiceGroup
                                    id={corpApprovalInputId}
                                    styles={horizontalChoiceGroupStyle}
                                    selectedKey={selectedCorpApprovalOptionKey}
                                    options={approvalOptions}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                        setSelectedCorpApprovalOptionKey(option!.key as ApprovalOptionKey);
                                    }}
                                />
                            </td>
                            <td>
                                <Label htmlFor={corpCommentInputId}>{commonString.corpComment}</Label>
                                <TooltipHost content={validationConstants.corpComment.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={corpCommentInputId}
                                        autoComplete='off'
                                        ariaLabel={commonString.corpComment}
                                        className={pageStyles.corpDetailsDialogCommentTextField}
                                        value={corpComment}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            setCorpComment(newValue || '');
                                        }}
                                        maxLength={validationConstants.corpComment.maxLength}
                                        onGetErrorMessage={(value: string) => {
                                            const msg: string = validateInput(value, validationConstants.corpComment);
                                            // Company code validation has a 4 char min, but here we want to allow for
                                            // a blank field, so only set invalid if at least one char typed and is invalid.
                                            if (value.length > 0 && msg) {
                                                setInputInvalid(x => [...addToArrayIfNotPresent(x, 'corpComment')]);
                                                return msg;
                                            } else {
                                                setInputInvalid(x => [...removeFromArray(x, 'corpComment')]);
                                                return '';
                                            }
                                        }}
                                    />
                                </TooltipHost>
                            </td>
                        </tr>
                    )}
                    {(props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.StcApproval) && (
                        <tr>
                            <td>
                                <Label htmlFor={stcApprovalInputId}>{commonString.stcApproval}</Label>
                                <ChoiceGroup
                                    id={stcApprovalInputId}
                                    styles={horizontalChoiceGroupStyle}
                                    selectedKey={selectedStcApprovalOptionKey}
                                    options={approvalOptions}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                        setSelectedStcApprovalOptionKey(option!.key as ApprovalOptionKey);
                                    }}
                                />
                            </td>
                            <td>
                                <Label htmlFor={stcCommentInputId}>{commonString.stcComment}</Label>
                                <TooltipHost content={validationConstants.stcComment.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={stcCommentInputId}
                                        autoComplete='off'
                                        ariaLabel={commonString.stcComment}
                                        className={pageStyles.corpDetailsDialogCommentTextField}
                                        value={stcComment}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            setStcComment(newValue || '');
                                        }}
                                        maxLength={validationConstants.stcComment.maxLength}
                                        onGetErrorMessage={(value: string) => {
                                            const msg: string = validateInput(value, validationConstants.stcComment);
                                            // Company code validation has a 4 char min, but here we want to allow for
                                            // a blank field, so only set invalid if at least one char typed and is invalid.
                                            if (value.length > 0 && msg) {
                                                setInputInvalid(x => [...addToArrayIfNotPresent(x, 'stcComment')]);
                                                return msg;
                                            } else {
                                                setInputInvalid(x => [...removeFromArray(x, 'stcComment')]);
                                                return '';
                                            }
                                        }}
                                    />
                                </TooltipHost>
                            </td>
                        </tr>
                    )}
                    {(props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.PoePodAvailability || props.updateMode === CorpDetailsUpdateMode.InvoiceAvailability) && (
                        <tr>
                            {(props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.PoePodAvailability) && (
                                <td>
                                    <Label htmlFor={poePodAvailabilityInputId}>{commonString.poePodAvailability}</Label>
                                    <ChoiceGroup
                                        id={poePodAvailabilityInputId}
                                        styles={horizontalChoiceGroupStyle}
                                        selectedKey={selectedPoePodAvailabilityOptionKey}
                                        options={availabilityOptions}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                            setSelectedPoePodAvailabilityOptionKey(option!.key as AvailabilityOptionKey);
                                        }}
                                    />
                                </td>
                            )}
                            {(props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.InvoiceAvailability) && (
                                <td>
                                    <Label htmlFor={invoiceExpectedDateInputId}>{commonString.invoiceExpectedDate}</Label>
                                    <DatePicker
                                        id={invoiceExpectedDateInputId}
                                        ariaLabel={commonString.invoiceExpectedDate}
                                        className={pageStyles.corpDetailsDialogInvoiceExpectedDatePicker}
                                        value={invoiceExpectedDate || undefined}
                                        onSelectDate={(date: Date | null | undefined) => {
                                            setInvoiceExpectedDate(date);
                                        }}
                                    />
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>

            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.pyaUpdateCorpDetailsButtonClicked });

                        const poLines: PurchaseOrderLineItemPair[] = props.priorYearAccrualLineItems.map(x => {
                            return new PurchaseOrderLineItemPair(Number(x.purchaseOrder), Number(x.lineItem));
                        });
                        props.onUpdateClicked(
                            poLines,
                            // Instantiate a CorpDetails object and set only the fields that are to be updated based on the updateMode.
                            // Leave any non-displayed fields as undefined.
                            // The API to update these corp details will only update fields that are not null (undefined).
                            new CorpDetails({
                                corpApproval: (props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.CorpApproval) ?
                                    new CorpApproval({
                                        approved: isNullOrUndefined(selectedCorpApprovalOptionKey) ? undefined :
                                            selectedCorpApprovalOptionKey === ApprovalOptionKey.Approved,
                                        comment: corpComment
                                    }) : undefined,
                                stcApproval: (props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.StcApproval) ?
                                    new StcApproval({
                                        approved: isNullOrUndefined(selectedStcApprovalOptionKey) ? undefined :
                                            selectedStcApprovalOptionKey === ApprovalOptionKey.Approved,
                                        comment: stcComment
                                }) : undefined,
                                poePodAvailability: (props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.PoePodAvailability) ?
                                    new PoePodAvailability({
                                        available: isNullOrUndefined(selectedPoePodAvailabilityOptionKey) ? undefined :
                                            selectedPoePodAvailabilityOptionKey === AvailabilityOptionKey.Yes
                                }) : undefined,
                                invoiceAvailability: (props.updateMode === CorpDetailsUpdateMode.All || props.updateMode === CorpDetailsUpdateMode.InvoiceAvailability) ?
                                    new InvoiceAvailability({
                                        expectedDate: invoiceExpectedDate || undefined
                                }) : undefined
                            })
                        );
                    }}
                    disabled={inputInvalid.length > 0}
                    text="Update"
                />
                <DefaultButton
                    onClick={() => props.onCancelClicked()}
                    text="Cancel" 
                />
            </DialogFooter>
        </Dialog>
    );
};
