import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { commonString } from '../../common/commonString';
import { DatePicker, FontIcon } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { UserDelegate } from '../../models/user/userDelegate';
import { editDelegateToEndDate } from '../../store/actions/pageActions/userProfilePage.action';
import { pageStyles } from './UserProfilePage.styles';

interface IDelegateToEndDateInputProps {
    item: UserDelegate;
}

export const DelegateToEndDateInput: React.FunctionComponent<IDelegateToEndDateInputProps> = (props: IDelegateToEndDateInputProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <div>
            <DatePicker
                ariaLabel={commonString.endDate}
                value={props.item.endDate}
                minDate={props.item.startDate}
                onSelectDate={(date: Date | null | undefined) => {
                    if (date) {
                        dispatch(editDelegateToEndDate(props.item.clientRowKey!, date));
                    }
                }}
            />
            {props.item.endDate < (new Date()) && (
                <div className={pageStyles.expiredWarning}>
                    <FontIcon iconName="Warning" className={pageStyles.expiredIcon} />
                    Expired
                </div>
            )}
        </div>
    );
}
