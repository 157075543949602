import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    pageDescriptionUl: {
        margin: '0 0 8px 0'
    } as IStyle,
    filterPoPlusOptionalLineTextField: {
        minWidth: '190px'
    } as IStyle,
    filterPoOwnerTextField: {
        minWidth: '170px'
    } as IStyle,
    filterCompanyCodeTextField: {
        minWidth: '110px'
    } as IStyle,
    filterFiscalYearComboBox: {
        minWidth: '100px'
    } as IStyle,
    filterDelegatedByComboBox: {
        minWidth: '160px'
    } as IStyle,
    filterStatusComboBox: {
        minWidth: '200px'
    } as IStyle,
    filterRegionComboBox: {
        minWidth: '200px'
    } as IStyle,
    filterCostCategorySubclassComboBox: {
        minWidth: '190px'
    } as IStyle,
    filterCorpApprovalComboBox: {
        minWidth: '190px'
    } as IStyle,
    filterStcApprovalComboBox: {
        minWidth: '190px'
    } as IStyle,
    filterPoePodAvailabilityComboBox: {
        minWidth: '190px'
    } as IStyle,
    showCurrencyComboBox: {
        minWidth: '180px'
    } as IStyle,
    hierarchyFilterButton: {
        margin: '0 0 5px 0'
    } as IStyle,
    detailsList: {
        // Add a border to the right side of the cells.
        '.ms-DetailsRow-cell': {
            borderRight: '1px solid rgb(243, 242, 241)'
        } as IStyle
    } as IStyle,
    reminderMailListTable: {
        height: '130px',
        display: 'block',
        borderCollapse: 'collapse',
        'thead>tr>th': {
            fontWeight: 500,
            minWidth: '130px'
        } as IStyle,
        'td': {
            minWidth: '130px'
        } as IStyle
    } as IStyle,
    reminderMailListTableBody: {
        height: '100px',
        display: 'block',
        overflow: 'auto'
    } as IStyle,
    pageLink: {
        margin: '0 0 20px 0'
    } as IStyle,
    corpDetailsDialogTable: {
        borderCollapse: 'collapse',
        'td': {
            padding: '0 0 12px 0',
            verticalAlign: 'top'
        } as IStyle
    } as IStyle,
    corpDetailsDialogCommentTextField: {
        width: '310px'
    } as IStyle,
    corpDetailsDialogInvoiceExpectedDatePicker: {
        minWidth: '170px'
    } as IStyle,
    corpDetailsDialogPrefillWarningContainer: {
        margin: '0 0 18px 0'
    } as IStyle,
    corpDetailsDialogPrefillWarning: {
        color: 'red'
    } as IStyle,
    pieChartContainerWrapper: {
        position: 'relative',
        width: '100px',
        height: '56px' // Roughly the same height as the data tiles.
    } as IStyle,
    pieChartContainer: {
        position: 'absolute', // The chart will absolutely position within the container wrapper.
        bottom: '-20px', // And will overflow out of it on top and bottom, using the bottom position and height.
        width: '100px',
        height: '100px'
    } as IStyle,
    costCategoryConfigDialogContentContainer: {
        margin: '0 0 32px 0'
    } as IStyle,
    costCategoryConfigComboBox: {
        width: '300px'
    } as IStyle,
    costCategoryConfigComboBoxLoadSpinnerOuterContainer: {
        position: 'relative',
        width: '300px'
    } as IStyle,
    costCategoryConfigComboBoxLoadSpinnerInnerContainer: {
        position: 'absolute',
        top: '2px',
        left: '266px' // Positions the spinner over the dropdown arrow, based on above costCategoryComboBoxLoadSpinnerOuterContainer width.
    } as IStyle,
    costCategoryConfigLargeTextField: {
        width: '300px'
    } as IStyle,
    costCategoryConfigLargeReadonlyTextField: {
        width: '300px',
        '.ms-TextField-fieldGroup': {
            border: '1px dashed lightgray!important'
        } as IStyle
    } as IStyle,
    glLookupNoDataFound: {
        lineHeight: '32px',
        color: 'red'
    } as IStyle,
    costCategoryConfigSmallTextField: {
        width: '190px'
    } as IStyle,
    actionButton: {
        minWidth: '40px',
        padding: 0
    } as IStyle,
    excelIcon: {
        margin: '0 8px 0 0',
        fontSize: 16
    } as IStyle,
    buttonText: {
        fontWeight: 600
    } as IStyle,
    icon: {
        fontSize: '20px',
        verticalAlign: 'middle',
        margin: '0 0 4px 0'
    } as IStyle,
    hiddenInput: {
        display: 'none'
    } as IStyle
});
