import React from 'react';
import {
    Stack,
    Text,
    FontIcon
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { componentStyles } from './LoadFailureDisplay.styles';

/**
 * Returns a common widget indicating a load failure.
 */
export const LoadFailureDisplay: React.FunctionComponent = (): JSX.Element => {
    return (
        <Stack horizontal className={commonStyles.errorText}>
            <FontIcon iconName="Error" className={componentStyles.errorIcon} />
            <Text variant="small">Load failure</Text>
        </Stack>
    );                               
};
