import {
    FeedbackAgeGroup,
    FeedbackAuthenticationType,
    FeedbackPolicyValue,
    FeedbackUiType,
    IFeedbackInitOptions
} from '@coherence-design-system/feedback-center';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { appConfig } from '../appConfig';

export const getOcvOptions = (): IFeedbackInitOptions => {
    return {
        appId: appConfig.current.ocv.appId,
        isProduction: appConfig.current.ocv.isProdEnv,
        clientName: 'GSR', // This is the name of the onboarded client in Centro.
        authenticationType: FeedbackAuthenticationType.Unauthenticated,
        ageGroup: FeedbackAgeGroup.Adult,
        callbackFunctions: {},
        feedbackConfig: {
            feedbackUiType: FeedbackUiType.SidePane,
            isDisplayed: false,
            isEmailCollectionEnabled: true,
            isFileUploadEnabled: true,
            isFeedbackForumEnabled: false,
            isMyFeedbackEnabled: false,
            isScreenshotEnabled: true,
            isScreenRecordingEnabled: true,
            myFeedbackUrl: undefined,
            privacyUrl: 'https://privacy.microsoft.com/en-us/privacystatement',
            complianceChecks: {
                connectedExperiences: FeedbackPolicyValue.Enabled,
                policyAllowFeedback: FeedbackPolicyValue.Enabled,
                policyAllowSurvey: FeedbackPolicyValue.Enabled,
                policyAllowScreenshot: FeedbackPolicyValue.Enabled,
                policyAllowContact: FeedbackPolicyValue.Enabled,
                policyAllowContent: FeedbackPolicyValue.Disabled
            }
        },
        themeOptions: { baseTheme: CoherenceTheme }
    } as IFeedbackInitOptions;
};
