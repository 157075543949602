import React from 'react';
import { componentStyles } from './SectionWrapper.styles';

export interface ISectionWrapperProps {
    children?: React.ReactNode;
}

/**
 * A section wrapper to contain Sections.
 * @param props Section wrapper props.
 * @returns JSX for the component.
 */
export const SectionWrapper: React.FunctionComponent<ISectionWrapperProps> = (props: ISectionWrapperProps): JSX.Element => {
    return (
        <div className={componentStyles.sectionWrapper}>
            {props.children}
        </div>
    );
};
