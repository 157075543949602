import { ErrorModel } from '../error/errorModel';
import { JeToolFunction } from './jeToolFunction';

/**
 * Represents a JE tool function payload. This is the data that is sent to the server.
 */
export interface IJeToolData {
    jeToolFunction: JeToolFunction;
    jePostData?: string;
    errors?: ErrorModel[];
}

export class JeToolData implements IJeToolData {
    public jeToolFunction: JeToolFunction;
    jePostData?: string;
    errors?: ErrorModel[];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IJeToolData) {
        this.jeToolFunction = jsonData.jeToolFunction;
        this.jePostData = jsonData.jePostData;
 
        if (jsonData.errors && this.errors) {
            for (let i: number = 0; i < jsonData.errors.length; i++) {
                this.errors.push(new ErrorModel(jsonData.errors[i]));
            }
        }
    }
}
