/**
 * Represents the selection state of a tree node and its children.
 */
export enum TreeStateSelection {
    /**
     * The tree node is unselected.
     */
    Unselected = 0,

    /**
     * The tree node is selected and all children nodes are selected.
     */
    Selected = 1,

    /**
     * The tree node is partially selected, meaning at least one descendant node is selected
     * and at least one ore more nodes is unselected.
     */
    PartiallySelected = 2
}