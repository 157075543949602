import { PurchaseOrderCheckResult } from './purchaseOrderCheckResult';
import { PurchaseOrderDetails } from './purchaseOrderDetails';
import { PurchaseOrderSearchMetaData } from './purchaseOrderSearchMetaData';

export interface IPurchaseOrderSearchResults {
    metaData: PurchaseOrderSearchMetaData;
    results: PurchaseOrderDetails[];
    visibilityCheckResults?: PurchaseOrderCheckResult[];
}

export class PurchaseOrderSearchResults implements IPurchaseOrderSearchResults {
    public metaData: PurchaseOrderSearchMetaData;
    public results: PurchaseOrderDetails[] = [];
    public visibilityCheckResults?: PurchaseOrderCheckResult[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderSearchResults) {
        this.metaData = new PurchaseOrderSearchMetaData(jsonData.metaData);

        if (jsonData.results) {
            for (let i: number = 0; i < jsonData.results.length; i++) {
                this.results.push(new PurchaseOrderDetails(jsonData.results[i]));
            }
        }

        if (jsonData.visibilityCheckResults && this.visibilityCheckResults) {
            for (let i: number = 0; i < jsonData.visibilityCheckResults.length; i++) {
                this.visibilityCheckResults.push(new PurchaseOrderCheckResult(jsonData.visibilityCheckResults[i]));
            }
        }
    }
}
