import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';
import { ReversePurchaseOrderReceipt } from '../../../models/grReversal/reversePurchaseOrderReceipt';
import { AnyAction } from '@reduxjs/toolkit';
import { PurchaseOrderCheckResult } from '../../../models/purchaseOrder/purchaseOrderCheckResult';

/**
 * Payload used with callApiReverseGoodsReceipt action.
 */
export interface IApiReverseGoodsReceipt extends ICallApiBase {
    reversePurchaseOrderReceipts?: ReversePurchaseOrderReceipt[] | null;
}

/**
 * Call API to reverse goods receipt.
 * @param grReversalRequest Reversal request.
 * @returns Redux dispatch function.
 */
export const callApiReverseGoodsReceipt = (grReversalRequest: ReversePurchaseOrderReceipt[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ReversePurchaseOrderReceipt[] | null>(
        actionTypes.API_REVERSE_GOODS_RECEIPT,
        async () => {
            return await receiptingApiClient.reverseGoodsReceipt(grReversalRequest);
        },
        (payload: IApiReverseGoodsReceipt, data: ReversePurchaseOrderReceipt[] | null) => {
            payload.reversePurchaseOrderReceipts = data;
        }
    );
};

/**
 * Action creator: Reset the api call state for reverse goods receipt. 
 * @returns Redux action.
 */
export const resetApiCallStateForReverseGoodsReceipt = (): AnyAction => {
    const payload: IApiReverseGoodsReceipt = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reversePurchaseOrderReceipts: undefined
    };
    return {
        type: actionTypes.API_REVERSE_GOODS_RECEIPT,
        payload: payload
    } as AnyAction;
};

/**
 * Payload used with callApiPurchaseOrderCheck action.
 */
export interface IApiPurchaseOrderCheck extends ICallApiBase {
    checkResults?: PurchaseOrderCheckResult[] | null;
}

/**
 * Call API to do purchase order check.
 * @param userAlias User alias.
 * @param userAadOid User AAD object id.
 * @returns Redux dispatch function.
 */
export const callApiPurchaseOrderCheck = (purchaseOrderNumber: string, userAlias: string, userAadOid: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PurchaseOrderCheckResult[] | null>(
        actionTypes.API_PURCHASE_ORDER_CHECK,
        async () => {
            return await receiptingApiClient.purchaseOrderCheck(purchaseOrderNumber, userAlias, userAadOid);
        },
        (payload: IApiPurchaseOrderCheck, data: PurchaseOrderCheckResult[] | null) => {
            payload.checkResults = data;
        }
    );
};

/**
 * Action creator: Reset the api call state for purchase order check. 
 * @returns Redux action.
 */
export const resetApiCallStateForPurchaseOrderCheck = (): AnyAction => {
    const payload: IApiPurchaseOrderCheck = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        checkResults: undefined
    };
    return {
        type: actionTypes.API_PURCHASE_ORDER_CHECK,
        payload: payload
    } as AnyAction;
};
