import React from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogProps
} from '@fluentui/react';
import { componentStyles } from './GenericDialog.styles';

export enum GenericDialogMode {
    /**
     * Generic dialog with Ok button.
     */
    Ok,

    /**
     * Generic dialog with Ok and Cancel buttons.
     */
    OkCancel,

    /**
     * Generic dialog with Yes and No buttons.
     */
    YesNo,

    /**
     * Generic dialog with Yes, No, and Cancel buttons.
     */
    YesNoCancel
}

interface IGenericDialogProps extends IDialogProps {
    /**
     * Display the dialog if true.
     */
    displayDialog: boolean;

    /**
     * Dialog title.
     */
    title: string;

    /**
     * Dialog subtext.
     */
    subText?: string;

    /**
     * Dialog JSX content.
     */
    content?: JSX.Element;

    /**
     * Dialog mode.
     */
    mode: GenericDialogMode;

    /**
     * Ok clicked callback.
     * Used when mode is GenericDialogMode.Ok.
     */
    onOkClicked?: () => void;

    /**
     * Cancel clicked callback.
     * Used when mode is GenericDialogMode.OkCancel.
     */
    onCancelClicked?: () => void;

    /**
     * Yes clicked callback.
     * Used when mode is GenericDialogMode.YesNo.
     */
    onYesClicked?: () => void;

    /**
     * No clicked callback.
     * Used when mode is GenericDialogMode.YesNo.
     */
    onNoClicked?: () => void;

    /**
     * Dialog width. Defaults to 500px if not specified.
     */
    width?: number;

    /**
     * If true then disable the Ok button.
     * Useful when using validation of inputs in the dialog content, and need to disable Ok if invalid input.
     */
    disableOkButton?: boolean;
}

/**
 * Displays a generic dialog popup.
 * @param props Generic dialog props.
 * @returns JSX for generic dialog.
 */
export const GenericDialog: React.FunctionComponent<IGenericDialogProps> = (props: IGenericDialogProps): JSX.Element => {
    return (
        <Dialog
            { ...props }
            hidden={!props.displayDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.title,
                subText: props.subText,
                showCloseButton: false
            }}
            modalProps={{
                containerClassName: componentStyles.dialogContainer,
                isBlocking: true
            }}
            minWidth={props.width || 500}
        >
            {props.content}
            <DialogFooter>
                {(props.mode === GenericDialogMode.Ok || props.mode === GenericDialogMode.OkCancel) && (
                    <DefaultButton
                        onClick={() => {
                            if (props.onOkClicked) {
                                props.onOkClicked();
                            }
                        }}
                        text='Ok'
                        disabled={props.disableOkButton}
                    />
                )}
                {props.mode === GenericDialogMode.OkCancel && (
                    <DefaultButton
                        onClick={() => {
                            if (props.onCancelClicked) {
                                props.onCancelClicked();
                            }
                        }}
                        text='Cancel'
                    />
                )}
                {(props.mode === GenericDialogMode.YesNo || props.mode === GenericDialogMode.YesNoCancel) && (
                    <DefaultButton
                        onClick={() => {
                            if (props.onYesClicked) {
                                props.onYesClicked();
                            }
                        }}
                        text='Yes'
                    />
                )}
                {(props.mode === GenericDialogMode.YesNo || props.mode === GenericDialogMode.YesNoCancel) && (
                    <DefaultButton
                        onClick={() => {
                            if (props.onNoClicked) {
                                props.onNoClicked();
                            }
                        }}
                        text='No'
                    />
                )}
                {props.mode === GenericDialogMode.YesNoCancel && (
                    <DefaultButton
                        onClick={() => {
                            if (props.onCancelClicked) {
                                props.onCancelClicked();
                            }
                        }}
                        text='Cancel'
                    />
                )}
            </DialogFooter>
        </Dialog>
    );
};
