import { JournalEntry } from './journalEntry';

export interface IPOLinePriorYearAccrualActivity {
    activityAmount: number;
    transactionId: string;
    accrualTransactionId: string;
    activityDate: Date;
    accrualActivityId?: string;
    activityType: string;
    activityProcessingStatus: string;
    purchaseOrderNumber: number;
    purchaseOrderLineItem: number;
    currency: string;
    poCompanyCode: string;
    internalOrder?: string;
    costCenter?: string;
    glAccountCode: string;
    dedicatedPnlAccountCode: string;
    journalEntries: JournalEntry[];
}

export class POLinePriorYearAccrualActivity implements IPOLinePriorYearAccrualActivity {
    public activityAmount: number;
    public transactionId: string;
    public accrualTransactionId: string;
    public activityDate: Date;
    public accrualActivityId?: string;
    public activityType: string;
    public activityProcessingStatus: string;
    public purchaseOrderNumber: number;
    public purchaseOrderLineItem: number;
    public currency: string;
    public poCompanyCode: string;
    public internalOrder?: string;
    public costCenter?: string;
    public glAccountCode: string;
    public dedicatedPnlAccountCode: string;
    public journalEntries: JournalEntry[] = [];

    // Additional data used in client that does not come from server.
    public clientRowKey?: string = '';
    public detailsRowJsx?: JSX.Element; // Used by onCustomRenderRow in CustomDetailsList.util.tsx to render a detail row.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPOLinePriorYearAccrualActivity) {
        this.activityAmount = jsonData.activityAmount;
        this.transactionId = jsonData.transactionId;
        this.accrualTransactionId = jsonData.accrualTransactionId;
        this.activityDate = jsonData.activityDate;
        this.accrualActivityId = jsonData.accrualActivityId;
        this.activityType = jsonData.activityType;
        this.activityProcessingStatus = jsonData.activityProcessingStatus;
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.purchaseOrderLineItem = jsonData.purchaseOrderLineItem;
        this.currency = jsonData.currency;
        this.poCompanyCode = jsonData.poCompanyCode;
        this.internalOrder = jsonData.internalOrder;
        this.costCenter = jsonData.costCenter;
        this.glAccountCode = jsonData.glAccountCode;
        this.dedicatedPnlAccountCode = jsonData.dedicatedPnlAccountCode;

        if (jsonData.journalEntries) {
            for (let i: number = 0; i < jsonData.journalEntries.length; i++) {
                this.journalEntries.push(new JournalEntry(jsonData.journalEntries[i]));
            }
        }
    }

    public get internalOrderOrCostCenter(): string {
        // Only one of internalOrder or costCenter should exist. But just in case below code can return a string with both
        // if both are present. Both are to be displayed in one column in the data table.
        let str: string = '';
        if (this.internalOrder) {
            str += `IO: ${this.internalOrder} `;
        }
        if (this.costCenter) {
            str += `CC: ${this.costCenter}`;
        }
        return str.trim();
    }
}
