import { AnyAction } from 'redux';
import { UserDelegate } from '../../../models/user/userDelegate';
import { UserProfile } from '../../../models/user/userProfile';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';
import { AppDispatch } from '../../reduxStore';
import { createShortGuid } from '../../../common/common.func.guid';
import { AccessRight } from '../../../common/appEnums';

export interface IUserProfilePageData {
    delegateToArray?: UserDelegate[];
}

/**
 * Action creator: Store user profile page date.
 * @param delegatdelegateToArrayeTo Delegate to array.
 * @returns Redux action.
 */
export const storeUserProfilePageData = (delegateToArray: UserDelegate[]): AnyAction => {
    return {
        type: actionTypes.USERPROFILE_STORE_PAGEDATA,
        userProfilePageData: {
            delegateToArray: delegateToArray
        } as IUserProfilePageData
    } as AnyAction;
};

export interface IEditDelegateTo {
    clientRowKey: string;
    alias?: string;
    aadOid?: string;
    isValid?: boolean;
    accessRights?: AccessRight[];
    companyCodes?: number[];
    startDate?: Date;
    endDate?: Date;
}

/**
 * Action creator: Edit delegate to.
 * @param clientRowKey Client row key used to identify the delegate row to update. Note that it is legal to have multiple rows with the same alias.
 * @param alias Alias.
 * @param aadOid AAD object id.
 * @returns Redux action.
 */
export const editDelegateTo = (clientRowKey: string, alias: string, aadOid: string): AnyAction => {
    return {
        type: actionTypes.EDIT_DELEGATE_TO,
        editDelegateTo: {
            clientRowKey: clientRowKey,
            alias: alias,
            aadOid: aadOid
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Action creator: Edit delegate to valid.
 * @param clientRowKey Client row key used to identify the delegate row to update. Note that it is legal to have multiple rows with the same alias.
 * @param isValid Is valid.
 * @returns Redux action.
 */
export const editDelegateToValid = (clientRowKey: string, isValid: boolean): AnyAction => {
    return {
        type: actionTypes.EDIT_DELEGATE_TO_VALID,
        editDelegateTo: {
            clientRowKey: clientRowKey,
            isValid
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Action creator: Delete delegate to.
 * @param clientRowKey Client row key.
 * @returns Redux action.
 */
export const deleteDelegateTo = (clientRowKey: string): AnyAction => {
    return {
        type: actionTypes.DELETE_DELEGATE_TO,
        editDelegateTo: {
            clientRowKey: clientRowKey
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Action creator: Add delegate to.
 * @returns Redux action.
 */
export const addDelegateTo = (): AnyAction => {
    return {
        type: actionTypes.ADD_DELEGATE_TO
    } as AnyAction;
};

/**
 * Action creator: Edit delegate access right.
 * @param clientRowKey Client row key used to identify the delegate row to update. Note that it is legal to have multiple rows with the same alias.
 * @param accessRights Access rights.
 * @returns Redux action.
 */
export const editDelegateToAccessRights = (clientRowKey: string, accessRights: AccessRight[]): AnyAction => {
    return {
        type: actionTypes.EDIT_DELEGATE_TO_ACCESSRIGHTS,
        editDelegateTo: {
            clientRowKey: clientRowKey,
            accessRights: accessRights
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Action creator: Edit delegate company codes.
 * @param clientRowKey Client row key.
 * @param companyCodes Company codes.
 * @returns Redux action.
 */
export const editDelegateToCompanyCodes = (clientRowKey: string, companyCodes: number[]): AnyAction => {
    return {
        type: actionTypes.EDIT_DELEGATE_TO_COMPANYCODES,
        editDelegateTo: {
            clientRowKey: clientRowKey,
            companyCodes: companyCodes
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Action creator: Edit delegate start date.
 * @param clientRowKey Client row key.
 * @param startDate Start date.
 * @returns Redux action.
 */
export const editDelegateToStartDate = (clientRowKey: string, startDate: Date): AnyAction => {
    return {
        type: actionTypes.EDIT_DELEGATE_TO_STARTDATE,
        editDelegateTo: {
            clientRowKey: clientRowKey,
            startDate: startDate
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Action creator: Edit delegate end date.
 * @param clientRowKey Client row key.
 * @param endDate End date.
 * @returns Redux action.
 */
export const editDelegateToEndDate = (clientRowKey: string, endDate: Date): AnyAction => {
    return {
        type: actionTypes.EDIT_DELEGATE_TO_ENDDATE,
        editDelegateTo: {
            clientRowKey: clientRowKey,
            endDate: endDate
        } as IEditDelegateTo
    } as AnyAction;
};

/**
 * Payload used with callApiSaveUserProfile action.
 */
export interface IApiSaveUserProfile extends ICallApiBase {
    // No data returned.
}

/**
 * Save user profile.
 * @param userProfile User profile to save.
 * @returns Redux dispatch function.
 */
export const callApiSaveUserProfile = (userProfile: UserProfile): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_SAVE_USER_PROFILE,
        async () => {
            return await receiptingApiClient.saveUserProfile(userProfile) || null;
        }
    );
}

/**
 * Reload user profile action payload.
 */
export interface IApiReloadUserProfile extends ICallApiBase {
    userProfile?: UserProfile | undefined;
}

/**
 * Reload user profile. Initial load is done during app startup.
 * @param alias User alias.
 * @returns Redux dispatch function.
 */
export const callApiReloadUserProfile = (alias: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<UserProfile | undefined>(
        actionTypes.API_RELOAD_USER_PROFILE,
        async () => {
            return await receiptingApiClient.loadUserProfile(alias) || undefined;
        },
        (payload: IApiReloadUserProfile, data, dispatch: AppDispatch) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.delegateTo.forEach((item: UserDelegate) => {
                    item.clientRowKey = createShortGuid();
                });
                data.delegateFrom.forEach((item: UserDelegate) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.userProfile = data || undefined;

            // Store data for the user profile page.
            dispatch(
                storeUserProfilePageData(
                    payload.userProfile?.delegateTo || []
                )
            );
        }
    );
};
