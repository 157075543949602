import React from 'react';
import { ICustomCoherenceTreeItem } from './ICustomCoherenceTreeItem';
import { IHierarchyNodeCommon } from '../../models/hierarchy/hierarchyNodeCommon';
import { HierarchyTreeNodeIcon } from './HierarchyTreeNodeIcon';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { IHierarchyTreeItemState } from './IHierarchyTreeItemState';
import { HierarchyTreeType } from './HierarchyTreeType';

// Type used with tree item state changed callback.
export type TreeItemStateChangedCallback = (treeItemStates: IKeyValuePair<IHierarchyTreeItemState>) => void;

/**
 * Hierarchy manager. Stores all tree items and states, and has functionality for managing the hierarchy tree.
 */
export class HierarchyManager {
    private readonly: boolean = false;
    private instanceId: string;
    private hierarchyTreeType: HierarchyTreeType;
    private treeItemStateChangedCallback: TreeItemStateChangedCallback;

    public customCoherenceTreeItems: ICustomCoherenceTreeItem[] = [];
    public hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> = {};

    /**
     * Creates a new hierarchy manager.
     * @param instanceId Instance id.
     * @param hierarchyTreeType Hierarchy tree type.
     * @param treeItemStateChangedCallback Tree item state changed callback.
     */
    constructor(instanceId: string, hierarchyTreeType: HierarchyTreeType, treeItemStateChangedCallback: TreeItemStateChangedCallback) {
        this.instanceId = instanceId;
        this.hierarchyTreeType = hierarchyTreeType;
        this.treeItemStateChangedCallback = treeItemStateChangedCallback;
    }

    /**
     * Get all leaf most nodes under a node.
     * @param treeItem Custom coherence tree item.
     * @param allLeafItems Array of leaf items populated by this function.
     */
    private getAllLeafNodesUnderNode(treeItem: ICustomCoherenceTreeItem, allLeafItems: ICustomCoherenceTreeItem[]) {
        let isLeafNode: boolean = false;
        if (treeItem.children && treeItem.children.length > 0) {
            // If there are children, recurse into those.
            for (let i: number = 0; i < treeItem.children.length; i++) {
                const childItem: ICustomCoherenceTreeItem = treeItem.children[i] as ICustomCoherenceTreeItem;
                this.getAllLeafNodesUnderNode(childItem, allLeafItems);
            }
        } else {
            // This is a leaf most node.
            isLeafNode = true;
            allLeafItems.push(treeItem);
        }
        return isLeafNode;
    }

    /**
     * Update isChecked to true or false recursively. isIndeterminate will be set to false for all.
     * @param treeItem Tree item to update and all of its children.
     * @param isChecked Checked or not checked.
     */
    private updateCheckRecursive(treeItem: ICustomCoherenceTreeItem, isChecked: boolean) {
        treeItem.isIndeterminate = false;
        treeItem.isChecked = isChecked;

        this.hierarchyTreeItemStates[treeItem.id] = {
            isIndeterminate: treeItem.isIndeterminate,
            isChecked: treeItem.isChecked,
            isLeaf: treeItem.children ? false : true
        };

        if (treeItem.children) {
            for (let i: number = 0; i < treeItem.children.length; i++) {
                this.updateCheckRecursive(treeItem.children[i] as ICustomCoherenceTreeItem, isChecked);
            }
        }
    }

    /**
     * Handle checkbox change event.
     * @param treeItem Custom coherence tree item.
     * @param isChecked Is checked or not.
     */
    private handleCheckboxChange(treeItem: ICustomCoherenceTreeItem, isChecked: boolean) {
        this.updateCheckRecursive(treeItem, isChecked);

        // Call updateNodeStates on the top level root node. Because if this checkbox change was made on some
        // lower level node, to check or uncheck that node and its children, then this could change the isIndeterminate
        // and isChecked states on parent nodes too.
        this.updateNodeStates(this.customCoherenceTreeItems);

        this.treeItemStateChangedCallback(this.hierarchyTreeItemStates);
    }

    /**
     * Build the node "icon" element, which allows for custom JSX. So we will use this "icon" element
     * also for a Checkbox.
     * @param treeItem Custom coherence tree item.
     */
    private buildNodeIcon (treeItem: ICustomCoherenceTreeItem): JSX.Element {
        return (
            <HierarchyTreeNodeIcon
                instanceId={this.instanceId}
                hierarchyTreeType={this.hierarchyTreeType}
                treeItem={treeItem}
                readonly={this.readonly}
                onCheckboxChange={(treeItem: ICustomCoherenceTreeItem, checked: boolean) => {
                    this.handleCheckboxChange(treeItem, checked!);
                }
            }/>
        )
    }

    /**
     * Update the node state for all nodes by setting the isChecked and isIndeterminate.
     * - If a node has all leaf most nodes checked, then isIndeterminate is false and isChecked is true.
     * - If a node has no leaf most nodes checked, then isIndeterminate is false and isChecked is false.
     * - If a node has partial leaf most nodes checked, then isIndeterminate is true and isChecked is false.
     * @param treeItems Custom coherence tree items.
     */
    private updateNodeStates(treeItems: ICustomCoherenceTreeItem[]) {
        for (let i: number = 0; i < treeItems.length; i++) {
            const allLeafNodes: ICustomCoherenceTreeItem[] = [];
            this.getAllLeafNodesUnderNode(treeItems[i], allLeafNodes);

            const totalLeafNodeCount: number = allLeafNodes.length;
            let checkedCount: number = 0;
            allLeafNodes.forEach(n => {
                if (n.isChecked) {
                    checkedCount++;
                }
            });

            if (checkedCount === totalLeafNodeCount) {
                treeItems[i].isIndeterminate = false;
                treeItems[i].isChecked = true;
            } else if (checkedCount === 0) {
                treeItems[i].isIndeterminate = false;
                treeItems[i].isChecked = false;
            } else if (checkedCount < totalLeafNodeCount) {
                treeItems[i].isIndeterminate = true;
                treeItems[i].isChecked = false;
            }

            this.hierarchyTreeItemStates[treeItems[i].id] = {
                isIndeterminate: treeItems[i].isIndeterminate,
                isChecked: treeItems[i].isChecked,
                isLeaf: treeItems[i].children ? false : true
            };

            if (!treeItems[i].icon) {
                treeItems[i].icon = this.buildNodeIcon(treeItems[i]);
            }

            // Recurse into children.
            if (treeItems[i].children) {
                this.updateNodeStates(treeItems[i].children as ICustomCoherenceTreeItem[]);
            }
        }
    }

    /**
     * Build tree nodes recursively.
     * @param nodes Array of nodes implementing IHierarchyNodeCommon.
     * @param selectedCodes Codes for selected nodes.
     * @returns Array of tree nodes.
     */
    private buildNodes(nodes: IHierarchyNodeCommon[], selectedCodes: string[]): ICustomCoherenceTreeItem[] {
        const treeItems: ICustomCoherenceTreeItem[] = [];
        for (let i: number = 0; i < nodes.length; i++) {
            const isLeafNode: boolean = nodes[i].children ? false : true;

            let isChecked: boolean | undefined = undefined;
            let isIndeterminate: boolean | undefined = undefined;
            if (isLeafNode) {
                // Only check for codes on the leaf nodes. The other parent nodes have codes too, but we don't need to
                // check for those codes as they are never stored. Only leaf most nodes are stored on the user profile.
                isChecked = selectedCodes.indexOf(nodes[i].code) > -1;
                isIndeterminate = false; // Leaf nodes will always be not indeterminate.
            } else {
                // If not a leaf node, then always build it as isChecked = false and isIndeterminate = true.
                // The state for all parent nodes above leaf nodes will be determined in updateNodeStates.
                isChecked = false;
                isIndeterminate = true;
            }

            const treeItem: ICustomCoherenceTreeItem = {
                // From CoherenceTreeItem:
                id: nodes[i].code,
                title: nodes[i].description,
                // icon will be built during call to updateNodeStates, so don't set it here.
                isSelected: false,
                isExpanded: false,
                children: undefined, // Will be set below, need to pass in this treeItem into buildNodes.
                // From CustomCoherenceTreeItem:
                isChecked: isChecked,
                isIndeterminate: isIndeterminate,
                hierarchyNode: nodes[i]
            };
            treeItem.children = nodes[i].children ? this.buildNodes(nodes[i].children!, selectedCodes) : undefined;
            treeItems.push(treeItem);

            this.hierarchyTreeItemStates[treeItem.id] = {
                isIndeterminate: isIndeterminate,
                isChecked: isChecked,
                isLeaf: treeItem.children ? false : true
            };
        }

        // Return sorted tree items for each level.
        return treeItems.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    /**
     * Build the hierarchy tree by converting the data returned from the api into a format suitable for
     * the CoherenceTreeView control.
     * @param rootNodes Root nodes.
     * @param selectedCodes Codes for selected nodes.
     * @param readonly True if read only.
     */
    public buildHierarchy(rootNodes: IHierarchyNodeCommon[], selectedCodes: string[], readonly: boolean): void {
        this.readonly = readonly;
        this.customCoherenceTreeItems = this.buildNodes(rootNodes, selectedCodes);
        this.updateNodeStates(this.customCoherenceTreeItems);
    }

    /**
     * Clear selections.
     */
    public clearSelections(): void {
        // Loop over the root nodes in this.customCoherenceTreeItems and call handleCheckboxChange with false
        // (to unselect) for each. This will recursively uncheck every node.
        this.customCoherenceTreeItems.forEach(item => {
            this.handleCheckboxChange(item, false);
        });
    }
}
