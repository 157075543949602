import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { commonString } from '../../common/commonString';
import { validationConstants } from '../../common/validationConstants';
import { TextField, TooltipDelay, TooltipHost } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { closeLineItemCommentsChange, editLineItemClosedCommentsHasError } from '../../store/actions/pageActions/editPage.action';

interface ClosedCommentsInputProps {
    item: PurchaseOrderLineItem;
}

export const ClosedCommentsInput: React.FunctionComponent<ClosedCommentsInputProps> = (props: ClosedCommentsInputProps): JSX.Element => {
    const [errorMsg, setErrorMsg] = useState<string>('');

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <TooltipHost content={validationConstants.closedComments.tooltip} delay={TooltipDelay.long}>
            <TextField
                id={`closedComments-${props.item.purchaseOrderLineNumber}`}
                autoComplete='off'
                ariaLabel={`${commonString.closedComments} ${validationConstants.closedComments.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                value={props.item.closedComments}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                    newValue = newValue || '';

                    if (newValue.length > 0 &&
                        (
                            newValue?.length > validationConstants.closedComments.maxLength! ||
                            !RegExp(validationConstants.closedComments.pattern!).test(newValue)
                        )
                    ) {
                        setErrorMsg(validationConstants.closedComments.errorMsg!);
                        dispatch(editLineItemClosedCommentsHasError(props.item.purchaseOrderLineNumber, true));
                    } else {
                        setErrorMsg('');
                        dispatch(editLineItemClosedCommentsHasError(props.item.purchaseOrderLineNumber, false));
                    }

                    dispatch(closeLineItemCommentsChange(props.item.purchaseOrderLineNumber, newValue));
                }}
                onBlur={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                    dispatch(closeLineItemCommentsChange(props.item.purchaseOrderLineNumber, props.item.closedComments || ''));
                }}
                errorMessage={errorMsg}
                disabled={
                    props.item.checkToDisableEditing(false) ||
                    (props.item.canClose && !props.item.isClosed) // Disable if closed checkbox can be selected and is not selected.
                }
            />
        </TooltipHost>
    );
}
