import React, { useEffect, useState } from 'react';
import { ComboBox, IComboBox, IComboBoxOption, Label, TooltipDelay, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useAppSelector } from '../../store/hooks';
import { Company } from '../../models/domain/company';
import { IApiLoadCompanies } from '../../store/actions/app.action';
import { appConstants } from '../../common/appConstants';
import { CallApiState } from '../../store/actions/generic.action';
import { commonString } from '../../common/commonString';

export interface ICompanyCodeSelectionProps {
    showLabel: boolean;
    readonly: boolean;
    disabled: boolean;
    tooltip: string;
    selectedCompanyCodes: number[];
    onSelectionChanged?: (companyCodes: number[]) => void;

    /**
     * Optional input id. If not supplied then one will be generated.
     */
    inputId?: string;

    /**
     * Optional next input id. This is used to help with accessibility. Using the arrow keys to navigate out
     * of the ComboBox should move focus to the next or previous element if this control is used in a grid like
     * a DetailsList. When the ComboBox is not using allowFreeInput or allowFreeForm then by using the arrow
     * keys will not cause focus to move to the next or previous column's input. This will help make that work.
     * See code for onKeyDownCapture in the ComboBox.
     */
    nextInputId?: string;

    /**
     * Optional prev input id. This is used to help with accessibility. Using the arrow keys to navigate out
     * of the ComboBox should move focus to the next or previous element if this control is used in a grid like
     * a DetailsList. When the ComboBox is not using allowFreeInput or allowFreeForm then by using the arrow
     * keys will not cause focus to move to the next or previous column's input. This will help make that work.
     * See code for onKeyDownCapture in the ComboBox.
     */
    prevInputId?: string;
}

/**
 * Company code selection component. Note this is a generic company code selection control used on multiple pages, such as the user
 * profile page to set up delegates, and also the access request page for when requesting to be a finance controller.
 * Therefore it does not have any code specific to those pages, and rather it works genericly by emitting a onSelectionChanged
 * event so the calling code can handle that.
 * @param props Company code selection props.
 * @returns JSX for the component.
 */
export const CompanyCodeSelection: React.FunctionComponent<ICompanyCodeSelectionProps> = (props: ICompanyCodeSelectionProps): JSX.Element => {
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);
    const inputId: string = useId(); // This will be used only if props.inputElemId was not supplied.

    // Redux store selectors to get state from the store when it changes.
    const apiLoadCompanies: IApiLoadCompanies = 
        useAppSelector<IApiLoadCompanies>(state => state.appReducer.apiLoadCompanies);

    /**
     * Effect for when selected company codes changes via props.
     */
    useEffect(() => {
        setSelectedKeys(props.selectedCompanyCodes);
    }, [props.selectedCompanyCodes]);

    /**
     * Effect for when API to load company (with company code) data completes.
     */
    useEffect(() => {
        if (apiLoadCompanies.callApiState === CallApiState.Completed) {
            // Build company code combobox options
            const companyCodeComboboxOptions: IComboBoxOption[] = [];

            const allIsSelected: boolean = props.selectedCompanyCodes.indexOf(appConstants.allCompanyCodeValue) > -1;

            const companies: Company[] | undefined = apiLoadCompanies.companies;
            if (companies) {
                companyCodeComboboxOptions.push({
                    key: appConstants.allCompanyCodeValue,
                    text: commonString.allCompanyCodes,
                    selected: allIsSelected,
                    disabled: props.readonly
                } as IComboBoxOption);
                companies.sort((a: Company, b: Company) => (a.companyName < b.companyName) ? -1 : 1).forEach(c => {
                    companyCodeComboboxOptions.push({
                        key: c.companyCode,
                        text: `${c.companyName} - ${c.companyCode}`,
                        selected: !allIsSelected && props.selectedCompanyCodes.indexOf(c.companyCode) > -1,
                        disabled: props.readonly
                    } as IComboBoxOption);
                })
            }

            setOptions(companyCodeComboboxOptions);
        }
    }, [apiLoadCompanies.callApiState, apiLoadCompanies.companies, props.readonly, props.selectedCompanyCodes]);

    return (
        <>
            {props.showLabel && (
                <Label htmlFor={props.inputId || inputId}>{commonString.companyCodes}</Label>
            )}
            <TooltipHost content={props.tooltip} delay={TooltipDelay.long}>
                <ComboBox
                    id={props.inputId || inputId}
                    ariaLabel={`${commonString.companyCodes} ${props.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                    onKeyDownCapture={(event: React.KeyboardEvent<IComboBox>) => {
                        if (props.nextInputId && event.code === 'ArrowRight') {
                            document.getElementById(props.nextInputId)?.focus();
                        } else if (props.prevInputId && event.code === 'ArrowLeft') {
                            document.getElementById(props.prevInputId)?.focus();
                        }
                    }}
                    multiSelect={true}
                    autoComplete='off' // Turn off autoComplete when multiSelect is true.
                    options={options}
                    selectedKey={selectedKeys}
                    useComboBoxAsMenuWidth={false}
                    disabled={props.disabled}
                    onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                        if (option) {
                            // Get company code for the current option.
                            const companyCode: number = option.key as number;
                            // Prepare a new companyCodes array.
                            const companyCodes: number[] = [...selectedKeys];

                            if (companyCode === appConstants.allCompanyCodeValue && option.selected) {
                                companyCodes.splice(0, companyCodes.length);
                                companyCodes.push(appConstants.allCompanyCodeValue);
                            } else {
                                // Check if all is selected and is not the current option.
                                const allCompanyCodesIndex: number = companyCodes.indexOf(appConstants.allCompanyCodeValue);
                                if (companyCode !== appConstants.allCompanyCodeValue && option.selected && allCompanyCodesIndex > -1) {
                                    // Remove selection for all company codes.
                                    companyCodes.splice(allCompanyCodesIndex, 1);
                                }

                                const index: number = companyCodes.indexOf(companyCode);
                                if (option.selected && index < 0) {
                                    companyCodes.push(companyCode);
                                } else if (!option.selected && index > -1) {
                                    companyCodes.splice(index, 1);
                                }
                            }

                            // If user unselected everyting, then default back to all.
                            if (companyCodes.length === 0) {
                                companyCodes.push(appConstants.allCompanyCodeValue);
                            }

                            setSelectedKeys(companyCodes);

                            const newOptions = [...options];
                            newOptions.forEach(o => {
                                o.selected = companyCodes.indexOf(o.key as number) > -1;
                            });
                            setOptions(newOptions);
                        }
                    }}
                    onMenuDismissed={() => {
                        if (props.onSelectionChanged) {
                            props.onSelectionChanged(selectedKeys);
                        }
                    }}
                    errorMessage={selectedKeys.length === 0 ? commonString.selectAtLeastOne : ''}
                />
            </TooltipHost>
        </>
    );
};
