import { IComboBoxOption } from '@fluentui/react';
import { ShipmentUploadFileStatus } from '../../models/shipment/shipmentUploadFileStatus';
import { Organization } from '../../models/shipment/organization';

export const all: string = 'All';

export const shipmentUploadFileStatusOptions: IComboBoxOption[] = [
    {
        key: all,
        text: all
    } as IComboBoxOption,
    ...Object.keys(ShipmentUploadFileStatus).filter(x => !isNaN(Number(x))).map((val, index) => {
        return {
            key: Number(val),
            text: ShipmentUploadFileStatus[val]
        } as IComboBoxOption;
    })
];

export const supplierOptions: IComboBoxOption[] = [
    ...Object.keys(Organization).filter(x => !isNaN(Number(x))).map((val, index) => {
        return {
            key: Number(val),
            text: Organization[val]
        } as IComboBoxOption;
    })
];

export enum PivotItemKey {
    ShipmentFiles = 'ShipmentFiles',
    Upload = 'Upload'
}
