import { Configuration, LogLevel } from '@azure/msal-browser';
import { appConfig } from '../../shell/appConfig';
import { trimLeadingChars } from '../../common/common.func.transform';

/**
 * Initialize the MSAL configuration.
 * @returns MSAL configuration object.
 */
export const initMsalConfig = (): Configuration => {
    if (!appConfig.current) {
        throw new Error('Cannot initialize MSAL until app config is initialized.');
    }

    const buildRedirectUri = (): string => {
        let redirectUri: string = appConfig.current.msal.redirectUri + trimLeadingChars(window.location.pathname, ['/']);
        // If the url has querystring params present, then encode these first before redirecting for authentication.
        // They will be lost during the redirection and back unless encoded. See the index.tsx for how they are decoded
        // upon redirect back to this site.
        if (window.location.search) {
            const encodeToken: string = '_enc_';
            redirectUri += `${encodeToken}${encodeURIComponent(window.location.search)}${encodeToken}`;
        }
        return redirectUri;
    };

    const msalConfig: Configuration = {
        auth: {
            clientId: appConfig.current.msal.clientId,
            authority: appConfig.current.msal.authority,
            redirectUri: buildRedirectUri(),
            postLogoutRedirectUri: appConfig.current.msal.postLogoutRedirectUri,
            navigateToLoginRequestUrl: false
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Warning, // Use LogLevel.Verbose to see more diagnostic info.
                piiLoggingEnabled: false,
                loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                    console.log('[MSAL]', message);
                }
            },
            loadFrameTimeout: 6000
        },
        cache: {
            cacheLocation: appConfig.current.msal.cacheLocation,
            storeAuthStateInCookie: appConfig.current.msal.storeAuthStateInCookie
        }
    }

    return msalConfig;
};
