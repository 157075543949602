import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
    Checkbox,
    ComboBox,
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    FontIcon,
    IColumn,
    IComboBox,
    IComboBoxOption,
    IDetailsHeaderProps,
    Label,
    Link,
    Pivot,
    PivotItem,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { commonStyles, stackTokensSmallGap, stackTokensNormalGap, stackTokensLargeGap } from '../../common/common.styles';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { pageStyles } from './PriorYearAccrualPage.styles';
import './stickyDetailsListColumns.css';
import { CustomDetailsList, ExcelExportMode } from '../../components/CustomDetailsList/CustomDetailsList';
import { CallApiState } from '../../store/actions/generic.action';
import {
    forYouPriorYearLocalCurrencyAccrualColumns, 
    getElevatedPriorYearLocalCurrencyAccrualColumns,
    forYouPriorYearUsdCurrencyAccrualColumns,
    getElevatedPriorYearUsdCurrencyAccrualColumns
} from './priorYearAccrualColumns';
import {
    IApiPriorYearAccrualSearch,
    callApiPriorYearAccrualSearch,
    IApiPriorYearAccrualUserSummary,
    callApiPriorYearAccrualUserSummary,
    IApiPriorYearAccrualElevatedSummary,
    callApiPriorYearAccrualElevatedSummary,
    resetApiPriorYearAccrualSearch,
    toggleAllSelected,
    callApiPriorYearAccrualRetain,
    callApiPriorYearAccrualRefresh,
    callApiClosePoLines,
    IApiPriorYearAccrualRetain,
    IApiPriorYearAccrualRefresh,
    IApiClosePoLines,
    callApiPriorYearAccrualExport,
    IApiPriorYearAccrualExport,
    IApiTriggerPriorYearAccrualManualNotification,
    IApiUpdateAccountingDetails,
    callApiUpdateAccountingDetails,
    callApiTriggerPriorYearAccrualManualNotification,
    IApiPriorYearAccrualRegions,
    callApiPriorYearAccrualRegions,
    IApiCostCategorySubclasses,
    callApiCostCategorySubclasses,
    IApiPriorYearAccrualExportCorpDetails,
    callApiPriorYearAccrualExportCorpDetails,
    IApiPriorYearAccrualImportCorpDetails,
    callApiPriorYearAccrualImportCorpDetails
} from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addToArrayIfNotPresent, removeFromArray } from '../../common/common.func.transform';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { validationConstants } from '../../common/validationConstants';
import { appConstants } from '../../common/appConstants';
import { UserProfile } from '../../models/user/userProfile';
import { calcPercentage, reloadCountdown, validateInput } from '../../common/common.func.general';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { ApprovalStatus, PriorYearAccrualSearch, PriorYearAccrualSearchRole } from '../../models/priorYearAccrual/priorYearAccrualSearch';
import {
    DisplayCurrencyKey,
    PriorYearAccrualPivotItemKey,
    displayCurrencyOptions,
    filterApprovalOptions,
    filterAvailabilityOptions,
    filterStatusForYouOptions,
    filterStatusElevatedOptions,
    includeOwnPosKey,
    queryStringParams,
    fiscalYearOptions,
    summaryDataColors,
    all
} from './priorYearAccrualPageConstants';
import { Supplier } from '../../models/domain/supplier';
import { DataTile } from '../../components/DataTile/DataTile';
import { PurchaseOrderLineItemPair } from '../../models/purchaseOrder/purchaseOrderLineItemPair';
import { PurchaseOrderLineClose } from '../../models/purchaseOrder/purchaseOrderLineClose';
import { AppDispatch } from '../../store/reduxStore';
import { LoadFailureDisplay } from '../../components/LoadFailureDisplay/LoadFailureDisplay';
import { PyaLineItemDetailsPanel } from './PyaLineItemDetailsPanel';
import { appConfig } from '../../shell/appConfig';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { AdminTab } from './AdminTab';
import { SupplierInput } from '../../components/SupplierInput/SupplierInput';
import { commonString } from '../../common/commonString';
import { CorpDetailsDialog, CorpDetailsUpdateMode } from './CorpDetailsDialog';
import { SendReminderEmailDialog } from './SendReminderEmailDialog';
import { ConfirmCloseDialog } from './ConfirmCloseDialog';
import { ConfirmRetainDialog } from './ConfirmRetainDialog';
import { GetPageLinkDialog } from './PageLinkDialog';
import { CorpDetails } from '../../models/priorYearAccrual/corpDetails';
import { PriorYearAccrualStatus } from '../../models/priorYearAccrual/priorYearAccrualStatus';
import { GraphUserInput } from '../../components/GraphUserInput/GraphUserInput';
import { tooltips } from '../../common/tooltips';
import { GraphUser } from '../../models/user/graphUser';
import { IApiLoadCompanies, callApiLoadCompanies, showNotificationPanel, showVideoGuide, teachingBubbleClearArray } from '../../store/actions/app.action';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { HierarchySelection } from '../../components/HierarchySelection/HierarchySelection';
import { CustomPieChart } from '../../components/CustomPieChart/CustomPieChart';
import { LineItemAuditPanel } from '../../components/LineItemAuditPanel/LineItemAuditPanel';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { CompanyCodeSelection } from '../../components/CompanyCodeSelection/CompanyCodeSelection';
import { stripAllCompanyCodeValue } from '../../components/CompanyCodeSelection/companyCodeUtil';
import { InitialVideo } from '../../shell/VideoGuide/VideoGuide';
import { AccessRight } from '../../common/appEnums';

interface IPageProps extends ICommonPageProps {
}

export const PriorYearAccrualPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    const poPlusOptionalLineNumberInputId: string = useId();
    const fiscalYearInputId: string = useId();
    const delegatedByInputId: string = useId();
    const statusInputId: string = useId();
    const regionInputId: string = useId();
    const costCategorySubclassInputId: string = useId();
    const corpApprovalInputId: string = useId();
    const stcApprovalInputId: string = useId();
    const poePodAvailabilityInputId: string = useId();
    const currencyInputId: string = useId();
    const gridHeaderCheckboxInputId: string = useId();

    // If the PO/Line filter is used, then this flag will make it so only that field can be used and disable all the other filters.
    const [useFilterPoLineOnly, setUseFilterPoLineOnly] = useState<boolean>(false);

    const [filterPoPlusOptionalLineNumber, setFilterPoPlusOptionalLineNumber] = useState<string>('');
    const [filterSupplierName, setFilterSupplierName] = useState<string>('');
    const [filterSupplierNumber, setFilterSupplierNumber] = useState<string>('');
    const [filterCompanyCodes, setFilterCompanyCodes] = useState<number[] | undefined>();
    const [filterPoOwner, setFilterPoOwner] = useState<string>('');
    const [filterFiscalYearKeys, setFilterFiscalYearKeys] = useState<string[]>([all]);
    const [filterDelegatedBy, setFilterDelegatedBy] = useState<string[] | undefined>();
    const [filterIncludeOwnPo, setFilterIncludeOwnPo] = useState<boolean>(true);
    const [filterStatusKeys, setFilterStatusKeys] = useState<(PriorYearAccrualStatus | string)[]>([all]);
    const [filterRegionKeys, setFilterRegionKeys] = useState<string[]>([all]);
    const [filterChannelFunctionDetailCodes, setFilterChannelFunctionDetailCodes] = useState<string[]>([]);
    const [filterExecutiveFunctionDetailCodes, setFilterExecutiveFunctionDetailCodes] = useState<string[]>([]);
    const [filterCostCategorySubclassKeys, setFilterCostCategorySubclassKeys] = useState<string[]>([all]);
    const [filterCorpApproval, setFilterCorpApproval] = useState<ApprovalStatus | undefined>();
    const [filterStcApproval, setFilterStcApproval] = useState<ApprovalStatus | undefined>();
    const [filterPoePodAvailability, setFilterPoePodAvailability] = useState<ApprovalStatus | undefined>();

    const [clearSupplierInputCounter, setClearSupplierInputCounter] = useState<number>(0);
    const [clearPoOwnerInputCounter, setClearPoOwnerInputCounter] = useState<number>(0);

    const [filterFiscalYearOptions, setFilterFiscalYearOptions] = useState<IComboBoxOption[]>(fiscalYearOptions);
    const [filterStatusOptions, setFilterStatusOptions] = useState<IComboBoxOption[]>([]);
    const [filterRegionOptions, setFilterRegionOptions] = useState<IComboBoxOption[]>([]);
    const [filterCostCategorySubclassOptions, setFilterCostCategorySubclassOptions] = useState<IComboBoxOption[]>([]);

    const [showViewPosFor, setShowViewPosFor] = useState<boolean>(false);
    const [viewPosForOptions, setViewPosForOptions] = useState<IComboBoxOption[]>([]);
    
    // Currency is not a filter to be passed to api. Locally it just controls what columns are shown.
    const [displayCurrencyKey, setDisplayCurrencyKey] = useState<DisplayCurrencyKey>(DisplayCurrencyKey.Local);

    // Array of filter inputs that are invalid.
    const [filterInputInvalid, setFilterInputInvalid] = useState<string[]>([]);

    const [displayCollapsablePageSummary, setDisplayCollapsablePageSummary] = useState<boolean>(true);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(appConfig.current.settings.priorYearAccrualDefaultPageSize);
    const [columns, setColumns] = useState<IColumn[]>(forYouPriorYearLocalCurrencyAccrualColumns);

    const [showPageLinkDialog, { toggle: toggleShowPageLinkDialog }] = useBoolean(false);
    const [showConfirmRetainDialog, { toggle: toggleShowConfirmRetainDialog }] = useBoolean(false);
    const [showConfirmCloseDialog, { toggle: toggleShowConfirmCloseDialog }] = useBoolean(false);
    const [showReminderEmailDialog, { toggle: toggleShowReminderEmailDialog }] = useBoolean(false);
    const [showCorpDetailsDialog, { toggle: toggleShowCorpDetailsDialog }] = useBoolean(false);

    const [corpDetailsUpdateMode, setCorpDetailsUpdateMode] = useState<CorpDetailsUpdateMode>(CorpDetailsUpdateMode.All);
    const [autoSearch, setAutoSearch] = useState<boolean>(false);
    const [queryStringParamsEvaluated, setQueryStringParamsEvaluated] = useState<boolean>(false);
    const [completedMsg, setCompletedMsg] = useState<string>('');

    // The elevated role name is used to store the elevated role by precedence if the user has one or more of these:
    // Agent, Accruals Admin, Finance Controller. If the user has multiple of these, then the first in the order shown
    // is taken as the precent. Most users have one such role, or no role at all if they are regular users. Generally
    // only us in the dev team, or sometimes the business operations team, might have multiple roles.
    const [elevatedRoleName, setElevatedRoleName] = useState<string>();
    // The hasElevatedAccess will only be set to true if the user is a Finance Controller, Accruals Admin, or Agent.
    // This is a ref as I don't want changes to this to cause a re-render or be in dependency arrays.
    const hasElevatedAccess = useRef<boolean>(false);
    const elevatedSearchRole = useRef<PriorYearAccrualSearchRole>();

    // The hasAdminAccess will only be set to true if the user is an Accruals Admin.
    const hasAdminAccess = useRef<boolean>(false);

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    // Below used with generic dialog.
    const [displayGenericDialog, { toggle: toggleDisplayGenericDialog }] = useBoolean(false);
    const [genericDialogTitle, setGenericDialogTitle] = useState<string>('');
    const [genericDialogMsg, setGenericDialogMsg] = useState<string>('');
    const [genericDialogCustomContent, setGenericDialogCustomContent] = useState<JSX.Element>();
    const callbackAfterGenericDialogCloseRef = useRef<() => void>();

    const importCorpDetailsFileInputRef = useRef<HTMLInputElement>(null);

    // Redux store selectors to get state from the store when it changes.
    const apiPriorYearAccrualUserSummary: IApiPriorYearAccrualUserSummary =
        useAppSelector<IApiPriorYearAccrualUserSummary>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualUserSummary);
    const apiPriorYearAccrualElevatedSummary: IApiPriorYearAccrualElevatedSummary =
        useAppSelector<IApiPriorYearAccrualElevatedSummary>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualElevatedSummary);
    const apiLoadCompanies: IApiLoadCompanies = 
        useAppSelector<IApiLoadCompanies>(state => state.appReducer.apiLoadCompanies);
    const apiPriorYearAccrualRegions: IApiPriorYearAccrualRegions =
        useAppSelector<IApiPriorYearAccrualRegions>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualRegions);
    const apiCostCategoriySubclasses: IApiCostCategorySubclasses =
        useAppSelector<IApiCostCategorySubclasses>((state) => state.priorYearAccrualPageReducer.apiCostCategoriySubclasses);
    const apiPriorYearAccrualSearch: IApiPriorYearAccrualSearch =
        useAppSelector<IApiPriorYearAccrualSearch>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualSearch);
    const apiPriorYearAccrualExport: IApiPriorYearAccrualExport =
        useAppSelector<IApiPriorYearAccrualExport>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualExport);
    const apiPriorYearAccrualExportCorpDetails: IApiPriorYearAccrualExportCorpDetails =
        useAppSelector<IApiPriorYearAccrualExportCorpDetails>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualExportCorpDetails);
    const apiPriorYearAccrualImportCorpDetails: IApiPriorYearAccrualImportCorpDetails =
        useAppSelector<IApiPriorYearAccrualImportCorpDetails>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualImportCorpDetails);
    const apiPriorYearAccrualRetain: IApiPriorYearAccrualRetain =
        useAppSelector<IApiPriorYearAccrualRetain>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualRetain);
    const apiPriorYearAccrualRefresh: IApiPriorYearAccrualRefresh =
        useAppSelector<IApiPriorYearAccrualRefresh>((state) => state.priorYearAccrualPageReducer.apiPriorYearAccrualRefresh);
    const apiClosePoLines: IApiClosePoLines =
        useAppSelector<IApiClosePoLines>((state) => state.priorYearAccrualPageReducer.apiClosePoLines);
    const apiTriggerPriorYearAccrualManualNotification: IApiTriggerPriorYearAccrualManualNotification =
        useAppSelector<IApiTriggerPriorYearAccrualManualNotification>((state) => state.priorYearAccrualPageReducer.apiTriggerPriorYearAccrualManualNotification);
    const apiUpdateAccountingDetails: IApiUpdateAccountingDetails =
        useAppSelector<IApiUpdateAccountingDetails>((state) => state.priorYearAccrualPageReducer.apiUpdateAccountingDetails);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Memoized boolean to indicate if For You tab is active.
     */
    const isForYouTabActive = useMemo<boolean>(() => {
        return params.activeTab?.toLowerCase() === PriorYearAccrualPivotItemKey.ForYou.toLowerCase();
    }, [params.activeTab]);

    /**
     * Memoized boolean to indicate if the Elevated tab is active.
     * It doesn't "actually" check if the tab is active - it checks the "activeTab" in the query string.
     */
    const isElevatedTabActive = useMemo<boolean>(() => {
        return params.activeTab?.toLowerCase() === PriorYearAccrualPivotItemKey.Elevated.toLowerCase();
    }, [params.activeTab]);

    /**
     * Memoized boolean to indicate if the Administration tab is active.
     */
    const isAdminTabActive = useMemo<boolean>(() => {
        return params.activeTab?.toLowerCase() === PriorYearAccrualPivotItemKey.Administration.toLowerCase();
    }, [params.activeTab]);

    /**
     * Memoized boolean to check if illegal access to elevated tab.
     */
    const isIllegalAccessToElevatedTab = useMemo<boolean>(() => {
        // Wait until query string params are evaluated before checking for illegal access.
        // This is because the hasElevatedAccess ref is not set until then.
        if (queryStringParamsEvaluated && !hasElevatedAccess.current && isElevatedTabActive) {
            return true;
        } else {
            return false;
        }
    }, [isElevatedTabActive, queryStringParamsEvaluated]);

    /**
     * Memoized boolean to check if illegal access to admin tab.
     */
    const isIllegalAccessToAdminTab = useMemo<boolean>(() => {
        // Wait until query string params are evaluated before checking for illegal access.
        // This is because the hasAdminAccess ref is not set until then.
        if (queryStringParamsEvaluated && !hasAdminAccess.current && isAdminTabActive) {
            return true;
        } else {
            return false;
        }
    }, [isAdminTabActive, queryStringParamsEvaluated]);

    /**
     * Memoized URL search params.
     */
    const urlSearchParams: URLSearchParams = useMemo(() => {
        const usp: URLSearchParams = new URLSearchParams(location.search);
        return usp;
    }, [location.search]);

    /**
     * Memoized value to check if all required page data is loaded.
     */
    const isRequiredPageDataLoaded: boolean = useMemo(() => {
        if (apiLoadCompanies.callApiState !== CallApiState.Completed) {
            return false;
        }

        if (isForYouTabActive) {
            // Nothing required to load in this view.
            return true;
        }

        if (isElevatedTabActive) {
            if (apiPriorYearAccrualRegions.callApiState === CallApiState.Completed &&
                apiCostCategoriySubclasses.callApiState === CallApiState.Completed) {
                return true;
            }
        }

        if (isAdminTabActive) {
            // Nothing required to load in this view.
            return true;
        }

        return false;
    }, [apiCostCategoriySubclasses.callApiState, apiLoadCompanies.callApiState, apiPriorYearAccrualRegions.callApiState, isAdminTabActive, isElevatedTabActive, isForYouTabActive]);

    /**
     * Effect to be called during initial page load.
     * To be used for initiating the load of any required page data.
     * Use in conjunction with memoized isRequiredPageDataLoaded.
     */
    useEffect(() => {
        // If the api call to load companies has already been called, then no need to call it again.
        // If not yet called, then call it now.
        if (apiLoadCompanies.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadCompanies());
        }

        if (isElevatedTabActive) {
            // Only need to load regions if on the elevated tab.
            if (apiPriorYearAccrualRegions.callApiState === CallApiState.Initial) {
                // If the api to get regions is not yet called, then call it. If this page was visited previously, then the
                // data is still in Redux state, so no need to call it again.
                dispatch(callApiPriorYearAccrualRegions());
            }

            // Only need to load cost category subclasses if on the elevated tab.
            if (apiCostCategoriySubclasses.callApiState === CallApiState.Initial) {
                // If the api to get cost category subclasses is not yet called, then call it. If this page was visited previously,
                // then the data is still in Redux state, so no need to call it again.
                dispatch(callApiCostCategorySubclasses());
            }
        }
    }, [apiCostCategoriySubclasses.callApiState, apiLoadCompanies.callApiState, apiLoadCompanies.companies, apiPriorYearAccrualRegions.callApiState, dispatch, isElevatedTabActive]);

    /**
     * Effect for when required page required data is loaded.
     * Certain apis such as to get the regions used in the search filter ComboBox need to be retrieved and loaded first
     * before proceeding with other page initialization.
     */
    useEffect(() => {
        if (isRequiredPageDataLoaded) {
            // *********************************************************************************
            // Evaluate query string params and set filter defaults based on the params.
            const poParam: string | null = urlSearchParams.get(queryStringParams.po);
            const ccParam: string | null = urlSearchParams.get(queryStringParams.cc);
            const ownerParam: string | null = urlSearchParams.get(queryStringParams.owner);
            const fyParam: string | null = urlSearchParams.get(queryStringParams.fy);
            const statusParam: string | null = urlSearchParams.get(queryStringParams.status);
            const supParam: string | null = urlSearchParams.get(queryStringParams.sup);
            const cfdcParam: string | null = urlSearchParams.get(queryStringParams.cfdc);
            const efdcParam: string | null = urlSearchParams.get(queryStringParams.efdc);
            const ccscParam: string | null = urlSearchParams.get(queryStringParams.ccsc);
            const corpParam: string | null = urlSearchParams.get(queryStringParams.corp);
            const stcParam: string | null = urlSearchParams.get(queryStringParams.stc);
            const ppParam: string | null = urlSearchParams.get(queryStringParams.pp);
            const curParam: string | null = urlSearchParams.get(queryStringParams.cur);
            const vfParam: string | null = urlSearchParams.get(queryStringParams.vf);
            const regParam: string | null = urlSearchParams.get(queryStringParams.reg);
    
            if (poParam) {
                setFilterPoPlusOptionalLineNumber(poParam || '');
            }
    
            if (ccParam) {
                const companyCodes: number[] = [];
                ccParam.split(',').forEach(cc => {
                    const ccNum: number = Number(cc);
                    if (!isNaN(ccNum)) {
                        companyCodes.push(ccNum);
                    }
                })
                if (companyCodes.length === 0) {
                    companyCodes.push(appConstants.allCompanyCodeValue);
                }
                setFilterCompanyCodes(companyCodes);
            }
            
            if (ownerParam) {
                setFilterPoOwner(ownerParam || '');
            }
    
            if (fyParam) {
                const fyKeys: string[] = [];
                fyParam.split(',').forEach(key => {
                    if (fiscalYearOptions.findIndex(x => x.key === key) > -1) {
                        fyKeys.push(key);
                    }
                });
                if (fyKeys.length === 0) {
                    fyKeys.push(all);
                }
                setFilterFiscalYearKeys(fyKeys);
            }
    
            if (statusParam) {
                const statusKeys: (PriorYearAccrualStatus | string)[] = [];
                statusParam.split(',').forEach(key => {
                    if (isForYouTabActive) {
                        if (filterStatusForYouOptions.findIndex(x => String(x.key) === key) > -1) {
                            statusKeys.push(Number(key) as PriorYearAccrualStatus);
                        }
                    } else if (isElevatedTabActive) {
                        if (filterStatusElevatedOptions.findIndex(x => String(x.key) === key) > -1) {
                            statusKeys.push(Number(key) as PriorYearAccrualStatus);
                        }
                    }
                });
                if (statusKeys.length === 0) {
                    statusKeys.push(all);
                }
                setFilterStatusKeys(statusKeys);
            }
    
            if (supParam) {
                setFilterSupplierNumber(supParam);
            }
    
            if (cfdcParam) {
                setFilterChannelFunctionDetailCodes(cfdcParam.split(','));
            }
    
            if (efdcParam) {
                setFilterExecutiveFunctionDetailCodes(efdcParam.split(','));
            }

            if (corpParam) {
                setFilterCorpApproval(
                    corpParam === '0' ? ApprovalStatus.PendingReview :
                    corpParam === '1' ? ApprovalStatus.NoOrNotApproved :
                    corpParam === '2' ? ApprovalStatus.YesOrApproved :
                    undefined
                );
            }
    
            if (stcParam) {
                setFilterStcApproval(
                    stcParam === '0' ? ApprovalStatus.PendingReview :
                    stcParam === '1' ? ApprovalStatus.NoOrNotApproved :
                    stcParam === '2' ? ApprovalStatus.YesOrApproved :
                    undefined
                );
            }
    
            if (ppParam) {
                setFilterPoePodAvailability(
                    ppParam === '0' ? ApprovalStatus.PendingReview :
                    ppParam === '1' ? ApprovalStatus.NoOrNotApproved :
                    ppParam === '2' ? ApprovalStatus.YesOrApproved :
                    undefined
                );
            }
    
            if (curParam) {
                setDisplayCurrencyKey(curParam === DisplayCurrencyKey.Usd ? DisplayCurrencyKey.Usd : DisplayCurrencyKey.Local);
            }

            // *********************************************************************************
            // Load region ComboBox options.
            if (isElevatedTabActive) {
                const regionOptions: IComboBoxOption[] = [
                    {
                        key: all,
                        text: all
                    } as IComboBoxOption,
                    ...(apiPriorYearAccrualRegions.regions || []).map((region)=> {
                        return {
                            key: region,
                            text: region
                        } as IComboBoxOption;
                    })
                ];
                setFilterRegionOptions(regionOptions);

                if (regParam) {
                    const regionKeys: string[] = [];
                    regParam.split(',').forEach(key => {
                        if (regionOptions.findIndex(x => String(x.key) === key) > -1) {
                            regionKeys.push(key);
                        }
                    });
                    if (regionKeys.length === 0) {
                        regionKeys.push(all);
                    }
                    setFilterRegionKeys(regionKeys);
                }
            }

            // *********************************************************************************
            // Load cost category subclass ComboBox options.
            if (isElevatedTabActive) {
                const costCategorySubclassOptions: IComboBoxOption[] = [
                    {
                        key: all,
                        text: all
                    } as IComboBoxOption,
                    ...(apiCostCategoriySubclasses.costCategorySubclasses || []).map((codeValue)=> {
                        return {
                            key: codeValue.code,
                            text: `${codeValue.code} - ${codeValue.value}`,
                            data: codeValue
                        } as IComboBoxOption;
                    })
                ];
                setFilterCostCategorySubclassOptions(costCategorySubclassOptions);

                if (ccscParam) {
                    const subclassKeys: string[] = [];
                    ccscParam.split(',').forEach(key => {
                        if (costCategorySubclassOptions.findIndex(x => String(x.key) === key) > -1) {
                            subclassKeys.push(key);
                        }
                    });
                    if (subclassKeys.length === 0) {
                        subclassKeys.push(all);
                    }
                    setFilterCostCategorySubclassKeys(subclassKeys);
                }
            }

            // *********************************************************************************
            // Get unexpired delegate from users and populate the "View POs For" ComboBox.
            const unexpiredDelegateFromUsers: string[] = userProfile?.getUnexpiredDelegateFromUsers(AccessRight.PoRequestor) || [];

            // Update the filter for delegated by.
            // If there is an option on the querystring to populate, then use that. Otherwise use all unexpired delegates.
            const selectedViewFor: string[] = [];
            const vfParamArray: string[] = vfParam?.split(',') || [];
            const vfParamSelfIncluded: boolean = vfParamArray.indexOf(userProfile?.alias!) > -1;
            const hasVfParam: boolean = vfParamArray.length > 0;
            if (hasVfParam) {
                // Validate that the alias in the vf query string are legitimate and are in unexpiredDelegateFromUsers.
                vfParamArray.forEach(x => {
                    if (unexpiredDelegateFromUsers.indexOf(x) > -1) {
                        selectedViewFor.push(x);
                    }
                });
            } else {
                selectedViewFor.push(...unexpiredDelegateFromUsers); 
            }
            setFilterDelegatedBy(selectedViewFor);

            // Update "View POs For" options to include delegates as well as the user.
            const delegateOptions: IComboBoxOption[] = [
                ...(unexpiredDelegateFromUsers).map((alias)=> {
                    return {
                        key: alias,
                        text: alias,
                        selected: selectedViewFor.indexOf(alias) > -1
                    } as IComboBoxOption;
                })
            ];

            setFilterIncludeOwnPo(hasVfParam ? vfParamSelfIncluded : true);

            const includeOwnPosOption: IComboBoxOption = {
                key: includeOwnPosKey,
                text: userProfile?.alias!,
                selected: hasVfParam ? vfParamSelfIncluded : true
            };

            setViewPosForOptions([...delegateOptions, includeOwnPosOption]);
    
            // Do not show the "View POs For" options if the user has no delegates.
            setShowViewPosFor(delegateOptions.length > 0);

            // *********************************************************************************
            // Set the elevated role name by precedence.
            if (userProfile?.isAgent) {
                setElevatedRoleName('Agent');
                elevatedSearchRole.current = PriorYearAccrualSearchRole.Agent;
            } else if (userProfile?.isAccrualsAdmin) {
                setElevatedRoleName('Accruals Admin');
                elevatedSearchRole.current = PriorYearAccrualSearchRole.AccrualAdmin;
            } else if (userProfile?.isFinanceController) {
                setElevatedRoleName('Finance Controller');
                elevatedSearchRole.current = PriorYearAccrualSearchRole.FinanceController;
            }
            hasElevatedAccess.current = userProfile?.isAgent || userProfile?.isAccrualsAdmin || userProfile?.isFinanceController || false;    

            hasAdminAccess.current = userProfile?.isAccrualsAdmin || false;

            // *********************************************************************************
            // This flag is used to delay rendering until the query string params are evaluated in this effect.
            setQueryStringParamsEvaluated(true);

            // *********************************************************************************
            // Set the auto search flag to true.
            // Changing this state value and handling the effect in the auto search useEffect works best because we need
            // the above state for all the filter defaults to be set first before searching.
            setAutoSearch(true);
        }
    }, [apiCostCategoriySubclasses.costCategorySubclasses, apiPriorYearAccrualRegions.callApiState, apiPriorYearAccrualRegions.regions, isElevatedTabActive, isForYouTabActive, isRequiredPageDataLoaded, urlSearchParams, userProfile]);

    /**
     * Get columns based on the active tab.
     */
    const getColumns = useCallback(() => {
        switch (displayCurrencyKey) {
            case DisplayCurrencyKey.Usd:
                return [
                    ...(isForYouTabActive ? forYouPriorYearUsdCurrencyAccrualColumns : []),
                    ...(isElevatedTabActive ? getElevatedPriorYearUsdCurrencyAccrualColumns() : [])
                ];
            default:
            case DisplayCurrencyKey.Local:
                return [
                    ...(isForYouTabActive ? forYouPriorYearLocalCurrencyAccrualColumns : []),
                    ...(isElevatedTabActive ? getElevatedPriorYearLocalCurrencyAccrualColumns() : [])
                ];
        }
    }, [displayCurrencyKey, isElevatedTabActive, isForYouTabActive]);

    /**
     * Effect for when displayCurrencyKey or params.activeTab changes.
     * Note this effect triggers because the getColumns dependency has dependencies on those.
     */
    useEffect(() => {
        // Set the columns to include additional retained columns.
        setColumns(getColumns());
    }, [getColumns]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiPriorYearAccrualUserSummary.errMsg) {
            handleError(apiPriorYearAccrualUserSummary.errMsg);
        }
        if (apiPriorYearAccrualElevatedSummary.errMsg) {
            handleError(apiPriorYearAccrualElevatedSummary.errMsg);
        }
        if (apiPriorYearAccrualSearch.errMsg) {
            handleError(apiPriorYearAccrualSearch.errMsg);
        }
        if (apiPriorYearAccrualExport.errMsg) {
            handleError(apiPriorYearAccrualExport.errMsg);
        }
        if (apiPriorYearAccrualExportCorpDetails.errMsg) {
            handleError(apiPriorYearAccrualExportCorpDetails.errMsg);
        }
        if (apiPriorYearAccrualImportCorpDetails.errMsg) {
            handleError(apiPriorYearAccrualImportCorpDetails.errMsg);
        }
        if (apiPriorYearAccrualRetain.errMsg) {
            handleError(apiPriorYearAccrualRetain.errMsg);
        }
        if (apiPriorYearAccrualRefresh.errMsg) {
            handleError(apiPriorYearAccrualRefresh.errMsg);
        }
        if (apiClosePoLines.errMsg) {
            handleError(apiClosePoLines.errMsg);
        }
        if (apiTriggerPriorYearAccrualManualNotification.errMsg) {
            handleError(apiTriggerPriorYearAccrualManualNotification.errMsg);
        }
        if (apiUpdateAccountingDetails.errMsg) {
            handleError(apiUpdateAccountingDetails.errMsg);
        }
        if (apiLoadCompanies.errMsg) {
            handleError(apiLoadCompanies.errMsg);
        }
        if (apiPriorYearAccrualRegions.errMsg) {
            handleError(apiPriorYearAccrualRegions.errMsg);
        }
        if (apiCostCategoriySubclasses.errMsg) {
            handleError(apiCostCategoriySubclasses.errMsg);
        }
    }, [apiClosePoLines.errMsg, apiCostCategoriySubclasses.errMsg, apiLoadCompanies.errMsg, apiPriorYearAccrualElevatedSummary.errMsg, apiPriorYearAccrualExport.errMsg, apiPriorYearAccrualExportCorpDetails.errMsg, apiPriorYearAccrualImportCorpDetails.errMsg, apiPriorYearAccrualRefresh.errMsg, apiPriorYearAccrualRegions.errMsg, apiPriorYearAccrualRetain.errMsg, apiPriorYearAccrualSearch.errMsg, apiPriorYearAccrualUserSummary.errMsg, apiTriggerPriorYearAccrualManualNotification.errMsg, apiUpdateAccountingDetails.errMsg, handleError]);

    /**
     * Show generic dialog.
     * @param title Dialog title.
     * @param msg Dialog message.
     * @param customContent Custom JSX content.
     * @param callback Callback to call after dialog closed.
     */
    const showGenericDialog = useCallback((title: string, msg: string, customContent?: JSX.Element, callback?: () => void) => {
        setGenericDialogTitle(title);
        setGenericDialogMsg(msg);
        setGenericDialogCustomContent(customContent);
        callbackAfterGenericDialogCloseRef.current = callback;
        toggleDisplayGenericDialog();
    }, [toggleDisplayGenericDialog]);

    /**
     * Perform search.
     * @param page Paginator page.
     * @param pageSize Page size.
     * @param updateSummary Update summary tiles or not.
     */
    const performSearch = useCallback((pageSize: number, page: number, updateSummary: boolean, callSearch: boolean) => {
        // If companyCodes is empty, then set it to undefined. The api expects this to be undefined if no company codes are selected.
        let companyCodes: number[] | undefined = stripAllCompanyCodeValue<number>(filterCompanyCodes);
        if (companyCodes.length === 0) {
            companyCodes = undefined;
        }

        // Depending on the selected view as, different filter inputs are shown. So set up the search inputs accordingly.
        if (isForYouTabActive) {
            const priorYearAccrualSearch: PriorYearAccrualSearch = new PriorYearAccrualSearch(
                filterPoPlusOptionalLineNumber,
                undefined, // PO Owner is not shown.
                filterFiscalYearKeys.indexOf(all) > -1 ? undefined : filterFiscalYearKeys,
                filterSupplierName,
                filterSupplierNumber,
                companyCodes,
                filterDelegatedBy,
                filterIncludeOwnPo,
                filterStatusKeys.indexOf(all) > -1 ? undefined : filterStatusKeys as PriorYearAccrualStatus[],
                undefined, // Corp Approval is not shown.
                undefined, // STC Approval is not shown.
                undefined, // POE/POD Availability is not shown.
                undefined, // Regions filter is not shown.
                undefined, // Cost Category Subclass filter is not shown.
                undefined, // Channel hierarchy is not shown.
                undefined, // Executive hierarchy is not shown.
                PriorYearAccrualSearchRole.User,
                pageSize, // Don't use the currentPageSize state as it may not be committed yet if it was just set. Use pageSize parameter.
                page // Don't use the selectedPage state as it may not be committed yet if it was just set. Use page parameter.
            );
            // Two APIs get called, one for the summary tiles and one for the prior year accrual search.
            dispatch(callApiPriorYearAccrualSearch(priorYearAccrualSearch));
            if (updateSummary) {
                dispatch(callApiPriorYearAccrualUserSummary(priorYearAccrualSearch));
            }
        } else if (isElevatedTabActive) {
            const priorYearAccrualSearch: PriorYearAccrualSearch = new PriorYearAccrualSearch(
                filterPoPlusOptionalLineNumber,
                filterPoOwner,
                filterFiscalYearKeys.indexOf(all) > -1 ? undefined : filterFiscalYearKeys,
                filterSupplierName,
                filterSupplierNumber,
                companyCodes,
                undefined, // Delegated By is not shown for elevated view.
                undefined, // Include own PO is not used for elevated view.
                filterStatusKeys.indexOf(all) > -1 ? undefined : filterStatusKeys as PriorYearAccrualStatus[],
                filterCorpApproval,
                filterStcApproval,
                filterPoePodAvailability,
                filterRegionKeys.indexOf(all) > -1 ? undefined : filterRegionKeys,
                filterCostCategorySubclassKeys.indexOf(all) > -1 ? undefined :
                    apiCostCategoriySubclasses.costCategorySubclasses?.filter(x => filterCostCategorySubclassKeys.indexOf(x.code) > -1),
                filterChannelFunctionDetailCodes,
                filterExecutiveFunctionDetailCodes,
                elevatedSearchRole.current,
                pageSize, // Don't use the currentPageSize state as it may not be committed yet if it was just set. Use pageSize parameter.
                page // Don't use the selectedPage state as it may not be committed yet if it was just set. Use page parameter.
            );
            // Two APIs get called, one for the summary tiles and one for the prior year accrual search.
            if (callSearch) {
                dispatch(callApiPriorYearAccrualSearch(priorYearAccrualSearch));
            }
            if (updateSummary) {
                dispatch(callApiPriorYearAccrualElevatedSummary(elevatedSearchRole.current!, priorYearAccrualSearch));
            }
        }
    }, [apiCostCategoriySubclasses.costCategorySubclasses, dispatch, filterChannelFunctionDetailCodes, filterCompanyCodes, filterCorpApproval, filterCostCategorySubclassKeys, filterDelegatedBy, filterExecutiveFunctionDetailCodes, filterFiscalYearKeys, filterIncludeOwnPo, filterPoOwner, filterPoPlusOptionalLineNumber, filterPoePodAvailability, filterRegionKeys, filterStatusKeys, filterStcApproval, filterSupplierName, filterSupplierNumber, isElevatedTabActive, isForYouTabActive]);

    /**
     * Perform Excel export.
     */
    const performExcelExport = useCallback(() => {
        // If companyCodes is empty, then set it to undefined. The api expects this to be undefined if no company codes are selected.
        let companyCodes: number[] | undefined = stripAllCompanyCodeValue<number>(filterCompanyCodes);
        if (companyCodes.length === 0) {
            companyCodes = undefined;
        }

        // Depending on the selected view as, different filter inputs are shown. So set up the search inputs accordingly.
        if (isForYouTabActive) {
            const priorYearAccrualSearch: PriorYearAccrualSearch = new PriorYearAccrualSearch(
                filterPoPlusOptionalLineNumber,
                undefined, // PO Owner is not shown.
                filterFiscalYearKeys.indexOf(all) > -1 ? undefined : filterFiscalYearKeys,
                filterSupplierName,
                filterSupplierNumber,
                companyCodes,
                filterDelegatedBy,
                filterIncludeOwnPo,
                filterStatusKeys.indexOf(all) > -1 ? undefined : filterStatusKeys as PriorYearAccrualStatus[],
                undefined, // Corp Approval is not shown.
                undefined, // STC Approval is not shown.
                undefined, // POE/POD Availability is not shown.
                undefined, // Regions filter is not shown.
                undefined, // Cost Category Subclass filter is not shown.
                undefined, // Channel hierarchy is not shown.
                undefined, // Executive hierarchy is not shown.
                PriorYearAccrualSearchRole.User,
                undefined, // recordsPerPage is set in server side code for the export api.
                undefined // startPage is set in server side code for the export api.
            );
            dispatch(callApiPriorYearAccrualExport(priorYearAccrualSearch));
        } else if (isElevatedTabActive) {
            const priorYearAccrualSearch: PriorYearAccrualSearch = new PriorYearAccrualSearch(
                filterPoPlusOptionalLineNumber,
                filterPoOwner,
                filterFiscalYearKeys.indexOf(all) > -1 ? undefined : filterFiscalYearKeys,
                filterSupplierName,
                filterSupplierNumber,
                companyCodes,
                undefined, // Delegated By is not shown for elevated view.
                undefined, // Include own PO is not used for elevated view.
                filterStatusKeys.indexOf(all) > -1 ? undefined : filterStatusKeys as PriorYearAccrualStatus[],
                filterCorpApproval,
                filterStcApproval,
                filterPoePodAvailability,
                filterRegionKeys.indexOf(all) > -1 ? undefined : filterRegionKeys,
                filterCostCategorySubclassKeys.indexOf(all) > -1 ? undefined :
                    apiCostCategoriySubclasses.costCategorySubclasses?.filter(x => filterCostCategorySubclassKeys.indexOf(x.code) > -1),
                filterChannelFunctionDetailCodes,
                filterExecutiveFunctionDetailCodes,
                elevatedSearchRole.current,
                undefined, // recordsPerPage is set in server side code for the export api.
                undefined // startPage is set in server side code for the export api.
            );
            dispatch(callApiPriorYearAccrualExport(priorYearAccrualSearch));
        }
    }, [apiCostCategoriySubclasses.costCategorySubclasses, dispatch, filterChannelFunctionDetailCodes, filterCompanyCodes, filterCorpApproval, filterCostCategorySubclassKeys, filterDelegatedBy, filterExecutiveFunctionDetailCodes, filterFiscalYearKeys, filterIncludeOwnPo, filterPoOwner, filterPoPlusOptionalLineNumber, filterPoePodAvailability, filterRegionKeys, filterStatusKeys, filterStcApproval, filterSupplierName, filterSupplierNumber, isElevatedTabActive, isForYouTabActive]);

    /**
     * Perform Excel export for corp details.
     */
    const performExcelExportCorpDetails = useCallback(() => {
        telemetryService.trackEvent({ name: trackedEvent.pyaCorpDetailsExportButtonClicked });

        // If companyCodes is empty, then set it to undefined. The api expects this to be undefined if no company codes are selected.
        let companyCodes: number[] | undefined = stripAllCompanyCodeValue<number>(filterCompanyCodes);
        if (companyCodes.length === 0) {
            companyCodes = undefined;
        }

        const priorYearAccrualSearch: PriorYearAccrualSearch = new PriorYearAccrualSearch(
            filterPoPlusOptionalLineNumber,
            filterPoOwner,
            filterFiscalYearKeys.indexOf(all) > -1 ? undefined : filterFiscalYearKeys,
            filterSupplierName,
            filterSupplierNumber,
            companyCodes,
            undefined, // Delegated By is not shown for elevated view.
            undefined, // Include own PO is not used for elevated view.
            filterStatusKeys.indexOf(all) > -1 ? undefined : filterStatusKeys as PriorYearAccrualStatus[],
            filterCorpApproval,
            filterStcApproval,
            filterPoePodAvailability,
            filterRegionKeys.indexOf(all) > -1 ? undefined : filterRegionKeys,
            filterCostCategorySubclassKeys.indexOf(all) > -1 ? undefined :
                apiCostCategoriySubclasses.costCategorySubclasses?.filter(x => filterCostCategorySubclassKeys.indexOf(x.code) > -1),
            filterChannelFunctionDetailCodes,
            filterExecutiveFunctionDetailCodes,
            elevatedSearchRole.current,
            undefined, // recordsPerPage is set in server side code for the export api.
            undefined // startPage is set in server side code for the export api.
        );
        dispatch(callApiPriorYearAccrualExportCorpDetails(priorYearAccrualSearch));
    }, [apiCostCategoriySubclasses.costCategorySubclasses, dispatch, filterChannelFunctionDetailCodes, filterCompanyCodes, filterCorpApproval, filterCostCategorySubclassKeys, filterExecutiveFunctionDetailCodes, filterFiscalYearKeys, filterPoOwner, filterPoPlusOptionalLineNumber, filterPoePodAvailability, filterRegionKeys, filterStatusKeys, filterStcApproval, filterSupplierName, filterSupplierNumber]);

    /**
     * Perform Excel import for corp details.
     */
    const performExcelImportCorpDetails = useCallback(() => {
        telemetryService.trackEvent({ name: trackedEvent.pyaCorpDetailsImportButtonClicked });
        // Trigger a click on the file input element.
        if (importCorpDetailsFileInputRef.current) {
            importCorpDetailsFileInputRef.current.value = '';
            importCorpDetailsFileInputRef.current.click();
        }
    }, []);

    /**
     * On import input change handler. Used with Excel import for corp details.
     * @param event Form event. 
     */
    const onImportCorpDetailsInput = (event: React.FormEvent<HTMLInputElement>) => {
        const files = importCorpDetailsFileInputRef.current?.files;

        if (!files || files.length !== 1) {
            return;
        }

        const file: File = files[0];

        if (file.size > (750 * 1024) /* 750k */) {
            showGenericDialog(
                'File size too large',
                `This file is ${file.size} bytes.<br>Please make sure the file is less than 750k.`
            );
            return;
        }

        if (file.name.indexOf('.xlsx') < 0) {
            showGenericDialog(
                'Wrong file type',
                'File must be an Excel .xlsx file.'
            );
            return;
        }

        showGenericDialog(
            'Import started',
            'Check the notification panel for import details.',
            undefined,
            () => {
                dispatch(showNotificationPanel(true));
            }
        );

        dispatch(callApiPriorYearAccrualImportCorpDetails(file));
    };

    /**
     * Effect for when apiPriorYearAccrualRetain returns data.
     * Retain api call handles all selected items in one go. While close line is async and completions will stream in
     * via SignalR notifications.
     */
    useEffect(() => {
        if (apiPriorYearAccrualRetain.callApiState === CallApiState.DataAvailable) {
            // Refresh data after a countdown interval.
            reloadCountdown(
                (msg) => setCompletedMsg(msg),
                () => {
                    setCompletedMsg('');
                    
                    // Set the selected page back to 1. Depending on the status filter (all, open, closed, retained),
                    // the search result count may now differ, which is why we need to reset the page back to 1.
                    // Retain is an immediate call in that the API controller immediately processes the retain request
                    // synchronously, while close is async in that a Function App processes the close requests. No busy
                    // spinner on the line is shown for retain, while a busy spinner is shown for close. SignalR is used
                    // for close to know when the request is done, but not for retain as it is synchronous.
                    const page: number = 1; // Reset the page to 1.
                    setSelectedPage(page);
                    
                    // Perform search.
                    performSearch(
                        currentPageSize, // Use the current page size.
                        page, // Don't use the selectedPage state as it isn't committed yet, use the const set above.
                        true,
                        true
                    );
                }
            );
        }
    }, [apiPriorYearAccrualRetain.callApiState, currentPageSize, performSearch]);

    /**
     * Effect for when apiClosePoLines returns data.
     * Close line is async and completions will stream in via SignalR notifications.
     */
    useEffect(() => {
        if (apiClosePoLines.callApiState === CallApiState.DataAvailable) {
            // Refresh data after a countdown interval.
            reloadCountdown(
                (msg) => setCompletedMsg(msg),
                () => {
                    setCompletedMsg('');

                    // Perform search to refresh data on the current page after the close lines api returns data.
                    // Do not set the selected page to back to 1.
                    // The lines that were marked for close should now have a busy indicator stating that offline processing
                    // is happening. This is handled by a function app that will send messages back via SignalR.
                    performSearch(
                        currentPageSize, // Use the current page size.
                        selectedPage, // Use the current selected page.
                        true,
                        true
                    );
                }
            );
        }
    }, [apiClosePoLines.callApiState, currentPageSize, performSearch, selectedPage]);

    /**
     * Effect for when apiUpdateAccountingDetails returns data.
     */
    useEffect(() => {
        if (apiUpdateAccountingDetails.callApiState === CallApiState.DataAvailable) {
            // Refresh data after a countdown interval.
            reloadCountdown(
                (msg) => setCompletedMsg(msg),
                () => {
                    setCompletedMsg('');

                    performSearch(
                        currentPageSize, // Use the current page size.
                        selectedPage, // Use the current selected page.
                        true,
                        true
                    );
                }
            );
        }
    }, [apiUpdateAccountingDetails.callApiState, currentPageSize, performSearch, selectedPage]);

    /**
     * Effect for when API to trigger prior year accrual manual notification returns.
     */
    useEffect(() => {
        if (apiTriggerPriorYearAccrualManualNotification.callApiState === CallApiState.DataAvailable) {
            setCompletedMsg('Email sent successfully.');
            setTimeout(() => {
                setCompletedMsg('');
            }, 3000);
            // No need to refresh data after this action.
        }
    }, [apiTriggerPriorYearAccrualManualNotification.callApiState]);

    /**
     * Effect for when API to refresh PO lines returns.
     */
    useEffect(() => {
        if (apiPriorYearAccrualRefresh.callApiState === CallApiState.DataAvailable) {
            setCompletedMsg('Refresh request queued successfully.');
            setTimeout(() => {
                setCompletedMsg('');
            }, 3000);
            // No need to refresh data after this action.
        }
    }, [apiPriorYearAccrualRefresh.callApiState]);

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (apiPriorYearAccrualSearch.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualSearch.callApiState]);

    /**
     * Memoized helper to check if the export api is running.
     * @returns True or false.
     */
    const isExportRunning = useMemo<boolean>(() => {
        if (apiPriorYearAccrualExport.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualExport.callApiState]);

    /**
     * Memoized helper to check if the export corp details api is running.
     * @returns True or false.
     */
    const isExportCorpDetailsRunning = useMemo<boolean>(() => {
        if (apiPriorYearAccrualExportCorpDetails.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualExportCorpDetails.callApiState]);

    /**
     * Memoized helper to check if the import corp details api is running.
     * @returns True or false.
     */
    const isImportCorpDetailsRunning = useMemo<boolean>(() => {
        if (apiPriorYearAccrualImportCorpDetails.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualImportCorpDetails.callApiState]);

    /**
     * Memoized helper to check if the retain api is running.
     * @returns True or false.
     */
    const isRetainRunning = useMemo<boolean>(() => {
        if (apiPriorYearAccrualRetain.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualRetain.callApiState]);

    /**
     * Memoized helper to check if the refresh api is running.
     * @returns True or false.
     */
    const isRefreshRunning = useMemo<boolean>(() => {
        if (apiPriorYearAccrualRefresh.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualRefresh.callApiState]);

    /**
     * Memoized helper to check if the close api is running.
     * @returns True or false.
     */
    const isCloseRunning = useMemo<boolean>(() => {
        if (apiClosePoLines.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiClosePoLines.callApiState]);

    /**
     * Memoized helper to check if the trigger prior year accrual manual notification api is running.
     * @returns True or false.
     */
    const isTriggerPriorYearAccrualManualNotificationRunning = useMemo<boolean>(() => {
        if (apiTriggerPriorYearAccrualManualNotification.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiTriggerPriorYearAccrualManualNotification.callApiState]);

    /**
     * Memoized helper to check if the update accounting details api is running.
     * @returns True or false.
     */
    const isUpdateAccountingDetailsRunning = useMemo<boolean>(() => {
        if (apiUpdateAccountingDetails.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiUpdateAccountingDetails.callApiState]);

    /**
     * Memoized helper that combines a check for all the page actions on the bottom of the page.
     * These are the buttons below the grid, for retain, close, manual email notification, accounting details, refresh.
     */
    const isPageActionRunning = useMemo<boolean>(() => {
        return isRetainRunning || isCloseRunning || isTriggerPriorYearAccrualManualNotificationRunning || isUpdateAccountingDetailsRunning || isRefreshRunning;
    }, [isCloseRunning, isRefreshRunning, isRetainRunning, isTriggerPriorYearAccrualManualNotificationRunning, isUpdateAccountingDetailsRunning]);

    /**
     * Memoized selected lines.
     */
    const selectedLines = useMemo<PriorYearAccrualLineItem[]>(() => {
        return apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items.filter(x => x.isSelected) || [];
    }, [apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items])

    /**
     * Memoized field for selection count.
     */
    const selectedCount: number = useMemo(() => {
        return selectedLines.length;
    }, [selectedLines.length]);

    /**
     * Search button clicked event handler.
     */
    const searchButtonClicked = useCallback(() => {
        telemetryService.trackEvent({ name: trackedEvent.pyaSearchButtonClicked });

        const page: number = 1; // Reset the page to 1.
        setSelectedPage(page);
        performSearch(
            currentPageSize,
            page, // Don't use the selectedPage state as it isn't committed yet, use the const set above.
            true,
            true
        );
    }, [currentPageSize, performSearch]);

     /**
      * Page load event handler.
      */
    const pageLoadOccurred = useCallback(() => {
        telemetryService.trackEvent({ name: trackedEvent.pyaPageLoadOccurred });

        const page: number = 1; // Reset the page to 1.
        setSelectedPage(page);
        performSearch(
            currentPageSize,
            page, // Don't use the selectedPage state as it isn't committed yet, use the const set above.
            true,
            false // Do not call the search api on page load.
        );
    }, [currentPageSize, performSearch]);

    /**
     * Clear filters.
     * @param excludePoLine Exclude PO line. If true, then all fields except the PO line filter are cleared.
     */
    const clearFilters = useCallback((excludePoLine: boolean = false) => {
        if (!excludePoLine) {
            setFilterPoPlusOptionalLineNumber('');
        }

        setFilterCompanyCodes([appConstants.allCompanyCodeValue]);
        setFilterFiscalYearKeys([all]);
        setFilterFiscalYearOptions(options => {
            options.forEach((option) => {
                option.selected = false;
            });
            return options;
        });
        setFilterIncludeOwnPo(true);
        setFilterStatusKeys([all]);
        setFilterStatusOptions(options => {
            options.forEach((option) => {
                option.selected = false;
            });
            return options;
        });
        setFilterRegionKeys([all]);
        setFilterRegionOptions(options => {
            options.forEach((option) => {
                option.selected = false;
            });
            return options;
        });
        setFilterChannelFunctionDetailCodes([]);
        setFilterExecutiveFunctionDetailCodes([]);
        setFilterCostCategorySubclassKeys([all]);
        setFilterCostCategorySubclassOptions(options => {
            options.forEach((option) => {
                option.selected = false;
            });
            return options;
        });
        setFilterCorpApproval(undefined);
        setFilterStcApproval(undefined);
        setFilterPoePodAvailability(undefined);

        // Not clearing the delegated by. Leaving that as-is.

        // Incrementing these counters will cause these autocomplete controls for supplier and graph user to clear.
        setClearSupplierInputCounter(x => x + 1);
        setClearPoOwnerInputCounter(x => x + 1);

        // Clear the validation errors if any had been set.
        setFilterInputInvalid([]);
    }, []);

    /**
     * Effect to set the filter status options based on the active tab.
     */
    useEffect(() => {
        setFilterStatusOptions(isForYouTabActive ? filterStatusForYouOptions : filterStatusElevatedOptions);
    }, [isForYouTabActive, params.activeTab]);

    /**
     * Effect for when auto search changes.
     * This will get set to once during page load, and also set to true when switching tabs.
     */
    useEffect(() => {
        if (autoSearch) {
            setAutoSearch(false);
            pageLoadOccurred();
        }
    }, [autoSearch, pageLoadOccurred]);

    /**
     * Page changed event handler.
     */
    const pageChanged = (page: number, pageSize: number) => {
        setSelectedPage(page);
        setCurrentPageSize(pageSize);

        performSearch(
            pageSize, // Don't use the currentPageSize state as it isn't committed yet. Use the input param.
            page, // Don't use the selectedPage state as it isn't committed yet. Use the input param.
            false,
            true
        );
    };

    /**
     * PO plus optional line number change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onChangePoPlusOptionalLineNumber = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue = newValue || '';
        setFilterPoPlusOptionalLineNumber(newValue.trim());

        if (newValue.length > 0) {
            // Clear all other filters except for this PO/line filter. All these other filters will also be disabled in the JSX.
            clearFilters(true);
            setUseFilterPoLineOnly(true);
        } else {
            setUseFilterPoLineOnly(false);
        }
    };

    /**
     * Fiscal Year change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFiscalYear = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: string = option.key as string;
            const keys: string[] = [...filterFiscalYearKeys];

            if (key === all && option.selected) {
                keys.splice(0, keys.length);
                keys.push(all);
            } else {
                // Check if all is selected and is not the current option.
                const allIndex: number = keys.indexOf(all);
                if (key !== all && option.selected && allIndex > -1) {
                    // Remove selection for all.
                    keys.splice(allIndex, 1);
                }

                const index: number = keys.indexOf(key);
                if (option.selected && index < 0) {
                    keys.push(key);
                } else if (!option.selected && index > -1) {
                    keys.splice(index, 1);
                }
            }

            // If user unselected everyting, then default back to all.
            if (keys.length === 0) {
                keys.push(all);
            }

            setFilterFiscalYearKeys(keys);

            const newOptions = [...filterFiscalYearOptions];
            newOptions.forEach(o => {
                o.selected = keys.indexOf(o.key as string) > -1;
            });
            setFilterFiscalYearOptions(newOptions);
        }
    };

    /**
     * Delegated By change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
     const onChangeDelegatedBy = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option === undefined || index === undefined) {
            return;
        }

        if (option.key === includeOwnPosKey) {
            setFilterIncludeOwnPo(option.selected!);
        } else {
            const delegatedBy: string[] = [...filterDelegatedBy || []];
            const optionIndex: number = delegatedBy.indexOf(option.text);
            if (option.selected && optionIndex < 0) {
                // If selected and not in the array yet, add it.
                delegatedBy.push(option.key as string);
            } else if (!option.selected && optionIndex > -1) {
                // If not selected and in the array, remove it.
                delegatedBy.splice(optionIndex, 1);
            }
            setFilterDelegatedBy(delegatedBy);
        }

        // Update the filter delegated by options with the changed option (now selected or unselected).
        const options: IComboBoxOption[] = [...viewPosForOptions];
        options[index] = option;
        setViewPosForOptions(options);
    };

    /**
     * Filter status change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFilterStatus = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: string = option.key as string;
            const keys: (PriorYearAccrualStatus | string)[] = [...filterStatusKeys];

            if (key === all && option.selected) {
                keys.splice(0, keys.length);
                keys.push(all);
            } else {
                // Check if all is selected and is not the current option.
                const allIndex: number = keys.indexOf(all);
                if (key !== all && option.selected && allIndex > -1) {
                    // Remove selection for all.
                    keys.splice(allIndex, 1);
                }

                const index: number = keys.indexOf(key);
                if (option.selected && index < 0) {
                    keys.push(key);
                } else if (!option.selected && index > -1) {
                    keys.splice(index, 1);
                }
            }

            // If user unselected everyting, then default back to all.
            if (keys.length === 0) {
                keys.push(all);
            }

            setFilterStatusKeys(keys);

            const newOptions = [...filterStatusOptions];
            newOptions.forEach(o => {
                o.selected = keys.indexOf(o.key as string) > -1;
            });
            setFilterStatusOptions(newOptions);
        }
    };

    /**
     * Filter region change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFilterRegion = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: string = option.key as string;
            const keys: string[] = [...filterRegionKeys];

            if (key === all && option.selected) {
                keys.splice(0, keys.length);
                keys.push(all);
            } else {
                // Check if all is selected and is not the current option.
                const allIndex: number = keys.indexOf(all);
                if (key !== all && option.selected && allIndex > -1) {
                    // Remove selection for all.
                    keys.splice(allIndex, 1);
                }

                const index: number = keys.indexOf(key);
                if (option.selected && index < 0) {
                    keys.push(key);
                } else if (!option.selected && index > -1) {
                    keys.splice(index, 1);
                }
            }

            // If user unselected everyting, then default back to all.
            if (keys.length === 0) {
                keys.push(all);
            }

            setFilterRegionKeys(keys);

            const newOptions = [...filterRegionOptions];
            newOptions.forEach(o => {
                o.selected = keys.indexOf(o.key as string) > -1;
            });
            setFilterRegionOptions(newOptions);
        }
    };

    /**
     * Filter cost category subclass change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFilterCostCategorySubclass = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: string = option.key as string;
            const keys: string[] = [...filterCostCategorySubclassKeys];

            if (key === all && option.selected) {
                keys.splice(0, keys.length);
                keys.push(all);
            } else {
                // Check if all is selected and is not the current option.
                const allIndex: number = keys.indexOf(all);
                if (key !== all && option.selected && allIndex > -1) {
                    // Remove selection for all.
                    keys.splice(allIndex, 1);
                }

                const index: number = keys.indexOf(key);
                if (option.selected && index < 0) {
                    keys.push(key);
                } else if (!option.selected && index > -1) {
                    keys.splice(index, 1);
                }
            }

            // If user unselected everyting, then default back to all.
            if (keys.length === 0) {
                keys.push(all);
            }

            setFilterCostCategorySubclassKeys(keys);

            const newOptions = [...filterCostCategorySubclassOptions];
            newOptions.forEach(o => {
                o.selected = keys.indexOf(o.key as string) > -1;
            });
            setFilterCostCategorySubclassOptions(newOptions);
        }
    };

    /**
     * Filter corp approval change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFilterCorpApproval = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: ApprovalStatus | string = option.key as ApprovalStatus | string;
            setFilterCorpApproval(
                key === ApprovalStatus.PendingReview ? ApprovalStatus.PendingReview :
                key === ApprovalStatus.NoOrNotApproved ? ApprovalStatus.NoOrNotApproved :
                key === ApprovalStatus.YesOrApproved ? ApprovalStatus.YesOrApproved :
                undefined // If the option key is for 'All' then set to undefined.
            );
        }
    };

    /**
     * Filter STC approval change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFilterStcApproval = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: ApprovalStatus | string = option.key as ApprovalStatus | string;
            setFilterStcApproval(
                key === ApprovalStatus.PendingReview ? ApprovalStatus.PendingReview :
                key === ApprovalStatus.NoOrNotApproved ? ApprovalStatus.NoOrNotApproved :
                key === ApprovalStatus.YesOrApproved ? ApprovalStatus.YesOrApproved :
                undefined // If the option key is for 'All' then set to undefined.
            );
        }
    };

    /**
     * Filter POE/POD availability change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeFilterPoePodAvailability = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const key: ApprovalStatus | string = option.key as ApprovalStatus | string;
            setFilterPoePodAvailability(
                key === ApprovalStatus.PendingReview ? ApprovalStatus.PendingReview :
                key === ApprovalStatus.NoOrNotApproved ? ApprovalStatus.NoOrNotApproved :
                key === ApprovalStatus.YesOrApproved ? ApprovalStatus.YesOrApproved :
                undefined // If the option key is for 'All' then set to undefined.
            );
        }
    };

    /**
     * Display currency change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeDisplayCurrency = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            setDisplayCurrencyKey(option.key as DisplayCurrencyKey);
        }
    };

    /**
     * Grid header check box change event handler.
     * @param event The check box change event.
     * @param checked Checked or not.
     */
    const onChangeGridHeaderCheckbox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        dispatch(toggleAllSelected(checked || false));
    };

    return (
        <>
            {queryStringParamsEvaluated && (
                <PageWrapper {...props}>
                    <SectionWrapper>
                        <Section>
                            {
                                ((isIllegalAccessToElevatedTab) && (
                                    <Text variant="medium">Access denied. This view requires Agent or Finance Controller role.</Text>
                                ))
                            }
                            {
                                ((isIllegalAccessToAdminTab) && (
                                    <Text variant="medium">Access denied. This view requires Accruals Admin role.</Text>
                                ))
                            }
                            {
                                (!isIllegalAccessToElevatedTab && !isIllegalAccessToAdminTab && (
                                    <>
                                        {
                                            // If user has elevated role, then let the user choose between different views.
                                            (hasElevatedAccess.current) && (
                                                <Stack tokens={stackTokensNormalGap}>
                                                    <Stack.Item>
                                                        <Pivot
                                                            headersOnly={true}
                                                            selectedKey={params.activeTab}
                                                            onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
                                                                if (isSearchRunning) {
                                                                    showGenericDialog(
                                                                        'Please wait...',
                                                                        'Please wait while the page completes processing.'
                                                                    );
                                                                } else {
                                                                    // Clear filters when switching views.
                                                                    clearFilters();
                                                                    setUseFilterPoLineOnly(false);

                                                                    // Clear search results when switching views.
                                                                    dispatch(resetApiPriorYearAccrualSearch());

                                                                    // Navigate to the child route using the item key.
                                                                    navigate(`${appConstants.publicUrl}/PriorYearAccrual/${item?.props.itemKey}`);
                                                                }
                                                            }}>
                                                            <PivotItem
                                                                headerText="For You"
                                                                itemKey={PriorYearAccrualPivotItemKey.ForYou as string}
                                                            />
                                                            {(hasElevatedAccess.current) && (
                                                                <PivotItem
                                                                    headerText={elevatedRoleName}
                                                                    itemKey={PriorYearAccrualPivotItemKey.Elevated as string}
                                                                />
                                                            )}
                                                            {userProfile?.isAccrualsAdmin && (
                                                                <PivotItem
                                                                    headerText="Administration"
                                                                    itemKey={PriorYearAccrualPivotItemKey.Administration as string}
                                                                />
                                                            )}
                                                        </Pivot>

                                                        <Separator />

                                                        {/* Position error bar below separator below the pivot. */}
                                                        <ErrorBar errors={errors} onDismiss={(index: number) => {
                                                            setErrors(clearErrorByIndex(errors, index));
                                                        }} />
                                                    </Stack.Item>
                                                </Stack>
                                            )
                                        }

                                        {
                                            // If viewing the ForYou or the Elevated views.
                                            (isForYouTabActive || isElevatedTabActive) && (
                                                <Stack tokens={stackTokensNormalGap}>
                                                    <Stack.Item>
                                                        <Stack horizontal tokens={stackTokensSmallGap}>
                                                            <Stack.Item>
                                                                <FontIcon
                                                                    role='button'
                                                                    tabIndex={0}
                                                                    className={commonStyles.expandCollapseIcon}
                                                                    aria-label={displayCollapsablePageSummary ? 'Collapse PO Line Validation For Prior Year Accruals description' : 'Expand PO Line Validation For Prior Year Accruals description'}
                                                                    iconName={displayCollapsablePageSummary ? 'ChevronDown' : 'ChevronUp'}
                                                                    onKeyDown={(key: React.KeyboardEvent<HTMLElement>) => {
                                                                        if (key.code === 'Enter') {
                                                                            setDisplayCollapsablePageSummary(!displayCollapsablePageSummary);
                                                                        }
                                                                    }}
                                                                    onClick={() => setDisplayCollapsablePageSummary(!displayCollapsablePageSummary)}
                                                                />
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={1}>PO Line Validation For Prior Year Accruals</Text>
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        {displayCollapsablePageSummary && (
                                                            <>
                                                                {isForYouTabActive && (
                                                                    <div>
                                                                        This page displays open and receipted PO lines for prior fiscal years where you are a PO owner or delegate.
                                                                        You need to validate and take one of the following actions.
                                                                        <ul className={pageStyles.pageDescriptionUl}>
                                                                            <li><b>Retain:</b> Use this option if you are expecting any invoices from suppliers.</li>
                                                                            <li><b>Close:</b> Use this option when all invoices are approved and no further invoices are expected.</li>
                                                                        </ul>
                            
                                                                        When taking action for a PO line, please note:
                                                                        <ul className={pageStyles.pageDescriptionUl}>
                                                                            <li>Open invoices cannot be processed and paid for closed PO lines.</li>
                                                                            <li>For invoice approvals, please work with the invoice approver to approve in MS Invoice.</li>
                                                                            <li>Pending payments against approved invoices will not be impacted by closing the line.</li>
                                                                            <li>Close the PO or PO line item if the invoice status is &quot;Ready for Payment&quot; or &quot;Paid&quot;. You may check the status in MS Invoice.</li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                                {isElevatedTabActive && (
                                                                    <>
                                                                        <div>
                                                                            This page displays open PO lines with an open accrual for prior fiscal years.
                                                                            The PO lines shown are based on your {elevatedRoleName} access.
                                                                            You can work with the PO owners or work on their behalf to take one of the following actions.
                                                                            <ul className={pageStyles.pageDescriptionUl}>
                                                                                <li><b>Retain:</b> Use this option if you are expecting any invoices from suppliers.</li>
                                                                                <li><b>Close:</b> Use this option when all invoices are approved and no further invoices are expected.</li>
                                                                            </ul>

                                                                            When taking action for a PO line, please note:
                                                                            <ul className={pageStyles.pageDescriptionUl}>
                                                                                <li>Open invoices cannot be processed and paid for closed PO lines.</li>
                                                                                <li>For invoice approvals, please work with the invoice approver to approve in MS Invoice.</li>
                                                                                <li>Pending payments against approved invoices will not be impacted by closing the line.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <Text>
                                                                    Training resources:
                                                                </Text>
                                                                {isForYouTabActive && (
                                                                    <>
                                                                        <Stack horizontal wrap tokens={stackTokensLargeGap} style={{ marginTop: '4px', marginLeft: '22px' }}>
                                                                            <Stack.Item>
                                                                                <Text>
                                                                                    <FontIcon
                                                                                        iconName='Video'
                                                                                        className={pageStyles.icon}
                                                                                    />&nbsp;
                                                                                    <Link
                                                                                        onClick={() => {
                                                                                            dispatch(showVideoGuide(InitialVideo.Pya));
                                                                                        }}>
                                                                                        Video guide
                                                                                    </Link>
                                                                                </Text>
                                                                            </Stack.Item>
                                                                        </Stack>
                                                                    </>
                                                                )}
                                                                {isElevatedTabActive && (
                                                                    <>
                                                                        <Stack horizontal wrap tokens={stackTokensLargeGap} style={{ marginTop: '4px', marginLeft: '22px' }}>
                                                                            <Stack.Item>
                                                                                <Text>
                                                                                    <FontIcon
                                                                                        iconName='Video'
                                                                                        className={pageStyles.icon}
                                                                                    />&nbsp;
                                                                                    <Link
                                                                                        onClick={() => {
                                                                                            dispatch(showVideoGuide(InitialVideo.PyaElevated));
                                                                                        }}>
                                                                                        Video guide
                                                                                    </Link>
                                                                                </Text>
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <Text>
                                                                                    <FontIcon
                                                                                        iconName='Video'
                                                                                        className={pageStyles.icon}
                                                                                    />&nbsp;
                                                                                    <Link
                                                                                        onClick={() => {
                                                                                            dispatch(showVideoGuide(InitialVideo.PyaElevatedAccountingDetails));
                                                                                        }}>
                                                                                        Video guide for accounting details update
                                                                                    </Link>
                                                                                </Text>
                                                                            </Stack.Item>
                                                                        </Stack>
                                                                    </>
                                                                )}
                                                                <Separator />
                                                            </>
                                                        )}
                                                    </Stack.Item>

                                                    {!isRequiredPageDataLoaded && (
                                                        <Stack.Item>
                                                            <Text variant='mediumPlus'>Loading...</Text>
                                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                        </Stack.Item>
                                                    )}

                                                    {isRequiredPageDataLoaded && (
                                                        <>
                                                            <Stack.Item>
                                                                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                                    <Stack.Item>
                                                                        <Label htmlFor={poPlusOptionalLineNumberInputId}>{commonString.poPlusOptionalLineNumber}</Label>
                                                                        <TooltipHost content={validationConstants.poPlusOptionalLineNumber.tooltip} delay={TooltipDelay.long}>
                                                                            <TextField
                                                                                id={poPlusOptionalLineNumberInputId}
                                                                                autoComplete='off'
                                                                                ariaLabel={`${commonString.poPlusOptionalLineNumber} ${validationConstants.poPlusOptionalLineNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                                                                className={pageStyles.filterPoPlusOptionalLineTextField}
                                                                                onChange={onChangePoPlusOptionalLineNumber}
                                                                                value={filterPoPlusOptionalLineNumber}
                                                                                validateOnLoad={false}
                                                                                validateOnFocusOut={true}
                                                                                maxLength={validationConstants.poPlusOptionalLineNumber.maxLength}
                                                                                onKeyDown={(key: React.KeyboardEvent<HTMLElement>) => {
                                                                                    if (key.code === 'Enter') {
                                                                                        searchButtonClicked();
                                                                                    }
                                                                                }}
                                                                                onGetErrorMessage={(value: string) => {
                                                                                    // Note on validating TextField input: The control will use maxLength to
                                                                                    // enforce data entry less than this length which is why that is used.
                                                                                    // Not using minLength or pattern props. All will be checked in this validateInput
                                                                                    // helper method which will return a string indicating the validation issue.
                                                                                    const msg: string = validateInput(value, validationConstants.poPlusOptionalLineNumber);
                                                                                    if (msg) {
                                                                                        setFilterInputInvalid(x => [...addToArrayIfNotPresent(x, 'poPlusOptionalLineNumberInputId')]);
                                                                                    } else {
                                                                                        setFilterInputInvalid(x => [...removeFromArray(x, 'poPlusOptionalLineNumberInputId')]);
                                                                                    }
                                                                                    return msg;
                                                                                }}

                                                                            />
                                                                        </TooltipHost>
                                                                    </Stack.Item>
                                                                    <Stack.Item>
                                                                        <SupplierInput
                                                                            showLabel={true}
                                                                            label={commonString.supplierNameNumber}
                                                                            disabled={useFilterPoLineOnly}
                                                                            tooltip={validationConstants.supplierNameOrNumber.tooltip}
                                                                            onChange={(supplier: Supplier | undefined) => {
                                                                                if (supplier) {
                                                                                    setFilterSupplierName(supplier.supplierName);
                                                                                    setFilterSupplierNumber(supplier.supplierNumber);
                                                                                } else {
                                                                                    setFilterSupplierName('');
                                                                                    setFilterSupplierNumber('');
                                                                                }
                                                                            }}
                                                                            onInputValidation={(msg: string) => {
                                                                                if (msg) {
                                                                                    setFilterInputInvalid(x => [...addToArrayIfNotPresent(x, 'supplierNameOrNumber')]);
                                                                                } else {
                                                                                    setFilterInputInvalid(x => [...removeFromArray(x, 'supplierNameOrNumber')]);
                                                                                }
                                                                            }}
                                                                            initialSelectedSupplier={filterSupplierNumber}
                                                                            handleError={(error: string) => {
                                                                                handleError(error);
                                                                            }}
                                                                            allowNotFoundSupplierNumber={true}
                                                                            clearInputCounter={clearSupplierInputCounter}
                                                                        />
                                                                    </Stack.Item>
                                                                    <Stack.Item>
                                                                        <CompanyCodeSelection
                                                                            showLabel={true}
                                                                            readonly={false}
                                                                            disabled={useFilterPoLineOnly}
                                                                            tooltip={tooltips.pyaCompanyCodeFilterSelection}
                                                                            selectedCompanyCodes={!filterCompanyCodes || filterCompanyCodes.length === 0 ? [appConstants.allCompanyCodeValue] : filterCompanyCodes}
                                                                            onSelectionChanged={(companyCodes: number[]) => {
                                                                                setFilterCompanyCodes(companyCodes.length === 0 ? [appConstants.allCompanyCodeValue] : companyCodes);
                                                                            }}
                                                                        />
                                                                    </Stack.Item>
                                                                    { // Show the PO Owner input only for Finance Controller view.
                                                                        isElevatedTabActive && (
                                                                            <Stack.Item>
                                                                                <GraphUserInput
                                                                                    showLabel={true}
                                                                                    label={commonString.poOwner}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    required={false}
                                                                                    tooltip={tooltips.poOwner}
                                                                                    onChange={(graphUser: GraphUser | undefined) => {
                                                                                        if (graphUser && graphUser.alias) {
                                                                                            setFilterPoOwner(graphUser.alias);
                                                                                        } else {
                                                                                            setFilterPoOwner('');
                                                                                        }
                                                                                    }}
                                                                                    onInputValidation={(msg: string) => {
                                                                                        if (msg) {
                                                                                            setFilterInputInvalid(x => [...addToArrayIfNotPresent(x, 'userAlias')]);
                                                                                        } else {
                                                                                            setFilterInputInvalid(x => [...removeFromArray(x, 'userAlias')]);
                                                                                        }
                                                                                    }}
                                                                                    disableDoesNotExistError={true}
                                                                                    initialSelectedAlias={filterPoOwner}
                                                                                    clearInputCounter={clearPoOwnerInputCounter}
                                                                                />
                                                                            </Stack.Item>
                                                                        )
                                                                    }
                                                                    <Stack.Item>
                                                                        <Label htmlFor={fiscalYearInputId}>{commonString.fiscalYear}</Label>
                                                                        <ComboBox
                                                                            id={fiscalYearInputId}
                                                                            ariaLabel={commonString.fiscalYear}
                                                                            className={pageStyles.filterFiscalYearComboBox}
                                                                            disabled={useFilterPoLineOnly}
                                                                            multiSelect={true}
                                                                            autoComplete='off' // Turn off autoComplete when multiSelect is true.
                                                                            options={filterFiscalYearOptions}
                                                                            selectedKey={filterFiscalYearKeys}
                                                                            onChange={onChangeFiscalYear}
                                                                            useComboBoxAsMenuWidth={true}
                                                                        />
                                                                    </Stack.Item>
                                                                    { // Show the Delegated By input only for For You view.
                                                                        // And if the user has no delegated by users, then don't show this input.
                                                                        isForYouTabActive && showViewPosFor && (
                                                                            // If the user has no delegated by users, then don't show this input.
                                                                            <Stack.Item>
                                                                                <Label htmlFor={delegatedByInputId}>{commonString.viewPosFor}</Label>
                                                                                <ComboBox
                                                                                    id={delegatedByInputId}
                                                                                    ariaLabel={commonString.viewPosFor}
                                                                                    className={pageStyles.filterDelegatedByComboBox}
                                                                                    // Not disabling this delegate selector if useFilterPoLineOnly.
                                                                                    multiSelect={true}
                                                                                    autoComplete='off' // Turn off autoComplete when multiSelect is true.
                                                                                    options={viewPosForOptions}
                                                                                    selectedKey={filterDelegatedBy}
                                                                                    onChange={onChangeDelegatedBy}
                                                                                    useComboBoxAsMenuWidth={false}
                                                                                />
                                                                            </Stack.Item>
                                                                        )
                                                                    }
                                                                    <Stack.Item>
                                                                        <Label htmlFor={statusInputId}>{commonString.responseStatus}</Label>
                                                                        <ComboBox
                                                                            id={statusInputId}
                                                                            ariaLabel={commonString.responseStatus}
                                                                            className={pageStyles.filterStatusComboBox}
                                                                            disabled={useFilterPoLineOnly}
                                                                            multiSelect={true}
                                                                            autoComplete='off' // Turn off autoComplete when multiSelect is true.
                                                                            options={filterStatusOptions}
                                                                            selectedKey={filterStatusKeys as string[] | number[]}
                                                                            onChange={onChangeFilterStatus}
                                                                            useComboBoxAsMenuWidth={false}
                                                                        />
                                                                    </Stack.Item>
                                                                    {isElevatedTabActive && (
                                                                        <>
                                                                            <Stack.Item>
                                                                                <Label htmlFor={regionInputId}>{commonString.region}</Label>
                                                                                <ComboBox
                                                                                    id={regionInputId}
                                                                                    ariaLabel={commonString.region}
                                                                                    className={pageStyles.filterRegionComboBox}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    multiSelect={true}
                                                                                    autoComplete='off' // Turn off autoComplete when multiSelect is true.
                                                                                    options={filterRegionOptions}
                                                                                    selectedKey={filterRegionKeys}
                                                                                    onChange={onChangeFilterRegion}
                                                                                    useComboBoxAsMenuWidth={false}
                                                                                />
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <HierarchySelection
                                                                                    showLabel={true}
                                                                                    readonly={false}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    tooltip={tooltips.pyaFilterHierarchySelection}
                                                                                    financeGeographySalesDistrictCodes={[]}
                                                                                    channelFunctionDetailCodes={filterChannelFunctionDetailCodes}
                                                                                    executiveFunctionDetailCodes={filterExecutiveFunctionDetailCodes}
                                                                                    showGeography={false}
                                                                                    showWarningIfBothChannelAndExecSelected={false}
                                                                                    onChanged={(
                                                                                        financeGeographySalesDistrictCodes: string[], // Unused
                                                                                        channelFunctionDetailCodes: string[],
                                                                                        executiveFunctionDetailCodes: string[]
                                                                                    ) => {
                                                                                        setFilterChannelFunctionDetailCodes(channelFunctionDetailCodes);
                                                                                        setFilterExecutiveFunctionDetailCodes(executiveFunctionDetailCodes);
                                                                                    }}
                                                                                />
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <Label htmlFor={costCategorySubclassInputId}>{commonString.costCategorySubclass}</Label>
                                                                                <ComboBox
                                                                                    id={costCategorySubclassInputId}
                                                                                    ariaLabel={commonString.costCategorySubclass}
                                                                                    className={pageStyles.filterCostCategorySubclassComboBox}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    multiSelect={true}
                                                                                    autoComplete='off' // Turn off autoComplete when multiSelect is true.
                                                                                    options={filterCostCategorySubclassOptions}
                                                                                    selectedKey={filterCostCategorySubclassKeys}
                                                                                    onChange={onChangeFilterCostCategorySubclass}
                                                                                    useComboBoxAsMenuWidth={false}
                                                                                />
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <Label htmlFor={corpApprovalInputId}>{commonString.corpApproval}</Label>
                                                                                <ComboBox
                                                                                    id={corpApprovalInputId}
                                                                                    ariaLabel={commonString.corpApproval}
                                                                                    className={pageStyles.filterCorpApprovalComboBox}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    options={filterApprovalOptions}
                                                                                    selectedKey={filterCorpApproval !== undefined ? filterCorpApproval : all}
                                                                                    onChange={onChangeFilterCorpApproval}
                                                                                    useComboBoxAsMenuWidth={true}
                                                                                />
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <Label htmlFor={stcApprovalInputId}>{commonString.stcApproval}</Label>
                                                                                <ComboBox
                                                                                    id={stcApprovalInputId}
                                                                                    ariaLabel={commonString.stcApproval}
                                                                                    className={pageStyles.filterStcApprovalComboBox}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    options={filterApprovalOptions}
                                                                                    selectedKey={filterStcApproval !== undefined ? filterStcApproval : all}
                                                                                    onChange={onChangeFilterStcApproval}
                                                                                    useComboBoxAsMenuWidth={true}
                                                                                />
                                                                            </Stack.Item>
                                                                            <Stack.Item>
                                                                                <Label htmlFor={poePodAvailabilityInputId}>{commonString.poePodAvailability}</Label>
                                                                                <ComboBox
                                                                                    id={poePodAvailabilityInputId}
                                                                                    ariaLabel={commonString.poePodAvailability}
                                                                                    className={pageStyles.filterPoePodAvailabilityComboBox}
                                                                                    disabled={useFilterPoLineOnly}
                                                                                    options={filterAvailabilityOptions}
                                                                                    selectedKey={filterPoePodAvailability !== undefined ? filterPoePodAvailability : all}
                                                                                    onChange={onChangeFilterPoePodAvailability}
                                                                                    useComboBoxAsMenuWidth={true}
                                                                                />
                                                                            </Stack.Item>
                                                                        </>
                                                                    )}
                                                                    <Stack.Item>
                                                                        <Label htmlFor={currencyInputId}>{commonString.currencySelection}</Label>
                                                                        <ComboBox
                                                                            id={currencyInputId}
                                                                            ariaLabel={commonString.currencySelection}
                                                                            className={pageStyles.showCurrencyComboBox}
                                                                            // Not disabling this currency if useFilterPoLineOnly. This is not really a filter anyway, it is just used client side to select what columns get displayed.
                                                                            options={displayCurrencyOptions}
                                                                            selectedKey={displayCurrencyKey}
                                                                            onChange={onChangeDisplayCurrency}
                                                                            useComboBoxAsMenuWidth={true}
                                                                        />
                                                                    </Stack.Item>
                                                                </Stack>
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <Stack horizontal tokens={stackTokensNormalGap}>
                                                                    <Stack.Item>
                                                                        <DefaultButton
                                                                            onClick={searchButtonClicked}
                                                                            text='Search'
                                                                            disabled={isSearchRunning || filterInputInvalid.length > 0 || isPageActionRunning || completedMsg.length > 0}
                                                                        />
                                                                    </Stack.Item>
                                                                    <Stack.Item>
                                                                        <DefaultButton
                                                                            onClick={() => {
                                                                                clearFilters();
                                                                                setUseFilterPoLineOnly(false);
                                                                            }}
                                                                            text='Clear'
                                                                            disabled={isSearchRunning}
                                                                        />
                                                                    </Stack.Item>
                                                                </Stack>
                                                            </Stack.Item>
                                                            {useFilterPoLineOnly && (
                                                                <Stack.Item>
                                                                    <Text variant="medium">Note: When searching for a specific PO number, some filters will be cleared and disabled.</Text>
                                                                </Stack.Item>
                                                            )}
                                                            <Stack.Item>
                                                                <>
                                                                    <Separator />
                                                                    <Text variant="mediumPlus" className={commonStyles.sectionHeading} role="heading" aria-level={2}>Search result summary</Text>
                                                                    <div style={{ marginTop: '8px' }}>
                                                                        {isForYouTabActive && (
                                                                            <>
                                                                                { apiPriorYearAccrualUserSummary.callApiState === CallApiState.Running && (
                                                                                    <>
                                                                                        <Text variant='mediumPlus'>Loading...</Text>
                                                                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                                    </>
                                                                                )}
                                                                                { apiPriorYearAccrualUserSummary.callApiState === CallApiState.Failed && (
                                                                                    <LoadFailureDisplay />
                                                                                )}
                                                                                { apiPriorYearAccrualUserSummary.callApiState === CallApiState.Completed && (
                                                                                    <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                                                        <Stack.Item>
                                                                                            <DataTile
                                                                                                dollarSymbol={false}
                                                                                                tileText={commonString.totalOpenLineItems}
                                                                                                tileValue={apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalOpenLineItems || 0}
                                                                                                barColor={summaryDataColors.totalOpen}
                                                                                            />
                                                                                        </Stack.Item>
                                                                                        <Stack.Item>
                                                                                            <DataTile
                                                                                                dollarSymbol={false}
                                                                                                tileText={commonString.totalRetainedLineItems}
                                                                                                tileValue={apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalRetainedLineItems || 0}
                                                                                                barColor={summaryDataColors.totalRetained}
                                                                                            />
                                                                                        </Stack.Item>
                                                                                        <Stack.Item>
                                                                                            <div className={pageStyles.pieChartContainerWrapper}>
                                                                                                <div className={pageStyles.pieChartContainer}>
                                                                                                    <CustomPieChart
                                                                                                        data={[
                                                                                                            {
                                                                                                                name: commonString.totalOpenLineItems,
                                                                                                                value: apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalOpenLineItems || 0,
                                                                                                                color: summaryDataColors.totalOpen,
                                                                                                                payload: { percent: calcPercentage(
                                                                                                                    (apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalOpenLineItems || 0),
                                                                                                                    (apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalOpenLineItems || 0) +
                                                                                                                    (apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalRetainedLineItems || 0)
                                                                                                                ) }
                                                                                                            },
                                                                                                            {
                                                                                                                name: commonString.totalRetainedLineItems,
                                                                                                                value: apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalRetainedLineItems || 0,
                                                                                                                color: summaryDataColors.totalRetained,
                                                                                                                payload: { percent: calcPercentage(
                                                                                                                    (apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalRetainedLineItems || 0),
                                                                                                                    (apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalOpenLineItems || 0) +
                                                                                                                    (apiPriorYearAccrualUserSummary.priorYearAccrualUserSummary?.totalRetainedLineItems || 0)
                                                                                                                ) }
                                                                                                            }
                                                                                                        ]}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Stack.Item>
                                                                                    </Stack>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {isElevatedTabActive && (
                                                                            <>
                                                                                { apiPriorYearAccrualElevatedSummary.callApiState === CallApiState.Running && (
                                                                                    <>
                                                                                        <Text variant='mediumPlus'>Loading...</Text>
                                                                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                                    </>
                                                                                )}
                                                                                { apiPriorYearAccrualElevatedSummary.callApiState === CallApiState.Failed && (
                                                                                    <LoadFailureDisplay />
                                                                                )}
                                                                                { apiPriorYearAccrualElevatedSummary.callApiState === CallApiState.Completed && (
                                                                                    <Stack tokens={stackTokensNormalGap}>
                                                                                        <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={false}
                                                                                                    tileText={commonString.totalLineItems}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalLineItems || 0}
                                                                                                    barColor={summaryDataColors.default}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={false}
                                                                                                    tileText={commonString.totalOpenLineItems}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenLineItems || 0}
                                                                                                    barColor={summaryDataColors.totalOpen}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={false}
                                                                                                    tileText={commonString.totalRetainedLineItems}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalRetainedLineItems || 0}
                                                                                                    barColor={summaryDataColors.totalRetained}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={false}
                                                                                                    tileText={commonString.totalClosedLineItems}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalClosedLineItems || 0}
                                                                                                    barColor={summaryDataColors.totalClosed}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                            <Stack.Item>
                                                                                                <div className={pageStyles.pieChartContainerWrapper}>
                                                                                                    <div className={pageStyles.pieChartContainer}>
                                                                                                        <CustomPieChart
                                                                                                            data={[
                                                                                                                {
                                                                                                                    name: commonString.totalOpenLineItems,
                                                                                                                    value: apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenLineItems || 0,
                                                                                                                    color: summaryDataColors.totalOpen,
                                                                                                                    payload: { percent: calcPercentage(
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenLineItems || 0),
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenLineItems || 0) +
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalRetainedLineItems || 0) +
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalClosedLineItems || 0)
                                                                                                                    ) }
                                                                                                                },
                                                                                                                {
                                                                                                                    name: commonString.totalRetainedLineItems,
                                                                                                                    value: apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalRetainedLineItems || 0,
                                                                                                                    color: summaryDataColors.totalRetained,
                                                                                                                    payload: { percent: calcPercentage(
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalRetainedLineItems || 0),
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenLineItems || 0) +
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalRetainedLineItems || 0) +
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalClosedLineItems || 0)
                                                                                                                    ) }
                                                                                                                },
                                                                                                                {
                                                                                                                    name: commonString.totalClosedLineItems,
                                                                                                                    value: apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalClosedLineItems || 0,
                                                                                                                    color: summaryDataColors.totalClosed,
                                                                                                                    payload: { percent: calcPercentage(
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalClosedLineItems || 0),
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenLineItems || 0) +
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalRetainedLineItems || 0) +
                                                                                                                        (apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalClosedLineItems || 0)
                                                                                                                    ) }
                                                                                                                }
                                                                                                            ]}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Stack.Item>
                                                                                        </Stack>
                                                                                        <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={true}
                                                                                                    tileText={commonString.totalOpenAmountUsd}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenAmount || 0}
                                                                                                    barColor={summaryDataColors.default}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={true}
                                                                                                    tileText={commonString.totalOpenAccrualAmountUsd}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalOpenAccrualAmount || 0}
                                                                                                    barColor={summaryDataColors.default}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                            <Stack.Item>
                                                                                                <DataTile
                                                                                                    dollarSymbol={true}
                                                                                                    tileText={commonString.totalInvoicedAmountUsd}
                                                                                                    tileValue={apiPriorYearAccrualElevatedSummary.priorYearAccrualElevatedSummary?.totalInvoicedAmount || 0}
                                                                                                    barColor={summaryDataColors.default}
                                                                                                />
                                                                                            </Stack.Item>
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <CustomDetailsList
                                                                    id="priorYearAccrualDetailsList"
                                                                    ariaLabelForGrid="Prior year accruals"
                                                                    displayTotalItems={true}
                                                                    showPaginator={
                                                                        // Show paginator only if needed.
                                                                        apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items &&
                                                                        apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.totalItems > currentPageSize ? true : false
                                                                    }
                                                                    showPageSize={true}
                                                                    pageSizes={appConfig.current.settings.priorYearAccrualPageSizes}
                                                                    onPageSizeChange={pageSize => {
                                                                        pageChanged(selectedPage, pageSize);
                                                                    }}
                                                                    selectedPage={selectedPage}
                                                                    onSelectedPageChange={page => {
                                                                        pageChanged(page, currentPageSize);
                                                                    }}
                                                                    totalItemsAtServer={apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.totalItems}
                                                                    items={apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items || []}
                                                                    isLoading={isSearchRunning}
                                                                    compact={false}
                                                                    columns={columns}
                                                                    selectionMode={SelectionMode.none}
                                                                    getKey={(item: PriorYearAccrualLineItem) => item.clientRowKey!}
                                                                    setKey="none"
                                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                                    isHeaderVisible={true}
                                                                    constrainMode={ConstrainMode.unconstrained}
                                                                    onRenderRow={onCustomRenderRow}
                                                                    showExcelExport={true}
                                                                    excelExportMode={ExcelExportMode.External}
                                                                    isExporting={isExportRunning}
                                                                    excelExportButtonClicked={() => performExcelExport()}
                                                                    excelExportButtonText="Export search results"
                                                                    useScrollablePane={true}
                                                                    onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                                                        if (detailsHeaderProps) {
                                                                            return (
                                                                                <Sticky stickyPosition={StickyPositionType.Header}>
                                                                                    <div id="gridHeaderCheckbox"> {/* See style for this div and for sticky columns in stickyDetailsListColumns.css. */}
                                                                                        {/* Note that I am not using the SelectionMode.multiple on the CustomDetailsList
                                                                                            itself - which is the built in row selection feature. Rather I am using my own
                                                                                            checkbox here and positioning it on the top left of the grid. The rows in the
                                                                                            first column also have a checkbox bound to each item isSelected.
                                                                                        */}
                                                                                        {apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items &&
                                                                                        apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items.length > 0 && (
                                                                                            <Checkbox
                                                                                                id={gridHeaderCheckboxInputId}
                                                                                                ariaLabel={commonString.selectAllLineItems}
                                                                                                onChange={onChangeGridHeaderCheckbox}
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                    {defaultRender!({ ...detailsHeaderProps })}
                                                                                </Sticky>
                                                                            );
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            </Stack.Item>
                                                            {!(isPageActionRunning || completedMsg) && (
                                                                <Stack.Item>
                                                                    <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                                        <Stack.Item>
                                                                            <DefaultButton
                                                                                onClick={toggleShowConfirmRetainDialog}
                                                                                text={`Retain ${selectedCount} selected line item${selectedCount > 1 ? 's' : ''}`}
                                                                                disabled={isSearchRunning || isPageActionRunning || selectedCount === 0 || completedMsg.length > 0}
                                                                            />
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            <DefaultButton
                                                                                onClick={toggleShowConfirmCloseDialog}
                                                                                text={`Close ${selectedCount} selected line item${selectedCount > 1 ? 's' : ''}`}
                                                                                disabled={isSearchRunning || isPageActionRunning || selectedCount === 0 || completedMsg.length > 0}
                                                                            />
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            <TooltipHost content={tooltips.pageLink} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                                                                                <DefaultButton
                                                                                    onClick={toggleShowPageLinkDialog}
                                                                                    text={commonString.getPageLink}
                                                                                    ariaLabel={`${commonString.getPageLink} ${tooltips.pageLink}`}
                                                                                    disabled={isSearchRunning || filterInputInvalid.length > 0 || isPageActionRunning || completedMsg.length > 0}
                                                                                />
                                                                            </TooltipHost>
                                                                        </Stack.Item>
                                                                        {userProfile?.isAccrualsAdmin && isElevatedTabActive && (
                                                                            <>
                                                                                <Stack.Item>
                                                                                    <DefaultButton
                                                                                        onClick={toggleShowReminderEmailDialog}
                                                                                        text={`Email PO owners for ${selectedCount} selected line item${selectedCount > 1 ? 's' : ''}`}
                                                                                        disabled={isSearchRunning || isPageActionRunning || selectedCount === 0 || completedMsg.length > 0}
                                                                                    />
                                                                                </Stack.Item>
                                                                                <Stack.Item>
                                                                                    <TooltipHost content={tooltips.pyaRefreshLines} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                                                                                        <DefaultButton
                                                                                            onClick={() => {
                                                                                                const poLines: PurchaseOrderLineItemPair[] = selectedLines.map(x => {
                                                                                                    return new PurchaseOrderLineItemPair(Number(x.purchaseOrder), Number(x.lineItem));
                                                                                                });
                                                                                                dispatch(callApiPriorYearAccrualRefresh(poLines));
                                                                                            }}
                                                                                            text={`Refresh ${selectedCount} selected line item${selectedCount > 1 ? 's' : ''}`}
                                                                                            disabled={isSearchRunning || isPageActionRunning || selectedCount === 0 || completedMsg.length > 0}
                                                                                        />
                                                                                    </TooltipHost>
                                                                                </Stack.Item>
                                                                            </>
                                                                        )}
                                                                        {(userProfile?.isAccrualsAdmin || userProfile?.isFinanceController || userProfile?.isAgent) && isElevatedTabActive && (
                                                                            <Stack.Item>
                                                                                <DefaultButton
                                                                                    split
                                                                                    splitButtonAriaLabel="See options"
                                                                                    aria-roledescription="split button"
                                                                                    menuProps={{
                                                                                        useTargetWidth: true,
                                                                                        items: [
                                                                                            {
                                                                                                key: CorpDetailsUpdateMode.CorpApproval,
                                                                                                text: `${commonString.updateOnly} ${commonString.corpApproval}`,
                                                                                                onClick: () => {
                                                                                                    setCorpDetailsUpdateMode(CorpDetailsUpdateMode.CorpApproval);
                                                                                                    toggleShowCorpDetailsDialog();
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                key: CorpDetailsUpdateMode.StcApproval,
                                                                                                text: `${commonString.updateOnly} ${commonString.stcApproval}`,
                                                                                                onClick: () => {
                                                                                                    setCorpDetailsUpdateMode(CorpDetailsUpdateMode.StcApproval);
                                                                                                    toggleShowCorpDetailsDialog();
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                key: CorpDetailsUpdateMode.PoePodAvailability,
                                                                                                text: `${commonString.updateOnly} ${commonString.poePodAvailability}`,
                                                                                                onClick: () => {
                                                                                                    setCorpDetailsUpdateMode(CorpDetailsUpdateMode.PoePodAvailability);
                                                                                                    toggleShowCorpDetailsDialog();
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                key: CorpDetailsUpdateMode.InvoiceAvailability,
                                                                                                text: `${commonString.updateOnly} ${commonString.invoiceExpectedDate}`,
                                                                                                onClick: () => {
                                                                                                    setCorpDetailsUpdateMode(CorpDetailsUpdateMode.InvoiceAvailability);
                                                                                                    toggleShowCorpDetailsDialog();
                                                                                                }
                                                                                            }
                                                                                        ]
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setCorpDetailsUpdateMode(CorpDetailsUpdateMode.All);
                                                                                        toggleShowCorpDetailsDialog();
                                                                                    }}
                                                                                    text={`Update accounting details for ${selectedCount} selected line item${selectedCount > 1 ? 's' : ''}`}
                                                                                    disabled={isSearchRunning || isPageActionRunning || selectedCount === 0 || completedMsg.length > 0}
                                                                                />
                                                                            </Stack.Item>
                                                                        )}
                                                                        {(isElevatedTabActive ? appConfig.current.featureFlighting.exportImportAccountingDetails : false) && (
                                                                            <>
                                                                                <Stack.Item>
                                                                                    <DefaultButton
                                                                                        onClick={performExcelExportCorpDetails}
                                                                                        disabled={isSearchRunning || isPageActionRunning || completedMsg.length > 0}
                                                                                    >
                                                                                        <FontIcon iconName="ExcelDocument" className={pageStyles.excelIcon} />
                                                                                        <Text>
                                                                                            {!isExportCorpDetailsRunning && (
                                                                                                <span className={pageStyles.buttonText}>Export accounting details</span>
                                                                                            )}
                                                                                            {isExportCorpDetailsRunning && (
                                                                                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                                            )}
                                                                                        </Text>
                                                                                    </DefaultButton>
                                                                                </Stack.Item>
                                                                                <Stack.Item>
                                                                                    <DefaultButton
                                                                                        onClick={performExcelImportCorpDetails}
                                                                                        disabled={isSearchRunning || isPageActionRunning || completedMsg.length > 0}
                                                                                    >
                                                                                        <FontIcon iconName="ExcelDocument" className={pageStyles.excelIcon} />
                                                                                        <Text>
                                                                                            {!isImportCorpDetailsRunning && (
                                                                                                <span className={pageStyles.buttonText}>Import accounting details</span>
                                                                                            )}
                                                                                            {isImportCorpDetailsRunning && (
                                                                                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                                            )}
                                                                                        </Text>
                                                                                    </DefaultButton>
                                                                                </Stack.Item>
                                                                            </>
                                                                        )}
                                                                    </Stack>
                                                                </Stack.Item>
                                                            )}
                                                            {(isPageActionRunning || completedMsg) && (
                                                                <Stack.Item>
                                                                    {(isPageActionRunning) && (
                                                                        <>
                                                                            <Text>Processing request... </Text>
                                                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                        </>
                                                                    )}
                                                                    {!(isPageActionRunning) && completedMsg && (
                                                                        // If any save is not running, and completed msg exists, then display the message.
                                                                        <Text>{completedMsg}</Text>
                                                                    )}
                                                                </Stack.Item>
                                                            )}
                                                        </>
                                                    )}
                                                </Stack>
                                            )
                                        }

                                        {
                                            // If viewing the Administration view.
                                            (params.activeTab === PriorYearAccrualPivotItemKey.Administration) && (
                                                <AdminTab
                                                    handleError={(error: string) => {
                                                        handleError(error);
                                                    }}
                                                />
                                            )
                                        }
                                    </>
                                ))
                            }
                        </Section>
                    </SectionWrapper>

                </PageWrapper>
            )}

            <PyaLineItemDetailsPanel />
            <LineItemAuditPanel />

            <GetPageLinkDialog
                showDialog={showPageLinkDialog}
                displayCurrencyKey={displayCurrencyKey}
                filterCompanyCodes={filterCompanyCodes}
                filterDelegatedBy={filterDelegatedBy}
                filterFiscalYearKeys={filterFiscalYearKeys.indexOf(all) > -1 ? undefined : filterFiscalYearKeys}
                filterIncludeOwnPo={filterIncludeOwnPo}
                filterStatusKeys={filterStatusKeys.indexOf(all) > -1 ? undefined : filterStatusKeys as PriorYearAccrualStatus[]}
                filterPoOwner={filterPoOwner}
                filterPoPlusOptionalLineNumber={filterPoPlusOptionalLineNumber}
                filterSupplierNumber={filterSupplierNumber}
                filterRegionKeys={filterRegionKeys.indexOf(all) > -1 ? undefined : filterRegionKeys}
                filterChannelFunctionDetailCodes={filterChannelFunctionDetailCodes}
                filterExecutiveFunctionDetailCodes={filterExecutiveFunctionDetailCodes}
                filterCostCategorySubclassKeys={filterCostCategorySubclassKeys}
                filterCorpApproval={filterCorpApproval}
                filterStcApproval={filterStcApproval}
                filterPoePodAvailability={filterPoePodAvailability}
                onCloseClicked={() => {
                    toggleShowPageLinkDialog();
                }}
            />

            <ConfirmRetainDialog
                showDialog={showConfirmRetainDialog}
                priorYearAccrualLineItems={selectedLines}
                onYesClicked={(poLines: PurchaseOrderLineItemPair[]) => {
                    dispatch(callApiPriorYearAccrualRetain(poLines));
                    toggleShowConfirmRetainDialog();
                }}
                onNoClicked={() => {
                    toggleShowConfirmRetainDialog();
                }}
            />

            <ConfirmCloseDialog
                showDialog={showConfirmCloseDialog}
                priorYearAccrualLineItems={selectedLines}
                onYesClicked={(poLines: PurchaseOrderLineClose[]) => {
                    dispatch(callApiClosePoLines(poLines));
                    toggleShowConfirmCloseDialog();
                }}
                onNoClicked={() => {
                    toggleShowConfirmCloseDialog();
                }}
            />

            <SendReminderEmailDialog
                showDialog={showReminderEmailDialog}
                priorYearAccrualLineItems={selectedLines}
                onSendClicked={(purchaseOrderOwners: string[] | undefined) => {
                    if (purchaseOrderOwners) {
                        dispatch(callApiTriggerPriorYearAccrualManualNotification(purchaseOrderOwners));
                    }
                    toggleShowReminderEmailDialog();
                }}
                onCancelClicked={() => {
                    toggleShowReminderEmailDialog();
                }}
                handleError={(error: string) => {
                    handleError(error);
                }}
            />

            <CorpDetailsDialog
                showDialog={showCorpDetailsDialog}
                priorYearAccrualLineItems={selectedLines}
                onUpdateClicked={
                    (
                        poLines: PurchaseOrderLineItemPair[],
                        corpDetails: CorpDetails
                    ) => {
                        dispatch(callApiUpdateAccountingDetails(poLines, corpDetails)); 
                        toggleShowCorpDetailsDialog();
                    }
                }
                onCancelClicked={() => {
                    toggleShowCorpDetailsDialog();
                }}
                updateMode={corpDetailsUpdateMode}
            />

            <GenericDialog
                displayDialog={displayGenericDialog}
                title={genericDialogTitle}
                content={
                    <>
                        <Text block variant="medium">{genericDialogMsg}</Text>
                        {genericDialogCustomContent}
                    </>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayGenericDialog();
                    if (callbackAfterGenericDialogCloseRef.current) {
                        callbackAfterGenericDialogCloseRef.current();
                    }
                }}
            />

            {/* Used with Excel import for corp details. */}
            <input
                ref={importCorpDetailsFileInputRef}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                title='Import accounting details'
                className={pageStyles.hiddenInput}
                onInput={onImportCorpDetailsInput}
            />
        </>
    );
};
