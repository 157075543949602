import React, { useState } from 'react';
import { DefaultButton, FontIcon, Spinner, SpinnerSize, TooltipDelay, TooltipHost } from '@fluentui/react';
import { pageStyles } from './ShipmentAdminPage.styles';
import { commonStyles } from '../../common/common.styles';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { shipmentApiClient } from '../../services/api/shipmentApiClient';
import { ShipmentFileInfo } from '../../models/shipment/shipmentFileInfo';

interface IComponentProps {
    shipmentFileInfo: ShipmentFileInfo;
}

export const DownloadButton: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    /**
     * Download button clicked event handler.
     */
    const downloadButtonClicked = async () => {
        telemetryService.trackEvent({ name: trackedEvent.downloadDailyReportButtonClicked });

        setIsDownloading(true);

        try {
            await shipmentApiClient.downloadShipmentDetails(props.shipmentFileInfo.filePath);
        } catch (err: any) {
            setError('Failed');
        }

        setIsDownloading(false);
    }

    return (
        <TooltipHost content={`Download ${props.shipmentFileInfo.fileName}`} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
            <DefaultButton
                className={pageStyles.downloadButton}
                onClick={downloadButtonClicked}
                disabled={isDownloading || error !== ''}
                ariaLabel='Download file'
            >
                {!error && isDownloading && (
                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                )}
                {!error && !isDownloading && (
                    <>
                        <FontIcon iconName="Download" className={pageStyles.fontIcon} />
                        <span>Download</span>
                    </>
                )}
                {error && (
                    <>
                        <FontIcon iconName="Error" className={pageStyles.fontIcon} />
                        <span>Failed</span>
                    </>
                )}
            </DefaultButton>
        </TooltipHost>
    );
};
