import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { Pivot, PivotItem, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { AccessRequestTab } from './AccessRequestTab';
import { ApprovalTab } from './ApprovalTab';
import { callApiLoadCompanies, IApiLoadCompanies, teachingBubbleClearArray } from '../../store/actions/app.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { AppDispatch } from '../../store/reduxStore';
import { CallApiState } from '../../store/actions/generic.action';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { AccessRequestPivotItemKey } from './AccessRequestPivotItemKey';
import { appConstants } from '../../common/appConstants';

interface IPageProps extends ICommonPageProps {
}

export const AccessRequestPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedPivotItemKey, setSelectedPivotItemKey] = useState<AccessRequestPivotItemKey>(AccessRequestPivotItemKey.Requests);

    const params = useParams();
    const navigate = useNavigate();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadCompanies: IApiLoadCompanies = 
        useAppSelector<IApiLoadCompanies>(state => state.appReducer.apiLoadCompanies);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Effect for when the active tab changes via route params.
     */
    useEffect(() => {
        if (params.activeTab?.toLowerCase() === AccessRequestPivotItemKey.Approvals.toLowerCase()) {
            setSelectedPivotItemKey(AccessRequestPivotItemKey.Approvals);
        } else {
            setSelectedPivotItemKey(AccessRequestPivotItemKey.Requests);
        }
    }, [params.activeTab]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadCompanies.errMsg) {
            handleError(apiLoadCompanies.errMsg);
        }
    }, [apiLoadCompanies.errMsg, handleError]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // If the api call to load companies has already been called, then no need to call it again.
        // If not yet called, then call it now.
        if (apiLoadCompanies.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadCompanies());
        }
    });

    /**
     * Memoized value to determine if data is loading.
     * @returns True if data is loading, otherwise false.
     */
    const isLoading = useMemo<boolean>((): boolean => {
        if (apiLoadCompanies.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiLoadCompanies.callApiState]);

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <SectionWrapper>
                {isLoading && (
                    <Section>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Section>
                )}
                {!isLoading && (
                    <Section>
                        <Stack tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Pivot
                                    aria-label="Access Request"
                                    selectedKey={selectedPivotItemKey}
                                    onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
                                        if (item?.props.itemKey) {
                                            // Navigate to the child route using the item key.
                                            navigate(`${appConstants.publicUrl}/AccessRequest/${item?.props.itemKey}`);
                                        }
                                    }}>
                                    <PivotItem headerText="Access Requests" itemKey={AccessRequestPivotItemKey.Requests as string}>
                                        <AccessRequestTab />
                                    </PivotItem>
                                    <PivotItem headerText="Approvals" itemKey={AccessRequestPivotItemKey.Approvals as string}>
                                        <ApprovalTab />
                                    </PivotItem>
                                </Pivot>
                            </Stack.Item>
                        </Stack>
                    </Section>
                )}
            </SectionWrapper>
        </PageWrapper>
    );
};
