/**
 * Enumeration of receipt status types.
 */
export enum ReceiptStatusType {
    ErroredOut = 0, // Errored out after submission.
    Submitted = 1, // Successfully submitted.
    InProgress = 2, // In progress for submission.
    Reversed = 3, // Reversed.
    NotFound = 4 // Not found.
}
