import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DefaultButton, Label, Stack, Text, TooltipDelay, TooltipHost } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { commonString } from '../../common/commonString';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { CallApiState } from '../../store/actions/generic.action';
import { ICustomCoherenceTreeItem } from './ICustomCoherenceTreeItem';
import { HierarchyManager } from './HierarchyManager';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { callApiLoadHierarchy, clearHierarchyStateInstance, IApiLoadHierarchy, storeChannelFunctionHierarchyTreeItemStates, storeExecutiveFunctionHierarchyTreeItemStates, storeFinanceGeographyHierarchyTreeItemStates } from '../../store/actions/app.action';
import { IHierarchyTreeItemState } from './IHierarchyTreeItemState';
import { HierarchyTreeType } from './HierarchyTreeType';
import { HierarchySelectionDialog } from './HierarchySelectionDialog';
import { componentStyles } from './HierarchySelection.styles';
import { getHierarchyCodes, getHierarchyLeafNodeCount, getHierarchySelectedCount } from './hierarchyUtil';
import { stackTokensSmallGap } from '../../common/common.styles';
import { createGuid } from '../../common/common.func.guid';
import { IHierarchyState } from '../../store/reducers/app.reducer';
import { TreeStateNode } from '../../models/hierarchy/treeState/treeStateNode';
import { TreeStateSelection } from '../../models/hierarchy/treeState/treeStateSelection';

export interface IHierarchySelectionProps {
    showLabel: boolean;
    readonly: boolean;
    disabled: boolean;
    tooltip: string;

    // Below string arrays are for the currently selected codes.
    financeGeographySalesDistrictCodes: string[];
    channelFunctionDetailCodes: string[];
    executiveFunctionDetailCodes: string[];

    showGeography: boolean; // Optionally hide the geography selection if not needed.
    showWarningIfBothChannelAndExecSelected: boolean; // Optionally show a warning if nodes from both channel and exec are selected. Not all use cases need this.

    onChanged?: (
        // The following contains flat arrays of codes for child level selected nodes.
        financeGeographySalesDistrictCodes: string[],
        channelFunctionDetailCodes: string[],
        executiveFunctionDetailCodes: string[],
        // The following contain the tree state for each tree. This will contain nodes only for selected or partially selected nodes.
        // It is only needed for certain cases. Ignore if not needed.
        financeGeographyTreeStateNode: TreeStateNode[],
        channelFunctionTreeStateNode: TreeStateNode[],
        executiveFunctionTreeStateNode: TreeStateNode[]
    ) => void;
}

/**
 * Hierarchy selection.
 * @param props Hierarchy selection props.
 * @returns JSX for the component.
 */
export const HierarchySelection: React.FunctionComponent<IHierarchySelectionProps> = (props: IHierarchySelectionProps): JSX.Element => {
    const hierarchyButtonId: string = useId();
    const [financeGeographyCustomCoherenceTreeItems, setFinanceGeographyCustomCoherenceTreeItems] = useState<ICustomCoherenceTreeItem[]>([]);
    const [channelFunctionCustomCoherenceTreeItems, setChannelFunctionCustomCoherenceTreeItems] = useState<ICustomCoherenceTreeItem[]>([]);
    const [executiveFunctionCustomCoherenceTreeItems, setExecutiveFunctionCustomCoherenceTreeItems] = useState<ICustomCoherenceTreeItem[]>([]);
    const [displayDialog, { toggle: toggleDisplayDialog }] = useBoolean(false);

    // This instanceId is used to handle when multiple instances of this HierarchySelection are used on the page at the same time.
    // It is used to store and retrieve into Redux state for this instance of the component.
    // There is an instance and copy (the copy having the same guid but with _COPY appended). These are used as keys in the state
    // object. The copy is used with the dialog while editing, so that if cancel is clicked the changes can be reverted.
    const instanceId = useRef<string>(createGuid());
    const instanceIdCopy = useRef<string>(`${instanceId.current}_COPY`);

    // Redux store selectors to get state from the store when it changes.
    const apiLoadHierarchy: IApiLoadHierarchy = 
        useAppSelector<IApiLoadHierarchy>(state => state.appReducer!.apiLoadHierarchy);
    const hierarchyStateInstances: IKeyValuePair<IHierarchyState> = 
        useAppSelector<IKeyValuePair<IHierarchyState>>(state => state.appReducer!.hierarchyStateInstances);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    // Hierarchy manager ref objects - one for each of Finance Geography, Channel Function, Executive Function.
    // Use the instanceIdCopy for each.
    const financeGeographyHierarchyManager = useRef<HierarchyManager>(
        new HierarchyManager(
            instanceIdCopy.current,
            HierarchyTreeType.FinanceGeography,
            (hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>) => {
                // When tree item states change, this callback will be called with all the tree item states for all nodes.
                // Store this data in the Redux store. It will be used by the HierarchyTreeNodeIcon control to get the
                // state used for the checkbox.
                // Make changes in the instance copy (instanceIdCopy).
                dispatch(storeFinanceGeographyHierarchyTreeItemStates(instanceIdCopy.current, hierarchyTreeItemStates));
            }
        )
    );
    const channelFunctionHierarchyManager = useRef<HierarchyManager>(
        new HierarchyManager(
            instanceIdCopy.current,
            HierarchyTreeType.ChannelFunction,
            (hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>) => {
                dispatch(storeChannelFunctionHierarchyTreeItemStates(instanceIdCopy.current, hierarchyTreeItemStates));
            }
        )
    );
    const executiveFunctionHierarchyManager = useRef<HierarchyManager>(
        new HierarchyManager(
            instanceIdCopy.current,
            HierarchyTreeType.ExecutiveFunction,
            (hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>) => {
                dispatch(storeExecutiveFunctionHierarchyTreeItemStates(instanceIdCopy.current, hierarchyTreeItemStates));
            }
        )
    );

    /**
     * This effect is run once during component load.
     */
    useMountEffect(() => {
        // If the api call to load hierarchy has already been called, then no need to call it again.
        // If not yet called then call it now.
        if (apiLoadHierarchy.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadHierarchy());
        }
    });

    /**
     * Cleanup effect. Clear state for this instance.
     */
    useEffect(() => {
        const instId: string = instanceId.current;
        const instIdCopy: string = instanceIdCopy.current;
        return () => {
            dispatch(clearHierarchyStateInstance(instId));
            dispatch(clearHierarchyStateInstance(instIdCopy));
        };
    }, [dispatch]);

    /**
     * Effect for when hierarchy loads.
     */
    useEffect(() => {
        if (apiLoadHierarchy.callApiState === CallApiState.Completed && apiLoadHierarchy.hierarchy) {
            // Build hierarchy for Finance Geography.
            financeGeographyHierarchyManager.current.buildHierarchy(
                apiLoadHierarchy.hierarchy.financeGeography.areaSummary,
                props.financeGeographySalesDistrictCodes,
                props.readonly
            );
            setFinanceGeographyCustomCoherenceTreeItems(financeGeographyHierarchyManager.current.customCoherenceTreeItems);
            // Store the state in both the instance and the copy.
            dispatch(storeFinanceGeographyHierarchyTreeItemStates(instanceId.current, financeGeographyHierarchyManager.current.hierarchyTreeItemStates));
            dispatch(storeFinanceGeographyHierarchyTreeItemStates(instanceIdCopy.current, financeGeographyHierarchyManager.current.hierarchyTreeItemStates));

            // Build hierarchy for Channel Function.
            channelFunctionHierarchyManager.current.buildHierarchy(
                apiLoadHierarchy.hierarchy.channelFunction.orgSummary,
                props.channelFunctionDetailCodes,
                props.readonly
            );
            setChannelFunctionCustomCoherenceTreeItems(channelFunctionHierarchyManager.current.customCoherenceTreeItems);
            // Store the state in both the instance and the copy.
            dispatch(storeChannelFunctionHierarchyTreeItemStates(instanceId.current, channelFunctionHierarchyManager.current.hierarchyTreeItemStates));
            dispatch(storeChannelFunctionHierarchyTreeItemStates(instanceIdCopy.current, channelFunctionHierarchyManager.current.hierarchyTreeItemStates));

            // Build hierarchy for Executive Function.
            executiveFunctionHierarchyManager.current.buildHierarchy(
                apiLoadHierarchy.hierarchy.executiveFunction.execOrgSummary,
                props.executiveFunctionDetailCodes,
                props.readonly
            );
            setExecutiveFunctionCustomCoherenceTreeItems(executiveFunctionHierarchyManager.current.customCoherenceTreeItems);
            // Store the state in both the instance and the copy.
            dispatch(storeExecutiveFunctionHierarchyTreeItemStates(instanceId.current, executiveFunctionHierarchyManager.current.hierarchyTreeItemStates));
            dispatch(storeExecutiveFunctionHierarchyTreeItemStates(instanceIdCopy.current, executiveFunctionHierarchyManager.current.hierarchyTreeItemStates));
        }
    }, [apiLoadHierarchy.callApiState, apiLoadHierarchy.hierarchy, dispatch, props.channelFunctionDetailCodes, props.executiveFunctionDetailCodes, props.financeGeographySalesDistrictCodes, props.readonly]);

    /**
     * Memoized count of selected finance geography nodes.
     */
    const financeGeographySelectedCount = useMemo<number>(() => {
        // Use the instanceId, not instanceIdCopy.
        const financeGeographyHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateInstances[instanceId.current]?.financeGeographyHierarchyTreeItemStates;
        if (financeGeographyHierarchyTreeItemStates) {
            return getHierarchySelectedCount(financeGeographyHierarchyTreeItemStates);
        }
        return 0;
    }, [hierarchyStateInstances]);

    /**
     * Memoized count of selected channel function nodes.
     */
    const channelFunctionSelectedCount = useMemo<number>(() => {
        // Use the instanceId, not instanceIdCopy.
        const channelFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateInstances[instanceId.current]?.channelFunctionHierarchyTreeItemStates;
        if (channelFunctionHierarchyTreeItemStates) {
            return getHierarchySelectedCount(channelFunctionHierarchyTreeItemStates);
        }
        return 0;
    }, [hierarchyStateInstances]);

    /**
     * Memoized count of selected executive function nodes.
     */
    const executiveFunctionSelectedCount = useMemo<number>(() => {
        // Use the instanceId, not instanceIdCopy.
        const executiveFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateInstances[instanceId.current]?.executiveFunctionHierarchyTreeItemStates;
        if (executiveFunctionHierarchyTreeItemStates) {
            return getHierarchySelectedCount(executiveFunctionHierarchyTreeItemStates);
        }
        return 0;
    }, [hierarchyStateInstances]);

    /**
     * Memoized count of finance geography leaf nodes.
     */
    const financeGeographyLeafNodeCount = useMemo<number>(() => {
        return getHierarchyLeafNodeCount(apiLoadHierarchy.hierarchy?.financeGeography.areaSummary);
    }, [apiLoadHierarchy.hierarchy?.financeGeography.areaSummary]);

    /**
     * Memoized count of channel function leaf nodes.
     */
    const channelFunctionLeafNodeCount = useMemo<number>(() => {
        return getHierarchyLeafNodeCount(apiLoadHierarchy.hierarchy?.channelFunction.orgSummary)
    }, [apiLoadHierarchy.hierarchy?.channelFunction.orgSummary]);

    /**
     * Memoized count of executive function leaf nodes.
     */
    const executiveFunctionLeafNodeCount = useMemo<number>(() => {
        return getHierarchyLeafNodeCount(apiLoadHierarchy.hierarchy?.executiveFunction.execOrgSummary);
    }, [apiLoadHierarchy.hierarchy?.executiveFunction.execOrgSummary]);

    /**
     * Build tree state nodes for the custom coherence tree items.
     */
    const buildTreeStateNodes = (customCoherenceTreeItems: ICustomCoherenceTreeItem[]): TreeStateNode[] => {
        const treeStateNodes: TreeStateNode[] = [];

        const determineSelectionState = (customCoherenceTreeItem: ICustomCoherenceTreeItem): TreeStateSelection => {
            // If a node has all leaf most nodes checked, then isIndeterminate is false and isChecked is true.
            if (customCoherenceTreeItem.isIndeterminate === false && customCoherenceTreeItem.isChecked === true) {
                return TreeStateSelection.Selected;
            }
            // If a node has no leaf most nodes checked, then isIndeterminate is false and isChecked is false.
            if (customCoherenceTreeItem.isIndeterminate === false && customCoherenceTreeItem.isChecked === false) {
                return TreeStateSelection.Unselected;
            }
            // If a node has partial leaf most nodes checked, then isIndeterminate is true and isChecked is false.
            if (customCoherenceTreeItem.isIndeterminate === true && customCoherenceTreeItem.isChecked === false) {
                return TreeStateSelection.PartiallySelected;
            }
            return TreeStateSelection.Unselected;
        };

        customCoherenceTreeItems.forEach(customCoherenceTreeItem => {
            const treeSelectionState = determineSelectionState(customCoherenceTreeItem);
            // Only build out state for selected and partially selected nodes. Ignore all fully unselected nodes.
            if (treeSelectionState === TreeStateSelection.Selected || treeSelectionState === TreeStateSelection.PartiallySelected) {
                const treeStateNode: TreeStateNode = new TreeStateNode(
                    customCoherenceTreeItem.hierarchyNode.typeName || 'unknown',
                    customCoherenceTreeItem.hierarchyNode.description,
                    customCoherenceTreeItem.hierarchyNode.code,
                    treeSelectionState,
                    customCoherenceTreeItem.children && customCoherenceTreeItem.children.length > 0 ?
                        buildTreeStateNodes(customCoherenceTreeItem.children as ICustomCoherenceTreeItem[]) : undefined
                );
                treeStateNodes.push(treeStateNode);
            }
        });

        return treeStateNodes;
    }

    return (
        <>
            <Stack horizontal tokens={stackTokensSmallGap}>
                <Stack.Item align='center'>
                    {props.showLabel && (
                        <Label htmlFor={hierarchyButtonId}>{commonString.hierarchy}</Label>
                    )}
                    <TooltipHost content={props.tooltip} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                        <DefaultButton
                            id={hierarchyButtonId}
                            onClick={() => toggleDisplayDialog()}
                            text={props.readonly ? 'View' : 'Select'}
                            ariaLabel={`${props.readonly ? 'View' : 'Select'} hierarchy. ${props.tooltip}`} // Use both the text and the tooltip content for the aria label used by the screen reader.
                            disabled={props.disabled}
                        />
                    </TooltipHost>
                </Stack.Item>
                {(financeGeographySelectedCount > 0 || channelFunctionSelectedCount > 0 || executiveFunctionSelectedCount > 0) && (
                    // Only show this count display if there were items selected.
                    <Stack.Item align='end'>
                        <table className={componentStyles.summaryTable}>
                            <tbody>
                                {props.showGeography && (
                                    <tr>
                                        <td>
                                            <Text variant="small">Geography:</Text>
                                        </td>
                                        <td>
                                            <Text variant="small">{financeGeographySelectedCount} of {financeGeographyLeafNodeCount}</Text>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        <Text variant="small">Channel:</Text>
                                    </td>
                                    <td>
                                        <Text variant="small">{channelFunctionSelectedCount} of {channelFunctionLeafNodeCount}</Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Text variant="small">Executive:</Text>
                                    </td>
                                    <td>
                                        <Text variant="small">{executiveFunctionSelectedCount} of {executiveFunctionLeafNodeCount}</Text>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Stack.Item>
                )}
                {financeGeographySelectedCount === 0 && channelFunctionSelectedCount === 0 && executiveFunctionSelectedCount === 0 && (
                    // If nothing selected then display this message.
                    <Stack.Item align='end'>
                        <Text variant="small" block>No hierarchy</Text>
                        <Text variant="small" block>selected</Text>
                    </Stack.Item>
                )}
            </Stack>

            {
                // The dialog is displayed using the instanceIdCopy. This means any editing is done using the copy of the instance.
                // When the Ok or Cancel buttons are clicked in the dialog, the copy will be stored as the instance, or vice versa.
            }
            <HierarchySelectionDialog
                instanceId={instanceIdCopy.current}
                readonly={props.readonly}
                displayDialog={displayDialog}
                financeGeographyCustomCoherenceTreeItems={financeGeographyCustomCoherenceTreeItems}
                channelFunctionCustomCoherenceTreeItems={channelFunctionCustomCoherenceTreeItems}
                executiveFunctionCustomCoherenceTreeItems={executiveFunctionCustomCoherenceTreeItems}
                showGeography={props.showGeography}
                showWarningIfBothChannelAndExecSelected={props.showWarningIfBothChannelAndExecSelected}
                onClear={() => {
                    financeGeographyHierarchyManager.current.clearSelections();
                    channelFunctionHierarchyManager.current.clearSelections();
                    executiveFunctionHierarchyManager.current.clearSelections();
                }}
                onCancel={() => {
                    toggleDisplayDialog();

                    // Get the actual instance state (not the copy).
                    const hierarchyState: IHierarchyState | undefined = hierarchyStateInstances[instanceId.current];
                    if (hierarchyState) {
                        const financeGeographyHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyState.financeGeographyHierarchyTreeItemStates;
                        const channelFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyState.channelFunctionHierarchyTreeItemStates;
                        const executiveFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyState.executiveFunctionHierarchyTreeItemStates;

                        // Store the actual instance states into the copied state.
                        dispatch(storeFinanceGeographyHierarchyTreeItemStates(instanceIdCopy.current, financeGeographyHierarchyTreeItemStates));
                        dispatch(storeChannelFunctionHierarchyTreeItemStates(instanceIdCopy.current, channelFunctionHierarchyTreeItemStates));
                        dispatch(storeExecutiveFunctionHierarchyTreeItemStates(instanceIdCopy.current, executiveFunctionHierarchyTreeItemStates));
                    }
                }}
                onOk={() => {
                    toggleDisplayDialog();

                    let financeGeographySalesDistrictCodes: string[] = [];
                    let channelFunctionDetailCodes: string[] = [];
                    let executiveFunctionDetailCodes: string[] = [];

                    let financeGeographyTreeStateNode: TreeStateNode[] = [];
                    let channelFunctionTreeStateNode: TreeStateNode[] = [];
                    let executiveFunctionTreeStateNode: TreeStateNode[] = [];

                    const hierarchyStateCopy: IHierarchyState | undefined = hierarchyStateInstances[instanceIdCopy.current];
                    if (hierarchyStateCopy) {
                        const financeGeographyHierarchyTreeItemStatesCopy: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateCopy.financeGeographyHierarchyTreeItemStates;
                        const channelFunctionHierarchyTreeItemStatesCopy: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateCopy.channelFunctionHierarchyTreeItemStates;
                        const executiveFunctionHierarchyTreeItemStatesCopy: IKeyValuePair<IHierarchyTreeItemState> | undefined = hierarchyStateCopy.executiveFunctionHierarchyTreeItemStates;

                        // Store the copied instance states into the actual state.
                        dispatch(storeFinanceGeographyHierarchyTreeItemStates(instanceId.current, financeGeographyHierarchyTreeItemStatesCopy));
                        dispatch(storeChannelFunctionHierarchyTreeItemStates(instanceId.current, channelFunctionHierarchyTreeItemStatesCopy));
                        dispatch(storeExecutiveFunctionHierarchyTreeItemStates(instanceId.current, executiveFunctionHierarchyTreeItemStatesCopy));
                        
                        // Get the hierarchy codes to be returned in the onChanged callback.
                        financeGeographySalesDistrictCodes = getHierarchyCodes(financeGeographyHierarchyTreeItemStatesCopy);
                        channelFunctionDetailCodes = getHierarchyCodes(channelFunctionHierarchyTreeItemStatesCopy);
                        executiveFunctionDetailCodes = getHierarchyCodes(executiveFunctionHierarchyTreeItemStatesCopy);

                        // Get the tree selection state for selected nodes in the tree. The parent nodes selection, either full check or
                        // partial check is inferred from the selected children level nodes when the tree is built from the original
                        // incoming codes (geo, channel, exec). When the tree is rendered, users can pick higher level nodes and all
                        // children under it will be unselected or selected. The tree selection state, which will include only partial
                        // or full selected nodes (not unselected nodes), will be built below. This is passed to the api to save the
                        // tree selection state. This data is used on the server side in a function app which is used to automatically
                        // select tree nodes when new hierarchy nodes (at all levels) gets synced in from the source system. That is,
                        // if a node at any level is fully selected, then a child node syncs in later, the child node will be automatically
                        // selected and all its children will automatically be selected. This is to prevent a problem where if a parent
                        // node is fully selected now (and all its children), only to have a new child node sync in later, which would now
                        // cause the parent node to only be partially selected.
                        financeGeographyTreeStateNode = buildTreeStateNodes(financeGeographyCustomCoherenceTreeItems);
                        channelFunctionTreeStateNode = buildTreeStateNodes(channelFunctionCustomCoherenceTreeItems);
                        executiveFunctionTreeStateNode = buildTreeStateNodes(executiveFunctionCustomCoherenceTreeItems);
                    }

                    if (props.onChanged) {
                        props.onChanged(
                            financeGeographySalesDistrictCodes, channelFunctionDetailCodes, executiveFunctionDetailCodes,
                            financeGeographyTreeStateNode, channelFunctionTreeStateNode, executiveFunctionTreeStateNode
                        );
                    }
                }}
            />
        </>
    );
};
