import { isNullOrUndefined } from '../../common/common.func.general';

/**
 * Represents a user object coming back from a Microsoft Graph API search.
 * Just the properties of interest... not all available properties.
 * Similar to the User type in the microsoft-graph.d.ts except this one has only what we need in this app.
 */
export interface IGraphUser {
    displayName?: string;
    givenName?: string;
    jobTitle?: string;
    userPrincipalName?: string;
    surname?: string;
    id?: string;
}

export class GraphUser implements IGraphUser {
    public displayName?: string;
    public givenName?: string;
    public jobTitle?: string;
    public userPrincipalName?: string;
    public surname?: string;
    public id?: string;

    /**
     * Gets the alias from the user principle name (email) string.
     */
    public get alias(): string {
        if (isNullOrUndefined(this.userPrincipalName)) {
            return '';
        }
        return this.userPrincipalName!.substring(0, this.userPrincipalName!.indexOf('@'));
    }

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IGraphUser) {
        this.displayName = jsonData.displayName;
        this.givenName = jsonData.givenName;
        this.jobTitle = jsonData.jobTitle;
        this.userPrincipalName = jsonData.userPrincipalName;
        this.surname = jsonData.surname;
        this.id = jsonData.id;
    }
}
