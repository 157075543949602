import React from 'react';
import {
    IColumn,
    Text,
    IDetailsColumnProps,
    Stack
} from '@fluentui/react';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { TeachingBubbleItem } from '../../components/CustomTeachingBubble/CustomTeachingBubble';
import { ReceivedAmountInput } from './ReceivedAmountInput';
import { PercentageReceivedInput } from './PercentageReceivedInput';
import { ReceiptDateInput } from './ReceiptDateInput';
import { DetailsActionButton } from '../../components/DetailsActionButton/DetailsActionButton';
import { commonStyles } from '../../common/common.styles';
import { LineItemStatusIndicator } from '../../components/LineItemStatusIndicator/LineItemStatusIndicator';
import { ClosedIndicator } from '../../components/ClosedIndicator/ClosedIndicator';
import { formatDateUsingLocale, isJulyFirstPst } from '../../common/common.func.datetime';
import { tooltips } from '../../common/tooltips';
import { AuditActionButton } from '../../components/AuditActionButton/AuditActionButton';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';
import { reduxStore } from '../../store/reduxStore';
import { servicesLineItemsSortOnColumn } from '../../store/actions/pageActions/editPage.action';

const columnSortHandler = (column: IColumn) => {
    reduxStore.dispatch(servicesLineItemsSortOnColumn(column.key));
}

export const servicesLineItemColumns: ICustomColumn[] = [
    {
        key: 'lineStatus',
        fieldName: undefined,
        name: '',
        isResizable: false,
        minWidth: 10,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <div className={commonStyles.statusColumnContainer}>
                    <LineItemStatusIndicator usedOnPage='edit' item={item} />
                </div>
            );
        }
    },
    {
        key: 'purchaseOrderLineNumber',
        fieldName: 'purchaseOrderLineNumber',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Line'
                    tooltip={tooltips.poLineNumber}
                />
            );
        },
        isResizable: false,
        minWidth: 50,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <>
                    <DetailsActionButton usedOnPage='editOrCloseLineItem' item={item} />
                    <ClosedIndicator item={item} usedOnPage='editPage' />
                </>
            );
        },
        isRowHeader: true
    },
    {
        key: 'info',
        fieldName: undefined,
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Info'
                    tooltip={tooltips.auditHistory}
                />
            );
        },
        isResizable: false,
        minWidth: 56,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Stack horizontal wrap={false}>
                    <AuditActionButton usedOnPage='editOrCloseLineItem' item={item} />
                </Stack>
            );
        },
        isRowHeader: true
    },
    {
        key: 'lineTotal',
        fieldName: 'lineTotal',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Line'
                    headerLine2='Total'
                    tooltip={tooltips.lineTotal}
                />
            );
        },
        isResizable: true,
        minWidth: 80,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {numberAsLocaleString(item.lineTotal, item.currencyFractionalPart)}
                </Text>
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'percentageReceived',
        fieldName: 'percentageReceived',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Received'
                    headerLine2='Percentage'
                    tooltip={tooltips.receivedPercentage}
                />
            );
        },
        isResizable: false,
        minWidth: 220,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <PercentageReceivedInput item={item} />
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'receivedAmount',
        fieldName: 'receivedAmount',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Received'
                    headerLine2='Amount'
                    tooltip={tooltips.receivedAmount}
                />
            );
        },
        isResizable: true,
        minWidth: 150,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <ReceivedAmountInput item={item} />
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'invoicedAmount',
        fieldName: 'invoicedAmount',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Invoiced'
                    headerLine2='Amount'
                    tooltip={tooltips.invoicedAmount}
                />
            );
        },
        isResizable: true,
        minWidth: 100,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {numberAsLocaleString(item.invoicedAmount, item.currencyFractionalPart)}
                </Text>
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'accruedAmount',
        fieldName: 'accruedAmount',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Amount To'
                    headerLine2='Accrue'
                    tooltip={tooltips.accruedAmount}
                    showTeachingBubble={true}
                    teachingBubbleItem={TeachingBubbleItem.AmountToAccrue}
                    teachingBubbleHeadline='Amount To Accrue'
                    teachingBubbleContent={tooltips.accruedAmount}
                />
            );
        },
        isResizable: true,
        minWidth: 110,
        onRender: (item: PurchaseOrderLineItem, index?: number) => {
            return (
                <>
                    <Text className={commonStyles.textCell}>
                        {numberAsLocaleString(item.accruedAmount, item.currencyFractionalPart)}
                    </Text>
                </>
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'receiptDate',
        fieldName: 'receiptDate',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            const isJulyFirst: boolean = isJulyFirstPst();
            const tooltip: string = `${tooltips.receiptDatePst}${isJulyFirst ? ` ${tooltips.receiptDateJulyFirst}` : ''}`;
            return (
                <CustomColumnHeader
                    headerLine1='Receipt'
                    headerLine2='Date (PST)'
                    tooltip={tooltip}
                    showTeachingBubble={true}
                    teachingBubbleItem={isJulyFirst ? TeachingBubbleItem.ReceiptDateJulyFirst : TeachingBubbleItem.ReceiptDate}
                    teachingBubbleHeadline='Receipt Date (PST)'
                    teachingBubbleContent={tooltip}
                />
            );
        },
        isResizable: false,
        minWidth: 150,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <ReceiptDateInput item={item} />
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'deliveryDate',
        fieldName: 'deliveryDate',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Delivery'
                    headerLine2='Date'
                    tooltip={tooltips.deliveryDate}
                />
            );
        },
        isResizable: false,
        minWidth: 100,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {formatDateUsingLocale(item.deliveryDate)}
                </Text>
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    },
    {
        key: 'currency',
        fieldName: 'currency',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Currency'
                    tooltip={tooltips.currencyCode}
                />
            );
        },
        isResizable: false,
        minWidth: 60,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {item.currency}
                </Text>
            );
        }
        // No need to make currency column sortable.
    },
    {
        key: 'lineDescription',
        fieldName: 'lineDescription',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Line'
                    headerLine2='Description'
                    tooltip={tooltips.lineDescription}
                />
            );
        },
        isResizable: true,
        minWidth: 250,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {item.description}
                </Text>
            );
        },
        sortCallback: (column: IColumn) => {
            columnSortHandler(column);
        }
    }
];
