import React, { useEffect, useState } from 'react';
import {
    FontIcon,
    Spinner,
    SpinnerSize,
    TooltipHost,
    Text,
    Link,
    TooltipDelay
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useAppSelector } from '../../store/hooks';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { extractPoLineFromNotificationId, isEqualIgnorePadding } from '../../common/common.func.general';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { componentStyles } from './LineItemStatusIndicator.styles';
import { GenericDialog, GenericDialogMode } from '../GenericDialog/GenericDialog';
import { commonStyles } from '../../common/common.styles';
import { tooltips } from '../../common/tooltips';

interface ILineItemStatusIndicatorProps {
    usedOnPage: 'priorYearAccrual' | 'closeLine' | 'edit';
    item: PurchaseOrderLineItem | PriorYearAccrualLineItem;
}

export const LineItemStatusIndicator: React.FunctionComponent<ILineItemStatusIndicatorProps> = (props: ILineItemStatusIndicatorProps): JSX.Element => {
    const [displayBusySpinner, setDisplayBusySpinner] = useState<boolean>(false);
    const [displayErrorIcon, setDisplayErrorIcon] = useState<boolean>(false);
    const [displayUnsupportedIcon, setDisplayUnsupportedIcon] = useState<boolean>(false);

    // This msg and dialog are used for both error or unsupported indicators.
    const [msg, setMsg] = useState<string>('');
    const [displayDialog, { toggle: toggleDisplayDialog }] = useBoolean(false);
    
    // Redux store selectors to get state from the store when it changes.
    const clientNotificationItems: ClientNotificationItem[] =
        useAppSelector<ClientNotificationItem[]>((state) => state.appReducer.clientNotificationItems);

    /**
     * Effect for when props change that affect display of error, info, or busy icons.
     */
    useEffect(() => {
        // Determine which icon to show: busy spinner, error icon, or unsupported icon. Never should have to show multiple icons.
        if (props.item instanceof PurchaseOrderLineItem) {
            if (props.item.isLineEnabledForGSR && props.item.offlineProcessingRunning && !props.item.offlineProcessingFailureMessage) {
                setDisplayBusySpinner(true);
                setDisplayErrorIcon(false);
                setDisplayUnsupportedIcon(false);
                setMsg('');
            } else if (props.item.isLineEnabledForGSR && !props.item.offlineProcessingRunning && props.item.offlineProcessingFailureMessage) {
                setDisplayBusySpinner(false);
                setDisplayErrorIcon(true);
                setDisplayUnsupportedIcon(false);
                setMsg(props.item.offlineProcessingFailureMessage);
            } else if (!props.item.isLineEnabledForGSR && props.item.lineDisabledMessage) {
                setDisplayBusySpinner(false);
                setDisplayErrorIcon(false);
                setDisplayUnsupportedIcon(true);
                setMsg(props.item.lineDisabledMessage);
            }
        } else if (props.item instanceof PriorYearAccrualLineItem) {
            // The PriorYearAccrualLineItem object does not have isLineEnabledForGSR or lineDisabledMessage fields.
            if (props.item.offlineProcessingRunning && !props.item.offlineProcessingFailureMessage) {
                setDisplayBusySpinner(true);
                setDisplayErrorIcon(false);
                setDisplayUnsupportedIcon(false);
                setMsg('');
            } else if (!props.item.offlineProcessingRunning && props.item.offlineProcessingFailureMessage) {
                setDisplayBusySpinner(false);
                setDisplayErrorIcon(true);
                setDisplayUnsupportedIcon(false);
                setMsg(props.item.offlineProcessingFailureMessage);
            }
        }
    }, [props.item]);

    /**
     * Effect for when clientNotificationItems changes.
     * We watch for changes to this in order to automatically stop the busy spinner, or update the error
     * indicator when notifications come in via SignalR.
     */
    useEffect(() => {
        clientNotificationItems.forEach(item => {
            // If the item is pre-existing (meaning the item existed at app-load time), then do nothing here.
            // We only want to do this update for notification items that come in after the app and any existing
            // notifications have already loaded. The reason for this is: Consider if there are notification items
            // that are for a PO line item in a failed state. If the user refreshes the page, we want the status
            // indicator to be what came back from the API to load the line items and not be altered by any existing
            // notificaiton.
            if (item && item.id && !item.isPreExisting) {
                // See if the notification item is for the PO/Line for this item.
                const extracted: {poNumber?: string, lineItem?: string} = extractPoLineFromNotificationId(item.id);
                const { poNumber, lineItem } = extracted;
                if (
                    (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem && isEqualIgnorePadding(props.item.purchaseOrder, poNumber || '') && isEqualIgnorePadding(props.item.lineItem, lineItem || '')) ||
                    ((props.usedOnPage === 'closeLine' || props.usedOnPage === 'edit') && props.item instanceof PurchaseOrderLineItem && isEqualIgnorePadding(props.item.purchaseOrderNumber, poNumber || '') && isEqualIgnorePadding(props.item.purchaseOrderLineNumber, lineItem || ''))
                ) {
                    // Determine which icon to show: busy spinner, error icon, or unsupported icon. Never should have to show multiple icons.
                    switch (item.notificationItemState) {
                        case ClientNotificationItemState.Succeeded: {
                            // Set the offline processing running flag to false and clear out any failure message.
                            setDisplayBusySpinner(false);
                            setDisplayErrorIcon(false);
                            setMsg('');
                            break;
                        }
                        case ClientNotificationItemState.Unknown:
                        case ClientNotificationItemState.Failed: {
                            // Set the offline processing running flag to false.
                            setDisplayBusySpinner(false);
                            // Set the item offline processing failure message to be the notification item text (not using the
                            // notification item header here). This should be the same text stored in the receipting DB for the
                            // offlineProcessingFailureMessage string that comes back with the line item data in search results.
                            setDisplayErrorIcon(true);
                            setMsg(item.text);
                            break;
                        }
                        case ClientNotificationItemState.InProgress: {
                            setDisplayBusySpinner(true);
                            setDisplayErrorIcon(false);
                            setMsg('');
                            break;
                        }
                        default:
                    }
                }
            }
        })
    }, [clientNotificationItems, props.item, props.usedOnPage]);

    return (
        <div className={componentStyles.container}>
            {displayErrorIcon && (
                <div className={componentStyles.lineError}>
                    <TooltipHost content={msg} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                        {/* Using a Link so the icon becomes focusable with keyboard navigation. */}
                        <Link
                            className={componentStyles.lineErrorIcon}
                            aria-label='View error'
                            onClick={() => toggleDisplayDialog()}
                        >
                            <FontIcon iconName='Error' className={commonStyles.pointer} />
                        </Link>
                    </TooltipHost>
                </div>
            )}
            {displayUnsupportedIcon && (
                <div className={componentStyles.lineUnsupported}>
                    <TooltipHost content={msg} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                        <Link
                            aria-label='View information'
                            onClick={() => toggleDisplayDialog()}
                        >
                            <FontIcon iconName='Info' className={commonStyles.pointer} />
                        </Link>
                    </TooltipHost>
                </div>
            )}
            {displayBusySpinner && (
                <div className={componentStyles.lineProcessingSpinner}>
                    <TooltipHost content={tooltips.offlineProcessingRunning} calloutProps={{ gapSpace: 8 }} delay={TooltipDelay.long}>
                        <Spinner size={SpinnerSize.small} />
                    </TooltipHost>
                </div>
            )}

            {/* In addition to showing the error in a tooltip on the icon... that icon can be clicked to show the same error in a dialog. */}
            <GenericDialog
                displayDialog={displayDialog}
                title={displayErrorIcon ? 'Error' : 'Information'}
                content={
                    <Text variant="medium">{msg}</Text>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayDialog();
                }}
            />
        </div>
    );
};
