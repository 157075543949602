export interface IAccrualLineDetail {
    id: string;
    purchaseOrderNumber: number;
    lineItemNumber: string;
    companyCode: string;
    currencyCode: string;
    lineItemTotalAmount: number;
    receivedAmount: number;
    invoicedAmount: number;
    internalOrder?: string;
    costCenter?: string;
    glAccountCode: string;
    isClosed: boolean;
}

export class AccrualLineDetail implements IAccrualLineDetail {
    public id: string;
    public purchaseOrderNumber: number;
    public lineItemNumber: string;
    public companyCode: string;
    public currencyCode: string;
    public lineItemTotalAmount: number;
    public receivedAmount: number;
    public invoicedAmount: number;
    public internalOrder?: string;
    public costCenter?: string;
    public glAccountCode: string;
    public isClosed: boolean;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IAccrualLineDetail) {
        this.id = jsonData.id;
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.lineItemNumber = jsonData.lineItemNumber;
        this.companyCode = jsonData.companyCode;
        this.currencyCode = jsonData.currencyCode;
        this.lineItemTotalAmount = jsonData.lineItemTotalAmount;
        this.receivedAmount = jsonData.receivedAmount;
        this.invoicedAmount = jsonData.invoicedAmount;
        this.internalOrder = jsonData.internalOrder;
        this.costCenter = jsonData.costCenter;
        this.glAccountCode = jsonData.glAccountCode;
        this.isClosed = jsonData.isClosed;
    }
}
