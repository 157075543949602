import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    sectionWrapper: {
        maxWidth: 'calc(100% - 20px)',
        flex: '1 auto',
        paddingBottom: '20px'
    } as IStyle
});
