import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    poSummaryTileColorBar: {
        padding: '2px'
    } as IStyle,
    poSummaryTileContent: {
        padding: '6px'
    } as IStyle,
    poSummaryTileButton: {
        padding: '6px 6px 6px 0'
    } as IStyle,
    poSummaryTileTable: {
        borderCollapse: 'collapse',
        'tr:nth-child(odd)': {
            // Note that this styling is similar to the Tile.tsx component. Not using that component here on the home page
            // as I wanted the layout to be in a table where the Find button is aligned across all tiles in the far right cell.
            border: '1px solid lightgray',
            '-webkit-box-shadow': '6px 6px 6px 0px rgb(160 160 160 / 90%)',
            '-moz-box-shadow': '6px 6px 6px 0px rgba(160, 160, 160, 0.90)',
            boxShadow: '1px 1px 1px 0px rgb(160 160 160 / 90%)',
            margin: '2px 2px 2px 0px',
            width: 'fit-content'
        } as IStyle,
        'tr:nth-child(even)': {
            backgroundColor: 'white',
            height: '12px'
        } as IStyle
    } as IStyle,
    poSummaryTileCategoryText: {
        fontStyle: 'italic',
        fontWeight: 'bold'
    } as IStyle,
    poSummaryTileNumber: {
        color: 'blue',
        fontWeight: '500',
        fontSize: '18px'
    } as IStyle,
    showFilterToggle: {
        margin: '0 0 0 46px'
    } as IStyle,
    driSearchToggle: {
        margin: '0 0 0 20px'
    } as IStyle,
    searchClearButtonHorizontalStack: {
        padding: '6px 0px 6px 0'
    } as IStyle,
    filterTextField: {
        minWidth: '170px'
    } as IStyle,
    filterDatePicker: {
        minWidth: '170px'
    } as IStyle,
    filterComboBox: {
        minWidth: '170px'
    } as IStyle,
    icon: {
        fontSize: '20px',
        verticalAlign: 'middle',
        margin: '0 0 4px 0'
    } as IStyle
});
