/**
 * Tile type enum matches the server side enum of the same name.
 */
export enum TileType {
    HomeOpen = 1,
    HomeDueThisMonth = 2,
    HomeOverDue = 3,
    FinanceControllerUnavailableOwner = 4,
    FinanceControllerUnavailableApprover = 5,
    FinanceControllerDueThisMonth = 6,
    FinanceControllerOpen = 7,
    FinanceControllerReceiptedByFinanceController = 8
}
