import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    Stack,
    Label,
    SelectionMode,
    ConstrainMode,
    DetailsListLayoutMode,
    Sticky,
    StickyPositionType,
    Text,
    IDetailsHeaderProps,
    IColumn,
    Spinner,
    SpinnerSize,
    IComboBoxOption,
    IComboBox,
    DefaultButton,
    TextField,
    VirtualizedComboBox,
    ChoiceGroup,
    IChoiceGroupOption,
    ITextField,
    Separator
} from '@fluentui/react';
import { commonStyles, horizontalChoiceGroupStyle, stackTokensLargeGap, stackTokensNormalGap } from '../../common/common.styles';
import { CustomDetailsList, ExcelExportMode } from '../../components/CustomDetailsList/CustomDetailsList';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { IColumnsAndItems, onCustomRenderRow, resetColumnSorting, sortOnColumn } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { CallApiState } from '../../store/actions/generic.action';
import { CostCategoryConfiguration, ICostCategoryConfiguration } from '../../models/priorYearAccrual/costCategoryConfiguration';
import { costCategoryConfigColumns } from './costCategoryConfigColumns';
import {
    IApiCostCategoryConfiguration,
    IApiCostCategoryConfigurationForGlAccount,
    IApiCostCategoryGroupNames,
    IApiCostCategoryLineItemDetails,
    IApiCostCategoryLineItems,
    IApiCostCategorySubclasses,
    IApiDeleteCostCategoryConfiguration,
    IApiSaveCostCategoryConfiguration,
    callApiCostCategoryConfiguration,
    callApiCostCategoryConfigurationForGlAccount,
    callApiCostCategoryGroupNames,
    callApiCostCategoryLineItemDetails,
    callApiCostCategoryLineItems,
    callApiCostCategorySubclasses,
    callApiDeleteCostCategoryConfiguration,
    callApiSaveCostCategoryConfiguration,
    costCategoryConfigurationSortOnColumn,
    deleteCostCategoryConfiguration,
    editCostCategoryConfiguration,
    resetApiCostCategoryConfigurationForGlAccount,
    resetApiCostCategoryGroupNames,
    resetApiCostCategoryLineItemDetails,
    resetApiCostCategoryLineItems,
    resetApiDeleteCostCategoryConfiguration,
    resetApiSaveCostCategoryConfiguration,
    updateSortedCostCategoryConfiguration
} from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { commonString } from '../../common/commonString';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { deepCopyObject } from '../../common/common.func.general';
import { validationConstants } from '../../common/validationConstants';
import { all } from './priorYearAccrualPageConstants';
import { CodeValue } from '../../models/priorYearAccrual/codeValue';

interface IAdminCostCategoryConfigProps {
    handleError: (errMsg: string) => void;
}

/**
 * These config modes represent different ways this config can be done. By using a set of mapping fields
 * to the dedicated GL account. Or a direct GL account to dedicated GL account mapping.
 */
export enum ConfigModeOptionKey {
    SubclassMapping = 'Subclass Mapping',
    SpecificGlAccount = 'Specific GL Account'
}

export const AdminCostCategoryConfig: React.FunctionComponent<IAdminCostCategoryConfigProps> = (props: IAdminCostCategoryConfigProps): JSX.Element => {
    const [columns, setColumns] = useState<IColumn[]>(costCategoryConfigColumns);

    const subclassInputId: string = useId();
    const shortNameInputId: string = useId();
    const groupNameInputId: string = useId();
    const lineItemInputId: string = useId();
    const lineItemDetailInputId: string = useId();
    const glAccountInputId: string = useId();
    const dedicatedGlInputId: string = useId();
    const readonlySubclassInputId: string = useId();
    const readonlyGroupNameInputId: string = useId();
    const readonlyLineItemInputId: string = useId();
    const readonlyLineItemDetailInputId: string = useId();

    const dedicatedGlInputRef = useRef<ITextField | null>();

    const [subclassOptions, setSubclassOptions] = useState<IComboBoxOption[]>([]);
    const [groupNameOptions, setGroupNameOptions] = useState<IComboBoxOption[]>([]);
    const [lineItemOptions, setLineItemOptions] = useState<IComboBoxOption[]>([]);
    const [lineItemDetailOptions, setLineItemDetailOptions] = useState<IComboBoxOption[]>([]);

    const [shortNameValidationError, setShortNameValidationError] = useState<string>('');
    const [glAccountValidationError, setGlAccountValidationError] = useState<string>('');
    const [dedicatedGlValidationError, setDedicatedGlValidationError] = useState<string>('');

    const [showEditDialog, { toggle: toggleEditDialog }] = useBoolean(false);
    const [showDeleteConfirmDialog, { toggle: toggleDeleteConfirmDialog }] = useBoolean(false);

    const [editDialogClosing, setEditDialogClosing] = useState<boolean>(false);

    const [editingCostCategoryConfiguration, setEditingCostCategoryConfiguration] = useState<CostCategoryConfiguration>();

    const [selectedConfigModeOptionKey, setSelectedConfigModeOptionKey] = useState<ConfigModeOptionKey>(ConfigModeOptionKey.SubclassMapping);

    // Redux store selectors to get state from the store when it changes.
    const apiCostCategoriySubclasses: IApiCostCategorySubclasses =
        useAppSelector<IApiCostCategorySubclasses>(state => state.priorYearAccrualPageReducer.apiCostCategoriySubclasses);
    const apiCostCategoryGroupNames: IApiCostCategoryGroupNames =
        useAppSelector<IApiCostCategoryGroupNames>(state => state.priorYearAccrualPageReducer.apiCostCategoryGroupNames);
    const apiCostCategoryLineItems: IApiCostCategoryLineItems =
        useAppSelector<IApiCostCategoryLineItems>(state => state.priorYearAccrualPageReducer.apiCostCategoryLineItems);
    const apiCostCategoryLineItemDetails: IApiCostCategoryLineItemDetails =
        useAppSelector<IApiCostCategoryLineItemDetails>(state => state.priorYearAccrualPageReducer.apiCostCategoryLineItemDetails);
    const apiCostCategoryConfig: IApiCostCategoryConfiguration =
        useAppSelector<IApiCostCategoryConfiguration>(state => state.priorYearAccrualPageReducer.apiCostCategoryConfiguration);
    const apiSaveCostCategoryConfiguration: IApiSaveCostCategoryConfiguration =
        useAppSelector<IApiSaveCostCategoryConfiguration>(state => state.priorYearAccrualPageReducer.apiSaveCostCategoryConfiguration);
    const apiDeleteCostCategoryConfiguration: IApiDeleteCostCategoryConfiguration =
        useAppSelector<IApiDeleteCostCategoryConfiguration>(state => state.priorYearAccrualPageReducer.apiDeleteCostCategoryConfiguration);
    const apiCostCategoryConfigurationForGlAccount: IApiCostCategoryConfigurationForGlAccount =
        useAppSelector<IApiCostCategoryConfigurationForGlAccount>(state => state.priorYearAccrualPageReducer.apiCostCategoryConfigurationForGlAccount);
    const costCategoryConfigSortUsingColumnKey: string | undefined =
        useAppSelector<string | undefined>((state) => state.priorYearAccrualPageReducer.costCategoryConfigurationSortUsingColumnKey);
    const costCategoryConfigurationToEdit: CostCategoryConfiguration | undefined =
        useAppSelector<CostCategoryConfiguration | undefined>(state => state.priorYearAccrualPageReducer.costCategoryConfigurationToEdit);
    const costCategoryConfigurationToDelete: CostCategoryConfiguration | undefined =
        useAppSelector<CostCategoryConfiguration | undefined>(state => state.priorYearAccrualPageReducer.costCategoryConfigurationToDelete);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Clear validation errors.
     */
    const clearErrors = () => {
        setShortNameValidationError('');
        setGlAccountValidationError('');
        setDedicatedGlValidationError('');
    };

    /**
     * Reset call state for APIs used when editing cost category configuration.
     */
    const resetEditDialogCostCategoryConfigurationApiState = useCallback(() => {
        // No need to reset the api to get subclasses. That can persist in the app state (no need to reload after loaded once).
        dispatch(resetApiCostCategoryGroupNames());
        dispatch(resetApiCostCategoryLineItems());
        dispatch(resetApiCostCategoryLineItemDetails());
        dispatch(resetApiCostCategoryConfigurationForGlAccount());
    }, [dispatch]);

    /**
     * Memoized flag to check if API to load cost category config is running.
     */
    const isLoadingCostCategoryConfig = useMemo<boolean>(() => {
        if (apiCostCategoryConfig.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiCostCategoryConfig.callApiState]);

    /**
     * Memoized flag to check if API to load cost category config for a specific GL is running.
     */
    const isLoadingCostCategoryForGl = useMemo<boolean>(() => {
        if (apiCostCategoryConfigurationForGlAccount.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiCostCategoryConfigurationForGlAccount.callApiState]);

    /**
     * Memoized flag to check if API to load cost category subclasses is running.
     */
    const isLoadingSubclasses = useMemo<boolean>(() => {
        if (apiCostCategoriySubclasses.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiCostCategoriySubclasses.callApiState]);

    /**
     * Memoized flag to check if API to load group names is running.
     */
    const isLoadingGroupNames = useMemo<boolean>(() => {
        if (apiCostCategoryGroupNames.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiCostCategoryGroupNames.callApiState]);

    /**
     * Memoized flag to check if API to load line items is running.
     */
    const isLoadingLineItems = useMemo<boolean>(() => {
        if (apiCostCategoryLineItems.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiCostCategoryLineItems.callApiState]);

    /**
     * Memoized flag to check if API to load line item details is running.
     */
    const isLoadingLineItemDetails = useMemo<boolean>(() => {
        if (apiCostCategoryLineItemDetails.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiCostCategoryLineItemDetails.callApiState]);

    /**
     * Memoized flag to check if the save api is running.
     */
    const isSaving = useMemo<boolean>(() => {
        if (apiSaveCostCategoryConfiguration.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSaveCostCategoryConfiguration.callApiState]);

    /**
     * Memoized flag to check if the delete api is running.
     */
    const isDeleting = useMemo<boolean>(() => {
        if (apiDeleteCostCategoryConfiguration.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiDeleteCostCategoryConfiguration.callApiState]);

    /**
     * Memoized flag to determine if editing a new record or not.
     */
    const isEditingNewRecord = useMemo<boolean>(() => {
        // New record have undefined for the id.
        if (costCategoryConfigurationToEdit && costCategoryConfigurationToEdit.id === undefined) {
            return true;
        }
        return false;
    }, [costCategoryConfigurationToEdit]);

    /**
     * Memoized flag to indicate if there is a duplicate record.
     * Run this check only when adding a new record.
     */
    const isDuplicateDetected = useMemo<boolean>(() => {
        if (isEditingNewRecord &&
            editingCostCategoryConfiguration?.subclassCode && // Subclass is a required field and will not be null (as it is a dropdown with one selected).
            apiCostCategoryConfig.costCategoryConfig?.find(x =>
            x.subclassCode === editingCostCategoryConfiguration?.subclassCode &&
            // Just comparing using the *Code fields, not the *Desc fields.
            (x.groupNameCode || all) === (editingCostCategoryConfiguration?.groupNameCode || all) &&
            (x.lineItemCode || all) === (editingCostCategoryConfiguration?.lineItemCode || all) &&
            (x.lineItemDetailCode || all) === (editingCostCategoryConfiguration?.lineItemDetailCode || all) &&
            (x.glAccount || all) === (editingCostCategoryConfiguration?.glAccount || all) &&
            (x.dedicatedGl || 0) === (editingCostCategoryConfiguration?.dedicatedGl || 0))
        ) {
            return true;
        }
        return false;
    }, [apiCostCategoryConfig.costCategoryConfig, editingCostCategoryConfiguration?.dedicatedGl, editingCostCategoryConfiguration?.glAccount, editingCostCategoryConfiguration?.groupNameCode, editingCostCategoryConfiguration?.lineItemCode, editingCostCategoryConfiguration?.lineItemDetailCode, editingCostCategoryConfiguration?.subclassCode, isEditingNewRecord]);

    /**
     * Memoized flag to check if input is valid.
     * @returns True or false.
     */
    const isInputValid = useMemo<boolean>(() => {
        // Check if duplicate is detected.
        if (isDuplicateDetected) {
            return false;
        }

        // Check required fields.
        if (selectedConfigModeOptionKey === ConfigModeOptionKey.SpecificGlAccount) {
            // If we are using the specific GL Account config option, then the GL Account is required.
            if (!editingCostCategoryConfiguration?.glAccount) {
                return false;
            }

            // Check for the fields returned from the apiCostCategoryConfigurationForGlAccount call are present.
            // When the lookup is complete, these values will be copied to the editingCostCategoryConfiguration. 
            if (!editingCostCategoryConfiguration?.subclassName ||
                !editingCostCategoryConfiguration?.subclassCode ||
                !editingCostCategoryConfiguration?.groupNameDesc ||
                !editingCostCategoryConfiguration?.groupNameCode ||
                !editingCostCategoryConfiguration?.lineItemDesc ||
                !editingCostCategoryConfiguration?.lineItemCode ||
                !editingCostCategoryConfiguration?.lineItemDetailDesc ||
                !editingCostCategoryConfiguration?.lineItemDetailCode) {
                return false;
            }
        }

        // Both config options require the Dedicated GL.
        if (!editingCostCategoryConfiguration?.dedicatedGl) {
            return false;
        }

        // Check for validation error messages.
        if (glAccountValidationError.length > 0 ||
            shortNameValidationError.length > 0 ||
            dedicatedGlValidationError.length > 0) {
            return false;
        }

        return true;
    }, [dedicatedGlValidationError.length, editingCostCategoryConfiguration?.dedicatedGl, editingCostCategoryConfiguration?.glAccount, editingCostCategoryConfiguration?.groupNameCode, editingCostCategoryConfiguration?.groupNameDesc, editingCostCategoryConfiguration?.lineItemCode, editingCostCategoryConfiguration?.lineItemDesc, editingCostCategoryConfiguration?.lineItemDetailCode, editingCostCategoryConfiguration?.lineItemDetailDesc, editingCostCategoryConfiguration?.subclassCode, editingCostCategoryConfiguration?.subclassName, glAccountValidationError.length, isDuplicateDetected, selectedConfigModeOptionKey, shortNameValidationError.length]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        resetColumnSortingAndSetColumns();

        // If the call to get subclasses is already complete from a prior run (if the user was on this page earlier, then navigated
        // elsewhere, then came back), then no need to get the data again.
        if (apiCostCategoriySubclasses.callApiState !== CallApiState.Completed) {
            dispatch(callApiCostCategorySubclasses());
        }

        // Same for cost category configuration data. Only fire the API call if it wasn't retrieved earlier.
        if (apiCostCategoryConfig.callApiState !== CallApiState.Completed) {
            dispatch(callApiCostCategoryConfiguration());
        }
    });

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        // Pass the error to the parent component.
        if (apiCostCategoryConfig.errMsg) {
            props.handleError(apiCostCategoryConfig.errMsg);
        }
        if (apiCostCategoriySubclasses.errMsg) {
            props.handleError(apiCostCategoriySubclasses.errMsg);
        }
        if (apiCostCategoryGroupNames.errMsg) {
            props.handleError(apiCostCategoryGroupNames.errMsg);
        }
        if (apiCostCategoryLineItems.errMsg) {
            props.handleError(apiCostCategoryLineItems.errMsg);
        }
        if (apiCostCategoryLineItemDetails.errMsg) {
            props.handleError(apiCostCategoryLineItemDetails.errMsg);
        }
        if (apiSaveCostCategoryConfiguration.errMsg) {
            props.handleError(apiSaveCostCategoryConfiguration.errMsg);
        }
        if (apiDeleteCostCategoryConfiguration.errMsg) {
            props.handleError(apiDeleteCostCategoryConfiguration.errMsg);
        }
        if (apiCostCategoryConfigurationForGlAccount.errMsg) {
            props.handleError(apiCostCategoryConfigurationForGlAccount.errMsg);
        }
        // Disabling exhaustive deps because we don't want to run this effect when the props.handleError function changes.
        // See: https://stackoverflow.com/questions/62807829/how-to-correctly-implement-a-props-callback-function-in-the-useeffect-hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiCostCategoriySubclasses.errMsg, apiCostCategoryConfig.errMsg, apiCostCategoryConfigurationForGlAccount.errMsg, apiCostCategoryGroupNames.errMsg, apiCostCategoryLineItemDetails.errMsg, apiCostCategoryLineItems.errMsg, apiDeleteCostCategoryConfiguration.errMsg, apiSaveCostCategoryConfiguration.errMsg]);

    /**
     * Effect for when sort on column changes.
     */
    useEffect(() => {
        if (costCategoryConfigSortUsingColumnKey) {
            // Create a new columns array here.
            const columns: IColumn[] = costCategoryConfigColumns;
            const column: IColumn | undefined = columns.find(x => x.key === costCategoryConfigSortUsingColumnKey);
            if (column) {
                const columnsAndItems: IColumnsAndItems<CostCategoryConfiguration> = sortOnColumn<CostCategoryConfiguration>(
                    column,
                    columns,
                    apiCostCategoryConfig.costCategoryConfig || []
                );
                // Cannot change the prior year accrual data in Redux state here, need to dispatch so it changes in the store.
                dispatch(updateSortedCostCategoryConfiguration(columnsAndItems.items));
                setColumns(columnsAndItems.columns);
            }
        }
    }, [apiCostCategoryConfig.costCategoryConfig, costCategoryConfigSortUsingColumnKey, dispatch]);

    /**
     * Effect for when api to get subclasses completes.
     */
    useEffect(() => {
        const options: IComboBoxOption[] = [];
        // Check for Completed state, not DataAvailable. If the data is already present from a prior visit to this page, or if
        // it just moved to Completed state, then load the options.
        if (apiCostCategoriySubclasses.callApiState === CallApiState.Completed && apiCostCategoriySubclasses.costCategorySubclasses) {
            apiCostCategoriySubclasses.costCategorySubclasses.forEach(codeValue => {
                options.push({
                    key: codeValue.code,
                    text: `${codeValue.code} - ${codeValue.value}`,
                    data: codeValue
                } as IComboBoxOption);
            });
            setSubclassOptions(options);
        }
    }, [apiCostCategoriySubclasses.callApiState, apiCostCategoriySubclasses.costCategorySubclasses]);

    /**
     * Effect for when api to get group names completes.
     */
    useEffect(() => {
        const options: IComboBoxOption[] = [];
        // Watching for change to DataAvailable rather than Completed. The api call state is stored in Redux which
        // persists between pages. Only populate these options if the groups were freshly retrieved, not if it was Completed
        // from a prior call.
        if (apiCostCategoryGroupNames.callApiState === CallApiState.DataAvailable && apiCostCategoryGroupNames.groupNames) {
            // Add an option for 'All'. If nothing is selected, then 'All' will be displayed in the ComboBox.
            // This option will allow the user to change from a selected value back to the 'All' option. When saved
            // and 'All' is selected, then it will save a blank string for the group name.
            options.push({
                key: all,
                text: all,
                data: all
            });
            apiCostCategoryGroupNames.groupNames.forEach(codeValue => {
                options.push({
                    key: codeValue.code,
                    text: `${codeValue.code} - ${codeValue.value}`,
                    data: codeValue
                } as IComboBoxOption);
            });
            setGroupNameOptions(options);
        }
    }, [apiCostCategoryGroupNames.callApiState, apiCostCategoryGroupNames.groupNames]);

    /**
     * Effect for when api to get line item completes.
     */
    useEffect(() => {
        const options: IComboBoxOption[] = [];
        // Watching for change to DataAvailable rather than Completed. The api call state is stored in Redux which
        // persists between pages. Only populate these options if the groups were freshly retrieved, not if it was Completed
        // from a prior call.
        if (apiCostCategoryLineItems.callApiState === CallApiState.DataAvailable && apiCostCategoryLineItems.lineItems) {
            // Add an option for 'All'. If nothing is selected, then 'All' will be displayed in the ComboBox.
            // This option will allow the user to change from a selected value back to the 'All' option. When saved
            // and 'All' is selected, then it will save a blank string for the line item.
            options.push({
                key: all,
                text: all,
                data: all
            });
            apiCostCategoryLineItems.lineItems.forEach(codeValue => {
                options.push({
                    key: codeValue.code,
                    text: `${codeValue.code} - ${codeValue.value}`,
                    data: codeValue
                } as IComboBoxOption);
            });
            setLineItemOptions(options);
        }
    }, [apiCostCategoryLineItems.callApiState, apiCostCategoryLineItems.lineItems]);

    /**
     * Effect for when api to get line item details completes.
     */
    useEffect(() => {
        const options: IComboBoxOption[] = [];
        // Watching for change to DataAvailable rather than Completed. The api call state is stored in Redux which
        // persists between pages. Only populate these options if the groups were freshly retrieved, not if it was Completed
        // from a prior call.
        if (apiCostCategoryLineItemDetails.callApiState === CallApiState.DataAvailable && apiCostCategoryLineItemDetails.lineItemDetails) {
            // Add an option for 'All'. If nothing is selected, then 'All' will be displayed in the ComboBox.
            // This option will allow the user to change from a selected value back to the 'All' option. When saved
            // and 'All' is selected, then it will save a blank string for the line item details.
            options.push({
                key: all,
                text: all,
                data: all
            });
            apiCostCategoryLineItemDetails.lineItemDetails.forEach(codeValue => {
                options.push({
                    key: codeValue.code,
                    text: `${codeValue.code} - ${codeValue.value}`,
                    data: codeValue
                } as IComboBoxOption);
            });
            setLineItemDetailOptions(options);
        }
    }, [apiCostCategoryLineItemDetails.callApiState, apiCostCategoryLineItemDetails.lineItemDetails]);

    /**
     * Effect for when api call to save cost category configuration completes.
     */
    useEffect(() => {
        // Watching for change to DataAvailable rather than Completed. The api call state is stored in Redux which
        // persists between pages. If someone was to save something, navigate elsewhere, then come back here, this
        // effect would run and the state from the prior call would still be Completed. So we only want the code to run
        // where when the transition to DataAvailable happens.
        if (apiSaveCostCategoryConfiguration.callApiState === CallApiState.DataAvailable) {
            // Clear the item being edited in Redux.
            dispatch(editCostCategoryConfiguration(undefined));

            // Reload the data.
            dispatch(callApiCostCategoryConfiguration());

            // Reset the API call state for the save API. This is just for cleanup, no need to keep this state around after
            // a successful save.
            // If the call failed then the call state would have moved to Failed and the error handling effect would trigger.
            dispatch(resetApiSaveCostCategoryConfiguration());
        }
    }, [apiSaveCostCategoryConfiguration.callApiState, dispatch]);

    /**
     * Effect for when api call to delete cost category configuration completes.
     */
    useEffect(() => {
        // Watching for change to DataAvailable rather than Completed. The api call state is stored in Redux which
        // persists between pages. If someone was to delete something, navigate elsewhere, then come back here, this
        // effect would run and the state from the prior call would still be Completed. So we only want the code to run
        // where when the transition to DataAvailable happens.
        if (apiDeleteCostCategoryConfiguration.callApiState === CallApiState.DataAvailable) {
            // Clear the item being edited in Redux.
            dispatch(deleteCostCategoryConfiguration(undefined));

            // Reload the data.
            dispatch(callApiCostCategoryConfiguration());

            // Reset the API call state for the delete API. This is just for cleanup, no need to keep this state around after
            // a successful save.
            // If the call failed then the call state would have moved to Failed and the error handling effect would trigger.
            dispatch(resetApiDeleteCostCategoryConfiguration());
        }
    }, [apiDeleteCostCategoryConfiguration.callApiState, dispatch]);

    /**
     * Effect for when deleting of cost category configuration.
     */
    useEffect(() => {
        if (costCategoryConfigurationToDelete) {
            toggleDeleteConfirmDialog();
        }
    }, [costCategoryConfigurationToDelete, toggleDeleteConfirmDialog]);

    /**
     * Effect for when editing of cost category configuration.
     */
    useEffect(() => {
        if (costCategoryConfigurationToEdit) {
            clearErrors();
            resetEditDialogCostCategoryConfigurationApiState();

            // Deep copy the object and set into local state. This is so we don't edit the state
            // directly in the Redux store and also to allow cancelling out of the dialog with no change made.
            const copy: ICostCategoryConfiguration = deepCopyObject(costCategoryConfigurationToEdit);
            // If no group name, line item, or line item detail, then default it to all.
            // If left as all during save it will be saved as undefined.
            if (!copy.groupNameCode) {
                copy.groupNameDesc = all;
                copy.groupNameCode = all;
            }
            if (!copy.lineItemCode) {
                copy.lineItemDesc = all;
                copy.lineItemCode = all;
            }
            if (!copy.lineItemDetailCode) {
                copy.lineItemDetailDesc = all;
                copy.lineItemDetailCode = all;
            }
            setEditingCostCategoryConfiguration(deepCopyObject(copy));  

            // If the GL Account is present in the data being edited, then set the config mode to ConfigModeOptionKey.SpecificGlAccount.
            // Otherwise set the mode to ConfigModeOptionKey.SubclassMapping.
            setSelectedConfigModeOptionKey(
                copy.glAccount && copy.glAccount.length > 0 ? ConfigModeOptionKey.SpecificGlAccount :
                ConfigModeOptionKey.SubclassMapping
            );

            // If editing a new record then id will be undefined.
            // When editing a new record, then the ComboBoxes will populate in a cascading fasion.
            // When editing an existing record, then the ComboBoxes will be read only and should have only the edited
            // records values as the only item in the read only ComboBox.
            if (copy.id === undefined) {
                // New record.
                // Get the group names for the subclass in the configuration to edit.
                // See in the addNewButtonClicked function where it will use the first subclass in the list as a default.
                dispatch(callApiCostCategoryGroupNames(copy.subclassCode));
            } else {
                // Existing record.
                // Populate each of the readonly ComboBox with one entry based on the record being edited.
                setGroupNameOptions([
                    {
                        key: copy.groupNameCode,
                        text: copy.groupNameDesc
                    } as IComboBoxOption
                ]);
                setLineItemOptions([
                    {
                        key: copy.lineItemCode,
                        text: copy.lineItemDesc
                    } as IComboBoxOption
                ]);
                setLineItemDetailOptions([
                    {
                        key: copy.lineItemDetailCode,
                        text: copy.lineItemDetailDesc
                    } as IComboBoxOption
                ]);
            }

            toggleEditDialog();
        }
    }, [costCategoryConfigurationToEdit, dispatch, resetEditDialogCostCategoryConfigurationApiState, toggleEditDialog]);

    /**
     * Effect for when the showEditDialog toggle boolean changes.
     */
    useEffect(() => {
        if (showEditDialog) {
            // After a brief wait, set focus to the next editable control in the dialog.
            // Some fields may be read only based on if editing a new record or not.
            setTimeout(() => {
                if (!isEditingNewRecord) {
                    // When editing an existing record, all the fields except the Dedicated GL will be disabled.
                    dedicatedGlInputRef.current?.focus();
                }
            }, 50); // 50ms seems like a good wait period. When it was shorter then sometimes the control was not yet rendered.
        } else {
            setTimeout(() => {
                setEditDialogClosing(false);
            }, 300); // Wait briefly before setting this. Allows render to finish and dialog to disappear first.
        }
    }, [isEditingNewRecord, showEditDialog]);

    /**
     * Effect for when the API to lookup cost category configuration for a GL account returns data.
     */
    useEffect(() => {
        if (apiCostCategoryConfigurationForGlAccount.callApiState === CallApiState.DataAvailable) {
            // Make sure that all required data is present.
            if (apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.subclassName &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.subclassCode &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.groupNameDesc &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.groupNameCode &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemDesc &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemCode &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemDetailDesc &&
                apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemDetailCode) {
                // If it is, then assign it to the cost category configuration being edited.
                setEditingCostCategoryConfiguration(prevState => {
                    return {
                        ...prevState!,
                        subclassName: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.subclassName || '',
                        subclassCode: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.subclassCode || '',
                        groupNameDesc: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.groupNameDesc,
                        groupNameCode: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.groupNameCode,
                        lineItemDesc: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemDesc,
                        lineItemCode: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemCode,
                        lineItemDetailDesc: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemDetailDesc,
                        lineItemDetailCode: apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration?.lineItemDetailCode
                    };
                });
            }
        }
    }, [apiCostCategoryConfigurationForGlAccount.callApiState, apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration]);

    /**
     * Reset column sorting and set columns.
     */
    const resetColumnSortingAndSetColumns = useCallback(() => {
        // Dispatch an action to not sort on any column.
        dispatch(costCategoryConfigurationSortOnColumn(undefined));
        setColumns(resetColumnSorting([...columns]));
    }, [columns, dispatch]);

    /**
     * Subclass change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeSubclass = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const selectedSubclass: CodeValue = option.data as CodeValue;
            setEditingCostCategoryConfiguration(prevState => {
                return {
                    ...prevState!,
                    subclassName: selectedSubclass.value,
                    subclassCode: selectedSubclass.code,
                    // Reset the dropdowns for group name, line item, and line item detail, back to all.
                    groupNameDesc: all,
                    groupNameCode: all,
                    lineItemDesc: all,
                    lineItemCode: all,
                    lineItemDetailDesc: all,
                    lineItemDetailCode: all
                };
            });

            // Get the group names for the selected subclass code.
            dispatch(callApiCostCategoryGroupNames(selectedSubclass.code));
        }
    };

    /**
     * Group name change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeGroupName = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const selectedGroupName: CodeValue = option.data as CodeValue;
            setEditingCostCategoryConfiguration(prevState => {
                return {
                    ...prevState!,
                    groupNameDesc: selectedGroupName.value,
                    groupNameCode: selectedGroupName.code,
                    // Reset the dropdowns for line item, and line item detail, back to all.
                    lineItemDesc: all,
                    lineItemCode: all,
                    lineItemDetailDesc: all,
                    lineItemDetailCode: all
                };
            });

            if (selectedGroupName.code !== all) {
                // Get the line items for the selected group name code.
                dispatch(callApiCostCategoryLineItems(selectedGroupName.code));
            }
        }
    };

    /**
     * Line item change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeLineItem = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const selectedLineItem: CodeValue = option.data as CodeValue;
            setEditingCostCategoryConfiguration(prevState => {
                return {
                    ...prevState!,
                    lineItemDesc: selectedLineItem.value,
                    lineItemCode: selectedLineItem.code,
                    // Reset the dropdown for line item detail back to all.
                    lineItemDetailDesc: all,
                    lineItemDetailCode: all
                };
            });

            if (selectedLineItem.code !== all) {
                // Get the line item details for the selected line item code.
                dispatch(callApiCostCategoryLineItemDetails(selectedLineItem.code));
            }
        }
    };

    /**
     * Line item detail change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeLineItemDetail = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            const selectedLineItemDetail: CodeValue = option.data as CodeValue;
            setEditingCostCategoryConfiguration(prevState => {
                return {
                    ...prevState!,
                    lineItemDetailDesc: selectedLineItemDetail.value,
                    lineItemDetailCode: selectedLineItemDetail.code
                };
            });
        }
    };

    /**
     * Add new button clicked event handler.
     */
    const addNewButtonClicked = () => {
        if (subclassOptions.length === 0) {
            return; // This should never be the case, as the subclass is required and the dropdown should be populated.
        }

        // Get the first subclass option to use as a default.
        const firstSubclassOption: IComboBoxOption = subclassOptions[0];

        const newCostCategoryConfiguration = new CostCategoryConfiguration({
            id: undefined, // For new records, the server side will assign an id during the insert to the SQL table.
            subclassName: firstSubclassOption.text,
            subclassCode: firstSubclassOption.key as string,
            shortName: '',
            groupNameDesc: '',
            groupNameCode: '',
            lineItemDesc: '',
            lineItemCode: '',
            lineItemDetailDesc: '',
            lineItemDetailCode: '',
            glAccount: '',
            glAccountDescription: '',
            dedicatedGl: 0,
            dedicatedGlDescription: ''
        });
        // Dispatch to Redux that we will begin editing this new entry.
        dispatch(editCostCategoryConfiguration(newCostCategoryConfiguration));
    };

    /**
     * Reset editing of cost category configuration.
     * This is called when changing between config modes.
     * @param configModeOptionKey Config mode option key. Passed as a param and not using the selectedConfigModeOptionKey as it is not set in state when this is called.
     */
    const resetEditingCostCategoryConfiguration = (configModeOptionKey: ConfigModeOptionKey) => {
        // Reset the APIs used with the editing dialog.
        resetEditDialogCostCategoryConfigurationApiState();

        // Get the first subclass option to use as a default.
        const firstSubclassOption: IComboBoxOption = subclassOptions[0];
        const firstSubclass: CodeValue = firstSubclassOption.data as CodeValue;

        if (configModeOptionKey === ConfigModeOptionKey.SubclassMapping) {
            setEditingCostCategoryConfiguration(prevState => {
                return {
                    ...prevState!,
                    subclassName: firstSubclass.value,
                    subclassCode: firstSubclass.code,
                    shortName: '',
                    // Reset the dropdowns for group name, line item, and line item detail, back to all.
                    groupNameDesc: all,
                    groupNameCode: all,
                    lineItemDesc: all,
                    lineItemCode: all,
                    lineItemDetailDesc: all,
                    lineItemDetailCode: all,
                    glAccount: '',
                    glAccountDescription: '',
                    dedicatedGl: 0,
                    dedicatedGlDescription: ''
                };
            });
            // Fetch the group names for the first subclass.
            dispatch(callApiCostCategoryGroupNames(firstSubclass.code));
        } else if (configModeOptionKey === ConfigModeOptionKey.SpecificGlAccount) {
            setEditingCostCategoryConfiguration(prevState => {
                return {
                    ...prevState!,
                    subclassName: '',
                    subclassCode: '',
                    shortName: '',
                    groupNameDesc: '',
                    groupNameCode: '',
                    lineItemDesc: '',
                    lineItemCode: '',
                    lineItemDetailDesc: '',
                    lineItemDetailCode: '',
                    glAccount: '',
                    glAccountDescription: '',
                    dedicatedGl: 0,
                    dedicatedGlDescription: ''
                };
            });
        }
    };

    return (
        <>
            <Stack tokens={stackTokensLargeGap}>
                <Stack.Item>
                    <Text variant='mediumPlus' block className={commonStyles.sectionHeading} role="heading" aria-level={1}>Cost category configuration</Text>
                    <Text variant='medium' block className={commonStyles.sectionContent}>Configure GL account mapping for posting prior year over and under accruals per corporate guidance. The Prior Year Accrual engine uses this data to find the Reclass To Account as per the cost category configuration.</Text>
                </Stack.Item>
                {isLoadingCostCategoryConfig && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Stack.Item>
                )}
                {!isLoadingCostCategoryConfig && (
                    <>
                        <Stack.Item>
                            <DefaultButton
                                onClick={addNewButtonClicked}
                                disabled={isSaving || isDeleting || isLoadingCostCategoryConfig || editDialogClosing}
                            >
                                {isSaving && costCategoryConfigurationToEdit?.id === undefined /* Show spinner here for saving new record only. */ && (
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                )}
                                {!(isSaving && costCategoryConfigurationToEdit?.id === undefined) && (
                                    <span>Add New</span>
                                )}
                            </DefaultButton>
                        </Stack.Item>
                        <Stack.Item>
                            <CustomDetailsList
                                id="costCategoryConfigDetailsList"
                                ariaLabelForGrid="Cost Category Configuration"
                                className={pageStyles.detailsList}
                                excelExportMode={ExcelExportMode.Internal}
                                showExcelExport={true}
                                exportExcelSheetName="Cost Category configuration"
                                displayTotalItems={false}
                                showPaginator={false}
                                showPageSize={false}
                                items={apiCostCategoryConfig.costCategoryConfig || []}
                                isLoading={apiCostCategoryConfig.callApiState === CallApiState.Running}
                                compact={false}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                getKey={(item: CostCategoryConfiguration) => item.clientRowKey!}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                isHeaderVisible={true}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderRow={onCustomRenderRow}
                                useScrollablePane={true}
                                onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                    if (detailsHeaderProps) {
                                        return (
                                            <Sticky stickyPosition={StickyPositionType.Header}>
                                                {defaultRender!({ ...detailsHeaderProps })}
                                            </Sticky>
                                        );
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Stack.Item>
                    </>
                )}
            </Stack>

            <GenericDialog
                displayDialog={showEditDialog}
                title="Cost Category Configuration"
                width={680}
                content={
                    <div className={pageStyles.costCategoryConfigDialogContentContainer}>
                        <Stack tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <ChoiceGroup
                                    styles={horizontalChoiceGroupStyle}
                                    selectedKey={selectedConfigModeOptionKey}
                                    options={[
                                        {
                                            key: ConfigModeOptionKey.SubclassMapping,
                                            text: ConfigModeOptionKey.SubclassMapping
                                        } as IChoiceGroupOption,
                                        {
                                            key: ConfigModeOptionKey.SpecificGlAccount,
                                            text: ConfigModeOptionKey.SpecificGlAccount
                                        } as IChoiceGroupOption
                                    ]}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                        const configModeOptionKey: ConfigModeOptionKey = option?.key as ConfigModeOptionKey;
                                        setSelectedConfigModeOptionKey(configModeOptionKey);
                                        // When changing between config modes, reset the data being edited.
                                        resetEditingCostCategoryConfiguration(configModeOptionKey);
                                    }}
                                    disabled={!isEditingNewRecord} // Only allow change for this field if editing a new record.
                                />
                                <Separator />
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                    {selectedConfigModeOptionKey === ConfigModeOptionKey.SubclassMapping && (
                                        <>
                                            <Stack.Item>
                                                <Label htmlFor={subclassInputId}>{commonString.costCategorySubclass}</Label>
                                                <VirtualizedComboBox
                                                    id={subclassInputId}
                                                    ariaLabel={commonString.costCategorySubclass}
                                                    className={pageStyles.costCategoryConfigComboBox}
                                                    disabled={isLoadingSubclasses || !isEditingNewRecord} // Only allow change for this field if editing a new record.
                                                    multiSelect={false}
                                                    options={subclassOptions}
                                                    selectedKey={editingCostCategoryConfiguration?.subclassCode}
                                                    onChange={onChangeSubclass}
                                                    useComboBoxAsMenuWidth={false}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label htmlFor={groupNameInputId}>{commonString.costCategoryGroupName}</Label>
                                                <div className={pageStyles.costCategoryConfigComboBoxLoadSpinnerOuterContainer}>
                                                    <VirtualizedComboBox
                                                        id={groupNameInputId}
                                                        ariaLabel={commonString.costCategoryGroupName}
                                                        className={pageStyles.costCategoryConfigComboBox}
                                                        disabled={
                                                            isLoadingGroupNames || !isEditingNewRecord // Only allow change for this field if editing a new record.
                                                        }
                                                        multiSelect={false}
                                                        options={groupNameOptions}
                                                        selectedKey={editingCostCategoryConfiguration?.groupNameCode}
                                                        onChange={onChangeGroupName}
                                                        useComboBoxAsMenuWidth={false}
                                                        // Using a placeholder for all in addition to an actual option for all.
                                                        // This is do handle when there are no options loaded yet if an option was not
                                                        // selected in a prior combo box which would cause the options here to load.
                                                        placeholder={all}
                                                    />
                                                    {isLoadingGroupNames && (
                                                        <div className={pageStyles.costCategoryConfigComboBoxLoadSpinnerInnerContainer}>
                                                            <Spinner size={SpinnerSize.medium} className={commonStyles.comboBoxLoadSpinner} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label htmlFor={lineItemInputId}>{commonString.costCategoryLineItem}</Label>
                                                <div className={pageStyles.costCategoryConfigComboBoxLoadSpinnerOuterContainer}>
                                                    <VirtualizedComboBox
                                                        id={lineItemInputId}
                                                        ariaLabel={commonString.costCategoryLineItem}
                                                        className={pageStyles.costCategoryConfigComboBox}
                                                        disabled={
                                                            editingCostCategoryConfiguration?.groupNameCode === all || // If group name code is all then disable this combo box.
                                                            isLoadingLineItems || !isEditingNewRecord // Only allow change for this field if editing a new record.
                                                        }
                                                        multiSelect={false}
                                                        options={lineItemOptions}
                                                        selectedKey={editingCostCategoryConfiguration?.lineItemCode}
                                                        onChange={onChangeLineItem}
                                                        useComboBoxAsMenuWidth={false}
                                                        // Using a placeholder for all in addition to an actual option for all.
                                                        // This is do handle when there are no options loaded yet if an option was not
                                                        // selected in a prior combo box which would cause the options here to load.
                                                        placeholder={all}
                                                    />
                                                    {isLoadingLineItems && (
                                                        <div className={pageStyles.costCategoryConfigComboBoxLoadSpinnerInnerContainer}>
                                                            <Spinner size={SpinnerSize.medium} className={commonStyles.comboBoxLoadSpinner} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label htmlFor={lineItemDetailInputId}>{commonString.costCategoryLineItemDetail}</Label>
                                                <div className={pageStyles.costCategoryConfigComboBoxLoadSpinnerOuterContainer}>
                                                    <VirtualizedComboBox
                                                        id={lineItemDetailInputId}
                                                        ariaLabel={commonString.costCategoryLineItemDetail}
                                                        className={pageStyles.costCategoryConfigComboBox}
                                                        disabled={
                                                            editingCostCategoryConfiguration?.lineItemCode === all || // If line item code is all then disable this combo box.
                                                            isLoadingLineItemDetails || !isEditingNewRecord // Only allow change for this field if editing a new record.
                                                        }
                                                        multiSelect={false}
                                                        options={lineItemDetailOptions}
                                                        selectedKey={editingCostCategoryConfiguration?.lineItemDetailCode}
                                                        onChange={onChangeLineItemDetail}
                                                        useComboBoxAsMenuWidth={false}
                                                        // Using a placeholder for all in addition to an actual option for all.
                                                        // This is do handle when there are no options loaded yet if an option was not
                                                        // selected in a prior combo box which would cause the options here to load.
                                                        placeholder={all}
                                                    />
                                                    {isLoadingLineItemDetails && (
                                                        <div className={pageStyles.costCategoryConfigComboBoxLoadSpinnerInnerContainer}>
                                                            <Spinner size={SpinnerSize.medium} className={commonStyles.comboBoxLoadSpinner} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Stack.Item>
                                        </>
                                    )}

                                    {selectedConfigModeOptionKey === ConfigModeOptionKey.SpecificGlAccount && (
                                        <>
                                            <Stack.Item>
                                                <Label htmlFor={glAccountInputId}>{commonString.glAccount}<span className={commonStyles.requiredFieldStar}>*</span></Label>
                                                <TextField
                                                    id={glAccountInputId}
                                                    autoComplete='off'
                                                    ariaLabel={`${commonString.glAccount} ${validationConstants.costCategoryGlAccount.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                                    className={pageStyles.costCategoryConfigSmallTextField}
                                                    value={editingCostCategoryConfiguration?.glAccount}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        newValue = newValue || '';
                                                        if (newValue.length > 0 && (newValue.length > validationConstants.costCategoryGlAccount.maxLength! ||
                                                            !RegExp(validationConstants.costCategoryGlAccount.pattern!).test(newValue))) {
                                                            setGlAccountValidationError(validationConstants.costCategoryGlAccount.errorMsg!);
                                                        } else {
                                                            setGlAccountValidationError('');
                                                        }
                                                        setEditingCostCategoryConfiguration(prevState => {
                                                            return {
                                                                ...prevState!,
                                                                glAccount: newValue
                                                            }
                                                        });
                                                    }}
                                                    disabled={!isEditingNewRecord} // Only allow change for this field if editing a new record.
                                                    errorMessage={glAccountValidationError}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>&nbsp;{/* Placeholder empty label for spacing. */}</Label>
                                                <DefaultButton
                                                    onClick={() => {
                                                        // Call the API to get the cost category configuration for the
                                                        // specified GL account.
                                                        if (editingCostCategoryConfiguration?.glAccount) {
                                                            dispatch(callApiCostCategoryConfigurationForGlAccount(editingCostCategoryConfiguration.glAccount));
                                                        }
                                                    }}
                                                    disabled={
                                                        !isEditingNewRecord || // Only enable the lookup button if editing a new record.
                                                        isLoadingCostCategoryForGl || !editingCostCategoryConfiguration?.glAccount || glAccountValidationError.length > 0
                                                    }
                                                >
                                                    {isLoadingCostCategoryForGl && (
                                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                    )}
                                                    {!isLoadingCostCategoryForGl && (
                                                        <span>Lookup</span>
                                                    )}
                                                </DefaultButton>
                                            </Stack.Item>
                                            {/* If editing a new record and no data was found in the GL lookup. */}
                                            {isEditingNewRecord && apiCostCategoryConfigurationForGlAccount.callApiState === CallApiState.Completed && !apiCostCategoryConfigurationForGlAccount.costCategoryConfiguration && (
                                                <Stack.Item>
                                                    <Label>&nbsp;{/* Placeholder empty label for spacing. */}</Label>
                                                    <Text variant="medium" className={pageStyles.glLookupNoDataFound}>No data found.</Text>
                                                </Stack.Item>
                                            )}
                                            <Stack.Item>
                                                {/* If editing a new record and the lookup is complete. Or if not editing a new record. Then display this section. */}
                                                {((isEditingNewRecord && apiCostCategoryConfigurationForGlAccount.callApiState === CallApiState.Completed) || !isEditingNewRecord) && (
                                                    <>
                                                        <Separator />
                                                        <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                            <Stack.Item >
                                                                <Label htmlFor={readonlySubclassInputId}>{commonString.costCategorySubclass} <i>{commonString.readonly}</i></Label>
                                                                <TextField
                                                                    id={readonlySubclassInputId}
                                                                    autoComplete='off'
                                                                    ariaLabel={commonString.costCategorySubclass}
                                                                    className={pageStyles.costCategoryConfigLargeReadonlyTextField}
                                                                    value={`${editingCostCategoryConfiguration?.subclassCode || ''} - ${editingCostCategoryConfiguration?.subclassName}`}
                                                                    readOnly={true}
                                                                />
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <Label htmlFor={readonlyGroupNameInputId}>{commonString.costCategoryGroupName} <i>{commonString.readonly}</i></Label>
                                                                <TextField
                                                                    id={readonlyGroupNameInputId}
                                                                    autoComplete='off'
                                                                    ariaLabel={commonString.costCategoryGroupName}
                                                                    className={pageStyles.costCategoryConfigLargeReadonlyTextField}
                                                                    value={`${editingCostCategoryConfiguration?.groupNameCode || ''} - ${editingCostCategoryConfiguration?.groupNameDesc}`}
                                                                    readOnly={true}
                                                                />
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <Label htmlFor={readonlyLineItemInputId}>{commonString.costCategoryLineItem} <i>{commonString.readonly}</i></Label>
                                                                <TextField
                                                                    id={readonlyLineItemInputId}
                                                                    autoComplete='off'
                                                                    ariaLabel={commonString.costCategoryLineItem}
                                                                    className={pageStyles.costCategoryConfigLargeReadonlyTextField}
                                                                    value={`${editingCostCategoryConfiguration?.lineItemCode || ''} - ${editingCostCategoryConfiguration?.lineItemDesc}`}
                                                                    readOnly={true}
                                                                />
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <Label htmlFor={readonlyLineItemDetailInputId}>{commonString.costCategoryLineItemDetail} <i>{commonString.readonly}</i></Label>
                                                                <TextField
                                                                    id={readonlyLineItemDetailInputId}
                                                                    autoComplete='off'
                                                                    ariaLabel={commonString.costCategoryLineItemDetail}
                                                                    className={pageStyles.costCategoryConfigLargeReadonlyTextField}
                                                                    value={`${editingCostCategoryConfiguration?.lineItemDetailCode || ''} - ${editingCostCategoryConfiguration?.lineItemDetailDesc}`}
                                                                    readOnly={true}
                                                                />
                                                            </Stack.Item>
                                                        </Stack>
                                                        <Separator />
                                                    </>
                                                )}
                                            </Stack.Item>
                                        </>
                                    )}
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                    {/* The Short Name input shows for both Subclass Mapping and Specific GL Account. */}
                                    <Stack.Item>
                                        <Label htmlFor={shortNameInputId}>{commonString.costCategoryShortName}</Label>
                                        <TextField
                                            id={shortNameInputId}
                                            autoComplete='off'
                                            ariaLabel={`${commonString.costCategoryShortName} ${validationConstants.costCategoryShortName.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                            className={pageStyles.costCategoryConfigSmallTextField}
                                            value={editingCostCategoryConfiguration?.shortName}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                newValue = newValue || '';
                                                if (newValue.length > 0 && (newValue.length > validationConstants.costCategoryShortName.maxLength! ||
                                                    !RegExp(validationConstants.costCategoryShortName.pattern!).test(newValue))) {
                                                    setShortNameValidationError(validationConstants.costCategoryShortName.errorMsg!);
                                                } else {
                                                    setShortNameValidationError('');
                                                }
                                                setEditingCostCategoryConfiguration(prevState => {
                                                    return {
                                                        ...prevState!,
                                                        shortName: newValue
                                                    }
                                                });
                                            }}
                                            disabled={!isEditingNewRecord} // Only allow change for this field if editing a new record.
                                            errorMessage={shortNameValidationError}
                                        />
                                    </Stack.Item>

                                    {/* The Dedicated GL input shows for both Subclass Mapping and Specific GL Account. */}
                                    <Stack.Item>
                                        <Label htmlFor={dedicatedGlInputId}>{commonString.dedicatedGl}<span className={commonStyles.requiredFieldStar}>*</span></Label>
                                        <TextField
                                            id={dedicatedGlInputId}
                                            autoComplete='off'
                                            componentRef={(ref) => dedicatedGlInputRef.current = ref}
                                            ariaLabel={`${commonString.dedicatedGl} ${validationConstants.costCategoryDedicatedGl.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                            className={pageStyles.costCategoryConfigSmallTextField}
                                            value={editingCostCategoryConfiguration?.dedicatedGl ? String(editingCostCategoryConfiguration.dedicatedGl) : ''}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                newValue = newValue || '';
                                                if (newValue.length > 0 && (newValue.length > validationConstants.costCategoryDedicatedGl.maxLength! ||
                                                    !RegExp(validationConstants.costCategoryDedicatedGl.pattern!).test(newValue))) {
                                                    setDedicatedGlValidationError(validationConstants.costCategoryDedicatedGl.errorMsg!);
                                                } else {
                                                    setDedicatedGlValidationError('');
                                                }
                                                setEditingCostCategoryConfiguration(prevState => {
                                                    return {
                                                        ...prevState!,
                                                        dedicatedGl: newValue ? Number(newValue) : 0
                                                    }
                                                });
                                            }}
                                            // Always allow editing of Dedicated GL (the reclass to account) even for existing records.
                                            errorMessage={dedicatedGlValidationError}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            {!editDialogClosing /* Prevents showing the below briefly when dialog is closing and before the dialog disappears. */ && (
                                <>
                                    {!isEditingNewRecord && (
                                        <Stack.Item>
                                            While editing an existing record, you may modify only the Reclass To Account.
                                        </Stack.Item>
                                    )}
                                    {isDuplicateDetected && (
                                        <Stack.Item>
                                            <Text variant='medium' className={commonStyles.errorText}>Duplicate detected. There is already another record with this configuration.</Text>
                                        </Stack.Item>
                                    )}
                                </>
                            )}
                        </Stack>
                    </div>
                }
                mode={GenericDialogMode.OkCancel}
                disableOkButton={!isInputValid}
                onOkClicked={() => {
                    setEditDialogClosing(true);
                    // Note that we are saving editingCostCategoryConfiguration and not costCategoryConfigurationToEdit.
                    // The editingCostCategoryConfiguration is copied into local component state when editing starts.
                    if (editingCostCategoryConfiguration) {
                        const dataToSave: CostCategoryConfiguration = deepCopyObject(editingCostCategoryConfiguration);

                        // Depending on the selected config mode option key, use one of the following methods to prepare data for saving.
                        if (selectedConfigModeOptionKey === ConfigModeOptionKey.SubclassMapping) {
                            // Set the GL account field to undefined. This is used only for specific GL account.
                            dataToSave.glAccount = undefined;
                            dataToSave.glAccountDescription = undefined;

                            // If group name, line item, or line item detail is set to 'All', then change to undefined before saving.
                            if (dataToSave.groupNameCode === all) {
                                dataToSave.groupNameDesc = undefined;
                                dataToSave.groupNameCode = undefined;
                            }
                            if (dataToSave.lineItemCode === all) {
                                dataToSave.lineItemDesc = undefined;
                                dataToSave.lineItemCode = undefined;
                            }
                            if (dataToSave.lineItemDetailCode === all) {
                                dataToSave.lineItemDetailDesc = undefined;
                                dataToSave.lineItemDetailCode = undefined;
                            }
                        }

                        // No need to pass this to the API.
                        delete dataToSave.clientRowKey;

                        dispatch(callApiSaveCostCategoryConfiguration(dataToSave));
                    }
                    // The call to clear the cost category configuration being edited will be done in an effect for when the api
                    // completes (see useEffect for apiSaveCostCategoryConfiguration and call to dispatch(editCostCategoryConfiguration(undefined))).
                    toggleEditDialog();
                }}
                onCancelClicked={() => {
                    setEditDialogClosing(true);
                    // Clear the item being edited in Redux.
                    dispatch(editCostCategoryConfiguration(undefined));
                    toggleEditDialog();
                }}
            />

            <GenericDialog
                displayDialog={showDeleteConfirmDialog}
                title="Delete Confirmation"
                subText="Are you sure you want to delete this entry?"
                width={560}
                mode={GenericDialogMode.YesNo}
                onYesClicked={() => {
                    if (costCategoryConfigurationToDelete && costCategoryConfigurationToDelete.id !== undefined) {
                        dispatch(callApiDeleteCostCategoryConfiguration(costCategoryConfigurationToDelete.id));
                    }
                    // The call to clear the cost category configuration being deleted will be done in an effect for when the api
                    // completes (see useEffect for apiDeleteCostCategoryConfiguration and call to dispatch(deleteCostCategoryConfiguration(undefined))).
                    toggleDeleteConfirmDialog();
                }}
                onNoClicked={() => {
                    // Clear the item being deleted in Redux.
                    dispatch(deleteCostCategoryConfiguration(undefined));
                    toggleDeleteConfirmDialog();
                }}
            />
        </>
    );
};
