import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { editLineItemPercentageReceived } from '../../store/actions/pageActions/editPage.action';
import { commonString } from '../../common/commonString';
import { ActionButton, Slider, Stack } from '@fluentui/react';
import { pageStyles } from './EditPage.styles';
import { AppDispatch } from '../../store/reduxStore';
import { setFocusOnElemById } from '../../common/common.func.general';
import { stackTokensTinyGap } from '../../common/common.styles';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IPercentageReceivedInputProps {
    item: PurchaseOrderLineItem;
}

export const PercentageReceivedInput: React.FunctionComponent<IPercentageReceivedInputProps> = (props: IPercentageReceivedInputProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Stack horizontal tokens={stackTokensTinyGap} className={pageStyles.percentageReceivedSliderContainer}>
            <Stack.Item>
                <Slider
                    id={`percentageReceived-${props.item.purchaseOrderLineNumber}`}
                    ariaLabel={commonString.receivedPercentage}
                    className={pageStyles.percentageReceivedSlider}
                    value={props.item.percentageReceived}
                    min={0}
                    max={100}
                    showValue
                    valueFormat={(value: number) => `${value}%`}
                    onChange={(value: number, range?: [number, number], event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent) => {
                        // Check if the ctrl key was pressed with arrow right or left. This is to fix an accessibility issue where
                        // the focus gets trapped in the slider control. We need to allow for movement to the control in the grid row
                        // to the left or right, and we can escape the trap by using the ctrl key plus arrow key.
                        const kbdEvent: React.KeyboardEvent = event as React.KeyboardEvent;
                        if (kbdEvent.ctrlKey) {
                            if (kbdEvent.key === 'ArrowRight') {
                                // Set the focus to the received amount input, which is to the right.
                                setFocusOnElemById(`receivedAmount-${props.item.purchaseOrderLineNumber}`);
                            } else if (kbdEvent.key === 'ArrowLeft') {
                                // Set the focus to the line item action button, which is to the left.
                                setFocusOnElemById(`detailsButton-${props.item.purchaseOrderLineNumber}`);
                            }
                            return;
                        }
                        dispatch(editLineItemPercentageReceived(props.item.purchaseOrderLineNumber, value));
                    }}
                    disabled={props.item.checkToDisableEditing()}
                />
            </Stack.Item>
            <Stack.Item>
                <ActionButton
                    id={`set100-${props.item.purchaseOrderLineNumber}`}
                    className={pageStyles.actionButtonSet100}
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.serviceReceipt100PctButtonClicked }, {
                            po: props.item.purchaseOrderNumber, line: props.item.purchaseOrderLineNumber
                        });
                        dispatch(editLineItemPercentageReceived(props.item.purchaseOrderLineNumber, 100));
                    }}
                    text="Set 100%"
                    disabled={props.item.checkToDisableEditing() || props.item.percentageReceived === 100}
                />
            </Stack.Item>
        </Stack>
    );
};
