import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    InteractionRequiredAuthError,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest
} from '@azure/msal-browser';
import { appConfig } from '../../shell/appConfig';
import { initMsalConfig } from './msalConfig';

let msalInstance: PublicClientApplication;

/**
 * Initialize the MSAL instance.
 * Should only be called after the app config has loaded, because the msalConfig uses app config settings.
 * @returns The new MSAL instance.
 */
export const initMsalInstance = async (): Promise<PublicClientApplication> => {
    const msalConfig: Configuration = initMsalConfig();
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    return msalInstance;
};

/**
 * Get the MSAL instance that was initialized in initMsalInstance.
 * @returns MSAL instance.
 */
export const getMsalInstance = (): PublicClientApplication => {
    if (!msalInstance) {
        throw new Error('MSAL instance has not been initialized.');
    }
    return msalInstance;
};

/**
 * Get the active MSAL account.
 * @returns Active MSAL account.
 */
export const getActiveAccount = (): AccountInfo => {
    const account: AccountInfo | null = msalInstance.getActiveAccount();
    if (!account) {
        // If no account is active. Then either setActiveAccount was not called or the user is not signed in.
        console.log('No active account! Verify a user has been signed in and setActiveAccount has been called. Redirecting to login.');
        msalInstance.loginRedirect();
    }
    return account as AccountInfo;
};

/**
 * Get users AAD object id.
 * @returns Users AAD object id.
 */
export const getUserAadOid = (): string => {
    return getActiveAccount().localAccountId;
};

/**
 * Get users email.
 * @returns Users email.
 */
export const getUserEmail = (): string => {
    return getActiveAccount().username;
};

/**
 * Get users email alias.
 * @returns Users email alias.
 */
export const getUserAlias = (): string => {
    return getUserEmail().split('@')[0] || ''
};

/**
 * Get user full name.
 * @returns Users full name.
 */
export const getUserFullName = (): string => {
    return getActiveAccount().name || '';
};

/**
 * Get user first name.
 * @returns Users first name.
 */
export const getUserFirstName = (): string => {
    return getUserFullName().split(' ')[0];
};

/**
 * Get access token for the scope.
 * See: https://learn.microsoft.com/en-us/entra/identity-platform/scenario-spa-acquire-token?tabs=react
 */
export const getAccessTokenForScope = async (scope: string): Promise<string> => {
    const account: AccountInfo = getActiveAccount();

    try {
        const silentRequest: SilentRequest = {
            scopes: [scope],
            account: account
        };
        const authenticationResult: AuthenticationResult = await msalInstance.acquireTokenSilent(silentRequest);
        return authenticationResult.accessToken;
    } catch (ex) {
        if (ex instanceof InteractionRequiredAuthError) {
            const redirectRequest: RedirectRequest = {
                scopes: [scope]
            };
            msalInstance.acquireTokenRedirect(redirectRequest);
            return '';
        }
        throw ex;
    }
};

/**
 * Get access token for Graph API calls.
 */
export const getAccessTokenForGraph = async (): Promise<string> => {
    return getAccessTokenForScope(appConfig.current.msal.graphUserReadScope);
};

/**
 * Get access token for Receipting API calls.
 */
export const getAccessTokenForReceipting = async (): Promise<string> => {
    return getAccessTokenForScope(appConfig.current.msal.receiptingApiScope);
};

/**
 * Get access token for Shipment API calls.
 */
export const getAccessTokenForShipment = async (): Promise<string> => {
    return getAccessTokenForScope(appConfig.current.msal.shipmentApiScope);
};
