import React from 'react';
import {
    MessageBar,
    MessageBarType
} from '@fluentui/react';

/**
 * Clear errors by index.
 * @param errors Array of error strings.
 * @param index Index to clear.
 */
export const clearErrorByIndex = (errors: string[], index: number): string[] => {
    return [...errors.filter((v, i) => i !== index)];
};

/**
 * Prepare errors. Such as failed api calls.
 * @param friendlyMsg Friendly error message.
 * @param error Any error object. Could be an Error or AxiosError or string or plain object.
 */
export const prepErrorMsg = (friendlyMsg: string, error: any): string => {
    let fullMsg: string = friendlyMsg;

    let errorMsg: string;
    // If the error is an object with a message property (Error and AxiosError which derives from Error have this message).
    if (error.message) {
        errorMsg = error.message;
    } else {
        // Fallback is to stringify the error. But ideally this should not be allowed to happen.
        // If there are other kinds of error objects, add other if conditions above to extract the proper error message.
        errorMsg = JSON.stringify(error);
    }

    // If errorMsg is present, then append it in brackets.
    if (errorMsg) {
        fullMsg += ` [${errorMsg}]`;
    }

    return fullMsg;
};

/**
 * Error bar props.
 */
export interface IErrorBarProps {
    errors: string[];
    onDismiss?: (index: number) => void;
}

/**
 * Error bar.
 * @param props Error bar props.
 */
export const ErrorBar: React.FunctionComponent<IErrorBarProps> = (props: IErrorBarProps): JSX.Element => {
    /**
     * Dismiss message event handler.
     * @param index Index of message to dismiss.
     */
    const onDismissMessage = (index: number) => {
        if (props.onDismiss) {
            props.onDismiss(index);
        }
    };

    return (
        <>
            {
                props.errors && props.errors.length > 0 &&
                    <div style={{ marginBottom: '14px' }}>
                        {
                            props.errors.map((msg, index) => {
                                return (
                                    <MessageBar
                                        key={index}
                                        messageBarType={MessageBarType.error}
                                        isMultiline={true}
                                        onDismiss={() => onDismissMessage(index)}
                                        dismissButtonAriaLabel="Close">
                                        {msg}
                                    </MessageBar>
                                );
                            })
                        }
                    </div>
            }
        </>
    );
};
