import { appConfig } from '../../shell/appConfig';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiClientBase, ApiService } from './apiClientBase';
import { GraphUser } from '../../models/user/graphUser';
import { isNullOrUndefined } from '../../common/common.func.general';
import { JsonObjectFactory } from './jsonObjectFactory';

/**
 * Graph api client.
 * 
 * Microsoft Graph: https://developer.microsoft.com/en-us/graph/get-started/angular
 * Microsoft Graph Explorer: https://developer.microsoft.com/en-us/graph/graph-explorer
 */
class GraphApiClient extends ApiClientBase {
    private graphApiVersion: string = 'v1.0';

    /**
     * Constructor for the Graph api client.
     */
    constructor() {
        super(ApiService.Graph)
    }

    /**
     * Get the graph api endpoint for users.
     */
    private get graphApiEndpointUsers(): string {
        return `${appConfig.current.graph.baseUrl}/${this.graphApiVersion}/users/`
    }

    /**
     * Get the graph api endpoint for me.
     */
    private get graphApiEndpointMe(): string {
        return `${appConfig.current.graph.baseUrl}/${this.graphApiVersion}/me/`;
    }

    /**
     * Use the Microsoft Graph API to get users matching a display name.
     * @param partialName Partial name.
     * @param count Count of users to match.
     * @param fields Optional select added to graph call, such as 'id,alias,displayName,userPrincipalName' which limits fields returned.
     * @returns Observable array of graph users.
     */
    public async graphApiGetUsersMatchingName(partialName: string, count: number = 6, fields?: string): Promise<GraphUser[] | null> {
        try {
            // Example call:
            // https://graph.microsoft.com/v1.0/users/?$count=true&$filter=(startsWith(userPrincipalName,'jasonp') or startsWith(displayName,'jasonp')) and endsWith(userPrincipalName, 'microsoft.com')&$top=5
            // Note using endsWith(userPrincipalName, 'microsoft.com') rather than @microsoft.com because some users have this property ending with exchange.microsoft.com or some other subdomain.
            let apiUrl: string = `${this.graphApiEndpointUsers}?$count=true&$filter=(startsWith(userPrincipalName,'${partialName}') or startsWith(displayName,'${partialName}')) and endsWith(userPrincipalName,'microsoft.com')&$top=${count}`;
            if (fields) {
                apiUrl += `&$select=${fields}`;
            }
            const token = await this.getAccessToken();
            const requestConfig: AxiosRequestConfig = {
                headers: {
                    'Authorization': token,
                    'ConsistencyLevel': 'eventual'
                }
            }
            const response: AxiosResponse<any, any> = await axios.get(apiUrl, requestConfig);

            if (isNullOrUndefined(response.data) || isNullOrUndefined(response.data.value)) {
                return null;
            }
            // Need to get the value array and instantiate it.
            const instanceData: GraphUser[] | null = JsonObjectFactory.instantiateFromJsonArray(response.data.value, GraphUser);
            return instanceData;
        } catch (err: any) {
            console.error(err);
        }

        return null;
    }

    /**
     * Use the Microsoft Graph API to get users matching a display name.
     * @param alias User alias.
     * @param fields Optional select added to graph call, such as 'id,alias,displayName,userPrincipalName' which limits fields returned.
     * @returns Observable graph user.
     */
     public async graphApiGetUserMatchingAlias(alias: string, fields?: string): Promise<GraphUser | null> {
        try {
            // Example call:
            // https://graph.microsoft.com/v1.0/users/?$filter=startsWith(userPrincipalName,'scbaker@') and endsWith(userPrincipalName,'microsoft.com')&$count=true&$top=1
            // Note using endsWith(userPrincipalName, 'microsoft.com') rather than @microsoft.com because some users have this property ending with exchange.microsoft.com or some other subdomain.
            let apiUrl: string = `${this.graphApiEndpointUsers}?$filter=startsWith(userPrincipalName,'${alias}@') and endsWith(userPrincipalName,'microsoft.com')&$count=true&$top=1`;
            if (fields) {
                apiUrl += `&$select=${fields}`;
            }
            const token = await this.getAccessToken();
            const requestConfig: AxiosRequestConfig = {
                headers: {
                    'Authorization': token,
                    'ConsistencyLevel': 'eventual'
                }
            }
            const response: AxiosResponse<any, any> = await axios.get(apiUrl, requestConfig);

            if (isNullOrUndefined(response.data) || isNullOrUndefined(response.data.value) || response.data.value.length === 0) {
                return null;
            }

            // Need to get the value array and instantiate it.
            const instanceData: GraphUser | null = JsonObjectFactory.instantiateFromJson(response.data.value[0], GraphUser);
            return instanceData;
        } catch (err: any) {
            console.error(err);
        }

        return null;
    }

    /**
     * Get user photo.
     * This is not using any generic method in ApiClientBase as it requires special headers and error handling.
     * @param upn User principal name. If not supplied then it defaults to 'me'.
     */
    public async getUserPhoto(upn?: string): Promise<string> {
        try {
            // Example call:
            // https://graph.microsoft.com/v1.0/me/photo/$value"
            let apiUrl: string;
            if (upn) {
                apiUrl = `${this.graphApiEndpointUsers}${upn}/photo/$value`;
            } else {
                apiUrl = `${this.graphApiEndpointMe}photo/$value`;
            }
            const token = await this.getAccessToken();
            const requestConfig: AxiosRequestConfig = {
                headers: {
                    'Authorization': token,
                    'Accept': 'image/pjpeg',
                    'Content-Type': 'image/jpeg'
                },
                responseType: 'arraybuffer'
            }
            const response: AxiosResponse<any, any> = await axios.get(apiUrl, requestConfig);
            const bloblUrl: string = window.URL.createObjectURL(new Blob([response.data]));
            return bloblUrl;
        } catch (err: any) {
            // Some users don't have photos, in which case a 404 is returned. Log other errors.
            if (!err.response || err.response.status !== 404) {
                console.error(err);
            }
        }

        // Return empty string if no photo.
        return '';
    }
}

export const graphApiClient = new GraphApiClient();
