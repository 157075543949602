import { IChoiceGroupOption, IComboBoxOption } from '@fluentui/react';
import { PriorYearAccrualStatus } from '../../models/priorYearAccrual/priorYearAccrualStatus';
import { commonString } from '../../common/commonString';
import { ApprovalStatus } from '../../models/priorYearAccrual/priorYearAccrualSearch';

export enum PriorYearAccrualPivotItemKey {
    ForYou = 'ForYou',
    Elevated = 'Elevated', // For Finance Controller, Accruals Admin, Agent
    Administration = 'Administration'
}

export const all: string = 'All';

export const filterStatusForYouOptions: IComboBoxOption[] = [
    {
        key: all,
        text: all
    } as IComboBoxOption,
    {
        key: PriorYearAccrualStatus.Open,
        text: commonString.openAwaitingResponse
    } as IComboBoxOption,
    // Do not include Closed in this ForYou set.
    {
        key: PriorYearAccrualStatus.Retained,
        text: commonString.retaied
    } as IComboBoxOption
];

export const filterStatusElevatedOptions: IComboBoxOption[] = [
    {
        key: all,
        text: all
    } as IComboBoxOption,
    {
        key: PriorYearAccrualStatus.Open,
        text: commonString.openAwaitingResponse
    } as IComboBoxOption,
    {
        key: PriorYearAccrualStatus.Closed,
        text: commonString.closed
    } as IComboBoxOption,
    {
        key: PriorYearAccrualStatus.Retained,
        text: commonString.retaied
    } as IComboBoxOption
];

export const filterApprovalOptions: IComboBoxOption[] = [
    {
        key: all,
        text: all
    } as IComboBoxOption,
    {
        key: ApprovalStatus.YesOrApproved,
        text: commonString.approved
    } as IComboBoxOption,
    {
        key: ApprovalStatus.NoOrNotApproved,
        text: commonString.notApproved
    } as IComboBoxOption,
    {
        key: ApprovalStatus.PendingReview,
        text: commonString.pendingReview
    } as IComboBoxOption
];

export const filterAvailabilityOptions: IComboBoxOption[] = [
    {
        key: all,
        text: all
    } as IComboBoxOption,
    {
        key: ApprovalStatus.YesOrApproved,
        text: commonString.yes
    } as IComboBoxOption,
    {
        key: ApprovalStatus.NoOrNotApproved,
        text: commonString.no
    } as IComboBoxOption,
    {
        key: ApprovalStatus.PendingReview,
        text: commonString.pendingReview
    } as IComboBoxOption
];

export enum DisplayCurrencyKey {
    Local = 'local',
    Usd = 'usd'
}

export const displayCurrencyOptions: IComboBoxOption[] = [
    {
        key: DisplayCurrencyKey.Local,
        text: 'PO Currency'
    } as IComboBoxOption,
    {
        key: DisplayCurrencyKey.Usd,
        text: 'Constant Dollar (USD)'
    } as IComboBoxOption
];

export const includeOwnPosKey: string = 'includeOwnPos';

export const queryStringParams = {
    po: 'po', // Purchase order
    cc: 'cc', // Company code
    owner: 'owner', // PO owner
    fy: 'fy', // Fiscal year
    status: 'status', // Status
    cur: 'cur', // Display currency
    sup: 'sup', // Supplier number
    cfdc: 'cfdc', // Channel function detail codes
    efdc: 'efdc', // Executive function detail codes
    ccsc: 'ccsc', // Cost category subclass
    vf: 'vf', // View for / delegation
    corp: 'corp', // Corp approval
    stc: 'stc', // Statutory controller approval
    pp: 'pp', // POE/POD availability
    reg: 'reg' // Region
};

export enum ApprovalOptionKey {
    NotApproved = 'Not Approved',
    Approved = 'Approved'
}

export const approvalOptions: IChoiceGroupOption[] = [
    { key: ApprovalOptionKey.NotApproved, text: ApprovalOptionKey.NotApproved, ariaLabel: ApprovalOptionKey.NotApproved },
    { key: ApprovalOptionKey.Approved, text: ApprovalOptionKey.Approved, ariaLabel: ApprovalOptionKey.Approved }
];

export enum AvailabilityOptionKey {
    No = 'No',
    Yes = 'Yes'
}

export const availabilityOptions: IChoiceGroupOption[] = [
    { key: AvailabilityOptionKey.No, text: AvailabilityOptionKey.No, ariaLabel: AvailabilityOptionKey.No },
    { key: AvailabilityOptionKey.Yes, text: AvailabilityOptionKey.Yes, ariaLabel: AvailabilityOptionKey.Yes }
];

/**
 * Gets the current fiscal year.
 * @returns Current fiscal year.
 */
export const currentFiscalYear: () => number = () => {
    let currentFiscalYear: number;

    const now: Date = new Date();
    const month: number = now.getMonth();
    const year: number = now.getFullYear();
    if (month < 6) {
        currentFiscalYear = year;
    } else {
        currentFiscalYear = year + 1;
    }

    return currentFiscalYear;
}

/**
 * Gets all fiscal years from FY22 till the current fiscal year - 1. Don't include the current fiscal year.
 * @returns Fiscal years.
 */
export const getFiscalYearsFromFY22: () => number[] = () => {
    const fiscalYears: number[] = [];

    for (let year: number = 2022; year < currentFiscalYear(); year++) {
        fiscalYears.push(year);
    }

    return fiscalYears;
}

export const fiscalYearOptions: IComboBoxOption[] = [
    {
        key: all,
        text: all
    } as IComboBoxOption,
    ...getFiscalYearsFromFY22().map((year)=> {
        return {
            key: `FY${String(year).substring(2)}`,
            text: `FY${String(year).substring(2)}`
        } as IComboBoxOption;
    })
];

export const summaryDataColors = {
    default: 'rgb(0, 120, 212)',
    totalOpen: 'darkorange',
    totalRetained: 'green',
    totalClosed: 'purple'
};
