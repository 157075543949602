import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    videoContainerResponsive: {
        // See how to make a responsive player at https://www.npmjs.com/package/react-player
        position: 'relative',
        paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) = 56.25%. */
    } as IStyle,
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0
    } as IStyle,
    heading: {
        fontSize: '20px',
        fontWeight: 600,
        margin: 0,
        lineHeight: '30px'
    } as IStyle,
    videoSelectionLabel: {
        display: 'inline-block'
    } as IStyle,
    videoSelectionComboBox: {
        width: '450px',
        margin: '0 16px 0 8px',
        display: 'inline-block'
    } as IStyle
});
