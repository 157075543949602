import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import {
    IApiDaily1010Reports,
    IApiDailyNon1010Reports,
    IApiDailyOpenAccrual1010Reports,
    IApiDailyOpenAccrualNon1010Reports,
    IApiDailyPriorYearAccrualReports,
    IApiSgCheckReportAccess
} from '../../actions/pageActions/reportsPage.action';

export interface IReportInfo {
    displayName: string;
    description?: string;
    sgName: string;
    sgAlias: string;
    reportUrl: string;
    approvalRequiresBusinessJustification: boolean;
    isMember: boolean;
}

export interface IReportPageData {
    corUsage: IReportInfo;
    gsrUsage: IReportInfo;
    gsr1010Usage: IReportInfo;
    gsrPoReport: IReportInfo;
    goodsReceiptingSummary: IReportInfo;
    pyaNotificationsReport: IReportInfo;
    hasAccessToDaily1010Reports: boolean;
    hasAccessToDailyNon1010Reports: boolean;
    hasAccessToDailyOpenAccrual1010Reports: boolean;
    hasAccessToDailyOpenAccrualNon1010Reports: boolean;
    hasAccessToDailyPriorYearAccrualReports: boolean;
}

export interface IReportsPageReducerState {
    reportPageData: IReportPageData;
    apiSgCheckReportAccess: IApiSgCheckReportAccess;
    apiDaily1010Reports: IApiDaily1010Reports;
    apiDailyNon1010Reports: IApiDailyNon1010Reports;
    apiDailyOpenAccrual1010Reports: IApiDailyOpenAccrual1010Reports;
    apiDailyOpenAccrualNon1010Reports: IApiDailyOpenAccrualNon1010Reports;
    apiDailyPriorYearAccrualReports: IApiDailyPriorYearAccrualReports;
}

// The sgCheck api requires the friendly names of these groups, not the alias.
// Not all SGs are mail enabled and do not have an alias. Which is why the sgCheck api requires the name.
export const reportSecurityGroupName = {
    // Below are used for the daily report download tabs.
    daily1010ReportsSecurityGroup: 'Receipting GSR Usage Report',
    dailyNon1010ReportsSecurityGroup: 'Daily Non-1010 Reports',
    dailyOpenAccrual1010ReportsSecurityGroup: 'openaccrual1010',
    dailyOpenAccrualNon1010ReportsSecurityGroup: 'openaccrualnon1010',
    dailyPriorYearAccrualReportsSecurityGroups: ['openaccrual1010', 'openaccrualnon1010'], // This check uses two SGs.
    // Below are used for PowerBI reports.
    corUsageSecurityGroup: 'Receipting COR Usage Report',
    gsrUsageSecurityGroup: 'Receipting GSR Usage Report',
    gsr1010UsageSecurityGroup: 'Receipting GSR Usage Report',
    gsrPoReportSecurityGroup: 'FinOps DTS Reports GSR',
    goodsReceiptingSummarySecurityGroup: 'Receipting COR Usage Report',
    pyaNotificationsReportSecurityGroup: 'Receipting GSR Usage Report'
};

// Aliases for the mail enabled SGs that are used for PowerBI report access.
const reportSecurityGroupAlias = {
    corUsageSecurityGroup: 'corusage',
    gsrUsageSecurityGroup: 'gsrusage',
    gsr1010UsageSecurityGroup: 'gsrusage',
    gsrPoReportSecurityGroup: '', // This "FinOps DTS Reports GSR" SG is not mail enabled.
    goodsReceiptingSummarySecurityGroup: 'corusage',
    pyaNotificationsReportSecurityGroup: 'gsrusage'
};

const initialReportsPageReducerState: IReportsPageReducerState = {
    reportPageData: {
        corUsage: {
            displayName: 'Confirmation Of Receipt (COR) report',
            description: 'This report displays assets that have been received and includes Asset Tags and Serial Numbers when applicable. Filters are customizable and export to Excel is available.',
            sgName: reportSecurityGroupName.corUsageSecurityGroup,
            sgAlias: reportSecurityGroupAlias.corUsageSecurityGroup,
            reportUrl: 'https://aka.ms/correport',
            approvalRequiresBusinessJustification: false,
            isMember: false
        },
        gsrUsage: {
            displayName: 'Goods and Services Receipt (GSR) report for non-1010',
            description: 'This report displays the receipting status of non-1010 PO lines. Filters are customizable and export to Excel is available.',
            sgName: reportSecurityGroupName.gsrUsageSecurityGroup,
            sgAlias: reportSecurityGroupAlias.gsrUsageSecurityGroup,
            reportUrl: 'https://aka.ms/gsrreport',
            approvalRequiresBusinessJustification: false,
            isMember: false
        },
        gsr1010Usage: {
            displayName: 'Goods and Services Receipt (GSR) report for 1010',
            description: 'This report displays the receipting status of 1010 PO lines. Filters are customizable and export to Excel is available.',
            sgName: reportSecurityGroupName.gsr1010UsageSecurityGroup,
            sgAlias: reportSecurityGroupAlias.gsr1010UsageSecurityGroup,
            reportUrl: 'https://aka.ms/gsr1010report',
            approvalRequiresBusinessJustification: false,
            isMember: false
        },
        gsrPoReport: {
            displayName: 'Goods and Services Receipt (GSR) report for PO receipting status',
            description: 'This report is meant for controllers, business program managers, or individuals who need to monitor a group of POs and their receipting status. Access will not be granted to individual PO owners.',
            sgName: reportSecurityGroupName.gsrPoReportSecurityGroup,
            sgAlias: reportSecurityGroupAlias.gsrPoReportSecurityGroup,
            reportUrl: 'https://aka.ms/GSRPOManagement',
            approvalRequiresBusinessJustification: false,
            isMember: false
        },
        goodsReceiptingSummary: {
            displayName: 'Goods receipting summary',
            description: 'This report is a consolidated summary of the ASN (Advanced Shipment Notification) received by Microsoft from the suppliers and GR (Goods Receipt) done through different sources which include auto-cor. The report gives a comprehensive summary of data filtered by fiscal years.',
            sgName: reportSecurityGroupName.goodsReceiptingSummarySecurityGroup,
            sgAlias: reportSecurityGroupAlias.goodsReceiptingSummarySecurityGroup,
            reportUrl: 'https://aka.ms/GrSummaryReport',
            approvalRequiresBusinessJustification: false,
            isMember: false
        },
        pyaNotificationsReport: {
            displayName: 'Prior year accruals notifications report',
            description: 'This report provides details on the notifications sent for the accruals pending from prior years.',
            sgName: reportSecurityGroupName.gsrUsageSecurityGroup,
            sgAlias: reportSecurityGroupAlias.gsrUsageSecurityGroup,
            reportUrl: 'https://aka.ms/GsrPyaNotificationsReport',
            approvalRequiresBusinessJustification: false,
            isMember: false
        },
        hasAccessToDaily1010Reports: false,
        hasAccessToDailyNon1010Reports: false,
        hasAccessToDailyOpenAccrual1010Reports: false,
        hasAccessToDailyOpenAccrualNon1010Reports: false,
        hasAccessToDailyPriorYearAccrualReports: false
    },
    apiSgCheckReportAccess: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        results: undefined
    },
    apiDaily1010Reports: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reportFiles: undefined
    },
    apiDailyNon1010Reports: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reportFiles: undefined
    },
    apiDailyOpenAccrual1010Reports: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reportFiles: undefined
    },
    apiDailyOpenAccrualNon1010Reports: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reportFiles: undefined
    },
    apiDailyPriorYearAccrualReports: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reportFiles: undefined
    }
};

export const reportsPageReducer: Reducer<IReportsPageReducerState, AnyAction> = (
    state: IReportsPageReducerState = initialReportsPageReducerState, action: AnyAction
): IReportsPageReducerState => {
    switch (action.type) {
        case actionTypes.API_SGCHECK_REPORTS: {
            const payload: IApiSgCheckReportAccess = action.payload as IApiSgCheckReportAccess;

            const checkGroups = (groups: string[]) => {
                if (payload.results !== undefined) {
                    for (let i = 0; i < groups.length; i++) {
                        if (payload.results[groups[i]]) {
                            return true;
                        }
                    }
                }
                return false;
            };

            return {
                ...state,
                reportPageData: {
                    ...state.reportPageData,
                    hasAccessToDaily1010Reports: payload.results && payload.results[reportSecurityGroupName.daily1010ReportsSecurityGroup],
                    hasAccessToDailyNon1010Reports: payload.results && payload.results[reportSecurityGroupName.dailyNon1010ReportsSecurityGroup],
                    hasAccessToDailyOpenAccrual1010Reports: payload.results && payload.results[reportSecurityGroupName.dailyOpenAccrual1010ReportsSecurityGroup],
                    hasAccessToDailyOpenAccrualNon1010Reports: payload.results && payload.results[reportSecurityGroupName.dailyOpenAccrualNon1010ReportsSecurityGroup],
                    hasAccessToDailyPriorYearAccrualReports: payload.results && checkGroups(reportSecurityGroupName.dailyPriorYearAccrualReportsSecurityGroups),
                    corUsage: {
                        ...state.reportPageData.corUsage,
                        isMember: payload.results && payload.results[reportSecurityGroupName.corUsageSecurityGroup]
                    },
                    gsrUsage: {
                        ...state.reportPageData.gsrUsage,
                        isMember: payload.results && payload.results[reportSecurityGroupName.gsrUsageSecurityGroup]
                    },
                    gsr1010Usage: {
                        ...state.reportPageData.gsr1010Usage,
                        isMember: payload.results && payload.results[reportSecurityGroupName.gsr1010UsageSecurityGroup]
                    },
                    gsrPoReport: {
                        ...state.reportPageData.gsrPoReport,
                        isMember: payload.results && payload.results[reportSecurityGroupName.gsrPoReportSecurityGroup]
                    },
                    goodsReceiptingSummary: {
                        ...state.reportPageData.goodsReceiptingSummary,
                        isMember: payload.results && payload.results[reportSecurityGroupName.goodsReceiptingSummarySecurityGroup]
                    },
                    pyaNotificationsReport: {
                        ...state.reportPageData.pyaNotificationsReport,
                        isMember: payload.results && payload.results[reportSecurityGroupName.pyaNotificationsReportSecurityGroup]
                    }
                },
                apiSgCheckReportAccess: { ...payload }
            } as IReportsPageReducerState
        }
        case actionTypes.API_DAILY_1010_REPORTS: {
            const payload: IApiDaily1010Reports = action.payload as IApiDaily1010Reports;
            return {
                ...state,
                apiDaily1010Reports: { ...payload }
            } as IReportsPageReducerState;
        }
        case actionTypes.API_DAILY_NON_1010_REPORTS: {
            const payload: IApiDailyNon1010Reports = action.payload as IApiDailyNon1010Reports;
            return {
                ...state,
                apiDailyNon1010Reports: { ...payload }
            } as IReportsPageReducerState;
        }
        case actionTypes.API_DAILY_OPEN_ACCRUAL_1010_REPORTS: {
            const payload: IApiDailyOpenAccrual1010Reports = action.payload as IApiDailyOpenAccrual1010Reports;
            return {
                ...state,
                apiDailyOpenAccrual1010Reports: { ...payload }
            } as IReportsPageReducerState;
        }
        case actionTypes.API_DAILY_OPEN_ACCRUAL_NON_1010_REPORTS: {
            const payload: IApiDailyOpenAccrualNon1010Reports = action.payload as IApiDailyOpenAccrualNon1010Reports;
            return {
                ...state,
                apiDailyOpenAccrualNon1010Reports: { ...payload }
            } as IReportsPageReducerState;
        }
        case actionTypes.API_DAILY_PRIOR_YEAR_ACCRUAL_REPORTS: {
            const payload: IApiDailyPriorYearAccrualReports = action.payload as IApiDailyPriorYearAccrualReports;
            return {
                ...state,
                apiDailyPriorYearAccrualReports: { ...payload }
            } as IReportsPageReducerState;
        }
        default:
    }

    return state;
};
