import { SalesDistrict } from './salesDistrict';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface ISalesSubLocation extends IHierarchyNodeCommon {
    salesDistrict: SalesDistrict[];
}

export class SalesSubLocation implements ISalesSubLocation {
    public typeName: string = HierarchyConstants.className.geography.salesSubLocation;
    public code: string;
    public description: string;
    public salesDistrict: SalesDistrict[] = [];
    public children?: IHierarchyNodeCommon[] = this.salesDistrict; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ISalesSubLocation) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.salesDistrict) {
            for (let i: number = 0; i < jsonData.salesDistrict.length; i++) {
                this.salesDistrict.push(new SalesDistrict(jsonData.salesDistrict[i]));
            }
        }
    }
}
