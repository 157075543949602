import { SalesSubLocation } from './salesSubLocation';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface ISalesLocation extends IHierarchyNodeCommon {
    salesSubLocation: SalesSubLocation[];
}

export class SalesLocation implements ISalesLocation {
    public typeName: string = HierarchyConstants.className.geography.salesLocation;
    public code: string;
    public description: string;
    public salesSubLocation: SalesSubLocation[] = [];
    public children?: IHierarchyNodeCommon[] = this.salesSubLocation; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ISalesLocation) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.salesSubLocation) {
            for (let i: number = 0; i < jsonData.salesSubLocation.length; i++) {
                this.salesSubLocation.push(new SalesSubLocation(jsonData.salesSubLocation[i]));
            }
        }
    }
}
