import { AnyAction, Reducer } from 'redux';
import { deepCopyObject } from '../../../common/common.func.general';
import { createShortGuid } from '../../../common/common.func.guid';
import { UserDelegate } from '../../../models/user/userDelegate';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiReloadUserProfile, IApiSaveUserProfile, IEditDelegateTo, IUserProfilePageData } from '../../actions/pageActions/userProfilePage.action';

export interface IUserProfilePageReducerState {
    userProfilePageData: IUserProfilePageData;
    apiReloadUserProfile: IApiReloadUserProfile;
    apiSaveUserProfile: IApiSaveUserProfile;
}

const initialUserProfilePageReducerState: IUserProfilePageReducerState = {
    userProfilePageData: {
        delegateToArray: undefined
    },
    apiReloadUserProfile: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        userProfile: undefined
    },
    apiSaveUserProfile: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
}

/**
 * Helper function to copy and modify the delegate to array.
 * @param editDelegateTo Delegate to that is being edited. To be 
 * @param delegateToArray Delegate to array.
 * @param modifyCallback Modify callback that will be called for the copy of the editDelegateTo found in the delegateToArray.
 * @returns Modified copy of the delegate to array.
 */
const copyAndModify = (editDelegateTo: IEditDelegateTo, delegateToArray: UserDelegate[] | undefined, modifyCallback: (userDelegateCopy: UserDelegate) => void): UserDelegate[] => {
    // Create a new array of line item objects using the array spread operator. Note the objects themselves
    // are the same instances, just put into a new array.
    const itemsCopy: UserDelegate[] = [...delegateToArray || []];

    const index: number = itemsCopy.findIndex(
        x => x.clientRowKey === editDelegateTo.clientRowKey
    );
    if (index > -1) {
        const userDelegate: UserDelegate = itemsCopy[index];
        // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
        // First make a copy of the object and replace it in the array. Then make mutations to the copy.
        const userDelegateCopy: UserDelegate = deepCopyObject(userDelegate);

        modifyCallback(userDelegateCopy);

        itemsCopy[index] = userDelegateCopy;
    }

    return itemsCopy;
}

export const userProfilePageReducer: Reducer<IUserProfilePageReducerState, AnyAction> = (
    state: IUserProfilePageReducerState = initialUserProfilePageReducerState, action: AnyAction
): IUserProfilePageReducerState => {
    switch (action.type) {
        case actionTypes.USERPROFILE_STORE_PAGEDATA: {
            const userProfilePageData: IUserProfilePageData = action.userProfilePageData as IUserProfilePageData;
            return {
                ...state,
                userProfilePageData: { ...userProfilePageData }
            } as IUserProfilePageReducerState
        }
        case actionTypes.EDIT_DELEGATE_TO: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = copyAndModify(editDelegateTo, state.userProfilePageData.delegateToArray, (userDelegateCopy) => {
                userDelegateCopy.alias = editDelegateTo.alias || '';
                userDelegateCopy.aadOid = editDelegateTo.aadOid || '';
            });

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.EDIT_DELEGATE_TO_VALID: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = copyAndModify(editDelegateTo, state.userProfilePageData.delegateToArray, (userDelegateCopy) => {
                userDelegateCopy.isValid = editDelegateTo.isValid;
            });

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.DELETE_DELEGATE_TO: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = [...state.userProfilePageData.delegateToArray || []];

            const index: number = itemsCopy.findIndex(
                x => x.clientRowKey === editDelegateTo.clientRowKey
            );
            if (index > -1) {
                itemsCopy.splice(index, 1);
            }

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.ADD_DELEGATE_TO: {
            const itemsCopy: UserDelegate[] = [...state.userProfilePageData.delegateToArray || []];

            const startDate: Date = new Date();
            startDate.setHours(0, 0, 0, 0); // Start of day.
            const endDate: Date = new Date();
            endDate.setHours(23, 59, 59, 0); // End of day.
            endDate.setMonth(startDate.getMonth() + 12);

            const userDelegate = new UserDelegate({
                aadOid: '',
                alias: '',
                accessRights: [],
                companyCodes: [],
                startDate: startDate,
                endDate: endDate
            })
            userDelegate.clientRowKey = createShortGuid();
            itemsCopy.push(userDelegate);

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.EDIT_DELEGATE_TO_ACCESSRIGHTS: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = copyAndModify(editDelegateTo, state.userProfilePageData.delegateToArray, (userDelegateCopy) => {
                userDelegateCopy.accessRights = editDelegateTo.accessRights || [];
            });

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.EDIT_DELEGATE_TO_COMPANYCODES: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = copyAndModify(editDelegateTo, state.userProfilePageData.delegateToArray, (userDelegateCopy) => {
                userDelegateCopy.companyCodes = editDelegateTo.companyCodes || [];
            });

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.EDIT_DELEGATE_TO_STARTDATE: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = copyAndModify(editDelegateTo, state.userProfilePageData.delegateToArray, (userDelegateCopy) => {
                if (editDelegateTo.startDate) {
                    userDelegateCopy.startDate = editDelegateTo.startDate;
                }
            });

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.EDIT_DELEGATE_TO_ENDDATE: {
            const editDelegateTo: IEditDelegateTo = action.editDelegateTo as IEditDelegateTo;

            const itemsCopy: UserDelegate[] = copyAndModify(editDelegateTo, state.userProfilePageData.delegateToArray, (userDelegateCopy) => {
                if (editDelegateTo.endDate) {
                    userDelegateCopy.endDate = editDelegateTo.endDate;
                }
            });

            return {
                ...state,
                userProfilePageData: {
                    ...state.userProfilePageData,
                    delegateToArray: itemsCopy
                } as IUserProfilePageData
            } as IUserProfilePageReducerState
        }
        case actionTypes.API_RELOAD_USER_PROFILE: {
            const payload: IApiReloadUserProfile = action.payload as IApiReloadUserProfile;
            return {
                ...state,
                apiReloadUserProfile: { ...payload }
            } as IUserProfilePageReducerState;
        }
        case actionTypes.API_SAVE_USER_PROFILE: {
            const payload: IApiSaveUserProfile = action.payload as IApiSaveUserProfile;
            return {
                ...state,
                apiSaveUserProfile: { ...payload }
            } as IUserProfilePageReducerState
        }
        
        default:
    }

    return state;
};
