import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import {
    Pivot,
    PivotItem,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    callApiSgCheckReportAccess,
    IApiSgCheckReportAccess
} from '../../store/actions/pageActions/reportsPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { IReportPageData } from '../../store/reducers/pageReducers/reportsPage.reducer';
import { PowerBiReportsTab } from './PowerBiReportsTab';
import { DailyReportsTab } from './DailyReportsTab';
import { AppDispatch } from '../../store/reduxStore';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { teachingBubbleClearArray } from '../../store/actions/app.action';

interface IPageProps extends ICommonPageProps {
}

export const ReportsPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    // Redux store selectors to get state from the store when it changes.
    const reportPageData: IReportPageData =
        useAppSelector<IReportPageData>((state) => state.reportsPageReducer.reportPageData);
    const apiSgCheckReportAccess: IApiSgCheckReportAccess =
        useAppSelector<IApiSgCheckReportAccess>((state) => state.reportsPageReducer.apiSgCheckReportAccess);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiSgCheckReportAccess.errMsg) {
            handleError(apiSgCheckReportAccess.errMsg);
        }
    }, [apiSgCheckReportAccess.errMsg, handleError]);

    /**
     * Memoized value to determine if data is loading.
     * @returns True if data is loading, otherwise false.
     */
    const isLoading = useMemo<boolean>((): boolean => {
        if (apiSgCheckReportAccess.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSgCheckReportAccess.callApiState]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Call to check SG memberships only if not already done so.
        if (apiSgCheckReportAccess.callApiState !== CallApiState.Completed) {
            dispatch(callApiSgCheckReportAccess());
        }
    });

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <SectionWrapper>
                {isLoading && (
                    <Section>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Section>
                )}
                {!isLoading && (
                    <Section>
                        <Stack tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Pivot aria-label="Reports">
                                    <PivotItem headerText="Power BI Reports">
                                        <PowerBiReportsTab />
                                    </PivotItem>
                                    {reportPageData.hasAccessToDaily1010Reports && (
                                        <PivotItem headerText="Daily 1010 Reports">
                                            <DailyReportsTab
                                                mode="1010"
                                            />
                                        </PivotItem>
                                    )}
                                    {reportPageData.hasAccessToDailyNon1010Reports && (
                                        <PivotItem headerText="Daily non-1010 Reports">
                                            <DailyReportsTab
                                                mode="non1010"
                                            />
                                        </PivotItem>
                                    )}
                                    {reportPageData.hasAccessToDailyOpenAccrual1010Reports && (
                                        <PivotItem headerText="Daily 1010 Open Accrual Reports">
                                            <DailyReportsTab
                                                mode="1010oa"
                                            />
                                        </PivotItem>
                                    )}
                                    {reportPageData.hasAccessToDailyOpenAccrualNon1010Reports && (
                                        <PivotItem headerText="Daily non-1010 Open Accrual Reports">
                                            <DailyReportsTab
                                                mode="non1010oa"
                                            />
                                        </PivotItem>
                                    )}
                                    {reportPageData.hasAccessToDailyPriorYearAccrualReports && (
                                        <PivotItem headerText="Daily Prior Year Accrual Reports">
                                            <DailyReportsTab
                                                mode="pya"
                                            />
                                        </PivotItem>
                                    )}
                                </Pivot>
                            </Stack.Item>
                        </Stack>
                    </Section>
                )}
            </SectionWrapper>
        </PageWrapper>
    );
};
