import React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { hideDetailsForLineItem } from '../../store/actions/pageActions/editPage.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { AppDispatch } from '../../store/reduxStore';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';

interface ILineItemDetailsPanelProps {
}

/**
 * Line item details panel.
 * This panel is used on the edit and close line pages.
 * Note that the prior year accruals line item has its own similar panel - see PyaLineItemDetailsPanel - which displays
 * different data.
 * @param props Component props.
 * @returns JSX for component.
 */
export const LineItemDetailsPanel: React.FunctionComponent<ILineItemDetailsPanelProps> = (props: ILineItemDetailsPanelProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const showDetailsForLineItem: PurchaseOrderLineItem | undefined =
        useAppSelector<PurchaseOrderLineItem | undefined>((state) => state.editPageReducer.showDetailsForLineItem);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Panel
            headerText={`Details for ${showDetailsForLineItem?.purchaseOrderNumber}-${showDetailsForLineItem?.purchaseOrderLineNumber}`}
            isOpen={showDetailsForLineItem !== undefined}
            onDismiss={() => {
                setTimeout(() => {
                    dispatch(hideDetailsForLineItem());
                });
            }}
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
            type={PanelType.medium}
        >
            <table className={commonStyles.detailsTable}>
                <tbody>
                    <tr>
                        <td>Delivery Date</td>
                        <td>{formatDateUsingLocale(showDetailsForLineItem?.deliveryDate)}</td>
                    </tr>
                    <tr>
                        <td>Line Description</td>
                        <td>{showDetailsForLineItem?.description}</td>
                    </tr>
                    <tr>
                        <td>Purchase Quantity</td>
                        <td>{showDetailsForLineItem?.totalQuantity}</td>
                    </tr>
                    <tr>
                        <td>Unit Price</td>
                        <td>{`${numberAsLocaleString(showDetailsForLineItem?.unitPrice, showDetailsForLineItem?.currencyFractionalPart)} ${showDetailsForLineItem?.currency}`}</td>
                    </tr>
                    <tr>
                        <td>Line Total</td>
                        <td>{`${numberAsLocaleString(showDetailsForLineItem?.lineTotal, showDetailsForLineItem?.currencyFractionalPart)} ${showDetailsForLineItem?.currency}`}</td>
                    </tr>
                    <tr>
                        <td>Invoiced Quantity</td>
                        <td>{showDetailsForLineItem?.invoicedQuantity}</td>
                    </tr>
                    <tr>
                        <td>Open Quantity</td>
                        <td>{showDetailsForLineItem?.openQuantity}</td>
                    </tr>
                    <tr>
                        <td>Received Quantity</td>
                        {/*
                            Using original state received quantity so that when user changes the quantity in the UI
                            for an item it does not reflect here immediately. This is to show the last received quantity
                            amount we had stored.
                        */}
                        <td>{showDetailsForLineItem?.originalState?.receivedQuantity}</td>
                    </tr>
                    <tr>
                        <td>Invoiced Amount</td>
                        <td>{`${numberAsLocaleString(showDetailsForLineItem?.invoicedAmount, showDetailsForLineItem?.currencyFractionalPart)} ${showDetailsForLineItem?.currency}`}</td>
                    </tr>
                    <tr>
                        <td>Open Amount</td>
                        <td>{`${numberAsLocaleString(showDetailsForLineItem?.openAmount, showDetailsForLineItem?.currencyFractionalPart)} ${showDetailsForLineItem?.currency}`}</td>
                    </tr>
                    <tr>
                        <td>Original Amount</td>
                        <td>{`${numberAsLocaleString(showDetailsForLineItem?.originalAmount, showDetailsForLineItem?.currencyFractionalPart)} ${showDetailsForLineItem?.currency}`}</td>
                    </tr>
                    <tr>
                        <td>Amount To Accrue</td>
                        <td>{showDetailsForLineItem?.accruedAmount}</td>
                    </tr>
                    <tr>
                        <td>Cost Center/IO</td>
                        <td>{showDetailsForLineItem?.costCenterInternalOrder}</td>
                    </tr>
                    <tr>
                        <td>GL Account</td>
                        <td>{showDetailsForLineItem?.glAccount}</td>
                    </tr>
                    <tr>
                        <td>Purchase Classification</td>
                        <td>{showDetailsForLineItem?.purchasingClassification}</td>
                    </tr>
                    <tr>
                        <td>Profit Center</td>
                        <td>{showDetailsForLineItem?.profitCenterCode}</td>
                    </tr>
                    <tr>
                        <td>Company Code</td>
                        <td>{showDetailsForLineItem?.companyCode}</td>
                    </tr>
                    <tr>
                        {/* Note that we use the word supplier rather than vendor, but the model still uses the word vendor. */}
                        <td>Supplier Number</td>
                        <td>{showDetailsForLineItem?.vendorNumber}</td>
                    </tr>
                    <tr>
                        <td>Document Type</td>
                        <td>{showDetailsForLineItem?.docType}</td>
                    </tr>
                    <tr>
                        <td>Account Assignment Code	</td>
                        <td>{showDetailsForLineItem?.accountAssignment}</td>
                    </tr>
                </tbody>
            </table>
        </Panel>
    );
};
