import { AnyAction } from 'redux';
import { TeachingBubbleItem } from '../../components/CustomTeachingBubble/CustomTeachingBubble';
import { ClientNotificationData } from '../../models/clientNotification/clientNotificationData';
import { Company } from '../../models/domain/company';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { UserProfile } from '../../models/user/userProfile';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { AppDispatch } from '../reduxStore';
import * as actionTypes from './actionTypes';
import { callApi, ICallApiBase } from './generic.action';
import { Hierarchy } from '../../models/hierarchy/hierarchy';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { IHierarchyTreeItemState } from '../../components/HierarchySelection/IHierarchyTreeItemState';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';
import { InitialVideo } from '../../shell/VideoGuide/VideoGuide';
import { CopilotPanelOpenState } from '../reducers/app.reducer';

/**
 * Load user profile action payload.
 */
export interface IApiLoadUserProfile extends ICallApiBase {
    userProfile?: UserProfile | undefined;
}

/**
 * Load user profile.
 * @param alias User alias.
 * @returns Redux dispatch function.
 */
export const callApiLoadUserProfile = (alias: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<UserProfile | undefined>(
        actionTypes.API_LOAD_USER_PROFILE,
        async () => {
            return await receiptingApiClient.loadUserProfile(alias) || undefined;
        },
        (payload: IApiLoadUserProfile, data) => {
            payload.userProfile = data || undefined;
        }
    );
};

/**
 * Load companies action payload.
 */
export interface IApiLoadCompanies extends ICallApiBase {
    companies?: Company[] | undefined;
}

/**
 * Load companies.
 * @returns Redux dispatch function.
 */
export const callApiLoadCompanies = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<Company[] | null>(
        actionTypes.API_LOAD_COMPANIES,
        async () => {
            return await receiptingApiClient.loadCompanies() || null;
        },
        (payload: IApiLoadCompanies, data) => {
            payload.companies = data || undefined;
        }
    );
};

/**
 * Existing notifications action payload.
 */
export interface IApiExistingNotifications extends ICallApiBase {
    clientNotificationData?: ClientNotificationData | undefined;
}

/**
 * Gets existing client notifications.
 * @param isPreExisting Set to true to indicate the notification item was pre-existing when the app loaded.
 * This is used to differentiate from existing notifications that the user already had when the app loaded versus
 * new ones that come in via SignalR or another call to this callApiExistingNotifications. If a notification is
 * marked as true for isPreExisting, then do not change it to false later in another call to this action.
 * The intention is to only update line items in the UI in the LineItemStatusIndicator when notifications come in
 * while the app is running.
 * @returns Redux dispatch function.
 */
export const callApiExistingNotifications = (isPreExisting: boolean): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ClientNotificationData | null>(
        actionTypes.API_EXISTING_NOTIFICATIONS,
        async () => {
            return await receiptingApiClient.existingNotifications() || null;
        },
        (payload: IApiExistingNotifications, data) => {
            payload.clientNotificationData = data || undefined;
            // If isPreExisting is true then set this to true on all items.
            if (isPreExisting) {
                payload.clientNotificationData?.items.forEach((item: ClientNotificationItem) => {
                    item.isPreExisting = isPreExisting;
                });
            }
        }
    );
};

/**
 * Dismiss notification action payload.
 */
export interface IApiDismissNotification extends ICallApiBase {
    notificationId?: string;
}

/**
 * Dismiss a notification.
 * @param notificationId Notification id to dismiss.
 * @returns Redux dispatch function.
 */
export const callApiDismissNotification = (notificationId: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DISMISS_NOTIFICATION,
        async () => {
            return await receiptingApiClient.dismissNotification(notificationId) || null;
        },
        (payload: IApiDismissNotification, data) => {
            payload.notificationId = notificationId;
        }
    );
};

/**
 * Dismiss all notifications action payload.
 */
export interface IApiDismissAllNotifications extends ICallApiBase {
    clientNotificationItemState?: ClientNotificationItemState;
}

/**
 * Dismiss all notifications.
 * @param clientNotificationItemState Client notification item state.
 * @returns Redux dispatch function.
 */
export const callApiDismissAllNotifications = (clientNotificationItemState?: ClientNotificationItemState): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ClientNotificationData | null>(
        actionTypes.API_DISMISS_ALL_NOTIFICATIONS,
        async () => {
            return await receiptingApiClient.dismissAllNotifications(clientNotificationItemState) || null;
        },
        (payload: IApiDismissAllNotifications, data) => {
            payload.clientNotificationItemState = clientNotificationItemState;
        }
    );
};

/**
 * Action creator: Upsert notification item.
 * Update or add a notification item.
 * @param clientNotificationItem Client notification item.
 * @returns Redux action.
 */
export const upsertNotificationItem = (clientNotificationItem: ClientNotificationItem) => {
    return {
        type: actionTypes.UPSERT_NOTIFICATION_ITEM,
        clientNotificationItem: clientNotificationItem
    } as AnyAction;
};

/**
 * Action creator: Trigger open notification panel if it is not already open.
 * @param isNotificationPanelOpen True to show notification panel. False otherwise.
 * @returns Redux action.
 */
export const showNotificationPanel = (isNotificationPanelOpen: boolean) => {
    return {
        type: actionTypes.SHOW_NOTIFICATION_PANEL,
        isNotificationPanelOpen: isNotificationPanelOpen
    } as AnyAction;
};

/**
 * Action creator: Teaching bubble show.
 * @param teachingBubbleItem Teaching bubble item.
 * @returns Redux action.
 */
export const teachingBubbleShow = (teachingBubbleItem: TeachingBubbleItem) => {
    return {
        type: actionTypes.TEACHING_BUBBLE_SHOW,
        teachingBubbleItem: teachingBubbleItem
    } as AnyAction;
};

/**
 * Action creator: Teaching bubble got it.
 * @param teachingBubbleItem Teaching bubble item.
 * @returns Redux action.
 */
export const teachingBubbleGotIt = (teachingBubbleItem: TeachingBubbleItem) => {
    return {
        type: actionTypes.TEACHING_BUBBLE_GOTIT,
        teachingBubbleItem: teachingBubbleItem
    } as AnyAction;
};

/**
 * Action creator: Teaching bubble clear array.
 * @returns Redux action.
 */
export const teachingBubbleClearArray = () => {
    return {
        type: actionTypes.TEACHING_BUBBLE_CLEAR_ARRAY
    } as AnyAction;
};

/**
 * Payload used with callApiLoadHierarchy action.
 */
export interface IApiLoadHierarchy extends ICallApiBase {
    hierarchy?: Hierarchy | null;
}

/**
 * Call API to load hierarchy.
 * @returns Redux dispatch function.
 */
export const callApiLoadHierarchy = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<Hierarchy | null>(
        actionTypes.API_LOAD_HIERARCHY,
        async () => {
            return await receiptingApiClient.loadHierarchy();
        },
        (payload: IApiLoadHierarchy, data: Hierarchy | null) => {
            payload.hierarchy = data;
        }
    );
};

/**
 * Action creator: Store finance geography hierarchy tree item states.
 * @param instanceId Instance id of the stored state. Helps with multiple instances of a component that uses the state.
 * @param hierarchyTreeItemStates Hierarchy tree item states.
 * @returns Redux action.
 */
export const storeFinanceGeographyHierarchyTreeItemStates = (instanceId: string, hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>): AnyAction => {
    return {
        type: actionTypes.STORE_FINANCE_GEOGRAPHY_HIERARCHY_TREE_ITEM_STATES,
        instanceId: instanceId,
        financeGeographyHierarchyTreeItemStates: hierarchyTreeItemStates
    } as AnyAction;
};

/**
 * Action creator: Store channel function hierarchy tree item states.
 * @param instanceId Instance id of the stored state. Helps with multiple instances of a component that uses the state.
 * @param hierarchyTreeItemStates Hierarchy tree item states.
 * @returns Redux action.
 */
export const storeChannelFunctionHierarchyTreeItemStates = (instanceId: string, hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>): AnyAction => {
    return {
        type: actionTypes.STORE_CHANNEL_FUNCTION_HIERARCHY_TREE_ITEM_STATES,
        instanceId: instanceId,
        channelFunctionHierarchyTreeItemStates: hierarchyTreeItemStates
    } as AnyAction;
};

/**
 * Action creator: Store executive function hierarchy tree item states.
 * @param instanceId Instance id of the stored state. Helps with multiple instances of a component that uses the state.
 * @param hierarchyTreeItemStates Hierarchy tree item states.
 * @returns Redux action.
 */
export const storeExecutiveFunctionHierarchyTreeItemStates = (instanceId: string, hierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>): AnyAction => {
    return {
        type: actionTypes.STORE_EXECUTIVE_FUNCTION_HIERARCHY_TREE_ITEM_STATES,
        instanceId: instanceId,
        executiveFunctionHierarchyTreeItemStates: hierarchyTreeItemStates
    } as AnyAction;
};

/**
 * Action creator: Clear hierarchy state for the instance.
 * @param instanceId Instance id of the stored state. Helps with multiple instances of a component that uses the state.
 * @returns Redux action.
 */
export const clearHierarchyStateInstance = (instanceId: string) => {
    return {
        type: actionTypes.CLEAR_HIERARCHY_STATE_INSTANCE,
        instanceId: instanceId
    } as AnyAction;
};

/**
 * Action creator: Set copilot panel open state.
 * @param copilotPanelOpenState Copilot panel open state.
 * @returns Redux action.
 */
export const setCopilotPanelOpenState = (copilotPanelOpenState: CopilotPanelOpenState): AnyAction => {
    return {
        type: actionTypes.COPILOT_PANEL_OPEN_STATE,
        copilotPanelOpenState: copilotPanelOpenState
    } as AnyAction;
};

/**
 * Action creator: Set nav is open.
 * @param isOpen Is open.
 * @returns Redux action.
 */
export const setNavIsOpen = (isOpen: boolean): AnyAction => {
    return {
        type: actionTypes.NAV_IS_OPEN,
        navIsOpen: isOpen
    } as AnyAction;
};

/**
 * Action creator: Show video guide.
 * @param initialVideo Initial video.
 * @returns Redux action.
 */
export const showVideoGuide = (initialVideo?: InitialVideo): AnyAction => {
    return {
        type: actionTypes.SHOW_VIDEO_GUIDE,
        initialVideo: initialVideo
    } as AnyAction;
};

/**
 * Action creator: Dismiss video guide.
 * @returns Redux action.
 */
export const dismissVideoGuide = (): AnyAction => {
    return {
        type: actionTypes.DISMISS_VIDEO_GUIDE
    } as AnyAction;
};
