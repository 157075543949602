import React from 'react';
import { useId } from '@fluentui/react-hooks';
import { useAppDispatch } from '../../store/hooks';
import { commonString } from '../../common/commonString';
import { DatePicker } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { UserDelegate } from '../../models/user/userDelegate';
import { editDelegateToStartDate } from '../../store/actions/pageActions/userProfilePage.action';

interface IDelegateToStartDateInputProps {
    item: UserDelegate;

    /**
     * Optional input id. If not supplied then one will be generated.
     */
    inputId?: string;
}

export const DelegateToStartDateInput: React.FunctionComponent<IDelegateToStartDateInputProps> = (props: IDelegateToStartDateInputProps): JSX.Element => {
    const inputId: string = useId();
    
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <DatePicker
            id={props.inputId || inputId}
            ariaLabel={commonString.startDate}
            value={props.item.startDate}
            maxDate={props.item.endDate}
            onSelectDate={(date: Date | null | undefined) => {
                if (date) {
                    dispatch(editDelegateToStartDate(props.item.clientRowKey!, date));
                }
            }}
        />
    );
}
