import { IAccrualLineDetail, AccrualLineDetail } from './accrualLineDetail';

export interface IPurchaseOrderLineReceiptsAndAccruals {
    purchaseOrderNumber: number;
    lineItemNumber: number;
    companyCode: string;
    currencyCode: string;
    accrualLine?: IAccrualLineDetail;
    receiptLine: IAccrualLineDetail;
}

export class PurchaseOrderLineReceiptsAndAccruals implements IPurchaseOrderLineReceiptsAndAccruals {
    public purchaseOrderNumber: number;
    public lineItemNumber: number;
    public companyCode: string;
    public currencyCode: string;
    public accrualLine?: AccrualLineDetail;
    public receiptLine: AccrualLineDetail;

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderLineReceiptsAndAccruals) {
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.lineItemNumber = jsonData.lineItemNumber;
        this.companyCode = jsonData.companyCode;
        this.currencyCode = jsonData.currencyCode;
        this.accrualLine = jsonData.accrualLine ? new AccrualLineDetail(jsonData.accrualLine) : undefined;
        this.receiptLine = new AccrualLineDetail(jsonData.receiptLine);
    }
}
