import { OrgDetail } from './orgDetail';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IOrgCode extends IHierarchyNodeCommon {
    orgDetail: OrgDetail[];
}

export class OrgCode implements IOrgCode {
    public typeName: string = HierarchyConstants.className.channel.orgCode;
    public code: string;
    public description: string;
    public orgDetail: OrgDetail[] = [];
    public children?: IHierarchyNodeCommon[] = this.orgDetail; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IOrgCode) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.orgDetail) {
            for (let i: number = 0; i < jsonData.orgDetail.length; i++) {
                this.orgDetail.push(new OrgDetail(jsonData.orgDetail[i]));
            }
        }
    }
}
