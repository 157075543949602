import React from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { Pivot, PivotItem, Stack } from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { GrReversalTab } from './GrReversalTab';
import { Section } from '../../components/Section/Section';
import { PoVisibilityCheckTab } from './PoVisibilityCheckTab';
import { DriReportsTab } from './DriReportsTab';
import { SimpleSapLookupTab } from './SimpleSapLookupTab';
import { JeToolsTab } from './JeToolsTab';

interface IPageProps extends ICommonPageProps {
}

export const DriToolsPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    return (
        <PageWrapper {...props}>
            <SectionWrapper>
                <Section>
                    <Stack tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <Pivot aria-label="Reports">
                                <PivotItem headerText="GR Reversal">
                                    <GrReversalTab />
                                </PivotItem>
                                <PivotItem headerText="PO Visibility Check">
                                    <PoVisibilityCheckTab />
                                </PivotItem>
                                <PivotItem headerText="DRI Reports">
                                    <DriReportsTab />
                                </PivotItem>
                                <PivotItem headerText="Simple SAP Lookup">
                                    <SimpleSapLookupTab />
                                </PivotItem>
                                <PivotItem headerText="JE Tools">
                                    <JeToolsTab />
                                </PivotItem>
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Section>
            </SectionWrapper>
        </PageWrapper>
    );
};
