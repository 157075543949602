import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CostCategoryConfiguration } from '../../models/priorYearAccrual/costCategoryConfiguration';
import { DefaultButton, FontIcon, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { commonStyles, stackTokensSmallGap } from '../../common/common.styles';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { AppDispatch } from '../../store/reduxStore';
import {
    IApiDeleteCostCategoryConfiguration,
    IApiSaveCostCategoryConfiguration,
    deleteCostCategoryConfiguration,
    editCostCategoryConfiguration
} from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { CallApiState } from '../../store/actions/generic.action';

interface IComponentProps {
    item: CostCategoryConfiguration;
}

export const CostCategoryConfigAction: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const apiSaveCostCategoryConfiguration: IApiSaveCostCategoryConfiguration =
        useAppSelector<IApiSaveCostCategoryConfiguration>(state => state.priorYearAccrualPageReducer.apiSaveCostCategoryConfiguration);
    const apiDeleteCostCategoryConfiguration: IApiDeleteCostCategoryConfiguration =
        useAppSelector<IApiDeleteCostCategoryConfiguration>(state => state.priorYearAccrualPageReducer.apiDeleteCostCategoryConfiguration);
    const costCategoryConfigurationToEdit: CostCategoryConfiguration | undefined =
        useAppSelector<CostCategoryConfiguration | undefined>(state => state.priorYearAccrualPageReducer.costCategoryConfigurationToEdit);
    const costCategoryConfigurationToDelete: CostCategoryConfiguration | undefined =
        useAppSelector<CostCategoryConfiguration | undefined>(state => state.priorYearAccrualPageReducer.costCategoryConfigurationToDelete);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Edit button clicked event handler.
     */
    const editButtonClicked = () => {
        dispatch(editCostCategoryConfiguration(props.item));
    };

    /**
     * Delete button clicked event handler.
     */
    const deleteButtonClicked = () => {
        dispatch(deleteCostCategoryConfiguration(props.item));
    };

    /**
     * Memoized helper to check if the save api is running for this item
     */
    const isSavingThisItem = useMemo<boolean>(() => {
        if (apiSaveCostCategoryConfiguration.callApiState === CallApiState.Running &&
            costCategoryConfigurationToEdit?.id === props.item.id) {
            return true;
        }
        return false;
    }, [apiSaveCostCategoryConfiguration.callApiState, costCategoryConfigurationToEdit?.id, props.item.id]);

    /**
     * Memoized helper to check if the delete api is running for this item
     */
    const isDeletingThisItem = useMemo<boolean>(() => {
        if (apiDeleteCostCategoryConfiguration.callApiState === CallApiState.Running &&
            costCategoryConfigurationToDelete?.id === props.item.id) {
            return true;
        }
        return false;
    }, [apiDeleteCostCategoryConfiguration.callApiState, costCategoryConfigurationToDelete?.id, props.item.id]);

    /**
     * Memoized helper to check if the save or delete api is running for any item.
     */
    const isSavingOrDeletingAnyItem = useMemo<boolean>(() => {
        if (apiSaveCostCategoryConfiguration.callApiState === CallApiState.Running ||
            apiDeleteCostCategoryConfiguration.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiDeleteCostCategoryConfiguration.callApiState, apiSaveCostCategoryConfiguration.callApiState]);

    return (
        <Stack horizontal tokens={stackTokensSmallGap}>
            <Stack.Item>
                <DefaultButton
                    onClick={editButtonClicked}
                    ariaLabel='Edit'
                    disabled={isSavingOrDeletingAnyItem}
                    className={pageStyles.actionButton}
                >
                    {isSavingThisItem && (
                        <Spinner size={SpinnerSize.small} className={commonStyles.spinnerInline} />
                    )}
                    {!isSavingThisItem && (
                        <FontIcon iconName="Edit" />
                    )}
                </DefaultButton>
            </Stack.Item>
            <Stack.Item>
                <DefaultButton
                    onClick={deleteButtonClicked}
                    ariaLabel='Delete'
                    disabled={isSavingOrDeletingAnyItem}
                    className={pageStyles.actionButton}
                >
                    {isDeletingThisItem && (
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    )}
                    {!isDeletingThisItem && (
                        <FontIcon iconName="Delete" />
                    )}
                </DefaultButton>
            </Stack.Item>
        </Stack>
    );
};
