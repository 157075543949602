import { Supplier } from '../domain/supplier';

export interface IRoleScope {
    financeGeographySalesDistrictCodes: string[];
    channelFunctionDetailCodes: string[];
    executiveFunctionDetailCodes: string[];
    companyCodes: string[];
    suppliers: Supplier[];
}

export class RoleScope implements IRoleScope {
    public financeGeographySalesDistrictCodes: string[] = [];
    public channelFunctionDetailCodes: string[] = [];
    public executiveFunctionDetailCodes: string[] = [];
    public companyCodes: string[] = [];
    public suppliers: Supplier[] = [];

    // Below fields are used in the UI only.
    public selectAllCompanyCodes = true; // Used for the company codes dropdown selection.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IRoleScope) {
        if (jsonData.financeGeographySalesDistrictCodes) {
            for (let i: number = 0; i < jsonData.financeGeographySalesDistrictCodes.length; i++) {
                this.financeGeographySalesDistrictCodes.push(jsonData.financeGeographySalesDistrictCodes[i]);
            }
        }

        if (jsonData.channelFunctionDetailCodes) {
            for (let i: number = 0; i < jsonData.channelFunctionDetailCodes.length; i++) {
                this.channelFunctionDetailCodes.push(jsonData.channelFunctionDetailCodes[i]);
            }
        }

        if (jsonData.executiveFunctionDetailCodes) {
            for (let i: number = 0; i < jsonData.executiveFunctionDetailCodes.length; i++) {
                this.executiveFunctionDetailCodes.push(jsonData.executiveFunctionDetailCodes[i]);
            }
        }

        if (jsonData.companyCodes) {
            for (let i: number = 0; i < jsonData.companyCodes.length; i++) {
                this.companyCodes.push(jsonData.companyCodes[i]);
            }

            const noneOptionText: string = 'Select all';
            this.selectAllCompanyCodes = this.companyCodes.length > 0 && this.companyCodes[0] !== noneOptionText ? false : true;
        }

        if (jsonData.suppliers) {
            for (let i: number = 0; i < jsonData.suppliers.length; i++) {
                this.suppliers.push(new Supplier(jsonData.suppliers[i]));
            }
        }
    }
}
