import * as actionTypes from '../actionTypes';
import { ICallApiBase, callApi } from '../generic.action';
import { AppDispatch } from '../../reduxStore';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { PurchaseOrderSearch } from '../../../models/purchaseOrder/purchaseOrderSearch';

/**
 * Payload used with callApiExcelImport action.
 */
export interface IApiExcelImport extends ICallApiBase {
}

/**
 * Call API to import the Excel file.
 * @param file File to import.
 * @returns Redux dispatch function.
 */
export const callApiExcelImport = (file: File): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_EXCEL_IMPORT,
        async () => {
            return await receiptingApiClient.excelImport(file);
        }
    );
};

/**
 * Payload used with callApiExcelExport action.
 */
export interface IApiExcelExport extends ICallApiBase {
    responseStatusCode?: number | null;
}

/**
 * Call API to export search results to Excel.
 * @param purchaseOrderSearch Purchase order search.
 * @returns Redux dispatch function.
 */
export const callApiExcelExport = (purchaseOrderSearch: PurchaseOrderSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<number>(
        actionTypes.API_EXCEL_EXPORT,
        async () => {
            return await receiptingApiClient.excelExport(purchaseOrderSearch);
        },
        (payload: IApiExcelExport, data: number | null) => {
            payload.responseStatusCode = data;
        }
    );
};
