export enum ShipmentUploadFileStatus {
    /**
     * The file is pending for processing.
     */
    Pending = 0,
 
    /**
     * The file is currently being processed.
     */
    Processing = 1,
 
    /**
     * The file processing has been completed successfully.
     */
    Completed = 2,
 
    /**
     * The file processing has failed.
     */
    Failed = 3
}
