import React from 'react';
import { ActionButton, Stack, TooltipDelay, TooltipHost } from '@fluentui/react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { showAuditForLineItem } from '../../store/actions/pageActions/editPage.action';
import { AppDispatch } from '../../store/reduxStore';
import { componentStyles } from './AuditActionButton.styles';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { tooltips } from '../../common/tooltips';
import { showAuditForPriorYearAccrualLineItem } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IAuditActionButtonProps {
    usedOnPage: 'priorYearAccrual' | 'editOrCloseLineItem';
    item: PurchaseOrderLineItem | PriorYearAccrualLineItem;
}

export const AuditActionButton: React.FunctionComponent<IAuditActionButtonProps> = (props: IAuditActionButtonProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Stack>
            <TooltipHost content={tooltips.viewLineAudit} delay={TooltipDelay.long}>
                <ActionButton
                    id={
                        `auditButton-${
                            props.item instanceof PurchaseOrderLineItem ? `${props.item.purchaseOrderNumber}-${props.item.purchaseOrderLineNumber}` :
                            props.item instanceof PriorYearAccrualLineItem ? `${props.item.purchaseOrder}-${props.item.lineItem}` :
                            ''
                        }`
                    }
                    ariaLabel={
                        `${props.item instanceof PurchaseOrderLineItem ? `${props.item.purchaseOrderNumber}-${props.item.purchaseOrderLineNumber}` :
                            props.item instanceof PriorYearAccrualLineItem ? `${props.item.purchaseOrder}-${props.item.lineItem}` :
                            ''
                        } ${tooltips.viewLineAudit}` // Make the screen reader speak the line number plus tooltip content.
                    }
                    className={componentStyles.actionButtonAudit}
                    onClick={() => {
                        if (props.usedOnPage === 'editOrCloseLineItem' && props.item instanceof PurchaseOrderLineItem) {
                            telemetryService.trackEvent({ name: trackedEvent.auditButtonClicked }, {
                                po: props.item.purchaseOrderNumber, line: props.item.purchaseOrderLineNumber
                            });
                            dispatch(showAuditForLineItem(props.item));
                        } else if (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem) {
                            telemetryService.trackEvent({ name: trackedEvent.auditButtonClicked }, {
                                po: props.item.purchaseOrder, line: props.item.lineItem
                            });
                            dispatch(showAuditForPriorYearAccrualLineItem(props.item));
                        }
                    }}
                    iconProps={{ iconName: 'History' }}
                />
            </TooltipHost>
        </Stack>
    );
};
