import {
    ISpinButtonStyles,
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    receiveMultipleContainer: {
        width: '60px'
    } as IStyle,
    receivedCheckbox: {
        top: '4px',
        '.ms-Checkbox-label': {
            /* There is no label used on this checkbox, so set the width to the checkbox width. */
            /* Note that globalStyles.css has an override for .ms-Checkbox-checkbox */
            width: '24px'
        }
    } as IStyle,
    editIcon: {
        margin: '0 6px 0 0'
    } as IStyle,
    invalidList: {
        marginTop: '0'
    } as IStyle,
    percentageReceivedSliderContainer: {
        margin: '2px 0 0 0'
    } as IStyle,
    percentageReceivedSlider: {
        width: '152px'
    } as IStyle,
    actionButtonSet100: {
        height: '16px',
        margin: '6px 0 0 0',
        padding: '0 0 0 0'
    } as IStyle,
    actionButtonAdjustAmount: {
        height: '30px',
        margin: '0 0 0 0',
        padding: '4px 4px 4px 4px'
    } as IStyle,
    actionButtonAdjustAmountText: {
        fontSize: '12px',
        textAlign: 'left',
        lineHeight: '14px',
        top: '1px',
        poistion: 'relative'
    } as IStyle,
    prePopulatedIconContainer: {
        position: 'absolute',
        right: '0',
        top: '18px',
        fontSize: '16px'
    } as IStyle,
    fiscalPeriod: {
        color: '#702EAA'
    } as IStyle,
    fiscalPeriodInfoIcon: {
        paddingLeft: 4,
        fontSize: 16,
        display: 'inline-block',
        position: 'relative',
        bottom: '-4px'
    } as IStyle,
    miniSearchResultsSectionExpanded: {
        width: '76px',
        height: '100%',
        left: '-20px',
        top: '8px',
        position: 'relative'
    } as IStyle,
    miniSearchResultsDivider: {
        borderRight: '1px solid lightgray',
        height: '100%',
        width: '1px',
        margin: '0 10px 0 0'
    } as IStyle,
    miniSearchResultsExpander: {
        position: 'absolute',
        left: '6px',
        top: '0'
    } as IStyle,
    miniSearchResultsHeading: {
        position: 'absolute',
        left: '42px',
        top: '6px',
        lineHeight: '12px'
    }as IStyle,
    poContainer: {
        width: '100%'
    } as IStyle,
    notice: {
        color: 'red',
        fontSize: '12px',
        marginTop: '4px',
        borderTop: '1px solid red',
        borderBottom: '1px solid red',
        padding: '4px 0 4px 0'
    } as IStyle
});

export const spinButtonStyle: Partial<ISpinButtonStyles> = {
    spinButtonWrapper: {
        minWidth: 62,
        width: 62,
        'input': {
            minWidth: 40,
            width: 40
        } as IStyle
    } as IStyle
};
