export interface IProfitCenterCodeMapping {
    pc: string;
    pcDesc: string;
    execOrgSummaryDesc: string;
    execOrgSummaryCode: string;
    execOrgDesc: string;
    execOrgCode: string;
    channelOrgSummaryDesc: string;
    channelOrgSummaryCode: string;
    channelOrgDesc: string;
    channelOrgCode: string;
    orgDetailDesc: string;
    orgDetailCode: string;
    funcSummaryDesc: string;
    funcDesc: string;
    funcDetailDesc: string;
    salesLocationDesc: string;
    salesLocationCode: string;
}

export class ProfitCenterCodeMapping implements IProfitCenterCodeMapping {
    public pc: string;
    public pcDesc: string;
    public execOrgSummaryDesc: string;
    public execOrgSummaryCode: string;
    public execOrgDesc: string;
    public execOrgCode: string;
    public channelOrgSummaryDesc: string;
    public channelOrgSummaryCode: string;
    public channelOrgDesc: string;
    public channelOrgCode: string;
    public orgDetailDesc: string;
    public orgDetailCode: string;
    public funcSummaryDesc: string;
    public funcDesc: string;
    public funcDetailDesc: string;
    public salesLocationDesc: string;
    public salesLocationCode: string;

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IProfitCenterCodeMapping) {
        this.pc = jsonData.pc;
        this.pcDesc = jsonData.pcDesc;
        this.execOrgSummaryDesc = jsonData.execOrgSummaryDesc;
        this.execOrgSummaryCode = jsonData.execOrgSummaryCode;
        this.execOrgDesc = jsonData.execOrgDesc;
        this.execOrgCode = jsonData.execOrgCode;
        this.channelOrgSummaryDesc = jsonData.channelOrgSummaryDesc;
        this.channelOrgSummaryCode = jsonData.channelOrgSummaryCode;
        this.channelOrgDesc = jsonData.channelOrgDesc;
        this.channelOrgCode = jsonData.channelOrgCode;
        this.orgDetailDesc = jsonData.orgDetailDesc;
        this.orgDetailCode = jsonData.orgDetailCode;
        this.funcSummaryDesc = jsonData.funcSummaryDesc;
        this.funcDesc = jsonData.funcDesc;
        this.funcDetailDesc = jsonData.funcDetailDesc;
        this.salesLocationDesc = jsonData.salesLocationDesc;
        this.salesLocationCode = jsonData.salesLocationCode;
    }
}
