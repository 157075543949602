import { OrgCode } from './orgCode';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IOrgSummary extends IHierarchyNodeCommon {
    orgCode: OrgCode[];
}

export class OrgSummary implements IOrgSummary {
    public typeName: string = HierarchyConstants.className.channel.orgSummary;
    public code: string;
    public description: string;
    public orgCode: OrgCode[] = [];
    public children?: IHierarchyNodeCommon[] = this.orgCode; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IOrgSummary) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.orgCode) {
            for (let i: number = 0; i < jsonData.orgCode.length; i++) {
                this.orgCode.push(new OrgCode(jsonData.orgCode[i]));
            }
        }
    }
}
