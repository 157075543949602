import React, { useMemo } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { editLineItemReceiptDate } from '../../store/actions/pageActions/editPage.action';
import { commonString } from '../../common/commonString';
import {
    DatePicker,
    DirectionalHint,
    FontIcon,
    IStyle,
    Text,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { pageStyles } from './EditPage.styles';

interface IReceiptDateInputProps {
    item: PurchaseOrderLineItem;
}

export const ReceiptDateInput: React.FunctionComponent<IReceiptDateInputProps> = (props: IReceiptDateInputProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const fiscalPeriod = useMemo(() => {
        return props.item.postingFiscalPeriodForSelectedReceiptDate;
    }, [props.item.postingFiscalPeriodForSelectedReceiptDate]) 

    return (
        // The minDate, maxDate, and disabled properties on DatePicker all check the dirty state
        // for 'receivedAmount'. The date picker should not be editable unless the received amount
        // is changed. The min and max date only needs to be set on the date picker if it is editable.
        // If viewing an old PO line that had been received long ago, the min/max dates should not
        // apply and cause a validation error.
        <>
            <DatePicker
                id={`receiptDate-${props.item.purchaseOrderLineNumber}`}
                ariaLabel={commonString.receiptDate}
                calendarProps={{
                    styles: {
                        root: {
                            /* For todays date, by default there is a blue circle highlighting today's date. This is regardless
                                of what the max date is. The max date (which is PST based) could be less than todays date if the
                                current time zone is ahead of PST. The blue highlight over todays date is confusing. It cannot be
                                disabled using a built in prop such as highlightCurrentMonth which works for month. There is
                                nothing similar for date. This CSS override will set these styles back to inherit (unset also works)
                                to get rid of this effect for todays date.
                                Default, example:
                                .dayIsToday-589
                                    border-radius: 100%;
                                    background-color: rgb(0, 120, 212) !important;
                                    color: rgb(255, 255, 255) !important;
                                    font-weight: 600 !important;
                            */
                            '.ms-CalendarDay-dayIsToday': {
                                borderRadius: 'unset!important',
                                backgroundColor: 'unset!important',
                                color: 'unset!important',
                                fontWeight: 'unset!important'
                            } as IStyle
                        }
                    }
                }}
                onSelectDate={(date: Date | null | undefined) => {
                    dispatch(editLineItemReceiptDate(props.item.purchaseOrderLineNumber, date));
                }}
                minDate={props.item.checkIsDirty('receivedAmount') ? props.item.calculateReceiptDateBoundaries('min') : undefined}
                maxDate={props.item.checkIsDirty('receivedAmount') ? props.item.calculateReceiptDateBoundaries('max') : undefined}
                value={typeof props.item.receiptDate === 'string' ? new Date(props.item.receiptDate) : props.item.receiptDate}
                disabled={props.item.checkToDisableEditing() || !props.item.checkIsDirty('receivedAmount')}
            />
            {
                props.item.checkIsDirty('receivedAmount') && fiscalPeriod && (
                    <TooltipHost content={`Your current receipting adjustment will impact the ${fiscalPeriod.fiscalYearQuarter} budget. The posting will be made in the ${fiscalPeriod.periodMonth} period.`} directionalHint={DirectionalHint.bottomCenter} delay={TooltipDelay.long}>
                        <Text variant='smallPlus' className={pageStyles.fiscalPeriod}>Impact {fiscalPeriod.fiscalYearQuarter} Budget</Text>
                        <FontIcon iconName="Info" className={pageStyles.fiscalPeriodInfoIcon} />
                    </TooltipHost>
                )
            }
        </>
    );
};
