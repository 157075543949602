import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Panel, PanelType } from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { hideDetailsForPriorYearAccrual } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { commonString } from '../../common/commonString';
import { AppDispatch } from '../../store/reduxStore';
import { ApprovalOptionKey, AvailabilityOptionKey, PriorYearAccrualPivotItemKey } from './priorYearAccrualPageConstants';
import { UserProfile } from '../../models/user/userProfile';

interface IComponentProps {
}

export const PyaLineItemDetailsPanel: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const params = useParams();

    // Redux store selectors to get state from the store when it changes.
    const showDetailsForPriorYearAccrualLineItem: PriorYearAccrualLineItem | undefined =
        useAppSelector<PriorYearAccrualLineItem | undefined>((state) => state.priorYearAccrualPageReducer.showDetailsForPriorYearAccrualLineItem);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);
    
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Memoized boolean to indicate if For You tab is active.
     */
    const isForYouTabActive = useMemo<boolean>(() => {
        return params.activeTab?.toLowerCase() === PriorYearAccrualPivotItemKey.ForYou.toLowerCase();
    }, [params.activeTab]);

    /**
     * Memoized boolean to indicate if the Elevated tab is active.
     */
    const isElevatedTabActive = useMemo<boolean>(() => {
        return params.activeTab?.toLowerCase() === PriorYearAccrualPivotItemKey.Elevated.toLowerCase();
    }, [params.activeTab]);

    return (
        <Panel
            headerText={`Details for ${showDetailsForPriorYearAccrualLineItem?.purchaseOrder}-${showDetailsForPriorYearAccrualLineItem?.lineItem}`}
            isOpen={showDetailsForPriorYearAccrualLineItem !== undefined}
            onDismiss={() => {
                setTimeout(() => {
                    dispatch(hideDetailsForPriorYearAccrual());
                });
            }}
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
            type={PanelType.medium}
        >
            <table className={commonStyles.detailsTable}>
                <tbody>
                    {(isForYouTabActive || isElevatedTabActive) && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Line Details</td>
                            </tr>
                            <tr>
                                <td>{commonString.fiscalYear}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.fiscalYear}</td>
                            </tr>
                            <tr>
                                <td>{commonString.companyCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.companyCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.poDescription}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.poDescription}</td>
                            </tr>
                            <tr>
                                <td>{commonString.lineDescription}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.lineDescription}</td>
                            </tr>
                            <tr>
                                <td>{commonString.currency}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.currency}</td>
                            </tr>
                            <tr>
                                <td>{commonString.deliveryDate}</td>
                                <td>{formatDateUsingLocale(showDetailsForPriorYearAccrualLineItem?.deliveryDate)}</td>
                            </tr>
                            <tr>
                                <td>{commonString.poCreateDate}</td>
                                <td>{formatDateUsingLocale(showDetailsForPriorYearAccrualLineItem?.poCreateDate)}</td>
                            </tr>
                            <tr>
                                <td>{commonString.costCenter}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.costCenter}</td>
                            </tr>
                            <tr>
                                <td>{commonString.internalOrder}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.ioNumber}</td>
                            </tr>
                            <tr>
                                <td>{commonString.profitCenter}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.profitCenterCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.glAccount}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.glAccount}</td>
                            </tr>
                            <tr>
                                <td>{commonString.lineTotal}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.lineTotal, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.lineTotalUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.lineTotalUsd, 2)} USD`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.invoicedAmount}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.invoicedAmount, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.invoicedAmountUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.invoicedAmountUsd, 2)} USD`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.openAmount}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.openAmount, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.openAmountUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.openAmountUsd, 2)} USD`}</td>
                            </tr>
                            <tr>
                                {/* This also appears under Review Details heading. */}
                                <td>{commonString.poOwner}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.purchaseOrderOwner}</td>
                            </tr>
                            <tr>
                                <td>{commonString.poApprover}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.purchaseOrderApprover}</td>
                            </tr>
                            <tr>
                                <td>{commonString.invoiceApprover}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.invoiceApprover}</td>
                            </tr>
                            <tr>
                                <td>{commonString.purchasingClassification}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.purchasingClassification}</td>
                            </tr>
                            <tr>
                                <td>{commonString.supplierName}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.vendorName}</td>
                            </tr>
                            <tr>
                                <td>{commonString.supplierNumber}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.vendorNumber}</td>
                            </tr>
                            <tr>
                                <td>{commonString.receiptDate}</td>
                                <td>{formatDateUsingLocale(showDetailsForPriorYearAccrualLineItem?.receiptDate)}</td>
                            </tr>
                            <tr>
                                <td>{commonString.lastReceiptActionedBy}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.lastReceiptActionedBy}</td>
                            </tr>
                        </>
                    )}

                    {isElevatedTabActive && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Prior Year Details</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticCostCenter}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticCostCenter}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticIO}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticIO}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticProfitCenter}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticProfitCenter}</td>
                            </tr>
                            <tr>
                                {/* This also appears under Static Cost Category Information heading. */}
                                <td>{commonString.staticGlAccount}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGlAccount}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticExecOrgSummary}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticExecOrgSummary}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticOrgSummary}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticOrgSummary}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticOrgDetail}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticOrgDetail}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticSalesLocation}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticSalesLocation}</td>
                            </tr>
                        </>
                    )}

                    {isElevatedTabActive && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Accrual Details</td>
                            </tr>
                            <tr>
                                <td>{commonString.pyaOpenAccrualAmount}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.openAccrualAmount}</td>
                            </tr>
                            <tr>
                                <td>{commonString.pyaOpenAccrualAmountUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.openAccrualAmountUsd, 2)} USD`}</td>
                            </tr>
                            {userProfile?.isAccrualsAdmin && (
                                <>
                                    <tr>
                                        <td>{commonString.invoicedAmountForCurrentFy}</td>
                                        <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.invoicedAmountForCurrentFy, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                                    </tr>
                                    <tr>
                                        <td>{commonString.invoicedAmountForCurrentFyUsd}</td>
                                        <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.invoicedAmountForCurrentFyUsd, 2)} USD`}</td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td>{commonString.accrualReversalForCurrentFy}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.accrualReversalForCurrentFy, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.accrualReversalForCurrentFyUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.accrualReversalForCurrentFyUsd, 2)} USD`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.systemPostedAccrualAmount}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.systemPostedAccrualAmount}</td>
                            </tr>
                            <tr>
                                <td>{commonString.systemPostedAccrualAmountUsd}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.systemPostedAccrualAmountUsd}</td>
                            </tr>
                        </>
                    )}

                    {isElevatedTabActive && userProfile?.isAccrualsAdmin && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Prior Year P&L Posting Information</td>
                            </tr>
                            <tr>
                                <td>{commonString.dedicatedPnlAccountCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.dedicatedPnlAccountCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.originalPlImpact}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.originalPlImpact, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.originalPlImpactUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.originalPlImpactUsd, 2)} USD`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.dedicatedPl}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.dedicatedPl, showDetailsForPriorYearAccrualLineItem?.currencyFractionalPart)} ${showDetailsForPriorYearAccrualLineItem?.currency}`}</td>
                            </tr>
                            <tr>
                                <td>{commonString.dedicatedPlUsd}</td>
                                <td>{`${numberAsLocaleString(showDetailsForPriorYearAccrualLineItem?.dedicatedPlUsd, 2)} USD`}</td>
                            </tr>
                        </>
                    )}

                    {isElevatedTabActive && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Hierarchy</td>
                            </tr>
                            <tr>
                                <td>{commonString.orgDetail}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.orgDetail}</td>
                            </tr>
                            <tr>
                                <td>{commonString.execOrg}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.execOrg}</td>
                            </tr>
                            <tr>
                                <td>{commonString.execOrgSummary}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.execOrgSummary}</td>
                            </tr>
                            <tr>
                                <td>{commonString.channelOrg}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.channelOrg}</td>
                            </tr>
                            <tr>
                                <td>{commonString.channelOrgSummary}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.channelOrgSummary}</td>
                            </tr>
                            <tr>
                                <td>{commonString.salesLocation}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.salesLocation}</td>
                            </tr>
                            {userProfile?.isAccrualsAdmin && (
                                <tr>
                                    <td>{commonString.areaDesc}</td>
                                    <td>{showDetailsForPriorYearAccrualLineItem?.areaDesc}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{commonString.region}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.region}</td>
                            </tr>
                        </>
                    )}
                    
                    {isElevatedTabActive && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Static Cost Category Information</td>
                            </tr>
                            <tr>
                                {/* This also appears under Prior Year Details heading. */}
                                <td>{commonString.staticGlAccount}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGlAccount}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLGlAccountDescription}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLGlAccountDescription}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLSubclassName}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLSubclassName}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLSubclassCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLSubclassCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLClassDesc}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLClassDesc}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLClassCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLClassCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLGroupNameDesc}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLGroupNameDesc}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLGroupNameCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLGroupNameCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLLineItemDesc}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLLineItemDesc}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLLineItemCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLLineItemCode}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLLineItemDetailDesc}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLLineItemDetailDesc}</td>
                            </tr>
                            <tr>
                                <td>{commonString.staticGLLineItemDetailCode}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.staticGLLineItemDetailCode}</td>
                            </tr>
                        </>
                    )}
                    
                    {isElevatedTabActive && (
                        <>
                            <tr>
                                <td colSpan={2} className={commonStyles.detailsSubHeading}>Review Details</td>
                            </tr>
                            <tr>
                                {/* This also appears under Line Details heading. */}
                                <td>{commonString.poOwner}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.purchaseOrderOwner}</td>
                            </tr>
                            <tr>
                                <td>{commonString.corpApproval}</td>
                                <td>
                                    { (showDetailsForPriorYearAccrualLineItem?.corpDetails?.corpApproval?.approved !== undefined && showDetailsForPriorYearAccrualLineItem?.corpDetails?.corpApproval?.approved !== null) && (
                                        showDetailsForPriorYearAccrualLineItem?.corpDetails.corpApproval.approved ? ApprovalOptionKey.Approved : ApprovalOptionKey.NotApproved
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{commonString.corpComment}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.corpDetails?.corpApproval?.comment}</td>
                            </tr>
                            <tr>
                                <td>{commonString.stcApproval}</td>
                                <td>
                                    { (showDetailsForPriorYearAccrualLineItem?.corpDetails?.stcApproval?.approved !== undefined && showDetailsForPriorYearAccrualLineItem?.corpDetails?.stcApproval?.approved !== null) && (
                                        showDetailsForPriorYearAccrualLineItem?.corpDetails.stcApproval.approved ? ApprovalOptionKey.Approved : ApprovalOptionKey.NotApproved
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{commonString.stcComment}</td>
                                <td>{showDetailsForPriorYearAccrualLineItem?.corpDetails?.stcApproval?.comment}</td>
                            </tr>
                            <tr>
                                <td>{commonString.poePodAvailability}</td>
                                <td>
                                    { (showDetailsForPriorYearAccrualLineItem?.corpDetails?.poePodAvailability?.available !== undefined && showDetailsForPriorYearAccrualLineItem?.corpDetails?.poePodAvailability?.available !== null) && (
                                        showDetailsForPriorYearAccrualLineItem?.corpDetails.poePodAvailability.available ? AvailabilityOptionKey.Yes : AvailabilityOptionKey.No
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{commonString.invoiceExpectedDate}</td>
                                <td>{formatDateUsingLocale(showDetailsForPriorYearAccrualLineItem?.corpDetails?.invoiceAvailability?.expectedDate)}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </Panel>
    );
};
