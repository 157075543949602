import { Organization } from '../../models/shipment/organization';
import { IShipmentFileInfo } from '../../models/shipment/shipmentFileInfo';
import { ShipmentFileProcessingStatus } from '../../models/shipment/shipmentFileProcessingStatus';
import { ShipmentUploadFileStatus } from '../../models/shipment/shipmentUploadFileStatus';
import { SupplierShipmentDetail } from '../../models/shipment/supplierShipmentDetail';
import { appConfig } from '../../shell/appConfig';
import { ApiClientBase, ApiService } from './apiClientBase';

/**
 * Shipment api client.
 */
class ShipmentApiClient extends ApiClientBase {
    /**
     * Constructor for the receipting service api client.
     */
    constructor() {
        super(ApiService.Shipment)
    }
    
    /**
     * Gets shipment status trail.
     * @param poNumber Purchase order number.
     * @param lineNumber Line number.
     * @returns Shipments status trail.
     */
    public async shipmentStatusTrail(poNumber: number, lineNumber: number): Promise<SupplierShipmentDetail[] | null> {
        const mockDataFile: string = 'shipmentStatusTrailLenovo.json';
        // const mockDataFile: string = 'shipmentStatusTrailZones.json';
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/${mockDataFile}` : `${appConfig.current.service.shipmentApiBaseUrl}shipment/shipmentstatustrail`;
        return await this.getObjectArray<SupplierShipmentDetail>(SupplierShipmentDetail, apiUrl, {
            purchaseOrder: poNumber,
            lineNumber: lineNumber
        });
    }

    /**
     * Ping the Shipment API.
     * @returns Ping response.
     */
    public async ping(): Promise<string> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/shipmentPing.txt` : `${appConfig.current.service.shipmentApiBaseUrl}ping`;
        return await this.getValue<string>(apiUrl);
    }

    /**
     * Gets shipment file processing status.
     * @param pageNumber Page number.
     * @param pageSize Page size.
     * @param shipmentUploadFileStatus Shipment file upload status.
     * @returns Shipment file processing statuses.
     */
    public async shipmentFileProcessingStatus(pageNumber: number, pageSize: number, shipmentUploadFileStatus?: ShipmentUploadFileStatus): Promise<any> {
        const mockDataFile: string = 'shipmentFileProcessingStatus.json';
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/${mockDataFile}` : `${appConfig.current.service.shipmentApiBaseUrl}shipment/fileProcessingStatus`;

        const result: ShipmentFileProcessingStatus | null = await this.getObject<ShipmentFileProcessingStatus>(ShipmentFileProcessingStatus, apiUrl, {
            pageNumber: pageNumber,
            pageSize: pageSize,
            shipmentUploadFileStatus: shipmentUploadFileStatus
        });

        if (appConfig.current.service.useLocalMockData && result) {
            // The mock data file has multiple records. Simulate taking the requested records here.
            const filteredData: IShipmentFileInfo[] = result.data.filter(
                x => shipmentUploadFileStatus === undefined || x.status === ShipmentUploadFileStatus[shipmentUploadFileStatus]
            );
            result.totalCount = filteredData.length;
            result.data = filteredData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        }

        return result;
    }

    /**
     * Upload shipment details.
     * @param file Excel file to upload.
     * @param organization Organization. This is the shipping supplier like Zones or HP.
     * @returns Returns null.
     */
    public async uploadShipmentDetails(file: File, organization: Organization): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.shipmentApiBaseUrl}shipment/uploadShipmentDetails`;
        return await this.postFile(apiUrl, file, { organization: organization });
    }

    /**
     * Download shipment details.
     * @param filePath File path.
     * @returns Response status code.
     */
    public async downloadShipmentDetails(filePath: string): Promise<number> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/mockExcel.xlsx` : `${appConfig.current.service.shipmentApiBaseUrl}shipment/downloadShipmentDetails`;
        return await this.downloadBlobFileUsingGet(apiUrl, { filePath: filePath });
    }
}

export const shipmentApiClient = new ShipmentApiClient();
