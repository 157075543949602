import { deepCopyObject } from '../../common/common.func.general';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { getUserAlias } from '../auth/msalHelper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

// Utility functions used with the receiptingApiClient.

/**
 * Adjust line item values, including partial received quantity, asset tag, serial number, type name, and receipt date.
 * @param toBeSavedItems To be saved items.
 */
export const adjustLineItemValues = (toBeSavedItems: PurchaseOrderLineItem[]): void => {
    for (let i: number = 0; i < toBeSavedItems.length; i++) {
        if (toBeSavedItems[i].isAsset) {
            // Adjust the received quantity so that it is the new received quantity minus the prior saved received
            // quantity. That is, the receivedQuantity should be a delta of what it was before what it is now.
            if (toBeSavedItems[i].receivedQuantity! > toBeSavedItems[i].originalState?.receivedQuantity!) {
                toBeSavedItems[i].receivedQuantity = toBeSavedItems[i].receivedQuantity! - toBeSavedItems[i].originalState?.receivedQuantity!;
            }

            // Adjust the asset details so we can send default values to the API if the asset details are not required.
            if (!toBeSavedItems[i].requireAssetDataCollection) {
                // Conditional checks are here to ensure these fields are not overwritten if they already have some value present.
                // If blank, then prepopulate with N/A or the current user alias for recipient.
                toBeSavedItems[i].assetTag = toBeSavedItems[i].assetTag ? toBeSavedItems[i].assetTag : 'N/A';
                toBeSavedItems[i].serialNumber = toBeSavedItems[i].serialNumber ? toBeSavedItems[i].serialNumber : 'N/A';
                toBeSavedItems[i].recipientName = toBeSavedItems[i].recipientName ? toBeSavedItems[i].recipientName : getUserAlias();
            }
        } else {
            // Normally, Javascript dates will be serialized as UTC format ISO strings.
            // The receiptDate as set above would look like this when serialized in json: '2022-10-01T07:00:00.000Z'
            // (assuming the current local time is midnight PST, as PST has 7 hour UTC offset).
            //
            // The receipt date is assumed to be in PST. The accrual posting to JEM will be posted on the date
            // chosen. So if the user chooses 10/1/2022 then the accrual posting to JEM will be at 10/1/2022 PST.
            // The max date a user can choose is based on their local time. Most of the world is on the next day
            // before PST is... that is, a user in Italy or East US could choose 10/1/2022 before a PST user could.
            //
            // We need to transmit the local time as the receipt date to the server during the save api call
            // without the Z at the end. So if the user picks 2022-10-01 as the date, then it needs to transmit as
            // '2022-10-01T00:00:00' (without the ending Z). When instantiated in C# as a DateTime object, it will
            // instantiate as a local PST DateTime (as server code runs in West US PST) with that exact same date
            // of '2022-10-01T00:00:00'. If 'Z' was added then the DateTime object would adjust for UTC time offset.
            // For example, try this in C# console:
            //   > Console.WriteLine(DateTimeOffset.Parse("2022-10-01T00:00:00.000").ToLocalTime());
            //   10/1/2022 12:00:00 AM -07:00
            //   > Console.WriteLine(DateTimeOffset.Parse("2022-10-01T00:00:00.000Z").ToLocalTime());
            //   9/30/2022 5:00:00 PM -07:00
            //
            // In the local PurchaseOrderLineItem object, the receiptDate can be a Date or a string. Here we will
            // convert it to the ISO string without the Z and with 0's for hours, mins, secs, ms.
            // Example: '2022-10-01T07:00:00.000Z' will convert to '2022-10-01T00:00:00.000' as a string (not Date).
            // This string representation would then be sent to the server during save.
            if (toBeSavedItems[i].receiptDate && toBeSavedItems[i].receiptDate instanceof Date) {
                const isoFmtReceiptDate: string = dayjs(toBeSavedItems[i].receiptDate).format('YYYY-MM-DDTHH:mm:ss');
                toBeSavedItems[i].receiptDate = isoFmtReceiptDate;
            }
        }
    }
}

/**
 * Delete certain immutable data from the to be saved items. Since data is immutable (like description
 * and all the data shown in the details) it isn't needed to be sent back to the API. We can reduce
 * the payload size of data sent over SignalR.
 * @param toBeSavedItems To be saved items.
 * @returns Array of purchase order line items.
 */
export const reduceDataOfItems = (toBeSavedItems: PurchaseOrderLineItem[]): PurchaseOrderLineItem[] => {
    const reducedItems: PurchaseOrderLineItem[] = [];
    for (let i: number = 0; i < toBeSavedItems.length; i++) {
        reducedItems.push(toBeSavedItems[i].reduceDataForSave());
    }
    return reducedItems;
}

/**
 * Prepare for bulk save purchase orders.
 * @param toBeSavedItems To be saved items.
 * @returns Array of purchase order line items.
 */
export const prepareBulkSavePurchaseOrders = (toBeSavedItems: PurchaseOrderLineItem[]): PurchaseOrderLineItem[] => {
    // Create a deep copy to adjust the values of saved items, if necessary.
    // Deep copy so the UI doesn't reflect any of these adjustments.
    const adjustedToBeSavedItems = deepCopyObject(toBeSavedItems);
    adjustLineItemValues(adjustedToBeSavedItems);

    return reduceDataOfItems(adjustedToBeSavedItems);
}
