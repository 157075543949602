import React from 'react';
import { DetailsRow, IColumn, IDetailsRowProps, IDetailsRowStyles, IRenderFunction } from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { detailsListScrollableContainerHeight } from './CustomDetailsList.styles';

/**
 * Common properties used on IColumn. Can be mixed in using ...commonColumnProps.
 * Any of these can be overridden while mixed in by supplying the property again.
 */
export const commonColumnProps: any = {
    isRowHeader: false,
    isResizable: true,
    isPadded: true,
    isCollapsible: false,
    isMultiline: true,
    headerClassName: `${commonStyles.wordWrapColumnHeader}`
};

/**
 * Copy and sort items.
 * @param items Items to sort.
 * @param columnKey Column key.
 * @param isSortedDescending Is sorted descending or not.
 * @returns Sorted array.
 */
const copyAndSort = <T extends unknown>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
};

export interface IColumnsAndItems<T> {
    columns: IColumn[];
    items: T[];
    currentSortedColumn: IColumn;
}

/**
 * Sort columns on column specified.
 * @param column Column to sort on.
 * @param columns All the columns.
 * @param items Items to sort.
 * @param isSortedDescending Optional: If supplied, sort this way. Otherwise invert the sort.
 * @returns Updated columns collection sorted column isSorted set to true.
 */
export const sortOnColumn = <T extends unknown>(column: IColumn, columns: IColumn[], items: T[], isSortedDescending?: boolean): IColumnsAndItems<T> => {
    if (!items || !column) {
        // Return the columns and items that was passed as input.
        return { columns: columns, items: items, currentSortedColumn: column };
    }

    const newColumns: IColumn[] = [...columns]; // Copy columns from state into new array using spread operator.
    const curColumn: IColumn = newColumns.filter(c => column.key === c.key)[0];
    if (!curColumn) {
        // If the column could not be found in the new columns, then return original columns and items.
        return { columns: columns, items: items, currentSortedColumn: column };
    }
    newColumns.forEach((newCol: IColumn) => {
        if (newCol === curColumn) {
            curColumn.isSorted = true;
            curColumn.isSortedDescending = isSortedDescending === undefined || isSortedDescending === null ? !curColumn.isSortedDescending : isSortedDescending;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });
    const newItems: T[] = [
        ...copyAndSort(items, curColumn.fieldName!, curColumn.isSortedDescending)
    ];

    return { columns: newColumns, items: newItems, currentSortedColumn: curColumn };
};

/**
 * Reset column sorting on columns.
 * @param columns Array of columns.
 * @returns Modified columns array.
 */
export const resetColumnSorting: (columns: IColumn[]) => IColumn[] = (columns) => {
    columns.forEach((column: IColumn) => {
        column.isSorted = undefined;
        column.isSortedDescending = undefined;
    });
    return columns;
};

/**
 * Assign the onColumnClick event on all columns.
 * @param cols Columns to apply the callback to.
 * @param onColumnClick Callback for when the user clicks on the column header.
 */
export const assignOnColumnClick = (cols: IColumn[], onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void) => {
    if (cols && cols.length > 0) { 
        cols.forEach(c => c.onColumnClick = onColumnClick);
    }
};

/**
 * Custom row rendering for details list.
 * Disables the hover effect on each row which is not desirable especially when SelectionMode is none.
 * See: https://github.com/microsoft/fluentui/issues/8783
 * If item has a detailsRowJsx property then it will be used to render details below the row.
 * @param detailsRowProps Details row props. Allows undefined because IRenderFunction requires it (but this will return empty element if this is the case).
 * @returns JSX for details row.
 */
export const onCustomRenderRow: IRenderFunction<IDetailsRowProps> = (
    detailsRowProps: IDetailsRowProps | undefined
): JSX.Element => {
    if (detailsRowProps) {
        const rowStyles: Partial<IDetailsRowStyles> = {
            root: {
                ':hover': {
                    background: 'transparent'
                }
            }
        };

        return (
            <>
                <DetailsRow {...detailsRowProps} styles={rowStyles} />
                {detailsRowProps.item.detailsRowJsx && (
                    // If detailsRowJsx is present on the item, then render it below the row.
                    <div>
                        {detailsRowProps.item.detailsRowJsx}
                    </div>
                )}
            </>
        );
    }
    return <></>;
};

/**
 * Helper method to adjust the height of an element used as a scrollable container for the DetailsList.
 * @param idPrefix Optional id prefix. Should be used when there are multiple details lists used on one page to identify this instance.
 */
export const adjustScrollableDetailsContainerHeight = (idPrefix?: string) => {
    // This code is to make adjustments to the height of the detail list container.
    // See the style for detailsListScrollableContainer, it is set to 82vh by default.
    // If the search results has a small number, like 6 or less results (it depends on the screen size of the user),
    // then there is a bunch of whitespace shown between the last line item and the bottom of the container.
    // This makes for a bit of a bad experience. So, this code will adjust the height of the container to a smaller
    // size in this case. Otherwise will default back to 82vh.
    setTimeout(() => {
        const detailsListScrollableContainer: HTMLElement | null = document.getElementById(`${idPrefix ? idPrefix : ''}_detailsListScrollableContainer`);
        if (detailsListScrollableContainer) {
            const detailsListResult: HTMLCollectionOf<Element> = detailsListScrollableContainer.getElementsByClassName('ms-DetailsList');
            if (detailsListResult.length > 0) {
                const detailsList: HTMLElement = detailsListResult[0] as HTMLElement;
                if (detailsList.offsetHeight < detailsListScrollableContainer.offsetHeight) {
                    detailsListScrollableContainer.style.height = `${detailsList.offsetHeight + 44}px`; // Give 44 extra height to make room for loading spinner in the grid.
                } else {
                    detailsListScrollableContainer.style.height = detailsListScrollableContainerHeight;
                }
            }
        }
    });
};
