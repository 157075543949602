import React, { useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption, Stack } from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { AdminHierarchyConfig } from './AdminHierarchyConfig';
import { AdminCostCategoryConfig } from './AdminCostCategoryConfig';
import { AdminManualPosting } from './AdminManualPosting';

interface IAdminTabProps {
    handleError: (errMsg: string) => void;
}

enum AdminOptionKey {
    HierarchyConfig = 'HierarchyConfig',
    CostCategoryConfig = 'CostCategoryConfig',
    ManualPosting = 'ManualPosting'
}

export const AdminTab: React.FunctionComponent<IAdminTabProps> = (props: IAdminTabProps): JSX.Element => {
    const [selectedAdminOptionKey, setSelectedAdminOptionKey] = useState<AdminOptionKey>(AdminOptionKey.HierarchyConfig);

    return (
        <Stack tokens={stackTokensNormalGap}>
            <Stack.Item>
                <ChoiceGroup
                    defaultSelectedKey={selectedAdminOptionKey}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                        setSelectedAdminOptionKey(option!.key as AdminOptionKey);
                    }}
                    options={[
                        {
                            key: AdminOptionKey.HierarchyConfig,
                            iconProps: {
                                iconName: 'BulletedTreeList'
                            },
                            text: 'Hierarchy'
                        },
                        {
                            key: AdminOptionKey.CostCategoryConfig,
                            iconProps: {
                                iconName: 'PageHeaderEdit'
                            },
                            text: 'Cost Category'
                        },
                        {
                            key: AdminOptionKey.ManualPosting,
                            iconProps: {
                                iconName: 'BulkUpload'
                            },
                            text: 'Manual Posting'
                        }
                    ]}
                />
            </Stack.Item>
            <Stack.Item>
                {selectedAdminOptionKey === AdminOptionKey.HierarchyConfig && (
                    <AdminHierarchyConfig
                        handleError={props.handleError}
                    />
                )}
                {selectedAdminOptionKey === AdminOptionKey.CostCategoryConfig && (
                    <AdminCostCategoryConfig
                        handleError={props.handleError}
                    />
                )}
                {selectedAdminOptionKey === AdminOptionKey.ManualPosting && (
                    <AdminManualPosting
                        handleError={props.handleError}
                    />
                )}
            </Stack.Item>
        </Stack>
    );
};
