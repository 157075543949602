import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    resultTable: {
        marginTop: '10px',
        borderCollapse: 'collapse',
        'th, td': {
            border: '.1px solid lightgray',
            textAlign: 'left'
        } as IStyle
    } as IStyle,
    statusCell: {
        textAlign: 'center',
        padding: 0
    } as IStyle,
    checkPass: {
        fontSize: '20px',
        color: 'green'
    } as IStyle,
    checkFail: {
        fontSize: '20px',
        color: '#e50000'
    } as IStyle
});
