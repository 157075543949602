import React, { useCallback, useEffect, useMemo } from 'react';
import {
    ActionButton,
    ConstrainMode,
    DetailsListLayoutMode,
    DirectionalHint,
    FontIcon,
    SelectionMode,
    Stack,
    Text,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { NavigateFunction, useNavigate } from 'react-router';
import { stackTokensNormalGap, stackTokensSmallGap } from '../../common/common.styles';
import { pageStyles } from './EditPage.styles';
import { useBoolean } from '@fluentui/react-hooks';
import { tooltips } from '../../common/tooltips';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ISearchReducer } from '../../store/reducers/search.reducer';
import { AppDispatch } from '../../store/reduxStore';
import { appConstants } from '../../common/appConstants';
import { miniSearchResultsColumns } from './miniSearchResultsColumns';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { IApiSearch, callApiSearch } from '../../store/actions/search.action';
import { CallApiState } from '../../store/actions/generic.action';
import { deepCopyObject } from '../../common/common.func.general';
import { CustomTeachingBubble, TeachingBubbleItem, teachingBubbleCheckShow } from '../../components/CustomTeachingBubble/CustomTeachingBubble';

const miniSearchResultsExpandedKey: string = 'miniSearchResultsExpanded';

interface IMiniSearchResultsProps {
}

export const MiniSearchResults: React.FunctionComponent<IMiniSearchResultsProps> = (props: IMiniSearchResultsProps): JSX.Element => {
    // Initialize the expanded flag from the local storage. If the key exists and it is 'false' then set this to false.
    // Otherwise if is null (key does not exist) or if it is 'true' or any other value then set this to true (true by default).
    const [expanded, { toggle: toggleExpanded }] = useBoolean(localStorage.getItem(miniSearchResultsExpandedKey) === 'false' ? false : true);

    // Redux store selectors to get state from the store when it changes.
    const apiSearch: IApiSearch =
        useAppSelector<IApiSearch>((state) => state.searchReducer.apiSearch);
    const searchReducer: ISearchReducer =
        useAppSelector<ISearchReducer>((state) => state.searchReducer);
    const editPoNumber: string | undefined =
        useAppSelector((state) => state.searchResultsPageReducer.editPoNumber);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const navigate: NavigateFunction = useNavigate();

    /**
     * Effect for when user clicks a PO number for editing.
     */
    useEffect(() => {
        if (editPoNumber) {
            // Navigate to the edit page for the PO number.
            navigate(`${appConstants.publicUrl}/Edit/${editPoNumber}`);
        }
    }, [editPoNumber, navigate]);

    /**
     * Effect for when expanded changes. Record into local storage the setting.
     * When the user comes back to the page, their last state will be used to initialize the expanded flag.
     */
    useEffect(() => {
        localStorage.setItem(miniSearchResultsExpandedKey, String(expanded));
    }, [expanded]);

    /**
     * Adjusts the height of the search results element. Useful in some cases, like paging (page next/previous),
     * where keeping the height of the search results the same while retrieving the next page of data.
     * This creates a less jarring UI where the height of the search results becomes small when only showing the
     * "Loading..." indicator, then becomes larger when the results appear. After search completes we can set
     * the height back to auto.
     * @param opt Option to maintain height or set to auto.
     */
    const adjustSearchResultsHeight = (opt: 'maintain' | 'auto') => {
        const searchResultsElem: HTMLElement | null = document.getElementById('miniSearchResults');
        if (searchResultsElem) {
            switch (opt) {
                case 'maintain':
                    searchResultsElem.style.height = `${searchResultsElem.offsetHeight}px`;
                    break;
                default:
                case 'auto':
                    searchResultsElem.style.height = 'auto';
                    break;
            }
        }
    };

    /**
     * Effect for when apiSearch returns data.
     */
    useEffect(() => {
        if (apiSearch.callApiState === CallApiState.DataAvailable) {
            // Adjust height back to auto. The height was maintained to previous height when search started.
            adjustSearchResultsHeight('auto');
        }
    }, [apiSearch.callApiState]);

    /**
     * Performs a search.
     * @param newPurchaseOrderSearch New purchase order search.
     */
    const search = useCallback((newPurchaseOrderSearch: PurchaseOrderSearch) => {
        adjustSearchResultsHeight('maintain');
        dispatch(callApiSearch(newPurchaseOrderSearch));
    }, [dispatch]);

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (apiSearch.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSearch.callApiState]);

    /**
     * Memoized field to check if PO header data is present. 
     */
    const headersPresent = useMemo<boolean>(() => {
        if (searchReducer.purchaseOrderHeaders === undefined ||
            searchReducer.purchaseOrderHeaders?.length === 0) {
            return false;
        } else {
            return true;
        }
    }, [searchReducer.purchaseOrderHeaders]);

    /**
     * Render teaching bubble.
     * @param targetElemId Target elem id.
     * @returns JSX element.
     */
    const renderTeachingBubble = (targetElemId: string): JSX.Element => {
        if (teachingBubbleCheckShow(TeachingBubbleItem.MiniSearchResults)) {
            return (
                <div>
                    <CustomTeachingBubble
                        targetElemId={targetElemId}
                        teachingBubbleItem={TeachingBubbleItem.MiniSearchResults}
                        teachingBubbleHeadline="Search results quick access"
                        teachingBubbleContent="Your PO search results are made available on this edit screen so that you can quickly change to other POs. You can collapse or expand this panel."
                        disableIsOnScreenCheck={true}
                    />
                </div>
            );
        }
        return <></>;
    };

    return (
        <Stack horizontal tokens={stackTokensSmallGap}>
            <Stack.Item>
                {!expanded && (
                    <div className={pageStyles.miniSearchResultsExpander}>
                        <TooltipHost content={tooltips.expandMiniSearchResults} calloutProps={{ directionalHint: DirectionalHint.rightCenter }} delay={TooltipDelay.long}>
                            <ActionButton
                                id="miniSearchResultsExpandButton"
                                onClick={toggleExpanded}
                                ariaLabel='Expand to choose any PO from search results'>
                                <FontIcon iconName="DoubleChevronRight" />
                            </ActionButton>
                        </TooltipHost>
                        {renderTeachingBubble('miniSearchResultsExpandButton')}
                    </div>
                )}
                {expanded && (
                    <>
                        <div className={pageStyles.miniSearchResultsExpander}>
                            <TooltipHost content={tooltips.collapseMiniSearchResults} calloutProps={{ directionalHint: DirectionalHint.rightCenter }} delay={TooltipDelay.long}>
                                <ActionButton
                                    id="miniSearchResultsCollapseButton"
                                    onClick={toggleExpanded}
                                    ariaLabel='Collapse search results'>
                                    <FontIcon iconName="DoubleChevronLeft" />
                                </ActionButton>
                            </TooltipHost>
                            {renderTeachingBubble('miniSearchResultsCollapseButton')}
                        </div>
                        <div className={pageStyles.miniSearchResultsHeading}>
                            <Text variant="small">Search<br/>results</Text>
                        </div>
                    </>
                )}
                {expanded && (
                    <Stack tokens={stackTokensNormalGap} className={pageStyles.miniSearchResultsSectionExpanded}>
                        <Stack.Item>
                            <CustomDetailsList
                                id="miniSearchResults"
                                ariaLabelForGrid="Purchase Orders"
                                displayTotalItems={false}
                                showPaginator={true}
                                canJumpToPage={false}
                                initialPagingLabelText=""
                                previousPageLabelText=""
                                nextPageLabelText=""
                                hidePageIndicator={true}
                                showPageSize={false}
                                selectedPage={(searchReducer.purchaseOrderSearch?.metaData.currentPageIndex || 0) + 1}
                                onSelectedPageChange={(page) => {
                                    if (searchReducer.purchaseOrderSearch) {
                                        // Adjust the page to retrieve in the search metadata and rerun the search.
                                        // Using deepCopyObject here because Redux store data should not be modified directly.
                                        const newPurchaseOrderSearch: PurchaseOrderSearch = deepCopyObject(searchReducer.purchaseOrderSearch) as PurchaseOrderSearch;
                                        newPurchaseOrderSearch.metaData.currentPageIndex = page - 1; // -1 because page is 1 based, while currentPageIndex is 0 based.
                                        search(newPurchaseOrderSearch);
                                    }
                                }}
                                showNoDataFoundMsg={!headersPresent}
                                items={searchReducer.purchaseOrderHeaders || []}
                                totalItemsAtServer={searchReducer.purchaseOrderSearch?.metaData.totalRecords}
                                isLoading={isSearchRunning}
                                compact={false}
                                columns={miniSearchResultsColumns}
                                selectionMode={SelectionMode.none}
                                getKey={(item: PurchaseOrderHeader) => item.clientRowKey}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                isHeaderVisible={true}
                                constrainMode={ConstrainMode.horizontalConstrained}
                                onRenderDetailsHeader={() => <></> /* Display no header. */}
                                loadingText=''
                            />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack.Item>
            {expanded && ( // Only show the divider when expanded.
                <Stack.Item>
                    <div className={pageStyles.miniSearchResultsDivider}></div>
                </Stack.Item>
            )}
        </Stack>
    );
};
