import { PurchaseOrderHeader } from './purchaseOrderHeader';
import { PurchaseOrderLineItem } from './purchaseOrderLineItem';

/**
 * Represents purchase order details.
 * Directly models the server side object. If that one changes, then this one must change.
 */
export interface IPurchaseOrderDetails {
    header: PurchaseOrderHeader;
    items?: PurchaseOrderLineItem[];
}

export class PurchaseOrderDetails implements IPurchaseOrderDetails {
    public header: PurchaseOrderHeader;
    public items?: PurchaseOrderLineItem[] = [];

    // Below fields are not part of the server returned object, but are added for client side support.
    public isExpanded: boolean = false; // Used in client to know if row is expanded or not.
    public isLoadingLineItems: boolean = false; // Flag indicating if currently loading line items.
    public noLineItemsFound: boolean = false; // Flag indicating if no line items found after load attempt.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderDetails) {
        this.header = new PurchaseOrderHeader(jsonData.header);

        if (jsonData.items && this.items) {
            for (let i: number = 0; i < jsonData.items.length; i++) {
                this.items.push(new PurchaseOrderLineItem(jsonData.items[i]));
            }
        }
    }

    /**
     * Toggle expanded state of the PO row (for use in table row display).
     */
    public toggleExpanded(): void {
        this.isExpanded = !this.isExpanded;
    }

    /**
     * Expand the PO row (for use in table row display).
     */
    public expand(): void {
        this.isExpanded = true;
    }

    /**
     * Collapse the PO row (for use in table row display).
     */
    public collapse(): void {
        this.isExpanded = false;
    }
}
