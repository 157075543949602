import { FunctionSummary } from './functionSummary';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IOrgDetail extends IHierarchyNodeCommon {
    functionSummary: FunctionSummary[];
}

export class OrgDetail implements IOrgDetail {
    public typeName: string = HierarchyConstants.className.channel.orgDetail;
    public code: string;
    public description: string;
    public functionSummary: FunctionSummary[] = [];
    public children?: IHierarchyNodeCommon[] = this.functionSummary; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IOrgDetail) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.functionSummary) {
            for (let i: number = 0; i < jsonData.functionSummary.length; i++) {
                this.functionSummary.push(new FunctionSummary(jsonData.functionSummary[i]));
            }
        }
    }
}
