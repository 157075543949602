export interface IShipmentTrail {
    statusDateTime?: Date;
    status: string;
}

export class ShipmentTrail implements IShipmentTrail {
    public statusDateTime?: Date;
    public status: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IShipmentTrail) {
        this.statusDateTime = jsonData.statusDateTime ? new Date(jsonData.statusDateTime) : undefined;
        this.status = jsonData.status;
    }
}
