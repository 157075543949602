import React from 'react';
import {
    ActionButton,
    FontIcon,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { stackTokensSmallGap } from '../../common/common.styles';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { componentStyles } from './NotificationPanel.styles';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';
import { formatDateTimeUsingLocale } from '../../common/common.func.datetime';
import { callApiDismissNotification, IApiDismissAllNotifications, IApiDismissNotification } from '../../store/actions/app.action';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CallApiState } from '../../store/actions/generic.action';
import { commonString } from '../../common/commonString';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

export interface INotificationPanelItemProps {
    item: ClientNotificationItem
}

/**
 * Notification item used in notification panel.
 * @returns Profile panel JSX.
 */
export const NotificationPanelItem: React.FunctionComponent<INotificationPanelItemProps> = (props: INotificationPanelItemProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const apiDismissNotification: IApiDismissNotification | undefined =
        useAppSelector<IApiDismissNotification>((state) => state.appReducer.apiDismissNotification);    
    const apiDismissAllNotifications: IApiDismissAllNotifications | undefined =
        useAppSelector<IApiDismissAllNotifications>((state) => state.appReducer.apiDismissAllNotifications);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <div>
            <Separator />
            <Stack horizontal tokens={stackTokensSmallGap}>
                <Stack.Item>
                    {
                        props.item.notificationItemState === ClientNotificationItemState.InProgress && (
                            <Spinner size={SpinnerSize.xSmall} className={componentStyles.itemSpinner} />
                        )
                    }
                    {
                        props.item.notificationItemState === ClientNotificationItemState.Succeeded && (
                            <FontIcon iconName='StatusCircleCheckmark' className={`${componentStyles.itemIcon} ${componentStyles.successIcon}`} />
                        )
                    }
                    {
                        props.item.notificationItemState === ClientNotificationItemState.Failed && (
                            <FontIcon iconName='StatusCircleExclamation' className={`${componentStyles.itemIcon} ${componentStyles.errorIcon}`} />
                        )
                    }
                    {
                        props.item.notificationItemState === ClientNotificationItemState.Unknown && (
                            <FontIcon iconName='StatusCircleQuestionMark' className={componentStyles.itemIcon} />
                        )
                    }
                </Stack.Item>
                <Stack.Item className={componentStyles.itemCenter}>
                    <Stack>
                        <Stack.Item>
                            <Text variant="medium" className={componentStyles.itemHeading}>
                                {props.item.heading}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="medium">
                                {props.item.text}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="smallPlus">
                                {formatDateTimeUsingLocale(props.item.updatedDateUtc)}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item className={componentStyles.itemDelete}>
                    <ActionButton
                        ariaLabel={commonString.delete}
                        disabled={apiDismissAllNotifications.callApiState === CallApiState.Running ||
                            apiDismissNotification.callApiState === CallApiState.Running}
                        onClick={() => {
                            telemetryService.trackEvent({ name: trackedEvent.dismissNotificationButtonClicked });

                            dispatch(callApiDismissNotification(props.item.id));
                        }}
                    >
                        <FontIcon iconName='Delete' />
                    </ActionButton>
                </Stack.Item>
            </Stack>
        </div>
    );
};
