import { IHeaderStyles } from '@coherence-design-system/header';
import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    headerIconContainer: {
        color: 'white',
        fontSize: '20px !important',
        position: 'relative'
    } as IStyle,
    notificationCountBadge: {
        color: 'white',
        position: 'absolute',
        top: '-8px',
        borderRadius: '8px',
        backgroundColor: 'rgb(0, 69, 120)',
        paddingLeft: '3px',
        paddingRight: '3px',
        right: '-12px',
        lineHeight: '16px',
        textAlign: 'center',
        minWidth: '12px',
        height: '16px',
        display: 'inline',
        fontWeight: 500,
        fontSize: '10px'
    } as IStyle,
    siteLabel: {
        fontSize: '16px',
        fontWeight: '600',
        textDecoration: 'none',
        color: 'rgb(255, 255, 255) !important',
        height: '46px',
        lineHeight: '46px',
        whiteSpace: 'nowrap'
    } as IStyle,
    envName: {
        margin: '0 0 0 20px',
        color: 'white'
    } as IStyle,
    userImage: {
        borderRadius: '50%'
    } as IStyle,
    resizeGroup: {
        root: {
            background: 'transparent',
            height: '100%'
        } as IStyle
    } as IStyle,
    searchTemplateContainer: {
        display: 'flex'
    } as IStyle,
    searchContainer: {
        flexGrow: 1
    } as IStyle,
    pstTimeDisplay: {
        margin: '0 0 0 40px',
        lineHeight: '16px',
        color: 'white'
    } as IStyle,
    pstTimeDisplayLabel: {
        fontSize: '10px',
        whiteSpace: 'nowrap'
    } as IStyle,
    pstTimeDisplayDateTime: {
        fontSize: '10px',
        whiteSpace: 'nowrap'
    } as IStyle
});

export const headerStyles = {
    hamburgerContainer: {
        button: {
            color: 'white'
        } as IStyle,
        'button:hover': {
            color: 'white'
        } as IStyle
    } as IStyle
} as IHeaderStyles;
