import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    blockedIcon: {
        margin: '0 10px 0 0'
    } as IStyle
});
