import React, { useEffect, useState } from 'react';
import { Checkbox } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { priorYearAccrualLineItemSelection } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { AppDispatch } from '../../store/reduxStore';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { closeLineItemSelection } from '../../store/actions/pageActions/editPage.action';
import { componentStyles } from './LineItemSelectionCheckbox.styles';
import { commonString } from '../../common/commonString';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { extractPoLineFromNotificationId, isEqualIgnorePadding } from '../../common/common.func.general';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';

interface ILineItemSelectionCheckboxProps {
    usedOnPage: 'priorYearAccrual' | 'closeLine'; // Line can only be closed on these pages.
    item: PurchaseOrderLineItem | PriorYearAccrualLineItem;
}

export const LineItemSelectionCheckbox: React.FunctionComponent<ILineItemSelectionCheckboxProps> = (props: ILineItemSelectionCheckboxProps): JSX.Element => {
    // Both PurchaseOrderLineItem and PriorYearAccrualLineItem have an isClosed and canClose property.
    const [isClosed, setIsClosed] = useState<boolean>(props.item.isClosed);
    const [canClose, setCanClose] = useState<boolean>(props.item.canClose);
    
    // Redux store selectors to get state from the store when it changes.
    const clientNotificationItems: ClientNotificationItem[] =
        useAppSelector<ClientNotificationItem[]>((state) => state.appReducer.clientNotificationItems);
    
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect for when props.isClosed changes.
     */
    useEffect(() => {
        setIsClosed(props.item.isClosed);
    }, [props.item.isClosed]);

    /**
     * Effect for when props.canClose changes.
     */
    useEffect(() => {
        setCanClose(props.item.canClose);
    }, [props.item.canClose]);

    /**
     * Effect for when clientNotificationItems changes.
     * We watch for changes to this in order to change the closed indicator and closed checkbox when notifications
     * come in via SignalR.
     */
    useEffect(() => {
        clientNotificationItems.forEach(item => {
            // If the item is pre-existing (meaning the item existed at app-load time), then do nothing here.
            // We only want to do this update for notification items that come in after the app and any existing
            // notifications have already loaded. The reason for this is: Consider if there are notification items
            // that are for a PO line item in a failed state. If the user refreshes the page, we want the status
            // indicator to be what came back from the API to load the line items and not be altered by any existing
            // notificaiton.
            if (item && item.id && !item.isPreExisting) {
                // See if the notification item is for the PO/Line for this item.
                const extracted: {poNumber?: string, lineItem?: string} = extractPoLineFromNotificationId(item.id);
                const { poNumber, lineItem } = extracted;
                if (
                    (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem && isEqualIgnorePadding(props.item.purchaseOrder, poNumber || '') && isEqualIgnorePadding(props.item.lineItem, lineItem || '')) ||
                    ((props.usedOnPage === 'closeLine') && props.item instanceof PurchaseOrderLineItem && isEqualIgnorePadding(props.item.purchaseOrderNumber, poNumber || '') && isEqualIgnorePadding(props.item.purchaseOrderLineNumber, lineItem || ''))
                ) {
                    switch (item.notificationItemState) {
                        case ClientNotificationItemState.Succeeded: {
                            setIsClosed(true);
                            setCanClose(false);
                            break;
                        }
                        default:
                    }
                }
            }
        })
    }, [clientNotificationItems, props.item, props.usedOnPage]);

    return (
        <div className={componentStyles.container}>
            <Checkbox
                className={componentStyles.gridRowCheckbox}
                ariaLabel={commonString.selectLineItem}
                checked={
                    isClosed ||
                    // If on the PYA page, check if the line item is selected. The PriorYearAccrualLineItem object has
                    // an isSelected property, but the PurchaseOrderLineItem object does not. When checking this checkbox
                    // from the close line page, the isClosed property is set.
                    (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem && props.item.isSelected) ||
                    props.item.offlineProcessingRunning
                }
                // Note that canClose is used to determine disabled. When this component is used on the prior year accrual
                // page the checkbox could be used to mark the line for retain or close. There is no canRetain at this
                // time, so canClose will be used to determine disabled in either case.
                disabled={
                    !canClose ||
                    props.item.offlineProcessingRunning ||
                    // Note that there is no similar isLineEnabledForGSR on a PriorYearAccrualLineItem.
                    (props.item instanceof PurchaseOrderLineItem && !props.item.isLineEnabledForGSR)
                }
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                    if (props.usedOnPage === 'closeLine' && props.item instanceof PurchaseOrderLineItem) {
                        dispatch(closeLineItemSelection(props.item.purchaseOrderLineNumber, checked || false));
                    } else if (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem) {
                        dispatch(priorYearAccrualLineItemSelection(props.item, checked || false));
                    }
                }}
            />
        </div>
    );
}
