import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import {
    IApiShipmentFileProcessingStatus,
    IApiShipmentUploadShipmentDetails,
    IApiShipmentDownloadShipmentDetails
} from '../../actions/pageActions/shipmentAdminPage.action';

export interface IShipmentAdminPageReducerState {
    apiShipmentFileProcessingStatus: IApiShipmentFileProcessingStatus;
    apiShipmentUploadShipmentDetails: IApiShipmentUploadShipmentDetails;
    apiShipmentDownloadShipmentDetails: IApiShipmentDownloadShipmentDetails;
}

const initialShipmentAdminPageReducerState: IShipmentAdminPageReducerState = {
    apiShipmentFileProcessingStatus: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        shipmentFileProcessingStatus: undefined
    },
    apiShipmentUploadShipmentDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiShipmentDownloadShipmentDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
};

export const shipmentAdminPageReducer: Reducer<IShipmentAdminPageReducerState, AnyAction> = (
    state: IShipmentAdminPageReducerState = initialShipmentAdminPageReducerState, action: AnyAction
): IShipmentAdminPageReducerState => {
    switch (action.type) {
        case actionTypes.API_SHIPMENT_FILE_PROCESSING_STATUS: {
            const payload: IApiShipmentFileProcessingStatus = action.payload as IApiShipmentFileProcessingStatus;
            return {
                ...state,
                apiShipmentFileProcessingStatus: { ...payload }
            } as IShipmentAdminPageReducerState;
        }
        case actionTypes.API_SHIPMENT_UPLOAD_SHIPMENT_DETAILS: {
            const payload: IApiShipmentFileProcessingStatus = action.payload as IApiShipmentFileProcessingStatus;
            return {
                ...state,
                apiShipmentUploadShipmentDetails: { ...payload }
            } as IShipmentAdminPageReducerState;
        }
        case actionTypes.API_SHIPMENT_DOWNLOAD_SHIPMENT_DETAILS: {
            const payload: IApiShipmentFileProcessingStatus = action.payload as IApiShipmentFileProcessingStatus;
            return {
                ...state,
                apiShipmentDownloadShipmentDetails: { ...payload }
            } as IShipmentAdminPageReducerState;
        }
        default:
    }

    return state;
};
