import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiExcelExport, IApiExcelImport } from '../../actions/miscActions/importExport.action';

export interface IImportExportReducerState {
    apiExcelImport: IApiExcelImport;
    apiExcelExport: IApiExcelExport;
}

const initialImportExportReducerState: IImportExportReducerState = {
    apiExcelImport: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiExcelExport: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
}

export const importExportReducer: Reducer<IImportExportReducerState, AnyAction> = (
    state: IImportExportReducerState = initialImportExportReducerState, action: AnyAction
): IImportExportReducerState => {
    switch (action.type) {
        case actionTypes.API_EXCEL_IMPORT: {
            const payload: IApiExcelImport = action.payload as IApiExcelImport;
            return {
                ...state,
                apiExcelImport: { ...payload }
            } as IImportExportReducerState
        }
        case actionTypes.API_EXCEL_EXPORT: {
            const payload: IApiExcelExport = action.payload as IApiExcelExport;
            return {
                ...state,
                apiExcelExport: { ...payload }
            } as IImportExportReducerState
        }
        default:
    }

    return state;
};
