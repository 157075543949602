import React, { useState, useEffect, useId, RefObject } from 'react';
import { appConfig } from '../appConfig';
import {
    Checkbox,
    DefaultButton,
    Panel,
    PanelType,
    Stack,
    TooltipHost,
    Image,
    Text,
    Separator,
    Link,
    IPanel
} from '@fluentui/react';
import { localStorageKey } from '../../common/appConstants';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { checkIfTeachingBubblesEnabled, enableTeachingBubbles, resetTeachingBubbles } from '../../components/CustomTeachingBubble/CustomTeachingBubble';
import { reloadSite } from '../../common/common.func.general';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { componentStyles } from './ProfilePanel.styles';
import { getMsalInstance, getUserEmail, getUserFullName } from '../../services/auth/msalHelper';

export interface IProfilePanelProps {
    componentRef?: RefObject<IPanel>;
    isOpen: boolean;
    onDismiss: () => void;
    userImage?: string;
}

// Using the word 'tips' rather than 'bubble' in the UI, in code this is referred to as a bubble.
const defaultResetTeachingBubbleButtonText: string = 'Reset teaching tips';
const completedResetTeachingBubbleButtonText: string = 'Reset completed';

/**
 * Profile panel used in the Coherence header.
 * @returns Profile panel JSX.
 */
export const ProfilePanel: React.FunctionComponent<IProfilePanelProps> = (props: IProfilePanelProps): JSX.Element => {
    const [isTeachingBubblesEnabled, setIsTeachingBubblesEnabled] = useState<boolean>(checkIfTeachingBubblesEnabled());

    const resetTeachingBubblesTooltipId = useId();
    const [resetTeachingBubbleButtonText, setResetTeachingBubbleButtonText] = useState<string>(defaultResetTeachingBubbleButtonText);

    /**
     * Effect for when isTeachingBubblesEnabled changes.
     */
    useEffect(() => {
        enableTeachingBubbles(isTeachingBubblesEnabled);
    }, [isTeachingBubblesEnabled]);

    return (
        <Panel
            componentRef={props.componentRef}
            headerText="Profile"
            isOpen={props.isOpen}
            onDismiss={() => {
                props.onDismiss();
            }}
            closeButtonAriaLabel="Close"
            overlayProps={{
                className: commonStyles.panelOverlay
            }}
            className={commonStyles.panel}
            type={PanelType.smallFixedFar}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return (
                    <div id="build" className={componentStyles.buildNumber}>
                        Build: {appConfig.current.build}
                    </div>
                );
            }}
        >
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Stack horizontal tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            {props.userImage && (
                                <Image src={props.userImage} height={72} width={72} className={componentStyles.userImage} />
                            )}
                        </Stack.Item>
                        <Stack.Item align='center'>
                            <Text variant="medium" className={componentStyles.userName}>{getUserFullName()}</Text><br />
                            <Text variant="medium">{getUserEmail()}</Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Separator />
                </Stack.Item>
                { appConfig.current.settings.environmentName !== 'PROD' && (
                    <Stack.Item>
                        <Checkbox
                            label="Use mock data"
                            checked={appConfig.current.service.useLocalMockData}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean)=> {
                                // See code in appConfig.ts where this is read during startup.
                                localStorage.setItem(localStorageKey.useLocalMockData, checked!.toString());
                                reloadSite();
                            }}
                        />
                    </Stack.Item>
                )}
                <Stack.Item>
                    <Checkbox
                        label="Enable teaching tips"
                        checked={isTeachingBubblesEnabled}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean)=> {
                            setIsTeachingBubblesEnabled(checked === true);
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TooltipHost
                        content="Clicking this will cause all 'teaching tips' that you previously clicked 'Got It' will reappear."
                        id={resetTeachingBubblesTooltipId}
                    >
                        <DefaultButton
                            aria-describedby={resetTeachingBubblesTooltipId}
                            text={resetTeachingBubbleButtonText}
                            onClick={() => {
                                telemetryService.trackEvent({ name: trackedEvent.resetTeachingBubbleButtonClicked });

                                // Reset all teaching bubble data in local storage.
                                resetTeachingBubbles();
                                // Update local state to enable teaching bubbles if it was not enabled.
                                // There is an effect above that will also update the local storage to enable it.
                                setIsTeachingBubblesEnabled(true);

                                // Change the button text temporarily, then back again.
                                setResetTeachingBubbleButtonText(completedResetTeachingBubbleButtonText);
                                setTimeout(() => {
                                    setResetTeachingBubbleButtonText(resetTeachingBubbleButtonText);
                                }, 1500);
                            }}
                            disabled={resetTeachingBubbleButtonText === completedResetTeachingBubbleButtonText}
                        />
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item>
                    <Separator />
                </Stack.Item>
                <Stack.Item>
                    <Link
                        onClick={() => {
                            getMsalInstance().logoutRedirect();
                        }}
                    >
                        Sign out
                    </Link>
                </Stack.Item>
            </Stack>
        </Panel>
    );
};
