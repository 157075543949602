import { combineReducers } from 'redux';
import { configureStore, Reducer } from '@reduxjs/toolkit'
import { homePageReducer, IHomePageReducerState } from './reducers/pageReducers/homePage.reducer';
import { IUserProfilePageReducerState, userProfilePageReducer } from './reducers/pageReducers/userProfilePage.reducer';
import { accessRequestPageReducer, IAccessRequestPageReducerState } from './reducers/pageReducers/accessRequestPage.reducer';
import { IReportsPageReducerState, reportsPageReducer } from './reducers/pageReducers/reportsPage.reducer';
import { accrualDashboardPageReducer, IAccrualDashboardPageReducerState } from './reducers/pageReducers/accrualDashboardPage.reducer';
import { driToolsPageReducer, IDriToolsPageReducerState } from './reducers/pageReducers/driToolsPage.reducer';
import { appReducer, IAppReducerState } from './reducers/app.reducer';
import { ISearchResultsPageReducerState, searchResultsPageReducer } from './reducers/pageReducers/searchResultsPage.reducer';
import { editPageReducer, IEditPageReducerState } from './reducers/pageReducers/editPage.reducer';
import { ISearchReducer, searchReducer } from './reducers/search.reducer';
import { auditHistoryReducer, IAuditHistoryReducerState } from './reducers/miscReducers/auditHistory.reducer';
import { IPriorYearAccrualPageReducerState, priorYearAccrualPageReducer } from './reducers/pageReducers/priorYearAccrualPage.reducer';
import { IImportExportReducerState, importExportReducer } from './reducers/miscReducers/importExport.reducer';
import { IShipmentStatusTrailReducerState, shipmentStatusTrailReducer } from './reducers/miscReducers/shipmentStatusTrail.reducer';
import { IShipmentAdminPageReducerState, shipmentAdminPageReducer } from './reducers/pageReducers/shipmentAdminPage.reducer';

// Useful links:
// https://redux.js.org/
// https://react-redux.js.org/
// https://redux-toolkit.js.org/
// https://www.npmjs.com/package/redux-thunk
// https://github.com/reduxjs/redux-devtools

// This CombinedReducerStates type contains all the reducers used in the app.
export type CombinedReducerStates = {
    appReducer: Reducer<IAppReducerState>;
    homePageReducer: Reducer<IHomePageReducerState>;
    searchResultsPageReducer: Reducer<ISearchResultsPageReducerState>;
    editPageReducer: Reducer<IEditPageReducerState>;
    userProfilePageReducer: Reducer<IUserProfilePageReducerState>;
    accessRequestPageReducer: Reducer<IAccessRequestPageReducerState>;
    reportsPageReducer: Reducer<IReportsPageReducerState>;
    accrualDashboardPageReducer: Reducer<IAccrualDashboardPageReducerState>;
    searchReducer: Reducer<ISearchReducer>;
    priorYearAccrualPageReducer: Reducer<IPriorYearAccrualPageReducerState>;
    driToolsPageReducer: Reducer<IDriToolsPageReducerState>;
    auditHistoryReducer: Reducer<IAuditHistoryReducerState>;
    shipmentStatusTrailReducer: Reducer<IShipmentStatusTrailReducerState>;
    importExportReducer: Reducer<IImportExportReducerState>;
    shipmentAdminPageReducer: Reducer<IShipmentAdminPageReducerState>;
}

const combinedReducers: CombinedReducerStates = {
    appReducer: appReducer,
    homePageReducer: homePageReducer,
    searchResultsPageReducer: searchResultsPageReducer,
    editPageReducer: editPageReducer,
    userProfilePageReducer: userProfilePageReducer,
    accessRequestPageReducer: accessRequestPageReducer,
    reportsPageReducer: reportsPageReducer,
    accrualDashboardPageReducer: accrualDashboardPageReducer,
    searchReducer: searchReducer,
    priorYearAccrualPageReducer: priorYearAccrualPageReducer,
    driToolsPageReducer: driToolsPageReducer,
    auditHistoryReducer: auditHistoryReducer,
    shipmentStatusTrailReducer: shipmentStatusTrailReducer,
    importExportReducer: importExportReducer,
    shipmentAdminPageReducer: shipmentAdminPageReducer
}

const rootReducer = combineReducers(combinedReducers);

export const reduxStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        // https://redux-toolkit.js.org/api/getDefaultMiddleware
        getDefaultMiddleware({
            serializableCheck: false,
            // Disabling as it makes local debugging very slow. When doing Redux related development, it is a
            // good idea to turn this on to ensure you are not making mistakes in reducers.
            immutableCheck: false
        })
});

// See: https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
