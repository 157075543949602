import { createShortGuid } from '../../../common/common.func.guid';
import { SupplierShipmentDetail } from '../../../models/shipment/supplierShipmentDetail';
import { shipmentApiClient } from '../../../services/api/shipmentApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';

/**
 * Payload used with callApiShipmentStatusTrail action.
 */
export interface IApiShipmentStatusTrail extends ICallApiBase {
    supplierShipmentDetails?: SupplierShipmentDetail[] | null;
}

/**
 * Call API to load shipment status trail.
 * @returns Redux dispatch function.
 */
export const callApiShipmentStatusTrail = (purchaseOrderNumber: string, purchaseOrderLineItemNumber: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierShipmentDetail[] | null>(
        actionTypes.API_SHIPMENT_STATUS_TRAIL,
        async () => {
            return await shipmentApiClient.shipmentStatusTrail(Number(purchaseOrderNumber), Number(purchaseOrderLineItemNumber));
        },
        (payload: IApiShipmentStatusTrail, data: SupplierShipmentDetail[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: SupplierShipmentDetail) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.supplierShipmentDetails = data;
        }
    );
};
