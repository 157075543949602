import { IChoiceGroupOption } from '@fluentui/react';

export enum RequestAccessOptionKey {
    FinanceControllerSelf = 'FinanceControllerSelf',
    FinanceControllerOther = 'FinanceControllerOther',
    Agent = 'Agent'
}

/**
 * Get request access options.
 * @param isCurrentFinanceController Is currently a finance controller flag.
 * @returns Options for the request access choice group.
 */
export const getRequestAccessOptions = (isCurrentFinanceController: boolean): IChoiceGroupOption[] => {
    let currentFcText: string = '';
    if (isCurrentFinanceController) {
        currentFcText = ' You are currently a Finance Controller. If you need to make changes to your configuration, you can do so here, and new request to modify your settings will be sent to your manager.';
    }

    return [
        {
            key: RequestAccessOptionKey.FinanceControllerSelf,
            text: `Finance Controller (for yourself) - Can access POs for selected company codes, hierarchies, or vendors.${currentFcText}`,
            ariaLabel: 'Finance Controller for yourself'
        },
        {
            key: RequestAccessOptionKey.FinanceControllerOther,
            text: 'Finance Controller (for someone else) - Can access POs for selected company codes, hierarchies, or vendors.',
            ariaLabel: 'Finance Controller for someone else'
        },
        {
            key: RequestAccessOptionKey.Agent,
            text: 'Agent - Can access any PO. For internal operations users only.',
            ariaLabel: 'Agent'
        }
    ];
}

/**
 * These access request group ids are holdovers from CIM (an old precursor system before GSR).
 * These could change now, if desired, before changing check both server side and client side code.
 */
export const accessRequestGroupId = {
    agentRoleGroupId: 2,
    financeControllerRoleGroupId: 3
};

/**
 * Submit success messages.
 */
export const submitSuccessMessage = {
    agent: 'Your request has been submitted. You will receive an email once the request has been processed.',
    financeControllerSelf: 'Your request has been submitted to your nearest FTE manager for approval. You will receive an email once the request has been processed.',
    financeControllerOther: 'Your request has been submitted to the nearest FTE manager of the person you are requesting on behalf of for approval. They will receive an email once the request has been processed.'
};
