import { ClientNotificationItem } from './clientNotificationItem';

export interface IClientNotificationData {
    items: ClientNotificationItem[];
    totalItemCount: number;
}

export class ClientNotificationData implements IClientNotificationData {
    public items: ClientNotificationItem[] = [];
    public totalItemCount: number;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IClientNotificationData) {
        if (jsonData.items) {
            for (let i: number = 0; i < jsonData.items.length; i++) {
                this.items.push(new ClientNotificationItem(jsonData.items[i]));
            }
        }

        this.totalItemCount = jsonData.totalItemCount;
    }

    /**
     * Check to see if any of the notification items are recent, rather than all old/prior existing items.
     * Recent will be considered within the last 24 hours.
     * @returns True if contains recent notification items.
     */
    public containsRecentClientNotificationItems(): boolean {
        if (!this.items || this.items.length === 0) {
            return false;
        }

        const yesterdaysDate: Date = new Date();
        yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);

        for (let i: number = 0; i < this.items.length; i++) {
            // New a date here as updatedDateUtc is in string format when returned in json.
            const itemDate: Date = new Date(this.items[i].updatedDateUtc);
            if (itemDate > yesterdaysDate) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns recent client notifications items. Recent items are ones that are 1 day old or newer.
     * @returns Recent notification items.
     */
    public recentClientNotificationItems(): ClientNotificationItem[] {
        const items: ClientNotificationItem[] = [];
        if (!this.items || this.items.length === 0) {
            return items;
        }

        const yesterdaysDate: Date = new Date();
        yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);

        for (let i: number = 0; i < this.items.length; i++) {
            // New a date here as updatedDateUtc is in string format when returned in json.
            const itemDate: Date = new Date(this.items[i].updatedDateUtc);
            if (itemDate > yesterdaysDate) {
                items.push(this.items[i]);
            }
        }

        return items;
    }
}
