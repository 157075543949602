import React from 'react';
import { componentStyles } from './Tile.styles';

/**
 * Tile props.
 */
export interface ITileProps {
    barColor: string;
    children?: React.ReactNode;
}

/**
 * Tile component.
 * @param props Data tile props.
 */
export const Tile: React.FunctionComponent<ITileProps> = (props: ITileProps): JSX.Element => {
    return (
        <div className={componentStyles.tile}>
            <table role="presentation">
                <tbody>
                    <tr>
                        <td className={componentStyles.colorBar} style={{ backgroundColor: props.barColor }} />
                        <td className={componentStyles.tileContent}>
                            {props.children}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
