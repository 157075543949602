import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    headerWrapper: {
        paddingLeft: '24px',
        paddingRight: '24px',
        alignSelf: 'flex-start',
        flexGrow: 1
    } as IStyle,
    headerText: {
        fontSize: '20px',
        fontWeight: 600,
        color: 'rgb(50, 49, 48)',
        lineHeight: '27px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        hyphens: 'auto'
    } as IStyle,
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        'td:first-child': {
            fontWeight: '500'
        } as IStyle,
        'td:last-child': {
        } as IStyle
    } as IStyle,
    subHeading: {
        textAlign: 'center'
    } as IStyle,
    statusDate: {
        fontWeight: 'unset!important'
    } as IStyle,
    scrollToBottom: {
        width: 'fit-content',
        fontSize: '14px',
        color: '#0070CC',
        ':hover': {
            color: '#0000EE',
            cursor: 'pointer'
        } as IStyle
    } as IStyle
});
