import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    diagnosticDisplay: {
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        width: 'calc(100% - 20px)',
        marginBottom: '20px',
        marginRight: '14px',
        'td': {
            border: '1px solid lightgray'
        } as IStyle,
        'td:first-child': {
            color: 'darkslateblue',
            fontSize: '16px',
            width: '110px',
            textAlign: 'center'
        } as IStyle,
        'td:last-child': {
            color: 'blue',
            overflow: 'auto'
        } as IStyle
    } as IStyle,
    jwtDisplay: {
        overflowWrap: 'break-word'
    } as IStyle,
    jsonBlock: {
        display: 'inline-block',
        'code': {
            color: '#727272'
        } as IStyle
    } as IStyle,
    jwtMsLinkContainer: {
        marginTop: '15px'
    } as IStyle,
    copyToClipboardButton: {
        marginTop: '10px'
    } as IStyle
});
