import { HierarchyConstants } from '../../models/hierarchy/hierarchyConstants';
import { IHierarchyNodeCommon } from '../../models/hierarchy/hierarchyNodeCommon';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { IHierarchyTreeItemState } from './IHierarchyTreeItemState';

/**
 * Get icon to use for the hierarchy tree node.
 * @param typeName Type name of the node.
 * @returns Path to icon.
 */
export const getHierarchyNodeIcon = (typeName: string): string => {
    const imgPath: string = 'images/hierarchy/';

    switch (typeName) {
        // Channel
        case HierarchyConstants.className.channel.functionCode:
            return `${imgPath}function.svg`;
        case HierarchyConstants.className.channel.functionDetailCode:
            return `${imgPath}function-detail.svg`;
        case HierarchyConstants.className.channel.functionSummary:
            return `${imgPath}function-summary.svg`;
        case HierarchyConstants.className.channel.orgCode:
            return `${imgPath}org.svg`;
        case HierarchyConstants.className.channel.orgDetail:
            return `${imgPath}org-detail.svg`;
        case HierarchyConstants.className.channel.orgSummary:
            return `${imgPath}org-summary.svg`;
        // Executive
        case HierarchyConstants.className.executive.execFunctionCode:
            return `${imgPath}function.svg`;
        case HierarchyConstants.className.executive.execFunctionDetailCode:
            return `${imgPath}function-detail.svg`;
        case HierarchyConstants.className.executive.execFunctionSummary:
            return `${imgPath}function-summary.svg`;
        case HierarchyConstants.className.executive.execOrg:
            return `${imgPath}org.svg`;
        case HierarchyConstants.className.executive.execOrgDetail:
            return `${imgPath}org-detail.svg`;
        case HierarchyConstants.className.executive.execOrgSummary:
            return `${imgPath}org-summary.svg`;
        // Geography
        case HierarchyConstants.className.geography.area:
            return `${imgPath}area.svg`;
        case HierarchyConstants.className.geography.areaSummary:
            return `${imgPath}area-summary.svg`;
        case HierarchyConstants.className.geography.region:
            return `${imgPath}region.svg`;
        case HierarchyConstants.className.geography.salesDistrict:
            return `${imgPath}sales-district.svg`;
        case HierarchyConstants.className.geography.salesLocation:
            return `${imgPath}sales-location.svg`;
        case HierarchyConstants.className.geography.salesSubLocation:
            return `${imgPath}sales-sub-location.svg`;
        case HierarchyConstants.className.geography.subRegion:
            return `${imgPath}sub-region.svg`;
        default:
            return '';
    }
};

/**
 * Get description to use for the hierarchy tree node.
 * @param typeName Type name of the node.
 * @returns Description of the hierarchy tree node.
 */
export const getHierarchyNodeDescription = (typeName: string): string => {
    switch (typeName) {
        // Channel
        case HierarchyConstants.className.channel.functionCode:
            return 'Function';
        case HierarchyConstants.className.channel.functionDetailCode:
            return 'Function Detail';
        case HierarchyConstants.className.channel.functionSummary:
            return 'Function Summary';
        case HierarchyConstants.className.channel.orgCode:
            return 'Org';
        case HierarchyConstants.className.channel.orgDetail:
            return 'Org Detail';
        case HierarchyConstants.className.channel.orgSummary:
            return 'Org Summary';
        // Executive
        case HierarchyConstants.className.executive.execFunctionCode:
            return 'Exec Function';
        case HierarchyConstants.className.executive.execFunctionDetailCode:
            return 'Exec Function Detail';
        case HierarchyConstants.className.executive.execFunctionSummary:
            return 'Exec Function Summary';
        case HierarchyConstants.className.executive.execOrg:
            return 'Exec Org';
        case HierarchyConstants.className.executive.execOrgDetail:
            return 'Exec Org Detail';
        case HierarchyConstants.className.executive.execOrgSummary:
            return 'Exec Org Summary';
        // Geography
        case HierarchyConstants.className.geography.area:
            return 'Area';
        case HierarchyConstants.className.geography.areaSummary:
            return 'Area Summary';
        case HierarchyConstants.className.geography.region:
            return 'Region';
        case HierarchyConstants.className.geography.salesDistrict:
            return 'Sales District';
        case HierarchyConstants.className.geography.salesLocation:
            return 'Sales Location';
        case HierarchyConstants.className.geography.salesSubLocation:
            return 'Sales Sub Location';
        case HierarchyConstants.className.geography.subRegion:
            return 'Sub Region';
        default:
            return '';
    }
};

/**
 * Gets selected count from hierarchy tree item states. Selected items are ones that are checked and are leaf nodes.
 * @param itemStates Item states.
 * @returns Selected item count.
 */
export const getHierarchySelectedCount = (itemStates: IKeyValuePair<IHierarchyTreeItemState>) => {
    let count: number = 0;
    Object.keys(itemStates).forEach(key => {
        if (itemStates[key].isChecked && itemStates[key].isLeaf) {
            count++;
        }
    });
    return count;
};

/**
 * Get total leaf node count.
 * @param nodes Node implementing IHierarchyNodeCommon.
 * @returns Total leaf node count.
 */
export const getHierarchyLeafNodeCount = (nodes: IHierarchyNodeCommon[] | undefined) => {
    let count: number = 0;

    if (!nodes) {
        return count;
    }

    nodes.forEach(node => {
        if (node.children) {
            count += getHierarchyLeafNodeCount(node.children)
            return count;
        } else {
            return ++count;
        }
    });

    return count;
};

/**
 * Gets hierarchy codes from key value pairs.
 * @param itemStates Item states.
 * @returns Selected item count.
 */
export const getHierarchyCodes = (itemStates: IKeyValuePair<IHierarchyTreeItemState>) => {
    const codes: string[] = [];
    Object.keys(itemStates).forEach(key => {
        if (itemStates[key].isChecked && itemStates[key].isLeaf) {
            codes.push(key);
        }
    });
    return codes;
};
