import { ExecOrgSummary } from './execOrgSummary';

export interface IExecutiveFunction {
    execOrgSummary: ExecOrgSummary[];
}

/**
 * User defined type guard to determine if object is a ExecutiveFunction.
 * @param obj Object to check if an executive function.
 * @returns Type guard indicating if object is an executive function.
 */
export function isExecutiveFunction(obj: any): obj is ExecutiveFunction {
    return 'execOrgSummary' in obj;
}

export class ExecutiveFunction implements IExecutiveFunction {
    public execOrgSummary: ExecOrgSummary[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IExecutiveFunction) {
        if (jsonData.execOrgSummary) {
            for (let i: number = 0; i < jsonData.execOrgSummary.length; i++) {
                this.execOrgSummary.push(new ExecOrgSummary(jsonData.execOrgSummary[i]));
            }
        }
    }
}
