import React from 'react';
import { IColumn } from '@fluentui/react';
import { commonString } from '../../common/commonString';
import { UserDelegate } from '../../models/user/userDelegate';
import { commonStyles } from '../../common/common.styles';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { appConstants } from '../../common/appConstants';
import { AccessRight } from '../../common/appEnums';

export const delegateFromColumns: IColumn[] = [
    {
        key: 'alias',
        fieldName: 'alias',
        name: commonString.alias,
        isResizable: true,
        minWidth: 100
    },
    {
        key: 'accessRights',
        fieldName: 'accessRights',
        name: commonString.accessRights,
        isResizable: true,
        minWidth: 160,
        onRender: (item: UserDelegate) => {
            let rights: string = '';
            if (item.accessRights.indexOf(AccessRight.PoRequestor) > -1) {
                rights += commonString.poOwner;
            }
            if (item.accessRights.indexOf(AccessRight.InvoiceApprover) > -1) {
                if (rights.length > 0) {
                    rights += ', '
                }
                rights += commonString.invoiceApprover;
            }

            return rights;
        }
    },
    {
        key: 'companyCodes',
        fieldName: 'companyCodes',
        name: commonString.companyCodes,
        isResizable: true,
        minWidth: 140,
        onRender: (item: UserDelegate) => {
            let companyCodes: string = '';

            for (let i: number = 0; i < item.companyCodes.length; i++) {
                if (i > 0) {
                    companyCodes += ', '
                }
                companyCodes += (item.companyCodes[i] === appConstants.allCompanyCodeValue ? commonString.allCompanyCodes : item.companyCodes[i]);
            }

            return <div className={commonStyles.wrapText}>
                {companyCodes}
            </div>;
        }
    },
    {
        key: 'startDate',
        fieldName: 'startDate',
        name: commonString.startDate,
        isResizable: false,
        minWidth: 80,
        onRender: (item: UserDelegate) => {
            return formatDateUsingLocale(item.startDate);
        }
    },
    {
        key: 'endDate',
        fieldName: 'endDate',
        name: commonString.endDate,
        isResizable: false,
        minWidth: 80,
        onRender: (item: UserDelegate) => {
            return formatDateUsingLocale(item.endDate);
        }
    }
];
