import { AnyAction, Reducer } from 'redux';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import * as actionTypes from '../actions/actionTypes';
import { CallApiState } from '../actions/generic.action';
import { IApiHomeTileInfo, IApiSearch, IApiSearchForPo } from '../actions/search.action';
import { PurchaseOrderCheckResult } from '../../models/purchaseOrder/purchaseOrderCheckResult';
import { IComboBoxOption } from '@fluentui/react';
import { IncludeGoodsServicesOptionKey, IncludeReceivedOptionKey } from '../../pages/HomePage/homePageConstants';
import { nowDate, oneMonthAgoDate } from '../../common/common.func.datetime';
import { Role } from '../../common/appEnums';

// This file contains Redux reducer for search capabilities used across multiple pages in the app
// such as the home page, search results page, and edit page.

export interface ISearchReducer {
    apiHomeTileInfo: IApiHomeTileInfo,
    apiSearch: IApiSearch,
    apiSearchForPo: IApiSearchForPo,
    purchaseOrderSearch?: PurchaseOrderSearch;
    searchAsRole?: Role;
    purchaseOrderHeaders?: PurchaseOrderHeader[];
    purchaseOrderCheckResults?: PurchaseOrderCheckResult[];
    // InputFilter is used to store all user input, so that it is persisted between page navigation.
    // When the user returns to the home page, the filters they set previously will remain.
    inputFilter: {
        driSearchEnabled: boolean;
        filterPoNumber: string;
        filterSupplierNumber: string;
        filterCompanyCode: string;
        filterPoApprover: string;
        filterPoOwner: string;
        filterInvoiceApprover: string;
        filterDateCreatedFrom: Date | undefined;
        filterDateCreatedTo: Date | undefined;
        filterDeliveryDateFrom: Date | undefined;
        filterDeliveryDateTo: Date | undefined;
        filterDelegatedBy: string[] | undefined;
        filterDelegatedByOptions: IComboBoxOption[];
        filterHierarchySalesDistrictCodes: string[];
        filterHierarchyChannelFunctionDetailCodes: string[];
        filterHierarchyExecutiveFunctionDetailCodes: string[];
        filterIncludeReceived: IncludeReceivedOptionKey;
        filterIncludeGoodsServices: IncludeGoodsServicesOptionKey;
    }
}

const initialSearchReducer: ISearchReducer = {
    apiHomeTileInfo: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        dashboardTiles: undefined
    },
    apiSearch: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        purchaseOrderSearchResults: undefined
    },
    apiSearchForPo: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        purchaseOrderSearchResults: undefined
    },
    purchaseOrderSearch: undefined,
    searchAsRole: undefined,
    purchaseOrderHeaders: undefined,
    purchaseOrderCheckResults: undefined,
    inputFilter: {
        driSearchEnabled: false,
        filterPoNumber: '',
        filterSupplierNumber: '',
        filterCompanyCode: '',
        filterPoApprover: '',
        filterPoOwner: '',
        filterInvoiceApprover: '',
        filterDateCreatedFrom: oneMonthAgoDate(),
        filterDateCreatedTo: nowDate(),
        filterDeliveryDateFrom: undefined,
        filterDeliveryDateTo: undefined,
        filterDelegatedBy: undefined,
        filterDelegatedByOptions: [],
        filterHierarchySalesDistrictCodes: [],
        filterHierarchyChannelFunctionDetailCodes: [],
        filterHierarchyExecutiveFunctionDetailCodes: [],
        filterIncludeReceived: IncludeReceivedOptionKey.Yes,
        filterIncludeGoodsServices: IncludeGoodsServicesOptionKey.Both
    }
};

export const searchReducer: Reducer<ISearchReducer, AnyAction> = (
    state: ISearchReducer = initialSearchReducer, action: AnyAction
): ISearchReducer => {
    switch (action.type) {
        case actionTypes.API_HOME_TILE_INFO: {
            const payload: IApiHomeTileInfo = action.payload as IApiHomeTileInfo;
            return {
                ...state,
                apiHomeTileInfo: { ...payload }
            } as ISearchReducer;
        }
        case actionTypes.API_SEARCH: {
            const payload: IApiSearch = action.payload as IApiSearch;
            return {
                ...state,
                apiSearch: { ...payload }
            } as ISearchReducer;
        }
        case actionTypes.API_SEARCH_FOR_PO: {
            const payload: IApiSearchForPo = action.payload as IApiSearchForPo;
            return {
                ...state,
                apiSearchForPo: { ...payload }
            } as ISearchReducer;
        }
        case actionTypes.STORE_LAST_SEARCH: {
            return {
                ...state,
                purchaseOrderSearch: action.purchaseOrderSearch
            } as ISearchReducer;
        }
        case actionTypes.STORE_SEARCH_RESULTS: {
            return {
                ...state,
                purchaseOrderHeaders: action.purchaseOrderHeaders
            } as ISearchReducer;
        }
        case actionTypes.STORE_VISIBILITY_CHECK_RESULTS: {
            return {
                ...state,
                purchaseOrderCheckResults: action.purchaseOrderCheckResults
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DRI_SEARCH_ENABLED: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    driSearchEnabled: action.driSearchEnabled
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_PO_NUMBER: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterPoNumber: action.filterPoNumber
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_SUPPLIER_NUMBER: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterSupplierNumber: action.filterSupplierNumber
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_COMPANY_CODE: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterCompanyCode: action.filterCompanyCode
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_PO_APPROVER: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterPoApprover: action.filterPoApprover
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_PO_OWNER: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterPoOwner: action.filterPoOwner
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_INVOICE_APPROVER: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterInvoiceApprover: action.filterInvoiceApprover
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DATE_CREATED_FROM: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterDateCreatedFrom: action.filterDateCreatedFrom
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DATE_CREATED_TO: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterDateCreatedTo: action.filterDateCreatedTo
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DELIVERY_DATE_FROM: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterDeliveryDateFrom: action.filterDeliveryDateFrom
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DELIVERY_DATE_TO: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterDeliveryDateTo: action.filterDeliveryDateTo
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DELEGATED_BY: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterDelegatedBy: action.filterDelegatedBy
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_DELEGATED_BY_OPTIONS: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterDelegatedByOptions: action.filterDelegatedByOptions
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_HIERARCHY_SALES_DISTRICT_CODES: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterHierarchySalesDistrictCodes: action.filterHierarchySalesDistrictCodes
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_FILTER_HIERARCHY_CHANNEL_FUNCTION_DETAIL_CODES: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterHierarchyChannelFunctionDetailCodes: action.filterHierarchyChannelFunctionDetailCodes
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_HIERARCHY_EXECUTIVE_FUNCTION_DETAIL_CODES: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterHierarchyExecutiveFunctionDetailCodes: action.filterHierarchyExecutiveFunctionDetailCodes
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_INCLUDE_RECEIVED: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterIncludeReceived: action.filterIncludeReceived
                }
            } as ISearchReducer;
        }
        case actionTypes.INPUT_FILTER_INCLUDE_GOODS_SERVICES: {
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    filterIncludeGoodsServices: action.filterIncludeGoodsServices
                }
            } as ISearchReducer;
        }
        default:
    }

    return state;
};
