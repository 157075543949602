import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiAccrualFailures, IApiAccrualOutOfSync, IApiPriorYearAccrualFailures } from '../../actions/pageActions/accrualDashboardPage.action';

export interface IAccrualDashboardPageReducerState {
    apiAccrualOutOfSync: IApiAccrualOutOfSync;
    apiAccrualFailures: IApiAccrualFailures;
    apiPriorYearAccrualFailures: IApiPriorYearAccrualFailures
}

const initialAccrualDashboardPageReducerState: IAccrualDashboardPageReducerState = {
    apiAccrualOutOfSync: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        poLineReceiptAccrualDetail: undefined
    },
    apiAccrualFailures: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        poLineAccrualProcessingDetail: undefined
    },
    apiPriorYearAccrualFailures: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        poLinePriorYearAccrualProcessingDetail: undefined
    }
}

export const accrualDashboardPageReducer: Reducer<IAccrualDashboardPageReducerState, AnyAction> = (
    state: IAccrualDashboardPageReducerState = initialAccrualDashboardPageReducerState, action: AnyAction
): IAccrualDashboardPageReducerState => {
    switch (action.type) {
        case actionTypes.API_ACCRUAL_OUT_OF_SYNC: {
            const payload: IApiAccrualOutOfSync = action.payload as IApiAccrualOutOfSync;
            return {
                ...state,
                apiAccrualOutOfSync: { ...payload }
            } as IAccrualDashboardPageReducerState
        }
        case actionTypes.API_ACCRUAL_FAILURES: {
            const payload: IApiAccrualFailures = action.payload as IApiAccrualFailures;
            return {
                ...state,
                apiAccrualFailures: { ...payload }
            } as IAccrualDashboardPageReducerState
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_FAILURES: {
            const payload: IApiPriorYearAccrualFailures = action.payload as IApiPriorYearAccrualFailures;
            return {
                ...state,
                apiPriorYearAccrualFailures: { ...payload }
            } as IAccrualDashboardPageReducerState
        }
        default:
    }

    return state;
};
