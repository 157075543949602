import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IApiPyaUploadManualPostings, callApiPyaUploadManualPostings, resetApiCallStateForPyaUploadManualPostings } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import {
    DefaultButton,
    FontIcon,
    Link,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensLargeGap } from '../../common/common.styles';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { CallApiState } from '../../store/actions/generic.action';
import { useBoolean } from '@fluentui/react-hooks';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';

interface IAdminManualPostingProps {
    handleError: (errMsg: string) => void;
}

export const AdminManualPosting: React.FunctionComponent<IAdminManualPostingProps> = (props: IAdminManualPostingProps): JSX.Element => {
    const [displayGenericDialog, { toggle: toggleDisplayGenericDialog }] = useBoolean(false);
    const [genericDialogTitle, setGenericDialogTitle] = useState<string>('');
    const [genericDialogMsg, setGenericDialogMsg] = useState<string>('');
    const [genericDialogCustomContent, setGenericDialogCustomContent] = useState<JSX.Element>();
    const callbackAfterGenericDialogCloseRef = useRef<() => void>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Redux store selectors to get state from the store when it changes.
    const apiPyaUploadManualPostings: IApiPyaUploadManualPostings =
        useAppSelector<IApiPyaUploadManualPostings>(state => state.priorYearAccrualPageReducer.apiPyaUploadManualPostings);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that returns a cleanup function for when this component is unmounted.
     */
    useEffect(() => {
        return () => {
            // Reset the api call state for PYA upload manual postings.
            // So that when users navigate away and come back later, the state is refreshed.
            dispatch(resetApiCallStateForPyaUploadManualPostings());
        }
    }, [dispatch]);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        // Pass the error to the parent component.
        if (apiPyaUploadManualPostings.errMsg) {
            props.handleError(apiPyaUploadManualPostings.errMsg);
        }
        // Disabling exhaustive deps because we don't want to run this effect when the props.handleError function changes.
        // See: https://stackoverflow.com/questions/62807829/how-to-correctly-implement-a-props-callback-function-in-the-useeffect-hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiPyaUploadManualPostings.errMsg]);

    /**
     * Show generic dialog.
     * @param title Dialog title.
     * @param msg Dialog message.
     * @param customContent Custom JSX content.
     * @param callback Callback to call after dialog closed.
     */
    const showGenericDialog = useCallback((title: string, msg: string, customContent?: JSX.Element, callback?: () => void) => {
        setGenericDialogTitle(title);
        setGenericDialogMsg(msg);
        setGenericDialogCustomContent(customContent);
        callbackAfterGenericDialogCloseRef.current = callback;
        toggleDisplayGenericDialog();
    }, [toggleDisplayGenericDialog]);

    /**
     * Upload button clicked event handler.
     */
    const uploadButtonClicked = () => {
        // Trigger a click on the file input element.
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };

    /**
     * On upload input change handler.
     * @param event Form event. 
     */
    const onUploadInput = (event: React.FormEvent<HTMLInputElement>) => {
        const files = fileInputRef.current?.files;

        if (!files || files.length !== 1) {
            return;
        }

        const file: File = files[0];

        if (file.size > (1024 * 1024 * 2) /* 2MB / 2,097,152 bytes */) {
            showGenericDialog(
                'File size too large',
                `This file is ${file.size} bytes.<br>Please make sure the file is less than 2MB.`
            );
            return;
        }

        if (file.name.indexOf('.xlsx') < 0) {
            showGenericDialog(
                'Wrong file type',
                'File must be an Excel .xlsx file.'
            );
            return;
        }

        dispatch(callApiPyaUploadManualPostings(file));
    };

    /**
     * Effect for when upload completes.
     */
    useEffect(() => {
        if (apiPyaUploadManualPostings.callApiState === CallApiState.Completed) {
            showGenericDialog('Upload result', 'Succeeded');
        } else if (apiPyaUploadManualPostings.callApiState === CallApiState.Failed) {
            showGenericDialog('Upload result', 'Failed');
        }
    }, [apiPyaUploadManualPostings.callApiState, showGenericDialog]);

    /**
     * Memoized helper to check if the upload api is running.
     */
    const isUploadRunning = useMemo<boolean>(() => {
        // Checking for both Running and DataAvailable states to get rid of "flickering" on the upload button.
        if (apiPyaUploadManualPostings.callApiState === CallApiState.Running ||
            apiPyaUploadManualPostings.callApiState === CallApiState.DataAvailable) {
            return true;
        }
        return false;
    }, [apiPyaUploadManualPostings.callApiState]);

    return (
        <>
            <Stack tokens={stackTokensLargeGap}>
                <Stack.Item>
                    <Text variant='mediumPlus' block className={commonStyles.sectionHeading} role="heading" aria-level={1}>Manual posting</Text>
                    <Text variant='medium' block className={commonStyles.sectionContent}>
                        Upload a file for manual PYA postings. File must be in a specific format.
                        See <Link href='files/PyaSampleManualPostings.xlsx'>this file</Link> as an example.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                <Stack.Item>
                    <DefaultButton
                        onClick={uploadButtonClicked}
                        disabled={isUploadRunning}
                    >
                        {isUploadRunning && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        )}
                        {!isUploadRunning && (
                            <>
                                <FontIcon iconName="ExcelDocument" className={pageStyles.excelIcon} />
                                <Text>Upload</Text>
                            </>
                        )}
                    </DefaultButton>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        title="import file"
                        className={pageStyles.hiddenInput}
                        onInput={onUploadInput}
                    />
                </Stack.Item>
                </Stack.Item>
            </Stack>
            
            <GenericDialog
                displayDialog={displayGenericDialog}
                title={genericDialogTitle}
                content={
                    <>
                        <Text block variant="medium">{genericDialogMsg}</Text>
                        {genericDialogCustomContent}
                    </>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayGenericDialog();
                    if (callbackAfterGenericDialogCloseRef.current) {
                        callbackAfterGenericDialogCloseRef.current();
                    }
                }}
            />
        </>
    );
};
