import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export class ExecFunctionDetailCode implements IHierarchyNodeCommon {
    public typeName: string = HierarchyConstants.className.executive.execFunctionDetailCode;
    public code: string;
    public description: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IHierarchyNodeCommon) {
        this.code = jsonData.code;
        this.description = jsonData.description;
    }
}
