import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiShipmentStatusTrail } from '../../actions/miscActions/shipmentStatusTrail.action';

export interface IShipmentStatusTrailReducerState {
    apiShipmentStatusTrail: IApiShipmentStatusTrail;
}

const initialShipmentStatusTrailReducerState: IShipmentStatusTrailReducerState = {
    apiShipmentStatusTrail: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierShipmentDetails: undefined
    }
}

export const shipmentStatusTrailReducer: Reducer<IShipmentStatusTrailReducerState, AnyAction> = (
    state: IShipmentStatusTrailReducerState = initialShipmentStatusTrailReducerState, action: AnyAction
): IShipmentStatusTrailReducerState => {
    switch (action.type) {
        case actionTypes.API_SHIPMENT_STATUS_TRAIL: {
            const payload: IApiShipmentStatusTrail = action.payload as IApiShipmentStatusTrail;
            return {
                ...state,
                apiShipmentStatusTrail: { ...payload }
            } as IShipmentStatusTrailReducerState
        }
        default:
    }

    return state;
};
