import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { ActionButton } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { clearEditPo, editPo } from '../../store/actions/pageActions/searchResultsPage.action';
import { CustomTeachingBubble, teachingBubbleCheckShow, TeachingBubbleItem } from '../CustomTeachingBubble/CustomTeachingBubble';
import { componentStyles } from './EditActionButton.styles';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { useParams } from 'react-router-dom';

interface IEditActionButtonProps {
    poHeader: PurchaseOrderHeader;
    index?: number;
    enableTeachingBubble: boolean;
    highlightActiveFromRoute?: boolean;
}

export const EditActionButton: React.FunctionComponent<IEditActionButtonProps> = (props: IEditActionButtonProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const params = useParams();

    return (
        <>
            <ActionButton
                id={`edit_${props.poHeader.purchaseOrderNumber}`}
                role="link"
                ariaLabel={`Open purchase order ${props.poHeader.purchaseOrderNumber}`}
                className={componentStyles.actionButtonEditPo}
                onClick={() => {
                    telemetryService.trackEvent({ name: trackedEvent.editPoButtonClicked }, {
                        po: props.poHeader.purchaseOrderNumber
                    });

                    dispatch(editPo(props.poHeader.purchaseOrderNumber));

                    // A second action is also dispatched to set the poNumber to undefined. This is a mechanism to let the
                    // component watching for the state change (using an effect) to navigate to the edit page. Setting the
                    // poNumber back to undefined prevents looping back to the edit page when the original component is
                    // revisited.
                    // Do the next dispatch in a setTimeout so that the first dispatch gets processed. Otherwise this
                    // second dispatch will overwrite the first on a render pass.
                    setTimeout(() => {
                        dispatch(clearEditPo());
                    });
                }}
            >
                {/* Highlight the active PO based on the route if the props.highlightActiveFromRoute is enabled. */}
                <div className={props.highlightActiveFromRoute === true && params.poNumber === props.poHeader.purchaseOrderNumber ? componentStyles.activePo : componentStyles.inactivePo}>
                    {props.poHeader.purchaseOrderNumber}
                </div>
            </ActionButton>
            { props.enableTeachingBubble === true && props.index === 0 && teachingBubbleCheckShow(TeachingBubbleItem.PoViewEdit) && (
                <CustomTeachingBubble
                    targetElemId={`edit_${props.poHeader.purchaseOrderNumber}`}
                    teachingBubbleItem={TeachingBubbleItem.PoViewEdit}
                    teachingBubbleHeadline="How to view and edit the PO"
                    teachingBubbleContent="Click the PO number to view or edit."
                />
            )}
        </>
    );
};
