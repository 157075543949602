import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { DefaultButton } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { UserDelegate } from '../../models/user/userDelegate';
import { deleteDelegateTo } from '../../store/actions/pageActions/userProfilePage.action';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IDelegateToDeleteButtonProps {
    item: UserDelegate;
}

export const DelegateToDeleteButton: React.FunctionComponent<IDelegateToDeleteButtonProps> = (props: IDelegateToDeleteButtonProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <DefaultButton
            onClick={() => {
                telemetryService.trackEvent({ name: trackedEvent.deleteDelegateButtonClicked });

                dispatch(deleteDelegateTo(props.item.clientRowKey!));
            }}
            text='Delete'
        />
    );
}
