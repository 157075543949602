import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { Section } from '../../components/Section/Section';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { delegateToColumns } from './delegateToColumns';
import { UserDelegate } from '../../models/user/userDelegate';
import { callApiLoadCompanies, IApiLoadCompanies, teachingBubbleClearArray } from '../../store/actions/app.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import {
    addDelegateTo,
    callApiReloadUserProfile,
    callApiSaveUserProfile,
    IApiReloadUserProfile,
    IApiSaveUserProfile,
    IUserProfilePageData
} from '../../store/actions/pageActions/userProfilePage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { delegateFromColumns } from './delegateFromColumns';
import { deepCopyObject, isNullOrUndefinedOrEmptyString } from '../../common/common.func.general';
import { UserProfile } from '../../models/user/userProfile';
import { saveButtonTextConstant } from './userProfilePageConstants';
import { pageStyles } from './UserProfilePage.styles';
import { AppDispatch } from '../../store/reduxStore';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { getUserAlias } from '../../services/auth/msalHelper';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IPageProps extends ICommonPageProps {
}

export const UserProfilePage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [saveButtonText, setSaveButtonText] = useState<string>('Save'); 

    // Redux store selectors to get state from the store when it changes.
    const apiLoadCompanies: IApiLoadCompanies = 
        useAppSelector<IApiLoadCompanies>(state => state.appReducer.apiLoadCompanies);
    const apiReloadUserProfile: IApiReloadUserProfile =
        useAppSelector<IApiReloadUserProfile>(state => state.userProfilePageReducer.apiReloadUserProfile);
    const userProfilePageData: IUserProfilePageData =
        useAppSelector<IUserProfilePageData>((state) => state.userProfilePageReducer.userProfilePageData);
    const apiSaveUserProfile: IApiSaveUserProfile =
        useAppSelector<IApiSaveUserProfile>(state => state.userProfilePageReducer.apiSaveUserProfile);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadCompanies.errMsg) {
            handleError(apiLoadCompanies.errMsg);
        }
    }, [apiLoadCompanies.errMsg, handleError]);

    /**
     * Effect for when save user profile api call state changes.
     */
    useEffect(() => {
        // Change the save button text based on save progress.
        switch (apiSaveUserProfile.callApiState) {
            case CallApiState.Running: {
                setSaveButtonText(saveButtonTextConstant.saving);
                break;
            }
            case CallApiState.Completed:
            default: {
                setTimeout(() => {
                    setSaveButtonText(saveButtonTextConstant.save);
                }, 1500);
                setSaveButtonText(saveButtonTextConstant.saved);
                break;
            }
        }
    }, [apiSaveUserProfile.callApiState]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Call the api to load the user profile. Do this on each time the page is loaded. This is to handle the case
        // where the user may have saved delegate changes, and upon revisiting the page it will get fresh data. Also,
        // if someone else has delegated to this user, it will get fresh data.
        // Note that in the action to load the user profile, there is a call to storeUserProfilePageData() to store data
        // for use on this page.
        const userAlias: string = getUserAlias();
        dispatch(callApiReloadUserProfile(userAlias));

        // If the api call to load companies has already been called, then no need to call it again.
        // If not yet called, then call it now.
        if (apiLoadCompanies.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadCompanies());
        }
    });

    /**
     * Add new button clicked event handler.
     */
    const addNewDelegateButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.addNewDelegateButtonClicked });

        dispatch(addDelegateTo());
    }

    /**
     * Save user profile button clicked event handler.
     */
    const saveUserProfileButtonClicked = async () => {
        telemetryService.trackEvent({ name: trackedEvent.saveUserProfileButtonClicked });

        // Make a copy of the user profile from the one in redux state.
        // As we will modify it by attaching the delegateTo array below.
        const userProfile: UserProfile = deepCopyObject(apiReloadUserProfile.userProfile);
        userProfile.delegateTo = userProfilePageData.delegateToArray || [];

        dispatch(callApiSaveUserProfile(userProfile));
    }

    /**
     * Memoized value to determine if data is loading.
     * @returns True if data is loading, otherwise false.
     */
    const isLoading = useMemo<boolean>((): boolean => {
        if (apiReloadUserProfile.callApiState === CallApiState.Running ||
            apiLoadCompanies.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiLoadCompanies.callApiState, apiReloadUserProfile.callApiState]);

    /**
     * Determine if data is saving.
     * @returns True if data is saving.
     */
    const isSaving = (): boolean => {
        if (apiSaveUserProfile.callApiState === CallApiState.Running ||
            // The saveButtonTextConstant.saved is a temporary 'Saved' message shown on the button. Return true also in this case. 
            saveButtonText === saveButtonTextConstant.saved) {
            return true;
        }
        return false;
    }

    /**
     * Checks if any input is invalid.
     */
    const anyInputInvalid = (): boolean => {
        if (userProfilePageData.delegateToArray) {
            for (let i: number = 0; i < userProfilePageData.delegateToArray.length; i++) {
                const userDelegate: UserDelegate = userProfilePageData.delegateToArray[i];
                if (isNullOrUndefinedOrEmptyString(userDelegate.alias) ||
                    userDelegate.accessRights.length === 0 ||
                    userDelegate.companyCodes.length === 0 ||
                    !userDelegate.isValid) {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <SectionWrapper>
                {isLoading && (
                    <Section>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Section>
                )}
                {!isLoading && (
                    <>
                        <Section>
                            <Stack tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={1}>Delegations To Others</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <Text variant='mediumPlus'>
                                        For purchase orders where you are the owner or the invoice approver - you can delegate others to be able to access those POs here. You can also specify specific company codes to allow and a date range for when the delegation is valid. Delegations and access rights granted here only apply to receipting activities on this site. They do not apply to other sites such as MS Invoice or MyOrder. You may set up delegations to the same person multiple times with different configurations.
                                    </Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <CustomDetailsList
                                        id="delegateToList"
                                        ariaLabelForGrid="Delegate to list"
                                        items={userProfilePageData.delegateToArray || []}
                                        compact={false}
                                        columns={delegateToColumns}
                                        selectionMode={SelectionMode.none}
                                        getKey={(item: UserDelegate) => item.clientRowKey!}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        isHeaderVisible={true}
                                        constrainMode={ConstrainMode.horizontalConstrained}
                                        onRenderRow={onCustomRenderRow}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            <DefaultButton
                                                onClick={addNewDelegateButtonClicked}
                                                text='Add New'
                                                disabled={isSaving()}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DefaultButton
                                                onClick={saveUserProfileButtonClicked}
                                                disabled={isSaving() || anyInputInvalid()}
                                            >
                                                {saveButtonText}
                                                {apiSaveUserProfile.callApiState === CallApiState.Running && (
                                                    <Spinner size={SpinnerSize.medium} className={pageStyles.saveSpinner} />
                                                )}
                                            </DefaultButton>
                                        </Stack.Item>
                                        {anyInputInvalid() && (
                                            <Stack.Item>
                                                <Text variant="medium" className={pageStyles.invalidInput}>Save is disabled as there is invalid input.</Text>
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Separator />
                                </Stack.Item>
                                <Stack.Item>
                                    <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={2}>Delegations From Others To Me</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <CustomDetailsList
                                        id="delegateFromList"
                                        ariaLabelForGrid="Delegate from list"
                                        items={apiReloadUserProfile.userProfile?.delegateFrom || []}
                                        compact={false}
                                        columns={delegateFromColumns}
                                        selectionMode={SelectionMode.none}
                                        getKey={(item: UserDelegate) => item.clientRowKey!}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        isHeaderVisible={true}
                                        constrainMode={ConstrainMode.horizontalConstrained}
                                        onRenderRow={onCustomRenderRow}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Section>
                    </>
                )}
            </SectionWrapper>
        </PageWrapper>
    );
};
