import { FinanceGeography } from './geography/financeGeography';
import { ChannelFunction } from './channel/channelFunction';
import { ExecutiveFunction } from './executive/executiveFunction';

export interface IHierarchy {
    financeGeography: FinanceGeography;
    channelFunction: ChannelFunction;
    executiveFunction: ExecutiveFunction;
}

export class Hierarchy implements IHierarchy {
    public financeGeography: FinanceGeography;
    public channelFunction: ChannelFunction;
    public executiveFunction: ExecutiveFunction;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IHierarchy) {
        this.financeGeography = new FinanceGeography(jsonData.financeGeography);
        this.channelFunction = new ChannelFunction(jsonData.channelFunction);
        this.executiveFunction = new ExecutiveFunction(jsonData.executiveFunction);
    }
}
