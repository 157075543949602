import { AnyAction, Reducer } from 'redux';
import { deepCopyObject } from '../../../common/common.func.general';
import { PurchaseOrderLineItem } from '../../../models/purchaseOrder/purchaseOrderLineItem';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiBulkSavePurchaseOrders, IApiClosePoLines, IEditLineItem, IEditPageData } from '../../actions/pageActions/editPage.action';

// The edit page also makes use of search.action.tsx and search.reducer.tsx for common search
// functionality used across multiple pages.

export interface IEditPageReducerState {
    editPageData: IEditPageData;
    showDetailsForLineItem?: PurchaseOrderLineItem;
    showAuditForLineItem?: PurchaseOrderLineItem;
    showShipmentInfoForLineItem?: PurchaseOrderLineItem;
    apiClosePoLines: IApiClosePoLines;
    apiBulkSavePurchaseOrders: IApiBulkSavePurchaseOrders;
    goodsLineItemsSortUsingColumnKey?: string;
    servicesLineItemsSortUsingColumnKey?: string;
}

const initialEditPageReducerState: IEditPageReducerState = {
    editPageData: {
        purchaseOrderHeader: undefined,
        goodsPurchaseOrderLineItems: undefined,
        servicesPurchaseOrderLineItems: undefined
    },
    showDetailsForLineItem: undefined,
    showAuditForLineItem: undefined,
    showShipmentInfoForLineItem: undefined,
    apiClosePoLines: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiBulkSavePurchaseOrders: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        bulkUpdateResult: undefined
    }
}

export const editPageReducer: Reducer<IEditPageReducerState, AnyAction> = (
    state: IEditPageReducerState = initialEditPageReducerState, action: AnyAction
): IEditPageReducerState => {
    switch (action.type) {
        case actionTypes.EDIT_STORE_PAGEDATA: {
            const editPageData: IEditPageData = action.editPageData as IEditPageData;
            return {
                ...state,
                editPageData: { ...editPageData }
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_STORE_PAGEDATA_CLEAR: {
            return {
                ...state,
                editPageData: {
                    purchaseOrderHeader: undefined,
                    goodsPurchaseOrderLineItems: undefined,
                    servicesPurchaseOrderLineItems: undefined
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_ASSETTAG: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];
            
            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.assetTag = editLineItem.assetTag;

                // If previously was fully received, then set the isUpdatingAssetDetails to true.
                if (lineItemCopy.currentlyFullyReceived) {
                    lineItemCopy.isUpdatingAssetDetails = true;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('assetTag');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_ASSETTAG_HASERROR: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;
            const lineItemsWithError: string[] = [...state.editPageData.lineItemsWithError || []];
            const err: string = `ASSETTAG_${editLineItem.lineNumber}`;
            const index: number = lineItemsWithError.indexOf(err!);

            if (editLineItem.hasError) {
                if (index < 0) {
                    lineItemsWithError.push(err!);
                }
            } else {
                if (index > -1) {
                    lineItemsWithError.splice(index, 1);
                }
            }
            
            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    lineItemsWithError: lineItemsWithError
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_SERIALNUMBER: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];
            
            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.serialNumber = editLineItem.serialNumber;

                // If previously was fully received, then set the isUpdatingAssetDetails to true.
                if (lineItemCopy.currentlyFullyReceived) {
                    lineItemCopy.isUpdatingAssetDetails = true;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('serialNumber');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_SERIALNUMBER_HASERROR: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;
            const lineItemsWithError: string[] = [...state.editPageData.lineItemsWithError || []];
            const err: string = `SERIALNUMBER_${editLineItem.lineNumber}`;
            const index: number = lineItemsWithError.indexOf(err!);

            if (editLineItem.hasError) {
                if (index < 0) {
                    lineItemsWithError.push(err!);
                }
            } else {
                if (index > -1) {
                    lineItemsWithError.splice(index, 1);
                }
            }
            
            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    lineItemsWithError: lineItemsWithError
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECIPIENTNAME: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];
            
            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.recipientName = editLineItem.recipientName;

                // If previously was fully received, then set the isUpdatingAssetDetails to true.
                if (lineItemCopy.currentlyFullyReceived) {
                    lineItemCopy.isUpdatingAssetDetails = true;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('recipientName');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECIPIENTNAME_HASERROR: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;
            const lineItemsWithError: string[] = [...state.editPageData.lineItemsWithError || []];
            const err: string = `RECIPIENTNAME_${editLineItem.lineNumber}`;
            const index: number = lineItemsWithError.indexOf(err!);

            if (editLineItem.hasError) {
                if (index < 0) {
                    lineItemsWithError.push(err!);
                }
            } else {
                if (index > -1) {
                    lineItemsWithError.splice(index, 1);
                }
            }
            
            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    lineItemsWithError: lineItemsWithError
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECEIVED: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];
            
            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.received = editLineItem.isReceived || false;

                if (lineItemCopy.received) {
                    // The checkbox is bound to the 'received' boolean. This gets set to true when checkbox is checked.
                    // The checkbox is only shown when the total quantity is 1. In addition to setting the 'received'
                    // boolean to true we also will set the receivedQuantity to 1. When there are multiple quantities
                    // then a slider is shown instead, which is bound directly to the 'receivedQuantity'.
                    lineItemCopy.receivedQuantity = 1;
                } else {
                    // Set the received quantity back to 0.
                    lineItemCopy.receivedQuantity = 0;

                    // Reset the following properties back to the original state.
                    lineItemCopy.assetTag = lineItemCopy.originalState?.assetTag;
                    lineItemCopy.serialNumber = lineItemCopy.originalState?.serialNumber;
                    lineItemCopy.recipientName = lineItemCopy.originalState?.recipientName;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('received');
                lineItemCopy.dirtyItem('receivedQuantity');
                // The following 3 only update if changed from received back to not received (above else condition).
                // Calling dirtyItem on these properties will set these back to not dirty as the values are reset above
                // in the else condition to the original state.
                lineItemCopy.dirtyItem('assetTag');
                lineItemCopy.dirtyItem('serialNumber');
                lineItemCopy.dirtyItem('recipientName');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECEIVED_PARTIAL: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];

            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1 && editLineItem.partialAmount !== undefined) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.receivedQuantity = editLineItem.partialAmount;
                lineItemCopy.receivedQuantityMinMaxCheck();

                if ((lineItemCopy.originalState?.receivedQuantity !== undefined &&
                    lineItemCopy.receivedQuantity > lineItemCopy.originalState?.receivedQuantity) ||
                    lineItemCopy.originalState?.receivedQuantity === undefined) {
                    // Mark the item received boolean to true. The search proc will normally only set the received to
                    // true if receivedQuantity = totalQuantity. Need to mark it as received here though, so that in
                    // the save api, it will enter into the block of code to save this item.
                    lineItemCopy.received = true;
                } else {
                    // If receivedQuantity and priorReceivedQuantity are equal, then nothing was done.
                    // This happens when the user changed the value, then changed it back.
                    // So reset received to false.
                    lineItemCopy.received = false;

                    // Reset the following properties back to the original state.
                    lineItemCopy.assetTag = lineItemCopy.originalState?.assetTag;
                    lineItemCopy.serialNumber = lineItemCopy.originalState?.serialNumber;
                    lineItemCopy.recipientName = lineItemCopy.originalState?.recipientName;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('received');
                lineItemCopy.dirtyItem('receivedQuantity');
                // The following 3 only update if received quantity changed and then changed back (above else condition).
                // Calling dirtyItem on these properties will set these back to not dirty as the values are reset above
                // in the else condition to the original state.
                lineItemCopy.dirtyItem('assetTag');
                lineItemCopy.dirtyItem('serialNumber');
                lineItemCopy.dirtyItem('recipientName');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECEIVED_AMOUNT: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.servicesPurchaseOrderLineItems || []];

            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1 && editLineItem.receivedAmount !== undefined) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.receivedAmount = editLineItem.receivedAmount;

                // Convert the received amount string value from the input box to a number if it is a string.
                lineItemCopy.receivedAmount = Number(lineItemCopy.receivedAmount);

                lineItemCopy.receivedAmountMinMaxCheck();
                lineItemCopy.percentageReceivedCalc();
                lineItemCopy.accruedAmountCalc();

                if (Number(lineItemCopy.receivedAmount) !== Number(lineItemCopy.originalState?.receivedAmount)) {
                    // If received amount changed, then set automatically set the receipt date to the current PST date.
                    lineItemCopy.setReceiptDateToCurrentPstDate();
                } else {
                    // If the received amount did not change or was changed back to the original value, then put the
                    // receipt date back to the original state date, as it is not allowed to change only the receipt date.
                    lineItemCopy.receiptDate = lineItemCopy.originalState?.receiptDate;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('receivedAmount');
                lineItemCopy.dirtyItem('percentageReceived');
                lineItemCopy.dirtyItem('receiptDate');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    servicesPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECEIVED_AMOUNT_HASERROR: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;
            const lineItemsWithError: string[] = [...state.editPageData.lineItemsWithError || []];
            const err: string = `RECEIVED_AMOUNT_${editLineItem.lineNumber}`;
            const index: number = lineItemsWithError.indexOf(err!);

            if (editLineItem.hasError) {
                if (index < 0) {
                    lineItemsWithError.push(err!);
                }
            } else {
                if (index > -1) {
                    lineItemsWithError.splice(index, 1);
                }
            }
            
            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    lineItemsWithError: lineItemsWithError
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_PERCENTAGE_RECEIVED: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.servicesPurchaseOrderLineItems || []];

            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1 && editLineItem.percentageReceived !== undefined) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.receivedAmount = Number(
                    // lineTotal should never be undefined, but just in case.
                    ((lineItemCopy.lineTotal || 0) *
                    (editLineItem.percentageReceived / 100))
                    // Calling toFixed will round the calculated percent of the line total to nearest currency fractional part.
                    .toFixed(lineItemCopy.currencyFractionalPart)
                );

                lineItemCopy.percentageReceived = editLineItem.percentageReceived;

                lineItemCopy.percentageReceivedMinCheck();
                lineItemCopy.receivedAmountMinMaxCheck();
                lineItemCopy.accruedAmountCalc();
                lineItemCopy.setReceiptDateToCurrentPstDate();

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('receivedAmount');
                lineItemCopy.dirtyItem('percentageReceived');
                lineItemCopy.dirtyItem('receiptDate');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    servicesPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.EDIT_LINEITEM_RECEIPT_DATE: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PurchaseOrderLineItem[] = [...state.editPageData.servicesPurchaseOrderLineItems || []];

            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            if (index > -1 && editLineItem.receiptDate !== undefined) {
                const lineItem: PurchaseOrderLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.receiptDate = editLineItem.receiptDate;

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('receiptDate');

                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    servicesPurchaseOrderLineItems: itemsCopy
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.SHOW_DETAILS_FOR_LINEITEM: {
            return {
                ...state,
                showDetailsForLineItem: action.showDetailsForLineItem
            } as IEditPageReducerState
        }
        case actionTypes.SHOW_AUDIT_FOR_LINEITEM: {
            return {
                ...state,
                showAuditForLineItem: action.showAuditForLineItem
            } as IEditPageReducerState
        }
        case actionTypes.SHOW_SHIPMENT_INFO_FOR_LINEITEM: {
            return {
                ...state,
                showShipmentInfoForLineItem: action.showShipmentInfoForLineItem
            } as IEditPageReducerState
        }
        case actionTypes.CLOSE_LINE_ITEM_SELECTION: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopyGoods: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];
            const itemsCopyServices: PurchaseOrderLineItem[] = [...state.editPageData.servicesPurchaseOrderLineItems || []];
            
            const indexGoods: number = itemsCopyGoods.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            const indexServices: number = itemsCopyServices.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );

            let lineItem: PurchaseOrderLineItem | undefined = undefined;
            if (indexGoods > -1) {
                lineItem = itemsCopyGoods[indexGoods];
            } else if (indexServices > -1) {
                lineItem = itemsCopyServices[indexServices];
            }

            if (lineItem) {
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.isClosed = editLineItem.isClosed || false;

                if (lineItemCopy.canClose && !lineItemCopy.isClosed) {
                    // If user checked it as closed, and then unchecked it,
                    // then reset the following properties back to the original state.
                    lineItemCopy.closedComments = lineItemCopy.originalState?.closedComments;
                }

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('isClosed');
                lineItemCopy.dirtyItem('closedComments');

                if (indexGoods > -1) {
                    itemsCopyGoods[indexGoods] = lineItemCopy;
                } else if (indexServices > -1) {
                    itemsCopyServices[indexServices] = lineItemCopy;
                }
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopyGoods,
                    servicesPurchaseOrderLineItems: itemsCopyServices
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.CLOSE_LINE_ITEM_COMMENTS: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopyGoods: PurchaseOrderLineItem[] = [...state.editPageData.goodsPurchaseOrderLineItems || []];
            const itemsCopyServices: PurchaseOrderLineItem[] = [...state.editPageData.servicesPurchaseOrderLineItems || []];
            
            const indexGoods: number = itemsCopyGoods.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );
            const indexServices: number = itemsCopyServices.findIndex(
                x => x.purchaseOrderLineNumber === editLineItem.lineNumber
            );

            let lineItem: PurchaseOrderLineItem | undefined = undefined;
            if (indexGoods > -1) {
                lineItem = itemsCopyGoods[indexGoods];
            } else if (indexServices > -1) {
                lineItem = itemsCopyServices[indexServices];
            }

            if (lineItem) {
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PurchaseOrderLineItem = deepCopyObject(lineItem);

                lineItemCopy.closedComments = editLineItem.closedComments;

                // Set dirty state for all updated fields.
                lineItemCopy.dirtyItem('closedComments');

                if (indexGoods > -1) {
                    itemsCopyGoods[indexGoods] = lineItemCopy;
                } else if (indexServices > -1) {
                    itemsCopyServices[indexServices] = lineItemCopy;
                }
            }

            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: itemsCopyGoods,
                    servicesPurchaseOrderLineItems: itemsCopyServices
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.CLOSE_LINE_ITEM_COMMENTS_HASERROR: {
            const editLineItem: IEditLineItem = action.editLineItem as IEditLineItem;
            const lineItemsWithError: string[] = [...state.editPageData.lineItemsWithError || []];
            const err: string = `CLOSECOMMENTS_${editLineItem.lineNumber}`;
            const index: number = lineItemsWithError.indexOf(err!);

            if (editLineItem.hasError) {
                if (index < 0) {
                    lineItemsWithError.push(err!);
                }
            } else {
                if (index > -1) {
                    lineItemsWithError.splice(index, 1);
                }
            }
            
            return {
                ...state,
                editPageData: {
                    ...state.editPageData,
                    lineItemsWithError: lineItemsWithError
                } as IEditPageData
            } as IEditPageReducerState
        }
        case actionTypes.API_CLOSE_LINE: {
            const payload: IApiClosePoLines = action.payload as IApiClosePoLines;
            return {
                ...state,
                apiClosePoLines: { ...payload }
            } as IEditPageReducerState;
        }
        case actionTypes.API_BULK_SAVE_PURCHASE_ORDERS: {
            const payload: IApiBulkSavePurchaseOrders = action.payload as IApiBulkSavePurchaseOrders;
            return {
                ...state,
                apiBulkSavePurchaseOrders: { ...payload }
            } as IEditPageReducerState;
        }
        case actionTypes.GOODS_LINE_ITEMS_SORT_ON_COLUMN: {
            return {
                ...state,
                goodsLineItemsSortUsingColumnKey: action.goodsLineItemsSortUsingColumnKey
            } as IEditPageReducerState;
        }
        case actionTypes.GOODS_LINE_ITEMS_UPDATE_SORTED: {
            return {
                ...state,
                goodsLineItemsSortUsingColumnKey: undefined, // Important to set this to undefined so the effect in the page runs once.
                editPageData: {
                    ...state.editPageData,
                    goodsPurchaseOrderLineItems: action.goodsLineItemsItems
                } as IEditPageData
            } as IEditPageReducerState;
        }
        case actionTypes.SERVICES_LINE_ITEMS_SORT_ON_COLUMN: {
            return {
                ...state,
                servicesLineItemsSortUsingColumnKey: action.servicesLineItemsSortUsingColumnKey
            } as IEditPageReducerState;
        }
        case actionTypes.SERVICES_LINE_ITEMS_UPDATE_SORTED: {
            return {
                ...state,
                servicesLineItemsSortUsingColumnKey: undefined, // Important to set this to undefined so the effect in the page runs once.
                editPageData: {
                    ...state.editPageData,
                    servicesPurchaseOrderLineItems: action.servicesLineItemsItems
                } as IEditPageData
            } as IEditPageReducerState;
        }
        default:
    }

    return state;
};
