import { SalesLocation } from './salesLocation';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface ISubRegion extends IHierarchyNodeCommon {
    salesLocation: SalesLocation[];
}

export class SubRegion implements ISubRegion {
    public typeName: string = HierarchyConstants.className.geography.subRegion;
    public code: string;
    public description: string;
    public salesLocation: SalesLocation[] = [];
    public children?: IHierarchyNodeCommon[] = this.salesLocation; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ISubRegion) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.salesLocation) {
            for (let i: number = 0; i < jsonData.salesLocation.length; i++) {
                this.salesLocation.push(new SalesLocation(jsonData.salesLocation[i]));
            }
        }
    }
}
