import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    columnHeading: {
        fontWeight: '500',
        lineHeight: '20px',
        display: 'inline-block'
    } as IStyle
});
