import React from 'react';
import { Checkbox, SpinButton, Text } from '@fluentui/react';
import { pageStyles, spinButtonStyle } from './EditPage.styles';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { editLineItemReceived, editLineItemReceivedPartial } from '../../store/actions/pageActions/editPage.action';
import { commonString } from '../../common/commonString';
import { AppDispatch } from '../../store/reduxStore';

interface IReceivedQuantityInputProps {
    item: PurchaseOrderLineItem;
}

export const ReceivedQuantityInput: React.FunctionComponent<IReceivedQuantityInputProps> = (props: IReceivedQuantityInputProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <>
            {
                props.item.totalQuantity && (
                    <>
                        {
                            // If total quantity is 1 then display a checkbox.
                            props.item.totalQuantity === 1 && (
                                <Checkbox
                                    id={`received-checkbox-${props.item.purchaseOrderLineNumber}`}
                                    ariaLabel={commonString.isReceived}
                                    className={pageStyles.receivedCheckbox}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                        dispatch(editLineItemReceived(props.item.purchaseOrderLineNumber, checked || false));
                                    }}
                                    checked={props.item.received}
                                    disabled={!props.item.canReceiveItem()}
                                />
                            )
                        }
                        {
                            // If total quantity is > 1 then display an input to allow partial quantity receiving.
                            props.item.totalQuantity > 1 && (
                                <div className={pageStyles.receiveMultipleContainer}>
                                    <SpinButton
                                        id={`received-spinbutton-${props.item.purchaseOrderLineNumber}`}
                                        inputProps={{
                                            'aria-label': commonString.receivedQuantity
                                        }}
                                        ariaLabel={commonString.receivedQuantity}
                                        incrementButtonAriaLabel="Increase value by 1"
                                        decrementButtonAriaLabel="Decrease value by 1"
                                        styles={spinButtonStyle}
                                        value={String(props.item.receivedQuantity || 0)}
                                        min={0}
                                        max={props.item.totalQuantity}
                                        step={1}
                                        disabled={!props.item.canReceiveItem()}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            dispatch(editLineItemReceivedPartial(props.item.purchaseOrderLineNumber, Number(newValue)));
                                        }}
                                    />
                                    <Text block style={{ marginTop: '4px' }}>of {props.item.totalQuantity}</Text>
                                </div>
                            )
                        }
                    </>
                )
            } 
        </>
    );
};
