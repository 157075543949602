import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { POLinePriorYearAccrualActivity } from '../../models/accrualDashboard/poLinePriorYearAccrualActivity';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { PriorYearAccrualAction } from './PriorYearAccrualAction';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';
import { numberAsLocaleString } from '../../common/common.func.transform';

const actionColumn: ICustomColumn = {
    key: 'action',
    fieldName: undefined,
    name: '',
    isResizable: false,
    minWidth: 80,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <PriorYearAccrualAction poLinePriorYearAccrualActivity={item} />
        );
    }
};

const purchaseOrderNumberColumn: ICustomColumn = {
    key: 'purchaseOrderNumber',
    fieldName: 'purchaseOrderNumber', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'PO Number', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PO'
                headerLine2='Number'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderNumber}
            </Text>
        );
    }
};

const purchaseOrderLineItemColumn: ICustomColumn = {
    key: 'purchaseOrderLineItem',
    fieldName: 'purchaseOrderLineItem', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Line Number', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line'
                headerLine2='Number'
            />
        );
    },
    isResizable: false,
    minWidth: 60,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderLineItem}
            </Text>
        );
    }
};

const currencyColumn: ICustomColumn = {
    key: 'currency',
    fieldName: 'currency', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Currency Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Currency'
                headerLine2='Code'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.currency}
            </Text>
        );
    }
};

const poCompanyCodeColumn: ICustomColumn = {
    key: 'poCompanyCode',
    fieldName: 'poCompanyCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Company Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Company'
                headerLine2='Code'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.poCompanyCode}
            </Text>
        );
    }
};

const activityTypeColumn: ICustomColumn = {
    key: 'activityType',
    fieldName: 'activityType', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Activity Type', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Activity'
                headerLine2='Type'
            />
        );
    },
    isResizable: false,
    minWidth: 130,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.activityType}
            </Text>
        );
    }
};

const accrualsProcessingStatusColumn: ICustomColumn = {
    key: 'accrualsProcessingStatus',
    fieldName: 'activityProcessingStatus', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Processing Status', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Processing'
                headerLine2='Status'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.activityProcessingStatus}
            </Text>
        );
    }
};

const internalOrderOrCostCenterColumn: ICustomColumn = {
    key: 'internalOrderOrCostCenter',
    fieldName: 'internalOrderOrCostCenter', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Internal Order or Cost Center', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Internal Order'
                headerLine2='or Cost Center'
            />
        );
    },
    isResizable: false,
    minWidth: 120,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.internalOrderOrCostCenter}
            </Text>
        );
    }
};

const glAccountCodeColumn: ICustomColumn = {
    key: 'glAccountCode',
    fieldName: 'glAccountCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'GL Account Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='GL Account'
                headerLine2='Code'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.glAccountCode}
            </Text>
        );
    }
};

const dedicatedAccountCodeColumn: ICustomColumn = {
    key: 'dedicatedAccountCode',
    fieldName: 'dedicatedPnlAccountCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Dedicated P&L Account', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Dedicated'
                headerLine2='P&L Account'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.dedicatedPnlAccountCode}
            </Text>
        );
    }
};

const accrualActivityIdColumn: ICustomColumn = {
    key: 'accrualActivityId',
    fieldName: 'accrualActivityId', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Activity Id', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Activity Id'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.accrualActivityId}
            </Text>
        );
    }
};

const activityDateColumn: ICustomColumn = {
    key: 'activityDate',
    fieldName: 'activityDate', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Activity Date', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Activity Date'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.activityDate)}
            </Text>
        );
    }
};

const activityAmountColumn: ICustomColumn = {
    key: 'activityAmount',
    fieldName: 'activityAmount', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Activity Amount', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Activity'
                headerLine2='Amount'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLinePriorYearAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {numberAsLocaleString(item.activityAmount, 2)}
            </Text>
        );
    }
};

export const priorYearAccrualFailureColumns: IColumn[] = [
    actionColumn,
    purchaseOrderNumberColumn,
    purchaseOrderLineItemColumn,
    currencyColumn,
    poCompanyCodeColumn,
    activityTypeColumn,
    accrualsProcessingStatusColumn,
    internalOrderOrCostCenterColumn,
    glAccountCodeColumn,
    dedicatedAccountCodeColumn,
    accrualActivityIdColumn,
    activityDateColumn,
    activityAmountColumn
];
