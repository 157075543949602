export interface IPurchaseOrderHeader {
    purchaseOrderNumber: string;
    companyCode: number;
    poLocalCurr: string;
    openAmount?: number;
    purchaseOrderRequestor: string;
    purchaseOrderApprover: string;
    invoiceApprover: string;
    vendorName?: string;
    vendorNumber: string;
    projectName: string;
    poCreateDate: Date;
}

export class PurchaseOrderHeader implements IPurchaseOrderHeader {
    public purchaseOrderNumber: string;
    public companyCode: number;
    public poLocalCurr: string;
    public openAmount?: number;
    public purchaseOrderRequestor: string;
    public purchaseOrderApprover: string;
    public invoiceApprover: string;
    public vendorName?: string;
    public vendorNumber: string;
    public projectName: string;
    public poCreateDate: Date;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    /**
     * Supplier (vendor) name/number combo string.
     */
    public get supplierNameNumberCombo(): string {
        return `${this.vendorName} (${this.vendorNumber})`;
    }

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderHeader) {
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.companyCode = jsonData.companyCode;
        this.poLocalCurr = jsonData.poLocalCurr;
        this.openAmount = jsonData.openAmount;
        this.purchaseOrderRequestor = jsonData.purchaseOrderRequestor;
        this.purchaseOrderApprover = jsonData.purchaseOrderApprover;
        this.invoiceApprover = jsonData.invoiceApprover;
        this.vendorName = jsonData.vendorName;
        this.vendorNumber = jsonData.vendorNumber;
        this.projectName = jsonData.projectName;
        this.poCreateDate = jsonData.poCreateDate;
    }
}
