import { ICoherenceTreeViewStyles } from '@coherence-design-system/controls';
import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    hierarchyNodeCheckbox: {
        margin: '0 4px 0 0'
    } as IStyle,
    dialogContainer: {
        minHeight: 'auto'
    } as IStyle,
    dialogTitle: {
        fontSize: '16px',
        minHeight: '0px',
        lineHeight: 0
    } as IStyle,
    pivotItemHeading: {
        lineHeight: '18px',
        textAlign: 'left'
    } as IStyle,
    summaryTable: {
        borderCollapse: 'collapse',
        lineHeight: '12px',
        'td': {
            whiteSpace: 'nowrap'
        } as IStyle
    } as IStyle,
    warningIcon: {
        color: 'orange',
        margin: '0 4px 0 0'
    } as IStyle,
    clearButton: {
        position: 'absolute',
        left: 0,
        top: 0
    } as IStyle
});

export const treeView = {
    root: {
        height: '260px',
        overflow: 'auto',
        margin: '6px 0 0 0'
    } as IStyle
} as ICoherenceTreeViewStyles;
