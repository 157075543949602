import React, { useMemo, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { pageStyles } from './DriToolsPage.styles';
import { validationConstants } from '../../common/validationConstants';
import { JeToolFunction } from '../../models/jetools/jeToolFunction';
import { JeToolData } from '../../models/jetools/jeToolData';
import { accrualsApiClient } from '../../services/api/accrualsApiClient';

interface IComponentProps {
}

export const JeToolsTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const jeDataInputId : string = useId();
    const [jeData, setJeData] = useState<string>('');
    const [jeDataValidationError, setJeDataValidationError] = useState<string>('');
    const [jeToolDataResponse, setJeToolDataResponse] = useState<any | null>();
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [showErrorDialog, { toggle: toggleShowErrorDialog }] = useBoolean(false);
    const [isActionRunning, setIsActionRunning] = useState<boolean>(false); // For any action that can have data refreshed.
    
    const validateJson = (value: string): boolean => {
        try {
            JSON.parse(value);
            return true;
        } catch (err: any) {
            return false;
        }
    };

    /**
     * Memoized helper to check if the input is invalid.
    */
    const isInputInvalid = useMemo<boolean>(() => {       
        if (jeData.trim().length === 0) {
            return true;
        }
        if (jeDataValidationError.length > 0) {
             return true;
        }
        return false;
    }, [jeData, jeDataValidationError]);

    /**
     * Submit button clicked event handler.
    */
    const submitButtonClicked = async (refresh: boolean = false) => {
        setIsActionRunning(true);
        const jeToolRequestData: JeToolData = new JeToolData({
            jeToolFunction: JeToolFunction.PostJe,
            jePostData: jeData
        });

        try {
            setJeToolDataResponse(null); // Clear it first in the event there is an API call exception.
            const obj: any | null = await accrualsApiClient.postJeData(
                jeToolRequestData
            );
            setJeToolDataResponse(obj);
        } catch (err: any) {
            setErrorMsg(JSON.stringify(err));
            toggleShowErrorDialog();
        }  
        setIsActionRunning(false);      
    };

    /**
     * Display results for success and failures.
     * @returns JSX for results.
    */
    const displayResults = (): JSX.Element => {
        if (jeToolDataResponse) {
            return (
                <>
                    <div>
                        <h3 className={commonStyles.sectionHeading}>JE Post Result</h3>
                        <table role="presentation">
                            <tbody>
                                <tr>
                                    <td>
                                        <span>{jeToolDataResponse}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                    
                </>
            );
        }

        return <></>;
    };

    return (
        <>  
            <Separator />      
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="medium">Use this tool to post JE information to JEM.</Text>
                </Stack.Item>
                <Separator />
                <Stack tokens={stackTokensNormalGap}>
                    <Stack.Item>
                        <Text variant="mediumPlus" className={commonStyles.sectionHeading} role="heading" aria-level={1}>Post JE</Text>
                    </Stack.Item>
                    <Stack.Item>
                        JSON for JE line and header to post to JEM.
                    </Stack.Item>
                    <Stack.Item> 
                        <TooltipHost content={validationConstants.jeData.tooltip} delay={TooltipDelay.long}>
                            <TextField
                                id={jeDataInputId}
                                multiline
                                rows={10}
                                autoComplete='off'
                                ariaLabel={`${commonString.jeData} ${validationConstants.jeData.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                className={pageStyles.wideTextField}
                                value={jeData}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    newValue = newValue || '';
                                    newValue = newValue.trim();
                                    if (newValue.length > 0 && (newValue.length > validationConstants.jeData.maxLength! ||
                                        !validateJson(newValue))) {
                                            setJeDataValidationError(validationConstants.jeData.errorMsg!);
                                    } else {
                                        setJeDataValidationError('');
                                    }
                                    setJeData(newValue);
                                }}
                                errorMessage={jeDataValidationError}
                            />
                        </TooltipHost>                                
                    </Stack.Item>
                </Stack>
                <Stack.Item>
                    <DefaultButton
                        onClick={() => submitButtonClicked(true)}
                        disabled={isInputInvalid}
                        >                   
                        {isActionRunning && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        )}
                        {!isActionRunning && (
                        <span>Submit</span>
                        )}                    
                    </DefaultButton>
                </Stack.Item>  
                <Stack.Item>
                    {displayResults()}
                </Stack.Item>   
                {/* Error dialog. */}           
                <Dialog
                    hidden={!showErrorDialog}
                    onDismiss={toggleShowErrorDialog}
                    dialogContentProps={{
                                type: DialogType.normal,
                                title: 'Error',
                                subText: errorMsg
                            }}
                            modalProps={{
                            isBlocking: true
                        }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={toggleShowErrorDialog} text="Ok" />
                    </DialogFooter> 
                </Dialog>                   
            </Stack>
        </>
    );
};