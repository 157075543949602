export interface IPurchaseOrderSearchFilter {
    purchaseOrderNumber?: string;
    vendorNumber?: string;
    purchaseOrderApprover?: string;
    purchaseOrderRequestor?: string;
    purchaseOrderInvoiceApprover?: string;
    dateCreatedFrom?: Date;
    dateCreatedTo?: Date;
    deliveryDateFrom?: Date;
    deliveryDateTo?: Date;
    includeReceived?: boolean;
    includeGsrCor?: 'COR' | 'GSR' | 'Both';
    selectedUsers?: string[];
    runAsUser?: boolean;
    companyCode?: string;
    financeGeographySalesDistrictCodes?: string[];
    channelFunctionDetailCodes?: string[];
    executiveFunctionDetailCodes?: string[];
    receivedByRole?: string;
}

export class PurchaseOrderSearchFilter implements IPurchaseOrderSearchFilter {
    public purchaseOrderNumber?: string;
    public vendorNumber?: string;
    public purchaseOrderApprover?: string;
    public purchaseOrderRequestor?: string;
    public purchaseOrderInvoiceApprover?: string;
    public dateCreatedFrom?: Date;
    public dateCreatedTo?: Date;
    public deliveryDateFrom?: Date;
    public deliveryDateTo?: Date;
    public includeReceived?: boolean;
    public includeGsrCor?: 'COR' | 'GSR' | 'Both';
    public selectedUsers?: string[] = [];
    public runAsUser?: boolean;
    public companyCode?: string;
    public financeGeographySalesDistrictCodes?: string[] = [];
    public channelFunctionDetailCodes?: string[] = [];
    public executiveFunctionDetailCodes?: string[] = [];
    public receivedByRole?: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderSearchFilter) {
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.vendorNumber = jsonData.vendorNumber;
        this.purchaseOrderApprover = jsonData.purchaseOrderApprover;
        this.purchaseOrderRequestor = jsonData.purchaseOrderRequestor;
        this.purchaseOrderInvoiceApprover = jsonData.purchaseOrderInvoiceApprover;
        this.dateCreatedFrom = jsonData.dateCreatedFrom;
        this.dateCreatedTo = jsonData.dateCreatedTo;
        this.deliveryDateFrom = jsonData.deliveryDateFrom;
        this.deliveryDateTo = jsonData.deliveryDateTo;
        this.includeReceived = jsonData.includeReceived;
        this.includeGsrCor = jsonData.includeGsrCor;

        if (jsonData.selectedUsers) {
            for (let i: number = 0; i < jsonData.selectedUsers.length; i++) {
                this.selectedUsers!.push(jsonData.selectedUsers[i]);
            }
        }

        this.runAsUser = jsonData.runAsUser;
        this.companyCode = jsonData.companyCode;

        if (jsonData.financeGeographySalesDistrictCodes && this.financeGeographySalesDistrictCodes) {
            for (let i: number = 0; i < jsonData.financeGeographySalesDistrictCodes.length; i++) {
                this.financeGeographySalesDistrictCodes.push(jsonData.financeGeographySalesDistrictCodes[i]);
            }
        } else {
            // Important: If the json data had this as undefined then keep it undefined (not an empty array). The search proc works differently for undefined versus empty array for this field.
            this.financeGeographySalesDistrictCodes = undefined;
        }

        if (jsonData.channelFunctionDetailCodes && this.channelFunctionDetailCodes) {
            for (let i: number = 0; i < jsonData.channelFunctionDetailCodes.length; i++) {
                this.channelFunctionDetailCodes.push(jsonData.channelFunctionDetailCodes[i]);
            }
        } else {
            // Important: If the json data had this as undefined then keep it undefined (not an empty array). The search proc works differently for undefined versus empty array for this field.
            this.channelFunctionDetailCodes = undefined;
        }

        if (jsonData.executiveFunctionDetailCodes && this.executiveFunctionDetailCodes) {
            for (let i: number = 0; i < jsonData.executiveFunctionDetailCodes.length; i++) {
                this.executiveFunctionDetailCodes.push(jsonData.executiveFunctionDetailCodes[i]);
            }
        } else {
            // Important: If the json data had this as undefined then keep it undefined (not an empty array). The search proc works differently for undefined versus empty array for this field.
            this.executiveFunctionDetailCodes = undefined;
        }

        this.receivedByRole = jsonData.receivedByRole;
    }
}
