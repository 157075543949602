/**
 * Represents an error model. Values in comments show example data.
 * Directly models the server side ErrorModel object. If that one changes, then this one must change.
 */
export interface IErrorModel {
    code: string;
    message: string;
    type: number;
}

/**
 * Represents an error model.
 */
export class ErrorModel implements IErrorModel {
    public code: string;
    public message: string;
    public type: number;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IErrorModel) {
        this.code = jsonData.code;
        this.message = jsonData.message;
        this.type = jsonData.type;
    }
}
