import { AreaSummary } from './areaSummary';

export interface IFinanceGeography {
    areaSummary: AreaSummary[];
}

/**
 * User defined type guard to determine if object is a FinanceGeography.
 * @param obj Object to check if a finance geography.
 * @returns Type guard indicating if object is a finance geography.
 */
export function isFinanceGeography(obj: any): obj is FinanceGeography {
    return 'areaSummary' in obj;
}

export class FinanceGeography implements IFinanceGeography {
    public areaSummary: AreaSummary[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IFinanceGeography) {
        if (jsonData.areaSummary) {
            for (let i: number = 0; i < jsonData.areaSummary.length; i++) {
                this.areaSummary.push(new AreaSummary(jsonData.areaSummary[i]));
            }
        }
    }
}
