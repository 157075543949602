import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    Label,
    Spinner,
    SpinnerSize,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { GraphUser } from '../../models/user/graphUser';
import { GraphUserOption } from './GraphUserOption';
import { validationConstants } from '../../common/validationConstants';
import { validateInput } from '../../common/common.func.general';
import { debounce } from 'lodash';
import { graphApiClient } from '../../services/api/graphApiClient';
import { commonStyles } from '../../common/common.styles';
import { componentStyles } from './GraphUserInput.styles';

/**
 * Graph user input props.
 */
export interface IGraphUserInputProps {
    /**
     * Shows a label above the ComboBox.
     */
    showLabel?: boolean;

    /**
     * Label string if showLabel is true.
     */
    label?: string;

    /**
     * Disables the ComboBox.
     */
    disabled?: boolean;

    /**
     * Indicates if input is required or not.
     */
    required?: boolean;

    /**
     * Tooltip to show for the ComboBox.
     */
    tooltip?: string;

    /**
     * Delay in milleseconds to use with debounce. Defaults to 250ms if not supplied.
     */
    debounceDelay?: number;

    /**
     * Change event callback.
     * @param graphUser Graph user that was selected.
     */
    onChange?: (graphUser: GraphUser | undefined) => void;

    /**
     * Input validation event callback.
     * @param msg Validation message.
     * @param isValid Indicates if valid or not.
     */
    onInputValidation?: (msg: string, isValid: boolean) => void;

    // Note, no handleError for this control. Any errors with Graph api call are just ignored.

    /**
     * Initial selected alias, if any. The ComboBox will be pre-populated with this value upon load.
     * A call will be made to load this graph user during load.
     */
    initialSelectedAlias?: string;

    /**
     * If this is changed via props, then the input will be cleared.
     * This is a bit of a hacky way to make this happen. Certain pages have a 'Clear' button where we want
     * to clear out the input fields. The initialSelectedAlias is used only during initialization by design.
     * And did not want to use that prop to also serve the purpose of clearing out input.
     */
    clearInputCounter?: number;

    /**
     * Graph record count. This indicates how many options (max) to load in the ComboBox for matching users.
     */
    graphRecordCount?: number;
    
    /**
     * Illegal user alias.
     */
    illegalAlias?: string;

    /**
     * If illegal user alias was selected, the error message to show.
     */
    illegalAliasErrorMessage?: string;

    /**
     * If true, then no error will be shown for a user that does not exist. An option in the ComboBox will be created
     * for the non-existant user. But no error will be shown.
     */
    disableDoesNotExistError?: boolean;

    /**
     * Optional input id. If not supplied then one will be generated.
     */
    inputId?: string;
}

const enterUserAlias: string = 'Enter user alias';
const userDoesNotExist: string = 'User does not exist';
const doesNotExistOptionKey: string = 'DOES_NOT_EXIST';
const graphFields: string = 'id,displayName,userPrincipalName';

const debugLogEnabled: boolean = false; // Do not check in with this set to true. Used for log to console for debugging or to see how this works.

/**
 * Graph user input.
 * @param props Graph user input props.
 */
export const GraphUserInput: React.FunctionComponent<IGraphUserInputProps> = (props: IGraphUserInputProps): JSX.Element => {
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);
    const [selectedOptionKey, setSelectedOptionKey] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [inputInvalid, setInputInvalid] = useState<boolean>(false);
    const [apiCallAliasSearchRunning, setApiCallAliasSearchRunning] = useState<boolean>(false);
    const [apiCallNameSearchRunning, setApiCallNameSearchRunning] = useState<boolean>(false);

    const inputId: string = useId();

    const selectedOptionRef = useRef<IComboBoxOption | undefined>();
    const comboBoxRef = useRef(null);
    const callQueueRef = useRef<Promise<GraphUser[] | null>[]>([]);
    const callQueueProcessingRef = useRef<boolean>(false);
    const debounceDelayRef = useRef<number>(props.debounceDelay || 250);
    const clearInputCounterRef = useRef<number>(0);

    /**
     * Handle input error.
     * @param errMsg Error message. Leave blank if no error.
     */
    const handleInputError = useCallback((errMsg: string) => {
        setErrorMessage(errMsg);
        if (props.onInputValidation) {
            const isValid: boolean = !errMsg; // If there is no error message, then assume it is valid.
            props.onInputValidation(errMsg, isValid);
        }
    }, [props]);

    /**
     * Check for illegal alias input.
     * @param alias Graph user.
     */
    const checkForIllegalAlias = useCallback((alias: string) => {
        // Check if the graph user option alias is an illegal value.
        if (props.illegalAlias && alias === props.illegalAlias) {
            handleInputError(props.illegalAliasErrorMessage || 'illegal input');
        } else {
            handleInputError('');
        }
    }, [handleInputError, props.illegalAlias, props.illegalAliasErrorMessage]);

    /**
     * Change the selected option.
     * @param option ComboBox option or undefined if nothing selected.
     */
    const changeSelectedOption = useCallback((option: IComboBoxOption | undefined) => {
        selectedOptionRef.current = option;
        setSelectedOptionKey((option?.key || '') as string);

        // Check for illegal alias. If already determined to be illegal then don't check again.
        // This check is done here in changeSelectedOption and not in onInputValueChangeGraphUser because this
        // function is called from loadSingleUserOption (called for existing record during load) as well as as
        // from onChangeGraphUser (called when selection made). Also, the onInputValueChangeGraphUser works with
        // input text typed in, and we need this illegal alias check to work even if the user enters the full name.
        // For example, if the illegal alias is 'jasonp' and the user searches for by typing "Jason Paape" and then
        // selects that option, this check would determine it to be illegal because the option.text is the alias of 'jasonp'.
        if (option && option.key !== doesNotExistOptionKey) {
            checkForIllegalAlias(option.text);
        }

        const graphUser: GraphUser | undefined = option?.data as GraphUser | undefined;
        if (props.onChange) {
            if (props.disableDoesNotExistError && option?.key === doesNotExistOptionKey) {
                // If does not exist error is disabled, and the user does not exist, then send a dummy graph user
                // to the onChange where the user principal name is what was typed in and is now the option text.
                props.onChange(new GraphUser({ userPrincipalName: `${option?.text}@doesnotexist.foo` }));
            } else {
                props.onChange(graphUser);
            }
        }
    }, [checkForIllegalAlias, props]);

    /**
     * Makes a graph api call to find a user by alias and to create a ComboBox option for that user
     * if it exists, otherwise a placeholder option is created for the not found user.
     * @param alias Alias of user.
     */
    const loadSingleUserOption = useCallback(async (alias: string) => {
        setApiCallAliasSearchRunning(true);

        // Note we are not using Redux here (no dispatch to store). Just using regular api call with await.
        const graphUser: GraphUser | null = await graphApiClient.graphApiGetUserMatchingAlias(alias!, graphFields);

        let option: IComboBoxOption;
        if (graphUser) {
            option = {
                key: graphUser.id,
                text: graphUser.alias,
                data: graphUser
            } as IComboBoxOption;
            handleInputError('');
        } else {
            // The user was not found.
            // So create an option and indicate the user does not exist (might have existed before but no longer does).
            option = {
                key: doesNotExistOptionKey,
                text: alias,
                data: null
            } as IComboBoxOption;
            if (!props.disableDoesNotExistError) {
                handleInputError(userDoesNotExist);
            }
        }

        // Set the options to be the array of the one option created above.
        setOptions([option]);

        changeSelectedOption(option);

        setApiCallAliasSearchRunning(false);
    }, [changeSelectedOption, handleInputError, props.disableDoesNotExistError]);

    /**
     * Effect for when initial selected alias changes via props.
     * The effect is fired when other things in the dependency array also change.
     * Uses an isInitialLoad state to ensure this effect only does anything on initial load.
     */
    useEffect(() => {
        // If an initial selected alias was supplied in props, and this is the initial load, then load the
        // single option for that alias.
        if (isInitialLoad) {
            setIsInitialLoad(false);

            if (props.initialSelectedAlias) {
                loadSingleUserOption(props.initialSelectedAlias);
            } else {
                changeSelectedOption(undefined);
                if (props.required) {
                    handleInputError(enterUserAlias);
                }
            }
        }
    }, [changeSelectedOption, handleInputError, isInitialLoad, loadSingleUserOption, props.initialSelectedAlias, props.required]);

    /**
     * Effect used to clear the input.
     * This is to handle a case where we want the caller to clear the input after initial load.
     */
    useEffect(() => {
        // See if the props.clearInputCounter is different from what we had before in clearInputCounterRef.current.
        if (props.clearInputCounter && props.clearInputCounter !== clearInputCounterRef.current) {
            // Set the clearInputCounterRef to what was passed in props.
            clearInputCounterRef.current = props.clearInputCounter;

            // Clear the input.
            changeSelectedOption(undefined);
            if (props.required) {
                handleInputError(enterUserAlias);
            }
        }
    }, [changeSelectedOption, handleInputError, props.clearInputCounter, props.required]);

    /**
     * Graph user change event handler.
     * @param event ComboBox event.
     * @param option Option selected, if any.
     * @param index Index of option selected.
     * @param value Value entered into text input.
     */
    const onChangeGraphUser = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            // If an option was selected in the ComboBox.
            changeSelectedOption(option);
        } else if (value) {
            // If user typed in a value that was not in the ComboBox option list.
            // This happens if the user typed in a value and then tabbed or clicked out.
            if (inputInvalid) {
                // If input is invalid, clear the invalid state as nothing is selected.
                setInputInvalid(false);

                changeSelectedOption(undefined);
                if (props.required) {
                    handleInputError(enterUserAlias);
                }
            } else {
                // Try to load a single user option based on input value.
                loadSingleUserOption(value);
            }
        } else {
            // If no option was selected and no text entered in the ComboBox.
            changeSelectedOption(undefined);
            if (props.required) {
                handleInputError(enterUserAlias);
            }
        }
    };

    /**
     * Checks to see if the ComboBox input element has focus.
     */
    const inputHasFocus = () => {
        // The html input element (inside the ComboBox) will have an id like graphUserInput3101-input.
        // The id comes from the ComboBox id (graphUserInputId) and the '-input' is appended to that.
        // By looking to see if this id is the active element, we can know if it has focus.
        return `${props.inputId || inputId}-input` === document.activeElement?.id;
    };

    /**
     * Debounced input handler.
     * Debouncing will make it so the api call will only occur every configurable ms rather than on
     * every keypress. This is to improve perf and not to slam the api call too often.
     * See links for info on debounce usage:
     * https://blog.abdulqudus.com/how-to-use-debounce-in-react-lodash
     * https://docs-lodash.com/v4/debounce/
     */
    const handleInputDebounce = useRef<(value: string) => void>(
        debounce(async (value: string) => {
            if (value && value.length > 0) {
                // Note we are not using Redux here (no dispatch to store). Just using regular api call with await.
                callQueueRef.current.push(graphApiClient.graphApiGetUsersMatchingName(value, props.graphRecordCount || 6, graphFields));
                debugLog(`Added call to queue. Queue count now: ${callQueueRef.current.length}`);
            }
        }, 250, { 'leading': true, 'trailing': true })).current;

    /**
     * Debug log.
     * @param msg Message to log to console.
     */
    const debugLog = (msg: string) => {
        if (debugLogEnabled) {
            console.log(msg);
        }
    }

    /**
     * Process the call queue to the graph api.
     */
    const processCallQueue = async () => {
        // If already processing call queue, then return and do nothing.
        if (callQueueProcessingRef.current) {
            debugLog('Already processing call queue');
            return;
        }

        // If the call queue is empty, then return and do nothing.
        // This happens because this processCallQueue is called right after handleInputDebounce,
        // which uses a debounce function to gate api calls. If a call has not started then the call queue
        // will be empty.
        if (callQueueRef.current.length === 0) {
            debugLog('Call queue is empty');
            return;
        }

        callQueueProcessingRef.current = true;
        setApiCallNameSearchRunning(true);

        debugLog('Starting processing call queue');

        // While there are calls in the queue, then sequentially process each call one by one.
        // Otherwise what happens is multiple calls to the graph api are made and they may come back out of
        // sequence. For example, if calls a, b, c, and d are made. They might complete in the order of b, a, d, c.
        // We want the calls to run in order so that the ComboBox options are based on the final characters typed.
        while (callQueueRef.current.length > 0) {
            debugLog(`Queue length ${callQueueRef.current.length}`);

            // Sequentially process each call in the queue.
            const apiCall: Promise<GraphUser[] | null> = callQueueRef.current[0];

            const graphUsers: GraphUser[] | null = await apiCall;

            // If input has lost focus, then do not update the options.
            // This happens if the user is typing and then quickly tabs or clicks out.
            if (inputHasFocus()) {
                if (graphUsers) {
                    const options: IComboBoxOption[] = graphUsers.map(graphUser => {
                        return {
                            key: graphUser.id,
                            text: graphUser.alias,
                            data: graphUser
                        } as IComboBoxOption;
                    });
                    setOptions(options);
                }
            }

            callQueueRef.current.splice(0, 1);
        }

        debugLog('Completed processing call queue');

        setApiCallNameSearchRunning(false);
        callQueueProcessingRef.current = false;
    };

    /**
     * Input value changed handler for graph user.
     * This will get called on every keystroke.
     * @param text Text input for graph user.
     */
    const onInputValueChangeGraphUser = (text: string) => {
        let errMsg: string = '';

        if (!text) {
            if (props.required) {
                errMsg = enterUserAlias;
            }
            setOptions([]);
        } else {
            // Validate input using length and RegEx checks.
            errMsg = validateInput(text, validationConstants.userName);
            if (errMsg) {
                setOptions([]);
                setInputInvalid(true);
            } else {
                setInputInvalid(false);
            }
        }

        handleInputError(errMsg);

        if (!errMsg) {
            // If valid input, use debounce functon to limit calls to api. Depending on if the user types quickly or slowly
            // this call will gate traffic to the api so no more than x calls are outbound in a certain time window.
            handleInputDebounce(text);

            // Use a setTimeout with the debounce delay time before processing the call queue. The API call is made in the
            // handleInputDebounce but it is awaited in the processCallQueue. It is possible that the api call in the
            // promise is already completed by this time, or it may still be pending. The promise being awaited will handle
            // it in either case. The reason this setTimeout is important is because if we called processCallQueue right away
            // after the above call to handleInputDebounce, the handleInputDebounce function may have not even added the call
            // to the queue yet (as the entire debounced function only runs every debounceDelay max).
            setTimeout(() => {
                // Process the call queue that the above function adds to. These calls are invoked on every keystroke
                // The process call queue function will only loop through calls if there are some present in the call array.
                processCallQueue();
            }, debounceDelayRef.current + 1 /* Plus one extra ms to ensure this runs after the debounced call. */);
        }
    };

    return (
        <>
            {props.showLabel && (
                <Label htmlFor={props.inputId || inputId}>{props.label}</Label>
            )}
            <TooltipHost content={props.tooltip} delay={TooltipDelay.long}>
                <div className={commonStyles.comboBoxLoadSpinnerOuterContainer}>
                    <ComboBox
                        id={props.inputId || inputId}
                        ref={comboBoxRef}
                        ariaLabel={`${props.label || enterUserAlias} ${props.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                        className={componentStyles.comboBox}
                        options={options}
                        onChange={onChangeGraphUser}
                        onInputValueChange={onInputValueChangeGraphUser}
                        selectedKey={selectedOptionKey}
                        text={selectedOptionRef.current?.text || ''}
                        allowFreeform
                        autoComplete="off"
                        useComboBoxAsMenuWidth={false}
                        openOnKeyboardFocus={true}
                        required={props.required || false}
                        disabled={apiCallAliasSearchRunning || props.disabled}
                        onRenderOption={(opt) => {
                            const graphUser: GraphUser = opt!.data as GraphUser;
                            return (
                                <GraphUserOption graphUser={graphUser} />
                            )
                        }}
                        errorMessage={errorMessage}
                    />
                    {(apiCallAliasSearchRunning || apiCallNameSearchRunning) && (
                        <div className={commonStyles.comboBoxLoadSpinnerInnerContainer}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.comboBoxLoadSpinner} />
                        </div>
                    )}
                </div>
            </TooltipHost>
        </>
    );
};
