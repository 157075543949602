import React from 'react';
import {
    FontIcon,
    Link,
    Separator,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { useAppSelector } from '../../store/hooks';
import { IReportPageData, IReportInfo } from '../../store/reducers/pageReducers/reportsPage.reducer';
import { pageStyles } from './ReportsPage.styles';

interface IComponentProps {
}

export const PowerBiReportsTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const reportPageData: IReportPageData =
        useAppSelector<IReportPageData>((state) => state.reportsPageReducer.reportPageData);

    /**
     * Return the request access link. This is a link to idwebelements that will automatically request an add to the security group.
     * ex: https://idwebelements.microsoft.com/GroupManagement.aspx?Group=corusage&Operation=join
     * @param sgAliasOrName Security group alias or name (either one works with the one click link).
     * @returns Link to idwebelements for one click join.
     */
    const requestAccessLink = (sgAliasOrName: string): string => {
        return `https://idwebelements.microsoft.com/GroupManagement.aspx?Group=${sgAliasOrName}&Operation=join`;
    };

    /**
     * Render a report block.
     * @param reportInfo Report info.
     * @returns JSX for report block.
     */
    const renderReportBlock = (reportInfo: IReportInfo): JSX.Element => {
        return (
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="mediumPlus" className={commonStyles.sectionHeading} role="heading" aria-level={2}>{reportInfo.displayName}</Text>
                </Stack.Item>
                { reportInfo.description && (
                    <Stack.Item>
                        {reportInfo.description}
                    </Stack.Item>
                )}
                {!reportInfo.isMember && (
                    <>
                        <Stack.Item>
                            <div className={pageStyles.noPermission}>
                                <FontIcon iconName="ErrorBadge" className={pageStyles.fontIcon} />
                                You do not have access to this report.
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            { reportInfo.approvalRequiresBusinessJustification && (
                                <>
                                    <div className={pageStyles.accessRequestInstructions}>
                                        Access Request Instructions:
                                        <ol>
                                            <li>
                                                Open <Link href="https://idweb" target="_blank">idweb<FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} /></Link>.
                                            </li>
                                            <li>
                                                Search for this security group:
                                                <ul>
                                                    <li>
                                                        Display Name: <span className={commonStyles.boldText}>{reportInfo.sgName}</span>
                                                    </li>
                                                    <li>
                                                        Group Alias: <span className={commonStyles.boldText}>{reportInfo.sgAlias}</span>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Select that group and click join.</li>
                                            <li>Provide business justification and then submit the request.</li>
                                        </ol>
                                    </div>
                                </>
                            )}
                            { !reportInfo.approvalRequiresBusinessJustification && (
                                // Use the SG alias (if mail enabled), or otherwise use the SG name.
                                // The idweb one click link works with either one.
                                <Link href={requestAccessLink(reportInfo.sgAlias || reportInfo.sgName)} target="_blank">
                                    Request access
                                    <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                                </Link>
                            )}
                        </Stack.Item>
                    </>
                )}
                {reportInfo.isMember && (
                    <>
                        <Stack.Item>
                            <div className={pageStyles.hasPermission}>
                                <FontIcon iconName="CheckMark" className={pageStyles.fontIcon} />
                                You have access to this report.
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <Link href={reportInfo.reportUrl} target="_blank">
                                Open report
                                <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                            </Link>
                        </Stack.Item>
                    </>
                )}
            </Stack>
        );
    };

    return (
        <Stack tokens={stackTokensNormalGap}>
            <Stack.Item>
                <Separator />
                {
                    renderReportBlock(reportPageData.corUsage)
                }
                <Separator />
                {
                    renderReportBlock(reportPageData.gsr1010Usage)
                }
                <Separator />
                {
                    renderReportBlock(reportPageData.gsrUsage)
                }
                <Separator />
                {
                    renderReportBlock(reportPageData.gsrPoReport)
                }
                <Separator />
                {
                    renderReportBlock(reportPageData.goodsReceiptingSummary)
                }
                <Separator />
                {
                    renderReportBlock(reportPageData.pyaNotificationsReport)
                }
                <Separator />
            </Stack.Item>
        </Stack>
    );
};
