import { AssetInformation } from './assetInformation';
import { IShipmentTrail, ShipmentTrail } from './shipmentTrail';

export interface ISupplierShipmentDetail {
    purchaseOrderNumber: number;
    lineItemNumber: number;
    quantity: number;
    assetDetails?: AssetInformation[];
    carrierName: string;
    carrierTrackingNumber: string;
    carrierTrackingURL?: string;
    estimatedShipmentDate?: Date;
    estimatedDeliveryDate?: Date;
    actualShipmentDate?: Date;
    actualDeliveryDate?: Date;
    shipToContactName: string;
    shipmentTrail?: ShipmentTrail[];
}

export class SupplierShipmentDetail implements ISupplierShipmentDetail {
    public purchaseOrderNumber: number;
    public lineItemNumber: number;
    public quantity: number;
    public assetDetails?: AssetInformation[];
    public carrierName: string;
    public carrierTrackingNumber: string;
    public carrierTrackingURL?: string;
    public estimatedShipmentDate?: Date;
    public estimatedDeliveryDate?: Date;
    public actualShipmentDate?: Date;
    public actualDeliveryDate?: Date;
    public shipToContactName: string;
    public shipmentTrail?: ShipmentTrail[];

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ISupplierShipmentDetail) {
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.lineItemNumber = jsonData.lineItemNumber;
        this.quantity = jsonData.quantity;
        this.assetDetails = jsonData.assetDetails ? jsonData.assetDetails.map((assetInformation: AssetInformation) => new AssetInformation(assetInformation)) : [];
        this.carrierName = jsonData.carrierName;
        this.carrierTrackingNumber = jsonData.carrierTrackingNumber;
        this.carrierTrackingURL = jsonData.carrierTrackingURL;
        this.estimatedShipmentDate = jsonData.estimatedShipmentDate ? new Date(jsonData.estimatedShipmentDate) : undefined;
        this.estimatedDeliveryDate = jsonData.estimatedDeliveryDate ? new Date(jsonData.estimatedDeliveryDate) : undefined;
        this.actualShipmentDate = jsonData.actualShipmentDate ? new Date(jsonData.actualShipmentDate) : undefined;
        this.actualDeliveryDate = jsonData.actualDeliveryDate ? new Date(jsonData.actualDeliveryDate) : undefined;
        this.shipToContactName = jsonData.shipToContactName;
        this.shipmentTrail = jsonData.shipmentTrail ? jsonData.shipmentTrail.map((shipmentTrail: IShipmentTrail) => new ShipmentTrail(shipmentTrail)) : undefined;
    }
}
