import React, { useMemo, useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Spinner,
    SpinnerSize,
    Text
} from '@fluentui/react';
import { POLineAccrualActivity } from '../../models/accrualDashboard/poLineAccrualActivity';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { commonStyles } from '../../common/common.styles';
import { useBoolean } from '@fluentui/react-hooks';
import { useAppSelector } from '../../store/hooks';
import { UserProfile } from '../../models/user/userProfile';
import ReactJson from 'react-json-view'
import { componentStyles } from './AccrualAction.styles';
import { appConfig } from '../../shell/appConfig';

interface IAccrualActionProps {
    poLineAccrualActivity: POLineAccrualActivity;
}

export const AccrualAction: React.FunctionComponent<IAccrualActionProps> = (props: IAccrualActionProps): JSX.Element => {
    const [isActionRunning, setIsActionRunning] = useState<boolean>(false); // Flag for or any action.
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false); // For any action that can have data refreshed.
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [showErrorDialog, { toggle: toggleShowErrorDialog }] = useBoolean(false);
    const [showAccrualJsonDialog, { toggle: toggleShowAccrualJsonDialog }] = useBoolean(false);
    const [showAccrualAuditHistoryJsonDialog, { toggle: toggleShowAccrualAuditHistoryJsonDialog }] = useBoolean(false);
    const [briefMsg, setBriefMsg] = useState<string>('');
    const [accrualObj, setAccrualObj] = useState<object | null>();
    const [accrualAuditHistoryObj, setAccrualAuditHistoryObj] = useState<object | null>();

    // Redux store selectors to get state from the store when it changes.
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    /**
     * Display a brief message on the button for 2 seconds.
     * @param msg Message to display.
     */
    const displayBriefMsg = (msg: string) => {
        setBriefMsg(msg);
        setTimeout(() => {
            setBriefMsg('');
        }, 2000);
    }

    /**
     * Requeue the accrual activity.
     */
    const requeueAccrualActivity = async () => {
        setIsActionRunning(true);

        // Not using Redux action to call this api, but rather using receiptingApiClient directly. The Redux/action approach
        // is good for when we would have one instance of one api running... where the state of that one call instance is
        // stored in Redux. While here we can click multiple instances of this button to requeue multiple items at a time.
        try {
            await receiptingApiClient.requeueAccrualActivity(
                props.poLineAccrualActivity.purchaseOrderNumber,
                props.poLineAccrualActivity.purchaseOrderLineItem,
                props.poLineAccrualActivity.transactionId
            );
            displayBriefMsg('Done');
        } catch (err: any) {
            setErrorMsg(JSON.stringify(err));
            toggleShowErrorDialog();
        }

        setIsActionRunning(false);
    }

    /**
     * View accrual JSON.
     */
    const viewAccrualJson = async (refresh: boolean = false) => {
        setIsActionRunning(true);
        if (refresh) {
            setIsRefreshing(true);
        }

        try {
            setAccrualObj(null); // Clear it first in the event there is an API call exception.
            const obj: Record<string, unknown> | null = await receiptingApiClient.lineItemAccrual(
                props.poLineAccrualActivity.purchaseOrderNumber,
                props.poLineAccrualActivity.purchaseOrderLineItem
            );
            setAccrualObj(obj);
            if (!refresh) {
                toggleShowAccrualJsonDialog();
            }
        } catch (err: any) {
            setErrorMsg(JSON.stringify(err));
            toggleShowErrorDialog();
        }

        setIsActionRunning(false);
        if (refresh) {
            setIsRefreshing(false);
        }
    }

    /**
     * View accrual audit history JSON.
     */
    const viewAccrualAuditHistoryJson = async (refresh: boolean = false) => {
        setIsActionRunning(true);
        if (refresh) {
            setIsRefreshing(true);
        }

        try {
            setAccrualAuditHistoryObj(null); // Clear it first in the event there is an API call exception.
            const obj: Record<string, unknown> | null = await receiptingApiClient.lineItemAccrualAuditHistory(
                props.poLineAccrualActivity.purchaseOrderNumber,
                props.poLineAccrualActivity.purchaseOrderLineItem
            );
            setAccrualAuditHistoryObj(obj);
            if (!refresh) {
                toggleShowAccrualAuditHistoryJsonDialog();
            }
        } catch (err: any) {
            setErrorMsg(JSON.stringify(err));
            toggleShowErrorDialog();
        }

        setIsActionRunning(false);
        if (refresh) {
            setIsRefreshing(false);
        }
    }

    /**
     * Memoized value to determine if the retry option should be disabled.
     * Only allow retry if there is a JE with Failed.
     */
    const isRetryDisabled = useMemo<boolean>(() => {
        return props.poLineAccrualActivity.journalEntries.filter(x => x.jeStatus === 'Failed').length === 0;
    }, [props.poLineAccrualActivity.journalEntries])

    return (
        <>
            <DefaultButton
                menuProps={{
                    shouldFocusOnMount: true,
                    items: [
                        {
                            key: 'retry',
                            text: `Retry ${isRetryDisabled ? '(disabled, no failed activity)' : ''}`,
                            title: 'This will queue a message on the service bus for Accrual Engine to reprocess the failed activity. Use this feature only for a transaction that is voided in JEM.',
                            onClick: () => {
                                requeueAccrualActivity();
                            },
                            disabled: isRetryDisabled
                        },
                        {
                            key: 'viewAccrualJson',
                            text: 'View Accrual JSON',
                            onClick: () => {
                                viewAccrualJson();
                            }
                        },
                        {
                            key: 'viewAccrualAuditHistoryJson',
                            text: 'View Accrual Audit History JSON',
                            onClick: () => {
                                viewAccrualAuditHistoryJson();
                            }
                        }
                    ]
                }}
                disabled={isActionRunning || briefMsg !== '' ||
                    // For PROD, only allow the DRI to use this feature. If user is not DRI then disable.
                    (appConfig.current.settings.environmentName.toUpperCase() === 'PROD' && !userProfile?.isDri) ||
                    // For non-PROD (PPE, DEV), only allow the DRI or Accruals Admin to use this feature. If user is not DRI or Accruals Admin then disable.
                    (appConfig.current.settings.environmentName.toUpperCase() !== 'PROD' && !(userProfile?.isDri || userProfile?.isAccrualsAdmin))
                }
            >
                {!briefMsg && isActionRunning && (
                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                )}
                {!briefMsg && !isActionRunning && (
                    <span>Action</span>
                )}
                {briefMsg && (
                    <span>{briefMsg}</span>
                )}
            </DefaultButton>

            {/* Error dialog. */}
            <Dialog
                hidden={!showErrorDialog}
                onDismiss={toggleShowErrorDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Error',
                    subText: errorMsg
                }}
                modalProps={{
                    isBlocking: true
                }}
            >
                <DialogFooter>
                    <DefaultButton onClick={toggleShowErrorDialog} text="Ok" />
                </DialogFooter>
            </Dialog>

            {/* Accrual data dialog. */}
            <Dialog
                hidden={!showAccrualJsonDialog}
                onDismiss={toggleShowAccrualJsonDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Accrual data for ${props.poLineAccrualActivity.purchaseOrderNumber}-${props.poLineAccrualActivity.purchaseOrderLineItem} `
                }}
                modalProps={{
                    isBlocking: true
                }}
                minWidth={'60vw'}
            >
                <div className={componentStyles.accrualJsonReferenceContainer}>
                    <div>
                        <Text variant='medium' className={componentStyles.accrualJsonReferenceHeading}>AccrualsProcessingStatus</Text><br/>
                        <Text variant='small'>0: New</Text><br/>
                        <Text variant='small'>1: InProgress</Text><br/>
                        <Text variant='small'>2: Success</Text><br/>
                        <Text variant='small'>3: Failure</Text><br/>
                        <Text variant='small'>4: PartialFailure</Text><br/>
                        <br/>
                        <Text variant='medium' className={componentStyles.accrualJsonReferenceHeading}>JeStatus</Text><br/>
                        <Text variant='small'>0: InProgress</Text><br/>
                        <Text variant='small'>1: Succeeded</Text><br/>
                        <Text variant='small'>2: Failed</Text><br/>
                        <br/>
                        <Text variant='medium' className={componentStyles.accrualJsonReferenceHeading}>PurchaseOrderActivityType</Text><br/>
                        <Text variant='small'>0: Receipt</Text><br/>
                        <Text variant='small'>1: AccountingModified</Text><br/>
                        <Text variant='small'>2: Invoice</Text><br/>
                        <Text variant='small'>3: POLineClosed</Text><br/>
                        <Text variant='small'>4: Onboarding</Text><br/>
                        <Text variant='small'>5: LineTotalReduced</Text><br/>
                        <Text variant='small'>6: Offboarding</Text><br/>
                        <br/>
                        <Text variant='medium' className={componentStyles.accrualJsonReferenceHeading}>OpenAccrualStatus</Text><br/>
                        <Text variant='small'>0: Unlocked</Text><br/>
                        <Text variant='small'>1: Locked</Text><br/>
                        <br/>
                    </div>
                </div>
                <div className={componentStyles.accrualJsonMainContainer}>
                    {isRefreshing && (
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    )}
                    {!isRefreshing && accrualObj && (
                        <ReactJson src={accrualObj || {}} />
                    )}
                    {!isRefreshing && !accrualObj && (
                        <Text>No data</Text>
                    )}
                </div>
                <DialogFooter>
                    <DefaultButton onClick={() => viewAccrualJson(true)} text="Refresh" disabled={isRefreshing} />
                    <DefaultButton onClick={toggleShowAccrualJsonDialog} text="Ok" />
                </DialogFooter>
            </Dialog>

            {/* Accrual audit history dialog. */}
            <Dialog
                hidden={!showAccrualAuditHistoryJsonDialog}
                onDismiss={toggleShowAccrualAuditHistoryJsonDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Accrual audit history for ${props.poLineAccrualActivity.purchaseOrderNumber}-${props.poLineAccrualActivity.purchaseOrderLineItem} `
                }}
                modalProps={{
                    isBlocking: true
                }}
                minWidth={'60vw'}
            >
                <div className={componentStyles.accrualAuditHistoryJsonContainer}>
                    {isRefreshing && (
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    )}
                    {!isRefreshing && accrualAuditHistoryObj && (
                        <ReactJson src={accrualAuditHistoryObj || {}} />
                    )}
                    {!isRefreshing && !accrualAuditHistoryObj && (
                        <Text>No data</Text>
                    )}
                </div>
                <DialogFooter>
                    <DefaultButton onClick={() => viewAccrualAuditHistoryJson(true)} text="Refresh" disabled={isRefreshing} />
                    <DefaultButton onClick={toggleShowAccrualAuditHistoryJsonDialog} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
