import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    filterComboBox: {
        width: '170px'
    } as IStyle,
    downloadButton: {
        width: '100px'
    } as IStyle,
    fontIcon: {
        margin: '0 6px 0 0'
    } as IStyle,
    excelIcon: {
        margin: '0 8px 0 0'
    } as IStyle,
    hiddenInput: {
        display: 'none'
    } as IStyle
});
