import React from 'react';
import { useId } from '@fluentui/react-hooks';
import { Text, TooltipDelay, TooltipHost } from '@fluentui/react';
import { componentStyles } from './CustomColumnHeader.styles';
import { CustomTeachingBubble, teachingBubbleCheckShow, TeachingBubbleItem } from '../CustomTeachingBubble/CustomTeachingBubble';

export interface CustomColumnHeaderProps {
    headerLine1?: string;
    headerLine2?: string;
    tooltip?: string;
    showTeachingBubble?: boolean;
    teachingBubbleItem?: TeachingBubbleItem;
    teachingBubbleHeadline?: string;
    teachingBubbleContent?: string;
}

/**
 * Custom column header. Has support for:
 *  - Two line header.
 *  - Teaching bubble.
 * @param props Custom column header props.
 * @returns JSX for the component.
 */
export const CustomColumnHeader: React.FunctionComponent<CustomColumnHeaderProps> = (props: CustomColumnHeaderProps): JSX.Element => {
    const componentId: string = useId();

    return (
        <div id={componentId}>
            <TooltipHost content={props.tooltip} delay={TooltipDelay.long}>
                <div>
                    <Text className={componentStyles.columnHeading}>
                        {props.headerLine1}<br/>
                        {props.headerLine2}
                    </Text>
                </div>
            </TooltipHost>

            { props.showTeachingBubble && props.teachingBubbleItem && props.teachingBubbleHeadline && props.teachingBubbleContent &&
              teachingBubbleCheckShow(props.teachingBubbleItem) && (
                <CustomTeachingBubble
                    targetElemId={componentId}
                    teachingBubbleItem={props.teachingBubbleItem}
                    teachingBubbleHeadline={props.teachingBubbleHeadline}
                    teachingBubbleContent={props.teachingBubbleContent}
                />
            )}
        </div>
    );
};
