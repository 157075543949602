import { ErrorDetail } from './errorDetail';

/**
 * Directly models the server side BulkUpdateResult object. If that one changes, then this one must change.
 */
export interface IBulkUpdateResult {
    errorDetails: ErrorDetail[];
    totalSuccessCount: number;
    totalFailureCount: number;
}

export class BulkUpdateResult implements IBulkUpdateResult {
    public errorDetails: ErrorDetail[] = [];
    public totalSuccessCount: number;
    public totalFailureCount: number;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IBulkUpdateResult) {
        if (jsonData.errorDetails) {
            for (let i: number = 0; i < jsonData.errorDetails.length; i++) {
                this.errorDetails.push(new ErrorDetail(jsonData.errorDetails[i]));
            }
        }
        this.totalSuccessCount = jsonData.totalSuccessCount;
        this.totalFailureCount = jsonData.totalFailureCount;
    }
}
