import { ExecFunctionDetailCode } from './execFunctionDetailCode';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IExecFunctionCode extends IHierarchyNodeCommon {
    functionDetailCode: ExecFunctionDetailCode[];
}

export class ExecFunctionCode implements IExecFunctionCode {
    public typeName: string = HierarchyConstants.className.executive.execFunctionCode;
    public code: string;
    public description: string;
    public functionDetailCode: ExecFunctionDetailCode[] = [];
    public children?: IHierarchyNodeCommon[] = this.functionDetailCode; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IExecFunctionCode) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.functionDetailCode) {
            for (let i: number = 0; i < jsonData.functionDetailCode.length; i++) {
                this.functionDetailCode.push(new ExecFunctionDetailCode(jsonData.functionDetailCode[i]));
            }
        }
    }
}
