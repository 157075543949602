import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { editLineItemReceivedAmount, editLineItemReceivedAmountHasError } from '../../store/actions/pageActions/editPage.action';
import { commonString } from '../../common/commonString';
import { validationConstants } from '../../common/validationConstants';
import { ActionButton, Stack, TextField, TooltipDelay, TooltipHost } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { stackTokensSmallGap } from '../../common/common.styles';
import { pageStyles } from './EditPage.styles';
import { tooltips } from '../../common/tooltips';
import { useBoolean } from '@fluentui/react-hooks';
import { ReceivedAmountAdjustDialog } from './ReceivedAmountAdjustDialog';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { appConfig } from '../../shell/appConfig';

interface IReceivedAmountInputProps {
    item: PurchaseOrderLineItem;
}

export const ReceivedAmountInput: React.FunctionComponent<IReceivedAmountInputProps> = (props: IReceivedAmountInputProps): JSX.Element => {
    const [errorMsg, setErrorMsg] = useState<string>('');
    // Using two local state values for the received amount input. One is a string which is bound to the text box.
    // The other is to store the numeric value. This makes it so users can freely type in the text box as a string.
    const [receivedAmountInput, setReceivedAmountInput] = React.useState<string>(String(props.item.receivedAmount));
    const [receivedAmount, setReceivedAmount] = React.useState<number>(Number(props.item.receivedAmount || 0));
    const [showReceivedAmountAdjustDialog, { toggle: toggleShowReceivedAmountAdjustDialog }] = useBoolean(false);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect that will update the local state for received amount if the props.item.receivedAmount changes.
     */
    useEffect(() => {
        setReceivedAmountInput(String(props.item.receivedAmount));
        setReceivedAmount(Number(props.item.receivedAmount || 0));
    }, [props.item.receivedAmount]);

    return (
        <>
            <Stack horizontal tokens={stackTokensSmallGap}>
                <Stack.Item>
                    <TooltipHost content={validationConstants.receivedAmount.tooltip} delay={TooltipDelay.long}>
                        <TextField
                            id={`receivedAmount-${props.item.purchaseOrderLineNumber}`}
                            autoComplete='off'
                            ariaLabel={commonString.receivedAmount}
                            value={receivedAmountInput}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                newValue = newValue || '';
                                newValue = newValue.trim();

                                if (newValue.length > 0 &&
                                    (
                                        newValue?.length > validationConstants.receivedAmount.maxLength! ||
                                        !RegExp(validationConstants.receivedAmount.pattern!).test(newValue)
                                    )
                                ) {
                                    setErrorMsg(validationConstants.receivedAmount.errorMsg!);
                                    dispatch(editLineItemReceivedAmountHasError(props.item.purchaseOrderLineNumber, true));
                                } else {
                                    setErrorMsg('');
                                    dispatch(editLineItemReceivedAmountHasError(props.item.purchaseOrderLineNumber, false));
                                }

                                setReceivedAmountInput(newValue);
                                const newValueAsNumber = newValue && newValue.length > 0 ? Number(newValue) : 0;
                                // If the user started by entering a decimal point, then the number will be NaN.
                                if (!Number.isNaN(newValueAsNumber)) {
                                    setReceivedAmount(newValueAsNumber);
                                }
                            }}
                            onBlur={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                // Ensure the min/max boundaries are not crossed.
                                let newReceivedAmount: number = receivedAmount;
                                if (newReceivedAmount < Number(props.item.invoicedAmount)) {
                                    newReceivedAmount = props.item.invoicedAmount || 0;
                                }
                                if (newReceivedAmount > Number(props.item.lineTotal)) {
                                    newReceivedAmount = Number(props.item.lineTotal);
                                }

                                if (newReceivedAmount !== receivedAmount) {
                                    // Update local component state.
                                    setReceivedAmountInput(String(newReceivedAmount));
                                    setReceivedAmount(newReceivedAmount);
                                }

                                // Dispatch the change to the store.
                                dispatch(editLineItemReceivedAmount(props.item.purchaseOrderLineNumber, newReceivedAmount));
                            }}
                            errorMessage={errorMsg}
                            disabled={props.item.checkToDisableEditing()}
                        />
                    </TooltipHost>
                </Stack.Item>
                {appConfig.current.featureFlighting.serviceLineAdjustAmount && (
                    <Stack.Item>
                        <TooltipHost content={tooltips.receivedAmountAdjustButton} delay={TooltipDelay.long}>
                            <ActionButton
                                id={`adjustAmount-${props.item.purchaseOrderLineNumber}`}
                                className={pageStyles.actionButtonAdjustAmount}
                                onClick={() => {
                                    telemetryService.trackEvent({ name: trackedEvent.serviceReceiptAddAmountButtonClicked }, {
                                        po: props.item.purchaseOrderNumber, line: props.item.purchaseOrderLineNumber
                                    });
                                    toggleShowReceivedAmountAdjustDialog();
                                }}
                                disabled={props.item.checkToDisableEditing() ||
                                    Number(props.item.receivedAmount) >= Number(props.item.lineTotal)
                                }
                            >
                                <div className={pageStyles.actionButtonAdjustAmountText}>Adjust<br/>amount</div>
                            </ActionButton>
                        </TooltipHost>
                    </Stack.Item>
                )}
            </Stack>

            <ReceivedAmountAdjustDialog
                display={showReceivedAmountAdjustDialog}
                lineTotal={props.item.lineTotal || 0}
                currentReceivedAmount={Number(props.item.receivedAmount || 0)}
                // Using toFixed below to work around floating point precision issue.
                // ex: 0.1 + 0.2 = 0.30000000000000004
                //     0.5 - 0.4 = 0.09999999999999998
                // https://stackoverflow.com/questions/5037839/avoiding-problems-with-javascripts-weird-decimal-calculations
                maxAddAllowed={Number((Number(props.item.lineTotal) - Number(props.item.receivedAmount)).toFixed(props.item.currencyFractionalPart))}
                maxSubtractAllowed={Number((Number(props.item.receivedAmount) - Number(props.item.invoicedAmount)).toFixed(props.item.currencyFractionalPart))}
                onOkClicked={(addAmount: number) => {
                    dispatch(editLineItemReceivedAmount(props.item.purchaseOrderLineNumber, Number((props.item.receivedAmount || 0)) + addAmount));
                    toggleShowReceivedAmountAdjustDialog();
                }}
                onCancelClicked={() => {
                    toggleShowReceivedAmountAdjustDialog();
                }}
            />
        </>
    );
};
