import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    reportBlock: {
        margin: '12px 0 0 0'
    } as IStyle,
    noPermission: {
        color: '#e50000'
    } as IStyle,
    hasPermission: {
        color: 'green'
    } as IStyle,
    fontIcon: {
        margin: '0 6px 0 0'
    } as IStyle,
    accessRequestInstructions: {
        'ol': {
            margin: '0 0 0 0'
        } as IStyle
    } as IStyle,
    downloadButton: {
        width: '100px'
    } as IStyle
});
