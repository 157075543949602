import { IStyle, mergeStyles } from '@fluentui/react';

// Global styles used site wide.
mergeStyles({
    ':global(body), :global(html), :global(#app)': {
        margin: 0,
        padding: 0,
        height: '100vh'
    } as IStyle
});
