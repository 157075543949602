import React from 'react';
import {
    FontIcon,
    Link,
    Separator,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';

interface IComponentProps {
}

interface IDriReport {
    displayName: string;
    description: string;
    reportUrl: string;
}

const shipmentReport: IDriReport = {
    displayName: 'Shipment Dashboard',
    description: 'Dashboard for shipment performance and exception metrics.',
    reportUrl: 'https://aka.ms/ShipmentReport'
};

export const DriReportsTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    /**
     * Render a report block.
     * @param driReport DRI report..
     * @returns JSX for report block.
     */
    const renderReportBlock = (driReport: IDriReport): JSX.Element => {
        return (
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="mediumPlus" className={commonStyles.sectionHeading} role="heading" aria-level={1}>{driReport.displayName}</Text>
                </Stack.Item>
                <Stack.Item>
                    {driReport.description}
                </Stack.Item>
                <Stack.Item>
                    <Link href={driReport.reportUrl} target="_blank">
                        Open report
                        <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                    </Link>
                </Stack.Item>
            </Stack>
        );
    };

    return (
        <>
            <Separator />

            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="medium">Below are the Power BI reports available to DRIs.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Separator />
                    {
                        renderReportBlock(shipmentReport)
                    }
                    <Separator />
                </Stack.Item>
            </Stack>
        </>
    );
};
