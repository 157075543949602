import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { PurchaseOrderLineReceiptsAndAccruals } from '../../models/accrualDashboard/purchaseOrderLineReceiptsAndAccruals';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { pageStyles } from './AccrualDashboardPage.styles';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';

const purchaseOrderNumberColumn: ICustomColumn = {
    key: 'purchaseOrderNumber',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PO'
                headerLine2='Number'
            />
        );
    },
    isResizable: true,
    minWidth: 70,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderNumber}
            </Text>
        );
    }
};

const lineItemNumberColumn: ICustomColumn = {
    key: 'lineItemNumber',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line'
                headerLine2='Number'
            />
        );
    },
    isResizable: true,
    minWidth: 70,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.lineItemNumber}
            </Text>
        );
    }
};

const companyCodeColumn: ICustomColumn = {
    key: 'companyCode',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Company'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.companyCode}
            </Text>
        );
    }
};

const currencyCodeColumn: ICustomColumn = {
    key: 'currencyCode',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Currency'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.currencyCode}
            </Text>
        );
    }
};

const receiptAccrualLabelColumn: ICustomColumn = {
    key: 'receiptAccrualLabel',
    fieldName: undefined,
    name: '',
    isResizable: false,
    minWidth: 40,
    onRender: () => {
        return (
            <div>
                <div>Accrual:</div>
                <div>Receipt:</div>
            </div>
        );
    }
};

const glAccountCodeColumn: ICustomColumn = {
    key: 'glAccountCode',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='GL Account'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 90,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.glAccountCode !== item.receiptLine?.glAccountCode ? pageStyles.notEqual : ''}>
                <div>{item.accrualLine?.glAccountCode}</div>
                <div>{item.receiptLine?.glAccountCode}</div>
            </div>
        );
    }
};

const internalOrderColumn: ICustomColumn = {
    key: 'internalOrder',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Internal'
                headerLine2='Order'
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.internalOrder !== item.receiptLine?.internalOrder ? pageStyles.notEqual : ''}>
                <div>{item.accrualLine?.internalOrder}</div>
                <div>{item.receiptLine?.internalOrder}</div>
            </div>
        );
    }
};

const costCenterColumn: ICustomColumn = {
    key: 'costCenter',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Cost'
                headerLine2='Center'
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.costCenter !== item.receiptLine?.costCenter ? pageStyles.notEqual : ''}>
                <div>{item.accrualLine?.costCenter}</div>
                <div>{item.receiptLine?.costCenter}</div>
            </div>
        );
    }
};

const lineItemTotalAmountColumn: ICustomColumn = {
    key: 'lineItemTotalAmount',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line Item'
                headerLine2='Total Amount'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.lineItemTotalAmount !== item.receiptLine?.lineItemTotalAmount ? pageStyles.notEqual : ''}>
                <div>{ numberAsLocaleString(item.accrualLine?.lineItemTotalAmount, 2)}</div>
                <div>{ numberAsLocaleString(item.receiptLine?.lineItemTotalAmount, 2)}</div>
            </div>
        );
    }
};

const receivedAmountColumn: ICustomColumn = {
    key: 'receivedAmount',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Received'
                headerLine2='Amount'
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.receivedAmount !== item.receiptLine?.receivedAmount ? pageStyles.notEqual : ''}>
                <div>{ numberAsLocaleString(item.accrualLine?.receivedAmount, 2)}</div>
                <div>{ numberAsLocaleString(item.receiptLine?.receivedAmount, 2)}</div>
            </div>
        );
    }
};

const invoicedAmountColumn: ICustomColumn = {
    key: 'invoicedAmount',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Invoiced'
                headerLine2='Amount'
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.invoicedAmount !== item.receiptLine?.invoicedAmount ? pageStyles.notEqual : ''}>
                <div>{ numberAsLocaleString(item.accrualLine?.invoicedAmount, 2)}</div>
                <div>{ numberAsLocaleString(item.receiptLine?.invoicedAmount, 2)}</div>
            </div>
        );
    }
};

const isClosedColumn: ICustomColumn = {
    key: 'isClosed',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Is'
                headerLine2='Closed'
            />
        );
    },
    isResizable: true,
    minWidth: 70,
    onRender: (item: PurchaseOrderLineReceiptsAndAccruals) => {
        return (
            <div className={item.accrualLine?.isClosed !== item.receiptLine?.isClosed ? pageStyles.notEqual : ''}>
                <div>{item.accrualLine?.isClosed ? 'true' : 'false'}</div>
                <div>{item.receiptLine?.isClosed ? 'true' : 'false'}</div>
            </div>
        );
    }
};

export const accrualsOutOfSyncColumns: IColumn[] = [
    purchaseOrderNumberColumn,
    lineItemNumberColumn,
    companyCodeColumn,
    currencyCodeColumn,
    receiptAccrualLabelColumn,
    glAccountCodeColumn,
    internalOrderColumn,
    costCenterColumn,
    lineItemTotalAmountColumn,
    receivedAmountColumn,
    invoicedAmountColumn,
    isClosedColumn
];
