import React from 'react';
import { ICommonPageProps } from '../../common/common.types';
import {
    FontIcon,
    Link,
    Image,
    Separator,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap, stackTokensSmallGap } from '../../common/common.styles';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { pageStyles } from './FaqPage.styles';
import { useNavigate } from 'react-router';
import { appConstants } from '../../common/appConstants';

interface IPageProps extends ICommonPageProps {
}

export const FaqPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const navigate = useNavigate();

    const renderQa = (q: JSX.Element, a: JSX.Element): JSX.Element => {
        return (
            <Stack.Item>
                <div className={pageStyles.question}>
                    {q}
                </div>
                <div className={pageStyles.answer}>
                    {a}
                </div>
            </Stack.Item>
        );
    };

    const renderLink = (text: string, url: string, iconName: string, openInNewWindow: boolean): JSX.Element => {
        return (
            <Link href={url} target={openInNewWindow ? '_blank' : '_self'}>
                {text}
                <FontIcon iconName={iconName} className={commonStyles.linkIcon} />
            </Link>
        );
    };

    const renderMyOrderLink = (): JSX.Element => {
        return renderLink('MyOrder', 'https://aka.ms/myorder', 'OpenInNewWindow', true);
    };

    const renderMsInvoiceLink = (): JSX.Element => {
        return renderLink('MS Invoice', 'https://aka.ms/msinvoice', 'OpenInNewWindow', true);
    };

    const renderProcureWebLink = (): JSX.Element => {
        return renderLink('ProcureWeb', 'https://aka.ms/procureweb', 'OpenInNewWindow', true);
    };

    const renderFdaLink = (): JSX.Element => {
        return renderLink('Finance Digital Assistant', 'https://aka.ms/fda', 'OpenInNewWindow', true);
    };

    const renderOneFinanceGlobalContactPbiLink = (): JSX.Element => {
        return renderLink('One Finance Global Contact Addendum - Power BI', 'https://msit.powerbi.com/groups/me/reports/55ff1807-0409-41d3-84ee-9e19b3870791/ReportSectiond76251f3810d10eb3dda?experience=power-bi', 'PowerBILogo', true);
    };

    const renderExpensePolicyLink = (): JSX.Element => {
        return renderLink('Expense Recognition Policy', 'https://microsoft.sharepoint.com/:w:/r/sites/mspolicy/_layouts/15/Doc.aspx?sourcedoc=%7B1633C7DE-D761-43DF-9462-C989BBA6C517%7D&file=Expense-Recognition-Policy.docx&action=default&mobileredirect=true&DefaultItemOpen=1&wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&wdExp=TEAMS-TREATMENT&wdhostclicktime=1712742853582&web=1', 'OpenInNewWindow', true);
    };

    const renderPoOwnerResponsibilityLink = (): JSX.Element => {
        return renderLink('PO Owner Responsibility', `${appConstants.publicUrl}/files/PO owner responsibility.pptx`, 'PowerPointDocument', false);
    };

    const siteNav = (linkText: string, path: string): JSX.Element => {
        return (
            <Link onClick={() => navigate(`${appConstants.publicUrl}/${path}`)}>
                {linkText}
            </Link>
        );
    };

    return (
        <PageWrapper {...props}>
            <SectionWrapper>
                <Section>
                    <Stack tokens={stackTokensSmallGap}>
                        <Stack.Item>
                            <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={1}>Frequently Asked Questions</Text>
                        </Stack.Item>
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How are accruals completed in the GSR (Goods and Services Receipting) tool?
                            </>,
                            <>
                                An accrual is posted when services are marked received in the GSR tool, but invoicing has not
                                been approved.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                When should I confirm receipt in the GSR tool?
                            </>,
                            <>
                                When services are completed or goods are received, PO owners and/or invoice approvers need to
                                complete receipt in the GSR tool. Do not mark items as received if the delivery date has passed,
                                but you have not yet received the services or goods. To update the delivery date of the purchase
                                order, go to {renderMyOrderLink()}.<br/>
                                <br/>
                                Per Microsoft&apos;s {renderExpensePolicyLink()}, you should only do receipt when services
                                have been completed or goods are received so that expenses can be recorded in the correct fiscal
                                period.<br/>
                                <br/>
                                For the cost to be recorded in the current month, PO owners and invoice approvers must confirm
                                receipt date with the last day of the respective month. For example, for costs to be reflected
                                in the month of November, the receipt date would need to be updated with any date in the month
                                of November.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                What are the responsibilities of PO owners or their delegates?
                            </>,
                            <>
                                To perform receipting in the GSR tool for their respective POs as and when goods or services are
                                received. This is to recognize and record the expense or cost in the right period (month). For
                                more details, refer to {renderPoOwnerResponsibilityLink()}&nbsp; or the below screenshot.<br/>
                                <br/>
                                <Image src="/files/PO owner responsibility.png" alt="PO owner responsibility screenshot" />
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                What is the cutoff date for accrual postings during month end close?
                            </>,
                            <>
                                On the 1<sup>st</sup> calendar day (11.59PM PST) of the month, PO owners, invoice approvers or
                                their delegates will be able to receipt for the prior opening period.
                                Please ensure receipt is done within the prior period &quot;Receipt date&quot; for accrual to land in the
                                correct period.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                What should I do when the purchase order delivery date has passed, but services or goods have
                                not been delivered?
                            </>,
                            <>
                                Do not receipt a PO line item just because the delivery date is due. Goods or services must be
                                actually received to perform receipt of the goods or services. Accruing to Budget is not permitted
                                and it is non-compliance.<br/>
                                <br/>
                                If you have not received the service but the delivery date is due or over-due, you can update the
                                delivery date of the PO line item in {renderMyOrderLink()}.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        <Stack horizontal wrap tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                {renderQa(
                                    <>
                                        How do I change the PO delivery/milestone date?
                                    </>,
                                    <>
                                        Go to {renderMyOrderLink()} and follow the steps listed below:
                                        <ol style={{ minWidth: '300px' }}>
                                            <li>Find your purchase order.</li>
                                            <li>Click on <i>Find PO</i></li>
                                            <li>Drill into the PO to select the line item.</li>
                                            <li>Under the Action dropdown menu, select &quot;Update delivery date&quot;.</li>
                                            <li>Enter the new delivery date.</li>
                                            <li>Click on <i>Update line item</i>.</li>
                                        </ol>
                                    </>
                                )}
                            </Stack.Item>
                            <Stack.Item align="start">
                                <Image width={435} src="/files/MyOrderScreenshot1.jpg" alt="MyOrder screenshot" />
                            </Stack.Item>
                            <Stack.Item align="start">
                                <Image width={400} src="/files/MyOrderScreenshot2.jpg" alt="MyOrder screenshot" />
                            </Stack.Item>
                        </Stack>
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How do I delegate my POs in the GSR tool?
                            </>,
                            <>
                                If you are the PO owner or invoice approver, you can delegate your POs using the {siteNav('User Profile', 'UserProfile')} page
                                in the GSR tool. For more details on delegation, watch the <i>User profile and delegates</i> video
                                by selecting the video camera icon on the top right corner of the page.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                When should I close a PO line?
                            </>,
                            <>
                                You can close a PO line if there is no further service expected, no PO open amount or if you are
                                not expecting any invoices to be submitted. PO lines can be closed when the invoice status is
                                &quot;Ready for payment&quot; or &quot;Paid&quot;.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How to close a PO line?
                            </>,
                            <>
                                You can close a PO line in {renderMyOrderLink()} or in GSR. In GSR, navigate to the PO. On
                                the <i>Edit PO</i> page, select the &quot;Close Line Items&quot; link.
                                Click on the check box to close the PO line and the comments are optional.
                                For any PO related questions, go to {renderProcureWebLink()}. 
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Can I close my PO line if payment is pending?
                            </>,
                            <>
                                No. PO can be closed when the invoice status is &quot;Ready for payment&quot; or &quot;Paid&quot;.
                                Also, please note that closing a PO line will not impact the payment when the payment status is &quot;Ready for payment&quot;.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Can a PO be closed automatically?
                            </>,
                            <>
                                An automatic SAP process is scheduled for all subsidiaries on either a weekly or monthly basis
                                based on parameters defined by MS Procurement.
                                However, the best practice is to close POs when they are no longer needed.
                                <ul>
                                    <li>Order Types</li>
                                    <li>Company Codes</li>
                                    <li>% of invoiced amount against the PO</li>
                                    <li>% of residual open amount</li>
                                    <li>Open purchase order with 15+ months of inactivity
                                        <ul>
                                            <li>PO line is +15 months later than all the 3 dates:
                                                <ul>
                                                    <li>PO line delivery date</li>
                                                    <li>PO line last change date</li>
                                                    <li>PO line last invoice posting date</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How do I change the GL (general ledger) Account, Internal Order, Cost Center or Category on a purchase order?
                            </>,
                            <>
                                Changes must be made in {renderMyOrderLink()} which will automatically reflect in GSR.
                                For any assistance, raise a ticket via {renderProcureWebLink()}.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Who do I contact for support?
                            </>,
                            <>
                                For support, contact the respective contacts for your inquiry.
                                <ul>
                                    <li>Invoice related questions: {renderFdaLink()}</li>
                                    <li>PO maintenance: {renderProcureWebLink()}</li>
                                    <li>Accruals related questions: Your entity Finance Controller - {renderOneFinanceGlobalContactPbiLink()}</li>
                                </ul>
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                What happens to my accruals when the invoice is submitted?
                            </>,
                            <>
                                Accruals are automatically reversed when the invoice is submitted and approved in {renderMsInvoiceLink()}.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How can I confirm if an invoice has been received?
                            </>,
                            <>
                                In GSR, navigate to the PO. On the <i>Edit PO</i> page, select the &quot;MS Invoice&quot; link.
                                If an invoice has been received, it shows that the supplier submitted the invoice within {renderMsInvoiceLink()}.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How can I see the invoice payment status, approval date, and more insights on the invoice?
                            </>,
                            <>
                                In GSR, navigate to the PO. On the <i>Edit PO</i> page, select the &quot;MS Invoice&quot; link.<br/>
                                <br/>
                                Referring to the screenshot below:
                                <ul>
                                    <li>
                                        Approval date - Reflects the date that the Invoice is approved. This is the date that
                                        expenses are posted to the ledger. It is also the date considered in GSR for reversal
                                        of the accrual.
                                    </li>
                                    <li>
                                        Payment date - Indicates the date that the cash is sent from Microsoft to supplier.
                                        This does not impact the expense posted to the ledger or reversal of any accrual.
                                    </li>
                                    <li>
                                        Account Payable cut-off are as follows.
                                        <ul>
                                            <li>
                                                International: CD-3 (Close Day)
                                            </li>
                                            <li>
                                                1010 is the last calendar day of the month, i.e. 30<sup>th</sup> or 31<sup>st</sup>
                                            </li>
                                            <li>
                                                If the Approval date of an invoice is on or before 31<sup>st</sup> Dec, the expense
                                                will post in December, and the GSR accrual will be reversed in December.
                                            </li>
                                            <li>
                                                If the Approval date of the invoice is on or after 1<sup>st</sup> Jan, the
                                                expense will post in January, and the GSR accrual will be reversed in January.
                                            </li>
                                            <li>
                                                The reversal of the GSR accrual will net the purchase order line to zero.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        An invoice is posted to the ledger when approved through MS Invoice. If the invoice is
                                        approved on Jan 31, it will be posted in January. If it is approved on Feb 1, it posts
                                        in Feb.
                                    </li>
                                    <li>
                                        The approval of an invoice affects the accruals in the GSR tool.
                                    </li>
                                    <li>
                                        Submitting the invoice does not affect the GSR tool.
                                    </li>
                                    <li>
                                        The date that the invoice is paid to the supplier does not affect the GSR tool.
                                    </li>
                                </ul>
                                <Image width={820} src="/files/MSInvoiceScreenshot1.jpg" alt="MSInvoice screenshot" />
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Are manual accruals allowed in GSR?
                            </>,
                            <>
                                Accruals are posted automatically upon receipt of services. For any exceptions, please work with
                                your entity Finance Controller - {renderOneFinanceGlobalContactPbiLink()}.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Can I view the PO line audit history?
                            </>,
                            <>
                                In GSR, navigate to the PO and select the audit link available for each PO line.
                                A panel will appear on the right of the screen with information regarding the PO line.
                                It will capture the audit history of who received the PO line, PO closure, dates, and document
                                numbers for accruals via journal entries, and reversals of the receipted balances.
                                Note: Audit history details will be available only if a receipt is done by the user, all
                                above stated subsequent triggers will be visible there on.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Where can I view PO activity in GSR?
                            </>,
                            <>
                                The audit history captures all PO related activities including who received the PO line and
                                PO closure, etc.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                As a PO owner, what actions do I need to take during the fiscal year end?
                            </>,
                            <>
                                <ul>
                                    <li>Complete receipting for your PO lines before the end of the fiscal year for only the goods or services received. Do not receipt any POs if the goods or services have not been received.</li>
                                    <li>Open accruals posted prior to June 30th must be resolved by Q1 of the subsequent fiscal year. Follow up with the suppliers to submit invoices by the end of Q1.</li>
                                    <li>Any retention of accruals should have a POE/POD (Proof of Execution/Proof of Delivery).</li>
                                    <li>If no goods or services will be received.
                                        <ul>
                                            <li>Close the PO line.</li>
                                            <li>Reduce the receipting amount of the PO line to 0.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How can I reverse an accrual?
                            </>,
                            <>
                                Any accrual reversal should be carried out by adjusting the receipting % or the receipting amount and by closing the PO line item.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                How long does it take for an action to reflect in the GSR tool?
                            </>,
                            <>
                                Actions are reflected near real time with a maximum of 2 minutes to reflect.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                What supporting documentation is required for receipting?
                            </>,
                            <>
                                While the GSR tool does not require supporting documentation to be uploaded, information
                                provided during receipting should align with details in the respective POE/POD:
                                <ul>
                                    <li>Receipting amount or percentage.</li>
                                    <li>Nature of service.</li>
                                    <li>Condition of goods and services.</li>
                                    <li>Date of service or goods received.</li>
                                </ul>
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                What should I do when an invoice is delayed?
                            </>,
                            <>
                                Follow up with the respective supplier and ensure that the POE/POD accurately reflects the following:
                                <ul>
                                    <li>Receipting amount or percentage.</li>
                                    <li>Nature of service.</li>
                                    <li>Condition of goods and services.</li>
                                    <li>Date of service or goods received.</li>
                                </ul>
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Where is a POE/POD uploaded and saved?
                            </>,
                            <>
                                POE/POD is uploaded by the supplier and/or PO owner or invoice approver in MS Invoice as part
                                of the invoice creation process if required. For help with MS Invoice, leverage the Finance
                                Digital Assistant (FDA) within the tool.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Why isn&apos;t my PO showing in GSR?
                            </>,
                            <>
                                POs excluded from GSR are part of an exception list provided by Finance Controllers.
                                You may not be the PO owner or invoice approver. In this case the PO will need to be delegated to you.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Updated my milestones timeline in OneAsk for ECIF. The timeline updated in OneAsk but in the GSR
                                still is showing the old timeline. Should I expect the date also updated in GSR tool?
                            </>,
                            <>
                                You should update the delivery date in &quot;Update Completed PO&quot; from MyOrder tool. Once you
                                updated then it will reflect in the GSR tool.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                If you are a PO owner for an outsourced contract with fixed monthly invoices month after month
                                for the full FY (fiscal year). Do you still need to receive these monthly?
                            </>,
                            <>
                                Yes, do receipting monthly to the extent of service received, you can update the amount or
                                % accordingly.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                If we get a monthly invoice on an approved contract PO, do we still need to receipt the services?
                            </>,
                            <>
                                As per MS policy the expectation is to recognize the cost in the same period. To ensure we
                                would request to do the receipt as and when the service is rendered/goods received and once
                                invoice is received the accrual will be reversed to the extent of invoice received.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                If I receive 50% of the services in the month of delivery, but 50% will be delayed by 1 month,
                                I set the receipted amount to 50% for that line item. Do I also need to change the delivery date
                                of that line item to next month when I expect to receive the final 50%?
                            </>,
                            <>
                                Yes, please extend the delivery date in MyOrder tool.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Can we have multi-year POs?
                            </>,
                            <>
                                It is advised to maintain separate PO for each fiscal year instead of single PO for multiple
                                years, which will help avoid the business impact on prior year accruals.
                            </>
                        )}
                        <Separator className={pageStyles.separator} />
                        {renderQa(
                            <>
                                Quick links for various tools:
                            </>,
                            <>
                                <ul>
                                    <li>{renderMyOrderLink()}</li>
                                    <li>{renderMsInvoiceLink()}</li>
                                    <li>{renderProcureWebLink()}</li>
                                    <li>{renderFdaLink()} - For PO or invoice related queries.</li>
                                    <li>{renderOneFinanceGlobalContactPbiLink()} - For accrual related queries.</li>
                                </ul>
                            </>
                        )}
                    </Stack>
                </Section>
            </SectionWrapper>
        </PageWrapper>
    );
};
