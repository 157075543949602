import { isNullOrUndefinedOrEmptyString } from '../../common/common.func.general';
import { CorpDetails } from './corpDetails';
import { PriorYearAccrualStatus } from './priorYearAccrualStatus';

export interface IPriorYearAccrualLineItem {
    // The following fields are also available from either the PO header or line item.
    purchaseOrder: string;
    lineItem: string;
    companyCode: number;
    currency: string;
    currencyFractionalPart: number;
    purchaseOrderOwner: string;
    purchaseOrderApprover: string;
    invoiceApprover: string;
    vendorName: string;
    vendorNumber: string;
    poDescription: string; // This is the description from the PO header.
    lineDescription: string; // This is the line item description.
    poCreateDate: Date;
    deliveryDate: Date;
    receiptDate?: Date;
    lineTotal: number;
    lineTotalUsd: number;
    invoicedAmount: number;
    invoicedAmountUsd: number;
    openAmount: number;
    openAmountUsd: number;
    originalPlImpact: number;
    originalPlImpactUsd: number;
    dedicatedPl: number;
    dedicatedPlUsd: number;
    costCenter: string;
    ioNumber: string;
    glAccount: string;
    purchasingClassification: string;
    profitCenterCode: string;
    offlineProcessingRunning: boolean;
    offlineProcessingFailureMessage: string;
    canClose: boolean;
    isClosed: boolean;
    deleteIndicator: string;
    systemPostedAccrualAmount: number;
    systemPostedAccrualAmountUsd: number;
    fiscalYear: string; // FY22, FY21
    openAccrualAmount: number;
    openAccrualAmountUsd: number;
    execOrg: string;
    orgDetail: string;
    execOrgSummary: string;
    salesLocation: string;
    areaDesc: string;
    region: string;
    isRetained?: boolean;
    status?: PriorYearAccrualStatus;
    retentionExpirationDate?: Date;
    lastReceiptActionedBy?: string;
    invoicedAmountForCurrentFy?: number;
    invoicedAmountForCurrentFyUsd?: number;
    accrualReversalForCurrentFy?: number;
    accrualReversalForCurrentFyUsd?: number;
    corpDetails?: CorpDetails;
    channelOrg?: string;
    channelOrgSummary?: string;
    staticGlAccount?: string;
    staticCostCenter?: string;
    staticProfitCenter?: string;
    staticIO?: string;
    staticExecOrgSummary?: string;
    staticOrgSummary?: string;
    staticOrgDetail?: string;
    staticSalesLocation?: string;
    staticGLSubclassName?: string;
    staticGLSubclassCode?: string;
    staticGLGlAccountDescription?: string;
    staticGLClassDesc?: string;
    staticGLClassCode?: string;
    staticGLGroupNameDesc?: string;
    staticGLGroupNameCode?: string;
    staticGLLineItemDesc?: string;
    staticGLLineItemCode?: string;
    staticGLLineItemDetailDesc?: string;
    staticGLLineItemDetailCode?: string;
    dedicatedPnlAccountCode?: string;
}

export class PriorYearAccrualLineItem implements IPriorYearAccrualLineItem {
    public purchaseOrder: string;
    public lineItem: string;
    public companyCode: number;
    public currency: string;
    public currencyFractionalPart: number;
    public purchaseOrderOwner: string;
    public purchaseOrderApprover: string;
    public invoiceApprover: string;
    public vendorName: string;
    public vendorNumber: string;
    public poDescription: string; // This is the description from the PO header. Sometimes referred to as "projectName" in SAP.
    public lineDescription: string; // This is the line item description.
    public poCreateDate: Date;
    public deliveryDate: Date;
    public receiptDate?: Date;
    public lineTotal: number;
    public lineTotalUsd: number;
    public invoicedAmount: number;
    public invoicedAmountUsd: number;
    public openAmount: number;
    public openAmountUsd: number;
    public originalPlImpact: number;
    public originalPlImpactUsd: number;
    public dedicatedPl: number;
    public dedicatedPlUsd: number;
    public costCenter: string;
    public ioNumber: string;
    public glAccount: string;
    public purchasingClassification: string;
    public profitCenterCode: string;
    public offlineProcessingRunning: boolean;
    public offlineProcessingFailureMessage: string;
    public canClose: boolean;
    public isClosed: boolean;
    public deleteIndicator: string;
    public systemPostedAccrualAmount: number;
    public systemPostedAccrualAmountUsd: number;
    public fiscalYear: string; // FY22, FY21
    public openAccrualAmount: number;
    public openAccrualAmountUsd: number;
    public execOrg: string;
    public orgDetail: string;
    public execOrgSummary: string;
    public salesLocation: string;
    public areaDesc: string;
    public region: string;
    public isRetained?: boolean | undefined;
    public status?: PriorYearAccrualStatus;
    public retentionExpirationDate?: Date | undefined;
    public lastReceiptActionedBy?: string;
    public invoicedAmountForCurrentFy?: number;
    public invoicedAmountForCurrentFyUsd?: number;
    public accrualReversalForCurrentFy?: number;
    public accrualReversalForCurrentFyUsd?: number;
    public corpDetails?: CorpDetails;
    public channelOrg?: string;
    public channelOrgSummary?: string;
    public staticGlAccount?: string;
    public staticCostCenter?: string;
    public staticProfitCenter?: string;
    public staticIO?: string;
    public staticExecOrgSummary?: string;
    public staticOrgSummary?: string;
    public staticOrgDetail?: string;
    public staticSalesLocation?: string;
    public staticGLSubclassName?: string;
    public staticGLSubclassCode?: string;
    public staticGLGlAccountDescription?: string;
    public staticGLClassDesc?: string;
    public staticGLClassCode?: string;
    public staticGLGroupNameDesc?: string;
    public staticGLGroupNameCode?: string;
    public staticGLLineItemDesc?: string;
    public staticGLLineItemCode?: string;
    public staticGLLineItemDetailDesc?: string;
    public staticGLLineItemDetailCode?: string;
    public dedicatedPnlAccountCode?: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isSelected: boolean = false;
    // This isDuplicate flag is used to handle the case where there might be multiple records with the same PO and line
    // number. This can happen in the finance controller search results if a line item had multiple receipt actions across
    // fiscal years. Only show the checkbox to select/unselect the line if this is not a duplicate.
    public isDuplicate: boolean = false;

    /**
     * Supplier (vendor) name/number combo string.
     */
    public get supplierNameNumberCombo(): string {
        return `${this.vendorName} (${this.vendorNumber})`;
    }

    /**
     * Cost center and internal order as 'Cost Center/IO' (only one of these 2 will generally be applicable).
     * @returns Merged cost center and internal order.
     */
    public get costCenterInternalOrder(): string | undefined {
        if (isNullOrUndefinedOrEmptyString(this.costCenter) && !isNullOrUndefinedOrEmptyString(this.ioNumber)) {
            return this.ioNumber;
        } else if (!isNullOrUndefinedOrEmptyString(this.costCenter) && isNullOrUndefinedOrEmptyString(this.ioNumber)) {
            return this.costCenter;
        } else if (!isNullOrUndefinedOrEmptyString(this.costCenter) && !isNullOrUndefinedOrEmptyString(this.ioNumber)) {
            return `${this.costCenter}/${this.ioNumber}`;
        }
        return '';
    }

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPriorYearAccrualLineItem) {
        this.purchaseOrder = jsonData.purchaseOrder;
        this.lineItem = jsonData.lineItem;
        this.companyCode = jsonData.companyCode;
        this.currency = jsonData.currency;
        this.currencyFractionalPart = jsonData.currencyFractionalPart;
        this.purchaseOrderOwner = jsonData.purchaseOrderOwner;
        this.purchaseOrderApprover = jsonData.purchaseOrderApprover;
        this.invoiceApprover = jsonData.invoiceApprover;
        this.vendorName = jsonData.vendorName;
        this.vendorNumber = jsonData.vendorNumber;
        this.poDescription = jsonData.poDescription;
        this.lineDescription = jsonData.lineDescription;
        this.poCreateDate = new Date(jsonData.poCreateDate);
        this.deliveryDate = new Date(jsonData.deliveryDate);
        this.receiptDate = jsonData.receiptDate ? new Date(jsonData.receiptDate) : undefined;
        this.lineTotal = jsonData.lineTotal;
        this.lineTotalUsd = jsonData.lineTotalUsd;
        this.invoicedAmount = jsonData.invoicedAmount;
        this.invoicedAmountUsd = jsonData.invoicedAmountUsd;
        this.openAmount = jsonData.openAmount;
        this.openAmountUsd = jsonData.openAmountUsd;
        this.originalPlImpact = jsonData.originalPlImpact;
        this.originalPlImpactUsd = jsonData.originalPlImpactUsd;
        this.dedicatedPl = jsonData.dedicatedPl;
        this.dedicatedPlUsd = jsonData.dedicatedPlUsd;
        this.costCenter = jsonData.costCenter;
        this.ioNumber = jsonData.ioNumber;
        this.glAccount = jsonData.glAccount;
        this.purchasingClassification = jsonData.purchasingClassification;
        this.profitCenterCode = jsonData.profitCenterCode;
        this.offlineProcessingRunning = jsonData.offlineProcessingRunning;
        this.offlineProcessingFailureMessage = jsonData.offlineProcessingFailureMessage;
        this.canClose = jsonData.canClose;
        this.isClosed = jsonData.isClosed;
        this.deleteIndicator = jsonData.deleteIndicator;
        this.systemPostedAccrualAmount = jsonData.systemPostedAccrualAmount;
        this.systemPostedAccrualAmountUsd = jsonData.systemPostedAccrualAmountUsd;
        this.fiscalYear = jsonData.fiscalYear;
        this.openAccrualAmount = jsonData.openAccrualAmount;
        this.openAccrualAmountUsd = jsonData.openAccrualAmountUsd;
        this.execOrg = jsonData.execOrg;
        this.orgDetail = jsonData.orgDetail;
        this.execOrgSummary = jsonData.execOrgSummary;
        this.salesLocation = jsonData.salesLocation;
        this.areaDesc = jsonData.areaDesc;
        this.region = jsonData.region;
        this.isRetained = jsonData.isRetained;
        this.status = jsonData.status as PriorYearAccrualStatus;
        this.retentionExpirationDate = jsonData.retentionExpirationDate ? new Date(jsonData.retentionExpirationDate) : undefined;
        this.lastReceiptActionedBy = jsonData.lastReceiptActionedBy;
        this.invoicedAmountForCurrentFy = jsonData.invoicedAmountForCurrentFy;
        this.invoicedAmountForCurrentFyUsd = jsonData.invoicedAmountForCurrentFyUsd;
        this.accrualReversalForCurrentFy = jsonData.accrualReversalForCurrentFy;
        this.accrualReversalForCurrentFyUsd = jsonData.accrualReversalForCurrentFyUsd;
        this.corpDetails = jsonData.corpDetails ? new CorpDetails(jsonData.corpDetails) : undefined;
        this.channelOrg = jsonData.channelOrg;
        this.channelOrgSummary = jsonData.channelOrgSummary;
        this.staticGlAccount = jsonData.staticGlAccount;
        this.staticCostCenter = jsonData.staticCostCenter;
        this.staticProfitCenter = jsonData.staticProfitCenter;
        this.staticIO = jsonData.staticIO;
        this.staticExecOrgSummary = jsonData.staticExecOrgSummary;
        this.staticOrgSummary = jsonData.staticOrgSummary;
        this.staticOrgDetail = jsonData.staticOrgDetail;
        this.staticSalesLocation = jsonData.staticSalesLocation;
        this.staticGLSubclassName = jsonData.staticGLSubclassName;
        this.staticGLSubclassCode = jsonData.staticGLSubclassCode;
        this.staticGLGlAccountDescription = jsonData.staticGLGlAccountDescription;
        this.staticGLClassDesc = jsonData.staticGLClassDesc;
        this.staticGLClassCode = jsonData.staticGLClassCode;
        this.staticGLGroupNameDesc = jsonData.staticGLGroupNameDesc;
        this.staticGLGroupNameCode = jsonData.staticGLGroupNameCode;
        this.staticGLLineItemDesc = jsonData.staticGLLineItemDesc;
        this.staticGLLineItemCode = jsonData.staticGLLineItemCode;
        this.staticGLLineItemDetailDesc = jsonData.staticGLLineItemDetailDesc;
        this.staticGLLineItemDetailCode = jsonData.staticGLLineItemDetailCode;
        this.dedicatedPnlAccountCode = jsonData.dedicatedPnlAccountCode;
    }
}
