import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Checkbox,
    Panel,
    PanelType,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { hideAuditForLineItem } from '../../store/actions/pageActions/editPage.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { hideAuditForPriorYearAccrualLineItem } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { IApiLoadAuditHistory, callApiLoadAuditHistory } from '../../store/actions/miscActions/auditHistory.action';
import { CallApiState } from '../../store/actions/generic.action';
import { AuditHistoryEvent } from '../../models/auditHistory/auditHistoryEvent';
import { ErrorBar, clearErrorByIndex } from '../ErrorBar/ErrorBar';
import { formatDateTimeAdjustForPstUsingFormat, formatDateTimeUsingFormat } from '../../common/common.func.datetime';
import { componentStyles } from './LineItemAuditPanel.styles';
import { UserProfile } from '../../models/user/userProfile';

interface ILineItemAuditPanelProps {
}

/**
 * Line item audit history panel.
 * This panel is used on the edit and close line pages. It is also used on the prior year accruals page.
 * @param props Component props.
 * @returns JSX for component.
 */
export const LineItemAuditPanel: React.FunctionComponent<ILineItemAuditPanelProps> = (props: ILineItemAuditPanelProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [showDriInfo, setShowDriInfo] = useState<boolean>(false);

    // Redux store selectors to get state from the store when it changes.
    const showAuditForLineItem: PurchaseOrderLineItem | undefined =
        useAppSelector<PurchaseOrderLineItem | undefined>((state) => state.editPageReducer.showAuditForLineItem);
    const showAuditForPriorYearAccrualLineItem: PriorYearAccrualLineItem | undefined =
        useAppSelector<PriorYearAccrualLineItem | undefined>((state) => state.priorYearAccrualPageReducer.showAuditForPriorYearAccrualLineItem);
    const apiLoadAuditHistory: IApiLoadAuditHistory =
        useAppSelector<IApiLoadAuditHistory>((state) => state.auditHistoryReducer.apiLoadAuditHistory);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Memoized field that gets the PO number from either the showAuditForLineItem or the showAuditForPriorYearAccrualLineItem.
     * There is not have consistent naming between these objects, unfortunately.
     */
    const poNumber: string | undefined = useMemo<string | undefined>(() => {
        if (showAuditForLineItem) {
            return showAuditForLineItem.purchaseOrderNumber;
        } else if (showAuditForPriorYearAccrualLineItem) {
            return showAuditForPriorYearAccrualLineItem.purchaseOrder;
        }

        return undefined;
    }, [showAuditForLineItem, showAuditForPriorYearAccrualLineItem]);

    /**
     * Memoized field that gets the line number from either the showAuditForLineItem or the showAuditForPriorYearAccrualLineItem.
     * There is not have consistent naming between these objects, unfortunately.
     */
    const lineNumber: string | undefined = useMemo<string | undefined>(() => {
        if (showAuditForLineItem) {
            return showAuditForLineItem.purchaseOrderLineNumber;
        } else if (showAuditForPriorYearAccrualLineItem) {
            return showAuditForPriorYearAccrualLineItem.lineItem;
        }

        return undefined;
    }, [showAuditForLineItem, showAuditForPriorYearAccrualLineItem]);

    /**
     * Memoized field that determines if the panel should be open or not.
     */
    const isPanelOpen: boolean = useMemo<boolean>(() => {
        if (poNumber && lineNumber) {
            return true;
        } else {
            return false;
        }
    }, [lineNumber, poNumber]);

    /**
     * Memoized field to indicate if the audit history data is loading.
     */
    const isLoading = useMemo<boolean>(() => {
        return apiLoadAuditHistory.callApiState === CallApiState.Running;
    }, [apiLoadAuditHistory.callApiState]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadAuditHistory.errMsg) {
            handleError(apiLoadAuditHistory.errMsg);
        }
    }, [apiLoadAuditHistory.errMsg, handleError]);

    /**
     * Effect that will run on component load.
     * Will load the audit history for the selected PO and line.
     */
    useEffect(() => {
        if (poNumber && lineNumber) {
            dispatch(callApiLoadAuditHistory(poNumber, lineNumber));
        }
    }, [dispatch, lineNumber, poNumber]);

    /**
     * Scroll to the bottom of the panel.
     */
    const scrollToBottom = () => {
        document.getElementById('auditPanelBottom')?.scrollIntoView();
    };

    return (
        <Panel
            onRenderHeader={() => {
                return (
                    <div className={componentStyles.headerWrapper}>
                        <div>
                            <h1 className={componentStyles.headerText}>
                                Audit history for {poNumber}-{lineNumber}
                            </h1>
                            <div
                                className={componentStyles.scrollToBottom}
                                tabIndex={0}
                                onKeyDown={(e) => e.code === 'Enter' || e.code === 'Space' ? scrollToBottom() : null}
                                onClick={() => {
                                    scrollToBottom();
                                }
                            }>Scroll to bottom</div>
                        </div>
                        { userProfile?.isDri && (
                            <Checkbox
                                label="Toggle DRI information"
                                className={componentStyles.driCheckbox}
                                checked={showDriInfo}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean)=> {
                                    setShowDriInfo(checked === true);
                                }}
                            />
                        )}
                        <Separator />
                    </div>
                );
            }}
            isOpen={isPanelOpen}
            onDismiss={() => {
                setTimeout(() => {
                    if (showAuditForLineItem) {
                        dispatch(hideAuditForLineItem());
                    } else if (showAuditForPriorYearAccrualLineItem) {
                        dispatch(hideAuditForPriorYearAccrualLineItem());
                    }
                });
            }}
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
            type={PanelType.medium}
        >
            <Stack>
                {errors.length > 0 && (
                    <Stack.Item>
                        <ErrorBar errors={errors} onDismiss={(index: number) => {
                            setErrors(clearErrorByIndex(errors, index));
                        }} />
                    </Stack.Item>
                )}
                {isLoading && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Stack.Item>
                )}
                {!isLoading &&
                    (!apiLoadAuditHistory.auditHistoryEvents ||
                    (apiLoadAuditHistory.auditHistoryEvents && apiLoadAuditHistory.auditHistoryEvents.length === 0)) && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>No audit data found</Text>
                    </Stack.Item>
                )}
                {!isLoading && apiLoadAuditHistory.auditHistoryEvents && apiLoadAuditHistory.auditHistoryEvents.length > 0 && (
                    <>
                        {apiLoadAuditHistory.auditHistoryEvents?.map((ahe: AuditHistoryEvent, index: number) => {
                            return (
                                <Stack.Item key={index}>
                                    <table className={componentStyles.eventTable}>
                                        <tbody>
                                            <tr>
                                                <td className={componentStyles.eventNameCell}>
                                                    <h2>{ahe.parsedEvent}</h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={componentStyles.eventTimeCell}>
                                                    Local:&nbsp;{formatDateTimeUsingFormat(ahe.date, 'MMM D, YYYY, h:mm:ss A')}&nbsp;&nbsp;-&nbsp;&nbsp;PST:&nbsp;{formatDateTimeAdjustForPstUsingFormat(ahe.date, 'MMM D, YYYY, h:mm:ss A')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className={componentStyles.eventInfoCell}>
                                                    <ul className={componentStyles.infoList}>
                                                        {
                                                            ahe.additionalInformation.map((info, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        {info}
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </td>
                                            </tr>
                                            {showDriInfo && (
                                                <tr>
                                                    <td colSpan={2} className={componentStyles.eventInfoCell}>
                                                        DRI info
                                                        <ul className={componentStyles.infoList}>
                                                            {
                                                                ahe.driInformation.map((info, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            {info}
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </td>
                                                </tr>  
                                            )}
                                        </tbody>
                                    </table>
                                    <Separator />
                                </Stack.Item>
                            );
                        })}
                    </>
                )}
            </Stack>
            <div id="auditPanelBottom"></div>
        </Panel>
    );
};
