import { JournalEntry } from './journalEntry';

export interface IPOLineAccrualActivity {
    transactionId: string;
    purchaseOrderNumber: number;
    purchaseOrderLineItem: number;
    currency: string;
    poCompanyCode: string;
    purchaseOrderActivityType: string;
    accrualsProcessingStatus: string;
    internalOrder?: string;
    costCenter?: string;
    glAccountCode: string;
    accrualActivityId?: string;
    accrualActivityDate?: Date;
    invoiceAmount?: number;
    onboardingAccrualAmount?: number;
    receiptAmount?: number;
    journalEntries: JournalEntry[];
}

export class POLineAccrualActivity implements IPOLineAccrualActivity {
    public transactionId: string;
    public purchaseOrderNumber: number;
    public purchaseOrderLineItem: number;
    public currency: string;
    public poCompanyCode: string;
    public purchaseOrderActivityType: string;
    public accrualsProcessingStatus: string;
    public internalOrder?: string;
    public costCenter?: string;
    public glAccountCode: string;
    public accrualActivityId?: string;
    public accrualActivityDate?: Date;
    public invoiceAmount?: number;
    public onboardingAccrualAmount?: number;
    public receiptAmount?: number;
    public journalEntries: JournalEntry[] = [];

    // Additional data used in client that does not come from server.
    public clientRowKey?: string = '';
    public detailsRowJsx?: JSX.Element; // Used by onCustomRenderRow in CustomDetailsList.util.tsx to render a detail row.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPOLineAccrualActivity) {
        this.transactionId = jsonData.transactionId;
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.purchaseOrderLineItem = jsonData.purchaseOrderLineItem;
        this.currency = jsonData.currency;
        this.poCompanyCode = jsonData.poCompanyCode;
        this.purchaseOrderActivityType = jsonData.purchaseOrderActivityType;
        this.accrualsProcessingStatus = jsonData.accrualsProcessingStatus;
        this.internalOrder = jsonData.internalOrder;
        this.costCenter = jsonData.costCenter;
        this.glAccountCode = jsonData.glAccountCode;
        this.accrualActivityId = jsonData.accrualActivityId;
        this.accrualActivityDate = jsonData.accrualActivityDate ? new Date(jsonData.accrualActivityDate) : undefined;
        this.invoiceAmount = jsonData.invoiceAmount;
        this.onboardingAccrualAmount = jsonData.onboardingAccrualAmount;
        this.receiptAmount = jsonData.receiptAmount;

        if (jsonData.journalEntries) {
            for (let i: number = 0; i < jsonData.journalEntries.length; i++) {
                this.journalEntries.push(new JournalEntry(jsonData.journalEntries[i]));
            }
        }
    }

    public get internalOrderOrCostCenter(): string {
        // Only one of internalOrder or costCenter should exist. But just in case below code can return a string with both
        // if both are present. Both are to be displayed in one column in the data table.
        let str: string = '';
        if (this.internalOrder) {
            str += `IO: ${this.internalOrder} `;
        }
        if (this.costCenter) {
            str += `CC: ${this.costCenter}`;
        }
        return str.trim();
    }
}
