import { SearchOperationParameter } from '../../common/appEnums';
import { PurchaseOrderSearchFilter } from './purchaseOrderSearchFilter';
import { PurchaseOrderSearchMetaData } from './purchaseOrderSearchMetaData';

export interface IPurchaseOrderSearch {
    metaData: PurchaseOrderSearchMetaData;
    filter?: PurchaseOrderSearchFilter;
}

/**
 * This object is passed into the purchase order search api, and is not returned from
 * any api, so not needed to implement IsSchemaCompliant.
 */
export class PurchaseOrderSearch implements IPurchaseOrderSearch {
    public metaData: PurchaseOrderSearchMetaData;
    public filter?: PurchaseOrderSearchFilter;

    // Below attributes are not part of the IPurchaseOrderSearch which mirrors the server side model.
    public searchOperationParameter: SearchOperationParameter = SearchOperationParameter.None;

    /**
     * PurchaseOrderSearch constructor.
     * @param metaData Search metadata.
     * @param filter Search filter.
     */
    constructor(metaData: PurchaseOrderSearchMetaData, filter?: PurchaseOrderSearchFilter) {
        this.metaData = metaData;
        this.filter = filter || new PurchaseOrderSearchFilter({}); // If filter is null/undefined then use an empty filter object.
    }
}
