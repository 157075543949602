import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    Label,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { UserProfile } from '../../models/user/userProfile';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RoleScope } from '../../models/user/roleScope';
import { UserRole } from '../../models/user/userRole';
import { CompanyCodeSelection } from '../../components/CompanyCodeSelection/CompanyCodeSelection';
import { HierarchySelection } from '../../components/HierarchySelection/HierarchySelection';
import { SupplierSelection } from '../../components/SupplierSelection/SupplierSelection';
import { RequestAccessOptionKey, accessRequestGroupId, getRequestAccessOptions, submitSuccessMessage } from './accessRequestConstants';
import { appConstants } from '../../common/appConstants';
import { validationConstants } from '../../common/validationConstants';
import { pageStyles } from './AccessRequestPage.styles';
import { CallApiState } from '../../store/actions/generic.action';
import { AppDispatch } from '../../store/reduxStore';
import { AccessRequest } from '../../models/user/accessRequest';
import { AccessRequestStatus } from '../../models/user/accessRequestStatus';
import { IApiLoadFinanceControllerAccessRequestHistory, IApiSubmitAccessRequest, callApiLoadFinanceControllerAccessRequestHistory, callApiSubmitAccessRequest } from '../../store/actions/pageActions/accessRequestPage.action';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { Supplier } from '../../models/domain/supplier';
import { GraphUser } from '../../models/user/graphUser';
import { GraphUserInput } from '../../components/GraphUserInput/GraphUserInput';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { tooltips } from '../../common/tooltips';
import { commonString } from '../../common/commonString';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { stripAllCompanyCodeValue } from '../../components/CompanyCodeSelection/companyCodeUtil';
import { Role } from '../../common/appEnums';

interface IAccessRequestTabProps {
}

export const AccessRequestTab: React.FunctionComponent<IAccessRequestTabProps> = (props: IAccessRequestTabProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectHierarchyNotice, setSelectHierarchyNotice] = useState<boolean>(false);

    // For access requests:
    const [currentUserFcRoleScope, setCurrentUserFcRoleScope] = useState<RoleScope | undefined>();
    const [selectedRequestAccessOptionKey, setSelectedRequestAccessOptionKey] = useState<RequestAccessOptionKey>();
    const [fcRequestRoleScope, setFcRequestRoleScope] = useState<RoleScope>(new RoleScope({
        financeGeographySalesDistrictCodes: [],
        channelFunctionDetailCodes: [],
        executiveFunctionDetailCodes: [],
        companyCodes: [],
        suppliers: []
    }));
    const [requestOnBehalfOfGraphUser, setRequestOnBehalfOfGraphUser] = useState<GraphUser | undefined>();

    // For business justification for agent requests:
    const agentBusinessJustificationInputId: string = useId();
    const [agentBusinessJustification, setBusinessJustification] = useState<string>('');
    const [agentBusinessJustificationValidationError, setBusinessJustificationValidationError] = useState<string>('');

    const [displaySuccessDialog, { toggle: toggleDisplaySuccessDialog }] = useBoolean(false);
    const [successDialogMsg, setSuccessDialogMsg] = useState<string>();

    // Redux store selectors to get state from the store when it changes.
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);
    const apiSubmitAccessRequest: IApiSubmitAccessRequest =
        useAppSelector<IApiSubmitAccessRequest>(state => state.accessRequestPageReducer.apiSubmitAccessRequest);
    const apiLoadFinanceControllerAccessRequestHistory: IApiLoadFinanceControllerAccessRequestHistory =
        useAppSelector<IApiLoadFinanceControllerAccessRequestHistory>(state => state.accessRequestPageReducer.apiLoadFinanceControllerAccessRequestHistory);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * This effect is run once during component load.
     */
    useMountEffect(() => {
        // If the api call to load hierarchy has already been called, then no need to call it again.
        // If not yet called then call it now.
        if (apiLoadFinanceControllerAccessRequestHistory.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadFinanceControllerAccessRequestHistory());
        }
    });

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiSubmitAccessRequest.errMsg) {
            handleError(apiSubmitAccessRequest.errMsg);
        }
        if (apiLoadFinanceControllerAccessRequestHistory.errMsg) {
            handleError(apiLoadFinanceControllerAccessRequestHistory.errMsg);
        }
    }, [apiLoadFinanceControllerAccessRequestHistory.errMsg, apiSubmitAccessRequest.errMsg, handleError]);

    /**
     * Effect for when user profile loads or roles change.
     */
    useEffect(() => {
        // If user has FC role, there would only be one instance of it.
        const fcUserRoles: UserRole[] | undefined = userProfile?.roles?.filter(userRole => userRole.name === Role.FinanceController);
        if (fcUserRoles && fcUserRoles.length > 0) {
            const ownRoleScope: RoleScope = fcUserRoles[0].scope!;

            // Store the current user FC role scope in state.
            setCurrentUserFcRoleScope(ownRoleScope);
        } else {
            setCurrentUserFcRoleScope(undefined);
        }
    }, [userProfile?.roles]);

    /**
     * Effect for when selected request access option key changes.
     */
    useEffect(() => {
        // Reset the request on behalf of graph user (regardless of selected option key).
        setRequestOnBehalfOfGraphUser(undefined);

        if (selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerSelf && currentUserFcRoleScope) {
            // If selection is Finance Controller for yourself and you have an existing role scope.
            // Store state for the FC request for yourself, for company codes, suppliers, and hierarchies using the
            // current user FC role scope as initial state values. This allows for the user to see their current selections
            // and to make a new request if any changes are needed. Storing this in different state than the currentUserFcRoleScope
            // state as that one is intended to be read only to view the users current selections, while this state is editable.
            setFcRequestRoleScope(new RoleScope({
                financeGeographySalesDistrictCodes: currentUserFcRoleScope.financeGeographySalesDistrictCodes,
                channelFunctionDetailCodes: currentUserFcRoleScope.channelFunctionDetailCodes,
                executiveFunctionDetailCodes: currentUserFcRoleScope.executiveFunctionDetailCodes,
                // If the company codes array is empty, then add the value for all company codes (removed later when request submitted).
                companyCodes: currentUserFcRoleScope.companyCodes.length === 0 ? [String(appConstants.allCompanyCodeValue)] : currentUserFcRoleScope.companyCodes,
                suppliers: currentUserFcRoleScope.suppliers
            }));
        } else if (selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerSelf || selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther) {
            // If selection is for Finance Controller for yourself (and you have no existing role scope) or for others,
            // then set up a empty RoleScope.
            setFcRequestRoleScope(new RoleScope({
                financeGeographySalesDistrictCodes: [],
                channelFunctionDetailCodes: [],
                executiveFunctionDetailCodes: [],
                companyCodes: [String(appConstants.allCompanyCodeValue)],
                suppliers: []
            }));
        }
    }, [currentUserFcRoleScope, selectedRequestAccessOptionKey]);

    /**
     * Effect for when API call to submit access request completed.
     */
    useEffect(() => {
        if (apiSubmitAccessRequest.callApiState === CallApiState.DataAvailable) {
            toggleDisplaySuccessDialog();
        }
    }, [apiSubmitAccessRequest.callApiState, toggleDisplaySuccessDialog]);

    /**
     * Memoized helper to check if the request api is running.
     * @returns True or false.
     */
    const isRequestRunning = useMemo<boolean>(() => {
        if (apiSubmitAccessRequest.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSubmitAccessRequest.callApiState]);

    /**
     * Submit access request button clicked event handler.
     */
    const submitAccessRequestButtonClicked = () => {
        if (!userProfile) {
            // User profile (for current user) should never be blank here, but just in case, do nothing.
            return;
        }

        telemetryService.trackEvent({ name: trackedEvent.submitAccessRequestButtonClicked });

        let groupId: number | undefined = undefined;
        let roleScope: RoleScope | undefined = undefined;
        let bj: string | undefined = undefined;
        let requestorAlias: string | undefined = undefined;
        let requestorAadOid: string | undefined = undefined;

        if (selectedRequestAccessOptionKey === RequestAccessOptionKey.Agent) {
            groupId = accessRequestGroupId.agentRoleGroupId;
            roleScope = new RoleScope({
                // For agent request, use empty array for all of the following.
                financeGeographySalesDistrictCodes: [],
                channelFunctionDetailCodes: [],
                executiveFunctionDetailCodes: [],
                companyCodes: [],
                suppliers: []
            });
            bj = agentBusinessJustification; // Set the business justification only for agent requests.
            requestorAlias = userProfile.alias;
            requestorAadOid = userProfile.id;

            setSuccessDialogMsg(submitSuccessMessage.agent);
        } else if (selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerSelf) {
            groupId = accessRequestGroupId.financeControllerRoleGroupId;
            roleScope = new RoleScope({
                // For Finance Controller request, set all values to what was set in the input selector components.
                financeGeographySalesDistrictCodes: fcRequestRoleScope?.financeGeographySalesDistrictCodes || [],
                channelFunctionDetailCodes: fcRequestRoleScope?.channelFunctionDetailCodes || [],
                executiveFunctionDetailCodes: fcRequestRoleScope?.executiveFunctionDetailCodes || [],
                companyCodes: fcRequestRoleScope?.companyCodes || [],
                suppliers: fcRequestRoleScope?.suppliers || []
            });
            bj = undefined; // No business justification for Finance Controller requests.
            requestorAlias = userProfile.alias;
            requestorAadOid = userProfile.id;

            // If the companyCodes list contains the all company code value, then set the array to empty.
            roleScope.companyCodes = stripAllCompanyCodeValue<string>(roleScope.companyCodes);

            setSuccessDialogMsg(submitSuccessMessage.financeControllerSelf);
        } else if (selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther) {
            groupId = accessRequestGroupId.financeControllerRoleGroupId;
            roleScope = new RoleScope({
                // For Finance Controller request, set all values to what was set in the input selector components.
                financeGeographySalesDistrictCodes: fcRequestRoleScope?.financeGeographySalesDistrictCodes || [],
                channelFunctionDetailCodes: fcRequestRoleScope?.channelFunctionDetailCodes || [],
                executiveFunctionDetailCodes: fcRequestRoleScope?.executiveFunctionDetailCodes || [],
                companyCodes: fcRequestRoleScope?.companyCodes || [],
                suppliers: fcRequestRoleScope?.suppliers || []
            });
            bj = undefined; // No business justification for Finance Controller requests.
            requestorAlias = requestOnBehalfOfGraphUser?.alias;
            requestorAadOid = requestOnBehalfOfGraphUser?.id;

            // If the companyCodes list contains the all company code value, then set the array to empty.
            if (roleScope.companyCodes.indexOf(String(appConstants.allCompanyCodeValue)) > -1) {
                roleScope.companyCodes = [];
            }

            setSuccessDialogMsg(submitSuccessMessage.financeControllerOther);
        }

        if (groupId && requestorAlias && requestorAadOid) {
            // Create a new AccessRequest object based on the input fields.
            const accessRequest: AccessRequest = new AccessRequest({
                submitterAlias: userProfile.alias,
                requestorAlias: requestorAlias,
                requestorAadOid: requestorAadOid,
                groupId: groupId,
                businessJustification: bj,
                createdDate: new Date(),
                lastModifiedDate: new Date(),
                scope: roleScope,
                approverAlias: undefined, // This is set in server side code to the nearest FTE manager of the requestorAlias.
                status: AccessRequestStatus.Pending
            });

            dispatch(callApiSubmitAccessRequest(accessRequest));
        }
    };

    /**
     * Memoized helper to determine if inputs are valid.
     */
    const inputsValid: boolean = useMemo<boolean>(() => {
        if (selectedRequestAccessOptionKey === RequestAccessOptionKey.Agent) {
            if (agentBusinessJustification.trim().length === 0 ||
                agentBusinessJustificationValidationError.length > 0) {
                return false;
            }
        }

        if (selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther) {
            if (requestOnBehalfOfGraphUser === undefined) {
                return false;
            }
        }

        if (selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerSelf ||
            selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther) {
            // At least one hierarchy node should be selected.
            if (fcRequestRoleScope.financeGeographySalesDistrictCodes.length === 0 &&
                fcRequestRoleScope.channelFunctionDetailCodes.length === 0 &&
                fcRequestRoleScope.executiveFunctionDetailCodes.length === 0) {
                setSelectHierarchyNotice(true);
                return false;
            } else {
                setSelectHierarchyNotice(false);
            }
        }

        return true;
    }, [agentBusinessJustification, agentBusinessJustificationValidationError.length, fcRequestRoleScope.channelFunctionDetailCodes.length, fcRequestRoleScope.executiveFunctionDetailCodes.length, fcRequestRoleScope.financeGeographySalesDistrictCodes.length, requestOnBehalfOfGraphUser, selectedRequestAccessOptionKey]);

    /**
     * Get role description.
     * @returns Role description.
     */
    const getRoleDescription = (userRole: UserRole): string => {
        switch (userRole.name.toLowerCase()) {
            case Role.Agent.toLocaleLowerCase():
                return 'Can view and edit any PO.';
            case Role.FinanceController.toLocaleLowerCase():
                return 'Can view and edit POs that map to the company hierarchy, company codes, and/or suppliers you selected.';
            case Role.AccrualsAdmin.toLocaleLowerCase():
                return 'Can access the accrual dashboard.';
            case Role.Dri.toLocaleLowerCase():
                return 'Can access DRI functionality.'
            default:
        }
        return '';
    }

    return (
        <>
            <Separator />

            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            {/* "Your Current Role" and "Your Finance Controller configuration" sections. */}
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="mediumPlus" block className={commonStyles.sectionHeading} role="heading" aria-level={1}>Your Current Role</Text>
                    <ul className={commonStyles.sectionContent}>
                        {userProfile!.roles && userProfile!.roles.length > 0 && (
                            <>
                                {userProfile!.roles?.map((userRole: UserRole, index: number) => {
                                    return (
                                        <li key={index}>
                                            {userRole.name} - {getRoleDescription(userRole)}
                                        </li>
                                    );
                                })}
                            </>
                        )}
                        {(!userProfile!.roles || userProfile!.roles.length === 0) && (
                            <li>
                                Standard - Can view any PO where you are the owner or invoice approver.
                            </li>
                        )}
                    </ul>
                </Stack.Item>
                {userProfile?.isFinanceController && currentUserFcRoleScope && (
                    <Stack.Item>
                        <Text variant="mediumPlus" block className={commonStyles.sectionHeading} role="heading" aria-level={2}>Your Finance Controller configuration</Text>
                        <Stack.Item className={commonStyles.sectionContent}>
                            <Stack horizontal tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <div className={pageStyles.companyCodeSelectionContainer}>
                                        <CompanyCodeSelection
                                            showLabel={true}
                                            readonly={true}
                                            disabled={false}
                                            tooltip={tooltips.fcViewCompanyCodeSelections}
                                            selectedCompanyCodes={
                                                currentUserFcRoleScope.companyCodes.length === 0 ?
                                                [appConstants.allCompanyCodeValue] :
                                                currentUserFcRoleScope.companyCodes.map(c => Number(c))
                                            }
                                        />
                                    </div>
                                </Stack.Item>
                                <Stack.Item>
                                    <HierarchySelection
                                        showLabel={true}
                                        readonly={true}
                                        disabled={false}
                                        tooltip={tooltips.fcViewFinanceControllerHierarchySelections}
                                        financeGeographySalesDistrictCodes={currentUserFcRoleScope.financeGeographySalesDistrictCodes}
                                        channelFunctionDetailCodes={currentUserFcRoleScope.channelFunctionDetailCodes}
                                        executiveFunctionDetailCodes={currentUserFcRoleScope.executiveFunctionDetailCodes}
                                        showGeography={true}
                                        showWarningIfBothChannelAndExecSelected={true}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <SupplierSelection
                                        showLabel={true}
                                        readonly={true}
                                        disabled={false}
                                        tooltip={tooltips.fcViewSupplierSelections}
                                        selectedSuppliers={currentUserFcRoleScope.suppliers}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack.Item>
                )}
            </Stack>

            <Separator />

            {/* "Request Access" section. */}
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="mediumPlus" block className={commonStyles.sectionHeading} role="heading" aria-level={2}>Request Access</Text>
                    <Text variant="medium" block className={commonStyles.sectionContent}>There is no permission needed in order to use this site. All users may access purchase orders they are the requestor or an approver for. If you need agent or finance controller access you may make that request here.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="medium" block className={commonStyles.sectionContent}><i>Agent vs delegate access:</i> Agent access grants you permission to all purchase orders. Delegate access grants you access to specific individuals purchase orders. If you need access to specific individuals purchase orders it is recommended you use delegate access. Contact the people you want to get delegate access from and have them go to the User Profile page to set up delegates.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="medium" block className={commonStyles.sectionContent}><i>Finance controller role:</i> Finance controller role is generally recommended for statutory and business controllers, budget owners or managers, and assistant controllers who are monitoring the accruals and have the right level of authority to perform receipting for others. Finance controller grants you permission to access purchase orders for selected company codes, hierarchies, and/or vendors. Access should be narrowed down to your financial scope and requests will be routed to your FTE manager for approval.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="medium" block className={commonStyles.sectionContent}>Request for access to role:</Text>
                    <ChoiceGroup
                        selectedKey={selectedRequestAccessOptionKey}
                        options={getRequestAccessOptions(userProfile?.isFinanceController || false)}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                            if (option?.key) {
                                setSelectedRequestAccessOptionKey(option.key as RequestAccessOptionKey);
                            }
                        }}
                        disabled={isRequestRunning}
                    />
                </Stack.Item>

                {selectedRequestAccessOptionKey === RequestAccessOptionKey.Agent && (
                    <>
                        <Stack.Item>
                            <Label htmlFor={agentBusinessJustificationInputId}>{commonString.businessJustification}</Label>
                            <TooltipHost content={validationConstants.businessJustification.tooltip} delay={TooltipDelay.long}>
                                <TextField
                                    id={agentBusinessJustificationInputId}
                                    autoComplete='off'
                                    ariaLabel={`${commonString.businessJustification} ${validationConstants.businessJustification.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                    className={pageStyles.businessJustificationTextField}
                                    value={agentBusinessJustification}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        newValue = newValue || '';
                                        if (newValue.length > 0 && (newValue.length > validationConstants.businessJustification.maxLength! ||
                                            !RegExp(validationConstants.businessJustification.pattern!).test(newValue))) {
                                                setBusinessJustificationValidationError(validationConstants.businessJustification.errorMsg!);
                                        } else {
                                            setBusinessJustificationValidationError('');
                                        }
                                        setBusinessJustification(newValue);
                                    }}
                                    disabled={isRequestRunning}
                                    errorMessage={agentBusinessJustificationValidationError}
                                />
                            </TooltipHost>
                        </Stack.Item>
                    </>
                )}

                {(selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerSelf ||
                  selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther) && (
                    <>
                        <Stack.Item>
                            <Stack horizontal tokens={stackTokensNormalGap}>
                                {selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther && (
                                    <Stack.Item>
                                        <GraphUserInput
                                            showLabel={true}
                                            label="Requesting For"
                                            disabled={false}
                                            required={true}
                                            tooltip={tooltips.fcRequestingAccessFor}
                                            onChange={(graphUser: GraphUser | undefined) => {
                                                if (graphUser && graphUser.alias && graphUser.id) {
                                                    setRequestOnBehalfOfGraphUser(graphUser);
                                                } else {
                                                    setRequestOnBehalfOfGraphUser(undefined);
                                                }
                                            }}
                                            onInputValidation={(msg: string) => {
                                                // Not doing anything here for input validation.
                                                // The onChange handler will be also called even when validation errors happen.
                                            }}
                                        />
                                    </Stack.Item>
                                )}
                                <Stack.Item>
                                    <div className={pageStyles.companyCodeSelectionContainer}>
                                        <CompanyCodeSelection
                                            showLabel={true}
                                            readonly={false}
                                            disabled={false}
                                            tooltip={tooltips.fcCompanyCodesNeedAccessTo}
                                            selectedCompanyCodes={fcRequestRoleScope.companyCodes.map(c => Number(c)) || []}
                                            onSelectionChanged={(companyCodes: number[]) => {
                                                setFcRequestRoleScope(prev => {
                                                    return {
                                                        ...prev,
                                                        companyCodes: companyCodes.map(c => String(c))
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </Stack.Item>
                                <Stack.Item>
                                    <HierarchySelection
                                        showLabel={true}
                                        readonly={false}
                                        disabled={false}
                                        tooltip={tooltips.fcMakeHierarchySelections}
                                        financeGeographySalesDistrictCodes={fcRequestRoleScope.financeGeographySalesDistrictCodes}
                                        channelFunctionDetailCodes={fcRequestRoleScope.channelFunctionDetailCodes}
                                        executiveFunctionDetailCodes={fcRequestRoleScope.executiveFunctionDetailCodes}
                                        showGeography={true}
                                        showWarningIfBothChannelAndExecSelected={true}
                                        onChanged={(
                                            financeGeographySalesDistrictCodes: string[],
                                            channelFunctionDetailCodes: string[],
                                            executiveFunctionDetailCodes: string[]
                                        ) => {
                                            setFcRequestRoleScope(prev => {
                                                return {
                                                    ...prev,
                                                    financeGeographySalesDistrictCodes: financeGeographySalesDistrictCodes,
                                                    channelFunctionDetailCodes: channelFunctionDetailCodes,
                                                    executiveFunctionDetailCodes: executiveFunctionDetailCodes
                                                }
                                            });
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <SupplierSelection
                                        showLabel={true}
                                        readonly={false}
                                        disabled={false}
                                        tooltip={tooltips.fcSelectSuppliers}
                                        selectedSuppliers={fcRequestRoleScope.suppliers}
                                        onchanged={(suppliers: Supplier[]) => {
                                            setFcRequestRoleScope(prev => {
                                                return {
                                                    ...prev,
                                                    suppliers: suppliers
                                                }
                                            });
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </>
                )}

                {(selectedRequestAccessOptionKey === RequestAccessOptionKey.Agent ||
                  selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerSelf ||
                  selectedRequestAccessOptionKey === RequestAccessOptionKey.FinanceControllerOther) && (
                    <>
                        <Stack.Item>
                            <DefaultButton
                                onClick={submitAccessRequestButtonClicked}
                                disabled={isRequestRunning || !inputsValid}
                            >
                                {isRequestRunning ? (
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerGrid} />
                                ) : (
                                    <Text>Submit</Text>
                                )}
                            </DefaultButton>
                        </Stack.Item>
                        {selectHierarchyNotice && (
                            <Stack.Item>
                                <Text className={pageStyles.selectHierarchyNotice}>Please select at least one hierarchy node.</Text>
                            </Stack.Item>
                        )}
                    </>
                )}
            </Stack>

            <Separator />

            {/* "Finance Controller Request History" section. */}
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="mediumPlus" block className={commonStyles.sectionHeading} role="heading" aria-level={2}>Finance Controller Request History</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="medium" block className={commonStyles.sectionContent}>View your request history for the past 30 days.</Text>
                </Stack.Item>
                { apiLoadFinanceControllerAccessRequestHistory.callApiState === CallApiState.Running && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Stack.Item>
                )}
                { apiLoadFinanceControllerAccessRequestHistory.callApiState === CallApiState.Completed && (
                    <Stack.Item>
                        <table className={pageStyles.requestTable}>
                            <thead className="requestTableHead">
                                <tr>
                                    <th>{commonString.createdDate}</th>
                                    <th>{commonString.requestor}</th>
                                    <th>{commonString.companyCodes}</th>
                                    <th>{commonString.hierarchy}</th>
                                    <th>{commonString.suppliers}</th>
                                    <th>{commonString.status}</th>
                                </tr>
                            </thead>
                            <tbody className="requestTableBody">
                                {
                                    apiLoadFinanceControllerAccessRequestHistory.accessRequests?.map((accessRequest: AccessRequest, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{formatDateUsingLocale(accessRequest.createdDate)}</td>
                                                <td>{accessRequest.requestorAlias}</td>
                                                <td>
                                                    <div className={pageStyles.companyCodeSelectionContainer}>
                                                        <CompanyCodeSelection
                                                            showLabel={false}
                                                            readonly={true}
                                                            disabled={false}
                                                            tooltip={tooltips.fcViewSupplierSelections}
                                                            selectedCompanyCodes={
                                                                // If the companyCodes array was undefined or empty, then default it to allCompanyCodeValue.
                                                                accessRequest.scope?.companyCodes && accessRequest.scope.companyCodes.length > 0 ?
                                                                accessRequest.scope.companyCodes.map(c => Number(c)) : [appConstants.allCompanyCodeValue]
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <HierarchySelection
                                                        showLabel={false}
                                                        readonly={true}
                                                        disabled={false}
                                                        tooltip={tooltips.fcViewFinanceControllerHierarchySelections}
                                                        financeGeographySalesDistrictCodes={accessRequest.scope?.financeGeographySalesDistrictCodes || []}
                                                        channelFunctionDetailCodes={accessRequest.scope?.channelFunctionDetailCodes || []}
                                                        executiveFunctionDetailCodes={accessRequest.scope?.executiveFunctionDetailCodes || []}
                                                        showGeography={true}
                                                        showWarningIfBothChannelAndExecSelected={true}
                                                    />
                                                </td>
                                                <td>
                                                    <SupplierSelection
                                                        showLabel={false}
                                                        readonly={true}
                                                        disabled={false}
                                                        tooltip={tooltips.fcViewSupplierSelections}
                                                        selectedSuppliers={accessRequest.scope?.suppliers || []}
                                                    />
                                                </td>
                                                <td>
                                                    <Text variant="medium">{AccessRequestStatus[accessRequest.status]}</Text>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </Stack.Item>
                )}
            </Stack>

            <GenericDialog
                displayDialog={displaySuccessDialog}
                title="Request submitted"
                content={
                    <Text variant="medium">{successDialogMsg}</Text>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplaySuccessDialog();
                }}
            />
        </>
    );
};
