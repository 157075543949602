import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    section: {
        background: 'white',
        padding: '28px',
        marginBottom: '28px',
        borderRadius: '2px',
        backgroundClip: 'padding-box',
        '-webkit-box-shadow': '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)',
        boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)',
        position: 'relative'
    } as IStyle
});
