import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    expiredWarning: {
        color: '#e50000',
        fontSize: '14px'
    } as IStyle,
    expiredIcon: {
        fontSize: '14px',
        margin: '2px 4px 0px 0px',
        verticalAlign: 'top'
    } as IStyle,
    saveSpinner: {
        margin: '0 0 0 12px'
    } as IStyle,
    invalidInput: {
        lineHeight: '32px',
        color: 'red'
    } as IStyle
});
