/**
 * The AuditEvent enum directly models the server side AuditHistoryEvents.AuditEvent enum.
 * The enum members are equal to their UI displayable audit event name.
 */
export enum AuditEvent {
    AsnReceived = 'Advanced shipment notification received',
    AutoCor = 'Auto confirmation',
    InitiateGR = 'Goods received initiated',
    InitiateSR = 'Services received initiated',
    ConfirmGR = 'Goods received confirmation',
    InitiateCloseLine = 'Close line initiated',
    ConfirmCloseLine = 'Close line confirmation',
    UpdateAssetDetails = 'Update asset details',
    MailRoom = 'Mail room'
}
