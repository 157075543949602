import React, { useState, useCallback, useEffect, useMemo, useReducer } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import {
    Breadcrumb,
    ConstrainMode,
    DetailsListLayoutMode,
    IBreadcrumbItem,
    SelectionMode,
    Separator,
    Stack
} from '@fluentui/react';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { stackTokensNormalGap } from '../../common/common.styles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CallApiState } from '../../store/actions/generic.action';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { headerColumns } from './headerColumns';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { deepCopyObject } from '../../common/common.func.general';
import { callApiSearch, IApiSearch } from '../../store/actions/search.action';
import { ISearchReducer } from '../../store/reducers/search.reducer';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { appConstants } from '../../common/appConstants';
import { AppDispatch } from '../../store/reduxStore';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { teachingBubbleClearArray } from '../../store/actions/app.action';
import { ImportExport } from '../../components/ImportExport/ImportExport';

interface IPageProps extends ICommonPageProps {
}

/**
 * Search results page.
 * @param props Page props.
 * @returns JSX for the page.
 */
 export const SearchResultsPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [breadCrumbItems, setBreadCrumbItems] = useState<IBreadcrumbItem[]>();

    // Redux store selectors to get state from the store when it changes.
    const apiSearch: IApiSearch =
        useAppSelector<IApiSearch>((state) => state.searchReducer.apiSearch);
    const searchReducer: ISearchReducer =
        useAppSelector<ISearchReducer>((state) => state.searchReducer);
    const editPoNumber: string | undefined =
        useAppSelector((state) => state.searchResultsPageReducer.editPoNumber);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const navigate: NavigateFunction = useNavigate();

    const [, forceRender] = useReducer((s) => s + 1, 0);

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiSearch.errMsg) {
            handleError(apiSearch.errMsg);
        }
    }, [apiSearch.errMsg, handleError]);

    /**
     * Make breadcrumbs.
     */
    const makeBreadCrumbs = useCallback(() => {
        const items: IBreadcrumbItem[] = [
            {
                text: 'Home',
                key: 'Home',
                onClick: () => {
                    navigate(`${appConstants.publicUrl}/Home`);
                }
            },
            {
                text: 'Search Results',
                key: 'SearchResults',
                isCurrentItem: true
            }
        ];
        setBreadCrumbItems(items);
    }, [navigate]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        makeBreadCrumbs();

        // If there is no purchaseOrderHeaders then navigate back home.
        // This could happen if the user directly links to this page or refreshes as this page.
        // The searchReducer.purchaseOrderHeaders object may exist but have 0 records, which is fine and
        // indicates there were no results. In that case a no results message will be displayed.
        if (!searchReducer.purchaseOrderHeaders) {
            navigate(`${appConstants.publicUrl}/Home`);
        }
    });

    /**
     * Effect for when user clicks a PO number for editing.
     */
    useEffect(() => {
        if (editPoNumber) {
            // Navigate to the edit page for the PO number.
            navigate(`${appConstants.publicUrl}/Edit/${editPoNumber}`);
        }
    }, [editPoNumber, navigate]);

    /**
     * Adjusts the height of the search results element. Useful in some cases, like paging (page next/previous),
     * where keeping the height of the search results the same while retrieving the next page of data.
     * This creates a less jarring UI where the height of the search results becomes small when only showing the
     * "Loading..." indicator, then becomes larger when the results appear. After search completes we can set
     * the height back to auto.
     * @param opt Option to maintain height or set to auto.
     */
    const adjustSearchResultsHeight = (opt: 'maintain' | 'auto') => {
        const searchResultsElem: HTMLElement | null = document.getElementById('searchResults');
        if (searchResultsElem) {
            switch (opt) {
                case 'maintain':
                    searchResultsElem.style.height = `${searchResultsElem.offsetHeight}px`;
                    break;
                default:
                case 'auto':
                    searchResultsElem.style.height = 'auto';
                    break;
            }
        }
    };

    /**
     * Effect for when apiSearch returns data.
     */
    useEffect(() => {
        if (apiSearch.callApiState === CallApiState.DataAvailable) {
            // Adjust height back to auto. The height was maintained to previous height when search started.
            adjustSearchResultsHeight('auto');
        }
    }, [apiSearch.callApiState]);

    /**
     * Performs a search.
     * @param newPurchaseOrderSearch New purchase order search.
     */
    const search = useCallback((newPurchaseOrderSearch: PurchaseOrderSearch) => {
        adjustSearchResultsHeight('maintain');
        dispatch(callApiSearch(newPurchaseOrderSearch));
    }, [dispatch]);

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (apiSearch.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSearch.callApiState]);

    /**
     * Memoized field to check if PO header data is present. 
     */
    const headersPresent = useMemo<boolean>(() => {
        if (searchReducer.purchaseOrderHeaders === undefined ||
            searchReducer.purchaseOrderHeaders?.length === 0) {
            return false;
        } else {
            return true;
        }
    }, [searchReducer.purchaseOrderHeaders]);

    return (
        <>
            <PageWrapper 
                {...props}
                onScroll={
                    // Force render is needed to reposition any teaching bubbles that may be present.
                    // They will not reposition themselves during scrolling to the elements they are anchored to.
                    ()=> forceRender()
                }
            >
                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <Stack tokens={stackTokensNormalGap}>
                    {breadCrumbItems && (
                        <Breadcrumb
                            items={breadCrumbItems}
                            ariaLabel="Site navigation"
                        />
                    )}
                    
                    <SectionWrapper>
                        <Section>
                            <CustomDetailsList
                                id="searchResults"
                                ariaLabelForGrid="Purchase Orders"
                                displayTotalItems={true}
                                displayTotalItemsText="Total Purchase Orders"
                                showPaginator={true}
                                showPageSize={false}
                                selectedPage={(searchReducer.purchaseOrderSearch?.metaData.currentPageIndex || 0) + 1}
                                onSelectedPageChange={(page) => {
                                    if (searchReducer.purchaseOrderSearch) {
                                        // Adjust the page to retrieve in the search metadata and rerun the search.
                                        // Using deepCopyObject here because Redux store data should not be modified directly.
                                        const newPurchaseOrderSearch: PurchaseOrderSearch = deepCopyObject(searchReducer.purchaseOrderSearch) as PurchaseOrderSearch;
                                        newPurchaseOrderSearch.metaData.currentPageIndex = page - 1; // -1 because page is 1 based, while currentPageIndex is 0 based.
                                        search(newPurchaseOrderSearch);
                                    }
                                }}
                                showNoDataFoundMsg={!headersPresent}
                                items={searchReducer.purchaseOrderHeaders || []}
                                totalItemsAtServer={searchReducer.purchaseOrderSearch?.metaData.totalRecords}
                                isLoading={isSearchRunning}
                                compact={false}
                                columns={headerColumns}
                                selectionMode={SelectionMode.none}
                                getKey={(item: PurchaseOrderHeader) => item.clientRowKey}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                isHeaderVisible={true}
                                constrainMode={ConstrainMode.horizontalConstrained}
                                useScrollablePane={true}
                            />

                            <Separator />

                            <ImportExport
                                message="You can export the search results to an Excel file for bulk editing. You can make bulk updates for goods and services line items and import the Excel file."
                                handleError={(error: string) => {
                                    handleError(error);
                                }}
                                purchaseOrderSearch={() => {
                                    // Make a copy of the purchase order search object. Cannot modify Redux state.
                                    const purchaseOrderSearchCopy: PurchaseOrderSearch = deepCopyObject(searchReducer.purchaseOrderSearch);
                                    return purchaseOrderSearchCopy;
                                }}
                                disabled={isSearchRunning || !headersPresent}
                                showSearchCriteriaWarning={true}
                            />
                        </Section>
                    </SectionWrapper>
                </Stack>
            </PageWrapper>
        </>
    );
};
