import { IStyle, mergeStyleSets } from '@fluentui/react';

export const defaultCopilotWidth: number = 435;
export const expandedCopilotWidth: number = 835;

export const appStyles = mergeStyleSets({
    windowContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
    } as IStyle,
    pageContainer: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    } as IStyle,
    mainContainer: {
        flex: '1 1 0%',
        overflow: 'auto'
    } as IStyle,
    mainContent: {
        height: '100%',
        backgroundColor: '#faf9f8'
    } as IStyle,
    copilotContainer_CopilotOpenDefault: {
        position: 'fixed',
        top: 48,
        bottom: 0,
        right: 0,
        left: `calc(100vw - ${defaultCopilotWidth}px)`,
        maxWidth: `${defaultCopilotWidth}px`,
        marginTop: '0'
    } as IStyle,
    copilotContainer_CopilotOpenExpanded: {
        position: 'fixed',
        top: 48,
        bottom: 0,
        right: 0,
        left: `calc(100vw - ${expandedCopilotWidth}px)`,
        maxWidth: `${expandedCopilotWidth}px`,
        marginTop: '0'
    } as IStyle,
    copilotContainerCollapsed: {
        // No style needed. Set to this when collapsed.
    }
});
