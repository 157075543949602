import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    FontIcon,
    Link,
    Panel,
    PanelType,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { hideShipmentInfoForLineItem } from '../../store/actions/pageActions/editPage.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { IApiShipmentStatusTrail, callApiShipmentStatusTrail } from '../../store/actions/miscActions/shipmentStatusTrail.action';
import { CallApiState } from '../../store/actions/generic.action';
import { ErrorBar, clearErrorByIndex } from '../ErrorBar/ErrorBar';
import { formatDateTimeUsingFormat } from '../../common/common.func.datetime';
import { componentStyles } from './LineItemShipmentInfoPanel.styles';
import { SupplierShipmentDetail } from '../../models/shipment/supplierShipmentDetail';
import { AssetInformation } from '../../models/shipment/assetInformation';
import { ShipmentTrail } from '../../models/shipment/shipmentTrail';

interface ILineItemShipmentInfoPanelProps {
}

/**
 * Line item shipment status trail panel.
 * This panel is used on the edit and close line pages. It is also used on the prior year accruals page.
 * @param props Component props.
 * @returns JSX for component.
 */
export const LineItemShipmentInfoPanel: React.FunctionComponent<ILineItemShipmentInfoPanelProps> = (props: ILineItemShipmentInfoPanelProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    // Redux store selectors to get state from the store when it changes.
    const showShipmentInfoForLineItem: PurchaseOrderLineItem | undefined =
        useAppSelector<PurchaseOrderLineItem | undefined>((state) => state.editPageReducer.showShipmentInfoForLineItem);
    const apiShipmentStatusTrail: IApiShipmentStatusTrail =
        useAppSelector<IApiShipmentStatusTrail>((state) => state.shipmentStatusTrailReducer.apiShipmentStatusTrail);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Memoized field that gets the PO number from the showShipmentInfoForLineItem.
     */
    const poNumber: string | undefined = useMemo<string | undefined>(() => {
        if (showShipmentInfoForLineItem) {
            return showShipmentInfoForLineItem.purchaseOrderNumber;
        }

        return undefined;
    }, [showShipmentInfoForLineItem]);

    /**
     * Memoized field that gets the line number from either the showShipmentInfoForLineItem.
     */
    const lineNumber: string | undefined = useMemo<string | undefined>(() => {
        if (showShipmentInfoForLineItem) {
            return showShipmentInfoForLineItem.purchaseOrderLineNumber;
        }

        return undefined;
    }, [showShipmentInfoForLineItem]);

    /**
     * Memoized field that determines if the panel should be open or not.
     */
    const isPanelOpen: boolean = useMemo<boolean>(() => {
        if (poNumber && lineNumber) {
            return true;
        } else {
            return false;
        }
    }, [lineNumber, poNumber]);

    /**
     * Memoized field to indicate if the shipment status trail data is loading.
     */
    const isLoading = useMemo<boolean>(() => {
        return apiShipmentStatusTrail.callApiState === CallApiState.Running;
    }, [apiShipmentStatusTrail.callApiState]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiShipmentStatusTrail.errMsg) {
            handleError(apiShipmentStatusTrail.errMsg);
        }
    }, [apiShipmentStatusTrail.errMsg, handleError]);

    /**
     * Effect that will run on component load.
     * Will load the shipment status trail for the selected PO and line.
     */
    useEffect(() => {
        if (poNumber && lineNumber) {
            dispatch(callApiShipmentStatusTrail(poNumber, lineNumber));
        }
    }, [dispatch, lineNumber, poNumber]);

    /**
     * Scroll to the bottom of the panel.
     */
    const scrollToBottom = () => {
        document.getElementById('auditPanelBottom')?.scrollIntoView();
    };

    return (
        <Panel
            onRenderHeader={() => {
                return (
                    <div className={componentStyles.headerWrapper}>
                        <div>
                            <div className={componentStyles.headerText}>
                                Shipment information for {poNumber}-{lineNumber}
                            </div>
                            <div
                                className={componentStyles.scrollToBottom}
                                tabIndex={0}
                                onKeyDown={(e) => e.code === 'Enter' || e.code === 'Space' ? scrollToBottom() : null}
                                onClick={() => {
                                    scrollToBottom();
                                }
                            }>Scroll to bottom</div>
                        </div>
                        <Separator />
                        <div>
                            This panel displays data provided by the shipping supplier when available.
                        </div>
                        <Separator />
                    </div>
                );
            }}
            isOpen={isPanelOpen}
            onDismiss={() => {
                setTimeout(() => {
                    if (showShipmentInfoForLineItem) {
                        dispatch(hideShipmentInfoForLineItem());
                    }
                });
            }}
            closeButtonAriaLabel="Close"
            isLightDismiss={true}
            type={PanelType.medium}
        >
            <Stack>
                {errors.length > 0 && (
                    <Stack.Item>
                        <ErrorBar errors={errors} onDismiss={(index: number) => {
                            setErrors(clearErrorByIndex(errors, index));
                        }} />
                    </Stack.Item>
                )}
                {isLoading && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Stack.Item>
                )}
                {!isLoading &&
                    (!apiShipmentStatusTrail.supplierShipmentDetails ||
                    (apiShipmentStatusTrail.supplierShipmentDetails && apiShipmentStatusTrail.supplierShipmentDetails.length === 0)) && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>No shipment data found</Text>
                    </Stack.Item>
                )}
                {!isLoading && apiShipmentStatusTrail.supplierShipmentDetails && apiShipmentStatusTrail.supplierShipmentDetails.length > 0 && (
                    <>
                        {apiShipmentStatusTrail.supplierShipmentDetails?.map((ssd: SupplierShipmentDetail, index: number) => {
                            return (
                                <Stack.Item key={index}>
                                    <table className={componentStyles.table}>
                                        <tbody>
                                            <tr>
                                                <td>Carrier</td>
                                                <td>{ssd.carrierName}</td>
                                            </tr>
                                            <tr>
                                                <td>Tracking Number</td>
                                                <td>
                                                    {!ssd.carrierTrackingURL && ssd.carrierTrackingNumber && (
                                                        <>{ssd.carrierTrackingNumber}</>
                                                    )}
                                                    {ssd.carrierTrackingURL && ssd.carrierTrackingNumber && (
                                                        <Link href={ssd.carrierTrackingURL} target="_blank">
                                                            {ssd.carrierTrackingNumber}
                                                            <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                                                        </Link>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Estimated Shipment Date</td>
                                                <td>{formatDateTimeUsingFormat(ssd.estimatedShipmentDate, 'MMM D, YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td>Estimated Delivery Date</td>
                                                <td>{formatDateTimeUsingFormat(ssd.estimatedDeliveryDate, 'MMM D, YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td>Actual Shipment Date</td>
                                                <td>{formatDateTimeUsingFormat(ssd.actualShipmentDate, 'MMM D, YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td>Actual Delivery Date</td>
                                                <td>{formatDateTimeUsingFormat(ssd.actualDeliveryDate, 'MMM D, YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td>Ship To Contact Name</td>
                                                <td>{ssd.shipToContactName}</td>
                                            </tr>
                                            <tr>
                                                <td>Quantity</td>
                                                <td>{ssd.quantity}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className={componentStyles.subHeading}>
                                                    <Separator />
                                                    Asset Information
                                                </td>
                                            </tr>
                                            {
                                                ssd.assetDetails?.map((assetInformation: AssetInformation, index: number) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <Separator />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Asset Tag</td>
                                                                <td>{assetInformation.assetTag}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Serial Number</td>
                                                                <td>{assetInformation.serialNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Recipient</td>
                                                                <td>{assetInformation.itemRecipient}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                            <tr>
                                                <td colSpan={2} className={componentStyles.subHeading}>
                                                    <Separator />
                                                    Shipment Trail
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <Separator />
                                                </td>
                                            </tr>
                                            {
                                                ssd.shipmentTrail?.sort((a, b) => (a.statusDateTime || 0) < (b.statusDateTime || 0) ? -1 : 1).map((shipmentTrail: ShipmentTrail, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className={componentStyles.statusDate}>{formatDateTimeUsingFormat(shipmentTrail.statusDateTime, 'MMM D, YYYY')}</td>
                                                            <td>{shipmentTrail.status}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            <tr>
                                                <td colSpan={2}>
                                                    <Separator />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Stack.Item>
                            );
                        })}
                    </>
                )}
            </Stack>
            <div id="shipmentInfoPanelBottom"></div>
        </Panel>
    );
};
