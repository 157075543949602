import React, { useEffect, useRef } from 'react';
import { DirectionalHint, TeachingBubble } from '@fluentui/react';
import { useOnScreen } from '../../common/common.hooks';
import { teachingBubbleGotIt, teachingBubbleShow } from '../../store/actions/app.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';

/**
 * Flags for internal use of this teachng bubble implementation.
 */
enum TeachingBubbleFlag {
    Enabled = 'Enabled'
}

/**
 * Specific teaching bubbles used across the app.
 */
export enum TeachingBubbleItem {
    PoViewEdit = 'PoViewEdit',
    AmountToAccrue = 'AmountToAccrue',
    ReceiptDate = 'ReceiptDate',
    /**
     * Same as ReceiptDate but with special handling for July 1st of each year.
     * The year will be appended to this so every year the user will get the teaching tip even if dismissed from a prior year.
     */
    ReceiptDateJulyFirst = 'ReceiptDateJulyFirst',
    VideoGuide = 'VideoGuide',
    MiniSearchResults = 'MiniSearchResults',
    FinanceAssistant = 'FinanceAssistant' 
}

const teachingBubblePrefix: string = 'tb_';
const teachingBubbleViewedValue: string = 'viewed';

export const checkIfTeachingBubblesEnabled = (): boolean => {
    // If local storage for TeachingBubbleItem.Enabled is null or 'true' then it is enabled.
    const isEnabled: string | null = localStorage.getItem(`${teachingBubblePrefix}${TeachingBubbleFlag.Enabled}`);
    if (isEnabled === null || isEnabled === 'true') {
        return true;
    }
    return false;
};

export const enableTeachingBubbles = (isEnabled: boolean): void => {
    localStorage.setItem(`${teachingBubblePrefix}${TeachingBubbleFlag.Enabled}`, String(isEnabled));
};

const getStorageKey = (teachingBubbleItem: TeachingBubbleItem): string => {
    let key: string = teachingBubbleItem;
    if (teachingBubbleItem === TeachingBubbleItem.ReceiptDateJulyFirst) {
        // For the ReceiptDateJulyFirst, append the year to the key so that it is shown once each year.
        key = key + new Date().getFullYear;
    }
    return key;
};

export const teachingBubbleCheckShow = (teachingBubbleItem: TeachingBubbleItem): boolean => {
    if (!checkIfTeachingBubblesEnabled()) {
        return false;
    }

    const key: string = getStorageKey(teachingBubbleItem);
    const valueLocal: string | null = localStorage.getItem(`${teachingBubblePrefix}${key}`);
    if (valueLocal === teachingBubbleViewedValue) {
        return false;
    }

    return true;
};

export const teachingBubbleViewed = (teachingBubbleItem: TeachingBubbleItem): void => {
    const key: string = getStorageKey(teachingBubbleItem);
    localStorage.setItem(`${teachingBubblePrefix}${key}`, teachingBubbleViewedValue)
};

export const resetTeachingBubbles = (): void => {
    for (const teachingBubbleItem in TeachingBubbleItem) {
        const key: string = getStorageKey(teachingBubbleItem as TeachingBubbleItem);
        localStorage.removeItem(`${teachingBubblePrefix}${key}`);
    }
};

export interface CustomTeachingBubbleProps {
    /**
     * Target element id.
     */
    targetElemId: string;

    /**
     * Teaching bubble item.
     */
    teachingBubbleItem: TeachingBubbleItem;

    /**
     * Teaching bubble headline.
     */
    teachingBubbleHeadline: string;

    /**
     * Teaching bubble content.
     */
    teachingBubbleContent: string;

    /**
     * By default there is a check to see if the target element is on the screen with at least 100 pixels margin.
     * If the target element is at the extreme edge of the page then this should be disabled. Defaults to false.
     */
    disableIsOnScreenCheck?: boolean;

    /**
     * Amount of gap space between the target element and the bubble. Defaults to 0.
     */
    gapSpace?: number;

    /**
     * Callback to be called after Got It was clicked.
     */
    onGotItClicked?: () => void;

    /**
     * Callback to be called after Disable Teaching Tips was clicked.
     */
    onDisableClicked?: () => void;
}

/**
 * Custom teaching bubble.
 * @param props Custom teaching bubble props.
 * @returns JSX for the component.
 */
export const CustomTeachingBubble: React.FunctionComponent<CustomTeachingBubbleProps> = (props: CustomTeachingBubbleProps): JSX.Element => {
    const onScreenElemRef = useRef<HTMLDivElement | null>(null);
    const isOnScreen = useOnScreen(onScreenElemRef, '-100px');

    // Redux store selectors to get state from the store when it changes.
    const teachingBubbleItemShown =
        useAppSelector((state) => state.appReducer.teachingBubbleItemShown);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect for when teaching bubble is intended to be shown based on props.teachingBubbleItem.
     * In Redux state there is an array of teaching bubbles to be shown if multiple were shown at the same time.
     */
    useEffect(() => {
        if (props.teachingBubbleItem) {
            dispatch(teachingBubbleShow(props.teachingBubbleItem));
        }
    }, [dispatch, props.teachingBubbleItem]);

    return (
        <div ref={onScreenElemRef}>
            {props.teachingBubbleItem === teachingBubbleItemShown && (props.disableIsOnScreenCheck || isOnScreen) && (
                <TeachingBubble
                    target={`#${props.targetElemId}`}
                    secondaryButtonProps={{
                        children: 'Disable teaching tips',
                        onClick: () => {
                            if (props.teachingBubbleItem) {
                                dispatch(teachingBubbleGotIt(props.teachingBubbleItem));
                                enableTeachingBubbles(false);
                            }
                            if (props.onDisableClicked) {
                                props.onDisableClicked();
                            }
                        }
                    }}
                    primaryButtonProps={{
                        children: 'Got it',
                        onClick: () => {
                            if (props.teachingBubbleItem) {
                                dispatch(teachingBubbleGotIt(props.teachingBubbleItem));
                                teachingBubbleViewed(props.teachingBubbleItem);
                            }
                            if (props.onGotItClicked) {
                                props.onGotItClicked();
                            }
                        }
                    }}
                    headline={props.teachingBubbleHeadline}
                    calloutProps={{
                        directionalHint: DirectionalHint.topCenter,
                        gapSpace: props.gapSpace || 0,
                        minPagePadding: 30
                    }}
                    focusTrapZoneProps={{
                        isClickableOutsideFocusTrap: true,
                        forceFocusInsideTrap: false
                    }}
                >
                    {props.teachingBubbleContent}
                </TeachingBubble>
            )}
        </div>
    );
};
