import React from 'react';
import { ICommonPageProps } from '../../common/common.types';
import {
    Separator,
    Stack,
    Text
} from '@fluentui/react';
import { stackTokensNormalGap } from '../../common/common.styles';
import { GenericDialog, GenericDialogMode } from '../GenericDialog/GenericDialog';

interface IExportDialogProps extends ICommonPageProps {
    display: boolean;
    onIncludeReceivedYesClicked: () => void;
    onIncludeReceivedNoClicked: () => void;
    onCancelClicked: () => void;
    showSearchCriteriaWarning: boolean;
}

/**
 * Export dialog.
 * @param props Export dialog props.
 * @returns JSX for the component.
 */
export const ExportDialog: React.FunctionComponent<IExportDialogProps> = (props: IExportDialogProps): JSX.Element => {
    return (
        <GenericDialog
            displayDialog={props.display}
            title='Export search results'
            content={
                <Stack tokens={stackTokensNormalGap}>
                    <Stack.Item>
                        {props.showSearchCriteriaWarning && (
                            <>
                                <Text variant="medium">
                                    Please refine your search criteria so that you export only what you need. Keep the PO count to a
                                    minimum. The export may fail due to a timeout if the export runs for more than 2 minutes.
                                </Text>
                                <Separator/>
                            </>
                        )}
                        <Text variant="medium">
                            <b>Do you want to include fully received lines in the exported Excel file?</b><br/>
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="medium">
                            If you choose &quot;Yes&quot; then the export file will contain all line items, including ones that
                            are already fully received.
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="medium">
                            If you choose &quot;No&quot; then the export file will contain only lines that have not been fully
                            received.
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="medium">
                            It may take a few seconds to couple of minutes, based on number of purchase order lines.
                            Closed line items will not be exported.
                            When importing, please keep the line items marked for update to less than 200 lines (you can
                            mark individual lines for update in the Excel file), otherwise the import may fail.
                        </Text>
                    </Stack.Item>
                </Stack>
            }
            mode={GenericDialogMode.YesNoCancel}
            onYesClicked={props.onIncludeReceivedYesClicked}
            onNoClicked={props.onIncludeReceivedNoClicked}
            onCancelClicked={props.onCancelClicked}
            width={600}
        />
    );
};
