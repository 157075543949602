/**
 * Represents a audit history event.
 * Directly models the server side object. If that one changes, then this one must change.
 */
export interface IAuditHistoryEvent {
    date: Date;
    event: string;
    additionalInformation: string[];
    driInformation: string[];
}

export class AuditHistoryEvent implements IAuditHistoryEvent {
    public date: Date;
    public event: string;
    public additionalInformation: string[] = [];
    public driInformation: string[] = [];

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';
    public parsedEvent: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IAuditHistoryEvent) {
        this.date = new Date(jsonData.date);
        this.event = jsonData.event;

        if (jsonData.additionalInformation) {
            for (let i: number = 0; i < jsonData.additionalInformation.length; i++) {
                this.additionalInformation.push(jsonData.additionalInformation[i]);
            }
            for (let i: number = 0; i < jsonData.driInformation.length; i++) {
                this.driInformation.push(jsonData.driInformation[i]);
            }
        }
    }
}
