import React from 'react';
import { ActionButton, Stack, TooltipDelay, TooltipHost } from '@fluentui/react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { showShipmentInfoForLineItem } from '../../store/actions/pageActions/editPage.action';
import { AppDispatch } from '../../store/reduxStore';
import { componentStyles } from './ShipmentInfoActionButton.styles';
import { tooltips } from '../../common/tooltips';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IShipmentInfoActionButtonProps {
    item: PurchaseOrderLineItem;
}

export const ShipmentInfoActionButton: React.FunctionComponent<IShipmentInfoActionButtonProps> = (props: IShipmentInfoActionButtonProps): JSX.Element => {
    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Stack>
            <TooltipHost content={tooltips.viewLineShipmentInfo} delay={TooltipDelay.long}>
                <ActionButton
                    id={`shipmentInfoButton-${props.item.purchaseOrderNumber}-${props.item.purchaseOrderLineNumber}`}
                    ariaLabel={`${props.item.purchaseOrderNumber}-${props.item.purchaseOrderLineNumber} ${tooltips.viewLineShipmentInfo}`} // Make the screen reader speak the line number plus tooltip content.
                    className={componentStyles.actionButtonShipmentInfo}
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.shipmentInfoButtonClicked }, {
                            po: props.item.purchaseOrderNumber, line: props.item.purchaseOrderLineNumber
                        });
                        dispatch(showShipmentInfoForLineItem(props.item));
                    }}
                    iconProps={{ iconName: 'DeliveryTruck' }}
                />
            </TooltipHost>
        </Stack>
    );
};
