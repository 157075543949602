import {
    ICoherencePageSizeStyleProps,
    ICoherencePageSizeStyles,
    ICoherencePageSizeSubComponentsStyles,
    ICoherencePaginationComponentsStyles,
    ICoherencePaginationStyleProps,
    ICoherencePaginationStyles
} from '@coherence-design-system/controls';
import {
    IStackStyles,
    IStyle,
    IStyleFunctionOrObject,
    mergeStyleSets
} from '@fluentui/react';

export const detailsListScrollableContainerHeight: string = 'calc(100vh - 200px)'; // 100 view height minus 200 px to account for pagination and action buttons on the bottom of the page.

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    footerContainer: {
        height: 'auto'
    } as IStyle,
    pageSizeContainer: {
        whiteSpace: 'nowrap', // Using nowrap will force the 'items per page' text inside the CoherencePageSize to always appear on one line and not wrap when the screen size is small.
        marginTop: '10px'
    } as IStyle,
    footerTextContainer: {
        whiteSpace: 'nowrap',
        padding: '0 30px 0 0',
        lineHeight: '44px'
    } as IStyle,
    footerText: {
        verticalAlign: 'sub'
    } as IStyle,
    excelExportImportButton: {
        marginTop: '0px',
        verticalAlign: 'middle'
    } as IStyle,
    excelExportImportContainer: {
        padding: '0 20px 0 0',
        lineHeight: '48px'
    } as IStyle,
    excelIcon: {
        fontSize: 16
    } as IStyle,
    exportButtonText: {
        margin: '0 0 0 10px'
    } as IStyle,
    detailsList: {
        width: 'fit-content'
    } as IStyle,
    detailsListScrollableContainer: {
        height: detailsListScrollableContainerHeight, // See code in CustomDetailsList.util.tsx where this can get adjusted when results returned from api calls.
        overflow: 'auto',
        position: 'relative',
        // The below media selector is to fix an issue with accessibility. If the screen is zoomed in close, like 200%,
        // then the sticky header can begin to obscure the line items in the grid. This is due to the view height calc in
        // the detailsListScrollableContainerHeight. By default we want that calc so the grid height is adjusted for the
        // view height. This will override that height and force it to larger height when zoomed in close.
        '@media screen and (max-height: 400px)': {
            height: '400px!important'
        } as IStyle
    } as IStyle
});

/**
 * Styles used with coherence pagination control.
 * @param higePageIndicator Hides the page indicator (ex: 1 / 2).
 */
export const getCoherencePaginationStyles = (hidePageIndicator: boolean): IStyleFunctionOrObject<ICoherencePaginationStyleProps, ICoherencePaginationStyles> => {
    return {
        root: {
            gridTemplateColumns: 'auto' // Override default: '1fr minmax(375px, 1fr) 1fr' (which makes it appear centered)
        } as IStyle,
        paginationContainer: hidePageIndicator ? {
            // The page indicator is under the first div in a span.
            'div>span': {
                display: 'none'
            } as IStyle,
            padding: '0 12px 0 0' // Default is 0 12px.
        } as IStyle : {
            padding: '0 12px 0 0' // Default is 0 12px.
        } as IStyle,
        subComponentStyles: {
            container: {
                root: {
                    height: '100%'
                } as IStyle
            }
        } as ICoherencePaginationComponentsStyles
    }
};

/**
 * Styles used with coherence page size control.
 */
export const coherencePageSizeStyles: IStyleFunctionOrObject<ICoherencePageSizeStyleProps, ICoherencePageSizeStyles> = {
    subComponentStyles: {
        container: {
            root: {
                height: '100%'
            } as IStyle
        } as IStyleFunctionOrObject<Record<string, unknown>, IStackStyles>
    } as ICoherencePageSizeSubComponentsStyles
} as IStyleFunctionOrObject<ICoherencePageSizeStyleProps, ICoherencePageSizeStyles>;
