import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    excelIcon: {
        margin: '0 8px 0 0'
    } as IStyle,
    hiddenInput: {
        display: 'none'
    } as IStyle
});
