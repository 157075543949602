export interface IPriorYearAccrualUserSummary {
    totalOpenLineItems: number | undefined;
    totalRetainedLineItems: number | undefined;
}

export class PriorYearAccrualUserSummary implements IPriorYearAccrualUserSummary {
    public totalOpenLineItems: number | undefined;
    public totalRetainedLineItems: number | undefined;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPriorYearAccrualUserSummary) {
        this.totalOpenLineItems = jsonData.totalOpenLineItems;
        this.totalRetainedLineItems = jsonData.totalRetainedLineItems;
    }
}
