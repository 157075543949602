export interface IPriorYearAccrualEmailSentDetail {
    purchaseOrder: number;
    lineItem: number;
    purchaseOrderOwner: string;
    lastEmailSent?: Date;
}

export class PriorYearAccrualEmailSentDetail implements IPriorYearAccrualEmailSentDetail {
    public purchaseOrder: number;
    public lineItem: number;
    public purchaseOrderOwner: string;
    public lastEmailSent?: Date;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPriorYearAccrualEmailSentDetail) {
        this.purchaseOrder = jsonData.purchaseOrder;
        this.lineItem = jsonData.lineItem;
        this.purchaseOrderOwner = jsonData.purchaseOrderOwner;
        this.lastEmailSent = jsonData.lastEmailSent ? new Date(jsonData.lastEmailSent) : undefined;
    }
}
