import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Stack,
    Text
} from '@fluentui/react';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { commonStyles, stackTokensTinyGap } from '../../common/common.styles';
import { DetailsActionButton } from '../../components/DetailsActionButton/DetailsActionButton';
import { LineItemStatusIndicator } from '../../components/LineItemStatusIndicator/LineItemStatusIndicator';
import { LineItemSelectionCheckbox } from '../../components/LineItemSelectionCheckbox/LineItemSelectionCheckbox';
import { ClosedCommentsInput } from './ClosedCommentsInput';
import { ClosedIndicator } from '../../components/ClosedIndicator/ClosedIndicator';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { tooltips } from '../../common/tooltips';
import { AuditActionButton } from '../../components/AuditActionButton/AuditActionButton';
import { ShipmentInfoActionButton } from '../../components/ShipmentInfoActionButton/ShipmentInfoActionButton';

export const closeLineColumns: IColumn[] = [
    {
        key: 'purchaseOrderLineNumber',
        fieldName: 'purchaseOrderLineNumber',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Line'
                    tooltip={tooltips.poLineNumber}
                />
            );
        },
        isResizable: false,
        minWidth: 50,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <>
                    <DetailsActionButton usedOnPage='editOrCloseLineItem' item={item} />
                    <ClosedIndicator item={item} usedOnPage='closeLine' />
                </>
            );
        }
    },
    {
        key: 'info',
        fieldName: undefined,
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Info'
                    tooltip={tooltips.auditHistory}
                />
            );
        },
        isResizable: false,
        minWidth: 56,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Stack horizontal wrap={false}>
                    <AuditActionButton usedOnPage='editOrCloseLineItem' item={item} />
                    {item.isAsset && (
                        // Show this only for goods line items, not services.
                        <ShipmentInfoActionButton item={item} />
                    )}
                </Stack>
            );
        },
        isRowHeader: true
    },
    {
        key: 'lineSelection',
        fieldName: undefined,
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Closed'
                    tooltip={tooltips.closedComments}
                />
            );
        },
        isResizable: false,
        minWidth: 44,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <div className={commonStyles.statusColumnContainer}>
                    <Stack horizontal tokens={stackTokensTinyGap}>
                        <LineItemSelectionCheckbox usedOnPage='closeLine' item={item} />
                        <LineItemStatusIndicator usedOnPage='closeLine' item={item} />
                    </Stack>
                </div>
            );
        }
    },
    {
        key: 'closedComments',
        fieldName: 'closedComments',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Closed'
                    headerLine2='Comments (optional)'
                    tooltip={tooltips.closedComments}
                />
            );
        },
        isResizable: true,
        minWidth: 250,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <ClosedCommentsInput item={item} />
            );
        }
    },
    {
        key: 'lineDescription',
        fieldName: 'lineDescription',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Line'
                    headerLine2='Description'
                    tooltip={tooltips.closedComments}
                />
            );
        },
        isResizable: true,
        minWidth: 250,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {item.description}
                </Text>
            );
        }
    }
];
