export interface IJournalEntry {
    jeId: string;
    jeCreationTimeStamp: Date;
    jeStatus: string;
    jeStatusTimeStamp: Date;
    sapDocument?: string;
    lineNum: string;
    errorDesc?: string;
}

export class JournalEntry implements IJournalEntry {
    public jeId: string;
    public jeCreationTimeStamp: Date;
    public jeStatus: string;
    public jeStatusTimeStamp: Date;
    public sapDocument?: string;
    public lineNum: string;
    public errorDesc?: string;

    // Additional data used in client that does not come from server.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IJournalEntry) {
        this.jeId = jsonData.jeId;
        this.jeCreationTimeStamp = new Date(jsonData.jeCreationTimeStamp);
        this.jeStatus = jsonData.jeStatus;
        this.jeStatusTimeStamp = new Date(jsonData.jeStatusTimeStamp);
        this.sapDocument = jsonData.sapDocument;
        this.lineNum = jsonData.lineNum;
        this.errorDesc = jsonData.errorDesc;
    }
}
