import React from 'react';
import { IColumn } from '@fluentui/react';
import { UserDelegate } from '../../models/user/userDelegate';
import { commonString } from '../../common/commonString';
import { CompanyCodeSelection } from '../../components/CompanyCodeSelection/CompanyCodeSelection';
import { AccessRightSelection } from './AccessRightSelection';
import { DelegateToAliasInput } from './DelegateToAliasInput';
import { DelegateToStartDateInput } from './DelegateToStartDateInput';
import { DelegateToEndDateInput } from './DelegateToEndDateInput';
import { DelegateToDeleteButton } from './DelegateToDeleteButton';
import { reduxStore } from '../../store/reduxStore';
import { editDelegateToCompanyCodes } from '../../store/actions/pageActions/userProfilePage.action';
import { tooltips } from '../../common/tooltips';

export const delegateToColumns: IColumn[] = [
    {
        key: 'alias',
        fieldName: 'alias',
        name: commonString.alias,
        isResizable: true,
        minWidth: 180,
        onRender: (item: UserDelegate) => {
            return (
                <DelegateToAliasInput
                    item={item}
                    inputId={`${item.clientRowKey}_alias`}
                    // The ComboBox input element id will get '-input' appended to it even if we supply the id.
                />
            );
        }
    },
    {
        key: 'accessRights',
        fieldName: 'accessRights',
        name: commonString.accessRights,
        isResizable: true,
        minWidth: 160,
        onRender: (item: UserDelegate) => {
            return (
                <AccessRightSelection
                    userDelegate={item}
                    inputId={`${item.clientRowKey}_accessRights`}
                    // The ComboBox input element id will get '-input' appended to it even if we supply the id.
                    nextInputId={`${item.clientRowKey}_companyCodes-input`}
                    prevInputId={`${item.clientRowKey}_alias-input`}
                />
            );
        }
    },
    {
        key: 'companyCodes',
        fieldName: 'companyCodes',
        name: commonString.companyCodes,
        isResizable: true,
        minWidth: 180,
        onRender: (item: UserDelegate) => {
            return (
                <CompanyCodeSelection
                    showLabel={false}
                    readonly={false}
                    disabled={false}
                    tooltip={tooltips.delegateCompanyCodes}
                    selectedCompanyCodes={item.companyCodes}
                    onSelectionChanged={(companyCodes: number[]) => {
                        reduxStore.dispatch(editDelegateToCompanyCodes(item.clientRowKey!, companyCodes));
                    }}
                    inputId={`${item.clientRowKey}_companyCodes`}
                    // The focusable element id in the DatePicker will have '-label' appended to it.
                    nextInputId={`${item.clientRowKey}_startDate-label`}
                    prevInputId={`${item.clientRowKey}_accessRights-input`}
                />
            );
        }
    },
    {
        key: 'startDate',
        fieldName: 'startDate',
        name: commonString.startDate,
        isResizable: false,
        minWidth: 150,
        onRender: (item: UserDelegate) => {
            return (
                <DelegateToStartDateInput
                    item={item} 
                    inputId={`${item.clientRowKey}_startDate`}
                />
            );
        }
    },
    {
        key: 'endDate',
        fieldName: 'endDate',
        name: commonString.endDate,
        isResizable: false,
        minWidth: 150,
        onRender: (item: UserDelegate) => {
            return (
                <DelegateToEndDateInput item={item} />
            );
        }
    },
    {
        key: 'deleteButton',
        name: '',
        isResizable: false,
        minWidth: 80,
        onRender: (item: UserDelegate) => {
            return (
                <DelegateToDeleteButton item={item} />
            );
        }
    }
];
