import React from 'react';
import {
    IColumn,
    Text,
    IDetailsColumnProps
} from '@fluentui/react';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { ReportFile } from '../../models/report/reportFile';
import { DownloadButton } from './DownloadButton';
import { commonStyles } from '../../common/common.styles';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';

export const fileNameColumn: ICustomColumn = {
    key: 'fileName',
    fieldName: 'fileName',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='File'
                headerLine2='Name'
            />
        );
    },
    isResizable: false,
    minWidth: 140,
    onRender: (item: ReportFile) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.fileName}
            </Text>
        );
    }
};

export const lastModifiedDateUtcColumn: ICustomColumn = {
    key: 'lastModifiedDateUtc',
    fieldName: 'lastModifiedDateUtc',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Last Modified'
                headerLine2='Date (UTC)'
            />
        );
    },
    isResizable: false,
    minWidth: 100,
    onRender: (item: ReportFile) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.lastModifiedDateUtc)}
            </Text>
        );
    }
};

export const reportDateUtcColumn: ICustomColumn = {
    key: 'reportDateUtc',
    fieldName: 'reportDateUtc',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Report'
                headerLine2='Date (UTC)'
            />
        );
    },
    isResizable: false,
    minWidth: 100,
    onRender: (item: ReportFile) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.reportDateUtc)}
            </Text>
        );
    }
};

export const reportDownloadColumn: ICustomColumn = {
    key: 'reportDownload',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Report'
                headerLine2='Download'
            />
        );
    },
    isResizable: false,
    minWidth: 120,
    onRender: (item: ReportFile) => {
        return (
            <DownloadButton reportFile={item} />
        );
    }
};

export const columns: IColumn[] = [
    fileNameColumn,
    lastModifiedDateUtcColumn,
    reportDateUtcColumn,
    reportDownloadColumn
];
