/**
 * Tooltips used on various non-input elements in the app, such as column headers.
 * Note that if the tooltip is to be used on a input field with validation, then it may be better to put the tooltip
 * in the validationConstants.ts as the IFieldValidation has a tooltip.
 */
export const tooltips = {
    fcViewCompanyCodeSelections: 'View your company code selections.',
    fcViewFinanceControllerHierarchySelections: 'View your Finance Controller finance hierarchy selections. This represents the portion of the company that you can view PO data for.',
    fcViewSupplierSelections: 'View your supplier selections.',
    fcRequestingAccessFor: 'Enter the person you are requesting access for.',
    fcCompanyCodesNeedAccessTo: 'Select the company codes you need access to.',
    fcMakeHierarchySelections: 'Make your hierarchy selections. This represents the portion of the company that you can view PO data for. It is recommended that you scope down your access to just the areas you are responsible for. The fewer selections made the faster the system will be for you.',
    fcSelectSuppliers: 'Select suppliers if you want to limit your access to specific suppliers.',
    apViewRequestorsCompanyCodeSelections: 'View the requestors company code selections.',
    apViewRequestorsFinanceControllerSelections: 'View the requestors Finance Controller finance hierarchy selections. This represents the portion of the company that the requestor can view PO data for.',
    apViewRequestorsSupplierSelections: 'View the requestors supplier selections.',
    poLineNumber: 'Purchase order line number.',
    auditHistory: 'Audit history.',
    received: 'Indicates if the line item has been received.',
    assetTag: 'Asset tag for the item if required.',
    serialNumber: 'Serial number for the item if required.',
    recipientName: 'Recipient alias for the item if required. This should be the alias of the person the item is for.',
    lineDescription: 'Description of the line item.',
    receivedPercentage: 'The received percentage is tied to the received amount. If you change either one, the other will change automatically. This represents what percent of the service line item has been received to date.',
    receivedAmount: 'The received amount indicates what portion of the line total has been received to date. The upper bound of the received amount is the line total, while the lower bound is the invoiced amount.',
    accruedAmount: 'This is the amount of the service line item you want to accrue. You can change the received amount or received percentage slider to change this value. The received amount cannot go less than the already invoiced amount and cannot go higher than the line total.',
    invoicedAmount: 'Invoiced amount represents how much of the line item has already been invoiced.',
    invoicedAmountForCurrentFy: 'Represents how much of the line item has already been invoiced in the current fiscal year.',
    accrualReversalForCurrentFy: 'Represents how much accrual reversals and new accruals were done in the current fiscal year.',
    lineTotal: 'Line total is the total value of the purchase order line item.',
    receiptDatePst: 'The receipt date indicates what date the line item accrual should be done. This date is based on PST time and the max date allowed is the current PST date. The fiscal posting period is displayed below the date picker when changed. This indicates what period the accrual will be posted to.',
    receiptDatePstPya: 'Receipt date determines the date on which the service is received (in PST time).',
    receiptDateJulyFirst: 'NOTICE: It is currently July 1st in the PST time zone. The new fiscal year has started. For service line items, if you want to accrue in the prior fiscal year then choose a receipt date of June 30th or prior.',
    deliveryDate: 'Delivery date is the due date for when the goods or services line item is expected to be received. Extend the delivery date in MyOrder tool if needed.',
    poCreateDate: 'Date the purchase order was created.',
    currencyCode: 'Purchase order currency.',
    filterHierarchy: 'Optionally select finance hierarchy nodes to filter the search results.',
    includeReceived: 'Select \'Yes\' to include POs with lines that are fully received 100%. Select \'No\' to exclude POs where all lines are fully received 100%.',
    includeGoodsServices: 'Select \'Both\' to include POs with either Goods or Services lines. Selecting Goods or Services will include POs with at least one line in that category. A PO may have a mix of both Goods and Services lines.',
    pyaAdminHierarchySelection: 'Select the company hierarchy that is to be enabled for prior year accruals.',
    pyaFilterHierarchySelection: 'Select the company hierarchy to filter on.',
    pyaCompanyCodeFilterSelection: 'Select the company codes to filter on.',
    clickToViewPo: 'Click the PO number to view or edit.',
    poTitle: 'Purchase order title.',
    supplierName: 'Supplier name.',
    supplierNameNumber: 'Supplier name and number.',
    poOwner: 'Purchase order owner alias.',
    invoiceApprover: 'Invoice approver alias.',
    poApprover: 'Purchase order approver alias.',
    companyCode: 'Company code.',
    delegateCompanyCodes: 'Select the company codes to delegate.',
    offlineProcessingRunning: 'Processing...',
    assetTagPrePopulatedBySupplier: 'Asset tag was pre-populated by the supplier.',
    serialNumberPrePopulatedBySupplier: 'Serial number was pre-populated by the supplier.',
    recipientPrePopulatedBySupplier: 'Recipient was pre-populated by the supplier.',
    driSearch: 'This allows for searching for otherwise blocked POs such as 8 series.',
    viewLineDetails: 'Click to view details for the line item.',
    viewLineAudit: 'Click to view audit history for the line item.',
    viewLineShipmentInfo: 'Click to view shipment information for the line item.',
    s2sOpenAccrualYtdAmount: 'The open accrual amount to date.',
    execOrg: 'Executive organization hierarchy.',
    orgDetail: 'Organization detail.',
    execOrgSummary: 'Executive organization summary.',
    channelOrgSummary: 'Channel organization summary.',
    channelOrg: 'Channel organization hierarchy.',
    region: 'Region',
    fiscalYear: 'Fiscal year to which the purchase order belongs. Microsoft fiscal year starts on July 1st and ends on June 30th.',
    openAmount: 'Amount of the purchase order line that is available for invoicing.',
    pyaOpenAmount: 'Open accrual for the purchase order line as of June 30th.',
    pyaStatus: 'The response status of the purchase order.',
    pyaRetentionExpirationDate: 'The retention period expires after three months. After the expiration date is passed, the status will be changed back to Open and reminder emails will resume.',
    corpApproval: 'Indicates if line items retained or released after December have received Corp Approval.',
    stcApproval: 'Indicates if line items retained or released (in the absence of a PO owner or delegate) received Statutory Tax Controller approval.',
    poePodAvailability: 'Assistant Controllers must ensure a Proof of Execution/Delivery is obtained from PO owners for any prior year retention that extends beyond December of the active fiscal year.',
    invoiceExpectedDate: 'Date when the invoice is expected.',
    corpComments: 'Comments from the Corporate Reviewer for prior year accrual line items that are approved or rejected.',
    stcComments: 'Comments from the Statutory Tax Controller Reviewer for prior year accrual line items that are approved or rejected.',
    grReversalComboBox: 'Choose whether to reverse goods receipt via purchase order line (PO number, line item, and reversal quantity) or via material document number.',
    pageLink: 'Allows you to copy a link to this page with selected search filters.',
    pyaRefreshLines: 'Queues selected PO line items to refresh the current accrual status.',
    costCategorySubclass: 'Cost category subclass.',
    costCategoryDedicatedGl: 'Corp GL as per guidelines.',
    staticGlAccount: 'Prior year accruals cost element.',
    staticIo: 'Prior year accruals internal order.',
    staticProfitCenter: 'Prior year accruals profit center.',
    staticGLSubclass: 'Static GL subclass.',
    staticCostCenter: 'Static cost center',
    closedComments: 'Comment that can be optionally entered for closed line items.',
    expandMiniSearchResults: 'Expand to choose another PO from search results.',
    collapseMiniSearchResults: 'Collapse search results.',
    salesLocation: 'Sales location.',
    areaSummary: 'Area summary.',
    costCenter: 'Cost center.',
    io: 'Internal order.',
    profitCenter: 'Profit center.',
    glAccount: 'General ledger account.',
    dedicatedPnlAccountCode: 'Dedicated P&L account code.',
    receivedAmountAdjustButton: 'Click to adjust the received amount by increasing or decreasing.',
    sapLookupComboBox: 'Choose whether to perform SAP lookup for purchase order header, purchase order lines, invoices or invoice details.'

};
