export interface IPurchaseOrderSearchMetaData {
    totalRecords: number;
    recordsPerPage: number;
    currentPageIndex: number;
}

export class PurchaseOrderSearchMetaData implements IPurchaseOrderSearchMetaData {
    public totalRecords: number;
    public recordsPerPage: number;
    public currentPageIndex: number;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderSearchMetaData) {
        this.totalRecords = jsonData.totalRecords;
        this.recordsPerPage = jsonData.recordsPerPage;
        this.currentPageIndex = jsonData.currentPageIndex;
    }
}
