import React, { useEffect, useMemo } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IApiGetPriorYearAccrualEmailDetails, callApiGetPriorYearAccrualEmailDetails } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { AppDispatch } from '../../store/reduxStore';
import { PriorYearAccrualEmailSentDetail } from '../../models/priorYearAccrual/priorYearAccrualEmailSentDetail';
import { removeDuplicatesFromArray } from '../../common/common.func.transform';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IComponentProps {
    showDialog: boolean;
    priorYearAccrualLineItems: PriorYearAccrualLineItem[];
    onSendClicked: (purchaseOrderOwners: string[] | undefined) => void;
    onCancelClicked: () => void;
    handleError: (error: string) => void;
}

export const SendReminderEmailDialog: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const apiGetPriorYearAccrualEmailDetails: IApiGetPriorYearAccrualEmailDetails =
        useAppSelector<IApiGetPriorYearAccrualEmailDetails>((state) => state.priorYearAccrualPageReducer.apiGetPriorYearAccrualEmailDetails);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        // Pass the error to the parent component.
        if (apiGetPriorYearAccrualEmailDetails.errMsg) {
            props.handleError(apiGetPriorYearAccrualEmailDetails.errMsg);
        }
        // Disabling exhaustive deps because we don't want to run this effect when the props.handleError function changes.
        // See: https://stackoverflow.com/questions/62807829/how-to-correctly-implement-a-props-callback-function-in-the-useeffect-hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiGetPriorYearAccrualEmailDetails.errMsg]);

    /**
     * Effect for when dialog is shown.
     */
    useEffect(() => {
        if (props.showDialog) {
            // Call API to get prior year accrual email details for the selected line items.
            // Pass the unique PO numbers for the selected lines.
            let purchaseOrderNumbers: number[] = props.priorYearAccrualLineItems.map(item => {
                return Number(item.purchaseOrder);
            });
            purchaseOrderNumbers = removeDuplicatesFromArray(purchaseOrderNumbers);
            dispatch(callApiGetPriorYearAccrualEmailDetails(purchaseOrderNumbers));
        }
    }, [dispatch, props.priorYearAccrualLineItems, props.showDialog]);

    /**
     * Memoized helper to check if the get prior year accrual email details api is running.
     * @returns True or false.
     */
    const isGetPriorYearAccrualEmailDetailsRunning = useMemo<boolean>(() => {
        if (apiGetPriorYearAccrualEmailDetails.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiGetPriorYearAccrualEmailDetails.callApiState]);

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={() => props.onCancelClicked()}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Email PO Owners',
                subText: 'Send reminder email notification to the PO owners listed below. The email notification will only be sent to PO owners who have not received a notification in the past 5 days.'
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth="400px"
        >
            {isGetPriorYearAccrualEmailDetailsRunning && (
                <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
            )}
            {!isGetPriorYearAccrualEmailDetailsRunning && (
                <table className={pageStyles.reminderMailListTable}>
                    <thead>
                        <tr>
                            <th>PO Owner</th>
                            <th>Last Sent Date</th>
                        </tr>
                    </thead>
                    <tbody className={pageStyles.reminderMailListTableBody} tabIndex={0}>
                        {apiGetPriorYearAccrualEmailDetails.priorYearAccrualEmailSentDetails?.map((item: PriorYearAccrualEmailSentDetail, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{item.purchaseOrderOwner}</td>
                                    <td>{formatDateUsingLocale(item.lastEmailSent)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.pyaSendReminderEmailsButtonClicked });

                        const purchaseOrderOwners: string[] | undefined = apiGetPriorYearAccrualEmailDetails.priorYearAccrualEmailSentDetails?.map(x => x.purchaseOrderOwner) || [];
                        props.onSendClicked(purchaseOrderOwners);
                    }}
                    disabled={isGetPriorYearAccrualEmailDetailsRunning || !apiGetPriorYearAccrualEmailDetails.priorYearAccrualEmailSentDetails || apiGetPriorYearAccrualEmailDetails.priorYearAccrualEmailSentDetails?.length === 0}
                    text="Send"
                />
                <DefaultButton
                    onClick={() => props.onCancelClicked()}
                    text="Cancel" 
                />
            </DialogFooter>
        </Dialog>
    );
};
