import React, { useMemo, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
    ComboBox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IComboBox,
    IComboBoxOption,
    Label,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { pageStyles } from './DriToolsPage.styles';
import { validationConstants } from '../../common/validationConstants';
import { padNumberWithLeadingChar } from '../../common/common.func.transform';
import { tooltips } from '../../common/tooltips';
import { SapLookupType } from '../../models/sapLookup/sapLookupType';
import { SapLookupData } from '../../models/sapLookup/sapLookupData';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import ReactJson from 'react-json-view'

interface IComponentProps {
}

export const lookupTypeOptions: IComboBoxOption[] = [
    {
        key: SapLookupType.PurchaseOrderHeader,
        text: commonString.purchaseOrderHeader
    } as IComboBoxOption,
    {
        key: SapLookupType.PurchaseOrderLines,
        text: commonString.purchaseOrderLines
    } as IComboBoxOption,
    {
        key: SapLookupType.Invoices,
        text: commonString.invoices
    } as IComboBoxOption,
    {
        key: SapLookupType.InvoiceDetails,
        text: commonString.invoiceDetails
    } as IComboBoxOption
];

export const SimpleSapLookupTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {  
    const poNumberInputId: string = useId();
    const invoiceNumberInputId: string = useId();
    const companyCodeInputId: string = useId();
    const fiscalYearInputId: string = useId();

    const lookupTypeInputId: string = useId();
    const [selectedLookupType, setLookupType] = useState<SapLookupType>(SapLookupType.PurchaseOrderHeader);
    const [poNumber, setPoNumber] = useState<string>('');
    const [invoiceNumber, setInvoiceNumber] = useState<string>('');
    const [companyCode, setCompanyCode] = useState<string>('');
    const [fiscalYear, setFiscalYear] = useState<string>('');
    const [poNumberValidationError, setPoNumberValidationError] = useState<string>('');
    const [invoiceNumberValidationError, setInvoiceNumberValidationError] = useState<string>('');
    const [companyCodeValidationError, setCompanyCodeValidationError] = useState<string>('');
    const [fiscalYearValidationError, setfiscalYearValidationError] = useState<string>('');
    const [sapLookupDataResponse, setSapLookupDataRespone] = useState<any | null>();
    const [isActionRunning, setIsActionRunning] = useState<boolean>(false); // For any action that can have data refreshed.
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [showErrorDialog, { toggle: toggleShowErrorDialog }] = useBoolean(false);

    /**
     * SAP lookup type change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeLookupType = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            setLookupType(option.key as SapLookupType);
        }
    };
    
    /**
     * Submit button clicked event handler.
     */
    const submitButtonClicked = async (refresh: boolean = false) => {
        setIsActionRunning(true);
        let po: string | undefined;
        let sapLookupRequest: SapLookupData | undefined;
        if (selectedLookupType === SapLookupType.PurchaseOrderHeader || selectedLookupType === SapLookupType.PurchaseOrderLines || selectedLookupType === SapLookupType.Invoices) {
            po = padNumberWithLeadingChar(poNumber, 10, '0');
            sapLookupRequest = new SapLookupData({
                purchaseOrder: po,    
                sapLookupBy: selectedLookupType
            });
        }
        if (selectedLookupType === SapLookupType.PurchaseOrderHeader && sapLookupRequest) {
            try {
                setSapLookupDataRespone(null); // Clear it first in the event there is an API call exception.
                const obj: any | null = await receiptingApiClient.performSapLookupForPurchaseOrderHeader(
                    sapLookupRequest
                );
                setSapLookupDataRespone(obj?.value);            
            } catch (err: any) {                
                setErrorMsg(JSON.stringify(err));
                toggleShowErrorDialog();
            }
        }
        if (selectedLookupType === SapLookupType.PurchaseOrderLines && sapLookupRequest) {
            
            try {
                setSapLookupDataRespone(null); // Clear it first in the event there is an API call exception.
                const obj: any | null = await receiptingApiClient.performSapLookupForPurchaseOrderLines(
                    sapLookupRequest
                );
                setSapLookupDataRespone(obj?.value);            
            } catch (err: any) {
                setErrorMsg(JSON.stringify(err));
                toggleShowErrorDialog();
            }
        }
        if (selectedLookupType === SapLookupType.Invoices && sapLookupRequest) {
            try {
                setSapLookupDataRespone(null); // Clear it first in the event there is an API call exception.
                const obj: any | null = await receiptingApiClient.performSapLookupForInvoices(
                    sapLookupRequest
                );
                const value = JSON.parse(obj?.value);
                setSapLookupDataRespone(value);           
            } catch (err: any) {
                setErrorMsg(JSON.stringify(err));
                toggleShowErrorDialog();
            }
        }
        if (selectedLookupType === SapLookupType.InvoiceDetails) {
            const sapLookupRequest: SapLookupData = new SapLookupData({
                invoiceNumber: invoiceNumber,
                companyCode: companyCode,
                fiscalYear: fiscalYear,    
                sapLookupBy: selectedLookupType
            });
            try {
                setSapLookupDataRespone(null); // Clear it first in the event there is an API call exception.
                const obj: any | null = await receiptingApiClient.performSapLookupForInvoiceDetails(
                    sapLookupRequest
                );
                const value = JSON.parse(obj?.value);
                setSapLookupDataRespone(value);
            
            } catch (err: any) {
                setErrorMsg(JSON.stringify(err));
                toggleShowErrorDialog();
            }
        }
       setIsActionRunning(false);
    };

     /**
     * Memoized helper to check if the input is invalid.
     */
     const isInputInvalid = useMemo<boolean>(() => {
        if (selectedLookupType === SapLookupType.PurchaseOrderHeader || selectedLookupType === SapLookupType.PurchaseOrderLines || selectedLookupType === SapLookupType.Invoices) {
            if (poNumber.trim().length === 0) {
                return true;
            }
            if (poNumberValidationError.length > 0) {
                return true;
            }
        } else if (selectedLookupType === SapLookupType.InvoiceDetails) {
            if (invoiceNumber.trim().length === 0 || companyCode.trim().length === 0 || fiscalYear.trim().length === 0) {
                return true;
            }
            if (invoiceNumberValidationError.length > 0 || companyCodeValidationError.length > 0 || fiscalYearValidationError.length > 0) {
                return true;
            }
        }

        return false;
    }, [poNumber, poNumberValidationError, selectedLookupType, invoiceNumber, invoiceNumberValidationError, companyCode, companyCodeValidationError, fiscalYear, fiscalYearValidationError]);

    /**
     * Display results for SAP lookup success and failures.
     * @returns JSX for results.
     */
    const displayResults = (): JSX.Element => {
        if (sapLookupDataResponse) {
            return (
                <>
                    <div>
                        <h3 className={commonStyles.sectionHeading}>Lookup results</h3>
                        <table role="presentation">
                            <tbody>
                                <tr>
                                    <td>
                                        <ReactJson src={sapLookupDataResponse || {}}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                    
                </>
            );
        }

        return <></>;
    };


    return (
        <>  
            <Separator />
        
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="medium">Use this tool to perform a simple SAP lookup to get purchase order and invoice details.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <Label htmlFor={lookupTypeInputId}>{commonString.lookupType}</Label>
                                <TooltipHost content={tooltips.sapLookupComboBox} delay={TooltipDelay.long}>
                                    <ComboBox
                                        id={lookupTypeInputId}
                                        ariaLabel={`${commonString.lookupType} ${tooltips.sapLookupComboBox}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.comboBox}
                                        options={lookupTypeOptions}
                                        selectedKey={selectedLookupType}
                                        onChange={onChangeLookupType}
                                        useComboBoxAsMenuWidth={true}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                </Stack.Item>
                {(selectedLookupType === SapLookupType.PurchaseOrderHeader || selectedLookupType === SapLookupType.PurchaseOrderLines || selectedLookupType === SapLookupType.Invoices) && (
                    <Stack.Item>
                        <Stack horizontal tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Label htmlFor={poNumberInputId}>{commonString.poNumber}</Label>
                                <TooltipHost content={validationConstants.poNumber.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={poNumberInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.poNumber} ${validationConstants.poNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.textField}
                                        value={poNumber}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.poNumber.maxLength! ||
                                                !RegExp(validationConstants.poNumber.pattern!).test(newValue))) {
                                                setPoNumberValidationError(validationConstants.poNumber.errorMsg!);
                                            } else {
                                                setPoNumberValidationError('');
                                            }
                                            setPoNumber(newValue);
                                        }}
                                        errorMessage={poNumberValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}
                {(selectedLookupType === SapLookupType.InvoiceDetails) && (
                <Stack.Item>
                        <Stack horizontal tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Label htmlFor={invoiceNumberInputId}>{commonString.invoiceNumber}</Label>
                                <TooltipHost content={validationConstants.invoiceNumber.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={invoiceNumberInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.invoiceNumber} ${validationConstants.invoiceNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.textField}
                                        value={invoiceNumber}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.invoiceNumber.maxLength! ||
                                                !RegExp(validationConstants.invoiceNumber.pattern!).test(newValue))) {
                                                setInvoiceNumberValidationError(validationConstants.invoiceNumber.errorMsg!);
                                            } else {
                                                setInvoiceNumberValidationError('');
                                            }
                                            setInvoiceNumber(newValue);
                                        }}
                                        errorMessage={invoiceNumberValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                            <Stack.Item>
                                <Label htmlFor={companyCodeInputId}>{commonString.companyCode}</Label>
                                <TooltipHost content={validationConstants.companyCode.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={companyCodeInputId}
                                        autoComplete='off'
                                        ariaLabel={validationConstants.companyCode.tooltip}
                                        className={pageStyles.textField}
                                        value={companyCode}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.companyCode.maxLength! ||
                                                !RegExp(validationConstants.companyCode.pattern!).test(newValue))) {
                                                setCompanyCodeValidationError(validationConstants.companyCode.errorMsg!);
                                            } else {
                                                setCompanyCodeValidationError('');
                                            }
                                            setCompanyCode(newValue);
                                        }}
                                        errorMessage={companyCodeValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>
                            <Stack.Item>
                                <Label htmlFor={fiscalYearInputId}>{commonString.fiscalYear}</Label>
                                <TooltipHost content={validationConstants.fiscalYear.tooltip} delay={TooltipDelay.long}>
                                    <TextField
                                        id={fiscalYearInputId}
                                        autoComplete='off'
                                        ariaLabel={`${commonString.fiscalYear} ${validationConstants.fiscalYear.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                        className={pageStyles.textField}
                                        value={fiscalYear}
                                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                            newValue = newValue || '';
                                            newValue = newValue.trim();
                                            if (newValue.length > 0 && (newValue.length > validationConstants.fiscalYear.maxLength! ||
                                                !RegExp(validationConstants.fiscalYear.pattern!).test(newValue))) {
                                                setfiscalYearValidationError(validationConstants.fiscalYear.errorMsg!);
                                            } else {
                                                setfiscalYearValidationError('');
                                            }
                                            setFiscalYear(newValue);
                                        }}
                                        errorMessage={fiscalYearValidationError}
                                    />
                                </TooltipHost>
                            </Stack.Item>            
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <DefaultButton
                            onClick={() => submitButtonClicked(true)}
                            disabled={isInputInvalid}
                        >
                        {isActionRunning && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        )}
                        {!isActionRunning && (
                        <span>Submit</span>
                        )}
                    </DefaultButton>
                </Stack.Item>      
                <Stack.Item>
                    {displayResults()}
                </Stack.Item>                                    
                {/* Error dialog. */}           
                <Dialog
                    hidden={!showErrorDialog}
                    onDismiss={toggleShowErrorDialog}
                    dialogContentProps={{
                                type: DialogType.normal,
                                title: 'Error',
                                subText: errorMsg
                            }}
                            modalProps={{
                            isBlocking: true
                        }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={toggleShowErrorDialog} text="Ok" />
                    </DialogFooter> 
                </Dialog>      
            </Stack>
        </>
    );
};
