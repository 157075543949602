import { POLineAccrualActivity } from './poLineAccrualActivity';

export interface IPOLineAccrualProcessingDetail {
    poLineAccrualActivities: POLineAccrualActivity[];
    lastRefreshTime?: Date;
}

export class POLineAccrualProcessingDetail implements IPOLineAccrualProcessingDetail {
    public poLineAccrualActivities: POLineAccrualActivity[] = [];
    public lastRefreshTime?: Date;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPOLineAccrualProcessingDetail) {
        if (jsonData.poLineAccrualActivities) {
            for (let i: number = 0; i < jsonData.poLineAccrualActivities.length; i++) {
                this.poLineAccrualActivities.push(new POLineAccrualActivity(jsonData.poLineAccrualActivities[i]));
            }
        }
        this.lastRefreshTime = jsonData.lastRefreshTime ? new Date(jsonData.lastRefreshTime) : undefined;
    }
}
