import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    dialogContainer: {
        minHeight: 'auto'
    } as IStyle,
    dialogTitle: {
        fontSize: '16px',
        minHeight: '0px',
        lineHeight: 0
    } as IStyle,
    supplierTextField: {
        width: '194px'
    } as IStyle,
    searchSupplierButton: {
        margin: '28px 0 0 0',
        minWidth: '34px',
        width: '34px'
    } as IStyle,
    dialogDivider: {
        background: 'lightgray',
        height: '100%',
        width: '2px',
        margin: '0 10px 0 10px'
    } as IStyle,
    list: {
        height: '200px',
        width: '260px',
        overflow: 'auto',
        borderTop: '1px solid lightgray'
    } as IStyle,
    listItem: {
        borderBottom: '1px solid lightgray'
    } as IStyle,
    listItemDetailsContainer: {
        display: 'inline-block',
        width: '200px'
    } as IStyle,
    listItemButtonContainer: {
        display: 'inline-block',
        verticalAlign: 'top',
        margin: '4px 0 4px 0'
    } as IStyle,
    addRemoveSupplierButton: {
        minWidth: '34px',
        width: '34px'
    } as IStyle
});
