import { AnyAction } from 'redux';
import * as actionTypes from '../actionTypes';

// The search results page also makes use of search.action.tsx and search.reducer.tsx for common search
// functionality used across multiple pages.

/**
 * Action creator: Edit a PO.
 * @param poNumber PO number to edit.
 * @returns Redux action.
 */
export const editPo = (poNumber: string | undefined) => {
    return {
        type: actionTypes.EDIT_PO,
        poNumber: poNumber
    } as AnyAction;
}

/**
 * Action creator: Clear edit PO.
 * @returns Redux action.
 */
export const clearEditPo = () => {
    return {
        type: actionTypes.EDIT_PO,
        poNumber: undefined
    } as AnyAction;
}
