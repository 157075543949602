import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    question: {
        fontWeight: 'bold',
        fontSize: '16px'
    } as IStyle,
    answer: {
        margin: '4px 0 0 0'
    } as IStyle,
    separator: {
        padding: '0'
    } as IStyle
});
