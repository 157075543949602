import { AnyAction } from 'redux';
import { PoType, Role, SearchOperation, SearchOperationParameter, Source } from '../../common/appEnums';
import { createShortGuid } from '../../common/common.func.guid';
import { PurchaseOrderDetails } from '../../models/purchaseOrder/purchaseOrderDetails';
import { PurchaseOrderHeader } from '../../models/purchaseOrder/purchaseOrderHeader';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { PurchaseOrderSearchFilter } from '../../models/purchaseOrder/purchaseOrderSearchFilter';
import { PurchaseOrderSearchMetaData } from '../../models/purchaseOrder/purchaseOrderSearchMetaData';
import { PurchaseOrderSearchResults } from '../../models/purchaseOrder/purchaseOrderSearchResults';
import { TileSet } from '../../models/dashboard/tileSet';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { AppDispatch } from '../reduxStore';
import * as actionTypes from './actionTypes';
import { callApi, CallApiState, ICallApiBase } from './generic.action';
import { PurchaseOrderCheckResult } from '../../models/purchaseOrder/purchaseOrderCheckResult';
import { IComboBoxOption } from '@fluentui/react';
import { IncludeGoodsServicesOptionKey, IncludeReceivedOptionKey } from '../../pages/HomePage/homePageConstants';
import { DashboardTile } from '../../models/dashboard/dashboardTile';

// This file contains Redux actions for search capabilities used across multiple pages in the app
// such as the home page, search results page, and edit page.

/**
 * Action creator: Store last search.
 * @param lastSearch Last search.
 * @returns Redux action.
 */
export const storeLastSearch = (lastSearch?: PurchaseOrderSearch): AnyAction => {
    return {
        type: actionTypes.STORE_LAST_SEARCH,
        purchaseOrderSearch: lastSearch
    } as AnyAction
};

/**
 * Action creator: Store search results.
 * @param purchaseOrderHeaders Purchase order headers.
 * @returns Redux action.
 */
export const storeSearchResults = (purchaseOrderHeaders: PurchaseOrderHeader[]): AnyAction => {
    return {
        type: actionTypes.STORE_SEARCH_RESULTS,
        purchaseOrderHeaders: purchaseOrderHeaders
    } as AnyAction;
};

/**
 * Action creator: Clear search results.
 * @returns Redux action.
 */
export const clearSearchResults = (): AnyAction => {
    return {
        type: actionTypes.STORE_SEARCH_RESULTS,
        purchaseOrderHeaders: undefined
    } as AnyAction;
};

/**
 * Action creator: Store visibility check results.
 * @param purchaseOrderHeaders Purchase order headers.
 * @returns Redux action.
 */
export const storeVisibilityCheckResults = (purchaseOrderCheckResults?: PurchaseOrderCheckResult[]): AnyAction => {
    return {
        type: actionTypes.STORE_VISIBILITY_CHECK_RESULTS,
        purchaseOrderCheckResults: purchaseOrderCheckResults
    } as AnyAction;
};

/**
 * Payload used with callApiHomeTileInfo action.
 */
export interface IApiHomeTileInfo extends ICallApiBase {
    dashboardTiles?: DashboardTile[] | null;
}

/**
 * Get data for the specified tile set and the first page of data for the Open tile for either the
 * regular user or Finance Controller (there is an Open tile in each).
 * @param purchaseOrderSearch Purchase order search criteria.
 * @param tileSet Tile set.
 * @returns Redux dispatch function.
 */
export const callApiHomeTileInfo = (purchaseOrderSearch: PurchaseOrderSearch, tileSet: TileSet): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<DashboardTile[] | null>(
        actionTypes.API_HOME_TILE_INFO,
        async () => {
            return await receiptingApiClient.homeTileInfo(purchaseOrderSearch, tileSet);
        },
        (payload: IApiHomeTileInfo, data, dispatch: AppDispatch) => {
            payload.dashboardTiles = data;
        }
    );
};

/**
 * Payload used with callApiSearch action.
 */
export interface IApiSearch extends ICallApiBase {
    purchaseOrderSearchResults?: PurchaseOrderSearchResults | null;
}

/**
 * Search for POs given search criteria.
 * @param purchaseOrderSearch Purchase order search criteria.
 * @returns Redux dispatch function.
 */
export const callApiSearch = (purchaseOrderSearch: PurchaseOrderSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PurchaseOrderSearchResults | null>(
        actionTypes.API_SEARCH,
        async () => {
            return await receiptingApiClient.search(purchaseOrderSearch);
        },
        (payload: IApiSearch, data: PurchaseOrderSearchResults | null, dispatch: AppDispatch) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.results.forEach((poDetails: PurchaseOrderDetails) => {
                    poDetails.header.clientRowKey = createShortGuid();
                    poDetails.items?.forEach((lineItem: PurchaseOrderLineItem) => {
                        lineItem.clientRowKey = createShortGuid();
                    })
                });
            }

            payload.purchaseOrderSearchResults = data;

            // Update the total records in the purchaseOrderSearch metaData with the value that came back from the api call.
            purchaseOrderSearch.metaData.totalRecords = data?.metaData.totalRecords || 0;

            // Store the last search and results.
            dispatch(storeLastSearch(purchaseOrderSearch));
            dispatch(storeSearchResults(data?.results.map(x => x.header) || []));

            // Store the visibility check results. This is primarily used when searching for a specific PO
            // (see callApiSearchForPo). The search api's should really be refactored and the direct PO
            // search api (callApiSearchForPo and the api it calls which does a GET request for a specific PO),
            // should be removed. There should just be this one callApiSearch api. So when searching using this
            // api, as of now, will not return visibilityCheckResults as a direct PO is not being looked up,
            // but rather search criteria are used for a set of results. After refactoring later, this code will
            // matter as we want to store the visibility check results of a search for a specific PO was done
            // via this api call.
            dispatch(storeVisibilityCheckResults(data?.visibilityCheckResults));
        }
    );
};

/**
 * Payload used with callApiSearchForPo action.
 */
export interface IApiSearchForPo extends ICallApiBase {
    purchaseOrderSearchResults?: PurchaseOrderSearchResults | null;
}

/**
 * Search for specific PO.
 * @param purchaseOrderNumber Purchase order number.
 * @param source Source.
 * @param searchOperation Search operation.
 * @param searchOperationParameter Search operation parameter.
 * @param includeReceived Included received.
 * @param poType PO type.
 * @param searchAsRole Search as role. This can be set to Agent, FinanceController, or User.
 * It is used to override a users normal role for search purposes.
 * @param applySiteFilters Apply site filters.
 * @param storeLastSearchAndResults Store last search and results in Redux store.
 * @returns Redux dispatch function.
 */
export const callApiSearchForPo = (
    purchaseOrderNumber: string,
    source: Source = Source.SAP,
    searchOperation: SearchOperation = SearchOperation.Nop,
    searchOperationParameter: SearchOperationParameter = SearchOperationParameter.None,
    includeReceived: boolean = true,
    poType: PoType = PoType.All,
    searchAsRole: Role = Role.User,
    applySiteFilters: boolean = true,
    storeLastSearchAndResults: boolean = true
): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PurchaseOrderSearchResults | null>(
        actionTypes.API_SEARCH_FOR_PO,
        async () => {
            // Note that when searching for a specific PO, there is a dedicated GET API for that which is different
            // from the search api that takes a PurchaseOrderSearch object. Need to refactor this and the server side
            // api so we don't need this dedicated GET API.
            return await receiptingApiClient.searchForPo(
                purchaseOrderNumber,
                source,
                searchOperation,
                searchOperationParameter,
                includeReceived,
                poType,
                searchAsRole,
                applySiteFilters
            );
        },
        (payload: IApiSearchForPo, data, dispatch) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.results.forEach((poDetails: PurchaseOrderDetails) => {
                    poDetails.header.clientRowKey = createShortGuid();
                    poDetails.items?.forEach((lineItem: PurchaseOrderLineItem) => {
                        lineItem.clientRowKey = createShortGuid();
                    })
                });
            }

            payload.purchaseOrderSearchResults = data;

            // Optionally store last search and results. This api to search for a single PO is called
            // from the home page (if PO number entered) and also called from the edit page when loading
            // the page to edit a PO. It is also called from the close line page.
            // In the latter case we don't want to store the last search results. Only store the last search
            // and results if called from the home page.
            if (storeLastSearchAndResults) {
                // Store the last search and results.
                // See note above about refactoring this API. As of now this API does not take a PurchaseOrderSearch
                // object so we need to make one here to be stored.
                dispatch(storeLastSearch(new PurchaseOrderSearch(
                    new PurchaseOrderSearchMetaData({
                        totalRecords: 0,
                        recordsPerPage: 0,
                        currentPageIndex: 0
                    }),
                    new PurchaseOrderSearchFilter({
                        purchaseOrderNumber: purchaseOrderNumber,
                        includeReceived: true,
                        includeGsrCor: 'Both'
                    })
                )));
                dispatch(storeSearchResults(data?.results.map(x => x.header) || []));
            }

            // Store the visibility check results regardless of the storeLastSearchAndResults flag.
            // This is because if the user refreshes or direct links to a page, such as the edit history page,
            // and that page (along with other pages like close line) will call this callApiSearchForPo
            // during page load. Those pages will pass storeLastSearchAndResults false. But we need to show
            // the visibility check results which would display if the PO is not found for the user for some reason,
            // such as lack of permissions or unsupported.
            dispatch(storeVisibilityCheckResults(data?.visibilityCheckResults));
        }
    );
};

/**
 * Action creator: Reset the api call state for home tile info. 
 * @returns Redux action.
 */
export const resetApiCallStateForHomeTileInfo = (): AnyAction => {
    const payload: IApiHomeTileInfo = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        dashboardTiles: undefined
    };
    return {
        type: actionTypes.API_HOME_TILE_INFO,
        payload: payload
    } as AnyAction;
};

/**
 * Action creator: Reset the api call state for search.
 * @returns Redux action.
 */
export const resetApiCallStateForSearch = (): AnyAction => {
    const payload: IApiSearch = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        purchaseOrderSearchResults: undefined
    };
    return {
        type: actionTypes.API_SEARCH,
        payload: payload
    } as AnyAction;
};

/**
 * Action creator: Reset the api call state for search for PO.
 * @returns Redux action.
 */
export const resetApiCallStateForSearchForPo = (): AnyAction => {
    const payload: IApiSearchForPo = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        purchaseOrderSearchResults: undefined
    };

    return {
        type: actionTypes.API_SEARCH_FOR_PO,
        payload: payload
    } as AnyAction;
};

/**
 * Action creator: Input filter - Enable DRI search.
 * @param driSearchEnabled If true, enabled, otherwise false.
 * @returns Redux action.
 */
export const inputFilterDriSearchEnabled = (driSearchEnabled: boolean): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DRI_SEARCH_ENABLED,
        driSearchEnabled: driSearchEnabled
    } as AnyAction
};

/**
 * Action creator: Input filter - PO number.
 * @param filterPoNumber PO number.
 * @returns Redux action.
 */
export const inputFilterPoNumber = (filterPoNumber: string): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_PO_NUMBER,
        filterPoNumber: filterPoNumber
    } as AnyAction
};

/**
 * Action creator: Input filter - Supplier number.
 * @param filterSupplierNumber Supplier number.
 * @returns Redux action.
 */
export const inputFilterSupplierNumber = (filterSupplierNumber: string): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_SUPPLIER_NUMBER,
        filterSupplierNumber: filterSupplierNumber
    } as AnyAction
};

/**
 * Action creator: Input filter - Company code.
 * @param filterCompanyCode Company code.
 * @returns Redux action.
 */
export const inputFilterCompanyCode = (filterCompanyCode: string): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_COMPANY_CODE,
        filterCompanyCode: filterCompanyCode
    } as AnyAction
};

/**
 * Action creator: Input filter - PO approver.
 * @param filterPoApprover PO approver.
 * @returns Redux action.
 */
export const inputFilterPoApprover = (filterPoApprover: string): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_PO_APPROVER,
        filterPoApprover: filterPoApprover
    } as AnyAction
};

/**
 * Action creator: Input filter - PO owner.
 * @param filterPoOwner PO owner.
 * @returns Redux action.
 */
export const inputFilterPoOwner = (filterPoOwner: string): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_PO_OWNER,
        filterPoOwner: filterPoOwner
    } as AnyAction
};

/**
 * Action creator: Input filter - Invoice approver.
 * @param filterInvoiceApprover Invoice approver.
 * @returns Redux action.
 */
export const inputFilterInvoiceApprover = (filterInvoiceApprover: string): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_INVOICE_APPROVER,
        filterInvoiceApprover: filterInvoiceApprover
    } as AnyAction
};

/**
 * Action creator: Input filter - Date created from.
 * @param filterDateCreatedFrom Date created from.
 * @returns Redux action.
 */
export const inputFilterDateCreatedFrom = (filterDateCreatedFrom: Date | undefined): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DATE_CREATED_FROM,
        filterDateCreatedFrom: filterDateCreatedFrom
    } as AnyAction
};

/**
 * Action creator: Input filter - Date created to.
 * @param filterDateCreatedTo Date created to.
 * @returns Redux action.
 */
export const inputFilterDateCreatedTo = (filterDateCreatedTo: Date | undefined): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DATE_CREATED_TO,
        filterDateCreatedTo: filterDateCreatedTo
    } as AnyAction
};

/**
 * Action creator: Input filter - Delivery date from.
 * @param filterDeliveryDateFrom Delivery date from.
 * @returns Redux action.
 */
export const inputFilterDeliveryDateFrom = (filterDeliveryDateFrom: Date | undefined): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DELIVERY_DATE_FROM,
        filterDeliveryDateFrom: filterDeliveryDateFrom
    } as AnyAction
};

/**
 * Action creator: Input filter - Delivery date to.
 * @param filterDeliveryDateTo Delivery date to.
 * @returns Redux action.
 */
export const inputFilterDeliveryDateTo = (filterDeliveryDateTo: Date | undefined): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DELIVERY_DATE_TO,
        filterDeliveryDateTo: filterDeliveryDateTo
    } as AnyAction
};

/**
 * Action creator: Input filter - Delegated by.
 * @param filterDelegatedBy Delegated by.
 * @returns Redux action.
 */
export const inputFilterDelegatedBy = (filterDelegatedBy: string[] | undefined): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DELEGATED_BY,
        filterDelegatedBy: filterDelegatedBy
    } as AnyAction
};

/**
 * Action creator: Input filter - Delegated by options.
 * @param filterDelegatedByOptions Delegated by options.
 * @returns Redux action.
 */
export const inputFilterDelegatedByOptions = (filterDelegatedByOptions: IComboBoxOption[]): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_DELEGATED_BY_OPTIONS,
        filterDelegatedByOptions: filterDelegatedByOptions
    } as AnyAction
};

/**
 * Action creator: Input filter - Hierarchy sales district codes.
 * @param filterHierarchySalesDistrictCodes Hierarchy sales district codes.
 * @returns Redux action.
 */
export const inputFilterHierarchySalesDistrictCodes = (filterHierarchySalesDistrictCodes: string[]): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_HIERARCHY_SALES_DISTRICT_CODES,
        filterHierarchySalesDistrictCodes: filterHierarchySalesDistrictCodes
    } as AnyAction
};

/**
 * Action creator: Input filter - Hierarchy channel function detail codes.
 * @param filterHierarchyChannelFunctionDetailCodes Hierarchy channel function detail codes.
 * @returns Redux action.
 */
export const inputFilterHierarchyChannelFunctionDetailCodes = (filterHierarchyChannelFunctionDetailCodes: string[]): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_FILTER_HIERARCHY_CHANNEL_FUNCTION_DETAIL_CODES,
        filterHierarchyChannelFunctionDetailCodes: filterHierarchyChannelFunctionDetailCodes
    } as AnyAction
};

/**
 * Action creator: Input filter - Hierarchy executive function detail codes.
 * @param filterHierarchyExecutiveFunctionDetailCodes Hierarchy executive function detail codes.
 * @returns Redux action.
 */
export const inputFilterHierarchyExecutiveFunctionDetailCodes = (filterHierarchyExecutiveFunctionDetailCodes: string[]): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_HIERARCHY_EXECUTIVE_FUNCTION_DETAIL_CODES,
        filterHierarchyExecutiveFunctionDetailCodes: filterHierarchyExecutiveFunctionDetailCodes
    } as AnyAction
};

/**
 * Action creator: Input filter - Include received.
 * @param filterIncludeReceived Include received.
 * @returns Redux action.
 */
export const inputFilterIncludeReceived = (filterIncludeReceived: IncludeReceivedOptionKey): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_INCLUDE_RECEIVED,
        filterIncludeReceived: filterIncludeReceived
    } as AnyAction
};

/**
 * Action creator: Input filter - Include goods and/or services.
 * @param filterIncludeGoodsServices Include received.
 * @returns Redux action.
 */
export const inputFilterIncludeGoodsServices = (filterIncludeGoodsServices: IncludeGoodsServicesOptionKey): AnyAction => {
    return {
        type: actionTypes.INPUT_FILTER_INCLUDE_GOODS_SERVICES,
        filterIncludeGoodsServices: filterIncludeGoodsServices
    } as AnyAction
};
