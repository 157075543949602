import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    DefaultButton,
    Label,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { commonString } from '../../common/commonString';
import { pageStyles } from './DriToolsPage.styles';
import { CallApiState } from '../../store/actions/generic.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';
import { validationConstants } from '../../common/validationConstants';
import { PoVisibilityCheckResults } from '../../components/PoVisibilityCheckResults/PoVisibilityCheckResults';
import { GraphUserInput } from '../../components/GraphUserInput/GraphUserInput';
import { GraphUser } from '../../models/user/graphUser';
import { IApiPurchaseOrderCheck, callApiPurchaseOrderCheck, resetApiCallStateForPurchaseOrderCheck } from '../../store/actions/pageActions/driToolsPage.action';

interface IComponentProps {
}

export const PoVisibilityCheckTab: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    const poNumberInputId: string = useId();
    const [poNumber, setPoNumber] = useState<string>('');
    const [poNumberValidationError, setPoNumberValidationError] = useState<string>('');

    const [checkGraphUser, setCheckGraphUser] = useState<GraphUser | undefined>();

    // Separate state for the check alias (different from checkGraphUser). This state is to store the last run
    // check alias. The checkGraphUser is for the current selection of the graph user input.
    const [checkAlias, setCheckAlias] = useState<string>('');

    // Redux store selectors to get state from the store when it changes.
    const apiPurchaseOrderCheck: IApiPurchaseOrderCheck =
        useAppSelector<IApiPurchaseOrderCheck>((state) => state.driToolsPageReducer.apiPurchaseOrderCheck);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiPurchaseOrderCheck.errMsg) {
            handleError(apiPurchaseOrderCheck.errMsg);
        }
    }, [apiPurchaseOrderCheck.errMsg, handleError]);

    /**
     * Effect that returns a cleanup function for when this component is unmounted.
     */
    useEffect(() => {
        return () => {
            // Reset the api call state for reverse goods receipt.
            // So that when users navigate away and come back later, the state is refreshed.
            dispatch(resetApiCallStateForPurchaseOrderCheck());
        }
    }, [dispatch]);

    /**
     * Memoized helper to check if the PO check api is running.
     * @returns True or false.
     */
    const isCheckRunning = useMemo<boolean>(() => {
        if (apiPurchaseOrderCheck.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPurchaseOrderCheck.callApiState]);

    /**
     * Memoized helper to determine if inputs are valid.
     */
    const inputsValid: boolean = useMemo<boolean>(() => {
        if (poNumber.trim().length === 0 || !checkGraphUser?.id) {
            return false;
        }
        return true;
    }, [checkGraphUser?.id, poNumber]);

    /**
     * Check button clicked event handler.
     */
    const checkButtonClicked = () => {
        if (checkGraphUser?.alias && checkGraphUser.id) {
            setCheckAlias(checkGraphUser.alias);
            dispatch(callApiPurchaseOrderCheck(poNumber, checkGraphUser.alias, checkGraphUser.id));
        }
    };

    return (
        <>    
            <Separator />

            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="medium">Use this tool to check a PO to see if it is available and if a specific user may or may not have access to it.</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <Label htmlFor={poNumberInputId}>{commonString.poNumber}</Label>
                            <TooltipHost content={validationConstants.poNumber.tooltip} delay={TooltipDelay.long}>
                                <TextField
                                    id={poNumberInputId}
                                    autoComplete='off'
                                    ariaLabel={`${commonString.poNumber} ${validationConstants.poNumber.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                                    className={pageStyles.textField}
                                    value={poNumber}
                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                        newValue = newValue || '';
                                        newValue = newValue.trim();
                                        if (newValue.length > 0 && (newValue.length > validationConstants.poNumber.maxLength! ||
                                            !RegExp(validationConstants.poNumber.pattern!).test(newValue))) {
                                            setPoNumberValidationError(validationConstants.poNumber.errorMsg!);
                                        } else {
                                            setPoNumberValidationError('');
                                        }
                                        setPoNumber(newValue);
                                    }}
                                    errorMessage={poNumberValidationError}
                                />
                            </TooltipHost>
                        </Stack.Item>
                        <Stack.Item>
                            <GraphUserInput
                                showLabel={true}
                                label="User Alias"
                                disabled={false}
                                required={true}
                                onChange={(graphUser: GraphUser | undefined) => {
                                    if (graphUser && graphUser.alias && graphUser.id) {
                                        setCheckGraphUser(graphUser);
                                    } else {
                                        setCheckGraphUser(undefined);
                                    }
                                }}
                                onInputValidation={(msg: string) => {
                                    // Not doing anything here for input validation.
                                    // The onChange handler will be also called even when validation errors happen.
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton
                        onClick={checkButtonClicked}
                        disabled={isCheckRunning || !inputsValid}
                    >
                        {isCheckRunning ? (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerGrid} />
                        ) : (
                            <Text>Check</Text>
                        )}
                    </DefaultButton>
                </Stack.Item>
                {apiPurchaseOrderCheck.callApiState === CallApiState.Completed && (
                    <>
                        <Stack.Item>
                            Check results for user: {checkAlias}
                        </Stack.Item>
                        <Stack.Item>
                            <PoVisibilityCheckResults
                                purchaseOrderNumber={poNumber}
                                results={apiPurchaseOrderCheck.checkResults || []}
                            />
                        </Stack.Item>
                    </>
                )}
            </Stack>
        </>
    );
};
