import { AccessRight, Role } from '../../common/appEnums';
import { isNullOrUndefined } from '../../common/common.func.general';
import { UserDelegate } from './userDelegate';
import { UserRole } from './userRole';

export interface IUserProfile {
    id: string; // Same as AAD Object Id. Needs to be called id for DocumentDB storage.
    alias: string;
    roles: UserRole[];
    delegateTo: UserDelegate[];
    delegateFrom: UserDelegate[];
}

export class UserProfile implements IUserProfile {
    public id: string;
    public alias: string;
    public roles: UserRole[] = [];
    public delegateTo: UserDelegate[] = [];
    public delegateFrom: UserDelegate[] = [];

    /**
     * Returns true if the user has the specified role.
     */
    private hasRole(role: string): boolean {
        if (isNullOrUndefined(this.roles)) {
            return false;
        }
        for (let i: number = 0; i < this.roles!.length; i++) {
            if (this.roles![i].name.toLowerCase() === role.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns true if the user has the agent role.
     */
    public get isAgent(): boolean {
        return this.hasRole('agent');
    }

    /**
     * Returns true if the user has the finance controller role.
     */
    public get isFinanceController(): boolean {
        return this.hasRole('finance controller');
    }

    /**
     * Returns true if the user has the accruals admin role.
     */
    public get isAccrualsAdmin(): boolean {
        return this.hasRole('accruals admin');
    }

    /**
     * Returns true if the user has the shipment admin role.
     */
    public get isShipmentAdmin(): boolean {
        return this.hasRole('shipment admin');
    }
    
    /**
     * Returns true if the user has the DRI role.
     */
    public get isDri(): boolean {
        return this.hasRole('dri');
    }

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IUserProfile) {
        this.id = jsonData.id;
        this.alias = jsonData.alias;

        if (jsonData.roles) {
            for (let i: number = 0; i < jsonData.roles.length; i++) {
                this.roles.push(new UserRole(jsonData.roles[i]));
            }
        }

        if (jsonData.delegateTo) {
            for (let i: number = 0; i < jsonData.delegateTo.length; i++) {
                this.delegateTo.push(new UserDelegate(jsonData.delegateTo[i]));
            }
        }
        if (jsonData.delegateFrom) {
            for (let i: number = 0; i < jsonData.delegateFrom.length; i++) {
                this.delegateFrom.push(new UserDelegate(jsonData.delegateFrom[i]));
            }
        }
    }

    /**
     * Gets list of users who have delegated to this user (delegateFrom list) which are not expired.
     * @param withAccessRight Option check to only return users with this access right.
     */
    public getUnexpiredDelegateFromUsers(withAccessRight?: AccessRight): string[] {
        const unexpiredDelegateFromList: string[] = [];
        for (let i: number = 0; i < this.delegateFrom.length; i++) {
            if (this.delegateFrom[i].isExpired) {
                continue;
            }
            if (withAccessRight) {
                if (this.delegateFrom[i].accessRights.indexOf(withAccessRight) < 0) {
                    continue;
                }
            }
            unexpiredDelegateFromList.push(this.delegateFrom[i].alias);
        }

        // The filter below will remove duplicates from the array. If a user has delegated to another user multiple
        // times with different company codes or permissions or expiration dates... then there would be the same user
        // multiple times in the array. This will fix that so the user will only appear once in the array.
        return unexpiredDelegateFromList.filter((value: string, index: number, array: string[]) => {
            return array.indexOf(value) === index;
        });
    }

    /**
     * Returns the users role to search as. 
     * This should be Role for FinanceController, Agent, or User, not any of the other roles.
     * A user can be either a FinanceController or Agent but not both. Most users are standard "User" roles
     * which means their RBAC record has no special "role".
     * There are some other roles like AccrualsAdmin and Dri but those are not used with search. 
     * The search api takes a 'searchAsRole' input which is why this function exists.
     * Probably the code should be refactored to not need to pass such a parameter.
     */
    public getSearchAsRole(): Role {
        if (this.isFinanceController) {
            return Role.FinanceController;
        } else if (this.isAgent) {
            return Role.Agent;
        }

        return Role.User;
    }
}
