import { appConstants } from '../../common/appConstants';

/**
 * If the company code array contains the "all company code" value, then return an empty array.
 * @param companyCodes Company codes array.
 * @returns Empty array if the company codes contained the all company code value, otherwise returns the unmodified array.
 */
export const stripAllCompanyCodeValue = <T extends string | number>(companyCodes: T[] | undefined): T[] => {
    if (!companyCodes || companyCodes.length === 0) {
        // If companyCodes is undefined or empty, return an empty array.
        return [];
    }

    // If the company codes array is a string array.
    if (typeof companyCodes[0] === 'string') {
        if ((companyCodes as string[]).indexOf(String(appConstants.allCompanyCodeValue)) > -1) {
            return [];
        }
    }

    // If the company codes array is a number array.
    if (typeof companyCodes[0] === 'number') {
        if ((companyCodes as number[]).indexOf(appConstants.allCompanyCodeValue) > -1) {
            return [];
        }
    }

    return companyCodes;
};
