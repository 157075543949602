import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    actionButtonEditPo: {
        height: '26px',
        fontSize: '12px',
        textDecoration: 'none',
        color: '#0070cc',
        paddingLeft: '0px',
        span: {
            marginLeft: '0px'
        } as IStyle,
        ':hover': {
            fontWeight: 'bold'
        }
    } as IStyle,
    activePo: {
        fontWeight: 'bold'
    } as IStyle,
    inactivePo: {
        fontWeight: 'normal'
    } as IStyle
});
