
import React, { useEffect, useState } from 'react';
import { Text } from '@fluentui/react';
import { useAppSelector } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { commonStyles } from '../../common/common.styles';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { extractPoLineFromNotificationId, isEqualIgnorePadding } from '../../common/common.func.general';
import { ClientNotificationItemState } from '../../models/clientNotification/clientNotificationItemState';

/**
 * Closed indicator props.
 */
export interface IClosedIndicatorProps {
    // Line can only be closed on priorYearAccrual or closeLine pages. This control is also used on the editPage
    // but since lines cannot be closed there, the SignalR notification code in this component would not be used
    // in that case.
    usedOnPage: 'priorYearAccrual' | 'closeLine' | 'editPage';
    item: PurchaseOrderLineItem | PriorYearAccrualLineItem;
}

/**
 * Closed indicator.
 * @param props Closed indicator props.
 */
export const ClosedIndicator: React.FunctionComponent<IClosedIndicatorProps> = (props: IClosedIndicatorProps): JSX.Element => {
    const [isClosed, setIsClosed] = useState<boolean>(props.item.isClosed);
    
    // Redux store selectors to get state from the store when it changes.
    const clientNotificationItems: ClientNotificationItem[] =
        useAppSelector<ClientNotificationItem[]>((state) => state.appReducer.clientNotificationItems);

    /**
     * Effect for when clientNotificationItems changes.
     * We watch for changes to this in order to change the closed indicator and closed checkbox when notifications
     * come in via SignalR.
     */
    useEffect(() => {
        clientNotificationItems.forEach(item => {
            // If the item is pre-existing (meaning the item existed at app-load time), then do nothing here.
            // We only want to do this update for notification items that come in after the app and any existing
            // notifications have already loaded. The reason for this is: Consider if there are notification items
            // that are for a PO line item in a failed state. If the user refreshes the page, we want the status
            // indicator to be what came back from the API to load the line items and not be altered by any existing
            // notificaiton.
            if (item && item.id && !item.isPreExisting) {
                // See if the notification item is for the PO/Line for this item.
                const extracted: {poNumber?: string, lineItem?: string} = extractPoLineFromNotificationId(item.id);
                const { poNumber, lineItem } = extracted;
                if (
                    (props.usedOnPage === 'priorYearAccrual' && props.item instanceof PriorYearAccrualLineItem && isEqualIgnorePadding(props.item.purchaseOrder, poNumber || '') && isEqualIgnorePadding(props.item.lineItem, lineItem || '')) ||
                    ((props.usedOnPage === 'closeLine') && props.item instanceof PurchaseOrderLineItem && isEqualIgnorePadding(props.item.purchaseOrderNumber, poNumber || '') && isEqualIgnorePadding(props.item.purchaseOrderLineNumber, lineItem || ''))
                ) {
                    switch (item.notificationItemState) {
                        case ClientNotificationItemState.Succeeded: {
                            setIsClosed(true);
                            break;
                        }
                        default:
                    }
                }
            }
        })
    }, [clientNotificationItems, props.item, props.usedOnPage]);

    return (
        <>
            {!props.item.canClose && isClosed && !props.item.offlineProcessingRunning && (
                <Text className={commonStyles.closedIndicator}>
                    Closed
                </Text>
            )}
        </>  
    );
};
