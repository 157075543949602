import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    pieChart: {
        zIndex: 100
    } as IStyle,
    pieChartTooltip: {
        border: '1px solid gray',
        backgroundColor: 'white',
        padding: '4px',
        borderRadius: '4px',
        whiteSpace: 'nowrap'
    } as IStyle
});
