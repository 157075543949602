import { IShipmentFileInfo, ShipmentFileInfo } from './shipmentFileInfo';

export interface IShipmentFileProcessingStatus {
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    data: IShipmentFileInfo[];
}

export class ShipmentFileProcessingStatus implements IShipmentFileProcessingStatus {
    public totalCount: number;
    public pageNumber: number;
    public pageSize: number;
    public data: IShipmentFileInfo[];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IShipmentFileProcessingStatus) {
        this.totalCount = jsonData.totalCount;
        this.pageNumber = jsonData.pageNumber;
        this.pageSize = jsonData.pageSize;
        this.data = jsonData.data.map((item: IShipmentFileInfo) => new ShipmentFileInfo(item));
    }
}
