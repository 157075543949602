import { AnyAction, Reducer } from 'redux';
import { deepCopyObject } from '../../../common/common.func.general';
import { PriorYearAccrualLineItem } from '../../../models/priorYearAccrual/priorYearAccrualLineItem';
import { IPriorYearAccrualSearchResults } from '../../../models/priorYearAccrual/priorYearAccrualSearchResults';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import {
    IApiPriorYearAccrualSearch,
    IApiPriorYearAccrualUserSummary,
    IApiPriorYearAccrualElevatedSummary,
    IApiPriorYearAccrualRetain,
    IApiPriorYearAccrualRefresh,
    IApiClosePoLines,
    IApiPriorYearAccrualExport,
    IApiPriorYearAccrualExportCorpDetails,
    IApiPriorYearAccrualImportCorpDetails,
    IApiGetPriorYearAccrualEmailDetails,
    IApiTriggerPriorYearAccrualManualNotification,
    IApiPriorYearAccrualHierarchyScope,
    IApiSavePriorYearAccrualHierarchyScope,
    IApiPriorYearAccrualProfitCenterCodeMapping,
    IApiUpdateAccountingDetails,
    IApiCostCategoryConfiguration,
    IApiCostCategorySubclasses,
    IApiSaveCostCategoryConfiguration,
    IApiDeleteCostCategoryConfiguration,
    IApiCostCategoryGroupNames,
    IApiCostCategoryLineItems,
    IApiCostCategoryLineItemDetails,
    IApiPriorYearAccrualRegions,
    IApiCostCategoryConfigurationForGlAccount,
    IApiPyaUploadManualPostings
} from '../../actions/pageActions/priorYearAccrualPage.action';
import { CostCategoryConfiguration } from '../../../models/priorYearAccrual/costCategoryConfiguration';
import { CodeValue } from '../../../models/priorYearAccrual/codeValue';

export interface IPriorYearAccrualPageReducerState {
    apiPriorYearAccrualUserSummary: IApiPriorYearAccrualUserSummary;
    apiPriorYearAccrualElevatedSummary: IApiPriorYearAccrualElevatedSummary;
    apiPriorYearAccrualRegions: IApiPriorYearAccrualRegions;
    apiPriorYearAccrualSearch: IApiPriorYearAccrualSearch;
    apiPriorYearAccrualExport: IApiPriorYearAccrualExport;
    apiPriorYearAccrualExportCorpDetails: IApiPriorYearAccrualExportCorpDetails;
    apiPriorYearAccrualImportCorpDetails: IApiPriorYearAccrualImportCorpDetails;
    apiPriorYearAccrualRetain: IApiPriorYearAccrualRetain;
    apiPriorYearAccrualRefresh: IApiPriorYearAccrualRefresh;
    apiClosePoLines: IApiClosePoLines;
    apiGetPriorYearAccrualEmailDetails: IApiGetPriorYearAccrualEmailDetails;
    apiTriggerPriorYearAccrualManualNotification: IApiTriggerPriorYearAccrualManualNotification;
    apiUpdateAccountingDetails: IApiUpdateAccountingDetails;
    showDetailsForPriorYearAccrualLineItem?: PriorYearAccrualLineItem;
    showAuditForPriorYearAccrualLineItem?: PriorYearAccrualLineItem;
    apiPriorYearAccrualHierarchyScope: IApiPriorYearAccrualHierarchyScope;
    apiSavePriorYearAccrualHierarchyScope: IApiSavePriorYearAccrualHierarchyScope;
    apiPriorYearAccrualProfitCenterCodeMapping: IApiPriorYearAccrualProfitCenterCodeMapping;
    profitCenterCodeMappingSortUsingColumnKey?: string;
    apiCostCategoryConfiguration: IApiCostCategoryConfiguration;
    costCategoryConfigurationSortUsingColumnKey?: string;
    apiCostCategoriySubclasses: IApiCostCategorySubclasses;
    apiCostCategoryGroupNames: IApiCostCategoryGroupNames;
    apiCostCategoryLineItems: IApiCostCategoryLineItems;
    apiCostCategoryLineItemDetails: IApiCostCategoryLineItemDetails;
    apiSaveCostCategoryConfiguration: IApiSaveCostCategoryConfiguration;
    apiDeleteCostCategoryConfiguration: IApiDeleteCostCategoryConfiguration;
    costCategoryConfigurationToEdit?: CostCategoryConfiguration;
    costCategoryConfigurationToDelete?: CostCategoryConfiguration;
    apiCostCategoryConfigurationForGlAccount: IApiCostCategoryConfigurationForGlAccount;
    apiPyaUploadManualPostings: IApiPyaUploadManualPostings;
}

const initialPriorYearAccrualPageReducerState: IPriorYearAccrualPageReducerState = {
    apiPriorYearAccrualUserSummary: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        priorYearAccrualUserSummary: undefined
    },
    apiPriorYearAccrualElevatedSummary: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        priorYearAccrualElevatedSummary: undefined
    },
    apiPriorYearAccrualRegions: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        regions: undefined
    },
    apiPriorYearAccrualSearch: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        priorYearAccrualSearchResults: undefined
    },
    apiPriorYearAccrualExport: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        responseStatusCode: undefined
    },
    apiPriorYearAccrualExportCorpDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        responseStatusCode: undefined
    },
    apiPriorYearAccrualImportCorpDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiPriorYearAccrualRetain: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiPriorYearAccrualRefresh: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiClosePoLines: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiGetPriorYearAccrualEmailDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        priorYearAccrualEmailSentDetails: undefined
    },
    apiTriggerPriorYearAccrualManualNotification: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiUpdateAccountingDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    showDetailsForPriorYearAccrualLineItem: undefined,
    showAuditForPriorYearAccrualLineItem: undefined,
    apiPriorYearAccrualHierarchyScope: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        priorYearAccrualHierarchyScope: undefined
    },
    apiSavePriorYearAccrualHierarchyScope: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiPriorYearAccrualProfitCenterCodeMapping: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        profitCenterCodeMapping: undefined
    },
    profitCenterCodeMappingSortUsingColumnKey: undefined,
    apiCostCategoryConfiguration: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        costCategoryConfig: undefined
    },
    costCategoryConfigurationSortUsingColumnKey: undefined,
    apiCostCategoriySubclasses: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        costCategorySubclasses: undefined
    },
    apiCostCategoryGroupNames: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        groupNames: undefined
    },
    apiCostCategoryLineItems: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        lineItems: undefined
    },
    apiCostCategoryLineItemDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        lineItemDetails: undefined
    },
    apiSaveCostCategoryConfiguration: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeleteCostCategoryConfiguration: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    costCategoryConfigurationToEdit: undefined,
    costCategoryConfigurationToDelete: undefined,
    apiCostCategoryConfigurationForGlAccount: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        costCategoryConfiguration: undefined
    },
    apiPyaUploadManualPostings: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
}

export const priorYearAccrualPageReducer: Reducer<IPriorYearAccrualPageReducerState, AnyAction> = (
    state: IPriorYearAccrualPageReducerState = initialPriorYearAccrualPageReducerState, action: AnyAction
): IPriorYearAccrualPageReducerState => {
    switch (action.type) {
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_USER_SUMMARY: {
            const payload: IApiPriorYearAccrualUserSummary = action.payload as IApiPriorYearAccrualUserSummary;
            return {
                ...state,
                apiPriorYearAccrualUserSummary: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_ELEVATED_SUMMARY: {
            const payload: IApiPriorYearAccrualElevatedSummary = action.payload as IApiPriorYearAccrualElevatedSummary;
            return {
                ...state,
                apiPriorYearAccrualElevatedSummary: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_REGIONS: {
            const payload: IApiPriorYearAccrualRegions = action.payload as IApiPriorYearAccrualRegions;
            return {
                ...state,
                apiPriorYearAccrualRegions: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_SEARCH: {
            const payload: IApiPriorYearAccrualSearch = action.payload as IApiPriorYearAccrualSearch;
            return {
                ...state,
                apiPriorYearAccrualSearch: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_EXPORT: {
            const payload: IApiPriorYearAccrualExport = action.payload as IApiPriorYearAccrualExport;
            return {
                ...state,
                apiPriorYearAccrualExport: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_EXPORT_CORP_DETAILS: {
            const payload: IApiPriorYearAccrualExportCorpDetails = action.payload as IApiPriorYearAccrualExportCorpDetails;
            return {
                ...state,
                apiPriorYearAccrualExportCorpDetails: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_IMPORT_CORP_DETAILS: {
            const payload: IApiPriorYearAccrualImportCorpDetails = action.payload as IApiPriorYearAccrualImportCorpDetails;
            return {
                ...state,
                apiPriorYearAccrualImportCorpDetails: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_RETAIN_LINE: {
            const payload: IApiPriorYearAccrualRetain = action.payload as IApiPriorYearAccrualRetain;
            return {
                ...state,
                apiPriorYearAccrualRetain: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_REFRESH_LINE: {
            const payload: IApiPriorYearAccrualRefresh = action.payload as IApiPriorYearAccrualRefresh;
            return {
                ...state,
                apiPriorYearAccrualRefresh: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_CLOSE_LINE: {
            const payload: IApiClosePoLines = action.payload as IApiClosePoLines;
            return {
                ...state,
                apiClosePoLines: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_GET_PRIOR_YEAR_ACCRUAL_EMAIL_DETAILS: {
            const payload: IApiGetPriorYearAccrualEmailDetails = action.payload as IApiGetPriorYearAccrualEmailDetails;
            return {
                ...state,
                apiGetPriorYearAccrualEmailDetails: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_TRIGGER_PRIOR_YEAR_ACCRUAL_MANUAL_NOTIFICATION: {
            const payload: IApiTriggerPriorYearAccrualManualNotification = action.payload as IApiTriggerPriorYearAccrualManualNotification;
            return {
                ...state,
                apiTriggerPriorYearAccrualManualNotification: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_UPDATE_ACCOUNTING_DETAILS: {
            const payload: IApiUpdateAccountingDetails = action.payload as IApiUpdateAccountingDetails;
            return {
                ...state,
                apiUpdateAccountingDetails: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.SHOW_DETAILS_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM: {
            return {
                ...state,
                showDetailsForPriorYearAccrualLineItem: action.showDetailsForPriorYearAccrualLineItem
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.SHOW_AUDIT_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM: {
            return {
                ...state,
                showAuditForPriorYearAccrualLineItem: action.showAuditForPriorYearAccrualLineItem
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.PRIOR_YEAR_ACCRUAL_LINE_ITEM_SELECTION: {
            const priorYearAccrualLineItem: PriorYearAccrualLineItem = action.lineItemSelection;

            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PriorYearAccrualLineItem[] = [...state.apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items || []];

            // Find the record in the api result data.
            const index: number = itemsCopy.findIndex(
                x => x.purchaseOrder === priorYearAccrualLineItem.purchaseOrder &&
                x.lineItem === priorYearAccrualLineItem.lineItem
            );
            if (index > -1) {
                const lineItem: PriorYearAccrualLineItem = itemsCopy[index];
                // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                const lineItemCopy: PriorYearAccrualLineItem = deepCopyObject(lineItem);
                lineItemCopy.isSelected = action.checked;
                itemsCopy[index] = lineItemCopy;
            }

            return {
                ...state,
                apiPriorYearAccrualSearch: {
                    ...state.apiPriorYearAccrualSearch,
                    priorYearAccrualSearchResults: {
                        ...state.apiPriorYearAccrualSearch.priorYearAccrualSearchResults,
                        items: itemsCopy
                    } as IPriorYearAccrualSearchResults
                } as IApiPriorYearAccrualSearch
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.PRIOR_YEAR_ACCRUAL_TOGGLE_ALL_SELECTION: {
            // Create a new array of line item objects using the array spread operator. Note the objects themselves
            // are the same instances, just put into a new array.
            const itemsCopy: PriorYearAccrualLineItem[] = [...state.apiPriorYearAccrualSearch.priorYearAccrualSearchResults?.items || []];

            for (let i: number = 0; i < itemsCopy.length; i++) {
                // Only change selection if canClose is true. There is no canRetain so canClose is also used to determine if can retain.
                if (itemsCopy[i].canClose) {
                    // Cannot directly mutate state in a reducer - need to return the updated state in the return statement below.
                    // First make a copy of the object and replace it in the array. Then make mutations to the copy.
                    const lineItemCopy: PriorYearAccrualLineItem = deepCopyObject(itemsCopy[i]);
                    lineItemCopy.isSelected = action.checked;
                    itemsCopy[i] = lineItemCopy;
                }
            }

            return {
                ...state,
                apiPriorYearAccrualSearch: {
                    ...state.apiPriorYearAccrualSearch,
                    priorYearAccrualSearchResults: {
                        ...state.apiPriorYearAccrualSearch.priorYearAccrualSearchResults,
                        items: itemsCopy
                    } as IPriorYearAccrualSearchResults
                } as IApiPriorYearAccrualSearch
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE: {
            const payload: IApiPriorYearAccrualHierarchyScope = action.payload as IApiPriorYearAccrualHierarchyScope;
            return {
                ...state,
                apiPriorYearAccrualHierarchyScope: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_SAVE_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE: {
            const payload: IApiSavePriorYearAccrualHierarchyScope = action.payload as IApiSavePriorYearAccrualHierarchyScope;
            return {
                ...state,
                apiSavePriorYearAccrualHierarchyScope: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_PROFIT_CENTER_CODE_MAPPING: {
            const payload: IApiPriorYearAccrualProfitCenterCodeMapping = action.payload as IApiPriorYearAccrualProfitCenterCodeMapping;
            return {
                ...state,
                apiPriorYearAccrualProfitCenterCodeMapping: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_COST_CATEGORY_CONFIG: {
            const payload: IApiCostCategoryConfiguration = action.payload as IApiCostCategoryConfiguration;
            return {
                ...state,
                apiCostCategoryConfiguration: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.PROFIT_CENTER_CODE_MAPPING_SORT_ON_COLUMN: {
            return {
                ...state,
                profitCenterCodeMappingSortUsingColumnKey: action.profitCenterCodeMappingSortUsingColumnKey
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.PROFIT_CENTER_CODE_MAPPING_UPDATE_SORTED: {
            return {
                ...state,
                profitCenterCodeMappingSortUsingColumnKey: undefined, // Important to set this to undefined so the effect in the page runs once.
                apiPriorYearAccrualProfitCenterCodeMapping: {
                    ...state.apiPriorYearAccrualProfitCenterCodeMapping,
                    profitCenterCodeMapping: action.profitCenterCodeMappingItems
                } as IApiPriorYearAccrualProfitCenterCodeMapping
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.COST_CATEGORY_CONFIGURATION_SORT_ON_COLUMN: {
            return {
                ...state,
                costCategoryConfigurationSortUsingColumnKey: action.costCategoryConfigurationSortUsingColumnKey
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.COST_CATEGORY_CONFIGURATION_UPDATE_SORTED: {
            return {
                ...state,
                costCategoryConfigurationSortUsingColumnKey: undefined, // Important to set this to undefined so the effect in the page runs once.
                apiCostCategoryConfiguration: {
                    ...state.apiCostCategoryConfiguration,
                    costCategoryConfig: action.costCategoryConfigurationItems
                } as IApiCostCategoryConfiguration
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_COST_CATEGORY_SUBCLASSES: {
            const payload: IApiCostCategorySubclasses = action.payload as IApiCostCategorySubclasses;
            payload.costCategorySubclasses?.sort((a: CodeValue, b: CodeValue) => {
                return a.value < b.value ? -1 : 1;
            });
            return {
                ...state,
                apiCostCategoriySubclasses: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_COST_CATEGORY_GROUP_NAMES: {
            const payload: IApiCostCategoryGroupNames = action.payload as IApiCostCategoryGroupNames;
            payload.groupNames?.sort((a: CodeValue, b: CodeValue) => {
                return a.value < b.value ? -1 : 1;
            });
            return {
                ...state,
                apiCostCategoryGroupNames: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_COST_CATEGORY_LINE_ITEMS: {
            const payload: IApiCostCategoryLineItems = action.payload as IApiCostCategoryLineItems;
            payload.lineItems?.sort((a: CodeValue, b: CodeValue) => {
                return a.value < b.value ? -1 : 1;
            });
            return {
                ...state,
                apiCostCategoryLineItems: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_COST_CATEGORY_LINE_ITEM_DETAILS: {
            const payload: IApiCostCategoryLineItemDetails = action.payload as IApiCostCategoryLineItemDetails;
            payload.lineItemDetails?.sort((a: CodeValue, b: CodeValue) => {
                return a.value < b.value ? -1 : 1;
            });
            return {
                ...state,
                apiCostCategoryLineItemDetails: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_SAVE_COST_CATEGORY_CONFIG: {
            const payload: IApiSaveCostCategoryConfiguration = action.payload as IApiSaveCostCategoryConfiguration;
            return {
                ...state,
                apiSaveCostCategoryConfiguration: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_DELETE_COST_CATEGORY_CONFIG: {
            const payload: IApiDeleteCostCategoryConfiguration = action.payload as IApiDeleteCostCategoryConfiguration;
            return {
                ...state,
                apiDeleteCostCategoryConfiguration: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.EDIT_COST_CATEGORY_CONFIGURATION: {
            return {
                ...state,
                costCategoryConfigurationToEdit: action.costCategoryConfigurationToEdit
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.DELETE_COST_CATEGORY_CONFIGURATION: {
            return {
                ...state,
                costCategoryConfigurationToDelete: action.costCategoryConfigurationToDelete
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_COST_CATEGORY_CONFIGURATION_FOR_GL: {
            const payload: IApiCostCategoryConfigurationForGlAccount = action.payload as IApiCostCategoryConfigurationForGlAccount;
            return {
                ...state,
                apiCostCategoryConfigurationForGlAccount: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        case actionTypes.API_PRIOR_YEAR_ACCRUAL_UPLOAD_MANUAL_POSTINGS: {
            const payload: IApiPyaUploadManualPostings = action.payload as IApiPyaUploadManualPostings;
            return {
                ...state,
                apiPyaUploadManualPostings: { ...payload }
            } as IPriorYearAccrualPageReducerState;
        }
        default:
    }

    return state;
};
