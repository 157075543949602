import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    comboBox: {
        width: '170px'
    } as IStyle,
    displayName: {
        whiteSpace: 'nowrap'
    } as IStyle,
    optionSeparator: {
        height: '4px'
    } as IStyle,
    comboBoxOption: {
        padding: '2px 0 2px 0'
    } as IStyle,
    userPhoto: {
        height: '38px'
    } as IStyle,
    userPhotoNotFoundIcon: {
        fontSize: '38px',
        lineHeight: '38px'
    } as IStyle
});
