import React, { useCallback, useEffect, useState } from 'react';
import {
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import {
    IApiIsAgentAccessRequestApprover,
    IApiLoadAccessRequestsForAgent,
    IApiLoadAccessRequestsForFinanceController,
    callApiIsAgentAccessRequestApprover,
    callApiLoadAccessRequestsForAgent,
    callApiLoadAccessRequestsForFinanceController
} from '../../store/actions/pageActions/accessRequestPage.action';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { CallApiState } from '../../store/actions/generic.action';
import { appConstants } from '../../common/appConstants';
import { pageStyles } from './AccessRequestPage.styles';
import { AccessRequest } from '../../models/user/accessRequest';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { CompanyCodeSelection } from '../../components/CompanyCodeSelection/CompanyCodeSelection';
import { HierarchySelection } from '../../components/HierarchySelection/HierarchySelection';
import { SupplierSelection } from '../../components/SupplierSelection/SupplierSelection';
import { ApproveRejectButtons } from './ApproveRejectButtons';
import { tooltips } from '../../common/tooltips';
import { commonString } from '../../common/commonString';
import { ErrorBar, clearErrorByIndex } from '../../components/ErrorBar/ErrorBar';

interface IApprovalTabProps {
}

export const ApprovalTab: React.FunctionComponent<IApprovalTabProps> = (props: IApprovalTabProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    
    // Redux store selectors to get state from the store when it changes.
    const apiIsAgentAccessRequestApprover: IApiIsAgentAccessRequestApprover =
        useAppSelector<IApiIsAgentAccessRequestApprover>(state => state.accessRequestPageReducer.apiIsAgentAccessRequestApprover);
    const apiLoadAccessRequestsForAgent: IApiLoadAccessRequestsForAgent =
        useAppSelector<IApiLoadAccessRequestsForAgent>(state => state.accessRequestPageReducer.apiLoadAccessRequestsForAgent);
    const apiLoadAccessRequestsForFinanceController: IApiLoadAccessRequestsForFinanceController =
        useAppSelector<IApiLoadAccessRequestsForFinanceController>(state => state.accessRequestPageReducer.apiLoadAccessRequestsForFinanceController);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when API call to check if user is agent access request approver completed.
     */
    useEffect(() => {
        // If the call to check if the user is an agent access request approver is completed and...
        // The user is an agent access request approver and...
        // If the api call to load access requests for agent has already been called,
        // then no need to call it again. If not yet called then call it now.
        if (apiIsAgentAccessRequestApprover.callApiState === CallApiState.Completed &&
            apiIsAgentAccessRequestApprover.isAgentAccessRequestApprover &&
            apiLoadAccessRequestsForAgent.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadAccessRequestsForAgent());
        }
    }, [apiIsAgentAccessRequestApprover.callApiState, apiIsAgentAccessRequestApprover.isAgentAccessRequestApprover, apiLoadAccessRequestsForAgent.callApiState, dispatch]);

    /**
     * This effect is run once during component load.
     */
    useMountEffect(() => {
        // If the api call to check if the user is an agent access request approver has already been called,
        // then no need to call it again. If not yet called then call it now.
        if (apiIsAgentAccessRequestApprover.callApiState === CallApiState.Initial) {
            dispatch(callApiIsAgentAccessRequestApprover());
        }

        // If the api call to load access requests for finance controller has already been called,
        // then no need to call it again. If not yet called then call it now.
        if (apiLoadAccessRequestsForFinanceController.callApiState === CallApiState.Initial) {
            dispatch(callApiLoadAccessRequestsForFinanceController());
        }
    });

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiIsAgentAccessRequestApprover.errMsg) {
            handleError(apiIsAgentAccessRequestApprover.errMsg);
        }
        if (apiLoadAccessRequestsForAgent.errMsg) {
            handleError(apiLoadAccessRequestsForAgent.errMsg);
        }
        if (apiLoadAccessRequestsForFinanceController.errMsg) {
            handleError(apiLoadAccessRequestsForFinanceController.errMsg);
        }
    }, [apiIsAgentAccessRequestApprover.errMsg, apiLoadAccessRequestsForAgent.errMsg, apiLoadAccessRequestsForFinanceController.errMsg, handleError]);

    return (
        <>
            <Separator />

            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            {/* "Review Finance Controller Access Requests for Approval" section. */}
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant="mediumPlus" block className={commonStyles.sectionHeading} role="heading" aria-level={1}>Review Finance Controller Access Requests For Approval</Text>
                </Stack.Item>
                { apiLoadAccessRequestsForFinanceController.callApiState === CallApiState.Running && (
                    <Stack.Item className={commonStyles.sectionContent}>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Stack.Item>
                )}
                { apiLoadAccessRequestsForFinanceController.callApiState === CallApiState.Completed && (
                    <>
                        {(!apiLoadAccessRequestsForFinanceController.accessRequests || 
                        (apiLoadAccessRequestsForFinanceController.accessRequests && apiLoadAccessRequestsForFinanceController.accessRequests.length === 0)) && (
                            <Stack.Item className={commonStyles.sectionContent}>
                                <Text variant='medium'>No pending finance controller access requests found.</Text>
                            </Stack.Item>
                        )}
                        {apiLoadAccessRequestsForFinanceController.accessRequests && apiLoadAccessRequestsForFinanceController.accessRequests.length > 0 && (
                            <>
                                <Stack.Item className={commonStyles.sectionContent}>
                                    <Text variant='medium'>You have pending access requests for review. Use this page to approve or reject access requests for the finance controller role.</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <table className={pageStyles.requestTable}>
                                        <thead className="requestTableHead">
                                            <tr>
                                                <th>{commonString.requestor}</th>
                                                <th>{commonString.companyCodes}</th>
                                                <th>{commonString.hierarchy}</th>
                                                <th>{commonString.suppliers}</th>
                                                <th>{commonString.createdDate}</th>
                                                <th>{commonString.action}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="requestTableBody">
                                            {
                                                apiLoadAccessRequestsForFinanceController.accessRequests?.map((accessRequest: AccessRequest, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{accessRequest.requestorAlias}</td>
                                                            <td>
                                                                <div className={pageStyles.companyCodeSelectionContainer}>
                                                                    <CompanyCodeSelection
                                                                        showLabel={false}
                                                                        readonly={true}
                                                                        disabled={false}
                                                                        tooltip={tooltips.apViewRequestorsCompanyCodeSelections}
                                                                        selectedCompanyCodes={
                                                                            // If the companyCodes array was undefined or empty, then default it to allCompanyCodeValue.
                                                                            accessRequest.scope?.companyCodes && accessRequest.scope.companyCodes.length > 0 ?
                                                                            accessRequest.scope.companyCodes.map(c => Number(c)) : [appConstants.allCompanyCodeValue]
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <HierarchySelection
                                                                    showLabel={false}
                                                                    readonly={true}
                                                                    disabled={false}
                                                                    tooltip={tooltips.apViewRequestorsFinanceControllerSelections}
                                                                    financeGeographySalesDistrictCodes={accessRequest.scope?.financeGeographySalesDistrictCodes || []}
                                                                    channelFunctionDetailCodes={accessRequest.scope?.channelFunctionDetailCodes || []}
                                                                    executiveFunctionDetailCodes={accessRequest.scope?.executiveFunctionDetailCodes || []}
                                                                    showGeography={true}
                                                                    showWarningIfBothChannelAndExecSelected={true}
                                                                />
                                                            </td>
                                                            <td>
                                                                <SupplierSelection
                                                                    showLabel={false}
                                                                    readonly={true}
                                                                    disabled={false}
                                                                    tooltip={tooltips.apViewRequestorsSupplierSelections}
                                                                    selectedSuppliers={accessRequest.scope?.suppliers || []}
                                                                />
                                                            </td>
                                                            <td>{formatDateUsingLocale(accessRequest.createdDate)}</td>
                                                            <td>
                                                                <ApproveRejectButtons
                                                                    accessRequest={accessRequest}
                                                                    handleError={(errMsg) => {
                                                                        handleError(errMsg);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </Stack.Item>
                            </>
                        )}
                    </>
                )}
            </Stack>

            <Separator />

            {/* "Review Agent Access Requests for Approval" section. */}
            <Stack tokens={stackTokensNormalGap}>
                { apiIsAgentAccessRequestApprover.callApiState === CallApiState.Running && (
                    <Stack.Item>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </Stack.Item>
                )}
                {apiIsAgentAccessRequestApprover.callApiState === CallApiState.Completed && apiIsAgentAccessRequestApprover.isAgentAccessRequestApprover && (
                    <>
                        <Stack.Item>
                            <Text variant="mediumPlus" block className={commonStyles.sectionHeading} role="heading" aria-level={2}>Review Agent Access Requests For Approval</Text>
                        </Stack.Item>

                        { apiLoadAccessRequestsForAgent.callApiState === CallApiState.Running && (
                            <Stack.Item>
                                <Text variant='mediumPlus'>Loading...</Text>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                            </Stack.Item>
                        )}

                        { apiLoadAccessRequestsForAgent.callApiState === CallApiState.Completed && (
                            <>
                                {(!apiLoadAccessRequestsForAgent.accessRequests || 
                                (apiLoadAccessRequestsForAgent.accessRequests && apiLoadAccessRequestsForAgent.accessRequests.length === 0)) && (
                                    <Stack.Item className={commonStyles.sectionContent}>
                                        <Text variant='medium'>No pending agent access requests found.</Text>
                                    </Stack.Item>
                                )}
                                {apiLoadAccessRequestsForAgent.accessRequests && apiLoadAccessRequestsForAgent.accessRequests.length > 0 && (
                                    <>
                                        <Stack.Item className={commonStyles.sectionContent}>
                                            <Text variant='medium'>You are an agent access request approver (member of security group &apos;Receipting Access Request Approvers&apos;) and can use this page to approve or reject access requests for the agent role.</Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <table className={pageStyles.requestTable}>
                                                <thead className="requestTableHead">
                                                    <tr>
                                                        <th>{commonString.requestor}</th>
                                                        <th>{commonString.businessJustification}</th>
                                                        <th>{commonString.createdDate}</th>
                                                        <th>{commonString.action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="requestTableBody">
                                                    {
                                                        apiLoadAccessRequestsForAgent.accessRequests?.map((accessRequest: AccessRequest, index: number) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{accessRequest.requestorAlias}</td>
                                                                    <td>{accessRequest.businessJustification}</td>
                                                                    <td>{formatDateUsingLocale(accessRequest.createdDate)}</td>
                                                                    <td>
                                                                        <ApproveRejectButtons
                                                                            accessRequest={accessRequest}
                                                                            handleError={(errMsg) => {
                                                                                handleError(errMsg);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </Stack.Item>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Stack>
        </>
    );
};
