import React from 'react';
import {
    Text,
    FontIcon,
    Stack
} from '@fluentui/react';
import { PurchaseOrderCheckResult } from '../../models/purchaseOrder/purchaseOrderCheckResult';
import { componentStyles } from './PoVisibilityCheckResults.styles';
import { stackTokensNormalGap } from '../../common/common.styles';

/**
 * PO visibility check props.
 */
export interface IPoVisibilityCheckProps {
    purchaseOrderNumber: string;
    results: PurchaseOrderCheckResult[];
}

/**
 * Reorder the check results so failed checks are shown first on the top of the list.
 * @param results Purchase order check results.
 * @returns Reordered purchase order check results.
 */
const reorder = (results: PurchaseOrderCheckResult[]): PurchaseOrderCheckResult[] => {
    const reorderedResults: PurchaseOrderCheckResult[] = [];
    reorderedResults.push(...results.filter(x => {
        if (!x.result) {
            return x;
        }
        return undefined;
    }));
    reorderedResults.push(...results.filter(x => {
        if (x.result) {
            return x;
        }
        return undefined;
    }));

    return reorderedResults;
};

/**
 * Component that displays PO visibility check results.
 */
export const PoVisibilityCheckResults: React.FunctionComponent<IPoVisibilityCheckProps> = (props: IPoVisibilityCheckProps): JSX.Element => {
    return (
        <Stack tokens={stackTokensNormalGap}>
            <Stack.Item>
                <Text>
                    No results were found for PO {props.purchaseOrderNumber}.
                    There could be several reasons why. Review the table below to help find the reason.
                </Text>
            </Stack.Item>
            <Stack.Item>
                <table cellPadding={'4px'} className={componentStyles.resultTable}>
                    <tbody>
                        <tr>
                            <th>{/* Placeholder for status column. */}</th>
                            <th>Check result</th>
                            <th>Recommended action</th>
                        </tr>
                        {reorder(props.results).map((x, index) => {
                            return (
                                <tr key={index}>
                                    <td className={componentStyles.statusCell}>
                                        {x.result && (
                                            <div className={componentStyles.checkPass}>
                                                <FontIcon iconName='StatusCircleCheckmark' />
                                            </div>
                                        )}
                                        {!x.result && (
                                            <div className={componentStyles.checkFail}>
                                                <FontIcon iconName='StatusCircleErrorX' />
                                            </div>
                                        )}
                                    </td>
                                    <td>{x.message}</td>
                                    <td>{x.recommendedAction}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Stack.Item>
        </Stack>
    );                               
};
