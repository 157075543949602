import { CodeValue } from './codeValue';
import { PriorYearAccrualStatus } from './priorYearAccrualStatus';

export enum PriorYearAccrualSearchRole {
    User = 'User',
    FinanceController = 'FinanceController',
    AccrualAdmin = 'AccrualAdmin',
    Agent = 'Agent'
}

export enum ApprovalStatus {
	PendingReview = 0,
    NoOrNotApproved = 1,
	YesOrApproved = 2
}

export interface IPriorYearAccrualSearch {
    purchaseOrderPlusOptionalLine: string | undefined;
    poOwner: string | undefined;
    fiscalYears: string[] | undefined;
    vendorName: string | undefined;
    vendorNumber: string | undefined;
    companyCodes: number[] | undefined;
    delegatedBy: string[] | undefined;
    includeOwnPo: boolean | undefined;
    statuses: PriorYearAccrualStatus[] | undefined;
    corpApproval: ApprovalStatus | undefined,
    stcApproval: ApprovalStatus | undefined,
    poePodAvailability: ApprovalStatus | undefined,
    regions: string[] | undefined;
    staticGLSubclasses: CodeValue[] | undefined;
    channelFunctionDetailCodes: string[] | undefined;
    executiveFunctionDetailCodes: string[] | undefined;
    priorYearAccrualSearchRole: PriorYearAccrualSearchRole | undefined;
    recordsPerPage: number | undefined;
    startPage: number | undefined;
}

export class PriorYearAccrualSearch implements IPriorYearAccrualSearch {
    public purchaseOrderPlusOptionalLine: string | undefined;
    public poOwner: string | undefined;
    public fiscalYears: string[] | undefined;
    public vendorName: string | undefined;
    public vendorNumber: string | undefined;
    public companyCodes: number[] | undefined;
    public delegatedBy: string[] | undefined;
    public includeOwnPo: boolean | undefined;
    public statuses: PriorYearAccrualStatus[] | undefined;
    public corpApproval: ApprovalStatus | undefined;
    public stcApproval: ApprovalStatus | undefined;
    public poePodAvailability: ApprovalStatus | undefined;
    public regions: string[] | undefined;
    public staticGLSubclasses: CodeValue[] | undefined;
    public channelFunctionDetailCodes: string[] | undefined;
    public executiveFunctionDetailCodes: string[] | undefined;
    public priorYearAccrualSearchRole: PriorYearAccrualSearchRole | undefined;
    public recordsPerPage: number | undefined;
    public startPage: number | undefined;

    constructor(
        purchaseOrderPlusOptionalLine: string | undefined,
        poOwner: string | undefined,
        fiscalYears: string[] | undefined,
        vendorName: string | undefined,
        vendorNumber: string | undefined,
        companyCodes: number[] | undefined,
        delegatedBy: string[] | undefined,
        includeOwnPo: boolean | undefined,
        statuses: PriorYearAccrualStatus[] | undefined,
        corpApproval: ApprovalStatus | undefined,
        stcApproval: ApprovalStatus | undefined,
        poePodAvailability: ApprovalStatus | undefined,
        regions: string[] | undefined,
        staticGLSubclasses: CodeValue[] | undefined,
        channelFunctionDetailCodes: string[] | undefined,
        executiveFunctionDetailCodes: string[] | undefined,
        priorYearAccrualSearchRole: PriorYearAccrualSearchRole | undefined,
        recordsPerPage: number | undefined,
        startPage: number | undefined
    ) {
        this.purchaseOrderPlusOptionalLine = purchaseOrderPlusOptionalLine;
        this.poOwner = poOwner;
        this.fiscalYears = fiscalYears;
        this.vendorName = vendorName;
        this.vendorNumber = vendorNumber;
        this.companyCodes = companyCodes;
        this.delegatedBy = delegatedBy;
        this.includeOwnPo = includeOwnPo;
        this.statuses = statuses;
        this.corpApproval = corpApproval;
        this.stcApproval = stcApproval;
        this.poePodAvailability = poePodAvailability;
        this.regions = regions;
        this.staticGLSubclasses = staticGLSubclasses;
        this.channelFunctionDetailCodes = channelFunctionDetailCodes;
        this.executiveFunctionDetailCodes = executiveFunctionDetailCodes;
        this.priorYearAccrualSearchRole = priorYearAccrualSearchRole;
        this.recordsPerPage = recordsPerPage;
        this.startPage = startPage;
    }
}
