/**
 * Directly models the server side ErrorDetail object. If that one changes, then this one must change.
 */
export interface IErrorDetail {
    purchaseOrderNumber?: string;
    lineItemNumber?: string;
    errorMessage?: string;
}

export class ErrorDetail implements IErrorDetail {
    public purchaseOrderNumber?: string;
    public lineItemNumber?: string;
    public errorMessage?: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IErrorDetail) {
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.lineItemNumber = jsonData.lineItemNumber;
        this.errorMessage = jsonData.errorMessage;
    }
}
