import { AnyAction } from 'redux';
import { TileSet } from '../../../models/dashboard/tileSet';
import { ViewAsRoleOptionKey } from '../../../pages/HomePage/homePageConstants';
import * as actionTypes from '../actionTypes';

// The home page also makes use of search.action.tsx and search.reducer.tsx for common search
// functionality used across multiple pages.

/**
 * Action creator: Change tile set.
 * @param tileSet Tile set.
 * @param viewAsRoleOptionKey View as role key.
 * @returns Redux action.
 */
export const changeTileSet = (tileSet: TileSet, viewAsRoleOptionKey: ViewAsRoleOptionKey): AnyAction => {
    return {
        type: actionTypes.CHANGE_TILESET,
        tileSet: tileSet,
        viewAsRoleOptionKey: viewAsRoleOptionKey
    } as AnyAction;
};
