import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    filterTextField: {
        minWidth: '170px'
    } as IStyle,
    notEqual: {
        color: '#e50000'
    } as IStyle,
    filterButton: {
        margin: '29px 0 0 0'
    } as IStyle,
    refreshButton: {
        margin: '29px 0 0 0'
    } as IStyle,
    journalEntryDetailsIndent: {
        display: 'table-cell',
        width: '90px'
    } as IStyle,
    accrualFailureBottomBorderBar: {
        borderBottom: '6px solid lightgray'
    } as IStyle,
    journalEntryDetails: {
        display: 'table-cell'
    } as IStyle,
    excelExportImportButton: {
        marginTop: '0px',
        verticalAlign: 'middle'
    } as IStyle,
    excelIcon: {
        fontSize: 16
    } as IStyle,
    exportButtonText: {
        margin: '0 0 0 10px'
    } as IStyle
});
