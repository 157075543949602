import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { ProfitCenterCodeMapping } from '../../models/priorYearAccrual/profitCenterCodeMapping';
import { reduxStore } from '../../store/reduxStore';
import { profitCenterCodeMappingSortOnColumn } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';

const columnSortHandler = (column: IColumn) => {
    reduxStore.dispatch(profitCenterCodeMappingSortOnColumn(column.key));
}

export const profitCenterColumn: ICustomColumn = {
    key: 'profitCenterColumn',
    fieldName: 'pc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Profit Center', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Profit'
                headerLine2='Center'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.pc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const profitCenterDescColumn: ICustomColumn = {
    key: 'profitCenterDescColumn',
    fieldName: 'pcDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Profit Center Description', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Profit Center'
                headerLine2='Description'
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.pcDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const execOrgSummaryDescColumn: ICustomColumn = {
    key: 'execOrgSummaryDescColumn',
    fieldName: 'execOrgSummaryDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Exec Org Summary', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Exec Org'
                headerLine2='Summary'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.execOrgSummaryDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const execOrgSummaryCodeColumn: ICustomColumn = {
    key: 'execOrgSummaryCodeColumn',
    fieldName: 'execOrgSummaryCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Exec Org Summary Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Exec Org'
                headerLine2='Summary Code'
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.execOrgSummaryCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const execOrgDescColumn: ICustomColumn = {
    key: 'execOrgDescColumn',
    fieldName: 'execOrgDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Exec Org', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Exec'
                headerLine2='Org'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.execOrgDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const execOrgCodeColumn: ICustomColumn = {
    key: 'execOrgCodeColumn',
    fieldName: 'execOrgCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Exec Org Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Exec Org'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.execOrgCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const channelOrgSummaryDescColumn: ICustomColumn = {
    key: 'channelOrgSummaryDescColumn',
    fieldName: 'channelOrgSummaryDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Channel Org Summary', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Channel Org'
                headerLine2='Summary'
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.channelOrgSummaryDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const channelOrgSummaryCodeColumn: ICustomColumn = {
    key: 'channelOrgSummaryCodeColumn',
    fieldName: 'channelOrgSummaryCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Channel Org Summary Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Channel Org'
                headerLine2='Summary Code'
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.channelOrgSummaryCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const channelOrgDescColumn: ICustomColumn = {
    key: 'channelOrgDescColumn',
    fieldName: 'channelOrgDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Channel Org', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Channel'
                headerLine2='Org'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.channelOrgDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const channelOrgCodeColumn: ICustomColumn = {
    key: 'channelOrgCodeColumn',
    fieldName: 'channelOrgCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Channel Org Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Channel'
                headerLine2='Org Code'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.channelOrgCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const orgDetailDescColumn: ICustomColumn = {
    key: 'orgDetailDescColumn',
    fieldName: 'orgDetailDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Org Detail', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Org'
                headerLine2='Detail'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.orgDetailDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const orgDetailCodeColumn: ICustomColumn = {
    key: 'orgDetailCodeColumn',
    fieldName: 'orgDetailCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Org Detail Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Org'
                headerLine2='Detail Code'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.orgDetailCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const funcSummaryDescColumn: ICustomColumn = {
    key: 'funcSummaryDescColumn',
    fieldName: 'funcSummaryDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Function Summary', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Function'
                headerLine2='Summary'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.funcSummaryDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const funcDescColumn: ICustomColumn = {
    key: 'funcDescColumn',
    fieldName: 'funcDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Function', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Function'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.funcDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const funcDetailDescColumn: ICustomColumn = {
    key: 'funcDetailDescColumn',
    fieldName: 'funcDetailDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Function Detail', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Function'
                headerLine2='Detail'
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.funcDetailDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const salesLocationDescColumn: ICustomColumn = {
    key: 'salesLocationDescColumn',
    fieldName: 'salesLocationDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Sales Location', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Sales'
                headerLine2='Location'
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.salesLocationDesc}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const salesLocationCodeColumn: ICustomColumn = {
    key: 'salesLocationCodeColumn',
    fieldName: 'salesLocationCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Sales Location Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Sales Location'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: ProfitCenterCodeMapping) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.salesLocationCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const profitCenterCodeMappingColumns: IColumn[] = [
    profitCenterColumn,
    profitCenterDescColumn,
    execOrgSummaryDescColumn,
    execOrgSummaryCodeColumn,
    execOrgDescColumn,
    execOrgCodeColumn,
    channelOrgSummaryDescColumn,
    channelOrgSummaryCodeColumn,
    channelOrgDescColumn,
    channelOrgCodeColumn,
    orgDetailDescColumn,
    orgDetailCodeColumn,
    funcSummaryDescColumn,
    funcDescColumn,
    funcDetailDescColumn,
    salesLocationDescColumn,
    salesLocationCodeColumn
];
