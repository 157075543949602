import React, { useState, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import {
    Breadcrumb,
    ConstrainMode,
    DetailsListLayoutMode,
    FontIcon,
    IBreadcrumbItem,
    IColumn,
    IDetailsHeaderProps,
    Link,
    PrimaryButton,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text
} from '@fluentui/react';
import { ICommonPageProps } from '../../common/common.types';
import { useNavigate, useParams } from 'react-router-dom';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { commonStyles, stackTokensNormalGap, stackTokensSmallGap } from '../../common/common.styles';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import {
    callApiBulkSavePurchaseOrders,
    clearEditPageData,
    goodsLineItemsSortOnColumn,
    IApiBulkSavePurchaseOrders,
    IEditPageData,
    servicesLineItemsSortOnColumn,
    storeEditPageData,
    updateSortedGoodsLineItems,
    updateSortedServicesLineItems
} from '../../store/actions/pageActions/editPage.action';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CallApiState } from '../../store/actions/generic.action';
import { PurchaseOrderSearchResults } from '../../models/purchaseOrder/purchaseOrderSearchResults';
import { PurchaseOrderDetails } from '../../models/purchaseOrder/purchaseOrderDetails';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { PoType, Role, SearchOperation, SearchOperationParameter, Source } from '../../common/appEnums';
import { numberAsLocaleString, trimLeadingZeros } from '../../common/common.func.transform';
import { formatDateUsingLocale, isJulyFirstPst } from '../../common/common.func.datetime';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { goodsLineItemColumns } from './goodsLineItemColumns';
import { servicesLineItemColumns } from './servicesLineItemColumns';
import { callApiSearchForPo, IApiSearchForPo } from '../../store/actions/search.action';
import { UserProfile } from '../../models/user/userProfile';
import { ISearchReducer } from '../../store/reducers/search.reducer';
import { pageStyles } from './EditPage.styles';
import { appConstants } from '../../common/appConstants';
import { IColumnsAndItems, onCustomRenderRow, resetColumnSorting, sortOnColumn } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { AppDispatch } from '../../store/reduxStore';
import { LineItemDetailsPanel } from '../../components/LineItemDetailsPanel/LineItemDetailsPanel';
import { ErrorDetail } from '../../models/purchaseOrder/errorDetail';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { reloadCountdown } from '../../common/common.func.general';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { teachingBubbleClearArray } from '../../store/actions/app.action';
import { PoVisibilityCheckResults } from '../../components/PoVisibilityCheckResults/PoVisibilityCheckResults';
import { commonString } from '../../common/commonString';
import { LineItemAuditPanel } from '../../components/LineItemAuditPanel/LineItemAuditPanel';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { MiniSearchResults } from './MiniSearchResults';
import { ImportExport } from '../../components/ImportExport/ImportExport';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { LineItemShipmentInfoPanel } from '../../components/LineItemShipmentInfoPanel/LineItemShipmentInfoPanel';
import { PurchaseOrderSearchMetaData } from '../../models/purchaseOrder/purchaseOrderSearchMetaData';
import { PurchaseOrderSearchFilter } from '../../models/purchaseOrder/purchaseOrderSearchFilter';
import { tooltips } from '../../common/tooltips';
import { useMountEffect } from '../../common/hooks/useMountEffect';

interface IPageProps extends ICommonPageProps {
}

/**
 * Edit page to view or edit the PO. The route to this page is like: /Edit/0080095894
 * where the number is the PO number. This page can be linked to directly, or navigated to from the
 * search results page.
 * @param props Page props.
 * @returns JSX for the page.
 */
export const EditPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [breadCrumbItems, setBreadCrumbItems] = useState<IBreadcrumbItem[]>();
    const [completedMsg, setCompletedMsg] = useState<string>('');
    const [sortableGoodsColumns, setSortableGoodsColumns] = useState<IColumn[]>(goodsLineItemColumns); // Local state for goods columns. Needed for sorting.
    const [sortableServicesColumns, setSortableServicesColumns] = useState<IColumn[]>(servicesLineItemColumns); // Local state for services columns. Needed for sorting.
    const [displayErrorDialog, { toggle: toggleDisplayErrorDialog }] = useBoolean(false);
    const poNumber = useRef<string>(''); 

    // Redux store selectors to get state from the store when it changes.
    const apiSearchForPo: IApiSearchForPo =
        useAppSelector<IApiSearchForPo>((state) => state.searchReducer.apiSearchForPo);
    const searchReducer: ISearchReducer =
        useAppSelector<ISearchReducer>((state) => state.searchReducer);
    const editPageData: IEditPageData =
        useAppSelector<IEditPageData>((state) => state.editPageReducer.editPageData);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);
    const apiBulkSavePurchaseOrders: IApiBulkSavePurchaseOrders =
        useAppSelector<IApiBulkSavePurchaseOrders>((state) => state.editPageReducer.apiBulkSavePurchaseOrders);
    const goodsLineItemsSortUsingColumnKey: string | undefined =
        useAppSelector<string | undefined>((state) => state.editPageReducer.goodsLineItemsSortUsingColumnKey);
    const servicesLineItemsSortUsingColumnKey: string | undefined =
        useAppSelector<string | undefined>((state) => state.editPageReducer.servicesLineItemsSortUsingColumnKey);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const params = useParams();
    const navigate = useNavigate();

    const [, forceRender] = useReducer((s) => s + 1, 0);

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Make breadcrumbs.
     */
    const makeBreadCrumbs = useCallback(() => {
        // If there is no purchaseOrderSearch in searchReducer then the user got to this page not
        // through the search results page. This happens if the user:
        // - Searched for a PO directly from the home page or header search.
        // - Directly links to this page.
        // - F5 refreshes this page.
        // Don't show the breadcrumb for the search results page in this case.
        let showSearchResultsPage: boolean = true;
        if (!searchReducer.purchaseOrderHeaders || searchReducer.purchaseOrderHeaders.length === 0) {
            showSearchResultsPage = false;
        }

        const items: IBreadcrumbItem[] = [];
        items.push({
            text: 'Home',
            key: 'Home',
            onClick: () => {
                navigate(`${appConstants.publicUrl}/Home`);
            }
        });
        if (showSearchResultsPage) {
            items.push({
                text: 'Search Results',
                key: 'SearchResults',
                onClick: () => {
                    navigate(`${appConstants.publicUrl}/SearchResults`);
                }
            });
        }
        items.push({
            text: 'Edit PO',
            key: 'EditPo',
            isCurrentItem: true
        });

        setBreadCrumbItems(items);
    }, [navigate, searchReducer.purchaseOrderHeaders]);

    /**
     * Load purchase order. Called by initial load as well as when refreshing after save.
     */
    const loadPurchaseOrder = useCallback(() => {
        // Clear any previous edit page data.
        dispatch(clearEditPageData());

        const searchAsRole: Role | undefined = userProfile?.getSearchAsRole();

        dispatch(callApiSearchForPo(
            poNumber.current,
            Source.SAP,
            SearchOperation.Search,
            SearchOperationParameter.None,
            true,
            PoType.All,
            searchAsRole,
            !searchReducer.inputFilter.driSearchEnabled,
            false // Do not store the last search and results (that should only be done when called from the home page).
        ));
    }, [dispatch, poNumber, searchReducer.inputFilter.driSearchEnabled, userProfile]);

    /**
     * Memoized field to check if the visiblity check result should be displayed.
     */
    const displayVisibilityCheckResult = useMemo<boolean>(() => {
        return searchReducer.purchaseOrderCheckResults !== undefined && searchReducer.purchaseOrderCheckResults.length > 0;
    }, [searchReducer.purchaseOrderCheckResults]);

    /**
     * Memoized field to check if the mini search should be displayed.
     */
    const showMiniSearch = useMemo<boolean>(() => {
        if (!searchReducer.purchaseOrderHeaders || searchReducer.purchaseOrderHeaders.length === 0) {
            return false;
        }
        return true;
    }, [searchReducer.purchaseOrderHeaders]);

    /**
     * Effect for when params.poNumber changes.
     */
    useEffect(() => {
        let po: string = params.poNumber || '';
        po = po.trim();
        if (po) {
            // If this edit page is already loaded, only if the po number changes should the call to
            // load purchase order happen. This effect has a dependency on makeBreadCrumbs which has a
            // dependency on searchReducer.purchaseOrderHeaders which means this effect will be called
            // whenever the headers array changes. This happens when the mini search results pagination
            // is used. So this check will prevent reloading the PO if it didn't change.
            if (poNumber.current !== po) {
                poNumber.current = po;
                loadPurchaseOrder();
            }
        } else {
            navigate(appConstants.publicUrl);
        }

        makeBreadCrumbs();
    }, [loadPurchaseOrder, makeBreadCrumbs, navigate, params.poNumber]);

    /**
     * Effect for when apiSearchForPo returns data.
     */
    useEffect(() => {
        if (apiSearchForPo.callApiState === CallApiState.DataAvailable) {
            const searchResults: PurchaseOrderSearchResults | null | undefined = apiSearchForPo.purchaseOrderSearchResults;
            if (searchResults && searchResults?.results && searchResults?.results.length > 0) {
                const poDetails: PurchaseOrderDetails = searchResults.results[0];
                if (poDetails) {
                    dispatch(
                        storeEditPageData(
                            poDetails.header,
                            poDetails.items?.filter(x => x.isAsset) || [],
                            poDetails.items?.filter(x => !x.isAsset) || []
                        )
                    );
                }
            }
        }
    }, [apiSearchForPo.callApiState, apiSearchForPo.purchaseOrderSearchResults, dispatch]);

    /**
     * Effect for when apiBulkSavePurchaseOrders returns data.
     */
    useEffect(() => {
        if (apiBulkSavePurchaseOrders.callApiState === CallApiState.DataAvailable) {
            // If there were any errors, display them in an error dialog.
            if (apiBulkSavePurchaseOrders.bulkUpdateResult?.errorDetails && apiBulkSavePurchaseOrders.bulkUpdateResult?.errorDetails.length > 0) {
                toggleDisplayErrorDialog();
            } else {
                // Refresh data after a countdown interval.
                reloadCountdown(
                    (msg) => setCompletedMsg(msg), 
                    () => {
                        setCompletedMsg('');
                        loadPurchaseOrder();
                    }
                );
            }
        }
    }, [apiBulkSavePurchaseOrders.bulkUpdateResult?.errorDetails, apiBulkSavePurchaseOrders.callApiState, loadPurchaseOrder, params.poNumber, toggleDisplayErrorDialog]);

    /**
     * Memoized field for all line items (goods and services combined).
     */
    const allLineItems = useMemo(() => {
        const allItems: PurchaseOrderLineItem[] = [
            ...(editPageData.goodsPurchaseOrderLineItems || []),
            ...(editPageData.servicesPurchaseOrderLineItems || [])
        ];
        return allItems.sort((a: PurchaseOrderLineItem, b: PurchaseOrderLineItem) => {
            if (Number(a.purchaseOrderLineNumber) < Number(b.purchaseOrderLineNumber)) {
                return -1;
            }
            return 1;
        });
    }, [editPageData.goodsPurchaseOrderLineItems, editPageData.servicesPurchaseOrderLineItems]);

    /**
     * Gets dirty line count.
     * @returns Count of dirty lines.
     */
    const dirtyItemCount = useMemo<number>((): number => {
        let count: number = 0;

        allLineItems.forEach(item => {
            if (item.isDirty) {
                count++;
            }
        });

        return count;
    }, [allLineItems]);

    /**
     * Memoized check if any line has an error. 
     */
    const anyLineHasError = useMemo<boolean>((): boolean => {
        if (editPageData.lineItemsWithError && editPageData.lineItemsWithError.length > 0) {
            return true;
        }
        return false;
    }, [editPageData.lineItemsWithError]);

    /**
     * Gets invalid line count.
     * @returns Count of invalid lines.
     */
    const invalidItemCount = useMemo<number>((): number => {
        let count: number = 0;

        allLineItems.forEach(item => {
            if (!item.isLineItemValid()) {
                count++;
            }
        });

        return count;
    }, [allLineItems]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Reset column sorting and set columns.
     */
    const resetColumnSortingAndSetColumns = useCallback(() => {
        // Dispatch an action to not sort on any column for goods and services line items.
        dispatch(goodsLineItemsSortOnColumn(undefined));
        dispatch(servicesLineItemsSortOnColumn(undefined));
        setSortableGoodsColumns(resetColumnSorting([...goodsLineItemColumns]));
        setSortableServicesColumns(resetColumnSorting([...servicesLineItemColumns]));
    }, [dispatch]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        resetColumnSortingAndSetColumns();
    });

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiSearchForPo.errMsg) {
            handleError(apiSearchForPo.errMsg);
        }
        if (apiBulkSavePurchaseOrders.errMsg) {
            handleError(apiBulkSavePurchaseOrders.errMsg);
        }
    }, [apiBulkSavePurchaseOrders.errMsg, apiSearchForPo.errMsg, handleError]);

    /**
     * Effect for when goods line items sort on column changes.
     */
    useEffect(() => {
        if (goodsLineItemsSortUsingColumnKey) {
            // Create a new columns array here.
            const columns: IColumn[] = goodsLineItemColumns;
            const column: IColumn | undefined = columns.find(x => x.key === goodsLineItemsSortUsingColumnKey);
            if (column) {
                const columnsAndItems: IColumnsAndItems<PurchaseOrderLineItem> = sortOnColumn<PurchaseOrderLineItem>(
                    column,
                    columns,
                    editPageData.goodsPurchaseOrderLineItems || []
                );
                // Cannot change the prior year accrual data in Redux state here, need to dispatch so it changes in the store.
                dispatch(updateSortedGoodsLineItems(columnsAndItems.items));
                setSortableGoodsColumns(columnsAndItems.columns);
            }
        }
    }, [dispatch, editPageData.goodsPurchaseOrderLineItems, goodsLineItemsSortUsingColumnKey]);

    /**
     * Effect for when services line items sort on column changes.
     */
    useEffect(() => {
        if (servicesLineItemsSortUsingColumnKey) {
            // Create a new columns array here.
            const columns: IColumn[] = servicesLineItemColumns;
            const column: IColumn | undefined = columns.find(x => x.key === servicesLineItemsSortUsingColumnKey);
            if (column) {
                const columnsAndItems: IColumnsAndItems<PurchaseOrderLineItem> = sortOnColumn<PurchaseOrderLineItem>(
                    column,
                    columns,
                    editPageData.servicesPurchaseOrderLineItems || []
                );
                // Cannot change the prior year accrual data in Redux state here, need to dispatch so it changes in the store.
                dispatch(updateSortedServicesLineItems(columnsAndItems.items));
                setSortableServicesColumns(columnsAndItems.columns);
            }
        }
    }, [dispatch, editPageData.servicesPurchaseOrderLineItems, servicesLineItemsSortUsingColumnKey]);
    
    /**
     * Render read only field.
     * @param fieldName Field name.
     * @param fieldValue Field value.
     * @returns JSX for read only field.
     */
    const renderReadOnlyField = (fieldName: string, fieldValue: string | number | undefined): JSX.Element => {
        return (
            <>
                <Text variant='mediumPlus' block style={{ fontWeight: 600 }}>{fieldName}</Text>
                <Text variant='mediumPlus' block>{fieldValue}</Text>
            </>
        );
    };

    /**
     * Memoized helper to check if the search api is running.
     * @returns True or false.
     */
    const isSearchRunning = useMemo<boolean>(() => {
        if (apiSearchForPo.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSearchForPo.callApiState]);

    /**
     * Memoized helper to check if the save api is running.
     */
    const isSaveRunning = useMemo<boolean>(() => {
        if (apiBulkSavePurchaseOrders.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiBulkSavePurchaseOrders.callApiState]);

    /**
     * Memoized helper to check if Goods PO line items data is present.
     * @returns True or false.
     */
    const isGoodsLineItemsPresent = useMemo<boolean>(() => {
        if (editPageData.goodsPurchaseOrderLineItems === undefined ||
            editPageData.goodsPurchaseOrderLineItems?.length === 0) {
            return false;
        } else {
            return true;
        }
    }, [editPageData.goodsPurchaseOrderLineItems]);

    /**
     * Memoized helper to check if Services PO line items data is present.
     * @returns True or false.
     */
    const isServicesLineItemsPresent = useMemo<boolean>(() => {
        if (editPageData.servicesPurchaseOrderLineItems === undefined ||
            editPageData.servicesPurchaseOrderLineItems?.length === 0) {
            return false;
        } else {
            return true;
        }
    }, [editPageData.servicesPurchaseOrderLineItems]);

    /**
     * Close line link clicked event handler.
     */
    const closeLineItemsLinkClicked = () => {
        if (editPageData.purchaseOrderHeader) {
            navigate(`${appConstants.publicUrl}/CloseLine/${editPageData.purchaseOrderHeader.purchaseOrderNumber}`);
        }
    };

    /**
     * MyOrder link clicked event handler.
     */
    const myOrderLinkClicked = () => {
        if (editPageData.purchaseOrderHeader?.purchaseOrderNumber) {
            window.open(`https://myorder.microsoft.com/#/change-order/requisition/${trimLeadingZeros(editPageData.purchaseOrderHeader.purchaseOrderNumber)}`, '_blank');
        }
    };

    /**
     * MSInvoice link clicked event handler.
     */
    const msInvoiceLinkClicked = () => {
        if (editPageData.purchaseOrderHeader?.purchaseOrderNumber) {
            window.open(`https://einvoice.microsoft.com/Reporting/PODetails.aspx?PONumber=${trimLeadingZeros(editPageData.purchaseOrderHeader.purchaseOrderNumber)}`, '_blank');
        }
    };

    /**
     * ProcureWeb link clicked event handler.
     */
    const procureWebLinkClicked = () => {
        if (editPageData.purchaseOrderHeader?.purchaseOrderNumber) {
            window.open(`https://procureweb.microsoft.com/order-overview/myorder/${trimLeadingZeros(editPageData.purchaseOrderHeader.purchaseOrderNumber)}`, '_blank');
        } 
    };

    /**
     * Save button clicked event handler.
     */
    const saveButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.editPoSaveButtonClicked });

        const toBeSavedItems: PurchaseOrderLineItem[] = [];

        // Determine what line items to save based on dirty flag.
        for (let i: number = 0; i < allLineItems.length; i++) {
            if (allLineItems[i].isDirty) {
                toBeSavedItems.push(allLineItems[i]);
            }
        }

        dispatch(callApiBulkSavePurchaseOrders(toBeSavedItems));
    };

    return (
        <>
            <PageWrapper 
                {...props}
                onScroll={
                    // Force render is needed to reposition any teaching bubbles that may be present.
                    // They will not reposition themselves during scrolling to the elements they are anchored to.
                    ()=> forceRender()
                }
            >
                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <Stack tokens={stackTokensNormalGap}>
                    {breadCrumbItems && (
                        <Breadcrumb
                            items={breadCrumbItems}
                            ariaLabel="Site navigation"
                        />
                    )}

                    { isJulyFirstPst() && isServicesLineItemsPresent && (
                        // Display notice if it is July 1st in PST and there are services line items.
                        // Reusing the same string shown in the teaching bubble for service line items.
                        <div className={pageStyles.notice}>{tooltips.receiptDateJulyFirst}</div>
                    )}

                    <SectionWrapper>
                        <Section>
                            <Stack horizontal tokens={stackTokensSmallGap}>
                                {showMiniSearch && (
                                    <MiniSearchResults />
                                )}
                                <Stack.Item className={pageStyles.poContainer}>
                                    <Stack tokens={stackTokensNormalGap}>
                                        { isSearchRunning && (
                                            <Stack.Item>
                                                <Text variant='mediumPlus'>Loading...</Text>
                                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                            </Stack.Item>
                                        )}
                                        { !isSearchRunning && (
                                            <>
                                                {displayVisibilityCheckResult && (
                                                    // Use params.poNumber rather than searchReducer.purchaseOrderSearch?.filter?.purchaseOrderNumber
                                                    // as the search reducer will not have the filter data populated (the storeLastSearchAndResults has
                                                    // false in the callApiSearchForPo call).
                                                    <PoVisibilityCheckResults
                                                        purchaseOrderNumber={params.poNumber || commonString.missingPoNumber}
                                                        results={searchReducer.purchaseOrderCheckResults!}
                                                    />
                                                )}

                                                {!displayVisibilityCheckResult && (
                                                    <>
                                                        {!editPageData.purchaseOrderHeader && (
                                                            <Stack.Item>
                                                                No purchase order data found.
                                                            </Stack.Item>
                                                        )}
                                                        {editPageData.purchaseOrderHeader && (
                                                            <>
                                                                <Stack.Item>
                                                                    <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                                        <Stack.Item grow>
                                                                            <Text variant='large' className={commonStyles.sectionHeading} role="heading" aria-level={1}>
                                                                                {editPageData.purchaseOrderHeader?.purchaseOrderNumber} - {editPageData.purchaseOrderHeader?.projectName}
                                                                            </Text>
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            <div>
                                                                                <Link onClick={closeLineItemsLinkClicked} role='link'>Close Line Items</Link>
                                                                            </div>
                                                                            <div>
                                                                                <Text>View PO on </Text>
                                                                                <Link onClick={myOrderLinkClicked} role='link'>
                                                                                    MyOrder
                                                                                    <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                                                                                </Link>
                                                                                <Text> / </Text>
                                                                                <Link onClick={msInvoiceLinkClicked} role='link'>
                                                                                    MS Invoice
                                                                                    <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                                                                                </Link>
                                                                                <Text> / </Text>
                                                                                <Link onClick={procureWebLinkClicked} role='link'>
                                                                                    ProcureWeb
                                                                                    <FontIcon iconName="OpenInNewWindow" className={commonStyles.linkIcon} />
                                                                                </Link>
                                                                            </div>
                                                                        </Stack.Item>
                                                                    </Stack>
                                                                    <Separator />
                                                                </Stack.Item>

                                                                <Stack.Item>
                                                                    <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={2}>
                                                                        PO Details
                                                                    </Text>
                                                                </Stack.Item>
                                                                <Stack.Item>
                                                                    <Stack horizontal wrap tokens={{ childrenGap: 26 }}>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Supplier', editPageData.purchaseOrderHeader?.supplierNameNumberCombo) }
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Company Code', editPageData.purchaseOrderHeader?.companyCode) }
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Open Amount', `${numberAsLocaleString(editPageData.purchaseOrderHeader?.openAmount || 0, 2)} ${editPageData.purchaseOrderHeader?.poLocalCurr}`) }
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Owner', editPageData.purchaseOrderHeader?.purchaseOrderRequestor) }
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Approver', editPageData.purchaseOrderHeader?.purchaseOrderApprover) }
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Invoice Approver', editPageData.purchaseOrderHeader?.invoiceApprover) }
                                                                        </Stack.Item>
                                                                        <Stack.Item>
                                                                            { renderReadOnlyField('Create Date', editPageData.purchaseOrderHeader?.poCreateDate ? formatDateUsingLocale(editPageData.purchaseOrderHeader?.poCreateDate) : '') }
                                                                        </Stack.Item>
                                                                    </Stack>
                                                                    <Separator />
                                                                </Stack.Item>
                                                                {
                                                                    // Display goods line items.
                                                                    isGoodsLineItemsPresent && (
                                                                        <Stack.Item>
                                                                            <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={2}>
                                                                                Goods Line Items
                                                                            </Text>
                                                                            <CustomDetailsList
                                                                                id="goodsLineItems"
                                                                                ariaLabelForGrid="Line Items"
                                                                                displayTotalItems={false}
                                                                                showPaginator={false}
                                                                                showPageSize={false}
                                                                                items={editPageData.goodsPurchaseOrderLineItems || []}
                                                                                isLoading={isSearchRunning}
                                                                                compact={false}
                                                                                columns={sortableGoodsColumns}
                                                                                selectionMode={SelectionMode.none}
                                                                                getKey={(item: PurchaseOrderLineItem) => item.clientRowKey!}
                                                                                setKey="none"
                                                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                                                isHeaderVisible={true}
                                                                                constrainMode={ConstrainMode.horizontalConstrained}
                                                                                onRenderRow={onCustomRenderRow}
                                                                                useScrollablePane={true}
                                                                                onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                                                                    if (detailsHeaderProps) {
                                                                                        return (
                                                                                            <Sticky stickyPosition={StickyPositionType.Header}>
                                                                                                {defaultRender!({ ...detailsHeaderProps })}
                                                                                            </Sticky>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Stack.Item>
                                                                    )
                                                                }
                                                                {
                                                                    // Display services line items.
                                                                    isServicesLineItemsPresent && (
                                                                        <Stack.Item>
                                                                            <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={2}>
                                                                                Services Line Items
                                                                            </Text>
                                                                            <CustomDetailsList
                                                                                id="servicesLineItems"
                                                                                ariaLabelForGrid="Line Items"
                                                                                displayTotalItems={false}
                                                                                showPaginator={false}
                                                                                showPageSize={false}
                                                                                items={editPageData.servicesPurchaseOrderLineItems || []}
                                                                                isLoading={isSearchRunning}
                                                                                compact={false}
                                                                                columns={sortableServicesColumns}
                                                                                selectionMode={SelectionMode.none}
                                                                                getKey={(item: PurchaseOrderLineItem) => item.clientRowKey!}
                                                                                setKey="none"
                                                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                                                isHeaderVisible={true}
                                                                                constrainMode={ConstrainMode.horizontalConstrained}
                                                                                onRenderRow={onCustomRenderRow}
                                                                                useScrollablePane={true}
                                                                                onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                                                                    if (detailsHeaderProps) {
                                                                                        return (
                                                                                            <Sticky stickyPosition={StickyPositionType.Header}>
                                                                                                {defaultRender!({ ...detailsHeaderProps })}
                                                                                            </Sticky>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Stack.Item>
                                                                    )
                                                                }

                                                                <Stack.Item>
                                                                    <PrimaryButton
                                                                        onClick={saveButtonClicked}
                                                                        disabled={dirtyItemCount === 0 || anyLineHasError || invalidItemCount > 0 || isSearchRunning || isSaveRunning || displayErrorDialog || completedMsg.length > 0}
                                                                    >
                                                                        {isSaveRunning && (
                                                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                                        )}
                                                                        {!isSaveRunning && (
                                                                            <span>Save</span>
                                                                        )}
                                                                    </PrimaryButton>
                                                                </Stack.Item>
                                                                <Stack.Item>
                                                                    {!isSaveRunning && dirtyItemCount > 0 && !completedMsg && !displayErrorDialog && (
                                                                        // If save is not running, and there are dirty items, and not displaying the save completed
                                                                        // message, and not displaying the error dialog, then display the modified line count.
                                                                        <Text>{dirtyItemCount} line items modified</Text>
                                                                    )}
                                                                    {!isSaveRunning && completedMsg && (
                                                                        // If save is not running, and completed msg exists, then display the message.
                                                                        <Text>{completedMsg}</Text>
                                                                    )}
                                                                </Stack.Item>
                                                                { invalidItemCount > 0 && allLineItems.filter(x => !x.isLineItemValid === false && x.getInvalidLineReasons().length > 0).length > 0 && (
                                                                    // If there are invalid line items, and there are invalid reasons present, display that information.
                                                                    // Some lines can be invalid but no reason string is in the invalid reasons array, such as an asset tag
                                                                    // or serial number that is not supplied or fails a regex check. In these cases, the field itself will display
                                                                    // an error indicator under the field. This section is for special invalid reasons such as certain cases
                                                                    // used with service line item receipt date validity checks.
                                                                    <>                                 
                                                                        <Stack.Item>
                                                                            Line items invalid:
                                                                        </Stack.Item>
                                                                        {
                                                                            allLineItems.filter(x => !x.isLineItemValid === false && x.getInvalidLineReasons().length > 0).map((purchaseOrderLineItem: PurchaseOrderLineItem, index: number) => {
                                                                                return (
                                                                                    <Stack.Item key={index}>
                                                                                        <Text>{purchaseOrderLineItem.purchaseOrderLineNumber}</Text>
                                                                                        <ul className={pageStyles.invalidList}>
                                                                                            {
                                                                                                purchaseOrderLineItem.getInvalidLineReasons().map((reason: string, reasonIndex: number) => {
                                                                                                    return (
                                                                                                        <li key={reasonIndex}>
                                                                                                            <Text>
                                                                                                                {reason}
                                                                                                            </Text>
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </Stack.Item>
                                                                                );
                                                                            })
                                                                        }
                                                                    </>
                                                                )}

                                                                {/* If there were save errors, display that information in a dialog. */}
                                                                <GenericDialog
                                                                    displayDialog={displayErrorDialog}
                                                                    title="An error occurred while saving"
                                                                    content={
                                                                        <ul>
                                                                            {
                                                                                apiBulkSavePurchaseOrders.bulkUpdateResult?.errorDetails.map((errorDetail: ErrorDetail, index: number) => {
                                                                                    return (
                                                                                        <li key={index}>{errorDetail.lineItemNumber} - {errorDetail.errorMessage}</li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    }
                                                                    mode={GenericDialogMode.Ok}
                                                                    onOkClicked={() => {
                                                                        toggleDisplayErrorDialog();
                                                                        loadPurchaseOrder();
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Stack>
                                </Stack.Item>
                            </Stack>

                            {!isSearchRunning && !displayVisibilityCheckResult && editPageData.purchaseOrderHeader && (
                                <>
                                    <Separator />

                                    <ImportExport
                                        message="You can export this PO to an Excel file for bulk editing. You can make bulk updates for goods and services line items and import the Excel file."
                                        handleError={(error: string) => {
                                            handleError(error);
                                        }}
                                        purchaseOrderSearch={() => {
                                            const purchaseOrderSearch: PurchaseOrderSearch = new PurchaseOrderSearch(
                                                new PurchaseOrderSearchMetaData({
                                                    // None of these actually matter for this search used for export.
                                                    totalRecords: -1,
                                                    recordsPerPage: 10,
                                                    currentPageIndex: 0
                                                }),
                                                new PurchaseOrderSearchFilter({
                                                    // Set the PO number in the filter to be the PO being edited.
                                                    purchaseOrderNumber: editPageData!.purchaseOrderHeader!.purchaseOrderNumber,
                                                    includeReceived: true,
                                                    includeGsrCor: 'Both',
                                                    // Include the current user and any delegates in the search. This is not used by Agent
                                                    // or Finance Controller based searches and is ignored in those cases.
                                                    selectedUsers: [...(userProfile?.getUnexpiredDelegateFromUsers() || []), userProfile!.alias]
                                                })
                                            );
                                            return purchaseOrderSearch;
                                        }}
                                        disabled={isSearchRunning}
                                        showSearchCriteriaWarning={false}
                                    />
                                </>
                            )}
                        </Section>
                    </SectionWrapper>
                </Stack>

            </PageWrapper>

            <LineItemDetailsPanel />
            <LineItemAuditPanel />
            <LineItemShipmentInfoPanel />
        </>
    );
};
