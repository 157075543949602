import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    comboBox: {
        width: '220px'
    } as IStyle,
    textField: {
        minWidth: '170px'
    } as IStyle,
    wideTextField: {
        width: '100%'
    } as IStyle,
    grReversalResultHeading: {
        marginBottom: '6px'
    } as IStyle,
    grReversalResultTable: {
        borderCollapse: 'collapse',
        'th,td': {
            padding: '6px',
            border: '1px solid lightgray'
        } as IStyle
    } as IStyle
});
