import { ErrorModel } from '../error/errorModel';
import { SapLookupType } from './sapLookupType';

/**
 * Represents a SAP lookup payload. This is the data that is sent to the server to lookup SAP data.
 */
export interface ISapLookupData {
    sapLookupBy: SapLookupType;
    purchaseOrder?: string;
    invoiceNumber?: string;
    companyCode?: string;
    fiscalYear?: string;
    errors?: ErrorModel[];
}

export class SapLookupData implements ISapLookupData {
    public sapLookupBy: SapLookupType;
    public purchaseOrder?: string;
    public invoiceNumber?: string;
    public companyCode?: string;
    public fiscalYear?: string;
    public errors?: ErrorModel[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ISapLookupData) {
        this.sapLookupBy = jsonData.sapLookupBy;
        this.purchaseOrder = jsonData.purchaseOrder;
        this.invoiceNumber = jsonData.invoiceNumber;
        this.companyCode = jsonData.companyCode;
        this.fiscalYear = jsonData.fiscalYear;

        if (jsonData.errors && this.errors) {
            for (let i: number = 0; i < jsonData.errors.length; i++) {
                this.errors.push(new ErrorModel(jsonData.errors[i]));
            }
        }
    }
}
