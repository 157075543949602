export interface IAssetInformation {
    assetTag: string;
    serialNumber: string;
    itemRecipient: string;
}

export class AssetInformation implements IAssetInformation {
    public assetTag: string;
    public serialNumber: string;
    public itemRecipient: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IAssetInformation) {
        this.assetTag = jsonData.assetTag;
        this.serialNumber = jsonData.serialNumber;
        this.itemRecipient = jsonData.itemRecipient;
    }
}
