import { Area } from './area';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IAreaSummary extends IHierarchyNodeCommon {
    area: Area[];
}

export class AreaSummary implements IAreaSummary {
    public typeName: string = HierarchyConstants.className.geography.areaSummary;
    public code: string;
    public description: string;
    public area: Area[] = [];
    public children?: IHierarchyNodeCommon[] = this.area; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IAreaSummary) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.area) {
            for (let i: number = 0; i < jsonData.area.length; i++) {
                this.area.push(new Area(jsonData.area[i]));
            }
        }
    }
}
