import { AnyAction } from 'redux';
import { AppDispatch } from '../reduxStore';

/**
 * Used with callApi to indicate state of the call.
 */
export enum CallApiState {
    /**
     * Initial state.
     * State will move to Running next.
     */
    Initial,

    /**
     * Call running.
     * State will move to DataAvailable or Failed next.
     */
    Running,

    /**
     * Call completed successfully and data is avaialble.
     * State will move to Completed next.
     */
    DataAvailable,

    /**
     * Call completed. 
     * Final resting state for successful call after DataAvailable.
     */
    Completed,

    /**
     * Call failed.
     * Final resting state for failed call.
     */
    Failed
}

/**
 * To be used as a base for an action payload.
 */
export interface ICallApiBase {
    /**
     * Call api action state.
     */
    callApiState: CallApiState;

    /**
     * Error message.
     */
    errMsg?: string;
}

/**
 * A generic action to call an api to load data.
 * The action payload should be an interface deriving from ICallApiBase.
 * @param actionType Action type string. Something from actionTypes.ts.
 * @param asyncCall Async callback that can be used to call an api or apis which returns a promise of type T.
 *   The dispatch function is also passed to this callback so it can make use of it if needed to dispatch other actions.
 * @param afterDataCallback Optional. Called after data retrieved. Payload and data is passed which can then me modified by the caller.
 *   The dispatch function is also passed to this callback so it can make use of it if needed to dispatch other actions.
 * @returns Dispatch thunk function used with React Redux. See https://redux.js.org/usage/writing-logic-thunks
 */
export function callApi<T>(actionType: string, asyncCall: (dispatch: AppDispatch) => Promise<T> | null, afterDataCallback?: (payload: ICallApiBase, data: T | null, dispatch: AppDispatch) => void): (dispatch: AppDispatch) => Promise<void> {
    return async (dispatch: AppDispatch) => {
        // Set up initial action data and payload.
        const action: AnyAction = {
            type: actionType
        }
        const payload: ICallApiBase = {
            callApiState: CallApiState.Running,
            errMsg: undefined
        }
        action.payload = payload;

        // Dispatch for the CallApiState.Running state.
        dispatch(action);

        try {
            // Invoke the async call (api).
            // Note that the dispatch function is passed to this callback. The callback code can then make use of this
            // dispatch function to dispatch any other actions.
            const data: T | null = await asyncCall(dispatch);

            // Call the after data callback and pass the payload and data.
            // The caller can attach the data to the payload, and also may add additional properties to the payload.
            if (afterDataCallback) {
                // Note that the dispatch function is passed to this callback. The callback code can then make use of this
                // dispatch function to dispatch any other actions.
                afterDataCallback(action.payload, data, dispatch);
            }

            // Dispatch for the CallApiState.DataAvailable state.
            action.payload.callApiState = CallApiState.DataAvailable;
            dispatch(action);

            // Dispatch for the CallApiState.Completed state.
            // Setting the state to completed is important as this will set the state in the reducer to this "resting" state
            // now that the call is done.
            action.payload.callApiState = CallApiState.Completed;
            setTimeout(() => {
                // Call this dispatch in a setTimeout, otherwise if it was called right after any prior dispatch,
                // such as above dispatch for CallApiState.DataAvailable, it would replace it, as only the
                // final dispatch for an action is processed in one render pass. If an effect in any component wanted
                // to handle CallApiState.DataAvailable then that dispatch above must be sent before this one.
                dispatch(action);
            });
        } catch (err: any) {
            // Dispatch for the CallApiState.Failed state.
            action.payload.callApiState = CallApiState.Failed;
            action.payload.errMsg = err.message;
            dispatch(action);
        }
    }
}
