import React, { useMemo, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { editLineItemRecipientName, editLineItemRecipientNameHasError } from '../../store/actions/pageActions/editPage.action';
import { commonString } from '../../common/commonString';
import { validationConstants } from '../../common/validationConstants';
import { TextField, TooltipHost, Text, Link, FontIcon, TooltipDelay } from '@fluentui/react';
import { AppDispatch } from '../../store/reduxStore';
import { commonStyles } from '../../common/common.styles';
import { pageStyles } from './EditPage.styles';
import { tooltips } from '../../common/tooltips';

interface IRecipientNameInputProps {
    item: PurchaseOrderLineItem;
}

export const RecipientNameInput: React.FunctionComponent<IRecipientNameInputProps> = (props: IRecipientNameInputProps): JSX.Element => {
    const [errorMsg, setErrorMsg] = useState<string>('');

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Memoized helper to indicate if the prepopulated icon should be shown.
     */
    const showPrepopulatedIcon = useMemo<boolean>(() => {
        return !!props.item.isRecipientPrePopulatedBySupplier && !!props.item.requireAssetDataCollection;
    }, [props.item.isRecipientPrePopulatedBySupplier, props.item.requireAssetDataCollection]);

    return (
        <>
            { props.item.requireAssetDataCollection && (
                <>
                    {/*
                        Note that for this field we are using a standard TextField based input and not the GraphUserInput.
                        This is mainly because when editing a PO, especially if it has lots of line items, we don't want the
                        graph api calls to go out for every recipient alias for all the line items. Just not needed in this
                        case.
                    */}
                    <TooltipHost content={validationConstants.userAlias.tooltip} delay={TooltipDelay.long}>
                        <TextField
                            id={`recipientName-${props.item.purchaseOrderLineNumber}`}
                            autoComplete='off'
                            ariaLabel={`${commonString.recipientName} ${validationConstants.userAlias.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                            value={props.item.recipientName}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                newValue = newValue || '';
                                newValue = newValue.trim();
            
                                if (newValue.length > 0 &&
                                    (
                                        newValue?.length > validationConstants.userAlias.maxLength! ||
                                        !RegExp(validationConstants.userAlias.pattern!).test(newValue)
                                    )
                                ) {
                                    setErrorMsg(validationConstants.userAlias.errorMsg!);
                                    dispatch(editLineItemRecipientNameHasError(props.item.purchaseOrderLineNumber, true));
                                } else {
                                    setErrorMsg('');
                                    dispatch(editLineItemRecipientNameHasError(props.item.purchaseOrderLineNumber, false));
                                }
            
                                dispatch(editLineItemRecipientName(props.item.purchaseOrderLineNumber, newValue));
                            }}
                            onBlur={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                dispatch(editLineItemRecipientName(props.item.purchaseOrderLineNumber, props.item.recipientName || ''));
                            }}
                            errorMessage={
                                (((props.item.canReceive && props.item.received) || props.item.isUpdatingAssetDetails) && props.item.recipientName?.length === 0) ? 'Enter recipient alias' :
                                errorMsg ? errorMsg : ''
                            }
                            disabled={props.item.checkToDisableEditing()}
                            styles={{ root: { width: `calc(100% - ${showPrepopulatedIcon ? '14px' /* Leave a little room for the icon. */ : '0'})` } }}
                        />
                    </TooltipHost>

                    {showPrepopulatedIcon && (
                        <div className={pageStyles.prePopulatedIconContainer}>
                            <TooltipHost content={tooltips.recipientPrePopulatedBySupplier} delay={TooltipDelay.long}>
                                {/* Using a Link so the icon becomes focusable with keyboard navigation. */}
                                <Link aria-label={tooltips.recipientPrePopulatedBySupplier}>
                                    <FontIcon iconName='Info' className={commonStyles.pointer} />
                                </Link>
                            </TooltipHost>
                        </div>
                    )}
                </>
            )}
            { !props.item.requireAssetDataCollection && (
                <Text className={commonStyles.textCell}>
                    {commonString.notRequired}
                </Text>
            )}
        </>
    );
};
