import { ServiceAlert } from '../../models/utility/serviceAlert';
import { ApiClientBase, ApiService } from './apiClientBase';

/**
 * Web server api client.
 */
class WebServerApiClient extends ApiClientBase {
    /**
     * Constructor for the web server api client.
     */
    constructor() {
        super(ApiService.WebServer)
    }

    /**
     * Gets service alert file.
     * @returns Service alert object.
     */
    public async getServiceAlert(): Promise<ServiceAlert | null> {
        const apiUrl: string = `${this.publicFolder}/serviceAlert.json`;
        return await this.getObject<ServiceAlert>(ServiceAlert, apiUrl);
    }
}

export const webServerApiClient = new WebServerApiClient();
