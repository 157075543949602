import { SubRegion } from './subRegion';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IRegion extends IHierarchyNodeCommon {
    subRegion: SubRegion[];
}

export class Region implements IRegion {
    public typeName: string = HierarchyConstants.className.geography.region;
    public code: string;
    public description: string;
    public subRegion: SubRegion[] = [];
    public children?: IHierarchyNodeCommon[] = this.subRegion; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IRegion) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.subRegion) {
            for (let i: number = 0; i < jsonData.subRegion.length; i++) {
                this.subRegion.push(new SubRegion(jsonData.subRegion[i]));
            }
        }
    }
}
