/**
 * Purchase order line item pair.
 */
export class PurchaseOrderLineItemPair {
    public purchaseOrder: number;
    public lineItem: number;

    constructor(purchaseOrder: number, lineItem: number) {
        this.purchaseOrder = purchaseOrder;
        this.lineItem = lineItem;
    }
}
