import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { CustomTooltip } from './CustomTooltip';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { componentStyles } from './CustomPieChart.styles';

/**
 * Custom pie chart props.
 */
export interface ICustomPieChartProps {
    data: Payload<number, string>[];
}

/**
 * Custom pie chart.
 * @param props Data tile props.
 */
export const CustomPieChart: React.FunctionComponent<ICustomPieChartProps> = (props: ICustomPieChartProps): JSX.Element => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart
                width={400}
                height={400}
                margin={{ top: 0, bottom: 0, right: 0, left: 0 }}
                className={componentStyles.pieChart}
            >
                <Pie
                    data={props.data}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                >
                    {props.data.map((payload, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={payload.color}
                            aria-label={`${payload.value} ${payload.name} ${numberAsLocaleString(payload.payload?.percent, 2)}%`}
                        />
                    ))}
                </Pie>
                <Tooltip
                    allowEscapeViewBox={{ x: true, y: true }}
                    offset={20} // Default: 10
                    content={
                        <CustomTooltip
                            active={undefined}
                            payload={undefined}
                        />
                    }
                />
            </PieChart>
        </ResponsiveContainer>
    );
};
