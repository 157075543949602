import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    dialogContainer: {
        minHeight: 'auto'
    } as IStyle
});
