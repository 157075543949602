import { ExecOrg } from './execOrg';
import { IHierarchyNodeCommon } from '../hierarchyNodeCommon';
import { HierarchyConstants } from '../hierarchyConstants';

export interface IExecOrgSummary extends IHierarchyNodeCommon {
    execOrg: ExecOrg[];
}

export class ExecOrgSummary implements IExecOrgSummary {
    public typeName: string = HierarchyConstants.className.executive.execOrgSummary;
    public code: string;
    public description: string;
    public execOrg: ExecOrg[] = [];
    public children?: IHierarchyNodeCommon[] = this.execOrg; // Part of IHierarchyNodeCommon, self reference to child array.

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IExecOrgSummary) {
        this.code = jsonData.code;
        this.description = jsonData.description;

        if (jsonData.execOrg) {
            for (let i: number = 0; i < jsonData.execOrg.length; i++) {
                this.execOrg.push(new ExecOrg(jsonData.execOrg[i]));
            }
        }
    }
}
