import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import {
    ComboBox,
    IComboBoxOption,
    Stack,
    Label,
    IComboBox,
    DefaultButton,
    Separator,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    IDetailsHeaderProps,
    Sticky,
    StickyPositionType,
    Pivot,
    PivotItem,
    Spinner,
    Text,
    Link,
    FontIcon,
    SpinnerSize
} from '@fluentui/react';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppDispatch } from '../../store/reduxStore';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import {
    callApiShipmentFileProcessingStatus,
    callApiShipmentUploadShipmentDetails,
    IApiShipmentFileProcessingStatus,
    IApiShipmentUploadShipmentDetails
} from '../../store/actions/pageActions/shipmentAdminPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { all, PivotItemKey, shipmentUploadFileStatusOptions, supplierOptions } from './shipmentAdminPageConstants';
import { commonString } from '../../common/commonString';
import { pageStyles } from './ShipmentAdminPage.styles';
import { ShipmentUploadFileStatus } from '../../models/shipment/shipmentUploadFileStatus';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ShipmentFileInfo } from '../../models/shipment/shipmentFileInfo';
import { onCustomRenderRow } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { shipmentFileColumns } from './shipmentFileColumns';
import { Organization } from '../../models/shipment/organization';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';

interface IPageProps extends ICommonPageProps {
}

export const ShipmentAdminPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const shipmentFileUploadStatusInputId: string = useId();
    const supplierInputId: string = useId();
    const [filterShipmentUploadFileStatus, setFilterShipmentUploadFileStatus] = useState<ShipmentUploadFileStatus | undefined>(undefined);
    const [selectedSupplier, setSelectedSupplier] = useState<Organization>(Organization.Dell);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(10);
    const [autoSearch, setAutoSearch] = useState<boolean>(true);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadStatusMsg, setUploadStatusMsg] = useState<string>('');

    // For the generic dialog.
    const [displayGenericDialog, { toggle: toggleDisplayGenericDialog }] = useBoolean(false);
    const [genericDialogTitle, setGenericDialogTitle] = useState<string>('');
    const [genericDialogMsg, setGenericDialogMsg] = useState<string>('');
    const [genericDialogCustomContent, setGenericDialogCustomContent] = useState<JSX.Element>();
    const callbackAfterGenericDialogCloseRef = useRef<() => void>();

    // Redux store selectors to get state from the store when it changes.
    const apiShipmentFileProcessingStatus: IApiShipmentFileProcessingStatus =
        useAppSelector<IApiShipmentFileProcessingStatus>((state) => state.shipmentAdminPageReducer.apiShipmentFileProcessingStatus);
    const apiShipmentUploadShipmentDetails: IApiShipmentUploadShipmentDetails =
        useAppSelector<IApiShipmentUploadShipmentDetails>((state) => state.shipmentAdminPageReducer.apiShipmentUploadShipmentDetails);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiShipmentFileProcessingStatus.errMsg) {
            handleError(apiShipmentFileProcessingStatus.errMsg);
        }
        if (apiShipmentUploadShipmentDetails.errMsg) {
            handleError(apiShipmentUploadShipmentDetails.errMsg);
        }
    }, [apiShipmentFileProcessingStatus.errMsg, apiShipmentUploadShipmentDetails.errMsg, handleError]);

    /**
     * Effect for when the page loads.
     */
    useEffect(() => {
        // The autoSearch state ensures we only call this once during page load. Not each time the dependencies change.
        if (autoSearch) {
            setAutoSearch(false);
            dispatch(callApiShipmentFileProcessingStatus(1, currentPageSize, filterShipmentUploadFileStatus));
        }
    }, [autoSearch, currentPageSize, dispatch, filterShipmentUploadFileStatus]);

    /**
     * Memoized value to determine if shipment statuses is loading.
     * @returns True if data is loading, otherwise false.
     */
    const isShipmentStatusesLoading = useMemo<boolean>((): boolean => {
        if (apiShipmentFileProcessingStatus.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiShipmentFileProcessingStatus.callApiState]);

    /**
     * Memoized value to determine if file is uploading.
     * @returns True if file is uploading, otherwise false.
    */
    const isFileUploading = useMemo<boolean>((): boolean => {
        if (apiShipmentUploadShipmentDetails.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiShipmentUploadShipmentDetails.callApiState]);

    /**
     * Filter button clicked event handler.
     */
    const filterButtonClicked = useCallback(() => {
        telemetryService.trackEvent({ name: trackedEvent.shipmentAdminFilterButtonClicked });

        const page: number = 1; // Reset the page to 1.
        setSelectedPage(page);

        dispatch(callApiShipmentFileProcessingStatus(page, currentPageSize, filterShipmentUploadFileStatus));
    }, [currentPageSize, dispatch, filterShipmentUploadFileStatus]);

    /**
     * Upload button clicked event handler.
     */
    const uploadButtonClicked = useCallback(() => {
        telemetryService.trackEvent({ name: trackedEvent.shipmentAdminUploadButtonClicked });

        // Trigger a click on the file input element.
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }

        // todo: Implement file upload. Pass selected supplier to the api. xxx
    }, []);
    
    /**
     * On upload input change handler.
     * @param event Form event. 
     */
    const onUploadInput = (event: React.FormEvent<HTMLInputElement>) => {
        const files = fileInputRef.current?.files;

        if (!files || files.length !== 1) {
            return;
        }

        const file: File = files[0];

        if (file.size > (2 * 1024 * 1024) /* 2mb */) {
            showGenericDialog(
                'File size too large',
                `This file is ${file.size} bytes.<br>Please make sure the file is less than 750k.`
            );
            return;
        }

        if (file.name.indexOf('.xlsx') < 0) {
            showGenericDialog(
                'Wrong file type',
                'File must be an Excel .xlsx file.'
            );
            return;
        }

        dispatch(callApiShipmentUploadShipmentDetails(file, selectedSupplier));
        setUploadStatusMsg('Uploading...');
    };

    /**
     * Effect to show upload status message.
     */
    useEffect(() => {
        // If the state is completed or failed, display a status message.
        if (apiShipmentUploadShipmentDetails.callApiState === CallApiState.Completed ||
            apiShipmentUploadShipmentDetails.callApiState === CallApiState.Failed) {
            if (apiShipmentUploadShipmentDetails.callApiState === CallApiState.Completed) {
                setUploadStatusMsg('Upload completed. Please view the Shipment Files tab for processing status.');
            } else if (apiShipmentUploadShipmentDetails.callApiState === CallApiState.Failed) {
                setUploadStatusMsg('Upload failed.');
            }
            // Clear the message after 5 seconds.
            setTimeout(() => {
                setUploadStatusMsg('');
            }, 5000);
        }
    }, [apiShipmentUploadShipmentDetails.callApiState]);

    /**
     * Show generic dialog.
     * @param title Dialog title.
     * @param msg Dialog message.
     * @param customContent Custom JSX content.
     * @param callback Callback to call after dialog closed.
     */
    const showGenericDialog = useCallback((title: string, msg: string, customContent?: JSX.Element, callback?: () => void) => {
        setGenericDialogTitle(title);
        setGenericDialogMsg(msg);
        setGenericDialogCustomContent(customContent);
        callbackAfterGenericDialogCloseRef.current = callback;
        toggleDisplayGenericDialog();
    }, [toggleDisplayGenericDialog]);

    /**
     * Page changed event handler.
     */
    const pageChanged = (page: number, pageSize: number) => {
        setSelectedPage(page);
        setCurrentPageSize(pageSize);

        dispatch(callApiShipmentFileProcessingStatus(page, pageSize, filterShipmentUploadFileStatus));
    };

    return (
        <>
            <PageWrapper {...props}>
                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <SectionWrapper>
                    <Section>
                        <Stack tokens={stackTokensNormalGap}>
                            <Stack.Item>
                                <Pivot>
                                    <PivotItem headerText="Shipment Files" itemKey={PivotItemKey.ShipmentFiles as string}>
                                        <Stack tokens={stackTokensNormalGap}>
                                            <Stack.Item>
                                                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                    <Stack.Item>
                                                        <Label htmlFor={shipmentFileUploadStatusInputId}>{commonString.shipmentUploadFileStatus}</Label>
                                                        <ComboBox
                                                            id={shipmentFileUploadStatusInputId}
                                                            ariaLabel={commonString.shipmentUploadFileStatus}
                                                            className={pageStyles.filterComboBox}
                                                            disabled={false}
                                                            multiSelect={false}
                                                            autoComplete='on' // Turn off autoComplete when multiSelect is true.
                                                            options={shipmentUploadFileStatusOptions}
                                                            selectedKey={filterShipmentUploadFileStatus ?? all}
                                                            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                                                                setFilterShipmentUploadFileStatus(option?.key === all ? undefined : option?.key as ShipmentUploadFileStatus);
                                                            }}
                                                            useComboBoxAsMenuWidth={true}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DefaultButton
                                                    onClick={filterButtonClicked}
                                                    text='Filter'
                                                    disabled={isShipmentStatusesLoading}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Separator />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <CustomDetailsList
                                                    id="shipmentFileDetailsList"
                                                    ariaLabelForGrid="Shipment files"
                                                    displayTotalItems={true}
                                                    showPaginator={
                                                        // Show paginator only if needed.
                                                        apiShipmentFileProcessingStatus.shipmentFileProcessingStatus?.data &&
                                                        apiShipmentFileProcessingStatus.shipmentFileProcessingStatus?.totalCount > currentPageSize ? true : false
                                                    }
                                                    showPageSize={true}
                                                    pageSizes={[10, 20, 50, 100]}
                                                    onPageSizeChange={pageSize => {
                                                        pageChanged(selectedPage, pageSize);
                                                    }}
                                                    selectedPage={selectedPage}
                                                    onSelectedPageChange={page => {
                                                        pageChanged(page, currentPageSize);
                                                    }}
                                                    totalItemsAtServer={apiShipmentFileProcessingStatus.shipmentFileProcessingStatus?.totalCount}
                                                    items={apiShipmentFileProcessingStatus.shipmentFileProcessingStatus?.data || []}
                                                    isLoading={isShipmentStatusesLoading}
                                                    compact={false}
                                                    columns={shipmentFileColumns}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={(item: ShipmentFileInfo) => item.clientRowKey!}
                                                    setKey="none"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    constrainMode={ConstrainMode.unconstrained}
                                                    onRenderRow={onCustomRenderRow}
                                                    showExcelExport={false}
                                                    useScrollablePane={true}
                                                    onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                                        if (detailsHeaderProps) {
                                                            return (
                                                                <Sticky stickyPosition={StickyPositionType.Header}>
                                                                    {defaultRender!({ ...detailsHeaderProps })}
                                                                </Sticky>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    </PivotItem>
                                    <PivotItem headerText="Upload" itemKey={PivotItemKey.Upload as string}>
                                        <Stack tokens={stackTokensNormalGap}>
                                            <Stack.Item>
                                                <p>
                                                    <Text variant='medium' block>
                                                        You can upload an Excel file with ASN (Advanced Shipment Notification) data from a supplier.
                                                    </Text>
                                                    <Text variant='medium' block>
                                                        File must be in a specific format and less than 2MB in size.
                                                        See <Link href='files/ShipmentAsnUpload.xlsx'>this file</Link> as an example.
                                                    </Text>
                                                    <Text variant='medium' block>
                                                        Select the supplier and click Upload File to choose a file to upload.
                                                    </Text>
                                                </p>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                                    <Stack.Item>
                                                        <Label htmlFor={supplierInputId}>{commonString.supplierName}</Label>
                                                        <ComboBox
                                                            id={supplierInputId}
                                                            ariaLabel={commonString.supplierName}
                                                            className={pageStyles.filterComboBox}
                                                            disabled={false}
                                                            multiSelect={false}
                                                            autoComplete='on' // Turn off autoComplete when multiSelect is true.
                                                            options={supplierOptions}
                                                            selectedKey={selectedSupplier ?? all}
                                                            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                                                                setSelectedSupplier(option?.key as Organization);
                                                            }}
                                                            useComboBoxAsMenuWidth={true}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DefaultButton
                                                    onClick={uploadButtonClicked}
                                                    disabled={isFileUploading}
                                                >
                                                    {isFileUploading && (
                                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                                    )}
                                                    {!isFileUploading && (
                                                        <>
                                                            <FontIcon iconName="ExcelDocument" className={pageStyles.excelIcon} />
                                                            <Text>Upload File</Text>
                                                        </>
                                                    )}
                                                </DefaultButton>
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                    title="import file"
                                                    className={pageStyles.hiddenInput}
                                                    onInput={onUploadInput}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Text>{uploadStatusMsg}</Text>
                                            </Stack.Item>
                                        </Stack>
                                    </PivotItem>
                                </Pivot>
                            </Stack.Item>
                        </Stack>
                    </Section>
                </SectionWrapper>
            </PageWrapper>

            <GenericDialog
                displayDialog={displayGenericDialog}
                title={genericDialogTitle}
                content={
                    <>
                        <Text block variant="medium">{genericDialogMsg}</Text>
                        {genericDialogCustomContent}
                    </>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayGenericDialog();
                    if (callbackAfterGenericDialogCloseRef.current) {
                        callbackAfterGenericDialogCloseRef.current();
                    }
                }}
            />
        </>
    );
};
