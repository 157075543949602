import { IChoiceGroupOption, IComboBoxOption } from '@fluentui/react';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { PurchaseOrderSearchFilter } from '../../models/purchaseOrder/purchaseOrderSearchFilter';
import { PurchaseOrderSearchMetaData } from '../../models/purchaseOrder/purchaseOrderSearchMetaData';

/**
 * Search results being displayed for one of these types of searches.
 */
export const enum SearchType {
    None,

    // Normal "For You" tiles.
    Open,
    DueThisMonth,
    Overdue,

    // Finance Controller tiles.
    FinanceControllerOpen,
    FinanceControllerDueThisMonth,
    FinanceControllerUnavailableOwner,
    FinanceControllerUnavailableApprover,
    FinanceControllerReceiptedByFinanceController,

    CustomSearch
}

export enum ViewAsRoleOptionKey {
    ForYou = 'FY',
    FinanceController = 'FC'
}

export const pageStrings = {
    yourPersonalizedSummary: 'Your personalized summary',
    customSearch: 'Custom search'
};

export const viewAsRoleOptions: IChoiceGroupOption[] = [
    // The ariaLabel includes the words "Your personalized summary" as heading text above. Accessibility requirement.
    { key: ViewAsRoleOptionKey.ForYou, text: 'For You', ariaLabel: `${pageStrings.yourPersonalizedSummary} - For You` },
    { key: ViewAsRoleOptionKey.FinanceController, text: 'Finance Controller', ariaLabel: `${pageStrings.yourPersonalizedSummary} - Finance Controller` }
];

export const purchaseOrderSearchInitial: PurchaseOrderSearch = new PurchaseOrderSearch(
    new PurchaseOrderSearchMetaData({
        totalRecords: -1,
        recordsPerPage: 10,
        currentPageIndex: 0
    }), new PurchaseOrderSearchFilter({})
);

export enum IncludeReceivedOptionKey {
    Yes = 'Yes',
    No = 'No'
}

export const filterIncludeReceivedOptions: IComboBoxOption[] = [
    {
        key: IncludeReceivedOptionKey.Yes,
        text: 'Yes'
    } as IComboBoxOption,
    {
        key: IncludeReceivedOptionKey.No,
        text: 'No'
    } as IComboBoxOption
];

export enum IncludeGoodsServicesOptionKey {
    Both = 'Both',
    Goods = 'Goods',
    Services = 'Services'
}

export const filterIncludeGoodsServicesOptions: IComboBoxOption[] = [
    {
        key: IncludeGoodsServicesOptionKey.Both,
        text: 'Both'
    } as IComboBoxOption,
    {
        key: IncludeGoodsServicesOptionKey.Goods,
        text: 'Goods'
    } as IComboBoxOption,
    {
        key: IncludeGoodsServicesOptionKey.Services,
        text: 'Services'
    } as IComboBoxOption
];
