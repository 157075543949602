import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import {
    IApiIsAgentAccessRequestApprover,
    IApiLoadAccessRequestsForAgent,
    IApiLoadAccessRequestsForFinanceController,
    IApiLoadFinanceControllerAccessRequestHistory,
    IApiSubmitAccessRequest
} from '../../actions/pageActions/accessRequestPage.action';
import { CallApiState } from '../../actions/generic.action';
import { AccessRequest } from '../../../models/user/accessRequest';

export interface IAccessRequestPageReducerState {
    apiSubmitAccessRequest: IApiSubmitAccessRequest;
    apiLoadFinanceControllerAccessRequestHistory: IApiLoadFinanceControllerAccessRequestHistory;
    apiLoadAccessRequestsForAgent: IApiLoadAccessRequestsForAgent;
    apiLoadAccessRequestsForFinanceController: IApiLoadAccessRequestsForFinanceController;
    apiIsAgentAccessRequestApprover: IApiIsAgentAccessRequestApprover;
}

const initialAccessRequestPageReducerState: IAccessRequestPageReducerState = {
    apiSubmitAccessRequest: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiLoadFinanceControllerAccessRequestHistory: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        accessRequests: undefined
    },
    apiLoadAccessRequestsForAgent: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        accessRequests: undefined
    },
    apiLoadAccessRequestsForFinanceController: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        accessRequests: undefined
    },
    apiIsAgentAccessRequestApprover: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        isAgentAccessRequestApprover: undefined
    }
};

export const accessRequestPageReducer : Reducer<IAccessRequestPageReducerState, AnyAction> = (
    state: IAccessRequestPageReducerState = initialAccessRequestPageReducerState, action: AnyAction
): IAccessRequestPageReducerState => {
    switch (action.type) { 
        case actionTypes.API_SUBMIT_ACCESS_REQUEST: {
            const payload: IApiSubmitAccessRequest = action.payload as IApiSubmitAccessRequest;
            return {
                ...state,
                apiSubmitAccessRequest: { ...payload }
            } as IAccessRequestPageReducerState;
        }
        case actionTypes.API_LOAD_FINANCE_CONTROLLER_ACCESS_REQUEST_HISTORY: {
            const payload: IApiLoadFinanceControllerAccessRequestHistory = action.payload as IApiLoadFinanceControllerAccessRequestHistory;
            if (payload && payload.accessRequests) {
                payload.accessRequests.sort((a: AccessRequest, b: AccessRequest) => {
                    // If createdDate was missing, then default to an early date.
                    if (!a.createdDate) {
                        a.createdDate = new Date('1900-01-01');
                    }
                    if (!b.createdDate) {
                        b.createdDate = new Date('1900-01-01');
                    }
                    // Sort the request history by latest created date descending.
                    if (a.createdDate! > b.createdDate!) {
                        return -1;
                    }
                    return 1;
                });
            }
            return {
                ...state,
                apiLoadFinanceControllerAccessRequestHistory: { ...payload }
            } as IAccessRequestPageReducerState;
        }
        case actionTypes.API_LOAD_ACCESS_REQUESTS_FOR_AGENT: {
            const payload: IApiLoadAccessRequestsForAgent = action.payload as IApiLoadAccessRequestsForAgent;
            return {
                ...state,
                apiLoadAccessRequestsForAgent: { ...payload }
            } as IAccessRequestPageReducerState;
        }
        case actionTypes.API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER: {
            const payload: IApiLoadAccessRequestsForFinanceController = action.payload as IApiLoadAccessRequestsForFinanceController;
            return {
                ...state,
                apiLoadAccessRequestsForFinanceController: { ...payload }
            } as IAccessRequestPageReducerState;
        }
        case actionTypes.API_IS_AGENT_ACCESS_REQUEST_APPROVER: {
            const payload: IApiIsAgentAccessRequestApprover = action.payload as IApiIsAgentAccessRequestApprover;
            return {
                ...state,
                apiIsAgentAccessRequestApprover: { ...payload }
            } as IAccessRequestPageReducerState;
        }
        case actionTypes.RESET_API_LOAD_ACCESS_REQUESTS_FOR_AGENT: {
            return {
                ...state,
                apiLoadAccessRequestsForAgent: {
                    callApiState: CallApiState.Initial,
                    errMsg: undefined,
                    accessRequests: undefined
                }
            } as IAccessRequestPageReducerState;
        }
        case actionTypes.RESET_API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER: {
            return {
                ...state,
                apiLoadAccessRequestsForFinanceController: {
                    callApiState: CallApiState.Initial,
                    errMsg: undefined,
                    accessRequests: undefined
                }
            } as IAccessRequestPageReducerState;
        }
        default:
    }

    return state;
};
