import React, { useMemo, useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import { POLinePriorYearAccrualActivity } from '../../models/accrualDashboard/poLinePriorYearAccrualActivity';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { commonStyles } from '../../common/common.styles';
import { useBoolean } from '@fluentui/react-hooks';
import { useAppSelector } from '../../store/hooks';
import { UserProfile } from '../../models/user/userProfile';

interface IPriorYearAccrualActionProps {
    poLinePriorYearAccrualActivity: POLinePriorYearAccrualActivity;
}

export const PriorYearAccrualAction: React.FunctionComponent<IPriorYearAccrualActionProps> = (props: IPriorYearAccrualActionProps): JSX.Element => {
    const [isActionRunning, setIsActionRunning] = useState<boolean>(false); // Flag for or any action.
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [showErrorDialog, { toggle: toggleShowErrorDialog }] = useBoolean(false);
    const [briefMsg, setBriefMsg] = useState<string>('');

    // Redux store selectors to get state from the store when it changes.
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    /**
     * Display a brief message on the button for 2 seconds.
     * @param msg Message to display.
     */
    const displayBriefMsg = (msg: string) => {
        setBriefMsg(msg);
        setTimeout(() => {
            setBriefMsg('');
        }, 2000);
    }

    /**
     * Requeue the prior year accrual activity.
     */
    const requeuePriorYearAccrualActivity = async () => {
        setIsActionRunning(true);

        // Not using Redux action to call this api, but rather using receiptingApiClient directly. The Redux/action approach
        // is good for when we would have one instance of one api running... where the state of that one call instance is
        // stored in Redux. While here we can click multiple instances of this button to requeue multiple items at a time.
        try {
            await receiptingApiClient.requeuePriorYearAccrualActivity(
                props.poLinePriorYearAccrualActivity.purchaseOrderNumber,
                props.poLinePriorYearAccrualActivity.purchaseOrderLineItem,
                props.poLinePriorYearAccrualActivity.transactionId
            );
            displayBriefMsg('Done');
        } catch (err: any) {
            setErrorMsg(JSON.stringify(err));
            toggleShowErrorDialog();
        }

        setIsActionRunning(false);
    }
    
    /**
     * Memoized value to determine if the retry option should be disabled.
     * Only allow retry if there is a JE with Failed.
     */
    const isRetryDisabled = useMemo<boolean>(() => {
        return props.poLinePriorYearAccrualActivity.journalEntries.filter(x => x.jeStatus === 'Failed').length === 0;
    }, [props.poLinePriorYearAccrualActivity.journalEntries])

    return (
        <>
            <DefaultButton
                menuProps={{
                    shouldFocusOnMount: true,
                    items: [
                        {
                            key: 'retry',
                            text: `Retry ${isRetryDisabled ? '(disabled, no failed activity)' : ''}`,
                            title: 'This will queue a message on the service bus for Prior Year Accrual Engine to reprocess the failed activity. Use this feature only for a transaction that is voided in JEM.',
                            onClick: () => {
                                requeuePriorYearAccrualActivity();
                            },
                            disabled: isRetryDisabled
                        }
                    ]
                }}
                disabled={isActionRunning || briefMsg !== '' || !userProfile?.isDri}
            >
                {!briefMsg && isActionRunning && (
                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                )}
                {!briefMsg && !isActionRunning && (
                    <span>Action</span>
                )}
                {briefMsg && (
                    <span>{briefMsg}</span>
                )}
            </DefaultButton>

            {/* Error dialog. */}
            <Dialog
                hidden={!showErrorDialog}
                onDismiss={toggleShowErrorDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Error',
                    subText: errorMsg
                }}
                modalProps={{
                    isBlocking: true
                }}
            >
                <DialogFooter>
                    <DefaultButton onClick={toggleShowErrorDialog} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
