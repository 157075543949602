export interface ICodeValue {
    code: string;
    value: string;
}

export class CodeValue implements ICodeValue {
    public code: string;
    public value: string;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ICodeValue) {
        this.code = jsonData.code;
        this.value = jsonData.value;
    }
}
