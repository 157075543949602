import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiPurchaseOrderCheck, IApiReverseGoodsReceipt } from '../../actions/pageActions/driToolsPage.action';

export interface IDriToolsPageReducerState {
    apiReverseGoodsReceipt: IApiReverseGoodsReceipt;
    apiPurchaseOrderCheck: IApiPurchaseOrderCheck
}

const initialDriToolsPageReducerState: IDriToolsPageReducerState = {
    apiReverseGoodsReceipt: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        reversePurchaseOrderReceipts: undefined
    },
    apiPurchaseOrderCheck: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        checkResults: undefined
    }
};

export const driToolsPageReducer: Reducer<IDriToolsPageReducerState, AnyAction> = (
    state: IDriToolsPageReducerState = initialDriToolsPageReducerState, action: AnyAction
): IDriToolsPageReducerState => {
    switch (action.type) {
        case actionTypes.API_REVERSE_GOODS_RECEIPT: {
            const payload: IApiReverseGoodsReceipt = action.payload as IApiReverseGoodsReceipt;
            return {
                ...state,
                apiReverseGoodsReceipt: { ...payload }
            } as IDriToolsPageReducerState;
        }
        case actionTypes.API_PURCHASE_ORDER_CHECK: {
            const payload: IApiPurchaseOrderCheck = action.payload as IApiPurchaseOrderCheck;
            return {
                ...state,
                apiPurchaseOrderCheck: { ...payload }
            } as IDriToolsPageReducerState;
        }
        default:
    }

    return state;
};
