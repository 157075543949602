import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { HierarchySelection } from '../../components/HierarchySelection/HierarchySelection';
import { PriorYearAccrualHierarchyScope } from '../../models/priorYearAccrual/priorYearAccrualHierarchyScope';
import { AppDispatch } from '../../store/reduxStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    IApiPriorYearAccrualHierarchyScope,
    IApiPriorYearAccrualProfitCenterCodeMapping,
    callApiPriorYearAccrualHierarchyScope,
    callApiPriorYearAccrualProfitCenterCodeMapping,
    callApiSavePriorYearAccrualHierarchyScope,
    profitCenterCodeMappingSortOnColumn,
    resetApiSavePriorYearAccrualHierarchyScope,
    updateSortedProfitCenterCodeMapping
} from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { useMountEffect } from '../../common/hooks/useMountEffect';
import { CallApiState } from '../../store/actions/generic.action';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IColumn,
    IDetailsHeaderProps,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text
} from '@fluentui/react';
import { commonStyles, stackTokensLargeGap } from '../../common/common.styles';
import { CustomDetailsList, ExcelExportMode } from '../../components/CustomDetailsList/CustomDetailsList';
import { ProfitCenterCodeMapping } from '../../models/priorYearAccrual/profitCenterCodeMapping';
import { IColumnsAndItems, onCustomRenderRow, resetColumnSorting, sortOnColumn } from '../../components/CustomDetailsList/CustomDetailsList.util';
import { profitCenterCodeMappingColumns } from './profitCenterCodeMappingColumns';
import { pageStyles } from './PriorYearAccrualPage.styles';
import { tooltips } from '../../common/tooltips';
import { TreeStateNode } from '../../models/hierarchy/treeState/treeStateNode';

interface IAdminHierarchyConfigProps {
    handleError: (errMsg: string) => void;
}

export const AdminHierarchyConfig: React.FunctionComponent<IAdminHierarchyConfigProps> = (props: IAdminHierarchyConfigProps): JSX.Element => {
    const [priorYearAccrualHierarchyScope, setPriorYearAccrualHierarchyScope] = useState<PriorYearAccrualHierarchyScope>(new PriorYearAccrualHierarchyScope({
        financeGeographySalesDistrictCodes: [],
        channelFunctionDetailCodes: [],
        executiveFunctionDetailCodes: [],
        financeGeographyTreeStateNodes: [],
        channelFunctionTreeStateNodes: [],
        executiveFunctionTreeStateNodes: []
    }));
    const [columns, setColumns] = useState<IColumn[]>(profitCenterCodeMappingColumns);
    const [selectedPage, setSelectedPage] = useState<number>(1);

    // Redux store selectors to get state from the store when it changes.
    const apiPriorYearAccrualHierarchyScope: IApiPriorYearAccrualHierarchyScope =
        useAppSelector<IApiPriorYearAccrualHierarchyScope>(state => state.priorYearAccrualPageReducer.apiPriorYearAccrualHierarchyScope);
    const apiSavePriorYearAccrualHierarchyScope: IApiPriorYearAccrualHierarchyScope =
        useAppSelector<IApiPriorYearAccrualHierarchyScope>(state => state.priorYearAccrualPageReducer.apiSavePriorYearAccrualHierarchyScope);
    const apiPriorYearAccrualProfitCenterCodeMapping: IApiPriorYearAccrualProfitCenterCodeMapping =
        useAppSelector<IApiPriorYearAccrualProfitCenterCodeMapping>(state => state.priorYearAccrualPageReducer.apiPriorYearAccrualProfitCenterCodeMapping);
    const profitCenterCodeMappingSortUsingColumnKey: string | undefined =
        useAppSelector<string | undefined>((state) => state.priorYearAccrualPageReducer.profitCenterCodeMappingSortUsingColumnKey);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Load prior year accrual hierarchy scope.
     */
    const loadPriorYearAccrualHierarchyScope = useCallback(() => {
        dispatch(callApiPriorYearAccrualHierarchyScope());
    }, [dispatch]);

    /**
     * Reset column sorting and set columns.
     */
    const resetColumnSortingAndSetColumns = useCallback(() => {
        // Dispatch an action to not sort on any column.
        dispatch(profitCenterCodeMappingSortOnColumn(undefined));
        const newColumns: IColumn[] = resetColumnSorting([...columns]);
        setColumns(newColumns);
    }, [columns, dispatch]);

    /**
     * Load prior year accrual profit center code mapping.
     */
    const loadPriorYearAccrualProfitCenterCodeMapping = useCallback(() => {
        resetColumnSortingAndSetColumns();
        dispatch(callApiPriorYearAccrualProfitCenterCodeMapping());
    }, [dispatch, resetColumnSortingAndSetColumns]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        if (apiPriorYearAccrualHierarchyScope.callApiState !== CallApiState.Completed) {
            loadPriorYearAccrualHierarchyScope();
        }
        if (apiPriorYearAccrualProfitCenterCodeMapping.callApiState !== CallApiState.Completed) {
            loadPriorYearAccrualProfitCenterCodeMapping();
        }
    });

    /**
     * Effect for when prior year accrual hierarchy scope data loads.
     */
    useEffect(() => {
        if (apiPriorYearAccrualHierarchyScope.callApiState === CallApiState.Completed && apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope) {
            setPriorYearAccrualHierarchyScope(apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope);
        }
    }, [apiPriorYearAccrualHierarchyScope.callApiState, apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope]);

    /**
     * Effect for when apiSavePriorYearAccrualHierarchyScope returns data.
     */
    useEffect(() => {
        // Watching for change to DataAvailable rather than Completed. The api call state is stored in Redux which
        // persists between pages. If someone was to save something, navigate elsewhere, then come back here, this
        // effect would run and the state from the prior call would still be Completed. So we only want the code to run
        // where when the transition to DataAvailable happens.
        if (apiSavePriorYearAccrualHierarchyScope.callApiState === CallApiState.DataAvailable) {
            // Reload the just saved hierarchy scope.
            loadPriorYearAccrualHierarchyScope();
            // Load profit center code mapping for changed hierarchy configuration.
            loadPriorYearAccrualProfitCenterCodeMapping();

            // Reset the API call state for the save API. This is just for cleanup, no need to keep this state around after
            // a successful save.
            // If the call failed then the call state would have moved to Failed and the error handling effect would trigger.
            dispatch(resetApiSavePriorYearAccrualHierarchyScope());
        }
    }, [apiSavePriorYearAccrualHierarchyScope.callApiState, dispatch, loadPriorYearAccrualHierarchyScope, loadPriorYearAccrualProfitCenterCodeMapping]);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        // Pass the error to the parent component.
        if (apiPriorYearAccrualHierarchyScope.errMsg) {
            props.handleError(apiPriorYearAccrualHierarchyScope.errMsg);
        }
        if (apiSavePriorYearAccrualHierarchyScope.errMsg) {
            props.handleError(apiSavePriorYearAccrualHierarchyScope.errMsg);
        }
        if (apiPriorYearAccrualProfitCenterCodeMapping.errMsg) {
            props.handleError(apiPriorYearAccrualProfitCenterCodeMapping.errMsg);
        }
        // Disabling exhaustive deps because we don't want to run this effect when the props.handleError function changes.
        // See: https://stackoverflow.com/questions/62807829/how-to-correctly-implement-a-props-callback-function-in-the-useeffect-hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiPriorYearAccrualHierarchyScope.errMsg, apiPriorYearAccrualProfitCenterCodeMapping.errMsg, apiSavePriorYearAccrualHierarchyScope.errMsg]);

    /**
     * Effect for when sort on column changes.
     */
    useEffect(() => {
        if (profitCenterCodeMappingSortUsingColumnKey) {
            // Create a new columns array here.
            const columns: IColumn[] = profitCenterCodeMappingColumns;
            const column: IColumn | undefined = columns.find(x => x.key === profitCenterCodeMappingSortUsingColumnKey);
            if (column) {
                const columnsAndItems: IColumnsAndItems<ProfitCenterCodeMapping> = sortOnColumn<ProfitCenterCodeMapping>(
                    column,
                    columns,
                    apiPriorYearAccrualProfitCenterCodeMapping.profitCenterCodeMapping || []
                );
                // Cannot change the prior year accrual data in Redux state here, need to dispatch so it changes in the store.
                dispatch(updateSortedProfitCenterCodeMapping(columnsAndItems.items));
                setColumns(columnsAndItems.columns);
            }
        }
    }, [apiPriorYearAccrualProfitCenterCodeMapping.profitCenterCodeMapping, dispatch, profitCenterCodeMappingSortUsingColumnKey]);

    /**
     * Save button clicked event handler.
     */
    const saveButtonClicked = () => {
        dispatch(callApiSavePriorYearAccrualHierarchyScope(priorYearAccrualHierarchyScope));
    };

    /**
     * Memoized helper to check if APIs to load data are running.
     */
    const isLoading = useMemo<boolean>(() => {
        if (apiPriorYearAccrualHierarchyScope.callApiState === CallApiState.Running) {
            return true;
        }
        if (apiPriorYearAccrualProfitCenterCodeMapping.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiPriorYearAccrualHierarchyScope.callApiState, apiPriorYearAccrualProfitCenterCodeMapping.callApiState]);

    /**
     * Memoized helper to check if the save api is running.
     */
    const isSaving = useMemo<boolean>(() => {
        if (apiSavePriorYearAccrualHierarchyScope.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiSavePriorYearAccrualHierarchyScope.callApiState]);

    /**
     * Memoized helper to see if the hierarchy values had changed in the UI versus what came back from the API call.
     */
    const isHierarchyChanged = useMemo<boolean>(() => {
        return !(isEqual(
            apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope?.channelFunctionDetailCodes,
            priorYearAccrualHierarchyScope.channelFunctionDetailCodes
        ) && isEqual(
            apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope?.executiveFunctionDetailCodes,
            priorYearAccrualHierarchyScope.executiveFunctionDetailCodes
        ));
    }, [apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope?.channelFunctionDetailCodes, apiPriorYearAccrualHierarchyScope.priorYearAccrualHierarchyScope?.executiveFunctionDetailCodes, priorYearAccrualHierarchyScope.channelFunctionDetailCodes, priorYearAccrualHierarchyScope.executiveFunctionDetailCodes]);

    return (
        <Stack tokens={stackTokensLargeGap}>
            <Stack.Item>
                <Text variant='mediumPlus' block className={commonStyles.sectionHeading} role="heading" aria-level={1}>Hierarchy configuration</Text>
                <Text variant='medium' block className={commonStyles.sectionContent}>Select the company hierarchy to be enabled for prior year accruals. The data grid below shows the profit center code mapping for the selected hierarchy.</Text>
            </Stack.Item>
            {isLoading && (
                <Stack.Item>
                    <Text variant='mediumPlus'>Loading...</Text>
                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                </Stack.Item>
            )}
            {!isLoading && (
                <>
                    <Stack.Item>
                        {priorYearAccrualHierarchyScope && (
                            <Stack horizontal tokens={stackTokensLargeGap}>
                                <Stack.Item>
                                    <HierarchySelection
                                        showLabel={true}
                                        readonly={false}
                                        disabled={isSaving}
                                        tooltip={tooltips.pyaAdminHierarchySelection}
                                        financeGeographySalesDistrictCodes={priorYearAccrualHierarchyScope.financeGeographySalesDistrictCodes}
                                        channelFunctionDetailCodes={priorYearAccrualHierarchyScope.channelFunctionDetailCodes}
                                        executiveFunctionDetailCodes={priorYearAccrualHierarchyScope.executiveFunctionDetailCodes}
                                        showGeography={false}
                                        showWarningIfBothChannelAndExecSelected={false}
                                        onChanged={(
                                            financeGeographySalesDistrictCodes: string[],
                                            channelFunctionDetailCodes: string[],
                                            executiveFunctionDetailCodes: string[],
                                            financeGeographyTreeStateNodes: TreeStateNode[],
                                            channelFunctionTreeStateNodes: TreeStateNode[],
                                            executiveFunctionTreeStateNodes: TreeStateNode[]
                                        ) => {
                                            setPriorYearAccrualHierarchyScope({
                                                financeGeographySalesDistrictCodes: financeGeographySalesDistrictCodes,
                                                channelFunctionDetailCodes: channelFunctionDetailCodes,
                                                executiveFunctionDetailCodes: executiveFunctionDetailCodes,
                                                financeGeographyTreeStateNodes: financeGeographyTreeStateNodes,
                                                channelFunctionTreeStateNodes: channelFunctionTreeStateNodes,
                                                executiveFunctionTreeStateNodes: executiveFunctionTreeStateNodes
                                            });
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item align='end'>
                                    <DefaultButton
                                        onClick={saveButtonClicked}
                                        disabled={isSaving || !isHierarchyChanged}
                                    >
                                        {isSaving && (
                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                        )}
                                        {!isSaving && (
                                            <span>Save</span>
                                        )}
                                    </DefaultButton>
                                </Stack.Item>
                            </Stack>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                        <CustomDetailsList
                            id="profitCenterCodeMappingDetailsList"
                            ariaLabelForGrid="Profit Center Code Mapping"
                            className={pageStyles.detailsList}
                            excelExportMode={ExcelExportMode.Internal}
                            showExcelExport={true}
                            exportExcelSheetName="Profit Center Code Mapping"
                            displayTotalItems={false}
                            showPaginator={true}
                            showPageSize={true}
                            selectedPage={selectedPage}
                            onSelectedPageChange={(page) => {
                                setSelectedPage(page);
                            }}
                            items={apiPriorYearAccrualProfitCenterCodeMapping.profitCenterCodeMapping || []}
                            isLoading={apiPriorYearAccrualProfitCenterCodeMapping.callApiState === CallApiState.Running}
                            compact={false}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            getKey={(item: ProfitCenterCodeMapping) => item.clientRowKey!}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            isHeaderVisible={true}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderRow={onCustomRenderRow}
                            useScrollablePane={true}
                            onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps | undefined, defaultRender) => {
                                if (detailsHeaderProps) {
                                    return (
                                        <Sticky stickyPosition={StickyPositionType.Header}>
                                            {defaultRender!({ ...detailsHeaderProps })}
                                        </Sticky>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        />
                        <Separator />
                    </Stack.Item>
                </>
            )}
        </Stack>
    );
};
