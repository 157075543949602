import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { JournalEntry } from '../../models/accrualDashboard/journalEntry';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';

const jeIdColumn: ICustomColumn = {
    key: 'jeId',
    fieldName: 'jeId', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'JE Id', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='JE'
                headerLine2='Id'
            />
        );
    },
    isResizable: false,
    minWidth: 270,
    onRender: (item: JournalEntry) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.jeId}
            </Text>
        );
    }
};

const jeCreationTimeStampColumn: ICustomColumn = {
    key: 'jeCreationTimeStamp',
    fieldName: 'jeCreationTimeStamp', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'JE Creation Date', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='JE'
                headerLine2='Creation Date'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: JournalEntry) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.jeCreationTimeStamp)}
            </Text>
        );
    }
};

const jeStatusColumn: ICustomColumn = {
    key: 'jeStatus',
    fieldName: 'jeStatus', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'JE Status', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='JE'
                headerLine2='Status'
            />
        );
    },
    isResizable: false,
    minWidth: 60,
    onRender: (item: JournalEntry) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.jeStatus}
            </Text>
        );
    }
};

const jeStatusTimeStampColumn: ICustomColumn = {
    key: 'jeStatusTimeStamp',
    fieldName: 'jeStatusTimeStamp', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'JE Status Date', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='JE'
                headerLine2='Status Date'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: JournalEntry) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.jeStatusTimeStamp)}
            </Text>
        );
    }
};

const sapDocumentColumn: ICustomColumn = {
    key: 'sapDocument',
    fieldName: 'sapDocument', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'SAP Document', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='SAP'
                headerLine2='Document'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: JournalEntry) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.sapDocument}
            </Text>
        );
    }
};

const errorDescColumn: ICustomColumn = {
    key: 'errorDesc',
    fieldName: 'errorDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Errors', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Errors'
                headerLine2=''
            />
        );
    },
    isResizable: false,
    minWidth: 350,
    onRender: (item: JournalEntry) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.errorDesc}
            </Text>
        );
    }
};

export const accrualFailureJournalEntryColumns: IColumn[] = [
    jeIdColumn,
    jeCreationTimeStampColumn,
    jeStatusColumn,
    jeStatusTimeStampColumn,
    sapDocumentColumn,
    errorDescColumn
];
