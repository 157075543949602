import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    itemSpinner: {
        margin: '6px 0 0 0',
        width: '22px'
    } as IStyle,
    itemIcon: {
        fontSize: '22px'
    } as IStyle,
    itemCenter: {
        width: '100%'
    } as IStyle,
    itemDelete: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    } as IStyle,
    successIcon: {
        color: 'green'
    } as IStyle,
    errorIcon: {
        color: '#e50000'
    } as IStyle,
    itemHeading: {
        lineHeight: '22px',
        color: '#727272'
    } as IStyle
});
