import React, { useMemo } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton
} from '@fluentui/react';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { PurchaseOrderLineClose } from '../../models/purchaseOrder/purchaseOrderLineClose';
import { padNumberWithLeadingChar } from '../../common/common.func.transform';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';

interface IComponentProps {
    showDialog: boolean;
    priorYearAccrualLineItems: PriorYearAccrualLineItem[];
    onYesClicked: (poLines: PurchaseOrderLineClose[]) => void;
    onNoClicked: () => void;
}

export const ConfirmCloseDialog: React.FunctionComponent<IComponentProps> = (props: IComponentProps): JSX.Element => {
    /**
     * Memoized field for selection count.
     */
    const selectedCount: number = useMemo(() => {
        const selectedLines: PriorYearAccrualLineItem[] = props.priorYearAccrualLineItems;
        return selectedLines.length;
    }, [props.priorYearAccrualLineItems]);

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={() => props.onNoClicked()}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Confirm Close',
                subText: `I confirm to close the selected PO line${selectedCount > 1 ? 's' : ''} as all invoices are processed and no further invoices are expected.`
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth="400px"
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.pyaConfirmCloseButtonClicked });

                        const selectedLines: PriorYearAccrualLineItem[] = props.priorYearAccrualLineItems.filter(x => x.isSelected) || [];
                        const poLines: PurchaseOrderLineClose[] = selectedLines.map(x => {
                            return new PurchaseOrderLineClose(
                                padNumberWithLeadingChar(x.purchaseOrder, 10),
                                padNumberWithLeadingChar(x.lineItem, 5),
                                'Line closed from prior year accruals page.'
                            );
                        });
                        props.onYesClicked(poLines);
                    }}
                    text="Yes"
                />
                <DefaultButton
                    onClick={() => props.onNoClicked()}
                    text="No" 
                />
            </DialogFooter>
        </Dialog>
    );
};
