import React, { useEffect, useMemo, useState } from 'react';
import { Image, TooltipHost, Checkbox, TooltipDelay } from '@fluentui/react';
import { useAppSelector } from '../../store/hooks';
import { ICustomCoherenceTreeItem } from './ICustomCoherenceTreeItem';
import { getHierarchyNodeDescription, getHierarchyNodeIcon } from './hierarchyUtil';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { IHierarchyTreeItemState } from './IHierarchyTreeItemState';
import { componentStyles } from './HierarchySelection.styles';
import { HierarchyTreeType } from './HierarchyTreeType';
import { IHierarchyState } from '../../store/reducers/app.reducer';

export interface IHierarchyTreeNodeIconProps {
    instanceId: string,
    hierarchyTreeType: HierarchyTreeType,
    treeItem: ICustomCoherenceTreeItem;
    readonly: boolean;
    onCheckboxChange: (treeItem: ICustomCoherenceTreeItem, checked: boolean) => void;
}

/**
 * Hierarchy tree node icon.
 * Build the node "icon" element, which allows for custom JSX. So we will use this "icon" element
 * also for a Checkbox in addition to an icon.
 * @param props Hierarchy tree node icon props.
 * @returns JSX for the component.
 */
export const HierarchyTreeNodeIcon: React.FunctionComponent<IHierarchyTreeNodeIconProps> = (props: IHierarchyTreeNodeIconProps): JSX.Element => {
    const [isIndeterminate, setIsIndeterminate] = useState<boolean | undefined>(props.treeItem.isIndeterminate);
    const [isChecked, setIsChecked] = useState<boolean | undefined>(props.treeItem.isChecked);
    
    // Redux store selectors to get state from the store when it changes.
    const hierarchyStateInstances: IKeyValuePair<IHierarchyState> = 
        useAppSelector<IKeyValuePair<IHierarchyState>>(state => state.appReducer!.hierarchyStateInstances);

    /**
     * Effect for when tree item states change. The tree item states are stored in Redux and this component listens
     * for changes to those states. These states are used to store the checked and indeterminate values for each node
     * in the tree.
     */
    useEffect(() => {
        // Get the state for this node. The treeItemStates is a dictionary where the key is the id of the node.
        let hierarchyTreeItemState: IHierarchyTreeItemState | undefined = undefined;
        switch (props.hierarchyTreeType) {
            case HierarchyTreeType.FinanceGeography: {
                hierarchyTreeItemState = hierarchyStateInstances[props.instanceId].financeGeographyHierarchyTreeItemStates[props.treeItem.id];
                break;
            }
            case HierarchyTreeType.ChannelFunction: {
                hierarchyTreeItemState = hierarchyStateInstances[props.instanceId].channelFunctionHierarchyTreeItemStates[props.treeItem.id];
                break;
            }
            case HierarchyTreeType.ExecutiveFunction: {
                hierarchyTreeItemState = hierarchyStateInstances[props.instanceId].executiveFunctionHierarchyTreeItemStates[props.treeItem.id];
                break;
            }
            default: {
                break;
            }
        }
        if (hierarchyTreeItemState) {
            setIsIndeterminate(hierarchyTreeItemState.isIndeterminate);
            setIsChecked(hierarchyTreeItemState.isChecked);
        }
    }, [hierarchyStateInstances, props.hierarchyTreeType, props.instanceId, props.treeItem.id]);

    /**
     * Memoized field for the node description.
     */
    const nodeDescription = useMemo<string>(() => {
        return getHierarchyNodeDescription(props.treeItem.hierarchyNode.typeName!)
    }, [props.treeItem.hierarchyNode.typeName]);

    /**
     * Memoized field for the node icon image source.
     */
    const nodeImgSrc = useMemo<string>(() => {
        return getHierarchyNodeIcon(props.treeItem.hierarchyNode.typeName!);
    }, [props.treeItem.hierarchyNode.typeName]);

    return (
        <>
            <Checkbox
                className={componentStyles.hierarchyNodeCheckbox}
                ariaLabel='Select node'
                checked={isChecked}
                indeterminate={isIndeterminate}
                disabled={props.readonly}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                    props.onCheckboxChange(props.treeItem, checked!);
                }}
            />
            <TooltipHost content={nodeDescription} delay={TooltipDelay.long}>
                <Image
                    src={nodeImgSrc}
                    alt={nodeDescription}
                    height="18px"
                />
            </TooltipHost>
        </>
    );
};
