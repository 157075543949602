import { TreeStateNode } from '../hierarchy/treeState/treeStateNode';

export interface IPriorYearAccrualHierarchyScope {
    channelFunctionDetailCodes: string[];
    executiveFunctionDetailCodes: string[];
    financeGeographySalesDistrictCodes: string[];
    financeGeographyTreeStateNodes: TreeStateNode[];
    channelFunctionTreeStateNodes: TreeStateNode[];
    executiveFunctionTreeStateNodes: TreeStateNode[];
}

export class PriorYearAccrualHierarchyScope implements IPriorYearAccrualHierarchyScope {
    public channelFunctionDetailCodes: string[] = [];
    public executiveFunctionDetailCodes: string[] = [];
    public financeGeographySalesDistrictCodes: string[] = [];
    public financeGeographyTreeStateNodes: TreeStateNode[] = [];
    public channelFunctionTreeStateNodes: TreeStateNode[] = [];
    public executiveFunctionTreeStateNodes: TreeStateNode[] = [];

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPriorYearAccrualHierarchyScope) {
        if (jsonData.channelFunctionDetailCodes) {
            for (let i: number = 0; i < jsonData.channelFunctionDetailCodes.length; i++) {
                this.channelFunctionDetailCodes.push(jsonData.channelFunctionDetailCodes[i]);
            }
        }

        if (jsonData.executiveFunctionDetailCodes) {
            for (let i: number = 0; i < jsonData.executiveFunctionDetailCodes.length; i++) {
                this.executiveFunctionDetailCodes.push(jsonData.executiveFunctionDetailCodes[i]);
            }
        }

        if (jsonData.financeGeographySalesDistrictCodes) {
            for (let i: number = 0; i < jsonData.financeGeographySalesDistrictCodes.length; i++) {
                this.financeGeographySalesDistrictCodes.push(jsonData.financeGeographySalesDistrictCodes[i]);
            }
        }

        if (jsonData.financeGeographyTreeStateNodes) {
            for (let i: number = 0; i < jsonData.financeGeographyTreeStateNodes.length; i++) {
                this.financeGeographyTreeStateNodes.push(new TreeStateNode(jsonData.financeGeographyTreeStateNodes[i].type, jsonData.financeGeographyTreeStateNodes[i].desc, jsonData.financeGeographyTreeStateNodes[i].code, jsonData.financeGeographyTreeStateNodes[i].treeStateSelection, jsonData.financeGeographyTreeStateNodes[i].children));
            }
        }

        if (jsonData.channelFunctionTreeStateNodes) {
            for (let i: number = 0; i < jsonData.channelFunctionTreeStateNodes.length; i++) {
                this.channelFunctionTreeStateNodes.push(new TreeStateNode(jsonData.channelFunctionTreeStateNodes[i].type, jsonData.channelFunctionTreeStateNodes[i].desc, jsonData.channelFunctionTreeStateNodes[i].code, jsonData.channelFunctionTreeStateNodes[i].treeStateSelection, jsonData.channelFunctionTreeStateNodes[i].children));
            }
        }

        if (jsonData.executiveFunctionTreeStateNodes) {
            for (let i: number = 0; i < jsonData.executiveFunctionTreeStateNodes.length; i++) {
                this.executiveFunctionTreeStateNodes.push(new TreeStateNode(jsonData.executiveFunctionTreeStateNodes[i].type, jsonData.executiveFunctionTreeStateNodes[i].desc, jsonData.executiveFunctionTreeStateNodes[i].code, jsonData.executiveFunctionTreeStateNodes[i].treeStateSelection, jsonData.executiveFunctionTreeStateNodes[i].children));
            }
        }
    }
}
