import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { POLineAccrualActivity } from '../../models/accrualDashboard/poLineAccrualActivity';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { AccrualAction } from './AccrualAction';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';

const actionColumn: ICustomColumn = {
    key: 'action',
    fieldName: undefined,
    name: '',
    isResizable: false,
    minWidth: 80,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <AccrualAction poLineAccrualActivity={item} />
        );
    }
};

const purchaseOrderNumberColumn: ICustomColumn = {
    key: 'purchaseOrderNumber',
    fieldName: 'purchaseOrderNumber', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'PO Number', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PO'
                headerLine2='Number'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderNumber}
            </Text>
        );
    }
};

const purchaseOrderLineItemColumn: ICustomColumn = {
    key: 'purchaseOrderLineItem',
    fieldName: 'purchaseOrderLineItem', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Line Number', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line'
                headerLine2='Number'
            />
        );
    },
    isResizable: false,
    minWidth: 60,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderLineItem}
            </Text>
        );
    }
};

const currencyColumn: ICustomColumn = {
    key: 'currency',
    fieldName: 'currency', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Currency Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Currency'
                headerLine2='Code'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.currency}
            </Text>
        );
    }
};

const poCompanyCodeColumn: ICustomColumn = {
    key: 'poCompanyCode',
    fieldName: 'poCompanyCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Company Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Company'
                headerLine2='Code'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.poCompanyCode}
            </Text>
        );
    }
};

const purchaseOrderActivityTypeColumn: ICustomColumn = {
    key: 'purchaseOrderActivityType',
    fieldName: 'purchaseOrderActivityType', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Activity Type', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Activity'
                headerLine2='Type'
            />
        );
    },
    isResizable: false,
    minWidth: 130,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderActivityType}
            </Text>
        );
    }
};

const accrualsProcessingStatusColumn: ICustomColumn = {
    key: 'accrualsProcessingStatus',
    fieldName: 'accrualsProcessingStatus', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Processing Status', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Processing'
                headerLine2='Status'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.accrualsProcessingStatus}
            </Text>
        );
    }
};

const internalOrderOrCostCenterColumn: ICustomColumn = {
    key: 'internalOrderOrCostCenter',
    fieldName: 'internalOrderOrCostCenter', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Internal Order or Cost Center', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Internal Order'
                headerLine2='or Cost Center'
            />
        );
    },
    isResizable: false,
    minWidth: 120,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.internalOrderOrCostCenter}
            </Text>
        );
    }
};

const glAccountCodeColumn: ICustomColumn = {
    key: 'glAccountCode',
    fieldName: 'glAccountCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'GL Account Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='GL Account'
                headerLine2='Code'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.glAccountCode}
            </Text>
        );
    }
};

const accrualActivityIdColumn: ICustomColumn = {
    key: 'accrualActivityId',
    fieldName: 'accrualActivityId', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Accrual Activity Id', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Accrual'
                headerLine2='Activity Id'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.accrualActivityId}
            </Text>
        );
    }
};

const accrualActivityDateColumn: ICustomColumn = {
    key: 'accrualActivityDate',
    fieldName: 'accrualActivityDate', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Accrual Activity Date', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Accrual'
                headerLine2='Activity Date'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.accrualActivityDate)}
            </Text>
        );
    }
};

const invoiceAmountColumn: ICustomColumn = {
    key: 'invoiceAmount',
    fieldName: 'invoiceAmount', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Invoice Amount', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Invoice'
                headerLine2='Amount'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {numberAsLocaleString(item.invoiceAmount, 2)}
            </Text>
        );
    }
};

const onboardingAccrualAmountColumn: ICustomColumn = {
    key: 'onboardingAccrualAmount',
    fieldName: 'onboardingAccrualAmount', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Onboarding Accrual Amount', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Onboarding'
                headerLine2='Accrual Amount'
            />
        );
    },
    isResizable: false,
    minWidth: 110,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {numberAsLocaleString(item.onboardingAccrualAmount, 2)}
            </Text>
        );
    }
};

const receiptAmountColumn: ICustomColumn = {
    key: 'receiptAmount',
    fieldName: 'receiptAmount', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Receipt Amount', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Receipt'
                headerLine2='Amount'
            />
        );
    },
    isResizable: false,
    minWidth: 70,
    onRender: (item: POLineAccrualActivity) => {
        return (
            <Text className={commonStyles.textCell}>
                {numberAsLocaleString(item.receiptAmount, 2)}
            </Text>
        );
    }
};

export const accrualFailureColumns: IColumn[] = [
    actionColumn,
    purchaseOrderNumberColumn,
    purchaseOrderLineItemColumn,
    currencyColumn,
    poCompanyCodeColumn,
    purchaseOrderActivityTypeColumn,
    accrualsProcessingStatusColumn,
    internalOrderOrCostCenterColumn,
    glAccountCodeColumn,
    accrualActivityIdColumn,
    accrualActivityDateColumn,
    invoiceAmountColumn,
    onboardingAccrualAmountColumn,
    receiptAmountColumn
];
