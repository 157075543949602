import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

/*
General purpose functions for date and time.
*/

/**
 * Time part.
 * Returned from some functions such as timePartFromMilliseconds().
 */
export interface ITimePart {
    days: number;
    hours: number;
    mins: number;
    totalMinutes: number;
    seconds: number;
    milliseconds: number;
}

/**
 * Returns the date as of now.
 */
export const nowDate = (): Date => {
    return new Date();
};

/**
 * Returns the date from a week ago.
 */
export const weekAgoDate = (): Date => {
    return new Date(nowDate().valueOf() - (1000 * 60 * 60 * 24 * 7));
};

/**
 * Returns the date from one month ago.
 */
export const oneMonthAgoDate = (): Date => {
    return dayjs().subtract(1, 'months').toDate();
};

/**
 * Returns the date from three months ago.
 */
export const threeMonthsAgoDate = (): Date => {
    return dayjs().subtract(3, 'months').toDate();
};

/**
 * Adds days to a date.
 * @param date Date to add days to.
 * @param days Number of days to add.
 */
export const datePlusDays = (date: Date, days: number): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
};

/**
 * Returns the month and year as a string.
 * @param offsetMonths Offset months. Nullable. If not supplied then current month. -1 returns prior month. 1 returns next month, etc...
 */
export const monthAndYearString = (offsetMonths?: number): string => {
    return dayjs().add(offsetMonths ? offsetMonths : 0, 'month').format('MMMM YYYY');
};

/**
 * Returns the date for the start of the month offset from the current month.
 * @param offsetMonths Offset months. Nullable. If not supplied then current month. -1 returns prior month. 1 returns next month, etc...
 */
export const startOfMonthOffset = (offsetMonths?: number): Date => {
    return dayjs().add(offsetMonths ? offsetMonths : 0, 'month').startOf('month').startOf('day').toDate();
};

/**
 * Returns the date for the end of the month offset from the current month.
 * @param offsetMonths Offset months. Nullable. If not supplied then current month. -1 returns prior month. 1 returns next month, etc...
 */
export const endOfMonthOffset = (offsetMonths?: number): Date => {
    return dayjs().add(offsetMonths ? offsetMonths : 0, 'month').endOf('month').endOf('day').toDate();
};

/**
 * Format date using user locale format.
 * @param date Date to format.
 * @returns Formatted date.
 */
export const formatDateUsingLocale = (date?: Date | string): string => {
    if (!date) {
        return '';
    }
    return dayjs(new Date(date)).format('L');
};

/**
 * Format date using custom format.
 * @param date Date to format.
 * @param format Format string.
 * @returns Formatted date.
 */
export const formatDateUsingFormat = (date?: Date | string, format?: string): string => {
    if (!date || !format) {
        return '';
    }
    return dayjs(new Date(date)).format(format);
};

/**
 * Format date/time using user locale format.
 * @param date Date to format.
 * @returns Formatted datetime.
 */
export const formatDateTimeUsingLocale = (date: Date | string): string => {
    if (!date) {
        return '';
    }
    return dayjs(new Date(date)).format('L LT');
};

/**
 * Format date/time using user locale format.
 * @param date Date to format.
 * @param format Format string.
 * @returns Formatted datetime.
 */
export const formatDateTimeUsingFormat = (date?: Date | string, format?: string): string => {
    if (!date || !format) {
        return '';
    }
    return dayjs(new Date(date)).format(format);
};

/**
 * Format date adjusted for PST time zone using user locale format.
 * @param date Date to format.
 * @returns Formatted date.
 */
export const formatDateTimeAdjustForPstUsingLocale = (date?: Date | string): string => {
    if (!date) {
        return '';
    }
    return dayjs(new Date(date)).tz('America/Los_Angeles').format('L LT');
};

/**
 * Format date adjusted for PST time zone using user locale format.
 * @param date Date to format.
 * @param format Format string.
 * @returns Formatted date.
 */
export const formatDateTimeAdjustForPstUsingFormat = (date?: Date | string, format?: string): string => {
    if (!date || !format) {
        return '';
    }
    return dayjs(new Date(date)).tz('America/Los_Angeles').format(format);
};

/**
 * Returns current PST date.
 * @returns Current PST date.
 */
export const currentPstDate = (): Date => {
    // Get current date/time in PST time zone.
    const nowPst: dayjs.Dayjs = dayjs().tz('America/Los_Angeles');

    // Make a new Date object using PST time. Do not specify Z in the string format.
    return new Date(nowPst.format('YYYY-MM-DDTHH:mm:ss.sss'));
}

/**
 * Returns true if currently July 1st in PST.
 * @returns True or false.
 */
export const isJulyFirstPst = (): boolean => {
    // Get current date/time in PST time zone.
    const nowPst: dayjs.Dayjs = dayjs().tz('America/Los_Angeles');
    return nowPst.month() === 6 /* July is 6 as month is zero indexed. */ && nowPst.date() === 1;
};

/**
 * Return time parts given milliseconds.
 * @param milliseconds Milliseconds.
 * @returns Time part from milliseconds.
 */
export const timePartFromMilliseconds = (milliseconds: number): ITimePart => {
    const days: number = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    milliseconds -= days * (1000 * 60 * 60 * 24);

    const hours: number = Math.floor(milliseconds / (1000 * 60 * 60));
    milliseconds -= hours * (1000 * 60 * 60);

    const mins: number = Math.floor(milliseconds / (1000 * 60));
    milliseconds -= mins * (1000 * 60);

    const seconds: number = Math.floor(milliseconds / (1000));
    milliseconds -= seconds * (1000);

    // Total minutes includes the minutes in the days and hours and minutes.
    const totalMinutes: number = (days * 24 /* hours in a day */ * 60 /* minutes in an hour */) +
                                 (hours * 60 /* minutes in an hour */) +
                                 mins;

    return {
        days: days,
        hours: hours,
        mins: mins,
        totalMinutes,
        seconds: seconds,
        milliseconds: milliseconds
    } as ITimePart;
};

/**
 * Returns date as a non-UTC ISO date string
 * @param date Date to convert to non UTC.
 * @returns 
 */
export const dateAsNonUtcIsoString = (date: Date): string => {
    // Notes on what this function does:
    // If the date is July 1, 2023 ad midnight IST, then doing a console.log(date) produces:
    // Sat Jul 01 2023 00:00:00 GMT+0530 (India Standard Time)
    // If we use the Date toIsoString() function, it will convert it to an ISO string with time zone offset.
    // For example, console.log(date.toISOString()) produces:
    // 2023-06-30T18:30:00.000Z
    // For this function, we want the ISO string format but without the time zone offset and date adjustment.
    // In the above case, the date was changed from July 1st to June 30th in the ISO string with the time zone offset.
    // What we want here, instead, is just the date using the ISO format and no time zone offset performed.
    // To do that, we can just use dayjs format function and give the ISO format string which produces:
    // 2023-07-01T00:00:00.000
    const dateStr: string = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.sss')
    return dateStr;
};
