import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { reduxStore } from '../../store/reduxStore';
import { costCategoryConfigurationSortOnColumn } from '../../store/actions/pageActions/priorYearAccrualPage.action';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';
import { CostCategoryConfiguration } from '../../models/priorYearAccrual/costCategoryConfiguration';
import { CostCategoryConfigAction } from './CostCategoryConfigAction';
import { all } from './priorYearAccrualPageConstants';

const columnSortHandler = (column: IColumn) => {
    reduxStore.dispatch(costCategoryConfigurationSortOnColumn(column.key));
}

export const actionColumn: ICustomColumn = {
    key: 'actionColumn',
    name: '',
    // No fieldName specified. This column will be excluded from Excel export.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Action'
            />
        );
    },
    isResizable: false,
    minWidth: 90,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <CostCategoryConfigAction item={item} />
        );
    }
};

export const subclassNameColumn: ICustomColumn = {
    key: 'subclassNameColumn',
    fieldName: 'subclassName', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Subclass Name', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Subclass'
                headerLine2='Name'
            />
        );
    },
    isResizable: true,
    minWidth: 160,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.subclassName}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const subclassCodeColumn: ICustomColumn = {
    key: 'subclassCodeColumn',
    fieldName: 'subclassCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Subclass Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Subclass'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 104,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.subclassCode}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const groupNameDescColumn: ICustomColumn = {
    key: 'groupNameDescColumn',
    fieldName: 'groupNameDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Group Name Desc', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Group Name'
                headerLine2='Desc'
            />
        );
    },
    isResizable: true,
    minWidth: 240,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.groupNameDesc || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const groupNameCodeColumn: ICustomColumn = {
    key: 'groupNameCodeColumn',
    fieldName: 'groupNameCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Group Name Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Group Name'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 104,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.groupNameCode || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const lineItemDescColumn: ICustomColumn = {
    key: 'lineItemDescColumn',
    fieldName: 'lineItemDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Line Item Desc', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line Item'
                headerLine2='Desc'
            />
        );
    },
    isResizable: true,
    minWidth: 240,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.lineItemDesc || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const lineItemCodeColumn: ICustomColumn = {
    key: 'lineItemCodeColumn',
    fieldName: 'lineItemCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Line Item Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line Item'
                headerLine2='Code'
            />
        );
    },
    isResizable: true,
    minWidth: 104,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.lineItemCode || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const lineItemDetailDescColumn: ICustomColumn = {
    key: 'lineItemDetailDescColumn',
    fieldName: 'lineItemDetailDesc', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Line Item Detail Desc', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line Item'
                headerLine2='Detail Desc'
            />
        );
    },
    isResizable: true,
    minWidth: 240,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.lineItemDetailDesc || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const lineItemDetailCodeColumn: ICustomColumn = {
    key: 'lineItemDetailCodeColumn',
    fieldName: 'lineItemDetailCode', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Line Item Detail Code', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line Item'
                headerLine2='Detail Code'
            />
        );
    },
    isResizable: true,
    minWidth: 104,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.lineItemDetailCode || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const shortNameColumn: ICustomColumn = {
    key: 'shortNameColumn',
    fieldName: 'shortName', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Short Name', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Short'
                headerLine2='Name'
            />
        );
    },
    isResizable: true,
    minWidth: 160,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.shortName}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const glAccountColumn: ICustomColumn = {
    key: 'glAccountColumn',
    fieldName: 'glAccount', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'GL Account', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='GL'
                headerLine2='Account'
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.glAccount || all}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const dedicatedGlColumn: ICustomColumn = {
    key: 'dedicatedGlColumn',
    fieldName: 'dedicatedGl', // The fieldName is normally not needed when using custom onRender. But it is needed for the Excel export to work.
    name: 'Dedicated GL', // Custom onRenderHeader used below. This name is used for column heading during Excel export in CustomDetailsList.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Dedicated'
                headerLine2='GL'
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: CostCategoryConfiguration) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.dedicatedGl}
            </Text>
        );
    },
    sortCallback: (column: IColumn) => {
        columnSortHandler(column);
    }
};

export const costCategoryConfigColumns: IColumn[] = [
    actionColumn,
    subclassNameColumn,
    subclassCodeColumn,
    groupNameDescColumn,
    groupNameCodeColumn,
    lineItemDescColumn,
    lineItemCodeColumn,
    lineItemDetailDescColumn,
    lineItemDetailCodeColumn,
    shortNameColumn,
    glAccountColumn,
    dedicatedGlColumn
];
