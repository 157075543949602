import { isNullOrUndefined } from '../../common/common.func.general';
import { PurchaseOrderSearchFilter } from '../purchaseOrder/purchaseOrderSearchFilter';
import { TileType } from './tileType';

export interface IDashboardTile {
    tileType: string | TileType;
    purchaseOrderCount: number;
    lineItemCount: number;
    purchaseOrderSearchFilter?: PurchaseOrderSearchFilter;
}

export class DashboardTile implements IDashboardTile {
    public tileType: TileType;
    public purchaseOrderCount: number;
    public lineItemCount: number;
    public purchaseOrderSearchFilter?: PurchaseOrderSearchFilter;

    /**
     * Constructor for dashboard tile.
     * @param dashboardTile Dashboard tile data.
     */
    constructor(dashboardTile: IDashboardTile) {
        this.tileType = TileType[dashboardTile.tileType];
        this.purchaseOrderCount = dashboardTile.purchaseOrderCount;
        this.lineItemCount = dashboardTile.lineItemCount;
        this.purchaseOrderSearchFilter = isNullOrUndefined(dashboardTile.purchaseOrderSearchFilter) ? undefined : new PurchaseOrderSearchFilter(dashboardTile.purchaseOrderSearchFilter!);
    }
}
