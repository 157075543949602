import { AccessRequestStatus } from './accessRequestStatus';
import { RoleScope } from './roleScope';

export interface IAccessRequest {
    id?: string;
    submitterAlias: string;
    requestorAlias: string;
    requestorAadOid?: string;
    groupId: number;
    businessJustification?: string;
    createdDate?: Date;
    lastModifiedDate?: Date;
    scope?: RoleScope;
    approverAlias?: string;
    status: AccessRequestStatus;
}

export class AccessRequest implements IAccessRequest {
    public id?: string;
    public submitterAlias: string;
    public requestorAlias: string;
    public requestorAadOid?: string;
    public groupId: number;
    public businessJustification?: string;
    public createdDate?: Date;
    public lastModifiedDate?: Date;
    public scope?: RoleScope;
    public approverAlias?: string;
    public status: AccessRequestStatus;

    /**
     * Returns whether or not at least one hierarchy has been selected for the access request scope.
     */
    public get isHierarchySelected(): boolean {
        if (this.scope) {
            if (this.scope.financeGeographySalesDistrictCodes.length > 0 ||
                this.scope.channelFunctionDetailCodes.length > 0 ||
                this.scope.executiveFunctionDetailCodes.length > 0) {
                return true;
            }
        }

        return false;
    }

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IAccessRequest) {
        this.id = jsonData.id;
        this.submitterAlias = jsonData.submitterAlias;
        this.requestorAlias = jsonData.requestorAlias;
        this.requestorAadOid = jsonData.requestorAadOid;
        this.groupId = jsonData.groupId;
        this.businessJustification = jsonData.businessJustification;
        this.createdDate = jsonData.createdDate ? new Date(jsonData.createdDate) : undefined;
        this.lastModifiedDate = jsonData.lastModifiedDate ? new Date(jsonData.lastModifiedDate) : undefined;

        if (jsonData.scope) {
            this.scope = new RoleScope(jsonData.scope);
        }

        this.approverAlias = jsonData.approverAlias;
        this.status = jsonData.status;
    }
}
