/**
 * Shipment supplier. It is called "Organization" in the Shipment API, so naming it that way here too.
 */
export enum Organization {
    Dell = 0,
    Lenovo = 1,
    Hp = 2,
    Zones = 3,
    Wwt = 4,
    Unknown = 5
}
