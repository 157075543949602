import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    Label,
    Spinner,
    SpinnerSize,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { validationConstants } from '../../common/validationConstants';
import { isNumeric, validateInput } from '../../common/common.func.general';
import { debounce } from 'lodash';
import { receiptingApiClient } from '../../services/api/receiptingApiClient';
import { commonStyles } from '../../common/common.styles';
import { Supplier } from '../../models/domain/supplier';
import { componentStyles } from './SupplierInput.styles';

/**
 * Supplier input props.
 */
export interface ISupplierInputProps {
    /**
     * Shows a label above the ComboBox.
     */
    showLabel?: boolean;

    /**
     * Label string if showLabel is true.
     */
    label?: string;

    /**
     * Disables the ComboBox.
     */
    disabled?: boolean;

    /**
     * Tooltip to show for the ComboBox.
     */
    tooltip?: string;

    /**
     * Delay in milleseconds to use with debounce. Defaults to 250ms if not supplied.
     */
    debounceDelay?: number;

    /**
     * Change event callback.
     * @param supplier Supplier that was selected.
     */
    onChange?: (supplier: Supplier | undefined) => void;

    /**
     * Input validation event callback.
     * @param msg Validation message.
     * @param isValid Indicates if valid or not.
     */
    onInputValidation?: (msg: string, isValid: boolean) => void;

    /**
     * Initial selected supplier, if any. The ComboBox will be pre-populated with this value upon load.
     * A call will be made to load this supplier during load.
     */
    initialSelectedSupplier?: string;

    /**
     * If this is changed via props, then the input will be cleared.
     * This is a bit of a hacky way to make this happen. Certain pages have a 'Clear' button where we want
     * to clear out the input fields. The initialSelectedAlias is used only during initialization by design.
     * And did not want to use that prop to also serve the purpose of clearing out input.
     */
    clearInputCounter?: number;

    /**
     * Handle error event callback.
     * @param error Error message.
     */
    handleError?: (error: string) => void;

    /**
     * If true, will allow entry of a supplier number that does not exist.
     * In the Receipting DB, there is the [Domain].[Supplier] table that gets suppliers populated via EDL data sync.
     * There may be suppliers that are not found in this table due to VendorAccountGroupCode mapping.
     */
    allowNotFoundSupplierNumber?: boolean;
}

const enterSupplierNameOrNumber: string = 'Enter supplier name or number';
const supplierDoesNotExist: string = 'Supplier does not exist';
const doesNotExistOptionKey: string = 'DOES_NOT_EXIST';

const debugLogEnabled: boolean = false; // Do not check in with this set to true. Used for log to console for debugging or to see how this works.

interface IQueueObj {
    search: string;
    promise: Promise<Supplier[] | null>;
}

/**
 * Supplier input.
 * @param props Supplier input props.
 */
export const SupplierInput: React.FunctionComponent<ISupplierInputProps> = (props: ISupplierInputProps): JSX.Element => {
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [options, setOptions] = useState<IComboBoxOption[]>([])
    const [selectedOptionKey, setSelectedOptionKey] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [inputInvalid, setInputInvalid] = useState<boolean>(false);
    const [apiCallSupplierSearchRunning, setApiCallSupplierSearchRunning] = useState<boolean>(false);

    const supplierInputId: string = useId();

    const selectedOptionRef = useRef<IComboBoxOption | undefined>();
    const comboBoxRef = useRef(null);
    const callQueueRef = useRef<IQueueObj[]>([]);
    const callQueueProcessingRef = useRef<boolean>(false);
    const debounceDelayRef = useRef<number>(props.debounceDelay || 250);
    const clearInputCounterRef = useRef<number>(0);

    /**
     * Handle input error.
     * @param errMsg Error message. Leave blank if no error.
     */
    const handleInputError = useCallback((errMsg: string) => {
        setErrorMessage(errMsg);
        if (props.onInputValidation) {
            const isValid: boolean = !errMsg; // If there is no error message, then assume it is valid.
            props.onInputValidation(errMsg, isValid);
        }
    }, [props]);

    /**
     * Change the selected option.
     * @param option ComboBox option or undefined if nothing selected.
     */
    const changeSelectedOption = useCallback((option: IComboBoxOption | undefined) => {
        selectedOptionRef.current = option;
        setSelectedOptionKey((option?.key || '') as string);

        const supplier: Supplier | undefined = option?.data as Supplier | undefined;
        if (props.onChange) {
            if (props.allowNotFoundSupplierNumber && option?.key === doesNotExistOptionKey) {
                props.onChange(new Supplier({ supplierName: '', supplierNumber: option?.text }));
            } else {
                props.onChange(supplier);
            }
        }
    }, [props]);

    /**
     * Makes an api call to find a supplier and to create a ComboBox option for that supplier
     * if it exists, otherwise a placeholder option is created for the not found supplier.
     * @param supplierNameOrNumber Supplier name or number.
     */
    const loadSingleSupplierOption = useCallback(async (supplierNameOrNumber: string) => {
        setApiCallSupplierSearchRunning(true);

        let suppliers: Supplier[] | null = null;
        try {
            // Note we are not using Redux here (no dispatch to store). Just using regular api call with await.
            suppliers = await receiptingApiClient.searchSuppliers(supplierNameOrNumber!);
        } catch (err: any) {
            if (props.handleError) {
                props.handleError(err.message);
            }
            return;
        }

        // If more than one found, use the first one that matches the name or number.
        const supplier: Supplier | null = suppliers && suppliers.length > 0 ?
            suppliers.find(x => x.supplierNumber === supplierNameOrNumber || x.supplierName === supplierNameOrNumber) || null : null;

        let option: IComboBoxOption;
        if (supplier) {
            option = {
                key: supplier.supplierNumber,
                text: `${supplier.supplierName} (${supplier.supplierNumber})`,
                data: supplier
            } as IComboBoxOption;
            handleInputError('');
        } else {
            // The supplier was not found.
            // So create an option and indicate the supplier does not exist.
            option = {
                key: doesNotExistOptionKey,
                text: supplierNameOrNumber,
                data: null
            } as IComboBoxOption;
            handleInputError(props.allowNotFoundSupplierNumber && isNumeric(supplierNameOrNumber) ? '' : supplierDoesNotExist);
        }

        // Set the options to be the array of the one option created above.
        setOptions([option]);

        changeSelectedOption(option);

        setApiCallSupplierSearchRunning(false);
    }, [changeSelectedOption, handleInputError, props]);

    /**
     * Effect for when initial selected supplier changes via props.
     * The effect is fired when other things in the dependency array also change.
     * Uses an isInitialLoad state to ensure this effect only does anything on initial load.
     */
    useEffect(() => {
        // If an initial selected supplier was supplied in props, and this is the initial load, then load the
        // single option for that supplier.
        if (isInitialLoad) {
            setIsInitialLoad(false);

            if (props.initialSelectedSupplier) {
                loadSingleSupplierOption(props.initialSelectedSupplier);
            } else {
                changeSelectedOption(undefined);
                handleInputError(''); 
            }
        }
    }, [changeSelectedOption, handleInputError, isInitialLoad, loadSingleSupplierOption, props.initialSelectedSupplier]);

    /**
     * Effect used to clear the input.
     * This is to handle a case where we want the caller to clear the input after initial load.
     */
    useEffect(() => {
        // See if the props.clearInputCounter is different from what we had before in clearInputCounterRef.current.
        if (props.clearInputCounter && props.clearInputCounter !== clearInputCounterRef.current) {
            // Set the clearInputCounterRef to what was passed in props.
            clearInputCounterRef.current = props.clearInputCounter;

            // Clear the input.
            changeSelectedOption(undefined);
            handleInputError('');
        }
    }, [changeSelectedOption, handleInputError, props.clearInputCounter]);

    /**
     * Supplier change event handler.
     * @param event ComboBox event.
     * @param option Option selected, if any.
     * @param index Index of option selected.
     * @param value Value entered into text input.
     */
    const onChangeSupplier = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            // If an option was selected in the ComboBox.
            changeSelectedOption(option);
        } else if (value) {
            // If user typed in a value that was not in the ComboBox option list.
            // This happens if the user typed in a value and then tabbed or clicked out.
            if (inputInvalid) {
                // If input is invalid, clear the invalid state as nothing is selected.
                setInputInvalid(false);

                changeSelectedOption(undefined);
                handleInputError('');
            } else {
                // Try to load a single supplier option.
                loadSingleSupplierOption(value);
            }
        } else {
            // If no option was selected and no text entered in the ComboBox.
            changeSelectedOption(undefined);
            handleInputError('');
        }
    };

    /**
     * Checks to see if the ComboBox input element has focus.
     */
    const inputHasFocus = () => {
        // The html input element (inside the ComboBox) will have an id like supplierInput3101-input.
        // The id comes from the ComboBox id (supplierInputId) and the '-input' is appended to that.
        // By looking to see if this id is the active element, we can know if it has focus.
        return `${supplierInputId}-input` === document.activeElement?.id;
    };

    /**
     * Debounced input handler.
     * Debouncing will make it so the api call will only occur every configurable ms rather than on
     * every keypress. This is to improve perf and not to slam the api call too often.
     * See links for info on debounce usage:
     * https://blog.abdulqudus.com/how-to-use-debounce-in-react-lodash
     * https://docs-lodash.com/v4/debounce/
     */
    const handleInputDebounce = useRef<(value: string) => void>(
        debounce(async (value: string) => {
            if (value && value.length > 0) {
                // Note we are not using Redux here (no dispatch to store). Just using regular api call with await.
                callQueueRef.current.push({
                    search: value,
                    promise: receiptingApiClient.searchSuppliers(value)
                });
                debugLog(`Added call to queue for "${value}". Queue count now: ${callQueueRef.current.length}`);
            }
        }, debounceDelayRef.current, { 'leading': true, 'trailing': true })).current;

    /**
     * Debug log.
     * @param msg Message to log to console.
     */
    const debugLog = (msg: string) => {
        if (debugLogEnabled) {
            console.log(msg);
        }
    }

    /**
     * Process the call queue to the api.
     */
    const processCallQueue = async () => {
        // If already processing call queue, then return and do nothing.
        if (callQueueProcessingRef.current) {
            debugLog('Already processing call queue');
            return;
        }

        // If the call queue is empty, then return and do nothing.
        // This happens because this processCallQueue is called right after handleInputDebounce,
        // which uses a debounce function to gate api calls. If a call has not started then the call queue
        // will be empty.
        if (callQueueRef.current.length === 0) {
            debugLog('Call queue is empty');
            return;
        }

        callQueueProcessingRef.current = true;
        setApiCallSupplierSearchRunning(true);

        debugLog('Starting processing call queue');

        // While there are calls in the queue, then sequentially process each call one by one.
        // Otherwise what happens is multiple calls to the api are made and they may come back out of
        // sequence. For example, if calls a, b, c, and d are made. They might complete in the order of b, a, d, c.
        // We want the calls to run in order so that the ComboBox options are based on the final characters typed.
        while (callQueueRef.current.length > 0) {
            debugLog(`Queue length: ${callQueueRef.current.length}`);

            // Sequentially process each call in the queue.
            const apiCall: IQueueObj = callQueueRef.current[0];

            let suppliers: Supplier[] | null = null;
            try {
                // Note we are not using Redux here (no dispatch to store). Just using regular api call with await.
                debugLog(`Starting api call to search for: ${apiCall.search}`);
                suppliers = await apiCall.promise;
                debugLog(`Completed api call to search for: ${apiCall.search}`)
            } catch (err: any) {
                if (props.handleError) {
                    props.handleError(err.message);
                }
                return;
            }

            // If input has lost focus, then do not update the options.
            // This happens if the user is typing and then quickly tabs or clicks out.
            if (inputHasFocus()) {
                if (suppliers) {
                    const options: IComboBoxOption[] = suppliers.map(supplier => {
                        return {
                            key: supplier.supplierNumber,
                            text: `${supplier.supplierName} (${supplier.supplierNumber})`,
                            data: supplier
                        } as IComboBoxOption;
                    });
                    setOptions(options);
                    debugLog('Set options');
                    debugLog(JSON.stringify(options));
                    // todo: seems if typed in quickly, the final option set might not be rendered. May need to either use setTimeout or use forceRender
                }
            }

            callQueueRef.current.splice(0, 1);
        }

        debugLog('Completed processing call queue');

        setApiCallSupplierSearchRunning(false);
        callQueueProcessingRef.current = false;
    };

    /**
     * Input value changed handler for supplier.
     * This will get called on every keystroke.
     * @param text Text input for supplier name or number.
     */
    const onInputValueChangeSupplier = (text: string) => {
        let errMsg: string = '';

        if (!text) {
            setOptions([]);
        } else {
            // Validate input using length and RegEx checks.
            errMsg = validateInput(text, validationConstants.supplierNameOrNumber);
            if (errMsg) {
                setOptions([]);
                setInputInvalid(true);
            } else {
                setInputInvalid(false);
            }
        }

        handleInputError(errMsg);

        if (!errMsg) {
            // If valid input, use debounce functon to limit calls to api. Depending on if the user types quickly or slowly
            // this call will gate traffic to the api so no more than x calls are outbound in a certain time window.
            handleInputDebounce(text);

            // Use a setTimeout with the debounce delay time before processing the call queue. The API call is made in the
            // handleInputDebounce but it is awaited in the processCallQueue. It is possible that the api call in the
            // promise is already completed by this time, or it may still be pending. The promise being awaited will handle
            // it in either case. The reason this setTimeout is important is because if we called processCallQueue right away
            // after the above call to handleInputDebounce, the handleInputDebounce function may have not even added the call
            // to the queue yet (as the entire debounced function only runs every debounceDelay max).
            setTimeout(() => {
                // Process the call queue that the above function adds to. These calls are invoked on every keystroke
                // The process call queue function will only loop through calls if there are some present in the call array.
                processCallQueue();
            }, debounceDelayRef.current + 1 /* Plus one extra ms to ensure this runs after the debounced call. */);
        }
    };

    return (
        <>
            {props.showLabel && (
                <Label htmlFor={supplierInputId}>{props.label}</Label>
            )}
            <TooltipHost content={props.tooltip} delay={TooltipDelay.long}>
                <div className={commonStyles.comboBoxLoadSpinnerOuterContainer}>
                    <ComboBox
                        id={supplierInputId}
                        ref={comboBoxRef}
                        ariaLabel={`${props.label || enterSupplierNameOrNumber} ${props.tooltip}`} // Use both the label and the tooltip content for the aria label used by the screen reader.
                        className={componentStyles.comboBox}
                        options={options}
                        onChange={onChangeSupplier}
                        onInputValueChange={onInputValueChangeSupplier}
                        selectedKey={selectedOptionKey}
                        text={selectedOptionRef.current?.text || ''}
                        allowFreeform
                        autoComplete="off"
                        useComboBoxAsMenuWidth={false}
                        openOnKeyboardFocus={true}
                        disabled={props.disabled}
                        errorMessage={errorMessage}
                    />
                    {apiCallSupplierSearchRunning && (
                        <div className={commonStyles.comboBoxLoadSpinnerInnerContainer}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.comboBoxLoadSpinner} />
                        </div>
                    )}
                </div>
            </TooltipHost>
        </>
    );
};
