import { AnyAction } from '@reduxjs/toolkit';
import { createShortGuid } from '../../../common/common.func.guid';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';
import { ShipmentFileProcessingStatus } from '../../../models/shipment/shipmentFileProcessingStatus';
import { ShipmentFileInfo } from '../../../models/shipment/shipmentFileInfo';
import { shipmentApiClient } from '../../../services/api/shipmentApiClient';
import { ShipmentUploadFileStatus } from '../../../models/shipment/shipmentUploadFileStatus';
import { Organization } from '../../../models/shipment/organization';

/**
 * Payload used with callApiShipmentFileProcessingStatus action.
 */
export interface IApiShipmentFileProcessingStatus extends ICallApiBase {
    shipmentFileProcessingStatus?: ShipmentFileProcessingStatus | null;
}

/**
 * Call API to get shipment file processing status.
 * @param pageNumber Page number.
 * @param pageSize Page size.
 * @param shipmentFileProcessingStatus Shipment file upload status.
 * @returns Redux dispatch function.
 */
export const callApiShipmentFileProcessingStatus = (pageNumber: number, pageSize: number, shipmentFileProcessingStatus?: ShipmentUploadFileStatus): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ShipmentFileProcessingStatus | null>(
        actionTypes.API_SHIPMENT_FILE_PROCESSING_STATUS,
        async () => {
            return await shipmentApiClient.shipmentFileProcessingStatus(pageNumber, pageSize, shipmentFileProcessingStatus);
        },
        (payload: IApiShipmentFileProcessingStatus, shipmentFileProcessingStatus: ShipmentFileProcessingStatus | null) => {
            if (shipmentFileProcessingStatus && shipmentFileProcessingStatus.data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                shipmentFileProcessingStatus.data.forEach((item: ShipmentFileInfo) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.shipmentFileProcessingStatus = shipmentFileProcessingStatus;
        }
    );
};

/**
 * Payload used with callApiShipmentUploadShipmentDetails action.
 */
export interface IApiShipmentUploadShipmentDetails extends ICallApiBase {
}

/**
 * Call API to upload shipment details.
 * @param file Excel file to upload.
 * @param organization Organization. This is the shipping supplier like Zones or HP.
 * @returns Redux dispatch function.
 */
export const callApiShipmentUploadShipmentDetails = (file: File, organization: Organization): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_SHIPMENT_UPLOAD_SHIPMENT_DETAILS,
        async () => {
            return await shipmentApiClient.uploadShipmentDetails(file, organization);
        }
    );
};

/**
 * Action creator: Reset the API to upload shipment details.
 * @returns Redux action.
 */
export const resetApiShipmentUploadShipmentDetails = (): AnyAction => {
    const apiData: IApiShipmentUploadShipmentDetails = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    return {
        type: actionTypes.API_SHIPMENT_UPLOAD_SHIPMENT_DETAILS,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiShipmentDownloadShipmentDetails action.
 */
export interface IApiShipmentDownloadShipmentDetails extends ICallApiBase {
}

/**
 * Call API to download shipment details.
 * @param filePath File path.
 * @returns Redux dispatch function.
 */
export const callApiShipmentDownloadShipmentDetails = (filePath: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<number>(
        actionTypes.API_SHIPMENT_DOWNLOAD_SHIPMENT_DETAILS,
        async () => {
            return await shipmentApiClient.downloadShipmentDetails(filePath);
        }
    );
};

/**
 * Action creator: Reset the API to download shipment details.
 * @returns Redux action.
 */
export const resetApiShipmentDownloadShipmentDetails = (): AnyAction => {
    const apiData: IApiShipmentDownloadShipmentDetails = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    return {
        type: actionTypes.API_SHIPMENT_DOWNLOAD_SHIPMENT_DETAILS,
        payload: apiData
    } as AnyAction;
};
