export interface ICostCategoryConfiguration {
    id?: number;
    subclassName: string;
    subclassCode: string;
    shortName?: string;
    groupNameDesc?: string;
    groupNameCode?: string;
    lineItemDesc?: string;
    lineItemCode?: string;
    lineItemDetailDesc?: string;
    lineItemDetailCode?: string;
    glAccount?: string;
    glAccountDescription?: string;
    dedicatedGl?: number; // This is the account to reclass to.
    dedicatedGlDescription?: string;
}

export class CostCategoryConfiguration implements ICostCategoryConfiguration {
    public id?: number;
    public subclassName: string;
    public subclassCode: string;
    public shortName?: string;
    public groupNameDesc?: string;
    public groupNameCode?: string;
    public lineItemDesc?: string;
    public lineItemCode?: string;
    public lineItemDetailDesc?: string;
    public lineItemDetailCode?: string;
    public glAccount?: string;
    public glAccountDescription?: string;
    public dedicatedGl?: number;
    public dedicatedGlDescription?: string;

    // Below fields are not part of the server returned object, but are added for client side support.
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: ICostCategoryConfiguration) {
        this.id = jsonData.id;
        this.subclassName = jsonData.subclassName;
        this.subclassCode = jsonData.subclassCode;
        this.shortName = jsonData.shortName;
        this.groupNameDesc = jsonData.groupNameDesc;
        this.groupNameCode = jsonData.groupNameCode;
        this.lineItemDesc = jsonData.lineItemDesc;
        this.lineItemCode = jsonData.lineItemCode;
        this.lineItemDetailDesc = jsonData.lineItemDetailDesc;
        this.lineItemDetailCode = jsonData.lineItemDetailCode;
        this.glAccount = jsonData.glAccount;
        this.glAccountDescription = jsonData.glAccountDescription;
        this.dedicatedGl = jsonData.dedicatedGl;
        this.dedicatedGlDescription = jsonData.dedicatedGlDescription;
    }
}
