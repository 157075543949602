import { POLinePriorYearAccrualActivity } from './poLinePriorYearAccrualActivity';

export interface IPOLinePriorYearAccrualProcessingDetail {
    poLinePriorYearAccrualActivities: POLinePriorYearAccrualActivity[];
    lastRefreshTime?: Date;
}

export class POLinePriorYearAccrualProcessingDetail implements IPOLinePriorYearAccrualProcessingDetail {
    public poLinePriorYearAccrualActivities: POLinePriorYearAccrualActivity[] = [];
    public lastRefreshTime?: Date;

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPOLinePriorYearAccrualProcessingDetail) {
        if (jsonData.poLinePriorYearAccrualActivities) {
            for (let i: number = 0; i < jsonData.poLinePriorYearAccrualActivities.length; i++) {
                this.poLinePriorYearAccrualActivities.push(new POLinePriorYearAccrualActivity(jsonData.poLinePriorYearAccrualActivities[i]));
            }
        }
        this.lastRefreshTime = jsonData.lastRefreshTime ? new Date(jsonData.lastRefreshTime) : undefined;
    }
}
